import React from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
  UrlParams,
} from "../../utils/commonUtils";
import { getSessionStorageItem } from "../../utils/useSessionStorage";
import { useCustomQuery } from "../../hooks/useGetData";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import { apiBaseURL } from "../../envSettings";
import { STRING_GET } from "../../utils/constantVariables";

let retrieveImportantMattersDescriptionURI;

const ImportantMattersDescription = (props) => {
  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();


  // get data
  const orderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );
  const orderImportantMatterDescriptions =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderImportantMatterDescriptions;

  let importantMatterDescriptionIds;
  if (
    orderImportantMatterDescriptions &&
    orderImportantMatterDescriptions?.length > 0
  ) {
    const ids = orderImportantMatterDescriptions.map(
      ({ CMAP_ImportantMatterDescription__c }, i) =>
        CMAP_ImportantMatterDescription__c
    );
    importantMatterDescriptionIds = [...new Set(ids)];
  }

  // @Model
  if (
    importantMatterDescriptionIds &&
    importantMatterDescriptionIds.length > 0
  ) {
    const params = UrlParams(
      importantMatterDescriptionIds,
      "importantMatterDescriptionIds"
    );

    retrieveImportantMattersDescriptionURI = `${apiBaseURL}order/RetrieveImportantMatterDescriptionInfoList?${params}`;
  }

  // update the context
  const updateModalContents = (props, placeholder, layoutName, content) => {
    const modalPlaceholder = getScDataFromPlaceholder(placeholder, props);
    const [modalContent] = getScDataFromComponent("M0-01", modalPlaceholder);
    modalContent.fields.importantMatterDescriptionList = content;
  };

  const importantMatterDescriptionInfos =
    props?.retrieveImportantMattersDescriptionData?.importantMatterDescriptionInfos;

  if (
    importantMatterDescriptionInfos &&
    importantMatterDescriptionInfos.length > 0
  ) {
    updateModalContents(
      props,
      "modal-button-section",
      "Important-Matters-Description-Modal-Layout",
      importantMatterDescriptionInfos
    );
  }

  const buttonSection = getScDataFromPlaceholder("button-section", props);
  const [backButton] = getScDataFromComponent("B-02", buttonSection);

  backButton.fields.onClickEvent = () => {
    redirectToPreviousPage();
  };

  // @View
  return (
    <>
      <div className="form_wrapper-input">
        <Placeholder name="heading-section" rendering={props.rendering} />
        <br />
        <Placeholder name="modal-button-section" rendering={props.rendering} />
      </div>
      <div className="btn_container">
        <Placeholder name="button-section" rendering={props.rendering} />
      </div>
    </>
  );
};

const ImportantMattersDescriptionLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);
  const {
    data: retrieveImportantMattersDescriptionData,
    isLoading: retrieveImportantMattersDescriptionIsLoading,
    error: retrieveImportantMattersDescriptionError,
    refetch: retrieveImportantMattersDescriptionRefresh,
    remove: retrieveImportantMattersDescriptionRemove,
  } = useCustomQuery(retrieveImportantMattersDescriptionURI, true, STRING_GET);

  props.setIsLoading(retrieveImportantMattersDescriptionIsLoading);

  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <ImportantMattersDescription
                rendering={props.rendering}
                retrieveImportantMattersDescriptionData={
                  retrieveImportantMattersDescriptionData
                }
              />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(ImportantMattersDescriptionLayout);
