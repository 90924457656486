import React, { useContext } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import ModalContext from "../../assets/context/ModalContext";

const Cpad5PageLayout = (props) => {
  const { state } = useContext(ModalContext);

  const { imgPath } = state;

  return (
    <React.Fragment>
      <main>
        <Placeholder
          name="progress-tracker-section"
          rendering={props.rendering}
        />
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <div className="form_wrapper-input">
                <Placeholder
                  name="contents-of-quotation-section"
                  rendering={props.rendering}
                />
              </div>
              <div className="form_wrapper-input">
                <Placeholder
                  name="plan-and-product-list-section"
                  rendering={props.rendering}
                />
              </div>
              <div className="btn_container">
                <Placeholder
                  name="button-first-section"
                  rendering={props.rendering}
                />
              </div>
            </div>
            <div className="form_wrapper">
              <div className="form_wrapper-input">
                <Placeholder
                  name="customer-information-section"
                  rendering={props.rendering}
                />
              </div>

              <div className="btn_container">
                <Placeholder
                  name="button-second-section"
                  rendering={props.rendering}
                />
              </div>
            </div>
            <div className="form_wrapper">
              <div className="form_wrapper-input">
                <Placeholder
                  name="billing-information-section"
                  rendering={props.rendering}
                />
              </div>
              {/* TODO: Reused the L-01 component. */}
              <div className="btn_container">
                <Placeholder
                  name="button-third-section"
                  rendering={props.rendering}
                />
              </div>
            </div>
            <div className="form_wrapper">
              <div className="form_wrapper-input">
                <Placeholder
                  name="additional-information-section"
                  rendering={props.rendering}
                />
              </div>
              <div className="btn_container">
                <Placeholder
                  name="button-third-section"
                  rendering={props.rendering}
                />
              </div>
            </div>

            <div className="form_wrapper">
              <div className="form_wrapper-input">
                <Placeholder
                  name="important-information-section"
                  rendering={props.rendering}
                />
              </div>
            </div>
            <div className="form_wrapper">
              <div className="form_wrapper-input">
                <Placeholder
                  name="signature-section"
                  rendering={props.rendering}
                />

                {imgPath !== "" && (
                  <div className="signature-canvas-img">
                    <img alt="signature" src={imgPath} />
                  </div>
                )}
              </div>
            </div>
            <div className="form_wrapper signature-canvas">
              <div className="btn_container">
                <Placeholder
                  name="button-fifth-section"
                  rendering={props.rendering}
                />
              </div>
              <div className="btn_container">
                <Placeholder
                  name="button-sixth-section"
                  rendering={props.rendering}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default Cpad5PageLayout;
