import React, { useEffect, useState } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
  redirectToLinkUrl,
} from "../../utils/commonUtils";
import { getSessionStorageItem, setSessionStorageItem } from "../../utils/useSessionStorage";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import {
  STRING_APPINFO_APARTMENTAVAILABLE,
  STRING_APPINFO_APARTMENTUNAVAILABLE,
} from "../../utils/constantVariables";
import { ApplicationInformationList } from "../../services/common/common-application-information-list";
import { CommonServiceUnavailableArea } from "../../services/common/common-service-unavailable-area";

const AreaHomeInfo = (props) => {
  return (
    <Placeholder name="apartment-info-section" rendering={props.rendering} />
  );
};

const AreaServiceApply = (props) => {
  const sitecoreContext = props.sitecoreContext;
  const apartmentName = props.apartmentName;
  const apartmentAddress = props.apartmentAddress;
  const pageContextField = sitecoreContext?.route?.fields;

  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  const buttonSection = getScDataFromPlaceholder(
    "apartment-subscription-button-section",
    props
  );
  const [backButton] = getScDataFromComponent("B-02", buttonSection);
  backButton.fields.onClickEvent = () => {
    redirectToPreviousPage();
  };

  const [applyButton] = getScDataFromComponent("B-01", buttonSection);
  applyButton.fields.onClickEvent = () => {
    // get ss
    const orderRelatedInfo = getSessionStorageItem(
      sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
      true
    );

    // save ss
    const nextOrderRelatedInfo = {
      ...orderRelatedInfo,
      orderRelatedInfo: {
        ...orderRelatedInfo?.orderRelatedInfo,
        orderInfo: {
          ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
          order: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
            CMAP_ApartmentPropertyName__c: apartmentName,
            CMAP_PropertyCity__c: orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_InstallationLocationCity__c,
            CMAP_PropertyAddress__c: apartmentAddress,
            CMAP_PropertyAddressCode11__c: orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_InstallationLocationAddressCode11__c,
          },
        },
      },
    };

    setSessionStorageItem(
      sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
      nextOrderRelatedInfo,
      true
    );

    const nextLink = pageContextField["ServiceApplication-ApplicationButtonLink"];
    redirectToLinkUrl(nextLink);
  };

  return (
    <>
      <div className="application_wrapper">
        <Placeholder
          name="apartment-subscription-paragraph-section"
          rendering={props.rendering}
        />
        <Placeholder
          name="apartment-info-button-section"
          rendering={props.rendering}
        />
        <div className="btn_container">
          <Placeholder
            name="apartment-subscription-button-section"
            rendering={props.rendering}
          />
        </div>
      </div>
      <Placeholder
        name="apartment-subscription-text-section"
        rendering={props.rendering}
      />
    </>
  );
};

const AreaProviderable = (props) => {
  const sitecoreContext = props.sitecoreContext;
  const apartmentName = props.apartmentName;
  const apartmentAddress = props.apartmentAddress;

  return (
    <div className="decoration-content application">
      <AreaServiceApply
        rendering={props.rendering}
        sitecoreContext={sitecoreContext}
        apartmentName={apartmentName}
        apartmentAddress={apartmentAddress}
      />
      <ApplicationInformationList
        rendering={props.rendering}
        screen={STRING_APPINFO_APARTMENTAVAILABLE}
        setIsLoading={props.setIsLoading}
      />
    </div>
  );
};

const AreaProviderableNone = (props) => {
  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  const sitecoreContext = props.sitecoreContext;

  const buttonSection = getScDataFromPlaceholder(
    "back-to-area-check-button-section",
    props
  );
  const [backButton] = getScDataFromComponent("B-02", buttonSection);
  backButton.fields.onClickEvent = () => {
    redirectToPreviousPage();
  };

  return (
    <>
      <CommonServiceUnavailableArea
        screen={STRING_APPINFO_APARTMENTUNAVAILABLE}
        rendering={props.rendering}
        sitecoreContext={sitecoreContext}
        setIsLoading={props.setIsLoading}
      />
    </>
  );
};

/** Layout Main */
const ApartmentSearchPageResultDetailLayout = (props) => {

  // get the data
  const apartmentName = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_APARTMENTNAME,
    true
  );
  const apartmentAddress = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_APARTMENTADDRESS,
    true
  );
  const isProvidableSessionStorage = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_ISPROVIDABLE,
    true
  );
  const provisionStatus = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_PROVISIONSTATUS,
    true
  );
  const provisionMethod = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_PROVISIONMETHOD,
    true
  );
  const note = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_NOTE,
    true
  );
  const isALCMProperty  = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_ISALCMPROPERTY,
    true
  );
  const roomNumber = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_INPUTROOMNUMBER_ROOMNUMBER,
    true
  );

  // @model
  const sitecoreContext = props?.sitecoreContext;
  const pageContextField = sitecoreContext?.route?.fields;
  di_register("thisPageSitecoreContext", sitecoreContext);
  const apartmentInProvision = getScDataFromPlaceholder(
    "apartment-info-section",
    props
  );
  const [isProvidable, setIsProvidable] = useState(false);

  // Event 1
  const [h02Data] = getScDataFromComponent("H-02", apartmentInProvision);
  const [l01Data] = getScDataFromComponent("L-01", apartmentInProvision);

  const apartmentDataList = [
    {
      fields: {
        Key: pageContextField["ApartmentInProvision-AddressText"]?.value,
        Value: apartmentAddress,
      },
    },
    {
      fields: {
        Key: pageContextField["ApartmentInProvision-RoomNumberText"]?.value,
        Value: roomNumber,
      },
    },
    {
      fields: {
        Key: pageContextField["ApartmentInProvision-StatusText"]?.value,
        Value: provisionStatus,
      },
    },
    {
      fields: {
        Key: pageContextField["ApartmentInProvision-MethodText"]?.value,
        Value: provisionMethod,
      },
    },
    {
      fields: {
        Key: pageContextField["ApartmentInProvision-NoteText"]?.value,
        Value: note,
      },
    },
  ];

  // update context
  useEffect(() => {
    // NOTE: assign the sessionstorageItem value in useState will fix the H-02 broken styling when rendering the page
    setIsProvidable(isProvidableSessionStorage);
  }, []);

  const apartmentDataListFiltered = isALCMProperty 
    ? apartmentDataList
    : apartmentDataList.filter(
        (x) =>
          x.fields.Key !=
          pageContextField["ApartmentInProvision-RoomNumberText"]?.value
      );
  l01Data.fields.dataRowList = apartmentDataListFiltered;
  l01Data.fields.titleText = { value: apartmentName };
  h02Data.fields.isHidden = !isProvidable;

  // @view
  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <div className="form_wrapper-input">
                {/* 住宅情報 */}
                <AreaHomeInfo rendering={props.rendering} />

                {/*提供可の場合*/}
                {isProvidable && (
                  <AreaProviderable
                    rendering={props.rendering}
                    sitecoreContext={sitecoreContext}
                    apartmentName={apartmentName}
                    apartmentAddress={apartmentAddress}
                    setIsLoading={props.setIsLoading}
                  />
                )}

                {/*提供不可の場合*/}
                {!isProvidable && (
                  <AreaProviderableNone
                    rendering={props.rendering}
                    sitecoreContext={sitecoreContext}
                    setIsLoading={props.setIsLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(ApartmentSearchPageResultDetailLayout);
