import React, { useContext, useState, useEffect } from "react";
import { Text, Placeholder } from "@sitecore-jss/sitecore-jss-react";
import ModalContext from "../../assets/context/ModalContext";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
} from "../../utils/commonUtils";

// @Model

// get the data

// update the context

// @Controller

// @View

const C03 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const [checkActive, setCheckActive] = useState([]);
  const [isLinkClicked, setIsLinkClicked] = useState(false);
  const {
    titleText,
    explanationText,
    buttonLabel,
    additionalClass,
    onItemClick,
    linkId,
  } = properData;

  const { state, dispatch } = useContext(ModalContext);

  const modalButtonShowHandler = (index) => {
    !checkActive.includes(index) && setCheckActive([...checkActive, index]);
    dispatch({ type: "SHOW_MODAL", payload: { id: "termsInformation1" } });
    onItemClick && onItemClick(index);
  };

  const buttonsectionp04 = getScDataFromPlaceholder(
    "button-section-p04",
    props
  );
  const [b04Data] = getScDataFromComponent("B-04", buttonsectionp04);
  b04Data.fields.isDisabled =
    props?.fields?.buttonList?.length === checkActive?.length || isLinkClicked
      ? false
      : true;

  useEffect(() => {
    const handleClick = (event) => {
      if (
        linkId &&
        event.target.tagName === "A" &&
        event.target.id === linkId
      ) {
        setIsLinkClicked(true);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <React.Fragment>
      <div
        className={`decoration-content terms C-03 ${additionalClass?.value}`}
      >
        <div className="content-title">
          <h5>
            <Text field={fillBlank(titleText)} />
          </h5>
        </div>
        <div className="information">
          <p>
            <Text field={fillBlank(explanationText)} encode={false} />
          </p>
          <ul>
            {props?.fields?.buttonList &&
              props?.fields?.buttonList?.map((item, id) => (
                <li
                  className={checkActive.includes(id) ? "active" : ""}
                  key={id}
                >
                  <div className="btn_wrapper">
                    <button
                      className="btn btn_popup btn_txt"
                      type="button"
                      id={item?.Id}
                      onClick={() => modalButtonShowHandler(id)}
                    >
                      <Text field={fillBlank(item?.Name)} />
                    </button>
                  </div>
                </li>
              ))}
          </ul>
          <Placeholder name="button-section-p04" rendering={props.rendering} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default C03;
