import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
} from "../../utils/commonUtils";
import {
  MEMBER_REGISTER_TYPE_3,
  STRING_BANK_ACCOUNT_TRANSFER,
  STRING_LOADING,
} from "../../utils/constantVariables";
import parse from "html-react-parser";
import { getURLType, redirectToLinkUrl } from "../../utils/targetUrlType";
import { useGetDataViaGraphQL } from "../../hooks/useGetData";
import { query } from "../../assets/graphql/StepsAfterOrderingList";

const ApplicationStepAfter = (props) => {
  // @View
  return (
    <>
      <div className="form_wrapper-input">
        <Placeholder
          name="application-step-after-section"
          rendering={props.rendering}
        />
      </div>

      <div className="btn_container">
        <Placeholder
          name="application-step-after-button-section"
          rendering={props.rendering}
        />
      </div>
    </>
  );
};

export const CommonApplicationStepAfter = (props) => {
  const {
    isLoggedIn,
    paymentMethod,
    isNewPaymentMethod,
    constructionSchedule,
    myCommufaRegistrationChoice,
  } = props;
  let orderListData = null;
  const applicationStepAfter = getScDataFromPlaceholder(
    "application-step-after-section",
    props
  );

  const btnSection = getScDataFromPlaceholder(
    "application-step-after-button-section",
    props
  );

  const [c04Data] = getScDataFromComponent("C-04", applicationStepAfter);
  const [b02Data] = getScDataFromComponent("B-02", btnSection);

  const stepsAfterOrderingList = useGetDataViaGraphQL(
    query,
    null,
    "StepsAfterOrderingListQuery"
  );

  if (
    isLoggedIn &&
    paymentMethod === STRING_BANK_ACCOUNT_TRANSFER &&
    isNewPaymentMethod &&
    constructionSchedule
  ) {
    orderListData =
      stepsAfterOrderingList[
        "MembersWithBankAccountRegistrationAndConstructionSchedulingSteps"
      ];
  } else if (
    isLoggedIn &&
    paymentMethod === STRING_BANK_ACCOUNT_TRANSFER &&
    isNewPaymentMethod &&
    !constructionSchedule
  ) {
    orderListData =
      stepsAfterOrderingList["MemberWithBankAccountRegistrationStep"];
  } else if (
    isLoggedIn &&
    (paymentMethod !== STRING_BANK_ACCOUNT_TRANSFER ||
      (paymentMethod === STRING_BANK_ACCOUNT_TRANSFER &&
        !isNewPaymentMethod)) &&
    constructionSchedule
  ) {
    orderListData =
      stepsAfterOrderingList["MemberWithConstructionSchedulingStep"];
  } else if (
    isLoggedIn &&
    (paymentMethod !== STRING_BANK_ACCOUNT_TRANSFER ||
      (paymentMethod === STRING_BANK_ACCOUNT_TRANSFER &&
        !isNewPaymentMethod)) &&
    !constructionSchedule
  ) {
    orderListData = stepsAfterOrderingList["MemberWithoutAnyStep"];
  } else if (
    !isLoggedIn &&
    myCommufaRegistrationChoice?.toString() !== MEMBER_REGISTER_TYPE_3 &&
    paymentMethod === STRING_BANK_ACCOUNT_TRANSFER &&
    isNewPaymentMethod &&
    constructionSchedule
  ) {
    orderListData =
      stepsAfterOrderingList[
        "PotentialMemberWithBankAccountRegistrationAndConstructionSchedulingSteps"
      ];
  } else if (
    !isLoggedIn &&
    myCommufaRegistrationChoice?.toString() !== MEMBER_REGISTER_TYPE_3 &&
    paymentMethod === STRING_BANK_ACCOUNT_TRANSFER &&
    isNewPaymentMethod &&
    !constructionSchedule
  ) {
    orderListData =
      stepsAfterOrderingList["PotentialMemberWithBankAccountRegistrationStep"];
  } else if (
    !isLoggedIn &&
    myCommufaRegistrationChoice?.toString() !== MEMBER_REGISTER_TYPE_3 &&
    (paymentMethod !== STRING_BANK_ACCOUNT_TRANSFER ||
      (paymentMethod === STRING_BANK_ACCOUNT_TRANSFER &&
        !isNewPaymentMethod)) &&
    constructionSchedule
  ) {
    orderListData =
      stepsAfterOrderingList["PotentialMemberWithConstructionSchedulingStep"];
  } else if (
    !isLoggedIn &&
    myCommufaRegistrationChoice?.toString() !== MEMBER_REGISTER_TYPE_3 &&
    (paymentMethod !== STRING_BANK_ACCOUNT_TRANSFER ||
      (paymentMethod === STRING_BANK_ACCOUNT_TRANSFER &&
        !isNewPaymentMethod)) &&
    !constructionSchedule
  ) {
    orderListData = stepsAfterOrderingList["PotentialMemberWithoutAnyStep"];
  } else if (
    !isLoggedIn &&
    myCommufaRegistrationChoice?.toString() === MEMBER_REGISTER_TYPE_3 &&
    paymentMethod === STRING_BANK_ACCOUNT_TRANSFER &&
    isNewPaymentMethod &&
    constructionSchedule
  ) {
    orderListData =
      stepsAfterOrderingList[
        "NonMemberWithBankAccountRegistrationAndConstructionSchedulingSteps"
      ];
  } else if (
    !isLoggedIn &&
    myCommufaRegistrationChoice?.toString() === MEMBER_REGISTER_TYPE_3 &&
    paymentMethod === STRING_BANK_ACCOUNT_TRANSFER &&
    isNewPaymentMethod &&
    !constructionSchedule
  ) {
    orderListData =
      stepsAfterOrderingList["NonMemberWithBankAccountRegistrationStep"];
  } else if (
    !isLoggedIn &&
    myCommufaRegistrationChoice?.toString() === MEMBER_REGISTER_TYPE_3 &&
    (paymentMethod !== STRING_BANK_ACCOUNT_TRANSFER ||
      (paymentMethod === STRING_BANK_ACCOUNT_TRANSFER &&
        !isNewPaymentMethod)) &&
    constructionSchedule
  ) {
    orderListData =
      stepsAfterOrderingList["NonMemberWithConstructionSchedulingStep"];
  } else if (
    !isLoggedIn &&
    myCommufaRegistrationChoice?.toString() === MEMBER_REGISTER_TYPE_3 &&
    (paymentMethod !== STRING_BANK_ACCOUNT_TRANSFER ||
      (paymentMethod === STRING_BANK_ACCOUNT_TRANSFER &&
        !isNewPaymentMethod)) &&
    !constructionSchedule
  ) {
    orderListData = stepsAfterOrderingList["NonMemberWithoutAnyStep"];
  } else {
    c04Data.fields["isHidden"] = true;
    b02Data.fields["isHidden"] = true;
  }

  props.setIsLoading(stepsAfterOrderingList === STRING_LOADING);

  if (orderListData) {
    if (orderListData?.fields?.find(({ name }) => name === "StepsAfterOrderingList-ItemList")?.targetItems?.length === 0) {
      c04Data.fields["isHidden"] = true;
    }

    orderListData.fields.map(function (item) {
      if (item.name === "StepsAfterOrderingList-ItemList") {
        c04Data.fields.itemList = item.targetItems.map((target, index) => {
          let {
            stepTitle,
            stepDescription,
            buttonText,
            linkUrl,
            urlType,
            itemList,
          } = "";
          target.fields.map((field, i) => {
            if (field.name === "StepsAfterOrdering-StepTitle") {
              stepTitle = field.value;
            }
            if (field.name === "StepsAfterOrdering-StepDescription") {
              stepDescription = field.value;
            }
            if (field.name === "StepsAfterOrdering-ButtonText") {
              buttonText = field.value;
            }
            if (field.name === "StepsAfterOrdering-LinkUrl") {
              linkUrl = parse(field.editable).props;
            }
            if (field.name === "StepsAfterOrdering-UrlType") {
              urlType = {
                fields: {
                  Code: {
                    value: field?.targetItem?.field?.value,
                  },
                },
              };
            }
          });

          itemList = {
            isShowNumber: item.targetItems.length > 1,
            countText: (index + 1).toString(),
            textAreaLabel: stepTitle,
            explanationText: stepDescription,
            buttonLabel: buttonText,
            linkURL: linkUrl,
            urlType: getURLType(urlType),
          };
          return itemList;
        });
      }
      if (item.name === "StepsAfterOrderingList-Title") {
        c04Data.fields["titleText"] = item.value;
      }
      if (item.name === "StepsAfterOrderingList-BackButtonActivationFlg") {
        if (item.value != 1) {
          b02Data.fields["isHidden"] = true;
        } else {
          b02Data.fields["isHidden"] = false;
        }
      }
    });
  }

  c04Data.fields["myClickEvent"] = ({ urlType, linkURL }) => {
    redirectToLinkUrl(linkURL, urlType);
  };

  // @View
  return <ApplicationStepAfter rendering={props.rendering} />;
};
