import React, { useState, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import { useFormContext } from "react-hook-form";
import parse from "html-react-parser";
import { useFormDataStore, useCustomerInfoStore } from "../../hooks/useSimpleOpState";

// get the data

// update the context

// @Controller

// @View

const I01 = (props) => {
  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext();
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    textInputLabel,
    requiredText,
    additionalClass,
    textInputPlaceholder,
    textInputWidth,
    descriptionText,
    dataRowList,
    isHidden,
    isHiddenTextBox,
    descriptionTextBottom,
    name,
    type
  } = properData;
  let { inputValue } = properData;
  // @Controller
  const inputId = `I01-1-${textInputLabel?.value?.replace(" ", "-")}`;
  let formName = props?.fields?.formName ? props.fields.inputName : inputId;
  let isRequired = props?.fields?.isRequired ? props.fields.isRequired : false;
  const formData = useFormDataStore((state) => state.formData);
  const customerInfo = useCustomerInfoStore((state) => state.customerInfo);
  const [isHiddenField, setIsHiddenField] = useState("");

  if(Object.keys(formData).length > 0 && type === "productAttributes" && formData[name]) {
    inputValue = 
    {
      name: name,
      value: formData[name]      
    };
  }

  if(Object.keys(customerInfo).length > 0 && type === "customerInfo" && customerInfo[name]) {
    inputValue = 
    {
      name: name,
      value: customerInfo[name]      
    };
  }


  useEffect(() => {
    setIsHiddenField(isHidden);
  }, [isHidden]);

  return (
    <React.Fragment>
      {!isHiddenField?.value && (
        <div className="input-box I-01">
          <div className={`input-form ${additionalClass?.value}`}>
            <div className="input-container">
              <label htmlFor={inputId}>
                <Text field={fillBlank(textInputLabel)} />
                {requiredText?.value && (
                  <span className="required">
                    <Text field={fillBlank(requiredText)} />
                  </span>
                )}
              </label>

              {descriptionText?.value && (
                <Text
                  field={fillBlank(descriptionText)}
                  tag="p"
                  encode={false}
                />
              )}
              {dataRowList?.length > 0 ? (
                dataRowList?.map((item, i) => (
                  <>
                    <Placeholder
                      name="description-text"
                      rendering={props.rendering}
                    />
                    <input
                      id={item?.fields.Value.value + "-" + i}
                      name={item?.fields.inputValue?.name}
                      type="text"
                      placeholder={item?.fields.Value.value}
                      className={textInputWidth?.value}
                      value={item?.fields.inputValue?.value}
                      onChange={props?.fields?.onChangeEvent}
                      disabled={inputValue && inputValue?.isDisabled}
                    />
                  </>
                ))
              ) : (
                <>
                  <Placeholder
                    name="description-text"
                    rendering={props.rendering}
                  />
                  {!isHiddenTextBox && (
                    <>
                      <input
                        id={inputId}
                        type="text"
                        name={inputValue?.name}
                        placeholder={textInputPlaceholder?.value}
                        className={textInputWidth?.value}
                        // {...register(formName, {
                        //   required: isRequired,
                        // })}
                        onChange={props?.fields?.onChangeEvent}
                        value={inputValue && inputValue?.value}
                        onBlur={props?.fields?.onBlurEvent}
                        disabled={inputValue && inputValue?.isDisabled}
                      />
                      {descriptionTextBottom && (
                        <span className="ml-0 mt-16 description-text-bottom">
                          {parse(descriptionTextBottom)}
                        </span>
                      )}
                    </>
                  )}
                </>
              )}
            </div>

            {/* TODO: Component can have multiple input w/ labels, also can be align side by side */}
            {/* <div className="input-container">
          <label htmlFor="I01-1">
            <Text field={fillBlank(textInputLabel)} />
            <span className="required">
              <Text field={fillBlank(requiredText)} />
            </span>
          </label>
          <input id="I01-1" name="I01-1" type="text" />
        </div> */}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default I01;
