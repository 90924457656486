import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const P02 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);

  const { additionalClass, tagIcon, tagText } = properData;

  // @View
  return (
    <React.Fragment>
      {/* if you want tag with icon, assign "with-icon" to additionClass */}
      <div className={`tag yellow ${additionalClass} P-02`}>
        <span>
          {tagIcon?.value && <img src={fillBlank(tagIcon)?.value?.src} />}
          <Text field={fillBlank(tagText)} />
        </span>
      </div>
    </React.Fragment>
  );
};

export default P02;
