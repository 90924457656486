import React, { useEffect, useState } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  getUrlInfo,
} from "../../utils/commonUtils";
import { di_register } from "../../utils/di";
import {
  STRING_APPLY,
  MEMBER_REGISTER_TYPE_1,
  MEMBER_REGISTER_TYPE_2,
  MEMBER_REGISTER_TYPE_3,
} from "../../utils/constantVariables";

// TODO: Test Data
import { getSessionStorageItem } from "../../utils/useSessionStorage";
import { cpadPageURL } from "../../envSettings";


const ApplicationCompleted = (props) => {
  // @View
  return (
    <>
      <Placeholder name="title-section" rendering={props.rendering} />
      <div className="form_wrapper-input">
        <Placeholder name="information-section" rendering={props.rendering} />
      </div>
      <div className="btn_container">
        <Placeholder name="return-button-section" rendering={props.rendering} />
      </div>
    </>
  );
};

export function CommonApplicationCompletedLayout(props) {
  // TODO delete test data

  const { sitecoreContext: sitecoreContext } = props;
  di_register("thisPageSitecoreContext", sitecoreContext);

  // get ss
  const myCommufaRegistrationChoice = getSessionStorageItem(
    keys.SSN_APPLICATION_CREATEMYCOMMUFAID_REGISTRATIONCHOICE,
    true
  );
  
  const applicationNumber = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_APPLICATIONNUMBER,
    true
  );

  // update the context
  let infoSection = getScDataFromPlaceholder("information-section", props);
  const btnSection = getScDataFromPlaceholder("return-button-section", props);
  let P05Data = getScDataFromComponent("P-05", infoSection)[0];
  let L06Data = getScDataFromComponent("L-06", infoSection)[0];
  let D01Data = getScDataFromComponent("D-01", infoSection)[0];
  const B02Data = getScDataFromComponent("B-02", btnSection)[0];

  const [descriptionText, setDescriptionText] = useState("");
  const [notesLabelVisible, setNotesLabelVisible] = useState("");

  useEffect(() => {
    const urlInfo = getUrlInfo();
    const isURLName = urlInfo?.origin === cpadPageURL;
    const isApplyPage =
      urlInfo?.pathnames[1]?.toLocaleLowerCase() === STRING_APPLY;

    if (isURLName && isApplyPage) {
      if (myCommufaRegistrationChoice?.toString() === MEMBER_REGISTER_TYPE_1) {
        setDescriptionText(
          props.sitecoreContext.route.fields[
            "CommonApplicationCompleted-DescriptionLabel"
          ].value
        );
        setNotesLabelVisible(true);
      } else if (myCommufaRegistrationChoice?.toString() === MEMBER_REGISTER_TYPE_2) {
        setDescriptionText(
          props.sitecoreContext.route.fields[
            "CommonApplicationCompleted-DescriptionLabelForRegisteringIDWithoutImmediateEmailConfirmation"
          ].value
        );
        setNotesLabelVisible(true);
      } else if (myCommufaRegistrationChoice?.toString() === MEMBER_REGISTER_TYPE_3) {
        setDescriptionText(
          props.sitecoreContext.route.fields[
            "CommonApplicationCompleted-DescriptionLabelForNotRegisteringID"
          ].value
        );
        setNotesLabelVisible(false);
      }
    } else {
      setDescriptionText(
        props.sitecoreContext.route.fields[
          "CommonApplicationCompleted-DescriptionLabel"
        ].value
      );
      setNotesLabelVisible(true);
    }
  }, []);

  const isBackButtonHidden = () => {
    const link =
      props.sitecoreContext.route.fields[
        "CommonApplicationCompleted-BackButtonLink"
      ].value.href;

    if (!link) {
      return true;
    }
  };

  // update the context
  D01Data.fields["descriptionText"] = descriptionText;
  P05Data.fields.isHidden = !applicationNumber;
  P05Data.fields["descriptionLabel"] = applicationNumber;
  L06Data.fields.isVisible = notesLabelVisible;
  B02Data.fields.isHidden = isBackButtonHidden();

  // @View
  return <ApplicationCompleted rendering={props.rendering} />;
}
