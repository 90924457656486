import React, { useEffect, useState } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { di_register } from "../../utils/di";
import {
  setSessionStorageItem,
  removeAllSessionStorageItem,
} from "../../utils/useSessionStorage";
import { apiBaseURL } from "../../envSettings";
import { useCustomQuery } from "../../hooks/useGetData";
import {
  STRING_APPLY,
  STRING_CHANGEITEM,
  STRING_CHANGEPLAN,
  STRING_POST,
  API_RESPONSE_STATUS,
  STRING_CATEGORY_NEW_CONTRACT,
  STRING_CATEGORY_PLAN_CHANGE,
  STRING_CATEGORY_PRODUCT_CHANGE,
} from "../../utils/constantVariables";
import {
  getParamValue,
  getUrlInfo,
  redirectToLinkUrl
} from "../../utils/commonUtils";
import { getGaClientIdFromCookie } from "../../utils/useCookies";

const CPadCustomerAssignmentLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  const pageContext = sitecoreContext?.route?.fields;
  di_register("thisPageSitecoreContext", sitecoreContext);

  let orderCategory = "";
  const urlInfo = getUrlInfo();
  const parentPath = urlInfo?.pathnames[1];

  const [gaCookieClientId, setGaCookieClientId] = useState("");

  switch (parentPath) {
    case STRING_APPLY:
      orderCategory = STRING_CATEGORY_NEW_CONTRACT;
      break;
    case STRING_CHANGEPLAN:
      orderCategory = STRING_CATEGORY_PLAN_CHANGE;
      break;
    case STRING_CHANGEITEM:
      orderCategory = STRING_CATEGORY_PRODUCT_CHANGE;
      break;
  }

  ///////////////////// CREATE ORDER RELATED INFO
  // API call
  const createOrderRelatedInfoURI = `${apiBaseURL}order/CreateOrderRelatedInfo`;

  // Request Body for CreateOrderRelatdInfo API
  const createOrderRelatedInfoRequest = {
    orderId: "",
    individualContractId: getParamValue("individualContractId"),
    caseId: "",
    customerId: getParamValue("accountId"),
    leadId: getParamValue("leadId"),
    orderCategorySelected: orderCategory,
  };

  const {
    data: createOrderRelatedInfoData,
    status: createOrderRelatedInfoStatus,
    isLoading: createOrderRelatedInfoIsLoading,
    refetch: createOrderRelatedInfoRefresh,
    remove: createOrderRelatedInfoRemove,
  } = useCustomQuery(
    createOrderRelatedInfoURI,
    false,
    STRING_POST,
    createOrderRelatedInfoRequest
  );

  useEffect(() => {
    removeAllSessionStorageItem([sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO]);

    setGaCookieClientId(getGaClientIdFromCookie());
  }, []);

  useEffect(() => {
    if (parentPath === STRING_APPLY) {
      if (createOrderRelatedInfoStatus === API_RESPONSE_STATUS.IDLE) {
        createOrderRelatedInfoRefresh();
      }

      if (createOrderRelatedInfoStatus === API_RESPONSE_STATUS.SUCCESS) {
        // @Model
        let accountData =
          createOrderRelatedInfoData?.orderRelatedInfo.accountInfo.account;
        let accountAdditionData =
          createOrderRelatedInfoData?.orderRelatedInfo.accountInfo
            .accountAddition;
        let orderData =
          createOrderRelatedInfoData?.orderRelatedInfo.orderInfo.order;
        let orderAdditionData =
          createOrderRelatedInfoData?.orderRelatedInfo.orderInfo.orderAddition;

        // Response
        const orderRelatedInfoForApply = {
          orderRelatedInfo: {
            accountInfo: {
              account: {
                Id: accountData?.Id,
                CMAP_ContractName__c: accountData?.CMAP_ContractName__c,
                LastName: accountData?.LastName,
                FirstName: accountData?.FirstName,
                LastNameKana__pc: accountData?.LastNameKana__pc,
                FirstNameKana__pc: accountData?.FirstNameKana__pc,
                CMAP_Gender__c: accountData?.CMAP_Gender__c,
                PersonBirthdate: accountData?.PersonBirthdate,
                CMAP_CorporateGroupName__c:
                  accountData?.CMAP_CorporateGroupName__c,
                CMAP_DepartmentName__c: accountData?.CMAP_DepartmentName__c,
                CMAP_ContactPersonFamilyName__c:
                  accountData?.CMAP_ContactPersonFamilyName__c,
                CMAP_ContactPersonName__c:
                  accountData?.CMAP_ContactPersonName__c,
                CMAP_ContactPersonLastNameKana__pc:
                  accountData?.CMAP_ContactPersonLastNameKana__pc,
                CMAP_ContactPersonFirstNameKana__pc:
                  accountData?.CMAP_ContactPersonFirstNameKana__pc,
                CMAP_ContactPostalCode__c:
                  accountData?.CMAP_ContactPostalCode__c,
                CMAP_ContactPostalCodeLowerFour__c:
                  accountData?.CMAP_ContactPostalCodeLowerFour__c,
                CMAP_ContactState__c: accountData?.CMAP_ContactState__c,
                CMAP_ContactCity__c: accountData?.CMAP_ContactCity__c,
                CMAP_ContactHouseNumber__c:
                  accountData?.CMAP_ContactHouseNumber__c,
                CMAP_MansionName__c: accountData?.CMAP_MansionName__c,
                CMAP_RoomNo__c: accountData?.CMAP_RoomNo__c,
                CMAP_AreaCode__c: accountData?.CMAP_AreaCode__c,
                CMAP_LocalTelephoneNumber__c:
                  accountData?.CMAP_LocalTelephoneNumber__c,
                CMAP_SubscriberNumber__c: accountData?.CMAP_SubscriberNumber__c,
                CMAP_MobileNumber1__c: accountData?.CMAP_MobileNumber1__c,
                CMAP_MobileNumber2__c: accountData?.CMAP_MobileNumber2__c,
                CMAP_MobileNumber3__c: accountData?.CMAP_MobileNumber3__c,
                CMAP_MyCommufaID__c: accountData?.CMAP_MyCommufaID__c,
                CMAP_MultilingualReexplainCenter__c:
                  accountData?.CMAP_MultilingualReexplainCenter__c,
              },
              accountAddition: {
                isNewAccount: accountAdditionData?.isNewAccount,
                leadId: accountAdditionData?.leadId,
              },
            },
            orderInfo: {
              order: {
                AccountId: orderData?.AccountId,
                CMAP_ContractName__c: orderData?.CMAP_ContractName__c,
                CMAP_Case__c: orderData?.CMAP_Case__c,
                CMAP_Billing__c: orderData?.CMAP_Billing__c,
                CMAP_BillingPaymentMethodAgent__c:
                  orderData?.CMAP_BillingPaymentMethodAgent__c,
                CMAP_BillingPaymentMethodRegistered__c:
                  orderData?.CMAP_BillingPaymentMethodRegistered__c,
                CMAP_NeedsSendingDocumentOfBilling__c:
                  orderData?.CMAP_NeedsSendingDocumentOfBilling__c,
                CMAP_AcquisitionAgency__c: orderData?.CMAP_AcquisitionAgency__c,
                CMAP_AcquisitionShop__c: orderData?.CMAP_AcquisitionShop__c,
                CMAP_IndividualContractComplementary__c:
                  orderData?.CMAP_IndividualContractComplementary__c,
                CMAP_ContractDocDeliveryCategory__c:
                  orderData?.CMAP_ContractDocDeliveryCategory__c,
                CMAP_StandardProductOffer__c:
                  orderData?.CMAP_StandardProductOffer__c,
                CMAP_MovementStandardProductOffer__c:
                  orderData?.CMAP_MovementStandardProductOffer__c,
                CMAP_OrderCategory__c: orderCategory,
                CMAP_ClientId__c: gaCookieClientId,
              },
              orderAddition: {
                orderChannelCategory: orderAdditionData?.orderChannelCategory,
                orderCategoryCurrentlySelected: orderCategory,
              },
              orderItemInfos: createOrderRelatedInfoData?.orderRelatedInfo?.orderInfo?.orderItemInfos?.map(
                (orderItemInfo) => {
                  const orderItem = orderItemInfo.orderItem;
                  const orderItemAddition = orderItemInfo.orderItemAddition;
                  return {
                    orderItem: {
                      CMAP_OfferPlanProduct__c:
                        orderItem?.CMAP_OfferPlanProduct__c,
                      CMAP_MainSubProductGroupId__c:
                        orderItem?.CMAP_MainSubProductGroupId__c,
                      CMAP_MainSubProductGroupCategory__c:
                        orderItem?.CMAP_MainSubProductGroupCategory__c,
                      CMAP_MainSubProductGroupName__c:
                        orderItem?.CMAP_MainSubProductGroupName__c,
                      CMAP_ProductCategoryId__c:
                        orderItem?.CMAP_ProductCategoryId__c,
                      CMAP_ProductCategoryName__c:
                        orderItem?.CMAP_ProductCategoryName__c,
                      Product2Id: orderItem?.Product2Id,
                      CMAP_ProductName__c: orderItem?.CMAP_ProductName__c,
                      UnitPrice: orderItem?.UnitPrice,
                      CMAP_ComsumptionTaxRate__c:
                        orderItem?.CMAP_ComsumptionTaxRate__c,
                      Quantity: orderItem?.Quantity,
                      CMAP_PriceCategory__c: orderItem?.CMAP_PriceCategory__c,
                      CMAP_InstallmentPaymentMonthly__c:
                        orderItem?.CMAP_InstallmentPaymentMonthly__c,
                      CMAP_InstallmentPayMonthlyPriceTaxExc__c:
                        orderItem?.CMAP_InstallmentPayMonthlyPriceTaxExc__c,
                      CMAP_NumberOfInstallments__c:
                        orderItem?.CMAP_NumberOfInstallments__c,
                      CMAP_InstallmentPayFirstMonthPriceTaxExc__c:
                        orderItem?.CMAP_InstallmentPayFirstMonthPriceTaxExc__c,
                      CMAP_InstallmentPaymentCurrently__c:
                        orderItem?.CMAP_InstallmentPaymentCurrently__c,
                      CMAP_SelectionAnnualPayment__c:
                        orderItem?.CMAP_SelectionAnnualPayment__c,
                      CMAP_AnnualPaymentPriceTaxExcluded__c:
                        orderItem?.CMAP_AnnualPaymentPriceTaxExcluded__c,
                      CMAP_Unchargeable__c: orderItem?.CMAP_Unchargeable__c,
                      CMAP_ProductSelectionState__c:
                        orderItem?.CMAP_ProductSelectionState__c,
                      CMAP_LastTimeChangeCategory__c:
                        orderItem?.CMAP_LastTimeChangeCategory__c,
                      CMAP_HasOrderItemAttribute__c:
                        orderItem?.CMAP_HasOrderItemAttribute__c,
                      CMAP_AvailableDailyPricing__c:
                        orderItem?.CMAP_AvailableDailyPricing__c,
                      CMAP_UnchangeableContract__c:
                        orderItem?.CMAP_UnchangeableContract__c,
                      CMAP_SetProduct__c: orderItem?.CMAP_SetProduct__c,
                      CMAP_HasPriceChanged__c:
                        orderItem?.CMAP_HasPriceChanged__c,
                      CMAP_TaxationCategory__c:
                        orderItem?.CMAP_TaxationCategory__c,
                      CMAP_RemainingDebtLumpSumPayment__c:
                        orderItem?.CMAP_RemainingDebtLumpSumPayment__c,
                      CMAP_ChangeSourceDestinationCategory__c:
                        orderItem?.CMAP_ChangeSourceDestinationCategory__c,
                      CMAP_InheritsMovementAssetAttribute__c:
                        orderItem?.CMAP_InheritsMovementAssetAttribute__c,
                      CMAP_OrderItemTypeSelection__c:
                        orderItem?.CMAP_OrderItemTypeSelection__c,
                      CMAP_OrderItemTypeBenefit__c:
                        orderItem?.CMAP_OrderItemTypeBenefit__c,
                      CMAP_OrderItemTypeAutomaticSelection__c:
                        orderItem?.CMAP_OrderItemTypeAutomaticSelection__c,
                      CMAP_BeforeOffer__c: orderItem?.CMAP_BeforeOffer__c,
                      CMAP_MainSubProGroupBenefitCPSortOrder__c:
                        orderItem?.CMAP_MainSubProGroupBenefitCPSortOrder__c,
                      CMAP_ProductCategoryBenefitSortOrder__c:
                        orderItem?.CMAP_ProductCategoryBenefitSortOrder__c,
                      CMAP_ProductSortOrder__c:
                        orderItem?.CMAP_ProductSortOrder__c,
                      CMAP_StandardProduct__c:
                        orderItem?.CMAP_StandardProduct__c,
                      CMAP_PredecessorOfferStartRequest__c:
                        orderItem?.CMAP_PredecessorOfferStartRequest__c,
                      CMAP_ExemptionPeriod__c:
                        orderItem?.CMAP_ExemptionPeriod__c,
                    },
                    orderItemAddition: {
                      precaution: orderItemAddition?.precaution,
                      tooltip: orderItemAddition?.tooltip,
                      cancellationPrecaution:
                        orderItemAddition?.cancellationPrecaution,
                      terminationPrecaution:
                        orderItemAddition?.terminationPrecaution,
                      otherCompanyScreenTransitionLinkDescription:
                        orderItemAddition?.otherCompanyScreenTransitionLinkDescription,
                      otherCompanyScreenTransitionLinkURL:
                        orderItemAddition?.otherCompanyScreenTransitionLinkURL,
                      quantityCategory: orderItemAddition?.quantityCategory,
                      totalPrice: orderItemAddition?.totalPrice,
                    },
                  };
                }
              ),
            },
          },
        };

        // Store values in sessionStorage
        setSessionStorageItem(
          sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
          orderRelatedInfoForApply,
          true
        );
      }
    }
  }, [createOrderRelatedInfoData, createOrderRelatedInfoStatus]);

  const [
    orderRelatedInfoForChangeItemOrPlan,
    setOrderRelatedInfoForChangeItemOrPlan,
  ] = useState({});

  useEffect(() => {
    if (parentPath === STRING_CHANGEPLAN || parentPath === STRING_CHANGEITEM) {
      if (createOrderRelatedInfoStatus === API_RESPONSE_STATUS.IDLE) {
        createOrderRelatedInfoRefresh();
      }

      if (createOrderRelatedInfoStatus === API_RESPONSE_STATUS.SUCCESS) {
        // @Model
        let accountData =
          createOrderRelatedInfoData?.orderRelatedInfo.accountInfo.account;
        let accountAdditionData =
          createOrderRelatedInfoData?.orderRelatedInfo.accountInfo
            .accountAddition;
        let orderData =
          createOrderRelatedInfoData?.orderRelatedInfo.orderInfo.order;
        let orderAdditionData =
          createOrderRelatedInfoData?.orderRelatedInfo.orderInfo.orderAddition;

        const changeItemOrPlanResponse = {
          orderRelatedInfo: {
            accountInfo: {
              account: {
                Id: accountData?.Id,
                CMAP_ContractName__c: accountData?.CMAP_ContractName__c,
                LastName: accountData?.LastName,
                FirstName: accountData?.FirstName,
                LastNameKana__pc: accountData?.LastNameKana__pc,
                FirstNameKana__pc: accountData?.FirstNameKana__pc,
                CMAP_Gender__c: accountData?.CMAP_Gender__c,
                PersonBirthdate: accountData?.PersonBirthdate,
                CMAP_CorporateGroupName__c:
                  accountData?.CMAP_CorporateGroupName__c,
                CMAP_DepartmentName__c: accountData?.CMAP_DepartmentName__c,
                CMAP_ContactPersonFamilyName__c:
                  accountData?.CMAP_ContactPersonFamilyName__c,
                CMAP_ContactPersonName__c:
                  accountData?.CMAP_ContactPersonName__c,
                CMAP_ContactPersonLastNameKana__pc:
                  accountData?.CMAP_ContactPersonLastNameKana__pc,
                CMAP_ContactPersonFirstNameKana__pc:
                  accountData?.CMAP_ContactPersonFirstNameKana__pc,
                CMAP_ContactPostalCode__c:
                  accountData?.CMAP_ContactPostalCode__c,
                CMAP_ContactPostalCodeLowerFour__c:
                  accountData?.CMAP_ContactPostalCodeLowerFour__c,
                CMAP_ContactState__c: accountData?.CMAP_ContactState__c,
                CMAP_ContactCity__c: accountData?.CMAP_ContactCity__c,
                CMAP_ContactHouseNumber__c:
                  accountData?.CMAP_ContactHouseNumber__c,
                CMAP_MansionName__c: accountData?.CMAP_MansionName__c,
                CMAP_RoomNo__c: accountData?.CMAP_RoomNo__c,
                CMAP_AreaCode__c: accountData?.CMAP_AreaCode__c,
                CMAP_LocalTelephoneNumber__c:
                  accountData?.CMAP_LocalTelephoneNumber__c,
                CMAP_SubscriberNumber__c: accountData?.CMAP_SubscriberNumber__c,
                CMAP_MobileNumber1__c: accountData?.CMAP_MobileNumber1__c,
                CMAP_MobileNumber2__c: accountData?.CMAP_MobileNumber2__c,
                CMAP_MobileNumber3__c: accountData?.CMAP_MobileNumber3__c,
                CMAP_MyCommufaID__c: accountData?.CMAP_MyCommufaID__c,
                CMAP_MultilingualReexplainCenter__c:
                  accountData?.CMAP_MultilingualReexplainCenter__c,
              },
              accountAddition: {
                isNewAccount: accountAdditionData?.isNewAccount,
                leadId: accountAdditionData?.leadId,
              },
            },
            orderInfo: {
              order: {
                AccountId: orderData?.AccountId,
                CMAP_ContractName__c: orderData?.CMAP_ContractName__c,
                CMAP_Case__c: orderData?.CMAP_Case__c,
                CMAP_Billing__c: orderData?.CMAP_Billing__c,
                CMAP_BillingPaymentMethodAgent__c:
                  orderData?.CMAP_BillingPaymentMethodAgent__c,
                CMAP_BillingPaymentMethodRegistered__c:
                  orderData?.CMAP_BillingPaymentMethodRegistered__c,
                CMAP_NeedsSendingDocumentOfBilling__c:
                  orderData?.CMAP_NeedsSendingDocumentOfBilling__c,
                CMAP_AcquisitionAgency__c: orderData?.CMAP_AcquisitionAgency__c,
                CMAP_AcquisitionShop__c: orderData?.CMAP_AcquisitionShop__c,
                CMAP_IndividualContractComplementary__c:
                  orderData?.CMAP_IndividualContractComplementary__c,
                CMAP_ContractDocDeliveryCategory__c:
                  orderData?.CMAP_ContractDocDeliveryCategory__c,
                CMAP_StandardProductOffer__c:
                  orderData?.CMAP_StandardProductOffer__c,
                CMAP_MovementStandardProductOffer__c:
                  orderData?.CMAP_MovementStandardProductOffer__c,
                CMAP_OrderCategory__c: orderCategory,
              },
              orderAddition: {
                orderChannelCategory: orderAdditionData?.orderChannelCategory,
              },
              orderItemInfos: createOrderRelatedInfoData?.orderRelatedInfo.orderInfo?.orderItemInfos?.map(
                (orderItemInfo) => {
                  const orderItem = orderItemInfo.orderItem;
                  const orderItemAddition = orderItemInfo.orderItemAddition;
                  return {
                    orderItem: {
                      CMAP_OfferPlanProduct__c:
                        orderItem?.CMAP_OfferPlanProduct__c,
                      CMAP_MainSubProductGroupId__c:
                        orderItem?.CMAP_MainSubProductGroupId__c,
                      CMAP_MainSubProductGroupCategory__c:
                        orderItem?.CMAP_MainSubProductGroupCategory__c,
                      CMAP_MainSubProductGroupName__c:
                        orderItem?.CMAP_MainSubProductGroupName__c,
                      CMAP_ProductCategoryId__c:
                        orderItem?.CMAP_ProductCategoryId__c,
                      CMAP_ProductCategoryName__c:
                        orderItem?.CMAP_ProductCategoryName__c,
                      Product2Id: orderItem?.Product2Id,
                      CMAP_ProductName__c: orderItem?.CMAP_ProductName__c,
                      UnitPrice: orderItem?.UnitPrice,
                      CMAP_ComsumptionTaxRate__c:
                        orderItem?.CMAP_ComsumptionTaxRate__c,
                      Quantity: orderItem?.Quantity,
                      CMAP_PriceCategory__c: orderItem?.CMAP_PriceCategory__c,
                      CMAP_InstallmentPaymentMonthly__c:
                        orderItem?.CMAP_InstallmentPaymentMonthly__c,
                      CMAP_InstallmentPayMonthlyPriceTaxExc__c:
                        orderItem?.CMAP_InstallmentPayMonthlyPriceTaxExc__c,
                      CMAP_NumberOfInstallments__c:
                        orderItem?.CMAP_NumberOfInstallments__c,
                      CMAP_InstallmentPayFirstMonthPriceTaxExc__c:
                        orderItem?.CMAP_InstallmentPayFirstMonthPriceTaxExc__c,
                      CMAP_InstallmentPaymentCurrently__c:
                        orderItem?.CMAP_InstallmentPaymentCurrently__c,
                      CMAP_SelectionAnnualPayment__c:
                        orderItem?.CMAP_SelectionAnnualPayment__c,
                      CMAP_AnnualPaymentPriceTaxExcluded__c:
                        orderItem?.CMAP_AnnualPaymentPriceTaxExcluded__c,
                      CMAP_Unchargeable__c: orderItem?.CMAP_Unchargeable__c,
                      CMAP_ProductSelectionState__c:
                        orderItem?.CMAP_ProductSelectionState__c,
                      CMAP_LastTimeChangeCategory__c:
                        orderItem?.CMAP_LastTimeChangeCategory__c,
                      CMAP_HasOrderItemAttribute__c:
                        orderItem?.CMAP_HasOrderItemAttribute__c,
                      CMAP_AvailableDailyPricing__c:
                        orderItem?.CMAP_AvailableDailyPricing__c,
                      CMAP_UnchangeableContract__c:
                        orderItem?.CMAP_UnchangeableContract__c,
                      CMAP_SetProduct__c: orderItem?.CMAP_SetProduct__c,
                      CMAP_HasPriceChanged__c:
                        orderItem?.CMAP_HasPriceChanged__c,
                      CMAP_TaxationCategory__c:
                        orderItem?.CMAP_TaxationCategory__c,
                      CMAP_RemainingDebtLumpSumPayment__c:
                        orderItem?.CMAP_RemainingDebtLumpSumPayment__c,
                      CMAP_ChangeSourceDestinationCategory__c:
                        orderItem?.CMAP_ChangeSourceDestinationCategory__c,
                      CMAP_InheritsMovementAssetAttribute__c:
                        orderItem?.CMAP_InheritsMovementAssetAttribute__c,
                      CMAP_OrderItemTypeSelection__c:
                        orderItem?.CMAP_OrderItemTypeSelection__c,
                      CMAP_OrderItemTypeBenefit__c:
                        orderItem?.CMAP_OrderItemTypeBenefit__c,
                      CMAP_OrderItemTypeAutomaticSelection__c:
                        orderItem?.CMAP_OrderItemTypeAutomaticSelection__c,
                      CMAP_BeforeOffer__c: orderItem?.CMAP_BeforeOffer__c,
                      CMAP_MainSubProGroupBenefitCPSortOrder__c:
                        orderItem?.CMAP_MainSubProGroupBenefitCPSortOrder__c,
                      CMAP_ProductCategoryBenefitSortOrder__c:
                        orderItem?.CMAP_ProductCategoryBenefitSortOrder__c,
                      CMAP_ProductSortOrder__c:
                        orderItem?.CMAP_ProductSortOrder__c,
                      CMAP_StandardProduct__c:
                        orderItem?.CMAP_StandardProduct__c,
                      CMAP_PredecessorOfferStartRequest__c:
                        orderItem?.CMAP_PredecessorOfferStartRequest__c,
                      CMAP_ExemptionPeriod__c:
                        orderItem?.CMAP_ExemptionPeriod__c,
                    },
                    orderItemAddition: {
                      precaution: orderItemAddition?.precaution,
                      tooltip: orderItemAddition?.tooltip,
                      cancellationPrecaution:
                        orderItemAddition?.cancellationPrecaution,
                      terminationPrecaution:
                        orderItemAddition?.terminationPrecaution,
                      otherCompanyScreenTransitionLinkDescription:
                        orderItemAddition?.otherCompanyScreenTransitionLinkDescription,
                      otherCompanyScreenTransitionLinkURL:
                        orderItemAddition?.otherCompanyScreenTransitionLinkURL,
                      quantityCategory: orderItemAddition?.quantityCategory,
                      totalPrice: orderItemAddition?.totalPrice,
                    },
                  };
                }
              ),
            },
          },
        };
        // Response
        setOrderRelatedInfoForChangeItemOrPlan(changeItemOrPlanResponse);

        // Store values in sessionStorage
        setSessionStorageItem(
          sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
          changeItemOrPlanResponse,
          true
        );
      }
    }
  }, [createOrderRelatedInfoData, createOrderRelatedInfoStatus]);

  /////////////////// UPDATE ORDER RELATED INFO
  // API call
  const updateOrderRelatedInfoURI = `${apiBaseURL}order/UpdateOrderRelatedInfo`;

  // @Model for UpdatedOrderRelatedInfo API's request body
  const accountParams =
    orderRelatedInfoForChangeItemOrPlan?.orderRelatedInfo?.accountInfo?.account;
  const accountAdditionParams =
    orderRelatedInfoForChangeItemOrPlan?.orderRelatedInfo?.accountInfo
      ?.accountAddition;
  const orderParams =
    orderRelatedInfoForChangeItemOrPlan?.orderRelatedInfo?.orderInfo?.order;
  const orderAdditionParams =
    orderRelatedInfoForChangeItemOrPlan?.orderRelatedInfo?.orderInfo
      ?.orderAddition;

  // Request body for UpdateOrderRelatedInfo API
  const updateOrderRelatedInfoRequest = {
    orderCategorySelected: orderParams?.CMAP_OrderCategory__c,
    orderRelatedInfo: {
      accountInfo: {
        account: {
          Id: accountParams?.Id,
          CMAP_ContractName__c: accountParams?.CMAP_ContractName__c,
          LastName: accountParams?.LastName,
          FirstName: accountParams?.FirstName,
          LastNameKana__pc: accountParams?.LastNameKana__pc,
          FirstNameKana__pc: accountParams?.FirstNameKana__pc,
          CMAP_Gender__c: accountParams?.CMAP_Gender__c,
          PersonBirthdate: accountParams?.PersonBirthdate,
          CMAP_CorporateGroupName__c: accountParams?.CMAP_CorporateGroupName__c,
          CMAP_DepartmentName__c: accountParams?.CMAP_DepartmentName__c,
          CMAP_ContactPersonFamilyName__c:
            accountParams?.CMAP_ContactPersonFamilyName__c,
          CMAP_ContactPersonName__c: accountParams?.CMAP_ContactPersonName__c,
          CMAP_ContactPersonLastNameKana__pc:
            accountParams?.CMAP_ContactPersonLastNameKana__pc,
          CMAP_ContactPersonFirstNameKana__pc:
            accountParams?.CMAP_ContactPersonFirstNameKana__pc,
          CMAP_ContactPostalCode__c: accountParams?.CMAP_ContactPostalCode__c,
          CMAP_ContactPostalCodeLowerFour__c:
            accountParams?.CMAP_ContactPostalCodeLowerFour__c,
          CMAP_ContactState__c: accountParams?.CMAP_ContactState__c,
          CMAP_ContactCity__c: accountParams?.CMAP_ContactCity__c,
          CMAP_ContactHouseNumber__c: accountParams?.CMAP_ContactHouseNumber__c,
          CMAP_MansionName__c: accountParams?.CMAP_MansionName__c,
          CMAP_RoomNo__c: accountParams?.CMAP_RoomNo__c,
          CMAP_AreaCode__c: accountParams?.CMAP_AreaCode__c,
          CMAP_LocalTelephoneNumber__c:
            accountParams?.CMAP_LocalTelephoneNumber__c,
          CMAP_SubscriberNumber__c: accountParams?.CMAP_SubscriberNumber__c,
          CMAP_MobileNumber1__c: accountParams?.CMAP_MobileNumber1__c,
          CMAP_MobileNumber2__c: accountParams?.CMAP_MobileNumber2__c,
          CMAP_MobileNumber3__c: accountParams?.CMAP_MobileNumber3__c,
          CMAP_MyCommufaID__c: accountParams?.CMAP_MyCommufaID__c,
          CMAP_MultilingualReexplainCenter__c:
            accountParams?.CMAP_MultilingualReexplainCenter__c,
        },
        accountAddition: { isNewAccount: accountAdditionParams?.isNewAccount },
      },
      orderInfo: {
        order: {
          AccountId: orderParams?.AccountId,
          CMAP_ContractName__c: orderParams?.CMAP_ContractName__c,
          CMAP_Billing__c: orderParams?.CMAP_Billing__c,
          CMAP_BillingPaymentMethodAgent__c:
            orderParams?.CMAP_BillingPaymentMethodAgent__c,
          CMAP_BillingPaymentMethodRegistered__c:
            orderParams?.CMAP_BillingPaymentMethodRegistered__c,
          CMAP_NeedsSendingDocumentOfBilling__c:
            orderParams?.CMAP_NeedsSendingDocumentOfBilling__c,
          CMAP_AcquisitionAgency__c: orderParams?.CMAP_AcquisitionAgency__c,
          CMAP_AcquisitionShop__c: orderParams?.CMAP_AcquisitionShop__c,
          CMAP_IndividualContractComplementary__c:
            orderParams?.CMAP_IndividualContractComplementary__c,
          CMAP_ContractDocDeliveryCategory__c:
            orderParams?.CMAP_ContractDocDeliveryCategory__c,
          CMAP_MovementStandardProductOffer__c:
            orderParams?.CMAP_MovementStandardProductOffer__c,
        },
        orderAddition: {
          orderChannelCategory: orderAdditionParams?.orderChannelCategory,
        },
        orderItemInfos: orderRelatedInfoForChangeItemOrPlan.orderInfo?.orderItemInfos?.map(
          (orderItemInfo) => {
            const orderItemParams = orderItemInfo.orderItem;
            const orderItemAdditionParams = orderItemInfo.orderItemAddition;
            return {
              orderItem: {
                CMAP_OfferPlanProduct__c:
                  orderItemParams?.CMAP_OfferPlanProduct__c,
                CMAP_MainSubProductGroupId__c:
                  orderItemParams?.CMAP_MainSubProductGroupId__c,
                CMAP_MainSubProductGroupCategory__c:
                  orderItemParams?.CMAP_MainSubProductGroupCategory__c,
                CMAP_MainSubProductGroupName__c:
                  orderItemParams?.CMAP_MainSubProductGroupName__c,
                CMAP_ProductCategoryId__c:
                  orderItemParams?.CMAP_ProductCategoryId__c,
                CMAP_ProductCategoryName__c:
                  orderItemParams?.CMAP_ProductCategoryName__c,
                Product2Id: orderItemParams?.Product2Id,
                CMAP_ProductName__c: orderItemParams?.CMAP_ProductName__c,
                UnitPrice: orderItemParams?.UnitPrice,
                CMAP_ComsumptionTaxRate__c:
                  orderItemParams?.CMAP_ComsumptionTaxRate__c,
                Quantity: orderItemParams?.Quantity,
                CMAP_PriceCategory__c: orderItemParams?.CMAP_PriceCategory__c,
                CMAP_InstallmentPaymentMonthly__c:
                  orderItemParams?.CMAP_InstallmentPaymentMonthly__c,
                CMAP_InstallmentPayMonthlyPriceTaxExc__c:
                  orderItemParams?.CMAP_InstallmentPayMonthlyPriceTaxExc__c,
                CMAP_NumberOfInstallments__c:
                  orderItemParams?.CMAP_NumberOfInstallments__c,
                CMAP_InstallmentPayFirstMonthPriceTaxExc__c:
                  orderItemParams?.CMAP_InstallmentPayFirstMonthPriceTaxExc__c,
                CMAP_InstallmentPaymentCurrently__c:
                  orderItemParams?.CMAP_InstallmentPaymentCurrently__c,
                CMAP_SelectionAnnualPayment__c:
                  orderItemParams?.CMAP_SelectionAnnualPayment__c,
                CMAP_AnnualPaymentPriceTaxExcluded__c:
                  orderItemParams?.CMAP_AnnualPaymentPriceTaxExcluded__c,
                CMAP_Unchargeable__c: orderItemParams?.CMAP_Unchargeable__c,
                CMAP_ProductSelectionState__c:
                  orderItemParams?.CMAP_ProductSelectionState__c,
                CMAP_LastTimeChangeCategory__c:
                  orderItemParams?.CMAP_LastTimeChangeCategory__c,
                CMAP_HasOrderItemAttribute__c:
                  orderItemParams?.CMAP_HasOrderItemAttribute__c,
                CMAP_AvailableDailyPricing__c:
                  orderItemParams?.CMAP_AvailableDailyPricing__c,
                CMAP_UnchangeableContract__c:
                  orderItemParams?.CMAP_UnchangeableContract__c,
                CMAP_SetProduct__c: orderItemParams?.CMAP_SetProduct__c,
                CMAP_HasPriceChanged__c:
                  orderItemParams?.CMAP_HasPriceChanged__c,
                CMAP_TaxationCategory__c:
                  orderItemParams?.CMAP_TaxationCategory__c,
                CMAP_RemainingDebtLumpSumPayment__c:
                  orderItemParams?.CMAP_RemainingDebtLumpSumPayment__c,
                CMAP_ChangeSourceDestinationCategory__c:
                  orderItemParams?.CMAP_ChangeSourceDestinationCategory__c,
                CMAP_InheritsMovementAssetAttribute__c:
                  orderItemParams?.CMAP_InheritsMovementAssetAttribute__c,
                CMAP_OrderItemTypeSelection__c:
                  orderItemParams?.CMAP_OrderItemTypeSelection__c,
                CMAP_OrderItemTypeBenefit__c:
                  orderItemParams?.CMAP_OrderItemTypeBenefit__c,
                CMAP_OrderItemTypeAutomaticSelection__c:
                  orderItemParams?.CMAP_OrderItemTypeAutomaticSelection__c,
                CMAP_BeforeOffer__c: orderItemParams?.CMAP_BeforeOffer__c,
                CMAP_MainSubProGroupBenefitCPSortOrder__c:
                  orderItemParams?.CMAP_MainSubProGroupBenefitCPSortOrder__c,
                CMAP_ProductCategoryBenefitSortOrder__c:
                  orderItemParams?.CMAP_ProductCategoryBenefitSortOrder__c,
                CMAP_ProductSortOrder__c:
                  orderItemParams?.CMAP_ProductSortOrder__c,
                CMAP_StandardProduct__c:
                  orderItemParams?.CMAP_StandardProduct__c,
                CMAP_PredecessorOfferStartRequest__c:
                  orderItemParams?.CMAP_PredecessorOfferStartRequest__c,
                CMAP_ExemptionPeriod__c:
                  orderItemParams?.CMAP_ExemptionPeriod__c,
              },
              orderItemAddition: {
                precaution: orderItemAdditionParams?.precaution,
                tooltip: orderItemAdditionParams?.tooltip,
                cancellationPrecaution:
                  orderItemAdditionParams?.cancellationPrecaution,
                terminationPrecaution:
                  orderItemAdditionParams?.terminationPrecaution,
                otherCompanyScreenTransitionLinkDescription:
                  orderItemAdditionParams?.otherCompanyScreenTransitionLinkDescription,
                otherCompanyScreenTransitionLinkURL:
                  orderItemAdditionParams?.otherCompanyScreenTransitionLinkURL,
                quantityCategory: orderItemAdditionParams?.quantityCategory,
                totalPrice: orderItemAdditionParams?.totalPrice,
              },
            };
          }
        ),
      },
    },
  };

  const {
    data: updateOrderRelatedInfoData,
    status: updateOrderRelatedInfoStatus,
    isLoading: updateOrderRelatedInfoIsLoading,
    refetch: updateOrderRelatedInfoRefresh,
  } = useCustomQuery(
    updateOrderRelatedInfoURI,
    false,
    STRING_POST,
    updateOrderRelatedInfoRequest
  );

  const isApiLoading =
    updateOrderRelatedInfoIsLoading || createOrderRelatedInfoIsLoading;
  props.setIsLoading(isApiLoading);

  useEffect(() => {
    if (
      createOrderRelatedInfoStatus === API_RESPONSE_STATUS.SUCCESS &&
      updateOrderRelatedInfoRequest?.orderCategorySelected
    ) {
      if (updateOrderRelatedInfoStatus === API_RESPONSE_STATUS.IDLE) {
        updateOrderRelatedInfoRefresh();
      }
    }
  }, [
    createOrderRelatedInfoStatus,
    updateOrderRelatedInfoRequest,
    updateOrderRelatedInfoStatus,
  ]);

  useEffect(() => {
    if (updateOrderRelatedInfoStatus === API_RESPONSE_STATUS.SUCCESS) {
      // @Model
      const account =
        updateOrderRelatedInfoData?.orderRelatedInfo?.accountInfo?.account;
      const accountAddition =
        updateOrderRelatedInfoData?.orderRelatedInfo?.accountInfo
          ?.accountAddition;
      const order =
        updateOrderRelatedInfoData?.orderRelatedInfo?.orderInfo?.order;
      const orderAddition =
        updateOrderRelatedInfoData?.orderRelatedInfo?.orderInfo?.orderAddition;
      const orderImportantMatterDescriptions =
        updateOrderRelatedInfoData?.orderRelatedInfo?.orderInfo
          ?.orderImportantMatterDescriptions;
      const orderItemInfos =
        updateOrderRelatedInfoData?.orderRelatedInfo?.orderInfo?.orderItemInfos;

      // TODO: Will create new task to modify the variables
      // Response
      const updateOrderRelatedInfo = {
        orderRelatedInfo: {
          accountInfo: {
            account: {
              Id: account?.Id,
              CMAP_ContractName__c: account?.CMAP_ContractName__c,
              LastName: account?.LastName,
              FirstName: account?.FirstName,
              LastNameKana__pc: account?.LastNameKana__pc,
              FirstNameKana__pc: account?.FirstNameKana__pc,
              CMAP_Gender__c: account?.CMAP_Gender__c,
              PersonBirthdate: account?.PersonBirthdate,
              CMAP_CorporateGroupName__c: account?.CMAP_CorporateGroupName__c,
              CMAP_DepartmentName__c: account?.CMAP_DepartmentName__c,
              CMAP_ContactPersonFamilyName__c:
                account?.CMAP_ContactPersonFamilyName__c,
              CMAP_ContactPersonName__c: account?.CMAP_ContactPersonName__c,
              CMAP_ContactPersonLastNameKana__pc:
                account?.CMAP_ContactPersonLastNameKana__pc,
              CMAP_ContactPersonFirstNameKana__pc:
                account?.CMAP_ContactPersonFirstNameKana__pc,
              CMAP_ContactPostalCode__c: account?.CMAP_ContactPostalCode__c,
              CMAP_ContactPostalCodeLowerFour__c:
                account?.CMAP_ContactPostalCodeLowerFour__c,
              CMAP_ContactState__c: account?.CMAP_ContactState__c,
              CMAP_ContactCity__c: account?.CMAP_ContactCity__c,
              CMAP_ContactHouseNumber__c: account?.CMAP_ContactHouseNumber__c,
              CMAP_MansionName__c: account?.CMAP_MansionName__c,
              CMAP_RoomNo__c: account?.CMAP_RoomNo__c,
              CMAP_AreaCode__c: account?.CMAP_AreaCode__c,
              CMAP_LocalTelephoneNumber__c:
                account?.CMAP_LocalTelephoneNumber__c,
              CMAP_SubscriberNumber__c: account?.CMAP_SubscriberNumber__c,
              CMAP_MobileNumber1__c: account?.CMAP_MobileNumber1__c,
              CMAP_MobileNumber2__c: account?.CMAP_MobileNumber2__c,
              CMAP_MobileNumber3__c: account?.CMAP_MobileNumber3__c,
              CMAP_MyCommufaID__c: account?.CMAP_MyCommufaID__c,
              CMAP_MultilingualReexplainCenter__c:
                account?.CMAP_MultilingualReexplainCenter__c,
            },
            accountAddition: {
              isNewAccount: accountAddition?.isNewAccount,
            },
          },
          orderInfo: {
            order: {
              CMAP_OrderBeforeChange__c: order?.CMAP_OrderBeforeChange__c,
              OpportunityId: order?.OpportunityId,
              AccountId: order?.AccountId,
              CMAP_ContractName__c: order?.CMAP_ContractName__c,
              CMAP_ResidenceType__c: order?.CMAP_ResidenceType__c,
              CMAP_BuildingCategory__c: order?.CMAP_BuildingCategory__c,
              CMAP_OfferPlan__c: order?.CMAP_OfferPlan__c,
              CMAP_OfferPlanName__c: order?.CMAP_OfferPlanName__c,
              CMAP_OfferPackageId__c: order?.CMAP_OfferPackageId__c,
              CMAP_IsTypeCircuit__c: order?.CMAP_IsTypeCircuit__c,
              CMAP_IsTypeCircuitWireless__c:
                order?.CMAP_IsTypeCircuitWireless__c,
              CMAP_IsTypeCircuitTypeJ__c: order?.CMAP_IsTypeCircuitTypeJ__c,
              CMAP_IsTypeNotCircuit__c: order?.CMAP_IsTypeNotCircuit__c,
              CMAP_IsTypeGoodsServiceSales__c:
                order?.CMAP_IsTypeGoodsServiceSales__c,
              CMAP_InstallationLocationOfferArea__c:
                order?.CMAP_InstallationLocationOfferArea__c,
              CMAP_InstallLocLayerDeliveryDuration__c:
                order?.CMAP_InstallLocLayerDeliveryDuration__c,
              CMAP_OfferMethod__c: order?.CMAP_OfferMethod__c,
              CMAP_OfferMethodNameForDisplay__c:
                order?.CMAP_OfferMethodNameForDisplay__c,
              CMAP_Property__c: order?.CMAP_Property__c,
              CMAP_ApartmentPropertyName__c:
                order?.CMAP_ApartmentPropertyName__c,
              CMAP_PropertyAddress__c: order?.CMAP_PropertyAddress__c,
              CMAP_PropertyAddressCode11__c:
                order?.CMAP_PropertyAddressCode11__c,
              CMAP_PropertyCity__c: order?.CMAP_PropertyCity__c,
              CMAP_PropertyOfferMethod__c: order?.CMAP_PropertyOfferMethod__c,
              CMAP_PropertyOfferMethodName__c:
                order?.CMAP_PropertyOfferMethodName__c,
              CMAP_Billing__c: order?.CMAP_Billing__c,
              CMAP_BillingPaymentMethodAgent__c:
                order?.CMAP_BillingPaymentMethodAgent__c,
              CMAP_BillingPaymentMethodRegistered__c:
                order?.CMAP_BillingPaymentMethodRegistered__c,
              CMAP_NeedsSendingDocumentOfBilling__c:
                order?.CMAP_NeedsSendingDocumentOfBilling__c,
              CMAP_AcquisitionAgency__c: order?.CMAP_AcquisitionAgency__c,
              CMAP_AcquisitionShop__c: order?.CMAP_AcquisitionShop__c,
              CMAP_IndividualContractPlan__c:
                order?.CMAP_IndividualContractPlan__c,
              ShippingStreet: order?.ShippingStreet,
              ShippingCity: order?.ShippingCity,
              ShippingState: order?.ShippingState,
              ShippingPostalCode: order?.ShippingPostalCode,
              ShippingLatitude: order?.ShippingLatitude,
              ShippingLongitude: order?.ShippingLongitude,
              CMAP_InstallLocBlockNumberGroupUnit__c:
                order?.CMAP_InstallLocBlockNumberGroupUnit__c,
              CMAP_InstallationLocationPrefectureCode__c:
                order?.CMAP_InstallationLocationPrefectureCode__c,
              CMAP_InstallationLocationCityCode__c:
                order?.CMAP_InstallationLocationCityCode__c,
              CMAP_InstallationLocationAddressCode11__c:
                order?.CMAP_InstallationLocationAddressCode11__c,
              CMAP_InstallationLocationAddressCode__c:
                order?.CMAP_InstallationLocationAddressCode__c,
              CMAP_InstallationLocationCity__c:
                order?.CMAP_InstallationLocationCity__c,
              CMAP_InstallationLocationHouseNumber__c:
                order?.CMAP_InstallationLocationHouseNumber__c,
              CMAP_InstallationLocationRoomNumber__c:
                order?.CMAP_InstallationLocationRoomNumber__c,
              CMAP_InstallationLocationExcludeRoom__c:
                order?.CMAP_InstallationLocationExcludeRoom__c,
              CMAP_InstallationLocationAddress__c:
                order?.CMAP_InstallationLocationAddress__c,
              CMAP_ContractStatus__c: order?.CMAP_ContractStatus__c,
              CMAP_DeliveryServiceAddress__PostalCode__s:
                order?.CMAP_DeliveryServiceAddress__PostalCode__s,
              CMAP_DeliveryServiceAddress__StateCode__s:
                order?.CMAP_DeliveryServiceAddress__StateCode__s,
              CMAP_DeliveryServiceAddress__City__s:
                order?.CMAP_DeliveryServiceAddress__City__s,
              CMAP_DeliveryServiceAddress__Street__s:
                order?.CMAP_DeliveryServiceAddress__Street__s,
              CMAP_DeliveryServiceAddressBuildingName__c:
                order?.CMAP_DeliveryServiceAddressBuildingName__c,
              CMAP_DeliveryServiceAddressRoomNumber__c:
                order?.CMAP_DeliveryServiceAddressRoomNumber__c,
              CMAP_DeliveryServiceAddressForDisplay__c:
                order?.CMAP_DeliveryServiceAddressForDisplay__c,
              CMAP_ExistsMyCommufaId__c: order?.CMAP_ExistsMyCommufaId__c,
              CMAP_NeedsApproval__c: order?.CMAP_NeedsApproval__c,
              CMAP_ChangeSourceOfferPlan__c:
                order?.CMAP_ChangeSourceOfferPlan__c,
              CMAP_ChangeSourceOpportunity__c:
                order?.CMAP_ChangeSourceOpportunity__c,
              CMAP_ChangeSourceIndividualContractPur__c:
                order?.CMAP_ChangeSourceIndividualContractPur__c,
              CMAP_ChangeSourceOfferPlanName__c:
                order?.CMAP_ChangeSourceOfferPlanName__c,
              CMAP_ChangeSourceTypeCircuit__c:
                order?.CMAP_ChangeSourceTypeCircuit__c,
              CMAP_ChangeSourceTypeCircuitWireless__c:
                order?.CMAP_ChangeSourceTypeCircuitWireless__c,
              CMAP_ChangeSourceTypeCircuitTypeJ__c:
                order?.CMAP_ChangeSourceTypeCircuitTypeJ__c,
              CMAP_ChangeSourceTypeNotCircuit__c:
                order?.CMAP_ChangeSourceTypeNotCircuit__c,
              CMAP_ChangeSourceCase__c: order?.CMAP_ChangeSourceCase__c,
              CMAP_ChangeSourceResidenceType__c:
                order?.CMAP_ChangeSourceResidenceType__c,
              CMAP_ChangeSourceBuildingCategory__c:
                order?.CMAP_ChangeSourceBuildingCategory__c,
              CMAP_ChangeSourceInstallationLocAddr__c:
                order?.CMAP_ChangeSourceInstallationLocAddr__c,
              CMAP_ChangeSourceDeliverySrvAddrForDisp__c:
                order?.CMAP_ChangeSourceDeliverySrvAddrForDisp__c,
              CMAP_CityId__c: order?.CMAP_CityId__c,
              CMAP_Rebuilding__c: order?.CMAP_Rebuilding__c,
              CMAP_PrefectureCityCode__c: order?.CMAP_PrefectureCityCode__c,
              CMAP_IndividualContractComplementary__c:
                order?.CMAP_IndividualContractComplementary__c,
              CMAP_BeforeOffer__c: order?.CMAP_BeforeOffer__c,
              CMAP_ContractDocDeliveryCategory__c:
                order?.CMAP_ContractDocDeliveryCategory__c,
              CMAP_MovementStandardProductOffer__c:
                order?.CMAP_MovementStandardProductOffer__c,
              CMAP_OrderCategory__c: orderCategory,
              CMAP_ClientId__c: gaCookieClientId,
            },
            orderAddition: {
              orderCategoryPreviouslySelected:
                orderAddition?.orderCategoryPreviouslySelected,
              orderChannelCategory: orderAddition?.orderChannelCategory,
              planType: orderAddition?.planType,
              previousLastModifiedDate: orderAddition?.previousLastModifiedDate,
              orderCategoryCurrentlySelected: orderCategory,
            },
            orderImportantMatterDescriptions: orderImportantMatterDescriptions?.map(
              (orderImportantMatterDescription) => {
                return {
                  CMAP_ImportantMatterDescription__c:
                    orderImportantMatterDescription?.CMAP_ImportantMatterDescription__c,
                  CMAP_ProductGroup__c:
                    orderImportantMatterDescription?.CMAP_ProductGroup__c,
                  CMAP_SetParentProduct__c:
                    orderImportantMatterDescription?.CMAP_SetParentProduct__c,
                  CMAP_ConsentDateTime__c:
                    orderImportantMatterDescription?.CMAP_ConsentDateTime__c,
                  CMAP_ImportantMatterDescriptionConsent__c:
                    orderImportantMatterDescription?.CMAP_ImportantMatterDescriptionConsent__c,
                };
              }
            ),
            orderItemInfos: orderItemInfos?.map((orderItemInfo) => {
              const orderItem = orderItemInfo.orderItem;
              const orderItemAddition = orderItemInfo.orderItemAddition;
              const orderItemAttributes = orderItemInfo.orderItemAttributes;
              return {
                orderItem: {
                  CMAP_OfferPlanProduct__c: orderItem?.CMAP_OfferPlanProduct__c,
                  CMAP_MainSubProductGroupId__c:
                    orderItem?.CMAP_MainSubProductGroupId__c,
                  CMAP_MainSubProductGroupCategory__c:
                    orderItem?.CMAP_MainSubProductGroupCategory__c,
                  CMAP_MainSubProductGroupName__c:
                    orderItem?.CMAP_MainSubProductGroupName__c,
                  CMAP_ProductCategoryId__c:
                    orderItem?.CMAP_ProductCategoryId__c,
                  CMAP_ProductCategoryName__c:
                    orderItem?.CMAP_ProductCategoryName__c,
                  Product2Id: orderItem?.Product2Id,
                  CMAP_ProductName__c: orderItem?.CMAP_ProductName__c,
                  UnitPrice: orderItem?.UnitPrice,
                  CMAP_UnitPriceTaxIncluded__c:
                    orderItem?.CMAP_UnitPriceTaxIncluded__c,
                  CMAP_ComsumptionTaxRate__c:
                    orderItem?.CMAP_ComsumptionTaxRate__c,
                  Quantity: orderItem?.Quantity,
                  CMAP_PriceCategory__c: orderItem?.CMAP_PriceCategory__c,
                  CMAP_InstallmentPaymentMonthly__c:
                    orderItem?.CMAP_InstallmentPaymentMonthly__c,
                  CMAP_InstallmentPayMonthlyPriceTaxExc__c:
                    orderItem?.CMAP_InstallmentPayMonthlyPriceTaxExc__c,
                  CMAP_InstallmentPayMonthlyPriceTaxInc__c:
                    orderItem?.CMAP_InstallmentPayMonthlyPriceTaxInc__c,
                  CMAP_NumberOfInstallments__c:
                    orderItem?.CMAP_NumberOfInstallments__c,
                  CMAP_InstallmentPayFirstMonthPriceTaxExc__c:
                    orderItem?.CMAP_InstallmentPayFirstMonthPriceTaxExc__c,
                  CMAP_InstallmentPayFirstMonthPriceTaxInc__c:
                    orderItem?.CMAP_InstallmentPayFirstMonthPriceTaxInc__c,
                  CMAP_InstallmentPaymentCurrently__c:
                    orderItem?.CMAP_InstallmentPaymentCurrently__c,
                  CMAP_SelectionAnnualPayment__c:
                    orderItem?.CMAP_SelectionAnnualPayment__c,
                  CMAP_AnnualPaymentPriceTaxExcluded__c:
                    orderItem?.CMAP_AnnualPaymentPriceTaxExcluded__c,
                  CMAP_AnnualPaymentPriceTaxIncluded__c:
                    orderItem?.CMAP_AnnualPaymentPriceTaxIncluded__c,
                  CMAP_SetParentProductId__c:
                    orderItem?.CMAP_SetParentProductId__c,
                  CMAP_Unchargeable__c: orderItem?.CMAP_Unchargeable__c,
                  CMAP_Asset__c: orderItem?.CMAP_Asset__c,
                  CMAP_ProductSelectionState__c:
                    orderItem?.CMAP_ProductSelectionState__c,
                  CMAP_LastTimeChangeCategory__c:
                    orderItem?.CMAP_LastTimeChangeCategory__c,
                  CMAP_HasOrderItemAttribute__c:
                    orderItem?.CMAP_HasOrderItemAttribute__c,
                  CMAP_AvailableDailyPricing__c:
                    orderItem?.CMAP_AvailableDailyPricing__c,
                  CMAP_UnchangeableContract__c:
                    orderItem?.CMAP_UnchangeableContract__c,
                  CMAP_SetProduct__c: orderItem?.CMAP_SetProduct__c,
                  CMAP_BenefitProduct__c: orderItem?.CMAP_BenefitProduct__c,
                  CMAP_BenefitId__c: orderItem?.CMAP_BenefitId__c,
                  CMAP_BenefitName__c: orderItem?.CMAP_BenefitName__c,
                  CMAP_BenefitCampaignId__c:
                    orderItem?.CMAP_BenefitCampaignId__c,
                  CMAP_BenefitCampaignName__c:
                    orderItem?.CMAP_BenefitCampaignName__c,
                  CMAP_BenefitGroupNumber__c:
                    orderItem?.CMAP_BenefitGroupNumber__c,
                  CMAP_GrantUnitPrice__c: orderItem?.CMAP_GrantUnitPrice__c,
                  CMAP_GrantPoint__c: orderItem?.CMAP_GrantPoint__c,
                  CMAP_ChangeableBenefit__c:
                    orderItem?.CMAP_ChangeableBenefit__c,
                  CMAP_HasPriceChanged__c: orderItem?.CMAP_HasPriceChanged__c,
                  CMAP_OrderAutomaticSelectionProduct__c:
                    orderItem?.CMAP_OrderAutomaticSelectionProduct__c,
                  CMAP_TaxationCategory__c: orderItem?.CMAP_TaxationCategory__c,
                  CMAP_NumberOfTimesPaid__c:
                    orderItem?.CMAP_NumberOfTimesPaid__c,
                  CMAP_RemainingDebtTaxExcluded__c:
                    orderItem?.CMAP_RemainingDebtTaxExcluded__c,
                  CMAP_RemainingDebtLumpSumPayment__c:
                    orderItem?.CMAP_RemainingDebtLumpSumPayment__c,
                  CMAP_OfferStartDate__c: orderItem?.CMAP_OfferStartDate__c,
                  CMAP_ChangeSourceDestinationCategory__c:
                    orderItem?.CMAP_ChangeSourceDestinationCategory__c,
                  CMAP_InheritsMovementAssetAttribute__c:
                    orderItem?.CMAP_InheritsMovementAssetAttribute__c,
                  CMAP_OrderItemBeforeChange__c:
                    orderItem?.CMAP_OrderItemBeforeChange__c,
                  CMAP_OrderItemTypeSelection__c:
                    orderItem?.CMAP_OrderItemTypeSelection__c,
                  CMAP_OrderItemTypeBenefit__c:
                    orderItem?.CMAP_OrderItemTypeBenefit__c,
                  CMAP_OrderItemTypeAutomaticSelection__c:
                    orderItem?.CMAP_OrderItemTypeAutomaticSelection__c,
                  CMAP_BeforeOffer__c: orderItem?.CMAP_BeforeOffer__c,
                  CMAP_MainSubProGroupBenefitCPSortOrder__c:
                    orderItem?.CMAP_MainSubProGroupBenefitCPSortOrder__c,
                  CMAP_ProductCategoryBenefitSortOrder__c:
                    orderItem?.CMAP_ProductCategoryBenefitSortOrder__c,
                  CMAP_ProductSortOrder__c: orderItem?.CMAP_ProductSortOrder__c,
                  CMAP_QuantityValueChange__c:
                    orderItem?.CMAP_QuantityValueChange__c,
                  CMAP_StandardProduct__c: orderItem?.CMAP_StandardProduct__c,
                  CMAP_PredecessorOfferStartRequest__c:
                    orderItem?.CMAP_PredecessorOfferStartRequest__c,
                  CMAP_ExemptionPeriod__c: orderItem?.CMAP_ExemptionPeriod__c,
                },
                orderItemAddition: {
                  precaution: orderItemAddition?.precaution,
                  tooltip: orderItemAddition?.tooltip,
                  cancellationPrecaution:
                    orderItemAddition?.cancellationPrecaution,
                  terminationPrecaution:
                    orderItemAddition?.terminationPrecaution,
                  otherCompanyScreenTransitionLinkDescription:
                    orderItemAddition?.otherCompanyScreenTransitionLinkDescription,
                  otherCompanyScreenTransitionLinkURL:
                    orderItemAddition?.otherCompanyScreenTransitionLinkURL,
                  quantityCategory: orderItemAddition?.quantityCategory,
                  previousLastModifiedDate:
                    orderItemAddition?.previousLastModifiedDate,
                  totalPrice: orderItemAddition?.totalPrice,
                },
                orderItemAttributes: orderItemAttributes?.map(
                  (orderItemAttribute) => {
                    return {
                      CMAP_OrderItemAttributeDefenition__c:
                        orderItemAttribute?.CMAP_OrderItemAttributeDefenition__c,
                      CMAP_Value__c: orderItemAttribute?.CMAP_Value__c,
                      CMAP_ValueLongTextArea__c:
                        orderItemAttribute?.CMAP_ValueLongTextArea__c,
                      CMAP_ValueChanged__c:
                        orderItemAttribute?.CMAP_ValueChanged__c,
                      CMAP_AssetAttribute__c:
                        orderItemAttribute?.CMAP_AssetAttribute__c,
                    };
                  }
                ),
              };
            }),
          },
        },
      };

      // Store values in sessionStorage
      setSessionStorageItem(
        sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
        updateOrderRelatedInfo,
        true
      );

      setSessionStorageItem(
        sessionKeys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO,
        updateOrderRelatedInfo,
        true
      );
    }
  }, [updateOrderRelatedInfoData, updateOrderRelatedInfoStatus]);

  useEffect(() => {
    if (
      (parentPath !== STRING_APPLY &&
        parentPath !== STRING_CHANGEITEM &&
        parentPath !== STRING_CHANGEPLAN) ||
      (parentPath === STRING_APPLY &&
        createOrderRelatedInfoStatus === API_RESPONSE_STATUS.SUCCESS) ||
      ((parentPath === STRING_CHANGEITEM || parentPath === STRING_CHANGEPLAN) &&
        createOrderRelatedInfoStatus === API_RESPONSE_STATUS.SUCCESS &&
        updateOrderRelatedInfoStatus === API_RESPONSE_STATUS.SUCCESS)
    ) {
      const nextPageLink = pageContext["CustomerAssignment-NextPageLink"];
      redirectToLinkUrl(nextPageLink);
    }
  }, [parentPath, createOrderRelatedInfoStatus, updateOrderRelatedInfoStatus]);

  return <></>;
};

export default withSitecoreContext()(CPadCustomerAssignmentLayout);
