import React, {
	useEffect,
	useState
} from "react";
import {
	Placeholder
} from "@sitecore-jss/sitecore-jss-react";
import moment from "moment";
import {
	recoilKeys as keys
} from "../../assets/recoilKeys";
import {
	getScDataFromPlaceholder,
	getScDataFromComponent,
	addDataRowList,
} from "../../utils/commonUtils";
import {
	STRING_NOT_CIRCUIT,
	STRING_GOODS_SERVICE_SALES,
	STRING_INDIVIDUAL,
	STRING_CORPORATION,
	STRING_CHANGEITEM,
	STRING_RELOCATE,
	STRING_CHANGEPLAN,
	STRING_APPLY,
	STRING_MOVING,
	STRING_WIRELESS_CIRCUIT,
	STRING_SIMULATOR,
	STRING_REBUILDING,
} from "../../utils/constantVariables";
import {
	getSessionStorageItem
} from "../../utils/useSessionStorage";
export function UserInfoDetailConfirmation(props) {
	const {
		sitecoreContext,
		orderCategory,
		orderRelatedInfo,
		urlParentName
	} = props;
	const pageContext = sitecoreContext.route.fields;

	const order = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order;
	const account = orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account;
	const productChangeAddRequestDate = order?.CMAP_ProductChangeAddRequestDate__c;
	const planChangeRequestDate = order?.CMAP_PlanChangeRequestDate__c;
	const updateAddRequestDate = order?.CMAP_UpdateAddRequestDate__c;
	const productChangeLastUtilizationDate = order?.CMAP_ProductChangeLastUtilizationDate__c;
	const updateLastUtilizationDate = order?.CMAP_UpdateLastUtilizationDate__c;
	const changeSourceLastUtilizationDate = order?.CMAP_ChangeSourceLastUtilizationDate__c;
	const isTypeCircuit = order?.CMAP_IsTypeCircuit__c;
	const buildingCategory = order?.CMAP_BuildingCategory__c;
	const shippingPostalCode = order?.ShippingPostalCode;
	const shippingState = order?.ShippingState;
	const shippingCity = order?.ShippingCity;
	const shippingStreet = order?.ShippingStreet;
	const installationLocationHouseNumber = order?.CMAP_InstallationLocationHouseNumber__c;
	const installationLocationRoomNumber = order?.CMAP_InstallationLocationRoomNumber__c;
	const deliveryServiceAddressPostalCode = order?.CMAP_DeliveryServiceAddress__PostalCode__s;
	const deliveryServiceAddressStateCode = order?.CMAP_DeliveryServiceAddress__StateCode__s;
	const deliveryServiceAddressCity = order?.CMAP_DeliveryServiceAddress__City__s;
	const deliveryServiceAddressStreet = order?.CMAP_DeliveryServiceAddress__Street__s;
	const deliveryServiceAddressBuildingName = order?.CMAP_DeliveryServiceAddressBuildingName__c;
	const deliveryServiceAddressRoomNumber = order?.CMAP_DeliveryServiceAddressRoomNumber__c;
	const individualContractComplementary = order?.CMAP_IndividualContractComplementary__c;
	const startRequestDate = order?.CMAP_StartRequestDate__c;
	const contractName = account?.CMAP_ContractName__c;
	const lastName = account?.LastName;
	const firstName = account?.FirstName;
	const firstNameKana = account?.FirstNameKana__pc;
	const lastNameKana = account?.LastNameKana__pc;
	const gender = account?.CMAP_Gender__c;
	const personBirthDate = account?.PersonBirthdate;
	const corporateGroupName = account?.CMAP_CorporateGroupName__c;
	const departmentName = account?.CMAP_DepartmentName__c;
	const contactPersonFamilyName = account?.CMAP_ContactPersonFamilyName__c;
	const contactPersonName = account?.CMAP_ContactPersonName__c;
	const contactPersonLastNameKana = account?.CMAP_ContactPersonLastNameKana__pc;
	const contactPersonFirstNameKana = account?.CMAP_ContactPersonFirstNameKana__pc;
	const contactPostalCode = account?.CMAP_ContactPostalCode__c;
	const contactPostalCodeLowerFour = account?.CMAP_ContactPostalCodeLowerFour__c;
	const contactState = account?.CMAP_ContactState__c;
	const contactCity = account?.CMAP_ContactCity__c;
	const contactHouseNumber = account?.CMAP_ContactHouseNumber__c;
	const mansionName = account?.CMAP_MansionName__c;
	const roomNo = account?.CMAP_RoomNo__c;
	const areaCode = account?.CMAP_AreaCode__c;
	const localTelephoneNumber = account?.CMAP_LocalTelephoneNumber__c;
	const subscriberNumber = account?.CMAP_SubscriberNumber__c;
	const mobileNumber1 = account?.CMAP_MobileNumber1__c;
	const mobileNumber2 = account?.CMAP_MobileNumber2__c;
	const mobileNumber3 = account?.CMAP_MobileNumber3__c;

	const planType = getSessionStorageItem(
		keys.SSN_APPLICATION_SELECTPLANTYPE_PLANTYPE,
		true
	);
	const corporationName = getSessionStorageItem(
		keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CORPORATIONNAME,
		true
	);

	const contactAddressCode = getSessionStorageItem(
		keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSCODE,
		true
	);
	const [isHiddenPlaceholder, setIsHiddenPlaceholder] = useState(false);


	const customerInformationSection = getScDataFromPlaceholder(
		"customer-information-section",
		props
	);
	const buttonSecondSection = getScDataFromPlaceholder("button-second-section", props);
	const b01Data = getScDataFromComponent("B-01i", buttonSecondSection)[0];
	useEffect(() => {
		let preferredDate = '',
			preferredDateLabelTitle = '',
			addRequestDate = '',
			addRequestDateLabelTitle = '',
			customerL01Data1List = [];
		const [customerH02Data] = getScDataFromComponent("H-02", customerInformationSection);
		customerH02Data.fields.titleText = pageContext["UserInfoDetailConfirmation-Title"];
		const [customerL01Data1, customerL01Data2, customerL01Data3, customerL01Data4] = getScDataFromComponent("L-01", customerInformationSection);
		customerL01Data2.fields.isHidden = true;
		customerL01Data3.fields.isHidden = true;
		customerL01Data4.fields.isHidden = true;
		
		if (urlParentName === STRING_CHANGEPLAN) {
			customerH02Data.fields.titleText = pageContext["PlanChangePreferredDayConfirmation-Title"];
			preferredDateLabelTitle = pageContext["PlanChangePreferredDayConfirmation-Label"]?.value;
			preferredDate = moment(planChangeRequestDate, "YYYY-MM-DD").format("YYYY年MM月DD日");
			customerL01Data1List.push(addDataRowList(preferredDateLabelTitle, preferredDate));
			const buttonLink = pageContext["PlanChangePreferredDayConfirmation-EditButtonLink"];
			b01Data.fields.linkURL = buttonLink;
		}

		if (urlParentName === STRING_RELOCATE || urlParentName === STRING_CHANGEITEM) {
			customerH02Data.fields.titleText = pageContext["PreferredDayConfirmation-Title"];
			if (productChangeAddRequestDate !== "") {
				preferredDateLabelTitle = pageContext["PreferredDayConfirmation-PreferredDateOfProductAddition"]?.value;
				addRequestDateLabelTitle = pageContext["PreferredDayConfirmation-UpdatePreferredDateOfProductAddition"]?.value;
				preferredDate = moment(productChangeAddRequestDate, "YYYY-MM-DD").format("YYYY年MM月DD日");
				if (updateAddRequestDate) {
					addRequestDate = pageContext["PreferredDayConfirmation-OverwriteLabel"]?.value;
				} else {
					addRequestDate = pageContext["PreferredDayConfirmation-NoOverwriteLabel"]?.value;
				}
				customerL01Data1List.push(addDataRowList(preferredDateLabelTitle, preferredDate), addDataRowList(addRequestDateLabelTitle, addRequestDate));
			}
			if (productChangeLastUtilizationDate !== "") {
				preferredDateLabelTitle = pageContext["PreferredDayConfirmation-PreferredDateOfLastUtilization"]?.value;
				addRequestDateLabelTitle = pageContext["PreferredDayConfirmation-UpdatePreferredDateOfLastUtilization"]?.value;
				preferredDate = moment(productChangeLastUtilizationDate, "YYYY-MM-DD").format("YYYY年MM月DD日");
				if (updateLastUtilizationDate) {
					addRequestDate = pageContext["PreferredDayConfirmation-OverwriteLabel"]?.value;
				} else {
					addRequestDate = pageContext["PreferredDayConfirmation-NoOverwriteLabel"]?.value;
				}
				customerL01Data1List.push(addDataRowList(preferredDateLabelTitle, preferredDate), addDataRowList(addRequestDateLabelTitle, addRequestDate));
			}

			const buttonLink = pageContext["PreferredDayConfirmation-EditButtonLink"];
			b01Data.fields.linkURL = buttonLink;

			if (productChangeAddRequestDate === "" && productChangeLastUtilizationDate === "") {
				setIsHiddenPlaceholder(true)
			}
		}
		
		if (urlParentName === STRING_MOVING || urlParentName === STRING_REBUILDING) {
			customerL01Data1.fields.titleText = pageContext["UserInfoDetailConfirmation-TransferPreferredDayTitle"];
			preferredDateLabelTitle = pageContext["UserInfoDetailConfirmation-TransferPreferredDayLabel"]?.value;
			preferredDate = moment(changeSourceLastUtilizationDate, "YYYY-MM-DD").format("YYYY年MM月DD日");
			customerL01Data1List.push(addDataRowList(preferredDateLabelTitle, preferredDate));
		}

		if (urlParentName === STRING_APPLY || urlParentName === STRING_SIMULATOR || urlParentName === STRING_MOVING || urlParentName === STRING_REBUILDING) {
			if (customerL01Data2) {
				customerL01Data2.fields.isHidden = false;
				customerL01Data2.fields.titleText = pageContext["UserInfoDetailConfirmation-UsageLocationInfoTitle"];
				const userInfoDetailLabel1 = pageContext["UserInfoDetailConfirmation-BuildingDivisionLabel"]?.value;
				const userInfoDetailLabel2 = pageContext["UserInfoDetailConfirmation-InstallationAddressLabel"]?.value;
				const userInfoDetailLabel3 = pageContext["UserInfoDetailConfirmation-AdditionalInformationLabel"]?.value;
				const userInfoDetailLabel4 = pageContext["UserInfoDetailConfirmation-StartRequestDateLabel"]?.value;
				const userInfoDetailValue1 = buildingCategory;
				const userInfoDetailValue3 = individualContractComplementary;
				let userInfoDetailValue2 = '',
					userInfoDetailValue4 = '',
					customerL01Data2List = [];
				if (isTypeCircuit) {
					userInfoDetailValue2 = `〒${shippingPostalCode}<br>${shippingState}${shippingCity}${shippingStreet}${installationLocationHouseNumber} ${installationLocationRoomNumber}`;
				} else {
					userInfoDetailValue2 = `〒${deliveryServiceAddressPostalCode}<br>${deliveryServiceAddressStateCode}${deliveryServiceAddressCity}${deliveryServiceAddressStreet}${deliveryServiceAddressBuildingName} ${deliveryServiceAddressRoomNumber}`;
				}

				if ((urlParentName === STRING_APPLY || urlParentName === STRING_SIMULATOR) && (planType === STRING_WIRELESS_CIRCUIT || planType === STRING_NOT_CIRCUIT || planType === STRING_GOODS_SERVICE_SALES)) {
					userInfoDetailValue4 = moment(startRequestDate, "YYYY-MM-DD").format("YYYY年MM月DD日");
				}

				customerL01Data2List.push(
					addDataRowList(userInfoDetailLabel1, userInfoDetailValue1),
					addDataRowList(userInfoDetailLabel2, userInfoDetailValue2),
					addDataRowList(userInfoDetailLabel3, userInfoDetailValue3)
				);
				if (userInfoDetailValue4) {
					customerL01Data2List.push(
						addDataRowList(userInfoDetailLabel4, userInfoDetailValue4)
					);
				}
				customerL01Data2.fields.dataRowList = customerL01Data2List
			}

			if (customerL01Data3) {
				customerL01Data3.fields.isHidden = false;
				customerL01Data3.fields.titleText = pageContext["UserInfoDetailConfirmation-CustomerInfoTitle"];
				let userInfoDetailValue1 = '',
					userInfoDetailValue2 = '',
					userInfoDetailValue3 = '',
					userInfoDetailValue4 = '',
					userInfoDetailValue5 = '',
					customerL01Data2List = [];
				let userInfoDetailLabel1 = '',
					userInfoDetailLabel2 = '',
					userInfoDetailLabel3 = '',
					userInfoDetailLabel4 = '',
					userInfoDetailLabel5 = '';
				if (contractName === STRING_INDIVIDUAL) {
					userInfoDetailLabel1 = pageContext["UserInfoDetailConfirmation-NameOfKanjiLabel"]?.value;
					userInfoDetailLabel2 = pageContext["UserInfoDetailConfirmation-NameOfFuriganaLabel"]?.value;
					userInfoDetailLabel3 = pageContext["UserInfoDetailConfirmation-Gender"]?.value;
					userInfoDetailLabel4 = pageContext["UserInfoDetailConfirmation-DateOfBirthLabel"]?.value;
					userInfoDetailValue1 = `${lastName}${firstName}`;
					userInfoDetailValue2 = `${lastNameKana}${firstNameKana}`;
					userInfoDetailValue3 = `${gender}`;
					userInfoDetailValue4 = `${moment(personBirthDate, "YYYY-MM-DD").format("YYYY年MM月DD日")}`
				}
				if (contractName === STRING_CORPORATION) {
					userInfoDetailLabel1 = pageContext["UserInfoDetailConfirmation-CorporateGroupNameOfKanjiLabel"]?.value;
					userInfoDetailLabel2 = pageContext["UserInfoDetailConfirmation-CorporateGroupNameOfFuriganaLabel"]?.value;
					userInfoDetailLabel3 = pageContext["UserInfoDetailConfirmation-DepartmentNameLabel"]?.value;
					userInfoDetailLabel4 = pageContext["UserInfoDetailConfirmation-ContactPersonNameOfKanjiLabel"]?.value;
					userInfoDetailLabel5 = pageContext["UserInfoDetailConfirmation-ContactPersonNameOfFuriganaLabel"]?.value;
					userInfoDetailValue1 = `${corporationName}`;
					userInfoDetailValue2 = `${corporateGroupName}`;
					userInfoDetailValue3 = `${departmentName}`;
					userInfoDetailValue4 = `${contactPersonFamilyName}${contactPersonName}`
					userInfoDetailValue5 = `${contactPersonLastNameKana}${contactPersonFirstNameKana}`
				}

				customerL01Data2List.push(
					addDataRowList(userInfoDetailLabel1, userInfoDetailValue1),
					addDataRowList(userInfoDetailLabel2, userInfoDetailValue2),
					addDataRowList(userInfoDetailLabel3, userInfoDetailValue3),
					addDataRowList(userInfoDetailLabel4, userInfoDetailValue4)
				);

				if (contractName === STRING_CORPORATION) {
					customerL01Data2List.push(
						addDataRowList(userInfoDetailLabel5, userInfoDetailValue5)
					);
				}
				customerL01Data3.fields.dataRowList = customerL01Data2List
			}

			if (customerL01Data4) {
				customerL01Data4.fields.isHidden = false;
				customerL01Data4.fields.titleText = pageContext["UserInfoDetailConfirmation-ContactInfoTitle"];
				let userInfoDetailValue1 = '',
					userInfoDetailValue2 = '',
					userInfoDetailValue3 = '',
					userInfoDetailValue4 = '',
					userInfoDetailValue5 = '',
					userInfoDetailValue6 = '',
					customerL01Data2List = [];
				let userInfoDetailLabel1 = '',
					userInfoDetailLabel2 = '',
					userInfoDetailLabel3 = '',
					userInfoDetailLabel4 = '',
					userInfoDetailLabel5 = '',
					userInfoDetailLabel6 = '';
				const userInfoDetailValue7 = `${areaCode}-${localTelephoneNumber}-${subscriberNumber}`;
				const userInfoDetailValue8 = `${mobileNumber1}-${mobileNumber2}-${mobileNumber3}`;
				const userInfoDetailLabel7 = pageContext["UserInfoDetailConfirmation-ContactAddressPhoneNumberLabel"]?.value;
				const userInfoDetailLabel8 = pageContext["UserInfoDetailConfirmation-ContactAddressMobileNumberLabel"]?.value;

				if (parseInt(contactAddressCode) === 1) {
					userInfoDetailLabel1 = pageContext["UserInfoDetailConfirmation-ContactAddressLabel"]?.value;
					userInfoDetailValue1 = pageContext["UserInfoDetailConfirmation-ContactAddressAltLabel"]?.value;
					customerL01Data2List.push(
						addDataRowList(userInfoDetailLabel1, userInfoDetailValue1),
					);
				} else {
					userInfoDetailLabel1 = pageContext["UserInfoDetailConfirmation-ContactAddressPostalCodeLabel"]?.value;
					userInfoDetailLabel2 = pageContext["UserInfoDetailConfirmation-ContactAddressPrefectureLabel"]?.value;
					userInfoDetailLabel3 = pageContext["UserInfoDetailConfirmation-ContactAddressCityNameLabel"]?.value;
					userInfoDetailLabel4 = pageContext["UserInfoDetailConfirmation-ContactAddressStreetNameLabel"]?.value;
					userInfoDetailLabel5 = pageContext["UserInfoDetailConfirmation-ContactAddressBuildingNameLabel"]?.value;
					userInfoDetailLabel6 = pageContext["UserInfoDetailConfirmation-ContactAddressRoomNumberLabel"]?.value;
					userInfoDetailValue1 = `${contactPostalCode}${contactPostalCodeLowerFour}`;
					userInfoDetailValue2 = `${contactState}`;
					userInfoDetailValue3 = `${contactCity}`;
					userInfoDetailValue4 = `${contactHouseNumber}`;
					userInfoDetailValue5 = `${mansionName}`;
					userInfoDetailValue6 = `${roomNo}`;
					customerL01Data2List.push(
						addDataRowList(userInfoDetailLabel1, userInfoDetailValue1),
						addDataRowList(userInfoDetailLabel2, userInfoDetailValue2),
						addDataRowList(userInfoDetailLabel3, userInfoDetailValue3),
						addDataRowList(userInfoDetailLabel4, userInfoDetailValue4),
						addDataRowList(userInfoDetailLabel5, userInfoDetailValue5),
						addDataRowList(userInfoDetailLabel6, userInfoDetailValue6),
					);
				}
				customerL01Data2List.push(
					addDataRowList(userInfoDetailLabel7, userInfoDetailValue7),
					addDataRowList(userInfoDetailLabel8, userInfoDetailValue8),
				);
				customerL01Data4.fields.dataRowList = customerL01Data2List;
			}

			const buttonLink = pageContext["UserInfoDetailConfirmation-EditButtonLink"];
			b01Data.fields.linkURL = buttonLink;
			if (productChangeAddRequestDate === "" && productChangeLastUtilizationDate === "") {
				setIsHiddenPlaceholder(true);
			}
		}

		if (customerL01Data1List.length > 0) {
			customerL01Data1.fields.dataRowList = customerL01Data1List;
		} else {
			customerL01Data1.fields.isHidden = true;
		}
	}, [])
	
	return (
		<>
			{!isHiddenPlaceholder && 
				<>
					<div className="form_wrapper-input"  name="customer-information-section">
						<Placeholder
							name="customer-information-section"
							rendering={props.rendering}
						/>
					</div>
					<div className="btn_container" name="button-second-section">
						<Placeholder
							name="button-second-section"
							rendering={props.rendering}
						/>
					</div>
				</>
			}
		</>
	)
}