import React, { useState, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import { useFormDataStore, useCustomerInfoStore } from "../../hooks/useSimpleOpState";

// @Model

// get the data

// update the context

// @Controller

// @View

const I03 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    inputTitleText,
    requiredText,
    digitPlaceholder1,
    digitPlaceholder2,
    digitPlaceholder3,
    name,
    type
  } = properData;
  let {inputValue} = properData;
  const formData = useFormDataStore((state) => state.formData);
  const customerInfo = useCustomerInfoStore((state) => state.customerInfo);
  if(Object.keys(formData).length > 0 && type === "productAttributes" && formData[name]) {
    inputValue = [
    {
      name: "PhoneNumber1",
      value: formData[name]?.phoneNumber1
    },
    {
      name: "PhoneNumber2",
      value: formData[name]?.phoneNumber2
    },
    {
      name: "PhoneNumber3",
      value: formData[name]?.phoneNumber3
    }];
  }

  if(Object.keys(customerInfo).length > 0 && type === "customerInfo" &&  customerInfo[name] ) {
    inputValue = [
    {
      name: "PhoneNumber1",
      value: customerInfo[name]?.phoneNumber1
    },
    {
      name: "PhoneNumber2",
      value: customerInfo[name]?.phoneNumber2
    },
    {
      name: "PhoneNumber3",
      value: customerInfo[name]?.phoneNumber3
    }];
  }

  return (
    <React.Fragment>
      <div className="input-box I-03">
        <div className="input-form">
          <div className="input-container">
            <label htmlFor="I03-1">
              <Text field={fillBlank(inputTitleText)} />
              {requiredText?.value && (
                <span className="required">
                  <Text field={fillBlank(requiredText)} />
                </span>
              )}
            </label>
            {/* TODO: This field is optional if description is needed */}
            {/* <p>
              <Text field={fillBlank(descriptionText)} />
            </p> */}
            <input
              name={inputValue && inputValue[0]?.name}
              id="I03-1"
              type="text"
              className="small numberOnly"
              placeholder={digitPlaceholder1?.value}
              value={inputValue && inputValue[0].value}
              onChange={props?.fields?.onChangeEvent}
              maxLength="3"
            />
            <span>-</span>
            <input
              name={inputValue && inputValue[1]?.name}
              type="text"
              className="small numberOnly"
              placeholder={digitPlaceholder2?.value}
              value={inputValue && inputValue[1].value}
              onChange={props?.fields?.onChangeEvent}
              maxLength="4"
            />
            <span>-</span>
            <input
              name={inputValue && inputValue[2]?.name}
              type="text"
              className="small numberOnly"
              placeholder={digitPlaceholder3?.value}
              value={inputValue && inputValue[2].value}
              onChange={props?.fields?.onChangeEvent}
              maxLength="4"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default I03;
