import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const L10 = (props) => {
  const {
    telephoneNumber,
    otherDeviceText,
    businessHoursText,
    additionalClass,
  } = props.fields;
  return (
    <React.Fragment>
      <div
        className={`list_wrapper with-background L-10 ${additionalClass?.value}`}
      >
        <dl>
          <dt>
            <Text field={fillBlank(telephoneNumber)} />
          </dt>
          <dd>
            <Text field={fillBlank(otherDeviceText)} />
            <br />
            <Text field={fillBlank(businessHoursText)} />
          </dd>
        </dl>
      </div>
    </React.Fragment>
  );
};

export default L10;
