import React from "react";

const SelectPlanProductEstimateLayout = (props) => {
  return (
    <React.Fragment>
      <div className="estimate_wrapper ED-02">
        <div className="sp-estimate-modal">
          <div className="sp_estimate-display">
            <div className="estimate_wrapper-display">
              <section>
                <h2>月額お見積もり</h2>
                <p>
                  <span className="bold">
                    <span className="text-primary text-big">16,173</span>円
                    <span className="regular">(税込)/月</span>
                  </span>
                </p>
                <ul className="section-list">
                  <li>
                    <dl>
                      <dt>消費税合計</dt>
                      <dd>1,038円</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>8%対象消費税</dt>
                      <dd>0円</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>10%対象消費税</dt>
                      <dd>1,038円</dd>
                    </dl>
                  </li>
                </ul>
              </section>

              <section className="white-bg">
                <h3>プラン</h3>
                <ul>
                  <li>
                    <span>パッケージ：</span>
                    <p>パッケージ名</p>
                  </li>
                  <li>
                    <span>バンドル：</span>
                    <p>バンドル名</p>
                  </li>
                  <li>
                    <span>プラン：</span>
                    <p>Netflix+10Gベストエフォート</p>
                  </li>
                </ul>
                <p>
                  <span className="bold">6,455円 (税込)/月</span>
                </p>
              </section>

              <section className="white-bg">
                <h3>商品​</h3>
                <h4>スタート割</h4>
                <label>光回線</label>
                <table>
                  <thead>
                    <tr>
                      <th>内訳</th>
                      <th>金額</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1Gベストエフォート</td>
                      <td>4,400円</td>
                    </tr>
                    <tr>
                      <td>Netflix プレミアム</td>
                      <td>489円</td>
                    </tr>
                    <tr>
                      <td>WiFi6 + メッシュ子機1台</td>
                      <td>880円</td>
                    </tr>
                    <tr>
                      <td>WiFi6 + メッシュ子機1台なし</td>
                      <td>-110円</td>
                    </tr>
                    <tr>
                      <td>HNセキュリティー</td>
                      <td>165円</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>小計</td>
                      <td>5,285円</td>
                    </tr>
                  </tfoot>
                </table>

                <label>光回線</label>
                <table>
                  <thead>
                    <tr>
                      <th>内訳</th>
                      <th>金額</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1Gベストエフォート</td>
                      <td>4,400円</td>
                    </tr>
                    <tr>
                      <td>Netflix プレミアム</td>
                      <td>489円</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>小計</td>
                      <td>5,285円</td>
                    </tr>
                  </tfoot>
                </table>
                <label>光回線</label>
                <table>
                  <thead>
                    <tr>
                      <th>内訳</th>
                      <th>金額</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1Gベストエフォート</td>
                      <td>4,400円</td>
                    </tr>
                    <tr>
                      <td>Netflix プレミアム</td>
                      <td>489円</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>小計</td>
                      <td>5,285円</td>
                    </tr>
                  </tfoot>
                </table>
              </section>

              <section className="white-bg">
                <table className="no-margin">
                  <tbody>
                    <tr>
                      <td className="bold">自動適用商品</td>
                    </tr>
                    <tr>
                      <td>ユニバーサルサービス料</td>
                      <td>XXX円</td>
                    </tr>
                    <tr>
                      <td>電話リレーサービス料</td>
                      <td>XXX円</td>
                    </tr>
                    <tr>
                      <td>XXXXXX割引</td>
                      <td>-XXX円</td>
                    </tr>
                    <tr>
                      <td>XXXXXX手数料</td>
                      <td>XXX円</td>
                    </tr>
                  </tbody>
                </table>
              </section>

              <section className="white-bg">
                <h3>特典</h3>
                <ul className="s-list">
                  <li>Netflix最大2ヶ月分プレゼント</li>
                  <li>コミュファ光 4K-LINKプレゼント</li>
                </ul>
              </section>

              <section>
                <h2>年額お見積もり</h2>
                <p>
                  <span className="bold">
                    <span className="text-primary text-big">27,000</span>円
                    <span className="regular">(税込)/月</span>
                  </span>
                </p>
                <ul className="section-list">
                  <li>
                    <dl>
                      <dt>消費税合計</dt>
                      <dd>1,038円</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>8%対象消費税</dt>
                      <dd>0円</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>10%対象消費税</dt>
                      <dd>1,038円</dd>
                    </dl>
                  </li>
                </ul>
              </section>

              <section className="white-bg">
                <label>光電話</label>
                <table>
                  <thead>
                    <tr>
                      <th>内訳</th>
                      <th>金額</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1Gベストエフォート</td>
                      <td>4,400円</td>
                    </tr>
                    <tr>
                      <td>Netflix プレミアム</td>
                      <td>489円</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>小計</td>
                      <td>5,285円</td>
                    </tr>
                  </tfoot>
                </table>
              </section>

              <section>
                <h2>初期費用</h2>
                <p>
                  <span className="bold">
                    <span className="text-primary text-big">12,100</span>円
                    <span className="regular">(税込)</span>
                  </span>
                </p>
                <ul className="section-list">
                  <li>
                    <dl>
                      <dt>消費税合計</dt>
                      <dd>1,038円</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>8%対象消費税</dt>
                      <dd>0円</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>10%対象消費税</dt>
                      <dd>1,038円</dd>
                    </dl>
                  </li>
                </ul>
              </section>

              <section className="white-bg">
                <table>
                  <thead>
                    <tr>
                      <th>内訳</th>
                      <th>金額</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>交換機等工事費</td>
                      <td>2,970円</td>
                    </tr>
                    <tr>
                      <td>回線終端装置工事費</td>
                      <td>7,920円</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>小計</td>
                      <td>4,000円</td>
                    </tr>
                  </tfoot>
                </table>
              </section>
            </div>
          </div>
          <button type="button" className="sp-estimate-close">
            <p>閉じる</p>
            <span aria-hidden="true">×</span>
          </button>
          <div className="sp-display-backdrop"></div>
        </div>

        {/* TODO: will need to add logic so the Footer does not overlap with estimate-amount */}
        <div className="sp-display">
          <div className="sp-display_amount">
            <div className="amount-detail">
              <h2>月額お見積もり</h2>
              <p>
                <span className="text-primary bold">16,173</span>円(税込)/月
              </p>
            </div>
          </div>
          <div className="sp-display_amount">
            <div className="amount-detail">
              <h2>割引合計額</h2>
              <p>
                <span className="text-primary bold">910</span>円/月
              </p>
            </div>
            <div className="btn_wrapper primary">
              <button className="btn btn_small checkBreakdown" type="button">
                内訳を確認
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SelectPlanProductEstimateLayout;
