import React, { useContext, useEffect, useState } from "react";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import { useCustomQuery } from "../../hooks/useGetData";
import { di_register } from "../../utils/di";
import { apiBaseURL } from "../../envSettings";
import ModalContext from "../../assets/context/ModalContext";
import {
  setSessionStorageItem,
  getSessionStorageItem,
} from "../../utils/useSessionStorage";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
  notEmpty,
  replaceScDataFromComponent,
  deepcopy,
  redirectToLinkUrl,
} from "../../utils/commonUtils";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import {
  STRING_GET,
  STRING_TYPE_K,
  STRING_TYPE_S,
  STRING_RESIDENCE_TYPE_HOME,
  STRING_APPINFO_HOMEAVAILABLE,
  STRING_APPINFO_HOMEUNAVAILABLE,
} from "../../utils/constantVariables";
import { ApplicationInformationList } from "../../services/common/common-application-information-list";
import { CommonServiceUnavailableArea } from "../../services/common/common-service-unavailable-area";

const AreaHomeInfo = (props) => {
  const { sitecoreContext } = props;

  const {
    prefectureCode,
    cityCode,
    offerMethodInfos,
    prefectureList,
    municipalityList,
  } = props;

  let addressName = "";
  const housingInfoSection = getScDataFromPlaceholder(
    "housing-information-section",
    props
  );
  let [L01Data] = getScDataFromComponent("L-01", housingInfoSection);
  const productName = L01Data?.fields?.productName;
  let prefectureItem = "";
  let streetItem = "";
  if (prefectureList !== "") {
    [prefectureItem] = prefectureList?.prefectures
      .filter(
        (x) => x.CMAP_StateCode__c.toString() === prefectureCode.toString()
      )
      .map((o) => o.CMAP_StateName__c);
  }
  if (municipalityList !== "") {
    [streetItem] = municipalityList?.citys
      .filter(
        (x) => x.CMAP_MunicipalityCode__c.toString() === cityCode.toString()
      )
      .map((o) => o.CMAP_MunicipalityName__c);
  }
  if (prefectureItem || streetItem) {
    addressName =
      (prefectureItem ? prefectureItem : "") +
      " " +
      (streetItem ? streetItem : "");
  }

  L01Data.fields.dataRowList = [
    {
      fields: {
        Key: productName,
        Value: addressName,
      },
    },
  ];

  // title display/hide logic
  const { "HomeInfo-Status": homeInfoStatus } = sitecoreContext?.route?.fields;
  const homeInfoStatusTitle = !offerMethodInfos ? null : homeInfoStatus.value;
  L01Data.fields.titleText = { value: homeInfoStatusTitle };

  return (
    <>
      <Placeholder
        name="housing-information-section"
        rendering={props.rendering}
      />
      {!offerMethodInfos && (
        <div className="btn_container">
          <Placeholder
            name="service-outside-area-button-section"
            rendering={props.rendering}
          />
        </div>
      )}
    </>
  );
};

const AreaServiceApply = (props) => {
  const {
    offerMethodInfos: [offerMethodInfos],
    sitecoreContext,
  } = props;

  // get the ss
  const orderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );

  let { StandardOpeningMonths, StandardOpeningDays } = "";
  const layerStandardOpenDays =
    offerMethodInfos?.layerDelivery?.CMAP_StandardOpenDays__c;
  const layerStandardOpeningMonths =
    offerMethodInfos?.layerDelivery?.CMAP_StandardOpeningMonths__c;
  const areaStandardOpenDays =
    offerMethodInfos?.offerArea?.CMAP_StandardOpenDays__c;
  const areaStandardOpenMonths =
    offerMethodInfos?.offerArea?.CMAP_StandardOpenMonths__c;

  const serviceApplicationNoteSection = getScDataFromPlaceholder(
    "service-application-note-section",
    props
  );
  let [L11Data] = getScDataFromComponent("L-11", serviceApplicationNoteSection);

  const {
    "ServiceApplication-Note": ServiceApplicationNote,
    "ServiceApplication-ApplicationButtonLink": ServiceApplicationApplicationButtonLink,
  } = sitecoreContext?.route?.fields;

  if (notEmpty(layerStandardOpenDays) && notEmpty(layerStandardOpeningMonths)) {
    StandardOpeningMonths = layerStandardOpeningMonths;
    StandardOpeningDays = layerStandardOpenDays;
  } else {
    StandardOpeningDays = areaStandardOpenDays;
    StandardOpeningMonths = areaStandardOpenMonths;
  }

  L11Data.fields["noteText"] = {
    value: ServiceApplicationNote.value
      .replace("{0}", StandardOpeningMonths)
      .replace("{1}", StandardOpeningDays),
  };

  const serviceApplicationButtonSection = getScDataFromPlaceholder(
    "service-application-button-section",
    props
  );
  let [B01Data] = getScDataFromComponent(
    "B-01",
    serviceApplicationButtonSection
  );
  B01Data.fields.onClickEvent = async () => {
    const updatedOrderRelatedInfo = {
      ...orderRelatedInfo,
      orderRelatedInfo: {
        ...orderRelatedInfo?.orderRelatedInfo,
        orderInfo: {
          ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
          order: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
            CMAP_InstallationLocationOfferArea__c:
              offerMethodInfos?.offerArea?.Id,
            CMAP_InstallLocLayerDeliveryDuration__c:
              offerMethodInfos?.layerDelivery?.Id,
            CMAP_OfferMethod__c:
              offerMethodInfos?.layerDelivery?.offerMethodName, //not existing on the current API response,
            CMAP_StandardOpeningDays__c: StandardOpeningMonths,
            CMAP_StandardOpeningMonths__c: StandardOpeningDays,
          },
        },
      },
    };

    setSessionStorageItem(
      keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
      updatedOrderRelatedInfo,
      true
    );

    redirectToLinkUrl(ServiceApplicationApplicationButtonLink);
  };

  return (
    <>
      <div className="decoration-content application">
        <div className="application_wrapper">
          <Placeholder
            name="service-application-section"
            rendering={props.rendering}
          />
          <div className="btn_container">
            <Placeholder
              name="service-application-button-section"
              rendering={props.rendering}
            />
          </div>
        </div>
      </div>
      <Placeholder
        name="service-application-note-section"
        rendering={props.rendering}
      />
    </>
  );
};

const AreaServiceList = (props) => {
  const {
    offerMethodInfos: [offerMethodInfos],
    onClickWithApiCall,
    sitecoreContext,
    RetrieveChannelListRefresh,
  } = props;

  let apartmentListSection = getScDataFromPlaceholder(
    "list-of-services-section",
    props
  );
  mappingServiceListForList(
    apartmentListSection,
    offerMethodInfos,
    onClickWithApiCall,
    sitecoreContext,
    RetrieveChannelListRefresh
  );

  return (
    <Placeholder name="list-of-services-section" rendering={props.rendering} />
  );
};

const mappingServiceListForList = (
  apartmentListSection,
  offerMethodInfos,
  onClickWithApiCall,
  sitecoreContext,
  RetrieveChannelListRefresh
) => {
  let C09Data = getScDataFromComponent("C-09", apartmentListSection);
  const {
    "ServiceList-ServiceLabel": ServiceListServiceLabel,
    "ServiceList-ChannelDescription": ServiceListChannelDescription,
  } = sitecoreContext?.route?.fields;
  if (offerMethodInfos) {
    let offerListApiData = offerMethodInfos["offerableServiceGroupInfos"];
    if (offerListApiData.length !== 0) {
      let original_C09 = Object.assign({}, C09Data[0]);
      C09Data = [];
      offerListApiData.forEach(function (v, index) {
        if (original_C09?.uid?.toString().includes("copy_")) {
          return true;
        }

        let new_C09 = deepcopy(original_C09);
        new_C09.uid = "copy_" + index + "_" + new_C09.uid;

        let thisFields = new_C09.fields;
        let serviceGroup = v.serviceGroup;
        thisFields.titleText = { value: serviceGroup };

        if (v.offerableServiceNames?.length > 1) {
          thisFields.descriptionText = {
            value: ServiceListServiceLabel.value,
          };
          thisFields.extraDescriptionText = {
            value: v.offerableServiceNames.join("/"),
          };
        } else {
          thisFields.descriptionText = { value: "" };
        }

        if (new_C09.placeholders && v.hasChannelInfo) {
          thisFields.descriptionText2 = {
            value: ServiceListChannelDescription.value,
          };
          const searchLinkSection = new_C09.placeholders["link-section"];
          let linkText = getScDataFromComponent("LiM-01", searchLinkSection);
          linkText[0].fields.onClickEvent = () => {
            onClickWithApiCall(linkText[0].fields.modalTarget.value);
            RetrieveChannelListRefresh();
          };
        } else {
          thisFields.isHidden = true;
        }
        C09Data.push(new_C09);
        new_C09 = null;
      });

      replaceScDataFromComponent(
        "C-09",
        apartmentListSection,
        C09Data,
        "mappingServiceListForList"
      );
    }
  }
};

const AreaProviderable = (props) => {
  const {
    offerMethodInfos,
    sitecoreContext,
    onClickWithApiCall,
    RetrieveChannelListRefresh,
  } = props;
  if (!offerMethodInfos) {
    return <></>;
  }

  return (
    <>
      <AreaServiceApply
        rendering={props.rendering}
        offerMethodInfos={offerMethodInfos}
        sitecoreContext={sitecoreContext}
      />

      <AreaServiceList
        rendering={props.rendering}
        offerMethodInfos={offerMethodInfos}
        onClickWithApiCall={onClickWithApiCall}
        sitecoreContext={sitecoreContext}
        RetrieveChannelListRefresh={RetrieveChannelListRefresh}
      />

      <ApplicationInformationList
        rendering={props.rendering}
        screen={STRING_APPINFO_HOMEAVAILABLE}
        sitecoreContext={sitecoreContext}
        setIsLoading={props.setIsLoading}
      />
    </>
  );
};

const AreaProviderableNone = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  const offerMethodInfos = props.offerMethodInfos;
  if (offerMethodInfos) {
    return <></>;
  }

  return (
    <>
      <CommonServiceUnavailableArea
        rendering={props.rendering}
        screen={STRING_APPINFO_HOMEUNAVAILABLE}
        sitecoreContext={sitecoreContext}
        setIsLoading={props.setIsLoading}
      />
    </>
  );
};

const HomeSearchResultLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);
  const [municipalityList, setMunicipalityList] = useState("");
  const [prefectureList, setPrefectureList] = useState("");
  const [offerMethodInfos, setOfferMethodInfos] = useState("");
  const { dispatch } = useContext(ModalContext);

  const personalizedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );

  const addressCode = getSessionStorageItem(
    keys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_ADDRESSCODE,
    true
  );

  const priorityOfferMethod = getSessionStorageItem(
    keys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_SHARED,
    true
  );

  const blockNo = getSessionStorageItem(
    keys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_BLOCKNO,
    true
  );

  const msgCode = getSessionStorageItem(
    keys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_MSGCODE,
    true
  );

  //TODO
  const blockNumberTypeC = ""; //TBA（Ongoing QA）
  const layerCodeTypeC = ""; //TBA（Ongoing QA）

  let {
    prefectureCode,
    cityCode,
    groupUnitCenterTypeK,
    layerCodeTypeK,
    groupUnitCenterTypeS,
    layerCodeTypeS,
    blockNoChar,
  } = "";
  const blockNoStr = blockNo.toString();
  if (blockNoStr.length >= 7) {
    const charCount = blockNoStr.charAt(blockNoStr.length - 7);
    blockNoChar = blockNoStr.slice(-charCount);
  }
  if (priorityOfferMethod === STRING_TYPE_K) {
    groupUnitCenterTypeK = blockNoChar;
    layerCodeTypeK = msgCode;
  }
  if (priorityOfferMethod === STRING_TYPE_S) {
    groupUnitCenterTypeS = blockNoChar;
    layerCodeTypeS = msgCode;
  }

  if (addressCode !== "" || addressCode !== undefined) {
    prefectureCode = String(addressCode).substring(0, 2);
    cityCode = String(addressCode).slice(-3);
  }
  const orderChannelCategory = personalizedInfo?.AppChannelDivision || "Web";
  const residenceType = STRING_RESIDENCE_TYPE_HOME;

  const RetrievePrefectureListURI = `${apiBaseURL}order/RetrievePrefectureList`;
  const {
    data: RetrievePrefectureListData,
    isLoading: RetrievePrefectureListIsLoading,
    error: RetrievePrefectureListError,
    refetch: RetrievePrefectureListRefresh,
    remove: RetrievePrefectureListRemove,
  } = useCustomQuery(RetrievePrefectureListURI, true, STRING_GET);

  const RetrieveMunicipalityListParams =
    `&prefectureCode=${prefectureCode}` + `&citySearchString=` + `&postalCode=`;
  const RetrieveMunicipalityListURI = `${apiBaseURL}order/RetrieveMunicipalityList?${RetrieveMunicipalityListParams}`;
  const {
    data: RetrieveMunicipalityListData,
    isLoading: RetrieveMunicipalityListIsLoading,
    error: RetrieveMunicipalityListError,
    refetch: RetrieveMunicipalityListRefresh,
    remove: RetrieveMunicipalityListRemove,
  } = useCustomQuery(RetrieveMunicipalityListURI, true, STRING_GET);

  const retrieveOfferMethodListParams =
    `prefectureCode=${prefectureCode}` +
    `&cityCode=${cityCode}` +
    `&priorityOfferMethod=${priorityOfferMethod}` +
    `&blockNumberTypeC=${blockNumberTypeC}` +
    `&layerCodeTypeC=${layerCodeTypeC}` +
    `&groupUnitCenterTypeK=${groupUnitCenterTypeK}` +
    `&layerCodeTypeK=${layerCodeTypeK}` +
    `&groupUnitCenterTypeS=${groupUnitCenterTypeS}` +
    `&layerCodeTypeS=${layerCodeTypeS}` +
    `&orderChannelCategory=${orderChannelCategory}` +
    `&residenceType=${residenceType}`;
  const RetrieveOfferMethodListURI = `${apiBaseURL}order/RetrieveOfferMethodList?${retrieveOfferMethodListParams}`;
  const {
    data: RetrieveOfferMethodListData,
    isLoading: RetrieveOfferMethodListIsLoading,
    error: RetrieveOfferMethodListError,
    refetch: RetrieveOfferMethodListRefresh,
    remove: RetrieveOfferMethodListRemove,
  } = useCustomQuery(RetrieveOfferMethodListURI, true, STRING_GET);

  useEffect(() => {
    if (
      RetrieveOfferMethodListData &&
      RetrieveOfferMethodListData["offerMethodInfos"]
    ) {
      if (RetrieveOfferMethodListData["offerMethodInfos"].length !== 0) {
        setOfferMethodInfos(RetrieveOfferMethodListData["offerMethodInfos"]);
      }
    }
  }, [RetrieveOfferMethodListData]);

  const RetrieveChannelListParams =
    `blockNumberGroupUnitCenter=${blockNo}` +
    `&prefectureCode=${prefectureCode}` +
    `&cityCode=${cityCode}` +
    `&accessCircuit=${priorityOfferMethod}`;
  const RetrieveChannelListURI = `${apiBaseURL}order/RetrieveChannelList?${RetrieveChannelListParams}`;
  const {
    data: RetrieveChannelListData,
    isLoading: RetrieveChannelListIsLoading,
    error: RetrieveChannelListError,
    refetch: RetrieveChannelListRefresh,
    remove: RetrieveChannelListRemove,
  } = useCustomQuery(RetrieveChannelListURI, false, STRING_GET);

  const isApiLoading =
    RetrievePrefectureListIsLoading ||
    RetrieveMunicipalityListIsLoading ||
    RetrieveOfferMethodListIsLoading ||
    RetrieveChannelListIsLoading;
  props.setIsLoading(isApiLoading);

  const onClickWithApiCall = (modalId) => {
    dispatch({ type: "SHOW_MODAL", payload: { id: modalId } });
  };
  useEffect(() => {
    if (RetrieveChannelListData) {
      dispatch({
        type: "UPDATE_CHANNEL_LIST",
        data: RetrieveChannelListData,
      });
    }
  }, [RetrieveChannelListData]);

  useEffect(() => {
    if (RetrievePrefectureListData) {
      setPrefectureList(RetrievePrefectureListData);
    }
  }, [RetrievePrefectureListData]);

  useEffect(() => {
    if (RetrieveMunicipalityListData) {
      setMunicipalityList(RetrieveMunicipalityListData);
    }
  }, [RetrieveMunicipalityListData]);
  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <div className="form_wrapper-input">
                <AreaHomeInfo
                  rendering={props.rendering}
                  prefectureList={prefectureList}
                  municipalityList={municipalityList}
                  sitecoreContext={sitecoreContext}
                  prefectureCode={prefectureCode}
                  cityCode={cityCode}
                  offerMethodInfos={offerMethodInfos}
                />

                <AreaProviderable
                  rendering={props.rendering}
                  setIsLoading={props.setIsLoading}
                  sitecoreContext={sitecoreContext}
                  offerMethodInfos={offerMethodInfos}
                  RetrieveOfferMethodListData={RetrieveOfferMethodListData}
                  onClickWithApiCall={onClickWithApiCall}
                  RetrieveChannelListRefresh={RetrieveChannelListRefresh}
                />

                <AreaProviderableNone
                  rendering={props.rendering}
                  setIsLoading={props.setIsLoading}
                  sitecoreContext={sitecoreContext}
                  offerMethodInfos={offerMethodInfos}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(HomeSearchResultLayout);
