import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from "../../utils/commonUtils";
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import classnames from "classnames";

const PT02 = (props) => {
  const {
    stepClassText,
    stepTitleText,
    headingText,
    mobileHeadingText,
    steps,
  } = props.fields;

  const stepList = props?.sitecoreContext?.route?.fields?.PageList;
  const pageTitle = props?.sitecoreContext?.route?.fields?.PageTitle;
  const currentPageId = props?.sitecoreContext?.itemId;
  let activeCount = 0;
  for (let i=0; stepList?.length > i; i++) {
    if (stepList[i].id === currentPageId) {
      break;
    }
    activeCount++;
  }
  activeCount = activeCount === stepList?.length - 1 ? activeCount + 1 : activeCount;
  const nextPageTitle = stepList[activeCount + 1]?.fields?.ProgressTrackerItemName ?? mobileHeadingText;

  return (
    <React.Fragment>
        <div className="title_wrapper PT-02">
          <ul>
            {stepList?.map((stepInfo, index) => (
              <li
                className={classnames({
                  active: activeCount > index,
                  current: activeCount === index,
                })}
                key={index}
              >
                
                <p>
                  <Text field={fillBlank(stepInfo.fields.ProgressTrackerItemName)} />
                </p>
                <span className="circle"></span>
              </li>
            ))}
          </ul>
          <h1>
            <Text field={fillBlank(pageTitle)} />
          </h1>
          <p className="sp_only">
            <Text field={nextPageTitle} />
          </p>
        </div>
    </React.Fragment>
  );
};

export default withSitecoreContext()(PT02);