import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const A02 = (props) => {
  const { alertText } = props.fields;
  let isShow = props.fields.isShow===undefined || props.fields?.isShow;

  return (
    <React.Fragment>
      {isShow? 
      <div className="alert-message warning A-02">
        <div className="note-title">
          <i className="bi bi-exclamation-triangle"></i>
          <p>
            <Text field={fillBlank(alertText)} />
          </p>
        </div>
      </div>
      : ""
      }
    </React.Fragment>
  );
};

export default A02;
