import React, { useState } from "react";
import { di_fetch } from "../../utils/di";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { getProperData, fillBlank, nullChecker } from "../../utils/commonUtils";
import { useSetSelectedItem } from "../../hooks/useSelectedState";
import { sitecoreApiHost } from "../../temp/config";

const S05 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const selections = nullChecker(properData?.selections, []);
  const isDisabled = nullChecker(properData?.isDisabled, { value: true });

  const componentCategory = nullChecker(properData?.category)?.value;
  const additionalClass = nullChecker(properData?.additionalClass);

  // @Controller
  const [selectedItem, selectedItemSetter] = useSetSelectedItem(
    componentCategory
  );

  const handleRadioButtonChange = (event) => {
    // changed context
    let newVal = {};
    newVal[componentCategory] = event.target.value;
    selectedItemSetter(newVal);
  };

  return (
    <React.Fragment>
      <div className={`input-form illustration ${additionalClass?.value} S-05`}>

        {selections?.length > 0 && (
          selections?.map((item, i) => (
            <div
              key={i}
              className={`input-box ${
                selectedItem.selected === item.Code ? "active" : ""
              }`}
            >
              <div className="input-container">
                <input
                  type="radio"
                  name={componentCategory}
                  value={item.Code}
                  className={isDisabled.value ? "disabled" : ""}
                  defaultChecked={selectedItem.selected === item.Code}
                  onChange={handleRadioButtonChange}
                  disabled={isDisabled?.value}
                />
                <label htmlFor="rdOptionS05">
                  <img src={sitecoreApiHost + item.IconUrl} />
                  <span>
                    <Text field={fillBlank(item.Value)} />
                  </span>
                </label>
              </div>
            </div>
          ))
        )}
      </div>
    </React.Fragment>
  );
};

export default S05;
