import React, { useState, useEffect, useMemo } from "react";
import classnames from "classnames";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import CarretIcon from "../../media/Project/commufa/welcome_commufa/img/icons/caret-down-square.svg";
import { Text, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch, di_register } from "../../utils/di";
import {
  fillBlank,
  formatCurrency,
  formatCurrencyChar,
  getScDataFromComponent,
  getScDataFromPlaceholder,
  redirect,
  showPrintDialog,
} from "../../utils/commonUtils";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import { apiBaseURL } from "../../envSettings";
import { useCustomQuery } from "../../hooks/useGetData";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import {
  STRING_POST,
  STRING_GET,
  STRING_SUSPENDSELECTED,
  STRING_DESELECTED,
  STRING_DOOR_TO_DOOR,
  STRING_TELE_MARKET,
  STRING_WEB,
  STRING_RESIDENCE_TYPE_APARTMENT,
  STRING_USERAGENT_CPAD_APP,
  STRING_CURRENCY_CHAR,
  STRING_STANDARD,
  STRING_DISCOUNT,
  STRING_OPTION,
  STRING_OTHER_PROVIDER,
  STRING_SUBTOTAL,
  STRING_TOTAL
} from "../../utils/constantVariables";

import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { format } from "date-fns";
import moment from "moment";
import { useFormContext } from "react-hook-form";

import {
  createActualMonthlyExpenseChartObj,
  createAnnualExpenseObj,
  createBenefitObj,
  createCumulativeTotalChartObj,
  createInitialCostObj,
  createMonthlyExpenseChartObj,
  createMonthlyExpenseObj
} from "../../services/calculations/C-Pad-Price-Simulation/calculateC-PadPriceSimulation"

// TODO: delete test data

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BreakdownRow = (props) => {
  const { data = [], title = "", currencyUnit } = props;
  return (
    <React.Fragment>
      <tr className="collapse-row">
        <td className="vertical-align-txt">
          <Text field={fillBlank(title)} />
        </td>
        <td colSpan={10}>
          <div className="grid-section">
            {data.map((item) => (
              <div key={item.name} className="D-01">
                <p>{item.name}</p>
                <p>{formatCurrencyChar(item?.price)}</p>
              </div>
            ))}
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

const PriceSimulationTable = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const {
    "PriceSimulationTable-CurrentLine": currentLine,
    "PriceSimulationTable-CommufaLine": commufaLine,
    "PriceSimulationTable-Difference": difference,
    "PriceSimulationTable-CurrentLineMonthlyColName": currentLineMonthlyColName,
    "PriceSimulationTable-CommufaLineMonthlyGroupName": commufaLineMonthlyGroupName,
    "PriceSimulationTable-DifferenceMonthlyGroupName": differenceMonthlyGroupName,
    "PriceSimulationTable-DifferenceTotalGroupName": differenceTotalGroupName,
    "PriceSimulationTable-DefferenceForTwoYearColName": defferenceForTwoYearColName,
    "PriceSimulationTable-DefferenceForFiveYearColName": defferenceForFiveYearColName,
    "PriceSimulationTable-BasicPriceRowName": basicPriceRowName,
    "PriceSimulationTable-BasicPriceBreakdown": basicPriceBreakdown,
    "PriceSimulationTable-OptionRowName": optionRowName,
    "PriceSimulationTable-OptionBreakdown": optionBreakdown,
    "PriceSimulationTable-DiscountRowName": discountRowName,
    "PriceSimulationTable-TotalRowName": totalRowName,
    "PriceSimulationTable-CommufaSubTotal": commufaSubTotalRowName,
    "PriceSimulationTable-TaxedPriceNote": taxedPriceNote,
  } = sitecoreContext?.route?.fields;
  const apiData = props?.apiData;
  const monthlyExpenseObj = props?.monthlyExpenseObj;
  const tableHeaders = apiData ? apiData[0].simulationInfo : null;
  const currencyUnit = props?.currencyUnit;
  const commufaLineMonthlyForFirstYearColName =
    tableHeaders?.simulatedUnitPriceLabel1;
  const commufaLineMonthlyForSecondYearColName =
    tableHeaders?.simulatedUnitPriceLabel2;
  const commufaLineMonthlyAfterThirdYearColName =
    tableHeaders?.simulatedUnitPriceLabel3;

  const differenceMonthlyForFirstYearColName =
    tableHeaders?.simulatedUnitPriceLabel1;
  const differenceMonthlyForSecondYearColName =
    tableHeaders?.simulatedUnitPriceLabel2;
  const differenceMonthlyAfterThirdYearColName =
    tableHeaders?.simulatedUnitPriceLabel3;

  const cPadHearingInfo = props?.cPadHearingInfo;
  const SelectedProviderName = {
    value: props?.SelectedProviderName,
  };
  const [expandedRows, setExpandedRows] = useState([]);

  const optionBreakDownData = [
    ...(cPadHearingInfo?.Option?.OtherOptionList.map((option) => ({
      name: option.OptionName,
      price: option.OptionPrice,
    })) || []),
  ];

  const standardMonthlyExpense = monthlyExpenseObj.find((item) => item.Category === STRING_STANDARD);
  const standardMonthlyOption = monthlyExpenseObj.find((item) => item.Category === STRING_OPTION);
  const standardMonthlyDiscount = monthlyExpenseObj.find((item) => item.Category === STRING_DISCOUNT);
  const standardMonthlyOtherProvider = monthlyExpenseObj.find((item) => item.Category === STRING_OTHER_PROVIDER);
  const standardMonthlySubtotal = monthlyExpenseObj.find((item) => item.Category === STRING_SUBTOTAL);
  const standardMonthlyTotal = monthlyExpenseObj.find((item) => item.Category === STRING_TOTAL);

  const tableData = [
    //basic price data
    {
      price1: { value: standardMonthlyExpense?.Current },
      price2: { value: standardMonthlyExpense?.Commufa1 },
      price3: { value: standardMonthlyExpense?.Commufa2 },
      price4: { value: standardMonthlyExpense?.Commufa3 },
      price5: { value: standardMonthlyExpense?.Commufa1Diff },
      price6: { value: standardMonthlyExpense?.Commufa2Diff },
      price7: { value: standardMonthlyExpense?.Commufa3Diff },
      price8: { value: standardMonthlyExpense?.CumulativeAmount1Diff },
      price9: { value: standardMonthlyExpense?.CumulativeAmount2Diff },
      collapsableRow: true,
      expandedData: (
        <BreakdownRow
          data={cPadHearingInfo?.BasicPrice?.ServiceList.map((service) => ({
            name: service.ServiceName,
            price: service.ServicePrice,
          }))}
          title={basicPriceBreakdown}
          currencyUnit={currencyUnit}
        />
      ),
    },
    //option price data
    {
      price1: { value: standardMonthlyOption?.Current },
      price2: { value: standardMonthlyOption?.Commufa1 },
      price3: { value: standardMonthlyOption?.Commufa2 },
      price4: { value: standardMonthlyOption?.Commufa3 },
      price5: { value: standardMonthlyOption?.Commufa1Diff },
      price6: { value: standardMonthlyOption?.Commufa2Diff },
      price7: { value: standardMonthlyOption?.Commufa3Diff },
      price8: { value: standardMonthlyOption?.CumulativeAmount1Diff },
      price9: { value: standardMonthlyOption?.CumulativeAmount2Diff },
      collapsableRow: true,
      expandedData: (
        <BreakdownRow
          data={optionBreakDownData}
          title={optionBreakdown}
          currencyUnit={currencyUnit}
        />
      ),
    },
    //discount price data
    {
      price1: { value: standardMonthlyDiscount?.Current },
      price2: { value: standardMonthlyDiscount?.Commufa1 },
      price3: { value: standardMonthlyDiscount?.Commufa2 },
      price4: { value: standardMonthlyDiscount?.Commufa3 },
      price5: { value: standardMonthlyDiscount?.Commufa1Diff, sign: standardMonthlyDiscount?.Commufa1Diff > 0 ? "+" : null },
      price6: { value: standardMonthlyDiscount?.Commufa2Diff, sign: standardMonthlyDiscount?.Commufa2Diff > 0 ? "+" : null },
      price7: { value: standardMonthlyDiscount?.Commufa3Diff, sign: standardMonthlyDiscount?.Commufa3Diff > 0 ? "+" : null },
      price8: { value: standardMonthlyDiscount?.CumulativeAmount1Diff, sign: standardMonthlyDiscount?.CumulativeAmount1Diff > 0 ? "+" : null },
      price9: { value: standardMonthlyDiscount?.CumulativeAmount2Diff, sign: standardMonthlyDiscount?.CumulativeAmount2Diff > 0 ? "+" : null },
    },
  ];

  // OtherProvider price data
  const tableDataOtherProvider = {
    price1: { value: null },
    price2: { value: standardMonthlyOtherProvider?.Commufa1 },
    price3: { value: standardMonthlyOtherProvider?.Commufa2 },
    price4: { value: standardMonthlyOtherProvider?.Commufa3 },
    price5: { value: standardMonthlyOtherProvider?.Commufa1Diff },
    price6: { value: standardMonthlyOtherProvider?.Commufa2Diff },
    price7: { value: standardMonthlyOtherProvider?.Commufa3Diff },
    price8: { value: standardMonthlyOtherProvider?.CumulativeAmount1Diff },
    price9: { value: standardMonthlyOtherProvider?.CumulativeAmount2Diff },
  };

  // sub total price data
  const tableDataSubTotal = {
    price1: { value: standardMonthlySubtotal?.Current },
    price2: { value: standardMonthlySubtotal?.Commufa1 },
    price3: { value: standardMonthlySubtotal?.Commufa2 },
    price4: { value: standardMonthlySubtotal?.Commufa3 },
    price5: { value: standardMonthlySubtotal?.Commufa1Diff },
    price6: { value: standardMonthlySubtotal?.Commufa2Diff },
    price7: { value: standardMonthlySubtotal?.Commufa3Diff },
    price8: { value: standardMonthlySubtotal?.CumulativeAmount1Diff },
    price9: { value: standardMonthlySubtotal?.CumulativeAmount2Diff },
  };

  // total price data
  const tableDataTotal = {
    price1: { value: standardMonthlyTotal?.Current },
    price2: { value: standardMonthlyTotal?.Commufa1 },
    price3: { value: standardMonthlyTotal?.Commufa2 },
    price4: { value: standardMonthlyTotal?.Commufa3 },
    price5: { value: standardMonthlyTotal?.Commufa1Diff },
    price6: { value: standardMonthlyTotal?.Commufa2Diff },
    price7: { value: standardMonthlyTotal?.Commufa3Diff },
    price8: { value: standardMonthlyTotal?.CumulativeAmount1Diff },
    price9: { value: standardMonthlyTotal?.CumulativeAmount2Diff },
  };

  if (cPadHearingInfo?.OtherProvider?.OtherProviderPrice) {
    tableData.push(tableDataOtherProvider, tableDataSubTotal);
  }
  tableData.push(tableDataTotal);

  //Table Row Names
  const rowFields = [
    basicPriceRowName,
    optionRowName,
    discountRowName,
  ];

  if (cPadHearingInfo?.OtherProvider?.OtherProviderPrice) {
    const providerNameCell = !!SelectedProviderName?.value ? SelectedProviderName : { value: "" };
    
    rowFields.push(providerNameCell, commufaSubTotalRowName)
  }
  rowFields.push(totalRowName);

  const handleRowClick = (index) => {
    const newExpandedRows = [...expandedRows];
    if (expandedRows.includes(index)) {
      const indexToRemove = newExpandedRows.indexOf(index);
      newExpandedRows.splice(indexToRemove, 1);
    } else {
      newExpandedRows.push(index);
    }
    setExpandedRows(newExpandedRows);
  };

  return (
    <div className="cpad-tables w-100">
      <table className="border-style-1">
        <thead className="custom-thead">
          <tr>
            <th className="no-border" rowSpan={3}>
              &nbsp;
            </th>
            <th>
              <Text field={fillBlank(currentLine)} />
            </th>
            <th colSpan={3} width="257px">
              <Text field={fillBlank(commufaLine)} />
            </th>
            <th colSpan={5}>
              <Text field={fillBlank(difference)} />
            </th>
          </tr>
          <tr>
            <th rowSpan={2}>
              <Text field={fillBlank(currentLineMonthlyColName)} />
            </th>
            <th colSpan={3}>
              <Text field={fillBlank(commufaLineMonthlyGroupName)} />
            </th>
            <th colSpan={3}>
              <Text field={fillBlank(differenceMonthlyGroupName)} />
            </th>
            <th colSpan={2}>
              <Text field={fillBlank(differenceTotalGroupName)} />
            </th>
          </tr>
          <tr>
            <th>
              <Text field={fillBlank(commufaLineMonthlyForFirstYearColName)} />
            </th>
            <th>
              <Text field={fillBlank(commufaLineMonthlyForSecondYearColName)} />
            </th>
            <th width="88px">
              <Text
                field={fillBlank(commufaLineMonthlyAfterThirdYearColName)}
              />
            </th>
            <th>
              <Text field={fillBlank(differenceMonthlyForFirstYearColName)} />
            </th>
            <th>
              <Text field={fillBlank(differenceMonthlyForSecondYearColName)} />
            </th>
            <th>
              <Text field={fillBlank(differenceMonthlyAfterThirdYearColName)} />
            </th>
            <th>
              <Text field={fillBlank(defferenceForTwoYearColName)} />
            </th>
            <th>
              <Text field={fillBlank(defferenceForFiveYearColName)} />
            </th>
          </tr>
        </thead>
        <tbody className="left-align-text double-border-last-two special-border">
          {tableData.map((row, index) => (
            <React.Fragment key={index}>
              <tr>
                <td width={133}>
                  <Text field={rowFields[index]} />
                </td>
                {row.collapsableRow ? (
                  <td>
                    <div className="side-td">
                      <div>
                        {expandedRows.includes(index) ? (
                          <span onClick={() => handleRowClick(index)}>
                            <img src={CarretIcon} className="icon upward" />
                          </span>
                        ) : (
                          <span
                            className="icon"
                            onClick={() => handleRowClick(index)}
                          >
                            <img src={CarretIcon} />
                          </span>
                        )}
                      </div>
                      <div className="D-01">
                        <p>
                          {formatCurrency(row.price1.value)}
                          <span className="font-w-normal currency-sign">
                            <Text field={fillBlank(currencyUnit)} />
                          </span>
                        </p>
                      </div>
                    </div>
                  </td>
                ) : (
                  <td>
                    {row.price1.value !== null ? (
                      <>
                        {formatCurrency(row.price1.value)}
                        <span className="font-w-normal currency-sign">
                          <Text field={fillBlank(currencyUnit)} />
                        </span>
                      </>
                    ) : null}
                  </td>
                )}

                <td>
                  {formatCurrency(row.price2.value)}
                  <span className="font-w-normal currency-sign">
                    <Text field={fillBlank(currencyUnit)} />
                  </span>
                </td>
                <td>
                  {formatCurrency(row.price3.value)}
                  <span className="font-w-normal currency-sign">
                    <Text field={fillBlank(currencyUnit)} />
                  </span>
                </td>
                <td>
                  {formatCurrency(row.price4.value)}
                  <span className="font-w-normal currency-sign">
                    <Text field={fillBlank(currencyUnit)} />
                  </span>
                </td>
                <td>
                  {formatCurrency(row.price5.value, row.price6?.sign)}
                  <span className="font-w-normal currency-sign">
                    <Text field={fillBlank(currencyUnit)} />
                  </span>
                </td>
                <td>
                  {formatCurrency(row.price6.value, row.price7?.sign)}
                  <span className="font-w-normal currency-sign">
                    <Text field={fillBlank(currencyUnit)} />
                  </span>
                </td>
                <td>
                  {formatCurrency(row.price7.value, row.price8?.sign)}
                  <span className="font-w-normal currency-sign">
                    <Text field={fillBlank(currencyUnit)} />
                  </span>
                </td>
                <td>
                  {formatCurrency(row.price8.value, row.price9?.sign)}
                  <span className="font-w-normal currency-sign">
                    <Text field={fillBlank(currencyUnit)} />
                  </span>
                </td>
                <td>
                  {formatCurrency(row.price9.value, row.price5?.sign)}
                  <span className="font-w-normal currency-sign">
                    <Text field={fillBlank(currencyUnit)} />
                  </span>
                </td>
              </tr>
              {expandedRows.includes(index) && row.expandedData}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div className="txt_right mt-16 txt-note">
        <span>
          <Text field={fillBlank(taxedPriceNote)} />
        </span>
      </div>
    </div>
  );
};

const PriceSimulationGraph = (props) => {
  const [activeToggle, setActiveToggle] = useState(1);
  const currencyUnit = props?.currencyUnit;
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const {
    "PriceSimulationGraph-MonthlyPriceComparisonTitle": monthlyPriceComparisonTitle,
    "PriceSimulationGraph-SwitchMonthlyPriceGraphButtonText": switchMonthlyPriceGraphButtonText,
    "PriceSimulationGraph-SwitchActualMonthlyPriceGraphButtonText": switchActualMonthlyPriceGraphButtonText,
    "PriceSimulationGraph-BasicPrice": basicPrice,
    "PriceSimulationGraph-Option": option,
    "PriceSimulationGraph-CurrentLineActualMonthlyPriceGraphName": currentLineActualMonthlyPriceGraphName,
    "PriceSimulationGraph-ActualMonthlyPriceForTwoYearGraphName": actualMonthlyPriceForTwoYearGraphName,
    "PriceSimulationGraph-ActualMonthlyPriceForFiveYearGraphName": actualMonthlyPriceForFiveYearGraphName,
    "PriceSimulationGraph-CurrentLineMonthlyPriceGraphName": currentLineMonthlyPriceGraphName,
    "PriceSimulationGraph-MonthlyPriceForFirstYearGraphName": monthlyPriceForFirstYearGraphName,
    "PriceSimulationGraph-MonthlyPriceForSecondYearGraphName": monthlyPriceForSecondYearGraphName,
    "PriceSimulationGraph-MonthlyPriceAfterThirdYearGraphName": monthlyPriceAfterThirdYearGraphName,
    "PriceSimulationGraph-TotalForTwoYearComparisonTitle": totalForTwoYearComparisonTitle,
    "PriceSimulationGraph-CurrentLineTotalForTwoYearGraphName": currentLineTotalForTwoYearGraphName,
    "PriceSimulationGraph-TotalForTwoYearGraphName": totalForTwoYearGraphName,
    "PriceSimulationGraph-TotalForFiveYearComparisonTitle": totalForFiveYearComparisonTitle,
    "PriceSimulationGraph-CurrentLineTotalForFiveYearGraphName": currentLineTotalForFiveYearGraphName,
    "PriceSimulationGraph-TotalForFiveYearGraphName": totalForFiveYearGraphName,
    "PriceSimulationGraph-Remarks": remarks,
  } = sitecoreContext?.route?.fields;
  const apiData = props?.apiData;
  const monthlyExpenseObj = props?.monthlyExpenseObj;
  const graphLabels = apiData ? apiData[0].simulationInfo : null;
  const SelectedProviderName = {
    value: props?.SelectedProviderName,
  };
  const cPadHearingInfo = props?.cPadHearingInfo;

  const [
    monthlyExpenseChartObj,
    actualMonthlyExpenseChartObj,
    cumulativeTotalChartObj
  ] = useMemo(() => {
    return [
      createMonthlyExpenseChartObj(monthlyExpenseObj),
      createActualMonthlyExpenseChartObj(monthlyExpenseObj),
      createCumulativeTotalChartObj(monthlyExpenseObj),
    ];
  }, [monthlyExpenseObj]);

  // 月額料金
  const totalMonthlyExpenseChart = monthlyExpenseChartObj.find((item) => item.Category === STRING_TOTAL);
  const subTotalMonthlyExpenseChart = monthlyExpenseChartObj.find((item) => item.Category === STRING_SUBTOTAL);
  const standardMonthlyExpenseChart = monthlyExpenseChartObj.find((item) => item.Category === STRING_STANDARD);
  const optionMonthlyExpenseChart = monthlyExpenseChartObj.find((item) => item.Category === STRING_OPTION);
  const otherProviderMonthlyExpenseChart = monthlyExpenseChartObj.find((item) => item.Category === STRING_OTHER_PROVIDER);

  const totalMonthlyExpenseChartArray = [
    subTotalMonthlyExpenseChart.Current ?? 0,
    totalMonthlyExpenseChart.Commufa1 ?? 0,
    totalMonthlyExpenseChart.Commufa2 ?? 0,
    totalMonthlyExpenseChart.Commufa3 ?? 0,
  ];

  const subTotalMonthlyExpenseChartArray = [
    0,
    subTotalMonthlyExpenseChart.Commufa1 ?? 0,
    subTotalMonthlyExpenseChart.Commufa2 ?? 0,
    subTotalMonthlyExpenseChart.Commufa3 ?? 0,
  ];

  const standardMonthlyExpenseChartArray = [
    standardMonthlyExpenseChart.Current ?? 0,
    standardMonthlyExpenseChart.Commufa1 ?? 0,
    standardMonthlyExpenseChart.Commufa2 ?? 0,
    standardMonthlyExpenseChart.Commufa3 ?? 0,
  ];

  const optionMonthlyExpenseChartArray = [
    optionMonthlyExpenseChart.Current ?? 0,
    optionMonthlyExpenseChart.Commufa1 ?? 0,
    optionMonthlyExpenseChart.Commufa2 ?? 0,
    optionMonthlyExpenseChart.Commufa3 ?? 0,
  ];

  const otherProviderMonthlyExpenseChartArray = [
    otherProviderMonthlyExpenseChart.Current ?? 0,
    otherProviderMonthlyExpenseChart.Commufa1 ?? 0,
    otherProviderMonthlyExpenseChart.Commufa2 ?? 0,
    otherProviderMonthlyExpenseChart.Commufa3 ?? 0,
  ];

  // 実質月額
  const totalActualMonthlyExpenseChart = actualMonthlyExpenseChartObj.find((item) => item.Category === STRING_TOTAL);
  const subTotalActualMonthlyExpenseChart = actualMonthlyExpenseChartObj.find((item) => item.Category === STRING_SUBTOTAL);
  const standardActualMonthlyExpenseChart = actualMonthlyExpenseChartObj.find((item) => item.Category === STRING_STANDARD);
  const optionActualMonthlyExpenseChart = actualMonthlyExpenseChartObj.find((item) => item.Category === STRING_OPTION);
  const otherProviderActualMonthlyExpenseChart = actualMonthlyExpenseChartObj.find((item) => item.Category === STRING_OTHER_PROVIDER);
  
  const totalActualMonthlyExpenseChartArray = [
    totalActualMonthlyExpenseChart.Current ?? 0,
    totalActualMonthlyExpenseChart.CumulativeAmount1 ?? 0,
    totalActualMonthlyExpenseChart.CumulativeAmount2 ?? 0,
  ];

  const subTotalActualMonthlyExpenseChartArray = [
    0,
    subTotalActualMonthlyExpenseChart.CumulativeAmount1 ?? 0,
    subTotalActualMonthlyExpenseChart.CumulativeAmount2 ?? 0,
  ];

  const standardActualMonthlyExpenseChartArray = [
    subTotalActualMonthlyExpenseChart.Current ?? 0,
    standardActualMonthlyExpenseChart.CumulativeAmount1 ?? 0,
    standardActualMonthlyExpenseChart.CumulativeAmount2 ?? 0,
  ];

  const optionActualMonthlyExpenseChartArray = [
    optionActualMonthlyExpenseChart.Current ?? 0,
    optionActualMonthlyExpenseChart.CumulativeAmount1 ?? 0,
    optionActualMonthlyExpenseChart.CumulativeAmount2 ?? 0,
  ];

  const otherProviderActualMonthlyExpenseChartArray = [
    otherProviderActualMonthlyExpenseChart.Current ?? 0,
    otherProviderActualMonthlyExpenseChart.CumulativeAmount1 ?? 0,
    otherProviderActualMonthlyExpenseChart.CumulativeAmount2 ?? 0,
  ];

  // 累計比較
  const totalCumulativeTotalChart = cumulativeTotalChartObj.find((item) => item.Category === STRING_TOTAL);
  const subTotalCumulativeTotalChart = cumulativeTotalChartObj.find((item) => item.Category === STRING_SUBTOTAL);
  const standardCumulativeTotalChart = cumulativeTotalChartObj.find((item) => item.Category === STRING_STANDARD);
  const optionCumulativeTotalChart = cumulativeTotalChartObj.find((item) => item.Category === STRING_OPTION);
  const otherProviderCumulativeTotalChart = cumulativeTotalChartObj.find((item) => item.Category === STRING_OTHER_PROVIDER);

  const totalCumulativeForTwoYearChartArray = [
    subTotalCumulativeTotalChart.CurrentCumulativeAmount1 ?? 0,
    totalCumulativeTotalChart.CumulativeAmount1 ?? 0,
  ];

  const totalCumulativeForFiveYearChartArray = [
    subTotalCumulativeTotalChart.CurrentCumulativeAmount2 ?? 0,
    totalCumulativeTotalChart.CumulativeAmount2 ?? 0,
  ];

  const subTotalCumulativeForTwoYearChartArray = [
    0,
    subTotalCumulativeTotalChart.CumulativeAmount1 ?? 0,
  ];

  const subTotalCumulativeForFiveYearChartArray = [
    0,
    subTotalCumulativeTotalChart.CumulativeAmount2 ?? 0,
  ];

  const standardCumulativeForTwoYearChartArray = [
    standardCumulativeTotalChart.CurrentCumulativeAmount1 ?? 0,
    standardCumulativeTotalChart.CumulativeAmount1 ?? 0,
  ];

  const standardCumulativeForFiveYearChartArray = [
    standardCumulativeTotalChart.CurrentCumulativeAmount2 ?? 0,
    standardCumulativeTotalChart.CumulativeAmount2 ?? 0,
  ];

  const optionCumulativeForTwoYearChartArray = [
    optionCumulativeTotalChart.CurrentCumulativeAmount1 ?? 0,
    optionCumulativeTotalChart.CumulativeAmount1 ?? 0,
  ];

  const optionCumulativeForFiveYearChartArray = [
    optionCumulativeTotalChart.CurrentCumulativeAmount2 ?? 0,
    optionCumulativeTotalChart.CumulativeAmount2 ?? 0,
  ];

  const otherProviderCumulativeForTwoYearChartArray = [
    otherProviderCumulativeTotalChart.CurrentCumulativeAmount1 ?? 0,
    otherProviderCumulativeTotalChart.CumulativeAmount1 ?? 0,
  ];

  const otherProviderCumulativeForFiveYearChartArray = [
    otherProviderCumulativeTotalChart.CurrentCumulativeAmount2 ?? 0,
    otherProviderCumulativeTotalChart.CumulativeAmount2 ?? 0,
  ];

  const chartOptions = {
    responsive: false,
    maintainAspectRation: false,
    barPercentage: 1,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      testDrawLine: true,
      function: true,
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: (context) => {
          let value = "#fff";

          if (context.datasetIndex === 1) {
            return (value = "#000");
          }

          return value;
        },
        formatter: (value, context) => {
          if (value > 0) {
            return value?.toLocaleString() + currencyUnit;
          } else {
            return null;
          }
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 45,
        bottom: 0,
      },
      margin: {
        left: "-50px",
      },
    },
  };

  const createRoundRectPath = (ctx, x, y, width, height, radius) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
    ctx.fill();
  };

  const fillRoundRect = (ctx, x, y, w, h, r) => {
    createRoundRectPath(ctx, x, y, w, h, r);
    ctx.fill();
  };

  const dataLabelSumPlugin = (chart, exceptIndex) => {
    const ctx = chart.ctx;
    const sums = [];

    const subTotalPrices = chart.data.datasets.find((item) => item.label === STRING_SUBTOTAL)?.data ?? [];
    const totalPrices = chart.data.datasets.find((item) => item.label === STRING_TOTAL)?.data ?? [];

    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      if (!meta.hidden) {
        meta.data.forEach((element, index) => {
          if (i === 0) {
            sums[index] = 0;
          }
          ctx.fillStyle = "rgba(0,0,0,0)";
          const fontSize = 16;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          const padding = 10;
          const position = element.tooltipPosition();
          sums[index] = sums[index] + dataset.data[index];
          let txw = ctx.measureText(ctx.font);
          let txw_half = 70 / 2;
          let twx_height =
            txw.actualBoundingBoxAscent + txw.actualBoundingBoxDescent;
          if (i === chart.data.datasets.length - 1) {
            fillRoundRect(
              ctx,
              position.x - txw_half,
              position.y - 30,
              70,
              twx_height + 11,
              13
            );
            ctx.fillStyle = "#000";
            ctx.font = "bolder 10.5pt Yu Gothic";

            if (index !== exceptIndex) {
              ctx.fillText(
                formatCurrency(totalPrices[index]).toLocaleString() +
                  currencyUnit,
                position.x,
                position.y - fontSize / 2 - padding - 21
              );
              ctx.fillText(
                `(${
                  formatCurrency(subTotalPrices[index]).toLocaleString() +
                  currencyUnit
                })`,
                position.x,
                position.y - fontSize / 2 - padding - 1
              );
            } else {
              ctx.fillText(
                formatCurrency(totalPrices[index]).toLocaleString() +
                  currencyUnit,
                position.x,
                position.y - fontSize / 2 - padding - 1
              );
            }
          }
        });
      }
    });
  };

  const drawCommonLinePlugin = (tgtChart, index) => {
    return tgtChart?.data?.datasets?.forEach((dataset, datasetIndex) => {
      if (datasetIndex === index) {
        dataset.data.forEach((dataPoint, index) => {
          let bar_width = tgtChart.getDatasetMeta(datasetIndex).data[index]
            .width;
          let bar_width_half = bar_width / 2;
          tgtChart.ctx.strokeStyle = "gray";
          tgtChart.ctx.beginPath();
          tgtChart.ctx.setLineDash([1, 2]);
          tgtChart.ctx.moveTo(
            tgtChart.getDatasetMeta(datasetIndex).data[index]?.x +
              bar_width_half,
            tgtChart.getDatasetMeta(datasetIndex).data[index]?.y
          );
          tgtChart.ctx.lineTo(
            tgtChart.getDatasetMeta(datasetIndex).data[index + 1]?.x -
              bar_width_half,
            tgtChart.getDatasetMeta(datasetIndex).data[index + 1]?.y
          );
          tgtChart.ctx.stroke();
        });
      }
    });
  };

  return (
    <>
      <div className="chart-section graph-2">
        <div className="right-container">
          <div className="graph-top-section">
            <div>
              <Text field={fillBlank(monthlyPriceComparisonTitle)} tag="h2" />
            </div>
            <div className="toggle-section">
              <dl
                className={classnames("switch-toggle", {
                  on: activeToggle === 0,
                })}
              >
                <dt
                  className={classnames({
                    active: activeToggle === 1,
                  })}
                  onClick={() =>
                    activeToggle === 0 ? setActiveToggle(1) : setActiveToggle(0)
                  }
                >
                  <Text field={switchMonthlyPriceGraphButtonText} />
                </dt>
                <dd
                  className={classnames({
                    active: activeToggle === 0,
                  })}
                  onClick={() =>
                    activeToggle === 0 ? setActiveToggle(1) : setActiveToggle(0)
                  }
                >
                  <Text field={switchActualMonthlyPriceGraphButtonText} />
                </dd>
              </dl>
            </div>
          </div>
          <div className="graph-legends">
            <div className="D-01">
              <div className="chart-legend">&nbsp;</div>
              <p>
                <Text field={fillBlank(basicPrice)} />
              </p>
            </div>
            <div className="D-01">
              <div className="chart-legend">&nbsp;</div>
              <p>
                <Text field={fillBlank(option)} />
              </p>
            </div>
            {cPadHearingInfo &&
              cPadHearingInfo?.OtherProvider?.OtherProviderPrice !== null &&
              SelectedProviderName?.value && (
                <div className="D-01">
                  <div className="chart-legend">&nbsp;</div>
                  <p>
                    <Text field={fillBlank(SelectedProviderName)} />
                  </p>
                </div>
              )}
          </div>
          <div className="graph-container">
            <div className="bar-graph-item two">
              {/* 実質月額 */}
              <Bar
                data={{
                  labels: [
                    fillBlank(currentLineActualMonthlyPriceGraphName).value,
                    fillBlank(actualMonthlyPriceForTwoYearGraphName).value,
                    fillBlank(actualMonthlyPriceForFiveYearGraphName).value,
                  ],
                  datasets: [
                    {
                      // 実質月額 合計
                      label: STRING_TOTAL,
                      data: totalActualMonthlyExpenseChartArray,
                      hidden: true
                    },
                    {
                      // 実質月額 小計
                      label: STRING_SUBTOTAL,
                      data: subTotalActualMonthlyExpenseChartArray,
                      hidden: true
                    },
                    {
                      // 実質月額 基本料
                      label: fillBlank(basicPrice).value,
                      data: standardActualMonthlyExpenseChartArray,
                      backgroundColor: (color) => {
                        if (color.dataIndex === 0) {
                          return "#ED6C00";
                        } else {
                          return "#C45800";
                        }
                      },
                      borderRadius: 0,
                      categoryPercentage: 0.7,
                      barPercentage: 0.75,
                    },
                    {
                      // 実質月額 オプション
                      label: fillBlank(option).value,
                      data: optionActualMonthlyExpenseChartArray,
                      backgroundColor: ["#AAD1EF"],
                      borderRadius: 0,
                      categoryPercentage: 0.7,
                      barPercentage: 0.75,
                    },
                    {
                      // 実質月額 他社プロバイダ
                      label: fillBlank(option).value,
                      data: otherProviderActualMonthlyExpenseChartArray,
                      backgroundColor: ["#d0d0d0", "#d0d0d0"],
                      borderRadius: 0,
                      categoryPercentage: 0.7,
                      barPercentage: 0.75,
                    },
                  ],
                }}
                width={420}
                height={240}
                options={chartOptions}
                plugins={[
                  ChartDataLabels,
                  {
                    afterDraw: (tgtChart) => drawCommonLinePlugin(tgtChart, 2),
                  },
                  { afterDraw: (chart) => dataLabelSumPlugin(chart, 0) },
                ]}
                className={classnames({
                  show: activeToggle === 0,
                  hide: activeToggle === 1,
                })}
                style={{ marginLeft: "-39px" }}
              />

              {/* 月額料金 */}
              <Bar
                data={{
                  labels: [
                    fillBlank(currentLineMonthlyPriceGraphName).value,
                    [
                      fillBlank(monthlyPriceForFirstYearGraphName).value.split(
                        "\r\n"
                      ),
                      graphLabels?.simulatedUnitPriceLabel1,
                      "",
                      "",
                    ],
                    [
                      fillBlank(monthlyPriceForSecondYearGraphName).value.split(
                        "\r\n"
                      ),
                      graphLabels?.simulatedUnitPriceLabel2,
                      "",
                      "",
                    ],
                    [
                      fillBlank(
                        monthlyPriceAfterThirdYearGraphName
                      ).value.split("\r\n"),
                      graphLabels?.simulatedUnitPriceLabel3,
                      "",
                      "",
                    ],
                  ],
                  datasets: [
                    {
                      // 月額料金 合計
                      label: STRING_TOTAL,
                      data: totalMonthlyExpenseChartArray,
                      hidden: true
                    },
                    {
                      // 月額料金 小計
                      label: STRING_SUBTOTAL,
                      data: subTotalMonthlyExpenseChartArray,
                      hidden: true
                    },
                    {
                      // 月額料金 基本料
                      label: fillBlank(basicPrice).value,
                      data: standardMonthlyExpenseChartArray,
                      backgroundColor: ["#ED6C00", "#ED6C00"],
                      borderRadius: 0,
                      categoryPercentage: 0.7,
                    },
                    {
                      // 月額料金 オプション
                      label: fillBlank(option).value,
                      data: optionMonthlyExpenseChartArray,
                      backgroundColor: ["#AAD1EF", "#AAD1EF"],
                      borderRadius: 0,
                      categoryPercentage: 0.7,
                    },
                    {
                      // 月額料金 他社プロバイダ                      
                      label: fillBlank(option).value,
                      data: otherProviderMonthlyExpenseChartArray,
                      backgroundColor: ["#d0d0d0", "#d0d0d0"],
                      borderRadius: 0,
                      categoryPercentage: 0.7,
                    },
                  ],
                }}
                width={420}
                height={270}
                options={chartOptions}
                plugins={[
                  ChartDataLabels,
                  {
                    afterDraw: (tgtChart) => drawCommonLinePlugin(tgtChart, 2),
                  },
                  { afterDraw: (chart) => dataLabelSumPlugin(chart, 0) },
                ]}
                className={classnames({
                  show: activeToggle === 1,
                  hide: activeToggle === 0,
                })}
                style={{ marginLeft: "-23px" }}
              />
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="graph-top-section cus-width">
            <div>
              <Text
                field={fillBlank(totalForTwoYearComparisonTitle)}
                tag="h2"
              />
            </div>
            <Text field={fillBlank(totalForFiveYearComparisonTitle)} tag="h2" />
          </div>
          <div className="graph-legends">
            <div className="D-01">
              <div className="chart-legend">&nbsp;</div>
              <p>
                <Text field={fillBlank(basicPrice)} />
              </p>
            </div>
            <div className="D-01">
              <div className="chart-legend">&nbsp;</div>
              <p>
                <Text field={fillBlank(option)} />
              </p>
            </div>

            {cPadHearingInfo &&
              cPadHearingInfo?.OtherProvider?.OtherProviderPrice !== null &&
              SelectedProviderName?.value && (
                <div className="D-01">
                  <div className="chart-legend">&nbsp;</div>
                  <p>
                    <Text field={fillBlank(SelectedProviderName)} />
                  </p>
                </div>
              )}
          </div>
          <div className="graph-container">
            <div className="bar-graph-item two">
              {/* 累計比較 2年 */}
              <Bar
                data={{
                  labels: [
                    currentLineTotalForTwoYearGraphName.value,
                    totalForTwoYearGraphName.value,
                  ],
                  datasets: [
                    {
                      // 累計比較 2年 合計
                      label: STRING_TOTAL,
                      data: totalCumulativeForTwoYearChartArray,
                      hidden: true
                    },
                    {
                      // 累計比較 2年 小計
                      label: STRING_SUBTOTAL,
                      data: subTotalCumulativeForTwoYearChartArray,
                      hidden: true
                    },
                    {
                      // 累計比較 2年 基本料
                      label: fillBlank(basicPrice).value,
                      data: standardCumulativeForTwoYearChartArray,
                      backgroundColor: ["#ED6C00", "#ED6C00"],
                      borderRadius: 0,
                      categoryPercentage: 0.7,
                    },
                    {
                      // 累計比較 2年 オプション
                      label: fillBlank(option).value,
                      data: optionCumulativeForTwoYearChartArray,
                      backgroundColor: ["#AAD1EF", "#AAD1EF"],
                      borderRadius: 0,
                      categoryPercentage: 0.7,
                    },
                    {
                      // 累計比較 2年 他社プロバイダ
                      label: fillBlank(option).value,
                      data: otherProviderCumulativeForTwoYearChartArray,
                      backgroundColor: ["#d0d0d0", "#d0d0d0"],
                      borderRadius: 0,
                      categoryPercentage: 0.7,
                    },
                  ],
                }}
                width={210}
                height={240}
                options={chartOptions}
                plugins={[
                  ChartDataLabels,
                  {
                    afterDraw: (tgtChart) => drawCommonLinePlugin(tgtChart, 2),
                  },
                  { afterDraw: (chart) => dataLabelSumPlugin(chart, 0) },
                ]}
                style={{ marginLeft: "-23px" }}
              />
              {/* 累計比較 5年 */}
              <Bar
                data={{
                  labels: [
                    currentLineTotalForFiveYearGraphName.value,
                    totalForFiveYearGraphName.value,
                  ],
                  datasets: [
                    {
                      // 累計比較 5年 合計
                      label: STRING_TOTAL,
                      data: totalCumulativeForFiveYearChartArray,
                      hidden: true
                    },
                    {
                      // 累計比較 5年 小計
                      label: STRING_SUBTOTAL,
                      data: subTotalCumulativeForFiveYearChartArray,
                      hidden: true
                    },
                    {
                      // 累計比較 5年 基本料
                      label: fillBlank(basicPrice).value,
                      data: standardCumulativeForFiveYearChartArray,
                      backgroundColor: ["#ED6C00", "#ED6C00"],
                      borderRadius: 0,
                      categoryPercentage: 0.7,
                    },
                    {
                      // 累計比較 5年 オプション
                      label: fillBlank(option).value,
                      data: optionCumulativeForFiveYearChartArray,
                      backgroundColor: ["#AAD1EF", "#AAD1EF"],
                      borderRadius: 0,
                      categoryPercentage: 0.7,
                    },
                    {
                      // 累計比較 5年 他社プロバイダ
                      label: fillBlank(option).value,
                      data: otherProviderCumulativeForFiveYearChartArray,
                      backgroundColor: ["#d0d0d0", "#d0d0d0"],
                      borderRadius: 0,
                      categoryPercentage: 0.7,
                    },
                  ],
                }}
                width={210}
                height={240}
                options={chartOptions}
                plugins={[
                  ChartDataLabels,
                  {
                    afterDraw: (tgtChart) => drawCommonLinePlugin(tgtChart, 2),
                  },
                  { afterDraw: (chart) => dataLabelSumPlugin(chart, 0) },
                ]}
                style={{ marginLeft: "-23px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="graph-remark">
        <span>
          <Text field={fillBlank(remarks)} />
        </span>
      </div>
    </>
  );
};

const EstimateSummary = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const {
    "EstimateSummary-Title": title,
    "EstimateSummary-Total": total,
    "EstimateSummary-BasicPrice": basicPrice,
    "EstimateSummary-Discount": discount,
    "EstimateSummary-Option": option,
    "EstimateSummary-YearlyPrice": yearlyPrice,
    "EstimateSummary-InitialCost": initialCost,
    "EstimateSummary-Benefit": benefit,
    "EstimateSummary-TaxDisplay": taxDisplay,
    "EstimateSummary-OpeningDate": openingDate,
    "EstimateSummary-TotalWithOtherProvider": totalWithOtherProvider,
  } = sitecoreContext?.route?.fields;
  const monthlyExpenseObj = props?.monthlyExpenseObj;
  const currencyUnit = props?.currencyUnit;
  const benefitListForAgencyList = props?.benefitListForAgencyList;
  const cPadHearingInfo = props?.cPadHearingInfo;
  const SelectedProviderName = props?.SelectedProviderName;
  const SystemDateTime = props?.systemDateTimeData
    ? new Date(props?.systemDateTimeData)
    : "";
  SystemDateTime &&
    SystemDateTime.setDate(
      SystemDateTime.getDate() + props?.standardOpeningDays
    );
  const openingDateValue = moment(new Date(SystemDateTime)).format(
    "YYYY/MM/DD"
  );

  const monthlyTotal = monthlyExpenseObj.find((item) => item.Category === STRING_TOTAL)?.Commufa1;
  const commufaMonthlySubTotal = monthlyExpenseObj.find((item) => item.Category === STRING_SUBTOTAL)?.Commufa1;
  const standardTotal = monthlyExpenseObj.find((item) => item.Category === STRING_STANDARD)?.Commufa1;
  const discountTotal = monthlyExpenseObj.find((item) => item.Category === STRING_DISCOUNT)?.Commufa1;
  const optionTotal = monthlyExpenseObj.find((item) => item.Category === STRING_OPTION)?.Commufa1;
  const otherProviderTotal = monthlyExpenseObj.find((item) => item.Category === STRING_OTHER_PROVIDER)?.Commufa1;

  // TODO: delete test data

  const annualExpenseObj = useMemo(() => {
    return createAnnualExpenseObj();
  }, []);

  // TODO: delete test data

  const initialCostObj = useMemo(() => {
    return createInitialCostObj();
  }, []);

  // TODO: delete test data

  const benefitObj = useMemo(() => {
    return createBenefitObj();
  }, []);

  return (
    <div className="form_wrapper-input summary-section mx-16">
      <div className="heading_wrapper H-02">
        <Text field={fillBlank(title)} tag="h2" />
      </div>
      <h3 className="mb-20">
        <Text field={fillBlank(totalWithOtherProvider)} />
        &nbsp;&nbsp;&nbsp;&nbsp;{formatCurrency(monthlyTotal)} <Text field={fillBlank(currencyUnit)} />
        <span className="txt-small font-w-normal">
          &nbsp;
          <Text field={fillBlank(taxDisplay)} />
        </span>
      </h3>
      {cPadHearingInfo &&
        cPadHearingInfo.OtherProvider?.OtherProviderPrice !== null && (
          <h3 className="mb-20">
            <Text field={fillBlank(total)} />
            &nbsp;&nbsp;&nbsp;&nbsp;{formatCurrency(commufaMonthlySubTotal)}
            <Text field={fillBlank(currencyUnit)} />
            <span className="txt-small font-w-normal">
              &nbsp;
              <Text field={fillBlank(taxDisplay)} />
            </span>
          </h3>
        )}
      <ul>
        <li>
          <div className="detail">
            <div className="content-title">
              <span>
                <Text field={fillBlank(basicPrice)} />
              </span>
            </div>
            <div className="text-right">
              <p>{formatCurrencyChar(standardTotal)}</p>
            </div>
          </div>
        </li>
        <li>
          <div className="detail">
            <div className="content-title">
              <span>
                <Text field={fillBlank(discount)} />
              </span>
            </div>
            <div className="text-right">
              <p>{formatCurrencyChar(discountTotal)}</p>
            </div>
          </div>
        </li>
        <li>
          <div className="detail">
            <div className="content-title">
              <span>
                <Text field={fillBlank(option)} />
              </span>
            </div>
            <div className="text-right">
              <p>{formatCurrencyChar(optionTotal)}</p>
            </div>
          </div>
        </li>
        {/* 年額料金 */}
        {annualExpenseObj?.length > 0 && (
          <li>
            <span className="txt-small">
              <Text field={fillBlank(yearlyPrice)} />
            </span>
            {annualExpenseObj.map((item, index) => (
              <div className="detail" key={item.CMAP_ProductName__c + "_an" + index}>
                <div className="content-title">
                  <span>{item?.CMAP_ProductName__c}</span>
                </div>
                <div className="text-right">
                  <p>{formatCurrencyChar(item.PriceTaxInc)} </p>
                </div>
              </div>
            ))}
          </li>
        )}

        {/* 初期費用 */}
        {initialCostObj?.length > 0 && (
          <li>
            <span className="txt-small">
              <Text field={fillBlank(initialCost)} />
            </span>
            {initialCostObj.map((item, index) => (
              <div className="detail" key={item.CMAP_ProductName__c + "_in" + index}>
                <div className="content-title">
                  <span>{item?.CMAP_ProductName__c}</span>
                </div>
                <div className="text-right">
                  <p>{formatCurrencyChar(item.PriceTaxInc)} </p>
                </div>
              </div>
            ))}
          </li>
        )}
        {/* プラン特典 */}
        {(benefitListForAgencyList?.length > 0 || benefitObj?.length > 0) &&
          <li>
            <span className="txt-small">
              <Text field={fillBlank(benefit)} />
            </span>
            {benefitListForAgencyList?.length > 0 &&
              benefitListForAgencyList.map((item, index) => (
                <div className="detail" key={item.BenefitName + "_beA" + index}>
                  <div className="content-title">
                    <span>{item.BenefitName}</span>
                  </div>
                  <div className="text-right">
                    <p>{formatCurrency(item.BenefitPrice) + currencyUnit}</p>
                  </div>
                </div>
              ))}
            {benefitObj?.length > 0 &&
              benefitObj.map((item, index) => (
                <div className="detail" key={item.CMAP_ProductName__c + "_be" + index}>
                  <div className="content-title">
                    <span>{item?.CMAP_BenefitName__c}</span>
                  </div>
                </div>
              ))}
          </li>
        }
        {props?.orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order.CMAP_ResidenceType__c !==
          STRING_RESIDENCE_TYPE_APARTMENT && (
          <>
            <li className="no-border mb-0">
              <span>
                <Text field={fillBlank(openingDate)} />
              </span>
            </li>
            <li>
              <span>{openingDateValue} 前後</span>
            </li>
          </>
        )}
      </ul>
      {cPadHearingInfo &&
        cPadHearingInfo.OtherProvider?.OtherProviderPrice !== null && (
          <h3 className="mb-20 mt-24">
            <Text field={fillBlank(SelectedProviderName)} />
            &nbsp;&nbsp;&nbsp;&nbsp;{formatCurrency(otherProviderTotal)}
            <Text field={fillBlank(currencyUnit)} />
            <span className="txt-small font-w-normal">
              &nbsp;
              <Text field={fillBlank(taxDisplay)} />
            </span>
          </h3>
        )}
    </div>
  );
};

const AgencyInfo = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const {
    "AgencyInfo-PrintButtonText": printButtonText,
    "AgencyInfo-PrintButtonIcon": printButtonImage,
    "AgencyInfo-NextButtonLink": nextButtonLink,
  } = sitecoreContext?.route?.fields;

  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  const inputValues = props?.inputValues;
  const PersonalizeInfo = props?.PersonalizeInfo;

  const agencyInfoInputsHolder = getScDataFromPlaceholder(
    "agency-info-inputs-section",
    props
  );
  const agencyInfoButtonsHolder = getScDataFromPlaceholder(
    "agency-info-buttons-section",
    props
  );
  const [
    i01AccountName,
    i01StoreName,
    i01MICNo,
    i01StaffName,
    i01Phone,
  ] = getScDataFromComponent("I-01", agencyInfoInputsHolder);

  i01AccountName.fields.inputValue = {
    name: "AccountName",
    value: inputValues?.AccountName,
  };
  i01StoreName.fields.inputValue = {
    name: "StoreName",
    value: inputValues?.StoreName,
  };
  i01MICNo.fields.inputValue = {
    name: "MICNo",
    value: inputValues?.MICNo,
  };
  i01StaffName.fields.inputValue = {
    name: "StaffName",
    value: inputValues?.StaffName,
  };
  i01Phone.fields.inputValue = {
    name: "Phone",
    value: inputValues?.Phone,
  };

  const [m01iDescDateData, m01iEstDueDateData] = getScDataFromComponent(
    "M-01i",
    agencyInfoInputsHolder
  );

  m01iDescDateData.fields.initialValue = {
    name: "descDate",
    value: inputValues?.DescriptionDate,
  };
  m01iDescDateData.fields.calendarPlaceholderFormat = "yyyy/MM/dd";

  m01iEstDueDateData.fields.initialValue = {
    name: "estimateDueDate",
    value: inputValues?.EstimateDueDate,
  };
  m01iEstDueDateData.fields.calendarPlaceholderFormat = "yyyy/MM/dd";
  const [b02iPrintBtnData] = getScDataFromComponent(
    "B-02i",
    agencyInfoButtonsHolder
  );
  const isUserAgent =
    props?.userAgent?.indexOf(STRING_USERAGENT_CPAD_APP) === -1 ? false : true;
  b02iPrintBtnData.fields.buttonLabel = printButtonText;
  b02iPrintBtnData.fields.isHidden = isUserAgent ? false : true;
  b02iPrintBtnData.fields.onClickEvent = () => {
    // TODO: Do the test on this part when we have the ctc devices.
    showPrintDialog();
  };

  // @Controller
  const handleInputChange = (value, name) => {
    props.setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  i01AccountName.fields.onChangeEvent = (e) =>
    handleInputChange(e.target.value, "AccountName");
  i01StoreName.fields.onChangeEvent = (e) =>
    handleInputChange(e.target.value, "StoreName");
  i01MICNo.fields.onChangeEvent = (e) =>
    handleInputChange(e.target.value, "MICNo");
  i01StaffName.fields.onChangeEvent = (e) =>
    handleInputChange(e.target.value, "StaffName");
  i01Phone.fields.onChangeEvent = (e) =>
    handleInputChange(e.target.value, "Phone");
  m01iDescDateData.fields.onChangeEvent = (date) =>
    handleInputChange(format(date, "yyyy-MM-dd"), "DescriptionDate");
  m01iEstDueDateData.fields.onChangeEvent = (date) =>
    handleInputChange(format(date, "yyyy-MM-dd"), "EstimateDueDate");
  if (
    PersonalizeInfo.SalesChannelSelect === STRING_DOOR_TO_DOOR ||
    PersonalizeInfo.SalesChannelSelect === STRING_TELE_MARKET ||
    PersonalizeInfo.SalesChannelSelect === STRING_WEB
  ) {
    i01StoreName.fields.isHidden = { value: true };
  } else {
    i01StoreName.fields.isHidden = { value: false };
  }

  const storeSessionsStorageValues = (inputValues) => {
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ACCOUNTNAME,
      inputValues?.AccountName,
      true
    );

    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STORENAME,
      inputValues?.StoreName,
      true
    );

    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_DESCRIPTIONDATE,
      inputValues?.DescriptionDate,
      true
    );

    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_MICNO,
      inputValues?.MICNo,
      true
    );

    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ESTIMATEDUEDATE,
      inputValues?.EstimateDueDate,
      true
    );

    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STAFFNAME,
      inputValues?.StaffName,
      true
    );

    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_PHONE,
      inputValues?.Phone,
      true
    );
  };
  const backButtonHolder = getScDataFromPlaceholder("button-section", props);
  const nextButtonHolder = getScDataFromPlaceholder("button-section", props);
  const [backButton] = getScDataFromComponent("B-02", backButtonHolder);
  const [nextButton] = getScDataFromComponent("B-01", nextButtonHolder);

  backButton.fields.onClickEvent = () => {
    storeSessionsStorageValues(inputValues);
    redirectToPreviousPage();
  };

  nextButton.fields.onClickEvent = () => {
    storeSessionsStorageValues(inputValues);
    redirect(nextButtonLink.value.href);
  };

  return (
    <>
      <div className="form_wrapper-input agency-info-section mx-16">
        <Placeholder name="agency-info-section" rendering={props.rendering} />
        <div className="grid-column two side-label-input custom-width">
          <Placeholder
            name="agency-info-inputs-section"
            rendering={props.rendering}
          />
        </div>

        <Placeholder
          name="agency-info-buttons-section"
          rendering={props.rendering}
        />
      </div>
    </>
  );
};

const EstimationResultButtonArea = (props) => {
  return (
    <>
      <div className="bottom-section chart-bottom-section center">
        <Placeholder name="button-section" rendering={props.rendering} />
      </div>
    </>
  );
};

const CPadPriceSimulationPageLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);
  const { setError, clearErrors } = useFormContext();

  // TODO: delete test data

  const orderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );
  const HearingInfo = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADHEARING_HEARINGINFO,
    true
  );

  const { BenefitListForAgency: BenefitListForAgency } = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_SELECTPRODUCT_BENEFITLISTFORAGENCY,
    true
  );
  const { ProductSelectionList: productSelectionList } = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_SELECTPRODUCT_PRODUCTSELECTIONLIST,
    true
  );
  const PersonalizeInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );

  const currencyUnit = STRING_CURRENCY_CHAR;

  // get the data
  const systemDateTimeURI = `${apiBaseURL}common/RetrieveSystemDateTime`;
  const {
    data: systemDateTimeData,
    isLoading: systemDateTimeIsLoading,
    error: systemDateTimeError,
    refetch: systemDateTimeRefresh,
    remove: systemDateTimeRemove,
  } = useCustomQuery(systemDateTimeURI, true, STRING_GET);

  const retrieveFeeSimulationURI = `${apiBaseURL}order/RetrieveFeeSimulation`;
  const orderItemInfo = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos;
  const orderItemArray = orderItemInfo?.reduce((a, { orderItem: item }) => {
    if (
      item.CMAP_ProductSelectionState__c !== STRING_DESELECTED &&
      item.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED
    ) {
      const ProductSelectionMethod = productSelectionList?.find(
        (sel) => sel.Product2Id === item.Product2Id
      );
      a.push({
        orderItem: {
          CMAP_MainSubProductGroupName__c: item.CMAP_MainSubProductGroupName__c,
          Product2Id: item.Product2Id,
          UnitPrice: item.UnitPrice,
          CMAP_UnitPriceTaxIncluded__c: item.CMAP_UnitPriceTaxIncluded__c,
          CMAP_PriceCategory__c: item.CMAP_PriceCategory__c,
          CMAP_InstallmentPaymentMonthly__c:
            item.CMAP_InstallmentPaymentMonthly__c,
          CMAP_InstallmentPayMonthlyPriceTaxExc__c:
            item.CMAP_InstallmentPayMonthlyPriceTaxExc__c,
          CMAP_InstallmentPayMonthlyPriceTaxInc__c:
            item.CMAP_InstallmentPayMonthlyPriceTaxInc__c,
          CMAP_NumberOfInstallments__c: item.CMAP_NumberOfInstallments__c,
          CMAP_InstallmentPayFirstMonthPriceTaxExc__c:
            item.CMAP_InstallmentPayFirstMonthPriceTaxExc__c,
          CMAP_InstallmentPayFirstMonthPriceTaxInc__c:
            item.CMAP_InstallmentPayFirstMonthPriceTaxInc__c,
          CMAP_SelectionAnnualPayment__c: item.CMAP_SelectionAnnualPayment__c,
          CMAP_AnnualPaymentPriceTaxExcluded__c:
            item.CMAP_AnnualPaymentPriceTaxExcluded__c,
          CMAP_AnnualPaymentPriceTaxIncluded__c:
            item.CMAP_AnnualPaymentPriceTaxIncluded__c,
          CMAP_Unchargeable__c: item.CMAP_Unchargeable__c,
          CMAP_BenefitProduct__c: item.CMAP_BenefitProduct__c,
          CMAP_GrantUnitPrice__c: item.CMAP_GrantUnitPrice__c,
          CMAP_GrantPoint__c: item.CMAP_GrantPoint__c,
          CMAP_OrderItemTypeSelection__c: item.CMAP_OrderItemTypeSelection__c,
          CMAP_OrderItemTypeBenefit__c: item.CMAP_OrderItemTypeBenefit__c,
          CMAP_OrderItemTypeAutomaticSelection__c:
            item.CMAP_OrderItemTypeAutomaticSelection__c,
          CMAP_ProductSelectionMethod__c: ProductSelectionMethod
            ? ProductSelectionMethod.CMAP_ProductSelection__c
            : "",
          CMAP_BenefitNameForCpad__c: "",
          CMAP_BenefitPriceForCpad__c: "",
          Quantity: item.Quantity ? item.Quantity : 1,
        },
      });
    }
    return a;
  }, []);
  const benefitListArray = BenefitListForAgency?.map((benefit) => ({
    orderItem: {
      CMAP_MainSubProductGroupName__c: "",
      Product2Id: "",
      UnitPrice: "",
      CMAP_UnitPriceTaxIncluded__c: "",
      CMAP_PriceCategory__c: "",
      CMAP_InstallmentPaymentMonthly__c: "",
      CMAP_InstallmentPayMonthlyPriceTaxExc__c: "",
      CMAP_InstallmentPayMonthlyPriceTaxInc__c: "",
      CMAP_NumberOfInstallments__c: "",
      CMAP_InstallmentPayFirstMonthPriceTaxExc__c: "",
      CMAP_InstallmentPayFirstMonthPriceTaxInc__c: "",
      CMAP_SelectionAnnualPayment__c: "",
      CMAP_AnnualPaymentPriceTaxExcluded__c: "",
      CMAP_AnnualPaymentPriceTaxIncluded__c: "",
      CMAP_Unchargeable__c: "",
      CMAP_BenefitProduct__c: "",
      CMAP_GrantUnitPrice__c: "",
      CMAP_GrantPoint__c: "",
      CMAP_OrderItemTypeSelection__c: "",
      CMAP_OrderItemTypeBenefit__c: "",
      CMAP_OrderItemTypeAutomaticSelection__c: "",
      CMAP_ProductSelectionMethod__c: "",
      CMAP_BenefitNameForCpad__c: benefit.BenefitName,
      CMAP_BenefitPriceForCpad__c: benefit.BenefitPrice,
      Quantity: "",
    },
  }));

  const payloadRequest = {
    orderRelatedInfo: {
      orderInfo: {
        order: {
          CMAP_OrderCategory__c:
            orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_OrderCategory__c,
        },
        orderItemInfos: [
          ...(orderItemArray?.length > 0 ? orderItemArray : []),
          ...(benefitListArray?.length > 0 ? benefitListArray : []),
        ],
      },
    },
  };

  const {
    data: retrieveFeeSimulationData,
    isLoading: retrieveFeeSimulationIsLoading,
    error: retrieveFeeSimulationError,
    refetch: retrieveFeeSimulationRefresh,
    remove: retrieveFeeSimulationRemove,
  } = useCustomQuery(
    retrieveFeeSimulationURI,
    true,
    STRING_POST,
    payloadRequest
  );

  const isApiLoading = systemDateTimeIsLoading || retrieveFeeSimulationIsLoading;
  props.setIsLoading(isApiLoading)
  
  const SelectedProviderName = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADHEARING_SELECTEDPROVIDERNAME,
    true
  );

  const accountName = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ACCOUNTNAME,
    true
  );
  const StoreName = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STORENAME,
    true
  );
  const descriptionDate = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_DESCRIPTIONDATE,
    true
  );
  const micNo = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_MICNO,
    true
  );
  const estimateDueDate = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ESTIMATEDUEDATE,
    true
  );
  const staffName = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STAFFNAME,
    true
  );
  const phone = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_PHONE,
    true
  );

  const hasAgencyInfoSessionData =
    accountName ||
    StoreName ||
    descriptionDate ||
    micNo ||
    estimateDueDate ||
    staffName ||
    phone;
  // @Model
  const [inputValues, setInputValues] = useState({
    AccountName: hasAgencyInfoSessionData
      ? accountName
      : PersonalizeInfo?.AccountName,
    StoreName: hasAgencyInfoSessionData
      ? StoreName
      : PersonalizeInfo?.StoreName,
    DescriptionDate: descriptionDate,
    MICNo: hasAgencyInfoSessionData ? micNo : PersonalizeInfo?.MICNo,
    EstimateDueDate: estimateDueDate,
    StaffName: hasAgencyInfoSessionData
      ? staffName
      : PersonalizeInfo?.StaffName,
    Phone: hasAgencyInfoSessionData ? phone : PersonalizeInfo?.Phone,
  });

  const [monthlyExpenseObj, setMonthlyExpenseObj] = useState([]);
  const [userAgent, setUserAgent] = useState("");
  // @Controller
  useEffect(() => {
    if (retrieveFeeSimulationData?.Result === false) {
      setError(retrieveFeeSimulationData?.ErrorCode, {
        type: "manual",
        message: retrieveFeeSimulationData?.ErrorMassage,
      });
    }
    
    if (retrieveFeeSimulationData?.Result === true) {
      setMonthlyExpenseObj(createMonthlyExpenseObj(retrieveFeeSimulationData.orderRelatedInfo.orderInfo.orderItemInfos));
    }
  }, [retrieveFeeSimulationData]);

  useEffect(() => {
    setUserAgent(window.navigator.userAgent);
  }, []);

  // @View
  return (
    <React.Fragment>
      <main>
        <div className="form_container cpad_wrapper2">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <PriceSimulationTable
                rendering={props.rendering}
                currencyUnit={currencyUnit}
                apiData={
                  retrieveFeeSimulationData?.orderRelatedInfo?.orderInfo
                    ?.orderItemInfos
                }
                SelectedProviderName={SelectedProviderName}
                cPadHearingInfo={HearingInfo}
                monthlyExpenseObj={monthlyExpenseObj}
              />
              <PriceSimulationGraph
                rendering={props.rendering}
                currencyUnit={currencyUnit}
                apiData={
                  retrieveFeeSimulationData?.orderRelatedInfo?.orderInfo
                    ?.orderItemInfos
                }
                SelectedProviderName={SelectedProviderName}
                cPadHearingInfo={HearingInfo}
                monthlyExpenseObj={monthlyExpenseObj}
              />
              <EstimateSummary
                rendering={props.rendering}
                currencyUnit={currencyUnit}
                SelectedProviderName={SelectedProviderName}
                systemDateTimeData={systemDateTimeData?.SystemDateTime}
                standardOpeningDays={
                  orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
                    ?.CMAP_StandardOpeningDays__c
                }
                benefitListForAgencyList={BenefitListForAgency}
                cPadHearingInfo={HearingInfo}
                orderRelatedInfo={orderRelatedInfo}
                monthlyExpenseObj={monthlyExpenseObj}
              />
              <AgencyInfo
                rendering={props.rendering}
                currencyUnit={currencyUnit}
                PersonalizeInfo={PersonalizeInfo}
                inputValues={inputValues}
                setInputValues={setInputValues}
                userAgent={userAgent}
              />
              <EstimationResultButtonArea rendering={props.rendering} />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(CPadPriceSimulationPageLayout);
