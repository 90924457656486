import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank, getProperData, nullChecker } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

// @Model

// get the data

// update the context

// @Controller

const S06 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    tagLabel,
    titleText,
    price,
    currency,
    descriptionText,
    optionLabel,
    optionLabel2,
    isHidden,
    arrowIcon,
  } = props.fields;

  const isHiddenOption = nullChecker(properData?.isHiddenOption)?.value;

  // @View
  return (
    <React.Fragment>
      {!isHidden && (
        <div className="input-form S-06">
          <div className="pull-down-arrow">
            <div className="input-box plan">
              <div className="tag yellow">
                <span>
                  <Text field={fillBlank(tagLabel)} />
                </span>
              </div>
              <div className="input-container">
                <label>
                  <p>
                    <Text field={fillBlank(titleText)} />
                  </p>
                  <span className="right">
                    <p>
                      <Text field={fillBlank(price)} />
                    </p>
                    <Text field={fillBlank(currency)} />
                  </span>
                  <p>
                    <Text field={fillBlank(descriptionText)} />
                  </p>
                </label>
                {!isHiddenOption && (
                  <div className="input-box-container">
                    <div className="input-box">
                      <div className="input-container">
                        <input
                          type="radio"
                          name="rdOptionS06"
                          data-toggle="modal"
                          data-target="#samplePopup"
                          onChange={properData.onChangeEvent}
                          value={optionLabel?.value}
                        />
                        <label htmlFor="rdOptionS06">
                          <Text field={fillBlank(optionLabel)} />
                        </label>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-container">
                        <input
                          type="radio"
                          name="rdOptionS06"
                          onChange={properData.onChangeEvent}
                          value={optionLabel2?.value}
                        />
                        <label htmlFor="rdOptionS06">
                          <Text field={fillBlank(optionLabel2)} />
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="arrow-down">
              <img src={fillBlank(arrowIcon)?.value?.src} />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default S06;
