import ja from "date-fns/locale/ja";
import { apiBaseURL } from "../envSettings";

// Fetch
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_ERROR = "FETCH_ERROR";

// API
export const STRING_POST = "POST";
export const STRING_GET = "GET";
export const API_RESPONSE_STATUS = {
  SUCCESS: "success",
  ERROR: "error",
  IDLE: "idle",
  LOADING: "loading",
};

// Errors path
export const STRING_ERROR_403_URI = "/error/403";
export const STRING_ERROR_500_URI = "/error/500";

// Login urls
export const STRING_LOGIN_URL = `${apiBaseURL}loginuser/login`;

// Window
export const SMALL_WINDOW_PARAMS =
  "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=800,height=800";
export const SAME_WINDOW = "_self";
export const ANOTHER_WINDOW = "_blank";
export const SMALL_WINDOW = "_popup";

// Path
export const STRING_SIMULATOR = "simulator";
export const STRING_APPLY = "apply";
export const STRING_MOVING = "moving";
export const STRING_REBUILDING = "rebuilding";
export const STRING_CHANGEPLAN = "changeplan";
export const STRING_CHANGEITEM = "changeitem";
export const STRING_TERMINATE = "terminate";
export const STRING_CANCEL = "cancel";
export const STRING_ASSIGN = "assign";
export const STRING_RELOCATE = "relocate";

// Urls
export const STRING_CPAD_URL = "cpadjss.dev.local";
export const STRING_MYCOMMUFA_URL = "masterjss.dev.local";

// Date
export const ISO_EMPTY_DATE = "0001-01-01T00:00:00Z";
export const DATE_LOCALE = {
  locale: ja,
};
export const DATE_FORMAT_YYYY年M月 = "yyyy年M月";
export const DATE_FORMAT_YYYYMM = "yyyyMM";

// Plan types
export const STRING_CIRCUIT = "Circuit";
export const STRING_WIRELESS_CIRCUIT = "WirelessCircuit";
export const STRING_NOT_CIRCUIT = "NotCircuit";
export const STRING_GOODS_SERVICE_SALES = "GoodsServiceSales";

// Contract Name
export const STRING_INDIVIDUAL = "Individual";
export const STRING_CORPORATION = "Corporation";

// Residence type
export const STRING_RESIDENCE_TYPE_HOME = "Home";
export const STRING_RESIDENCE_TYPE_APARTMENT = "Apartment";

// Categories
export const STRING_CATEGORY_NEW_CONTRACT = "NewContractPurchace";
export const STRING_CATEGORY_MOVEMENT = "Movement";
export const STRING_CATEGORY_TRANSFER = "Transfer";
export const STRING_CATEGORY_PLAN_CHANGE = "PlanChange";
export const STRING_CATEGORY_PRODUCT_CHANGE = "ProductChange";
export const STRING_CATEGORY_CANCEL = "Cancel";
export const STRING_CATEGORY_ORDER_CHANGE = "OrderChange";
export const STRING_CATEGORY_INDIVIDUAL_CONTRACT_CHANGE =
  "IndividualContractChange";

// Current section select
export const STRING_CURRENT_SECTION_SELECT_PLAN = "selectPlanSection";
export const STRING_CURRENT_SECTION_SELECT_PRODUCT = "selectProductSection";
export const STRING_CURRENT_SECTION_SELECT_BENEFIT = "selectBenefitSection";
export const STRING_CURRENT_SECTION_SELECT_PLAN_ATTRIBUTES =
  "selectPlanAttributesSection";

// Option
export const STRING_OPTION_CHANGE = "変更する";
export const STRING_OPTION_NOT_CHANGE = "変更しない";

// Plan exist
export const STRING_PLAN_NOT_EXIST =
  "現在ご契約中のプランは引き続きご利用いただくことが出来ません。新しくご希望のプランを選択してください。";
export const STRING_PLAN_EXIST = "変更後のプランを選択してください";

// Selected data
export const STRING_SELECTED_PACKAGE = "s15PackageSelectionData";
export const STRING_SELECTED_BUNDLE = "s15BundleSelectionData";
export const STRING_SELECTED_PLAN = "s15PlanSelectionData";

// Types
export const STRING_TYPE_K = "Type K";
export const STRING_TYPE_S = "Type S";

// LUMPSUM Payment
export const STRING_CATEGORY_TERMINATION_REMAINING_DEBT_LUMPSUM_PAYMENT =
  "TerminationRemainingDebtLumpSumPayment";
export const STRING_LUMPSUMPAYMENTINSTALLMENTPAYMENT =
  "LumpSumPaymentInstallmentPayment";

// Product
export const STRING_PRODUCT_COPY = "product_copy_";

// Benefits
export const STRING_PLAN_BENEFITS_SECTION = "plan-benefits-section";
export const STRING_BENEFIT_INFO_COPY = "benefit_info_copy_";

// CMAP strings
export const STRING_CREDIT_CARD = "CreditCard";
export const STRING_INDIVIDUAL_CONTRACT_INFO = "individualContractInfo";
export const STRING_INDIVIDUAL_CONTRACT = "individualContract";
export const STRING_BANK_ACCOUNT_TRANSFER = "BankAccountTransfer";
export const STRING_SPECIAL_NOTES = "CMAP_SpecialNotes__c";
export const STRING_SELECTED = "Selected";
export const STRING_DESELECTED = "Deselected";

// Quantity Type
export const STRING_FIXED_QUANTITY = "FixedQuantity";
export const STRING_FLEXIBLE_QUANTITY = "FlexibleQuantity";
export const STRING_UNIT_QUANTITY = "円(税込)/1";

// Numerical Input
export const STRING_HIDE = "hide";
export const STRING_QUANTITY_LABEL = "quantity_label";
export const STRING_QUANTITY_INPUT = "quantity_input";

// Payment Label
export const STRING_PAYMENT_INSTALLMENT = "分割払い";
export const STRING_PAYMENT_ANNUAL = "年額払い";

// Price Category
export const STRING_LUMPSUMPAYMENT = "LumpSumPayment";
export const STRING_LUMPSUMINSTALLMENTPAYMENT = "LumpSumInstallmentPayment";
export const STRING_MONTHLYPAYMENT = "MonthlyPayment";
export const STRING_AGENTCLAIM = "AgentClaim";
export const STRING_MONTHLYPAYMENTANNUALPAYMENT = "MonthlyPaymentAnnualPayment";
export const STRING_MONTHLYANNUALPAYMENT = "MonthlyAnnualPayment";
export const STRING_ANNUALPAYMENT = "AnnualPayment";
export const STRING_SUSPENDSELECTED = "SuspendSelected";
export const STRING_INSTALLMENTPAYMENT = "InstallmentPayment";
export const STRING_SIXTY_INSTALLPAYMENT = "SixtyInstallmentPayment";

// error message
export const STRING_S17_ERROR_MESSAGE =
  "申込セット内承認の合計価格がセット商品価格を超えています";

// input type
export const STRING_RADIO = "radio";
export const STRING_CHECKBOX = "checkbox";
export const STRING_BULLET = "bullet";

// dependency type

export const STRING_DEPENDENCY_SINGLE = "DependenceSingleProduct";
export const STRING_DEPENDENCY_GROUP = "DependenceGroupProduct";

// none of the above
export const STRING_NOT_SELECTED = "選択しない";

// product type
export const STRING_SINGLE = "single";
export const STRING_PRODUCT_SET = "productSet";

// additional class with-image
export const STRING_WITH_IMAGE = "with-image";
export const STRING_WITH_TOP_BOTTOM_MARGIN = "with-top-bottom-margin";

// Price Type
export const STRING_PRICE_TYPE_LUMPSUM = "一括払い";
export const STRING_PRICE_TYPE_INSTALLMENT = "分割払い/月";
export const STRING_PRICE_TYPE_ANNUAL = "年額払い";

// Price Unit
export const STRING_YEN_TAX_INCLUDED = "円(税込)";
export const STRING_YEN_TAX_INCLUDED_MONTH = "円(税込)/月";
export const STRING_YEN_TAX_INCLUDED_YEAR = "円(税込)/年";

// Product Selection Method
export const STRING_METHOD_STANDARDOFFER = "StandardOffer";
export const STRING_METHOD_SELECTABLESTANDARDOFFER = "SelectableStandardOffer";
export const STRING_METHOD_OPTION = "Option";
export const STRING_METHOD_SELECTABLEOPTION = "SelectableOption";
export const STRING_METHOD_UNSELECTABLE = "Unselectable";

export const STRING_BROADCASTING_CODE_CATEGORY =
  "CMAP_BroadcastingCodeCategory__c";
export const STRING_DOOR_TO_DOOR = "訪販";
export const STRING_TELE_MARKET = "テレマ訪販";
export const STRING_WEB = "販路";

// User Agents
export const STRING_USERAGENT_CPAD_APP = "c-pad_app";
export const STRING_USERAGENT_MOBILE_APP = "commufa_app";

// Credit Cards Name
export const STRING_CARD_VISA = "VISA";
export const STRING_CARD_MASTER = "Master";
export const STRING_CARD_JCB = "JCB";
export const STRING_CARD_AMEX = "AMEX";
export const STRING_CARD_DINERS = "Diners";

// for CommonApplicationInformationList
export const STRING_APPINFO_PASCO = "/commufa/map/map.asp";
export const STRING_APPINFO_SERVICEAVAILABLE = "service-available-section";
export const STRING_APPINFO_SERVICEUNAVAILABLE = "service-unavailable-section";
export const STRING_APPINFO_PLANTYPE = "plantype";
export const STRING_APPINFO_APARTMENTSEARCH = "apartment_search";
export const STRING_APPINFO_APARTMENTAVAILABLE = "apartment_available";
export const STRING_APPINFO_APARTMENTUNAVAILABLE = "apartment_unavailable";
export const STRING_APPINFO_HOMEAVAILABLE = "home_available";
export const STRING_APPINFO_HOMEUNAVAILABLE = "home_unavailable";

// Member register types
export const MEMBER_REGISTER_TYPE_1 = "1";
export const MEMBER_REGISTER_TYPE_2 = "2";
export const MEMBER_REGISTER_TYPE_3 = "3";

// Others
export const STRING_NO_PLAN_ERROR_MESSAGE = "選択できるプランがありません。";
export const ORDER_CONDITION = {
  VALID: "Valid",
  INVALID: "Invalid",
  CANCELED: "Canceled",
};

export const ORDER_CONDITION_TEXT = {
  COMPLETION: "完了",
  INVALID: "無効",
  CANCEL: "キャンセル",
  APPLYING: "申込中",
  PROCESSING: "処理中",
};

//Hearing Info Type
export const HEARING_INFO_TYPE = {
  SERVICE_PRICE: "ServicePrice",
  OPTION_PRICE: "OptionPrice",
};

//Cookie
export const COOKIE_EXPIRATION_DATE = "Thu, 01 Jan 1970 00:00:01 GMT";

export const STRING_MYCOMMUFFA_CAROUSEL_PLACEHOLDER = "image-carousel-section";
export const STRING_CPAD_CAROUSEL_PLACEHOLDER =
  "cPadMainVisual-carousel-section";
export const STRING_HIKARI_TV_SERVICE = "光テレビサービス";
export const STRING_STORE = "店舗";
export const buttonConditionCode = {
  necessary: "Necessary",
  notNecessary: "NotNecessary",
};
export const STRING_OTHER = "other";
export const STRING_INPUT_CONTENT = "input-content";
export const STRING_NOT_APPLIED = "適用しない";
export const STRING_STANDARD = "Standard";
export const STRING_DISCOUNT = "Discount";
export const STRING_OPTION = "Option";
export const STRING_OTHER_PROVIDER = "OtherProvider";
export const STRING_SUBTOTAL = "Subtotal";
export const STRING_TOTAL = "Total";
export const STRING_CURRENCY_CHAR = "円";
export const STRING_VALUE = "Value";
export const STRING_DESCRIPTION = "Description";
export const STRING_ADDED = "Added";
export const TAX_RATE_REDUCED = 8.0;
export const TAX_RATE_NORMAL = 10.0;

export const SP_BREAKPOINT = 640;

export const STRING_ORDER_CHANNEL_APPLIANCE = "ApplianceStore";
export const STRING_ORDER_CHANNEL_AUSHOP = "AuShop";

export const STRING_MONTHLY_CURRENCY_TEXT = "円(税込)/月";
export const STRING_ANNUAL_CURRENCY_TEXT = "円(税込)/年";
export const STRING_LUMPSUM_CURRENCY_TEXT = "円(税込)";
export const QUANTITY_DROPDOWN_VALUE = 3;
export const STRING_BEFORECHANGE_SELECTED = "これまで";
export const STRING_BEFORECHANGE_CHECKBOX = "既契約引継";

export const STRING_REQUEST_WEB = "Web";

//GraphQL loading response
export const STRING_LOADING = "loading...";

export const STRING_SMART_HOME = "smarthome";
export const STRING_ALTERNATIVE_PAYMENT = "alternativePayment";

export const STRING_REQUIRED = "必須";

export const STRING_SIMPLE_OP_APPLICABLE_MINDATE = 6;
export const STRING_SIMPLE_OP_SESSION_KEY = "SIMPLE_OP";
export const STRING_SIMPLE_OP_WEB_APPLIACTION = "Web受付";
export const STRING_SIMPLE_OP_APPLICATION = "申込";
export const STRING_SIMPLE_OP_LOGIN_USER = "ログイン申込";
export const STRING_SIMPLE_OP_NOT_LOGIN_USER = "未ログイン申込";
export const STRING_SIMPLE_OP_CONTENT_LABEL = "内訳";
export const STRING_SIMPLE_OP_QUANTITY_LABEL = "数量";
export const STRING_SIMPLE_OP_PRICE_LABEL = "金額";
export const STRING_SIMPLE_OP_CONSUMPTION_TAX_LABEL = "消費税";
export const STRING_SIMPLE_OP_SUMMARY_LABEL = "小計";
export const STRING_SIMPLE_OP_UNEXT_COMMON_KEY = "UNEXT";
export const STRING_SIMPLE_OP_UNEXT_APPLY_ATTR = "UNEXTApply__c";
export const STRING_SIMPLE_OP_KURASHI_COMMON_KEY = "Kurashi";
export const STRING_SIMPLE_OP_KURASHI_APPLY_ATTR = "KurashiSupportApply__c";
export const STRING_SIMPLE_OP_DAZN_COMMON_KEY = "DAZN";
export const STRING_SIMPLE_OP_DAZN_APPLY_ATTR = "DAZNPlan__c";
export const STRING_SIMPLE_OP_DAZN_APPLY_MONTHLY_VAL = "月額プラン";
export const STRING_SIMPLE_OP_DAZN_APPLY_ANNUAL_VAL = "年払いプラン（一括払い）";
export const STRING_SIMPLE_OP_SH_DEVICE_KEY = "SmartHomeDeviceNum__c";
export const STRING_SIMPLE_OP_SH_PAYMENT_ATTR = "SmartHomePayment__c";
export const STRING_SIMPLE_OP_SH_APPLY_SIXTY_VAL = "60回払い";
export const STRING_SIMPLE_OP_SH_APPLY_LUMPSUM_VAL = "一括払い";
export const STRING_SIMPLE_OP_CONFIRMATION_TEXT = "（確認用）";