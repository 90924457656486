import React, { useContext, useEffect, useState } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
  getProperData,
} from "../../utils/commonUtils";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import { useFormContext } from "react-hook-form";
import { apiBaseURL } from "../../envSettings";
import { useCustomQuery } from "../../hooks/useGetData";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import {
  STRING_INDIVIDUAL,
  STRING_CORPORATION,
  STRING_POST,
  STRING_GET,
  STRING_APPLY,
  STRING_SIMULATOR,
  STRING_MOVING,
  STRING_REBUILDING,
  STRING_RESIDENCE_TYPE_APARTMENT,
  STRING_REQUEST_WEB,
} from "../../utils/constantVariables";
import { apiResultCode } from "../../assets/consts";
import ModalContext from "../../assets/context/ModalContext";
import {
  setSessionStorageItem,
  getSessionStorageItem,
} from "../../utils/useSessionStorage";
import { setSessionStorageData } from "./saveSessionStorageData";
import { saveCustomerInfoData, validatePostalCode } from "./saveCustomerInfo";
import { useSetSelectedItem } from "../../hooks/useSelectedState";

export function RegisterContactAddress(props) {
  const {
    inputValues,
    setInputValues,
    isNotEncrypt,
    isFirstTimeDisplay,
    accountId,
    graphQlData,
    retrieveCustomerInfo,
    onChangeEventInput,
    orderRelatedInfo,
    planType,
    cMAPContractName,
    properties,
    isTypeCircuit,
    metadataDefinitions,
    pageContext,
    sitecoreContext,
    urlParentItem,
    retrieveLoginStatusData,
    isLoginStatus,
  } = props;
  const { dispatch } = useContext(ModalContext);
  const { setError, clearErrors } = useFormContext();
  const [registerCustomerInfo, setRegisterCustomerInfo] = useState(
    orderRelatedInfo
  );
  const [saveCustomerInfo, setSaveCustomerInfo] = useState(false);
  const [isValidate, setIsValidate] = useState(true);
  const [isPrefectureGenerate, setIsPrefectureGenerate] = useState(true);
  const [isButtonNextClick, setIsButtonNextClick] = useState(false);
  const [isPostalErrorMsgAreaHidden, setIsPostalErrorMsgAreaHidden] = useState(
    true
  );
  const [addressAfterMunicipality, setAddressAfterMunicipality] = useState("");

  const retrieveAddressInfoParams = `addressCodes=${orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_InstallationLocationAddressCode11__c}`;
  const retrieveAddressInfoURI = `${apiBaseURL}order/RetrieveAddressInfo?${retrieveAddressInfoParams}`;

  const {
    data: retrieveAddressInfoData,
    isLoading: retrieveAddressInfoLoading,
    refetch: retrieveAddressInfoRefresh,
  } = useCustomQuery(retrieveAddressInfoURI, false, STRING_GET);

  useEffect(() => {
    if (
      isTypeCircuit &&
      orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
        ?.CMAP_ResidenceType__c === STRING_RESIDENCE_TYPE_APARTMENT
    ) {
      retrieveAddressInfoRefresh();
      if (retrieveAddressInfoData) {
        let street = "";
        const afterMunicipality = retrieveAddressInfoData?.streets?.map(
          (address) => {
            return `${address?.CMAP_StreetOoazaName__c} ${address?.CMAP_StreetAzaName__c} ${inputValues.registerInstallationAddressStreetNumber}`;
          }
        );
        street = afterMunicipality[0];
        setAddressAfterMunicipality(street);
      }
    }
  }, [retrieveAddressInfoData]);

  const registerCustomerInfoURI = `${apiBaseURL}order/RegisterCustomerInfo`;
  const {
    data: registerCustomerInfoData,
    isLoading: registerCustomerInfoIsLoading,
    error: registerCustomerInfoError,
    refetch: registerCustomerInfoRefetch,
    remove: registerCustomerInfoRemove,
  } = useCustomQuery(
    registerCustomerInfoURI,
    false,
    STRING_POST,
    registerCustomerInfo
  );
  const redirectToPreviousPage = useRedirectToPreviousPage();

  const registerContactAddress = getScDataFromPlaceholder(
    "contact-info-section",
    props
  );
  const [A02Data] = getScDataFromComponent("A-02", registerContactAddress);
  A02Data.fields.isShow = !isPostalErrorMsgAreaHidden;
  const [B01Data] = getScDataFromComponent("B-01", registerContactAddress);
  const [S02Data] = getScDataFromComponent("S-02", registerContactAddress);
  const [postalCodeField] = getScDataFromComponent(
    "I-02",
    registerContactAddress
  );
  const [D01Note] = getScDataFromComponent("D-01", registerContactAddress);
  const [
    prefectureNameField,
    streetNameField,
    contactAddressBuildingNameField,
    contactAddressRoomNumberField,
  ] = getScDataFromComponent("I-01", registerContactAddress);
  const [phoneNumberField, mobileNumberField] = getScDataFromComponent(
    "I-03",
    registerContactAddress
  );
  const [emailAddressField] = getScDataFromComponent(
    "I-05",
    registerContactAddress
  );

  const ssnPostalCode = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSPOSTALCODE,
    isNotEncrypt
  );
  const ssnPrefecture = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSPREFECTURE,
    isNotEncrypt
  );
  const ssnCity = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSCITY,
    isNotEncrypt
  );
  const ssnDistrict = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSDISTRICT,
    isNotEncrypt
  );
  const ssnApartmentName = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSAPARTMENTNAME,
    isNotEncrypt
  );
  const ssnRoomNumber = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSAPARTMENTROOMNUMBER,
    isNotEncrypt
  );
  const ssnPhoneNumber1 = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSAREACODE,
    isNotEncrypt
  );
  const ssnPhoneNumber2 = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSLOCALTELEPHONENUMBER,
    isNotEncrypt
  );
  const ssnPhoneNumber3 = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSSUBSCRIBERNAME,
    isNotEncrypt
  );
  const ssnMobileNumber1 = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSMOBILENUMBER1,
    isNotEncrypt
  );
  const ssnMobileNumber2 = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSMOBILENUMBER2,
    isNotEncrypt
  );
  const ssnMobileNumber3 = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSMOBILENUMBER3,
    isNotEncrypt
  );
  const ssnEmailAddress = getSessionStorageItem(
    keys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESS,
    isNotEncrypt
  );
  const ssnEmailAddressDomain = getSessionStorageItem(
    keys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSDOMAIN,
    isNotEncrypt
  );
  const ssnAddressCode = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSCODE,
    isNotEncrypt
  );

  const contactAddressPlaceholder = (val, selected) => {
    B01Data.fields.isHidden = val;
    postalCodeField.fields.isHidden = {
      value: val,
    };
    D01Note.fields.isHidden = {
      value: val,
    };
    if (selected !== 1) {
      if (ssnPrefecture === "" || ssnCity === "") {
        searchResultPlaceholder(true);
      } else {
        searchResultPlaceholder(false);
        contactAddressPrefectureMunicipality = `${ssnPrefecture}${ssnCity}`;
        contactAddressStreetName = ssnDistrict;
        contactAddressBuildingName = ssnApartmentName;
        contactAddressRoomNumber = ssnRoomNumber;
      }
    } else {
      searchResultPlaceholder(true);
    }
  };
  const searchResultPlaceholder = (val) => {
    prefectureNameField.fields.isHidden = {
      value: val,
    };
    streetNameField.fields.isHidden = {
      value: val,
    };
    contactAddressBuildingNameField.fields.isHidden = {
      value: val,
    };
    contactAddressRoomNumberField.fields.isHidden = {
      value: val,
    };
  };

  const contactAddressCode = () => {
    if (
      (urlParentItem === STRING_APPLY || urlParentItem === STRING_SIMULATOR) &&
      (parseInt(inputValues.registerContactAddressCode) === 1 ||
        typeof inputValues.registerContactAddressCode === "undefined")
    ) {
      contactAddressPlaceholder(true, 1);
    } else {
      contactAddressPlaceholder(false);
    }
  };
  prefectureNameField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  streetNameField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  contactAddressBuildingNameField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  contactAddressRoomNumberField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  postalCodeField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  phoneNumberField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  mobileNumberField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  emailAddressField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };

  let contactAddressPrefectureMunicipality = "",
    contactAddressStreetName = "",
    contactAddressBuildingName = "",
    contactAddressRoomNumber = "",
    contactAddressPhoneNumber1 = "",
    contactAddressPhoneNumber2 = "",
    contactAddressPhoneNumber3 = "",
    contactAddressEmailAddress = "",
    contactAddressEmailAddressDomain = "",
    contactAddressPostalCode3Digit = "",
    contactAddressPostalCode4Digit = "",
    contactAddressSameAddressRadioButton = "",
    contactAddressMobileNumber1 = "",
    contactAddressMobileNumber2 = "",
    contactAddressMobileNumber3 = "";
  if (ssnPostalCode) {
    contactAddressPostalCode3Digit = String(ssnPostalCode).substring(0, 3);
    contactAddressPostalCode4Digit = String(ssnPostalCode).slice(-4);
  }

  useEffect(() => {
    contactAddressCode();
    if (
      urlParentItem === STRING_MOVING ||
      urlParentItem === STRING_REBUILDING
    ) {
      S02Data.fields.isHidden = {
        value: true,
      };
    }
    if (isTypeCircuit) {
      if (parseInt(ssnAddressCode) === 1) {
        contactAddressPlaceholder(true, 1);
      } else {
        contactAddressPlaceholder(false);
      }
    }
  }, []);

  const orderRelatedInfoAccount =
    orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account;

  useEffect(() => {
    if (isFirstTimeDisplay) {
      if (
        (urlParentItem === STRING_APPLY ||
          urlParentItem === STRING_SIMULATOR) &&
        isLoginStatus
      ) {
        const account = retrieveCustomerInfo?.accountInfo?.account;
        if (account) {
          contactAddressPostalCode3Digit = account.CMAP_ContactPostalCode__c;
          contactAddressPostalCode4Digit =
            account.CMAP_ContactPostalCodeLowerFour__c;
          contactAddressStreetName = account.CMAP_ContactHouseNumber__c;
          contactAddressBuildingName = account.CMAP_MansionName__c;
          contactAddressRoomNumber = account.CMAP_RoomNo__c;
          contactAddressPrefectureMunicipality = `${account.CMAP_ContactState__c}${account?.CMAP_ContactCity__c}`;
          contactAddressPhoneNumber1 = ssnPhoneNumber1;
          contactAddressPhoneNumber2 = ssnPhoneNumber2;
          contactAddressPhoneNumber3 = ssnPhoneNumber3;
          contactAddressMobileNumber1 = account.CMAP_MobileNumber1__c;
          contactAddressMobileNumber2 = account.CMAP_MobileNumber2__c;
          contactAddressMobileNumber3 = account.CMAP_MobileNumber3__c;
          const email = account.CMAP_MyCommufaID__c?.split("@");
          if (email?.length > 0) {
            contactAddressEmailAddress = email[0];
            contactAddressEmailAddressDomain = email[1];
          }
        }
      } else if (
        urlParentItem !== STRING_APPLY &&
        urlParentItem !== STRING_SIMULATOR
      ) {
        contactAddressPhoneNumber1 = orderRelatedInfoAccount?.CMAP_AreaCode__c;
        contactAddressPhoneNumber2 =
          orderRelatedInfoAccount?.CMAP_LocalTelephoneNumber__c;
        contactAddressPhoneNumber3 =
          orderRelatedInfoAccount?.CMAP_SubscriberNumber__c;
        contactAddressMobileNumber1 =
          orderRelatedInfoAccount?.CMAP_MobileNumber1__c;
        contactAddressMobileNumber2 =
          orderRelatedInfoAccount?.CMAP_MobileNumber2__c;
        contactAddressMobileNumber3 =
          orderRelatedInfoAccount?.CMAP_MobileNumber3__c;
        const email = orderRelatedInfoAccount?.CMAP_MyCommufaID__c?.split("@");
        if (email?.length > 0) {
          contactAddressEmailAddress = email[0];
          contactAddressEmailAddressDomain = email[1];
        }
      }
    } else {
      contactAddressSameAddressRadioButton = ssnAddressCode;
      contactAddressPhoneNumber1 = ssnPhoneNumber1;
      contactAddressPhoneNumber2 = ssnPhoneNumber2;
      contactAddressPhoneNumber3 = ssnPhoneNumber3;
      contactAddressMobileNumber1 = ssnMobileNumber1;
      contactAddressMobileNumber2 = ssnMobileNumber2;
      contactAddressMobileNumber3 = ssnMobileNumber3;
      contactAddressEmailAddress = ssnEmailAddress;
      contactAddressEmailAddressDomain = ssnEmailAddressDomain;
    }
  }, [retrieveCustomerInfo, isLoginStatus]);

  useEffect(() => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        registerContactAddressCode: contactAddressSameAddressRadioButton,
        registerContactAddressPrefectureMunicipality: contactAddressPrefectureMunicipality,
        registerContactAddressStreetName: contactAddressStreetName,
        registerContactAddressBuildingName: contactAddressBuildingName,
        registerContactAddressRoomNumber: contactAddressRoomNumber,
        registerContactAddressPostalCode3digit: contactAddressPostalCode3Digit,
        registerContactAddressPostalCode4digit: contactAddressPostalCode4Digit,
        registerContactAddressPhoneNumber1: contactAddressPhoneNumber1,
        registerContactAddressPhoneNumber2: contactAddressPhoneNumber2,
        registerContactAddressPhoneNumber3: contactAddressPhoneNumber3,
        registerContactAddressMobileNumber1: contactAddressMobileNumber1,
        registerContactAddressMobileNumber2: contactAddressMobileNumber2,
        registerContactAddressMobileNumber3: contactAddressMobileNumber3,
        registerContactAddressEmailAddress: contactAddressEmailAddress,
        registerContactAddressEmailDomain: contactAddressEmailAddressDomain,
        registerContactAddressPrefecture: ssnPrefecture,
        registerContactAddressCity: ssnCity,
      };
    });
  }, [retrieveCustomerInfo]);

  const selectContactAddressCategory = "select-contact-address";
  const [contactAddressOptions, setContactAddressOptions] = useState([]);
  S02Data.fields["category"] = { value: selectContactAddressCategory };
  S02Data.fields["selections"] = contactAddressOptions;

  const [
    selectedContactAddress,
    setSelectedContactAddress,
  ] = useSetSelectedItem(selectContactAddressCategory);
  useEffect(() => {
    if (graphQlData) {
      const data = graphQlData?.SelectContactAddressMaster?.children
        .sort((a, b) => (a.Code > b.Code ? 1 : -1))
        .map((item) => {
          if (item.fields) {
            let select = item.fields.reduce((acc, field) => {
              acc[field.name] = field.value;
              return acc;
            }, {});
            return select;
          }
          return item;
        });

      setContactAddressOptions(data);

      if (inputValues.registerContactAddressCode && data) {
        data.forEach((item) => {
          if (parseInt(item.Code) === inputValues.registerContactAddressCode) {
            setSelectedContactAddress({
              [selectContactAddressCategory]: item.Code,
            });
          }
        });
      }
    }
  }, [graphQlData]);

  useEffect(() => {
    if (selectedContactAddress) {
      const value = selectedContactAddress?.selected;

      if (parseInt(value) === 1) {
        contactAddressPlaceholder(true, 1);
      } else {
        contactAddressPlaceholder(false);
      }
      setInputValues((prevState) => {
        return {
          ...prevState,
          registerContactAddressCode: value,
        };
      });
    }
  }, [selectedContactAddress]);

  postalCodeField.fields.inputValue = [
    {
      value: inputValues.registerContactAddressPostalCode3digit,
      name: "registerContactAddressPostalCode3digit",
    },
    {
      value: inputValues.registerContactAddressPostalCode4digit,
      name: "registerContactAddressPostalCode4digit",
    },
  ];

  prefectureNameField.fields.inputValue = {
    value: inputValues.registerContactAddressPrefectureMunicipality,
    name: "registerContactAddressPrefectureMunicipality",
  };

  streetNameField.fields.inputValue = {
    value: inputValues.registerContactAddressStreetName,
    name: "registerContactAddressStreetName",
  };

  contactAddressBuildingNameField.fields.inputValue = {
    value: inputValues.registerContactAddressBuildingName,
    name: "registerContactAddressBuildingName",
  };

  contactAddressRoomNumberField.fields.inputValue = {
    value: inputValues.registerContactAddressRoomNumber,
    name: "registerContactAddressRoomNumber",
  };

  phoneNumberField.fields.inputValue = [
    {
      value: inputValues.registerContactAddressPhoneNumber1,
      name: "registerContactAddressPhoneNumber1",
    },
    {
      value: inputValues.registerContactAddressPhoneNumber2,
      name: "registerContactAddressPhoneNumber2",
    },
    {
      value: inputValues.registerContactAddressPhoneNumber3,
      name: "registerContactAddressPhoneNumber3",
    },
  ];

  mobileNumberField.fields.inputValue = [
    {
      value: inputValues.registerContactAddressMobileNumber1,
      name: "registerContactAddressMobileNumber1",
    },
    {
      value: inputValues.registerContactAddressMobileNumber2,
      name: "registerContactAddressMobileNumber2",
    },
    {
      value: inputValues.registerContactAddressMobileNumber3,
      name: "registerContactAddressMobileNumber3",
    },
  ];

  emailAddressField.fields.inputValue = [
    {
      value: inputValues.registerContactAddressEmailAddress,
      name: "registerContactAddressEmailAddress",
      isDisabled: true,
    },
    {
      value: inputValues.registerContactAddressEmailDomain,
      name: "registerContactAddressEmailDomain",
      isDisabled: true,
    },
  ];

  const retrievePrefectureMunicipalityListParams = `postalCode=${inputValues.registerContactAddressPostalCode3digit}${inputValues.registerContactAddressPostalCode4digit}`;
  const retrievePrefectureMunicipalityListURI = `${apiBaseURL}order/RetrievePrefectureMunicipalityList?${retrievePrefectureMunicipalityListParams}`;
  const {
    data: retrievePrefectureMunicipalityListData,
    isLoading: retrievePrefectureMunicipalityListLoading,
    error: retrievePrefectureMunicipalityListError,
    refetch: retrievePrefectureMunicipalityListRefresh,
    remove: retrievePrefectureMunicipalityListRemove,
  } = useCustomQuery(retrievePrefectureMunicipalityListURI, false, STRING_GET);

  const isApiLoading =
    registerCustomerInfoIsLoading ||
    retrieveAddressInfoLoading ||
    retrievePrefectureMunicipalityListLoading;
  props.setIsLoading(isApiLoading);

  B01Data.fields.onClickEvent = () => {
    clearErrors();
    let isValid = true;
    const fieldMetadataDefinitions =
      metadataDefinitions?.Account?.fieldMetadataDefinitions;
    isValid = validatePostalCode(
      setIsValidate,
      setError,
      inputValues,
      fieldMetadataDefinitions,
      cMAPContractName,
      accountId,
      retrieveLoginStatusData,
      isLoginStatus
    );
    if (isValid) {
      setIsPrefectureGenerate(false);
      retrievePrefectureMunicipalityListRefresh();
    }
  };

  useEffect(() => {
    if (
      retrievePrefectureMunicipalityListData &&
      retrievePrefectureMunicipalityListData["streets"]
    ) {
      if (retrievePrefectureMunicipalityListData["streets"].length !== 0) {
        const stateName =
          retrievePrefectureMunicipalityListData["streets"][0]
            .CMAP_StateName__c;
        const municipalityName =
          retrievePrefectureMunicipalityListData["streets"][0]
            .CMAP_MunicipalityName__c;

        searchResultPlaceholder(false);

        setIsPostalErrorMsgAreaHidden(true);
        setIsPrefectureGenerate(true);
        setInputValues((prevState) => {
          return {
            ...prevState,
            registerContactAddressPrefectureMunicipality: `${stateName}${municipalityName}`,
            registerContactAddressPrefecture: stateName,
            registerContactAddressCity: municipalityName,
          };
        });
      } else {
        searchResultPlaceholder(true);

        setIsPostalErrorMsgAreaHidden(false);
        A02Data.fields.alertText =
          pageContext[
            "RegisterContactAddress-PostalCodeNotApplicableMessage"
          ]?.value;
      }
    }
  }, [retrievePrefectureMunicipalityListData]);

  const buttonSection = getScDataFromPlaceholder("button-section", props);
  const [backButton] = getScDataFromComponent("B-02", buttonSection);
  const [nextButton] = getScDataFromComponent("B-01", buttonSection);
  backButton.fields.onClickEvent = () => {
    const { linkURL } = getProperData(sitecoreContext, backButton.fields);
    clearErrors();
    setSessionStorageData(
      inputValues,
      backButton,
      linkURL,
      isNotEncrypt,
      orderRelatedInfo,
      urlParentItem,
      isTypeCircuit,
      setSessionStorageItem,
      redirectToPreviousPage
    );
  };

  nextButton.fields.onClickEvent = async () => {
    saveCustomerInfoData(
      inputValues,
      metadataDefinitions,
      urlParentItem,
      planType,
      cMAPContractName,
      accountId,
      orderRelatedInfo,
      setError,
      clearErrors,
      setIsValidate,
      setIsButtonNextClick
    );
  };

  useEffect(() => {
    const processResult = registerCustomerInfoData?.processResult;
    if (processResult?.returnCode === apiResultCode.success) {
      const accountResponse =
        registerCustomerInfoData?.accountRegistrationUpdateResultInfo
          ?.accountInfo?.account;
      const accountAdditionResponse =
        registerCustomerInfoData?.accountRegistrationUpdateResultInfo
          ?.accountInfo?.accountAddition;
      let orderRelatedInfoItem = orderRelatedInfo;
      orderRelatedInfoItem = {
        ...orderRelatedInfo,
        orderRelatedInfo: {
          ...orderRelatedInfo?.orderRelatedInfo,
          accountInfo: {
            ...orderRelatedInfo?.orderRelatedInfo?.accountInfo,
            account: {
              ...orderRelatedInfo?.orderRelatedInfo?.accountInfo.account,
              ...accountResponse,
            },
            accountAddition: {
              ...orderRelatedInfo?.orderRelatedInfo?.accountInfo
                .accountAddition,
              ...accountAdditionResponse,
            },
          },
        },
      };
      setSessionStorageItem(
        keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
        orderRelatedInfoItem,
        isNotEncrypt
      );
      const { linkURL } = getProperData(sitecoreContext, nextButton.fields);
      setSessionStorageData(
        inputValues,
        nextButton,
        linkURL,
        isNotEncrypt,
        orderRelatedInfoItem,
        urlParentItem,
        isTypeCircuit,
        addressAfterMunicipality,
        setSessionStorageItem,
        redirectToPreviousPage
      );
    }

    if (processResult?.returnCode === apiResultCode.error) {
      processResult.resultInfos.forEach((err, index) => {
        if (err.resultCategory === "Error") {
          setError(err.resultCode + index, {
            type: "manual",
            message: err.resultMessage,
          });
        }
      });
    }

    if (processResult?.returnCode === apiResultCode.alert) {
      const warningModalSection = getScDataFromPlaceholder(
        "warning-modal-section",
        properties
      );
      const [popup] = getScDataFromComponent("P0-01", warningModalSection);
      popup.fields.isCustom = true;

      const modalSection = getScDataFromPlaceholder("modal-section", popup);
      const [warningModal] = getScDataFromComponent(
        "Warning-Message-Modal-Layout",
        modalSection
      );
      warningModal.fields = {
        descriptionText: pageContext["WarningMessageModal-Description"],
        closeText: pageContext["WarningMessageModal-CloseButtonText"],
        confirmText: pageContext["WarningMessageModal-ApplyButtonText"],
        warningMessageList: processResult.resultInfos.reduce((acc, err) => {
          if (err.resultCategory === apiResultCode.alert) {
            acc.push({
              code: err.resultCode,
              message: err.resultMessage,
            });
          }
          return acc;
        }, []),
        onConfirmClick: () => {
          const { linkURL } = getProperData(sitecoreContext, nextButton.fields);
          setSessionStorageData(
            inputValues,
            nextButton,
            linkURL,
            isNotEncrypt,
            orderRelatedInfo,
            urlParentItem,
            isTypeCircuit,
            addressAfterMunicipality,
            setSessionStorageItem,
            redirectToPreviousPage
          );
        },
      };

      dispatch({
        type: "SHOW_MODAL",
        payload: { id: popup?.fields?.modalTarget?.value },
      });
    }
  }, [registerCustomerInfoData]);

  useEffect(() => {
    if (saveCustomerInfo) {
      if (retrieveAddressInfoData) {
        registerCustomerInfoRemove();
        registerCustomerInfoRefetch();
      }
      setIsButtonNextClick(false);
    }
  }, [registerCustomerInfo, saveCustomerInfo, retrieveAddressInfoData]);

  useEffect(() => {
    let customerInfoRegistered = "";

    const appChannelDivision = getSessionStorageItem(
      keys.SSN_COMMON_COMMON_PERSONALIZEINFO,
      isNotEncrypt
    )?.AppChannelDivision;

    const customerInfoOrderChannelCategory = appChannelDivision
      ? appChannelDivision
      : STRING_REQUEST_WEB;

    let customerInfoId = "",
      customerInfoCMAP_ContractName__c = "",
      customerInfoLastName = "",
      customerInfoFirstName = "",
      customerInfoLastNameKana__pc = "",
      customerInfoFirstNameKana__pc = "",
      customerInfoCMAP_Gender__c = "",
      customerInfoPersonBirthdate = "",
      customerInfoCMAP_CorporateGroupName__c = "",
      customerInfoCMAP_DepartmentName__c = "",
      customerInfoCMAP_ContactPersonFamilyName__c = "",
      customerInfoCMAP_ContactPersonName__c = "",
      customerInfoCMAP_ContactPersonLastNameKana__pc = "",
      customerInfoCMAP_ContactPersonFirstNameKana__pc = "",
      customerInfoCMAP_MansionName__c = "",
      customerInfoCMAP_RoomNo__c = "",
      customerInfoCMAP_AreaCode__c = "",
      customerInfoCMAP_LocalTelephoneNumber__c = "",
      customerInfoCMAP_SubscriberNumber__c = "",
      customerInfoCMAP_MobileNumber1__c = "",
      customerInfoCMAP_MobileNumber2__c = "",
      customerInfoCMAP_MobileNumber3__c = "",
      customerInfoCMAP_ContactPostalCode__c = "",
      customerInfoCMAP_ContactPostalCodeLowerFour__c = "",
      customerInfoCMAP_ContactState__c = "",
      customerInfoCMAP_ContactCity__c = "",
      customerInfoCMAP_ContactHouseNumber__c = "",
      customerInfoCMAP_MyCommufaID__c = "";

    if (
      urlParentItem === STRING_APPLY ||
      urlParentItem === STRING_SIMULATOR ||
      urlParentItem === STRING_MOVING
    ) {
      customerInfoId = accountId;
      customerInfoCMAP_ContractName__c =
        orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account
          ?.CMAP_ContractName__c;
      if (cMAPContractName === STRING_INDIVIDUAL) {
        customerInfoLastName =
          inputValues.registerIndividualCustomerInfoLastName;
        customerInfoFirstName =
          inputValues.registerIndividualCustomerInfoFirstName;
        customerInfoLastNameKana__pc =
          inputValues.registerIndividualCustomerInfoLastNameFurigana;
        customerInfoFirstNameKana__pc =
          inputValues.registerIndividualCustomerInfoFirstNameFurigana;
        customerInfoCMAP_Gender__c =
          inputValues.registerIndividualCustomerInfoGender;
        customerInfoPersonBirthdate =
          inputValues.registerIndividualCustomerInfoBirthDate;
      }
      if (cMAPContractName === STRING_CORPORATION) {
        customerInfoLastName =
          inputValues.registerCorporateCustomerInfoCorporateName;
        customerInfoCMAP_CorporateGroupName__c =
          inputValues.registerCorporateCustomerInfoCorporateNameFurigana;
        customerInfoCMAP_DepartmentName__c =
          inputValues.registerCorporateCustomerInfoDepartmentName;
        customerInfoCMAP_ContactPersonFamilyName__c =
          inputValues.registerCorporateCustomerInfoPersonInChargeLastName;
        customerInfoCMAP_ContactPersonName__c =
          inputValues.registerCorporateCustomerInfoPersonInChargeFirstName;
        customerInfoCMAP_ContactPersonLastNameKana__pc =
          inputValues.registerCorporateCustomerInfoPersonInChargeLastNameFurigana;
        customerInfoCMAP_ContactPersonFirstNameKana__pc =
          inputValues.registerCorporateCustomerInfoPersonInChargeFirstNameFurigana;
      }
      customerInfoCMAP_AreaCode__c =
        inputValues.registerContactAddressPhoneNumber1;
      customerInfoCMAP_LocalTelephoneNumber__c =
        inputValues.registerContactAddressPhoneNumber2;
      customerInfoCMAP_SubscriberNumber__c =
        inputValues.registerContactAddressPhoneNumber3;
      customerInfoCMAP_MobileNumber1__c =
        inputValues.registerContactAddressMobileNumber1;
      customerInfoCMAP_MobileNumber2__c =
        inputValues.registerContactAddressMobileNumber2;
      customerInfoCMAP_MobileNumber3__c =
        inputValues.registerContactAddressMobileNumber3;
    }

    if (retrieveAddressInfoData) {
      if (
        urlParentItem === STRING_APPLY ||
        urlParentItem === STRING_SIMULATOR
      ) {
        customerInfoRegistered = accountId === "" ? true : false;
        customerInfoCMAP_MyCommufaID__c = `${inputValues.registerContactAddressEmailAddress}@${inputValues.registerContactAddressEmailDomain}`;

        if (parseInt(inputValues.registerContactAddressCode) === 1) {
          customerInfoCMAP_ContactHouseNumber__c = addressAfterMunicipality;
          customerInfoCMAP_MansionName__c =
            inputValues.registerInstallationAddressBuildingName;
          customerInfoCMAP_RoomNo__c =
            inputValues.registerInstallationAddressRoomNumber;

          if (isTypeCircuit) {
            customerInfoCMAP_ContactPostalCode__c = String(
              orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
                ?.ShippingPostalCode
            ).substring(0, 3);
            customerInfoCMAP_ContactPostalCodeLowerFour__c = String(
              orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
                ?.ShippingPostalCode
            ).slice(-4);
            customerInfoCMAP_ContactState__c =
              orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
                ?.ShippingState;
            customerInfoCMAP_ContactCity__c =
              orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
                ?.ShippingCity;
          } else {
            customerInfoCMAP_ContactPostalCode__c = String(
              orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
                ?.CMAP_DeliveryServiceAddress__PostalCode__s
            ).substring(0, 3);
            customerInfoCMAP_ContactPostalCodeLowerFour__c = String(
              orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
                ?.CMAP_DeliveryServiceAddress__PostalCode__s
            ).slice(-4);
            customerInfoCMAP_ContactState__c =
              orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
                ?.CMAP_DeliveryServiceAddress__StateCode__s;
            customerInfoCMAP_ContactCity__c =
              orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
                ?.CMAP_DeliveryServiceAddress__City__s;
          }
        } else {
          customerInfoCMAP_ContactPostalCode__c =
            inputValues.registerContactAddressPostalCode3digit;
          customerInfoCMAP_ContactPostalCodeLowerFour__c =
            inputValues.registerContactAddressPostalCode4digit;
          customerInfoCMAP_ContactState__c =
            inputValues.registerContactAddressPrefecture;
          customerInfoCMAP_ContactCity__c =
            inputValues.registerContactAddressCity;
          customerInfoCMAP_ContactHouseNumber__c =
            inputValues.registerContactAddressStreetName;
          customerInfoCMAP_MansionName__c =
            inputValues.registerContactAddressBuildingName;
          customerInfoCMAP_RoomNo__c =
            inputValues.registerContactAddressRoomNumber;
        }
      }
    }

    if (urlParentItem === STRING_MOVING) {
      customerInfoRegistered = false;
      if (parseInt(inputValues.registerContactAddressCode) === 2) {
        customerInfoCMAP_ContactPostalCode__c =
          inputValues.registerContactAddressPostalCode3digit;
        customerInfoCMAP_ContactPostalCodeLowerFour__c =
          inputValues.registerContactAddressPostalCode4digit;
        customerInfoCMAP_ContactState__c =
          inputValues.registerContactAddressPrefecture;
        customerInfoCMAP_ContactCity__c =
          inputValues.registerContactAddressCity;
        customerInfoCMAP_ContactHouseNumber__c =
          inputValues.registerInstallationAddressStreetNumber;
        customerInfoCMAP_MansionName__c =
          inputValues.registerInstallationAddressBuildingName;
        customerInfoCMAP_RoomNo__c =
          inputValues.registerInstallationAddressRoomNumber;
      }
    }

    const isMyCommufaIdValid =
      !!customerInfoCMAP_MyCommufaID__c &&
      customerInfoCMAP_MyCommufaID__c !== "@";

    if (isValidate && isPrefectureGenerate && isButtonNextClick) {
      const registerCustomerInfo = {
        duplicateAlertSkipped: true,
        orderChannelCategory: customerInfoOrderChannelCategory,
        registered: customerInfoRegistered,
        accountInfo: {
          account: {
            Id: customerInfoId,
            CMAP_ContractName__c: customerInfoCMAP_ContractName__c,
            LastName: customerInfoLastName,
            FirstName: customerInfoFirstName,
            LastNameKana__pc: customerInfoLastNameKana__pc,
            FirstNameKana__pc: customerInfoFirstNameKana__pc,
            CMAP_Gender__c: customerInfoCMAP_Gender__c,
            PersonBirthdate: customerInfoPersonBirthdate,
            CMAP_CorporateGroupName__c: customerInfoCMAP_CorporateGroupName__c,
            CMAP_DepartmentName__c: customerInfoCMAP_DepartmentName__c,
            CMAP_ContactPersonFamilyName__c: customerInfoCMAP_ContactPersonFamilyName__c,
            CMAP_ContactPersonName__c: customerInfoCMAP_ContactPersonName__c,
            CMAP_ContactPersonLastNameKana__pc: customerInfoCMAP_ContactPersonLastNameKana__pc,
            CMAP_ContactPersonFirstNameKana__pc: customerInfoCMAP_ContactPersonFirstNameKana__pc,
            CMAP_ContactPostalCode__c: customerInfoCMAP_ContactPostalCode__c,
            CMAP_ContactPostalCodeLowerFour__c: customerInfoCMAP_ContactPostalCodeLowerFour__c,
            CMAP_ContactState__c: customerInfoCMAP_ContactState__c,
            CMAP_ContactCity__c: customerInfoCMAP_ContactCity__c,
            CMAP_ContactHouseNumber__c: customerInfoCMAP_ContactHouseNumber__c,
            CMAP_MansionName__c: customerInfoCMAP_MansionName__c,
            CMAP_RoomNo__c: customerInfoCMAP_RoomNo__c,
            CMAP_AreaCode__c: customerInfoCMAP_AreaCode__c,
            CMAP_LocalTelephoneNumber__c: customerInfoCMAP_LocalTelephoneNumber__c,
            CMAP_SubscriberNumber__c: customerInfoCMAP_SubscriberNumber__c,
            CMAP_MobileNumber1__c: customerInfoCMAP_MobileNumber1__c,
            CMAP_MobileNumber2__c: customerInfoCMAP_MobileNumber2__c,
            CMAP_MobileNumber3__c: customerInfoCMAP_MobileNumber3__c,
            ...(isMyCommufaIdValid && {
              CMAP_MyCommufaID__c: customerInfoCMAP_MyCommufaID__c,
            }),
          },
          accountAddition: {
            isNewAccount:
              orderRelatedInfo?.orderRelatedInfo?.accountInfo?.accountAddition
                ?.isNewAccount,
            leadId:
              orderRelatedInfo?.orderRelatedInfo?.accountInfo?.accountAddition
                ?.leadId,
          },
        },
      };
      setSaveCustomerInfo(true);
      setRegisterCustomerInfo(registerCustomerInfo);
    }
  }, [
    isValidate,
    isButtonNextClick,
    isPrefectureGenerate,
    retrieveAddressInfoData,
  ]);

  return (
    <>
      <div className="form_wrapper-input" name="contact-info-section">
        <Placeholder name="contact-info-section" rendering={props.rendering} />
      </div>

      <div className="btn_container" name="button-section">
        <Placeholder name="button-section" rendering={props.rendering} />
      </div>
      <Placeholder name="warning-modal-section" rendering={props.rendering} />
    </>
  );
}
