import { di_fetch } from "./di";

export const errorMessageFormatter = (errorMessages) => {
  let result = null;
  const messages = errorMessages?.item?.children;
  if (messages) {
    result = {};
    messages.forEach(item => {
      const errorCode = item?.errorCode?.value;
      const errorMessage = item?.errorMessage?.value;
  
      result[errorCode] = errorMessage;
    });
  }
  return result;
};

export const getErrorMessage = (errorCode, messageParam) => {
  const errorMessages = di_fetch("errorMessages");
  if (errorMessages) {
    let message = errorMessages[errorCode];
  
    messageParam.forEach((param, index) => {
      message = message.replace(`[${index}]`, param);
    });
    return message;
  }
  return null;
};
