import React, { useEffect, useState } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

// @Model

// get the data

// update the context

// @Controller

// @View

const C07 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    titleText,
    subtitleText,
    priceText,
    currencyText,
    subscriptionText,
    textAreaLabel,
    contentLabel,
    contentText,
    MultilistItems,
  } = properData;
  const properMultilistItems = [];
  for (let i = 0; i < MultilistItems?.length; i++) {
    properMultilistItems[i] = getProperData(
      sitecoreContext,
      MultilistItems[i].fields
    );
  }

  const [isHideMultiList, setIsHideMultiList] = useState(false);

  useEffect(() => {
    properMultilistItems.length > 0
      ? setIsHideMultiList(false)
      : setIsHideMultiList(true);
  }, [MultilistItems]);

  return (
    <React.Fragment>
      <div className="decoration-content amount display2 C-07">
        <div className="information">
          <ul>
            <li>
              <div
                className={`detail ${
                  isHideMultiList ? "no-border-bottom" : ""
                } `}
              >
                <div className="content-title">
                  <h5>
                    <Text field={fillBlank(titleText)} />
                  </h5>
                  {subtitleText?.value && (
                    <p>
                      <Text field={fillBlank(subtitleText)} />
                    </p>
                  )}
                </div>
                <div className="text-right">
                  <p>
                    <span className="bold text-big">
                      <Text field={fillBlank(priceText)} />
                    </span>
                    <Text field={fillBlank(subscriptionText)} />
                  </p>
                </div>
              </div>
              <ul className={`sub1 ${isHideMultiList ? "hide" : ""}`}>
                {properMultilistItems?.map((item, i) => (
                  <li key={i}>
                    <div className="content-title">
                      <h6>
                        <Text field={fillBlank(item.textAreaLabel)} />
                      </h6>
                    </div>
                    <ul className="sub2">
                      {item.MultilistItems.map((subitem, i) => (
                        <li key={i}>
                          <div className="detail">
                            <div className="content-title">
                              <span>
                                <Text
                                  field={fillBlank(
                                    subitem.fields?.contentLabel
                                  )}
                                />
                              </span>
                            </div>
                            <div className="text-right">
                              <p>
                                <Text
                                  field={fillBlank(subitem.fields?.contentText)}
                                />
                                <Text field={fillBlank(subscriptionText)} />
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default C07;
