import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import {
  preventDoubleClick,
  getProperData,
  fillBlank,
} from "../../utils/commonUtils";

const B002i = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    buttonLabel,
    btnWrapperAdditionalClass,
    additionalClass,
    buttonIcon,
    isHidden,
  } = properData;

  // @Controller
  const handleButtonClick = preventDoubleClick(
    async (evt, myClickEvent = undefined) => {
      if (myClickEvent) {
        myClickEvent();
        return false;
      }

      if (props?.onClick) props.onClick();
    }
  );

  // @View
  return (
    <React.Fragment>
      {!isHidden && (
        <div
          className={`btn_wrapper secondary B-02i ${btnWrapperAdditionalClass?.value}`}
        >
          {/* className="btn_wrapper secondary disabled B-02i" */}
          <button
            className={`btn btn_icon ${additionalClass?.value}`}
            type="button"
            onClick={(e) => handleButtonClick(e, properData?.onClickEvent)}
          >
            {buttonIcon?.value && (
              <img src={fillBlank(buttonIcon)?.value?.src} />
            )}
            <Text field={fillBlank(buttonLabel)} />
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default B002i;
