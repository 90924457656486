
import React, { useEffect } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import {
  deepcopy,
  getScDataFromPlaceholder,
  getScDataFromComponent,
} from "../../utils/commonUtils";
import { getSessionStorageItem } from "../../utils/useSessionStorage";

export function CautionConfirmation(props) {
  const {sitecoreContext} = props;
  const pageContext = sitecoreContext.route.fields;
  
  const benefitCampaignInfos= getSessionStorageItem(
    keys.SSN_APPLICATION_SELECTPRODUCT_BENEFITCAMPAIGNINFOS,
    true
  )?.BenefitCampaignInfos;

  const cautionConfirmationSection = getScDataFromPlaceholder(
    "caution-confirmation-section",
    props
  );

  let componentList = [];
  let [d01Data] = getScDataFromComponent("D-01", cautionConfirmationSection);
  let [d02Data] = getScDataFromComponent("D-02", cautionConfirmationSection);
  const uid = "ccs1";
  useEffect(() => {
    if (benefitCampaignInfos && benefitCampaignInfos.length > 0) {
      for (let i = 0; i < benefitCampaignInfos.length; i++) {
        let cautionConfirmationHeader = deepcopy(d02Data);
        let cautionConfirmationText = deepcopy(d01Data);
      cautionConfirmationHeader.uid = cautionConfirmationHeader?.uid
          .toString()
          .concat(uid)
          .concat(i.toString());
        cautionConfirmationText.uid =
          cautionConfirmationText?.uid.toString().concat(uid).concat(i.toString()) +
          1;
        if (benefitCampaignInfos[i].CMAP_BenefitCampaignAdditionalRule__c && benefitCampaignInfos[i].CMAP_BenefitCampaignAdditionalRule__c !== "") {
          cautionConfirmationHeader.fields.descriptionText.value = benefitCampaignInfos[i].CMAP_BenefitCampaignName__c;
          cautionConfirmationText.fields.descriptionText.value = benefitCampaignInfos[i].CMAP_BenefitCampaignAdditionalRule__c;
          componentList[componentList.length] = cautionConfirmationHeader;
          componentList[componentList.length] = cautionConfirmationText;
        }
      }
    }
    else {
      d01Data.fields.isHidden = true;
      d02Data.fields.isHidden = true;
    }

    cautionConfirmationSection.splice(0);
    let d01InfoSection = deepcopy(d01Data);
    d01InfoSection.fields.descriptionText.value = pageContext["CautionConfirmation-BillingNotes"]?.value;
    componentList.push(d01InfoSection);

    for (let i = 0; i < componentList.length; i++) {
      cautionConfirmationSection[cautionConfirmationSection.length] =
        componentList[i];
    }
  }, [])

  return (
    <>
      <div className="form_wrapper-input" name="caution-confirmation-section">
        <Placeholder
          name="caution-confirmation-section"
          rendering={props.rendering}
        />
      </div>
    </>
  )
}