import React, { useState, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import { useFormContext } from "react-hook-form";

// get the data

// update the context

// @Controller

const I04 = (props) => {
  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext();
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    textInputLabel,
    requiredText,
    placeholderText,
    isHideField,
    initialValue,
    maxLength,
    disableField = false,
  } = properData;
  const inputId = `I04-1-${textInputLabel?.value?.replace(" ", "-")}`;
  let formName = props?.fields?.formName ? props.fields.inputName : inputId;
  let isRequired = props?.fields?.isRequired ? props.fields.isRequired : false;

  const [inputValue, setInputValue] = useState(initialValue);

  const onInputChange = (e) => {
    setInputValue(e?.target?.value);
    props?.fields?.onChangeEvent && props.fields.onChangeEvent(e);
  };

  useEffect(() => {
    //If there is a maxLength for the textarea input remove the excess characters.
    if (maxLength) {
      setInputValue(initialValue?.substring(0, maxLength));
    } else {
      setInputValue(initialValue);
    }
  }, [initialValue, maxLength]);

  // @View
  return (
    <React.Fragment>
      {!isHideField && (
        <div className="input-box I-04">
          <div className="input-form">
            <div className="input-container">
              <label htmlFor={inputId}>
                <Text field={fillBlank(textInputLabel)} />
                {requiredText?.value && (
                  <span className="required">
                    <Text field={fillBlank(requiredText)} />
                  </span>
                )}
              </label>
              {/* TODO: This field is optional if description is needed */}
              {/* <p>
              <Text field={fillBlank(descriptionText)} />
            </p> */}
              <textarea
                id={inputId}
                placeholder={placeholderText?.value}
                {...register(formName, {
                  required: isRequired,
                })}
                onChange={onInputChange}
                onBlur={props?.fields?.onBlurEvent}
                value={inputValue}
                maxLength={maxLength ? maxLength : null}
                disabled={disableField}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default I04;
