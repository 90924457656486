export const query = `query {
    CPadToolButton: item(path: "/sitecore/content/jss/masterjss/Data/cPad Tool Buttons Folder/cPad Tool Buttons") 
    {
          fields(excludeStandardFields: true){
        name
        value
        editable
        ...on LookupField{
          targetItem{
            field(name: "Value"){
              value
            }
          }
        }
      }
      children{
        fields(excludeStandardFields: true){
          name
          value
          editable
          ...on LookupField{
            targetItem{
              Value : field(name: "Value"){
                value
              }
              Code : field(name: "Code"){
                value
              }
            }
          }
        }
      }
    }
  }
  `;
  