export const query = `query {
  cPadEstimationDueDateMaster: item(path: "/sitecore/content/jss/masterjss/Data/CTC Master/cPadEstimationDueDateMaster") {
    children {
      fields(excludeStandardFields: true) {
        name
        value
      }
    }
  }
}`;
