import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from "../../utils/commonUtils";
import {
  useCustomQuery,
} from "../../hooks/useGetData";
import { formatDate } from "../../utils/dateFormat";
import { apiBaseURL } from "../../envSettings";
import { STRING_POST } from "../../utils/constantVariables";
import { getSessionStorageItem } from "../../utils/useSessionStorage";
import { recoilKeys as keys } from "../../assets/recoilKeys";

export function CommonMaintenanceList(props) {
  const sitecoreContext = props?.sitecoreContext;
  const {
    "CommonConstructionList-TopIcon": CommonConstructionListTopIcon,
    "CommonConstructionList-TopLabel": CommonConstructionListTopLabel,
  } = sitecoreContext?.route?.fields;

  const retrieveConstructionListRequest = {
    ContractInfos: props.contractInfo?.map((contract) => ({
      PostalCode: contract.AddressPost.replace("-", ""),
      Services: contract.Product,
    })),
  };

  const retrieveConstructionListQuery = `${apiBaseURL}common/RetrieveConstructionList`;

  const {
    data: retrieveConstructionListData,
    isLoading: retrieveConstructionListIsLoading,
    error: retrieveConstructionListError,
    refetch: retrieveConstructionListRefresh,
    remove: retrieveConstructionListRemove,
  } = useCustomQuery(
    retrieveConstructionListQuery,
    true,
    STRING_POST,
    retrieveConstructionListRequest
  );

  props.setIsLoading(retrieveConstructionListIsLoading);

  const constructionList = retrieveConstructionListData?.RetrieveConstructions;

  return (
    <>
      {props?.contractInfo?.length > 0 && constructionList?.length > 0 && (
        <div className="alert-message with-icon">
          <div className="alert-icon">
            <span>
              <img src={fillBlank(CommonConstructionListTopIcon)?.value?.src} />
            </span>
          </div>
          <div className="alert-text">
            <h2>
              <Text field={fillBlank(CommonConstructionListTopLabel?.value)} />
            </h2>
            <ul>
              {constructionList?.map((constructionListItem, index) => {
                return (
                  <li key={index}>
                    <a href={constructionListItem?.ConstructionDetailURL}>
                      {`${
                        fillBlank(constructionListItem?.TargetAreaTitle).value
                      }(${formatDate(
                        constructionListItem?.MaintenanceStartDate
                      )}〜${formatDate(
                        constructionListItem?.MaintenanceEndDate
                      )} ${fillBlank(constructionListItem.ServiceTypeText)
                        .value.split(",")
                        .join(", ")}
                         ${
                           fillBlank(constructionListItem.MaintenanceContents)
                             .value
                         })`}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
