import React from "react";
import { fillBlank } from "../../utils/commonUtils";
import {
  useCustomQuery,
} from "../../hooks/useGetData";
import { formatDate } from "../../utils/dateFormat";
import { apiBaseURL } from "../../envSettings";
import { STRING_POST } from "../../utils/constantVariables";

export function CommonTroubleList(props) {
  // @Model

  const sitecoreContext = props?.sitecoreContext;
  const {
    "CommonTroubleInformationList-TopIcon": CommonTroubleInformationListTopIcon,
    "CommonTroubleInformationList-TopLabel": CommonTroubleInformationListTopLabel,
  } = sitecoreContext?.route?.fields;

  const troubleURI = `${apiBaseURL}common/RetrieveTroubleList`;
  const {
    data: troubleList,
    isLoading: troubleListIsLoading,
    error: troubleListError,
    refetch: troubleListRefresh,
    remove: troubleListRemove,
  } = useCustomQuery(troubleURI, true, STRING_POST, {
    contractInfos: props?.contractInfo?.map((contract) => ({
      PostalCode: contract.AddressPost.replace("-", ""),
      Services: contract.Product,
    })),
  });

  props.setIsLoading(troubleListIsLoading);

  return (
    <>
      {props?.contractInfo?.length > 0 &&
        troubleList?.RetrieveTroubles?.length > 0 && (
          <div className="alert-message with-icon">
            <div className="alert-icon">
              <span>
                <img
                  src={fillBlank(CommonTroubleInformationListTopIcon).value.src}
                  alt="warning"
                />
              </span>
            </div>
            <div className="alert-text">
              <h2>{fillBlank(CommonTroubleInformationListTopLabel).value}</h2>
              <ul>
                {troubleList?.RetrieveTroubles?.map((troubleItem, index) => {
                  const troubleStart = fillBlank(troubleItem.TroubleStartDate)
                    .value;
                  const troubleEnd = fillBlank(troubleItem.TroubleEndDate)
                    .value;
                  return (
                    <li key={index}>
                      <a href={troubleItem.TroubleDetailURL}>
                        {`${fillBlank(troubleItem.TargetAreaTitle).value}（${
                          troubleStart === "–" ? "" : formatDate(troubleStart)
                        }〜${
                          troubleEnd === "–" ? "" : formatDate(troubleEnd)
                        } ${fillBlank(troubleItem.ServiceTypeText)
                          .value.split(",")
                          .join(", ")} ${
                          fillBlank(troubleItem.TroubleCause).value
                        }）`}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
    </>
  );
}
