import React, { useContext, useEffect, useMemo, useState } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { useFormContext } from "react-hook-form";
import { ApplyButtonContext } from "../../assets/context/ApplyButtonContext";
import {
  preventDoubleClick,
  getProperData,
  fillBlank,
  nullChecker,
} from "../../utils/commonUtils";
import {
  visibleComponentNames,
  useComponentVisivleAction,
} from "../../hooks/useMetadataConditionVisible";
import { di_fetch } from "../../utils/di";

const B001 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { agreementContext } = useContext(ApplyButtonContext);
  // @Model
  const {
    dataTargetValue,
    buttonText,
    additionalId,
    additionalClass,
    btnWrapperAdditionalClass,
    linkURL,
    buttonTextAuthenticated,
    isAuthenticatedEmail,
    isHidden = false,
    isDisabled = { value: false },
  } = properData;

  const { trigger } = useFormContext();
  const [
    optionSectionVisible,
    setOptionSectionVisible,
  ] = useComponentVisivleAction();

  // @Controller
  const handleClick = preventDoubleClick(
    async (event, myClickEvent = undefined) => {

      if (myClickEvent) {
        myClickEvent(event);
        return false;
      }
      // if url is defined

      // 1) check data

      // validation
      //let checkTargetKeys = Object.keys(showHideCtx?.showHideItems);
      let checkTargesObj = visibleComponentNames();
      let checkTargetKeys = Object.keys(checkTargesObj);
      let checkTargets = checkTargetKeys
        .map((k) => {
          if (checkTargesObj[k]) return k; // k.replace(/Section/g, '');
        })
        .filter((v) => v !== undefined);

      const result = await trigger(checkTargets);
      if (!result) {
        return false;
      }

      // 2) jump to the next page
      if (props.fields.nextPage) {
        window.location.href = props.fields.nextPage.value;
      } else if (props.fields.nextSection) {
        setOptionSectionVisible(props.fields.nextSection?.value, true);
      }

      // 3) sitecore-default function jumpt to page
      let urlInfo = linkURL?.value;
      let linkType = urlInfo?.linktype;
      let url = urlInfo.url ? urlInfo.url : urlInfo?.href;

      if (linkType == "javascript") {
        eval(url);
        return true;
      }
      window.location.href = url;
    }
  );
  
  // @View
  return (
    <React.Fragment>
      {!isHidden && (
        additionalId ? (
        <div id={additionalId?.value}
          className={`btn_wrapper primary B-01 ${btnWrapperAdditionalClass?.value}`}
        >
          <button 
            className={`btn btn_default ${additionalClass?.value} ${
              isDisabled?.value && "disabled"
            } ${
              !agreementContext && agreementContext !== undefined && "disabled"
            }`}
            type="button"
            data-toggle="modal"
            data-target={`#${dataTargetValue?.value}`}
            onClick={(e) => handleClick(e, props?.fields?.onClickEvent)}
            disabled={
              isDisabled?.value ||
              (agreementContext !== undefined && !agreementContext)
            }
          >
            <Text field={fillBlank(buttonText)} />
          </button>
        </div>
        ) : (
          <div 
          className={`btn_wrapper primary B-01 ${btnWrapperAdditionalClass?.value}`}
        >
          <button
            className={`btn btn_default ${additionalClass?.value} ${
              isDisabled?.value && "disabled"
            } ${
              !agreementContext && agreementContext !== undefined && "disabled"
            }`}
            type="button"
            data-toggle="modal"
            data-target={`#${dataTargetValue?.value}`}
            onClick={(e) => handleClick(e, props?.fields?.onClickEvent)}
            disabled={
              isDisabled?.value ||
              (agreementContext !== undefined && !agreementContext)
            }
          >
            <Text field={fillBlank(buttonText)} />
          </button>
        </div>
        )
      )}
    </React.Fragment>
  );
};

export default B001;
