import React, { useEffect, useRef } from "react";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import parse from "html-react-parser";
import { di_fetch, di_register } from "../../utils/di";
import {
  fillBlank,
  getScDataFromPlaceholder,
  getScDataFromComponent,
} from "../../utils/commonUtils";
import {
  useGetDataViaGraphQL as UseGetDataViaGraphQL,
  useCustomQuery,
} from "../../hooks/useGetData";
import { query as queryCPadBannerItems } from "../../assets/graphql/cPadBannerMaster";
import { query as queryCPadToolButtonItems } from "../../assets/graphql/cPadToolButtonMaster";
import { apiBaseURL } from "../../envSettings";
import { dateCondition } from "../../utils/dateLogic";
import {
  STRING_GET,
  STRING_CPAD_CAROUSEL_PLACEHOLDER,
  STRING_LOADING,
} from "../../utils/constantVariables";
import { CommonCarousel } from "../../services/common/common-carousel";

const CPadBanner = (props) => {
  const bannerSection = getScDataFromPlaceholder(
    "cPadBanner-carousel-section",
    props
  );
  const [ic01Data] = getScDataFromComponent("IC-01", bannerSection);

  // fetch the Data from sitecore master
  let bannerItems = props.cPadBannerQuery?.BannerGeneral;

  // 4.2 Set the display time
  ic01Data.fields.sliderRef = useRef();

  const sliderPause = () => {
    return ic01Data.fields.sliderRef?.current?.slickPause();
  };
  if (bannerItems?.fields?.[0]?.value === "") sliderPause();

  bannerItems = {
    ...bannerItems?.fields?.reduce((a, field) => {
      a[field.name] = field.value === "" ? null : parseInt(field.value);
      return a;
    }, {}),
    children: bannerItems?.children?.map((child) => {
      return child.fields.reduce((a, field) => {
        if (field.name === "cPadBannerItem-Thumbnail")
          a[field.name] = apiBaseURL + parse(field.editable).props?.src;
        else if (field.name === "cPadBannerItem-LinkURL")
          a[field.name] = parse(field.editable).props?.href;
        else a[field.name] = field.editable;
        return a;
      }, {});
    }),
  };

  // 4.1 Display banner items that meet the following conditions.
  const filteredBannerImage = bannerItems.children?.filter((sort) => {
    if (
      sort["cPadBannerItem-PublicationStartDate"] &&
      sort["cPadBannerItem-PublicationEndDate"] !== undefined
    ) {
      return dateCondition(
        sort["cPadBannerItem-PublicationStartDate"],
        sort["cPadBannerItem-PublicationEndDate"]
      );
    } else {
      return sort;
    }
  });

  ic01Data.fields.delayTime = {
    value: bannerItems["cPadBanner-DelayTime"],
  };
  ic01Data.fields.carouselImages = filteredBannerImage?.map((child) => ({
    mainImage: {
      value: {
        src: child["cPadBannerItem-Thumbnail"],
      },
    },
    linkUrl: {
      value: {
        href: child["cPadBannerItem-LinkURL"],
      },
    },
    publicationStartDate: {
      value: child["cPadBannerItem-PublicationStartDate"],
    },
    publicationEndDate: {
      value: child["cPadBannerItem-PublicationEndDate"],
    },
  }));

  // Settings for slick carousel (IC-01)
  ic01Data.fields.newSettings = {
    centerMode: false,
    dots: false,
    infinite: true,
    slidesToShow:
      filteredBannerImage?.length < 3 ? filteredBannerImage?.length : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: ic01Data.fields.delayTime.value,
    variableWidth: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel-slide">
      <div className="multiple-slide">
        <Placeholder
          name="cPadBanner-carousel-section"
          rendering={props.rendering}
        />
      </div>
    </div>
  );
};

const CPadNewInfo = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  const {
    "CommonInformationList-Title": CommonInformationListTitle,
  } = sitecoreContext?.route?.fields;

  const informationList = props?.informationList;

  return (
    <>
      {informationList?.length > 0 && (
        <div className="latest_article">
          <h5>
            <Text field={fillBlank(CommonInformationListTitle?.value)} />
          </h5>
          <ul>
            {informationList?.map((info, index) => (
              <li key={info.PublicationStartDate + "_" + index}>
                <span className="article-date">
                  {format(new Date(info.PublicationStartDate), "yyyy/M/dd", {
                    locale: ja,
                  })}
                </span>
                <a href={info.InformationDetailURL}>
                  <p className="article-description">
                    <Text field={fillBlank(info?.InformationTitle)} />
                  </p>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

const CPadToolButtonItems = (props) => {
  const toolButtonSection = getScDataFromPlaceholder(
    "cpad-tool-button-section",
    props
  );
  const [toolButtonData] = getScDataFromComponent(
    "CPadToolButton",
    toolButtonSection
  );

  let toolButtonItems = props.cPadToolButtonItemsQuery?.CPadToolButton;

  toolButtonItems = {
    ...toolButtonItems?.fields.reduce((a, field) => {
      if (field.name === "cPadToolButtons-Image")
        a[field.name] = apiBaseURL + parse(field.editable).props?.src;
      else a[field.name] = field?.targetItem?.field?.value;
      return a;
    }, {}),
    children: toolButtonItems?.children?.map((child) => {
      return child.fields.reduce((a, field) => {
        if (field.name === "cPadToolImageButtonItem-Image")
          a[field.name] = apiBaseURL + parse(field.editable).props?.src;
        else if (
          field.name === "cPadToolTextButtonItem-Link" ||
          field.name === "cPadToolImageButtonItem-Link"
        )
          a[field.name] = parse(field.editable).props?.href;
        else if (
          field.name === "cPadToolTextButtonItem-BackgroundColor" ||
          field.name === "cPadToolImageButtonItem-BackgroundColor"
        )
          a[field.name] = field?.targetItem?.Value?.value;
        else if (
          field.name === "cPadToolTextButtonItem-URLType" ||
          field.name === "cPadToolImageButtonItem-URLType"
        )
          a[field.name] = field?.targetItem?.Code?.value;
        else a[field.name] = field?.editable;
        return a;
      }, {});
    }),
  };

  toolButtonData.fields.profileImage = {
    value: { src: toolButtonItems["cPadToolButtons-Image"] },
  };

  toolButtonData.fields.imagePosition = {
    value: toolButtonItems["cPadToolButtons-ImagePosition"],
  };

  toolButtonData.fields.buttonData = toolButtonItems.children?.map((child) => ({
    buttonColor: {
      value:
        child["cPadToolTextButtonItem-BackgroundColor"] ||
        child["cPadToolImageButtonItem-BackgroundColor"],
    },
    linkUrl: {
      value: {
        href:
          child["cPadToolTextButtonItem-Link"] ||
          child["cPadToolImageButtonItem-Link"],
      },
    },
    image: {
      value: {
        src: child["cPadToolImageButtonItem-Image"],
      },
    },
    text: {
      value: child["cPadToolTextButtonItem-Text"],
    },
    urlType: {
      fields: {
        Code: {
          value: 
            child["cPadToolTextButtonItem-URLType"] || 
            child["cPadToolImageButtonItem-URLType"]
        }
      }
    },
  }));

  return (
    <Placeholder name="cpad-tool-button-section" rendering={props.rendering} />
  );
};

const CPadTopPageLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  const {
    "CommonInformationList-DisplayCount": CommonInformationListDisplayCount,
  } = sitecoreContext?.route?.fields;

  const cPadBannerQuery = UseGetDataViaGraphQL(
    queryCPadBannerItems,
    null,
    "CPadBanner-Query"
  );

  const cPadToolButtonItemsQuery = UseGetDataViaGraphQL(
    queryCPadToolButtonItems,
    null,
    "CPadToolButtonItems-Query"
  );

  const RetrieveInformationListURI = `${apiBaseURL}common/RetrieveInformationList?SiteName=cpad&DisplayCount=${CommonInformationListDisplayCount?.value}`;

  const {
    data: RetrieveInformationListData,
    isLoading: isRetrieveInformationListLoading,
    error: RetrieveInformationListError,
    refetch: RetrieveInformationListRefetch,
    remove: RetrieveInformationListRemove,
  } = useCustomQuery(RetrieveInformationListURI, true, STRING_GET);

  const isLoading =
    isRetrieveInformationListLoading ||
    cPadBannerQuery === STRING_LOADING ||
    cPadToolButtonItemsQuery === STRING_LOADING;
  props.setIsLoading(isLoading);

  const informationList = RetrieveInformationListData?.RetrieveInformations;

  return (
    <React.Fragment>
      <main>
        <CommonCarousel
          rendering={props.rendering}
          sectionName={STRING_CPAD_CAROUSEL_PLACEHOLDER}
          multipleSlide={false}
          sitecoreContext={sitecoreContext}
        />

        <CPadBanner
          rendering={props.rendering}
          cPadBannerQuery={cPadBannerQuery}
        />

        <CPadNewInfo
          rendering={props.rendering}
          informationList={informationList}
          sitecoreContext={sitecoreContext}
        />

        <CPadToolButtonItems
          rendering={props.rendering}
          cPadToolButtonItemsQuery={cPadToolButtonItemsQuery}
        />
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(CPadTopPageLayout);
