import React, { useEffect, useState } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { deepcopy, generateGUID, getScDataFromComponent, getScDataFromPlaceholder } from "../../utils/commonUtils";
import {
  STRING_NO_PLAN_ERROR_MESSAGE,
  STRING_CATEGORY_NEW_CONTRACT,
  STRING_CATEGORY_TRANSFER,
  STRING_CATEGORY_MOVEMENT,
  STRING_CATEGORY_PRODUCT_CHANGE,
  STRING_CATEGORY_PLAN_CHANGE,
  STRING_CURRENT_SECTION_SELECT_PLAN,
  STRING_CURRENT_SECTION_SELECT_PRODUCT,
  STRING_OPTION_CHANGE,
  STRING_OPTION_NOT_CHANGE,
  STRING_SELECTED_PACKAGE,
  STRING_SELECTED_BUNDLE,
  STRING_SELECTED_PLAN,
  STRING_PLAN_NOT_EXIST,
  STRING_PLAN_EXIST,
  STRING_POST,
} from "../../utils/constantVariables";
import { getSessionStorageItem, setSessionStorageItem, removeSessionStorageItem } from "../../utils/useSessionStorage";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { parseFieldMetadata } from "../../utils/parseMetadata";
import { useDynamicParallelQuery } from "../../hooks/useGetData";
import { apiBaseURL as API_BASE_URL } from "../../envSettings";

export function SelectPlanAttribute(props) {
  const personalizeInfo = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO, true);
  const orderRelatedInfo = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  const [inputState, setInputState] = useState({});

  const [productIds, setProductIds] = useState([]);
  const { isLoading: isRetrieveOrderItemAttrDefinitionsLoading, results: orderItemAttrData } = useDynamicParallelQuery(
    productIds,
    `${API_BASE_URL}order/RetrieveOrderItemAttrDefinitions`,
    STRING_POST
  );
  props.setIsLoading(isRetrieveOrderItemAttrDefinitionsLoading);

  useEffect(() => {
    if (!orderRelatedInfo) return;
    if (
      orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos &&
      orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos?.length > 0
    ) {
      let productIds = [];

      orderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos.forEach((item) => {
        if (item?.orderItem?.CMAP_HasOrderItemAttribute__c === true) {
          productIds.push({
            productId: item.orderItem.Product2Id,
            orderChannelCategory: personalizeInfo?.AppChannelDivision,
          });
        }
      });
      setProductIds(productIds || []);
    }
  }, []);

  useEffect(() => {
    if (
      !orderItemAttrData ||
      orderItemAttrData?.length === 0 ||
      isRetrieveOrderItemAttrDefinitionsLoading
    ) {
      return;
    }

    const attrSection = getScDataFromPlaceholder("application-plan-attribute-section", props);
    const [s19Data] = getScDataFromComponent("S-19", attrSection);
    const [h03Data] = getScDataFromComponent("H-03", attrSection);

    // remove template s19data
    attrSection.splice(0);
    orderItemAttrData.forEach((response) => {
      const metadataDefinitions = response?.data?.data?.orderItemAttributeDefinitionInfo?.metadataDefinitions;
      for (let definition in metadataDefinitions) {
        const mainData = metadataDefinitions[definition];

        const h03copy = deepcopy(h03Data);
        h03copy.uid = "H-03_copy_" + generateGUID();
        h03copy.fields.titleText = { value: mainData.metadataBaseLabel };
        attrSection.push(h03copy);

        for (let field in mainData?.fieldMetadataDefinitions) {
          const parsedField = parseFieldMetadata(mainData?.fieldMetadataDefinitions[field]);

          const s19copy = deepcopy(s19Data);
          s19copy.uid = "S-19_copy_" + generateGUID();
          s19copy.fields.state = inputState;
          s19copy.fields.update = setInputState;
          s19copy.fields.metadata = parsedField;
          s19copy.fields.category = generateGUID();
          s19copy.fields.onChangeEvent = (value) => {
          };

          attrSection.push(s19copy);
        }
      }
    });
  }, [orderItemAttrData]);

  useEffect(() => {
  }, [inputState]);

  return (
    <React.Fragment>
      {/* SECTION 4 */}
      <div className="form_wrapper">
        <Placeholder name="form-heading-4" rendering={props.rendering} />
        <div className="form_wrapper-input">
          <Placeholder name="application-plan-attribute-section" rendering={props.rendering} />
        </div>
        <div className="btn_container">
          <Placeholder name="form-button-4" rendering={props.rendering} />
        </div>
      </div>
    </React.Fragment>
  );
}
