import React, { useEffect, useState } from "react";
import {
  Placeholder,
  VisitorIdentification,
} from "@sitecore-jss/sitecore-jss-react";
import Helmet from "react-helmet";
import { useForm, FormProvider } from "react-hook-form";
//import "bootstrap/dist/js/bootstrap"; //<-- TODO: Fix compatibility of Modal dialog in CD. This line is for the Modal dialog however it only works in disconnected mode!!!
// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
import "bootstrap/dist/css/bootstrap.css";
import "./assets/app.css";
import "./media/Project/commufa/welcome_commufa/css/import.scss";
import "./media/Project/commufa/welcome_commufa/css/import_cpad.scss";

import {
  gtmId,
  cpadPageURL,
  masterPageURL,
  mycommufaPageURL,
  cpadSiteSetting,
  masterSiteSetting,
  myCommufaSiteSetting,
  commonSetting,
} from "./envSettings";
import TagManager from "react-gtm-module";

import { recoilKeys as sessionKeys } from "./assets/recoilKeys";
import { query as ErrorMessagesQuery } from "./assets/graphql/retrieveErrorMessages";
import { query as CommonSiteSettingsQuery } from "./assets/graphql/CommonSiteSettings";
import { useGetDataViaGraphQL } from "./hooks/useGetData";

import { DotLoader } from "react-spinners";

import { di_register } from "./utils/di";
import { errorMessageFormatter } from "./utils/errorMessageHandler";
import { siteSettingFormatter } from "./utils/sitecoreSettingHandler";
import {
  STRING_LOADING,
  STRING_CORPORATION,
  STRING_INDIVIDUAL,
} from "./utils/constantVariables";
import { getCookieValue, deleteCookieValue } from "./utils/useCookies";
import { setSessionStorageItem, getSessionStorageItem, removeAllSessionStorageItem } from "./utils/useSessionStorage";
import { getUrlInfo } from "./utils/commonUtils";


/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes
// Navigation = withTranslation()(Navigation);

const saveServerSessionInfoData = (serverSessionInfoCookieValue) => {
  // parseCookie
  const serverSessionInfoData = JSON.parse(serverSessionInfoCookieValue) || "";

  // get SS
  const personalizeInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );

  // destructure SS
  const updatedPersonalizeInfo = {
    ...personalizeInfo,
    ...serverSessionInfoData
  };
  
  // save SS
  setSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    updatedPersonalizeInfo,
    true
  );
}

const saveCustomerInfoData = (customerInfoCookieValue) => {
  // parseCookie
  const customerInfoData = JSON.parse(customerInfoCookieValue) || "";

  // get SS
  const orderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );

  // destructure SS
  const updatedOrderRelatedInfo = {
    ...orderRelatedInfo,
    orderRelatedInfo: {
      ...orderRelatedInfo?.orderRelatedInfo,
      accountInfo: {
        ...orderRelatedInfo?.orderRelatedInfo?.accountInfo,
        account: {
          ...orderRelatedInfo?.accountInfo?.account,
          ...customerInfoData?.accountInfo?.account,
        }
      },
    },
  };
  
  const customerName =
    customerInfoData?.accountInfo?.account?.CMAP_ContractName__c === STRING_INDIVIDUAL
      ? `${customerInfoData?.accountInfo?.account?.LastName} ${customerInfoData?.accountInfo?.account?.FirstName}`
      : customerInfoData?.accountInfo?.account?.CMAP_ContractName__c === STRING_CORPORATION
        ? customerInfoData?.accountInfo?.account?.LastName
        : "";

  // save SS
  setSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    updatedOrderRelatedInfo,
    true
  );

  setSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_CUSTOMERID,
    customerInfoData?.accountInfo?.account?.Id,
    true
  );

  setSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_CUSTOMERNAME,
    customerName,
    true
  );

  setSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_MYCOMMUFAID,
    customerInfoData?.accountInfo?.account?.CMAP_MyCommufaID__c,
    true
  );

  setSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    updatedOrderRelatedInfo,
    true
  );
}

function getSitePath() {
  const currentSite = getUrlInfo()?.origin;
  switch (currentSite) {
    case cpadPageURL:
      return `${cpadSiteSetting}${commonSetting}`;
    case mycommufaPageURL:
      return `${myCommufaSiteSetting}${commonSetting}`;
    case masterPageURL:
    default:
      return `${masterSiteSetting}${commonSetting}`;
  }
}

const Layout = ({ route }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isApiCookieProcessing, setIsApiCookieProcessing] = useState(true);
  const showLoader = isLoading || isApiCookieProcessing;

  const methods = useForm();
  const errorMessages = useGetDataViaGraphQL(ErrorMessagesQuery, null, "errorMessagesQuery");
  di_register("errorMessages", errorMessageFormatter(errorMessages));

  const commonSettingData = useGetDataViaGraphQL(
    CommonSiteSettingsQuery, 
    {"site" : getSitePath()}, 
    "CommonSiteSettingsQuery"
  );
  const formattedSetting = siteSettingFormatter(commonSettingData);
  const match = formattedSetting?.Favicon?.rendered.match(new RegExp('src="([^"]*)"'));
  const favicon = match && match[1] ? match[1] : "";

  useEffect(() => {
    setIsLoading(errorMessages === STRING_LOADING && commonSettingData === STRING_LOADING);
  }, [errorMessages, commonSettingData]);

  // get header api result trough cookies
  useEffect(() => {
    // get cookies
    const serverSessionInfoCookieValue = getCookieValue(sessionKeys.COOKIE_CTC_SERVER_SESSION_INFO) || null;
    const customerInfoCookieValue = getCookieValue(sessionKeys.COOKIE_CTC_CUSTOMER_INFO) || null;
    const logoutCookieValue = getCookieValue(sessionKeys.COOKIE_CTC_LOGOUT_FLG) || null;

    if (serverSessionInfoCookieValue) {
      // save SS
      saveServerSessionInfoData(serverSessionInfoCookieValue);

      // delete cookie
      deleteCookieValue(sessionKeys.COOKIE_CTC_SERVER_SESSION_INFO);
    }

    if (customerInfoCookieValue) {
      // get url info
      const urlInfo = getUrlInfo();
      const isCpad = urlInfo?.origin === cpadPageURL;

      if (!isCpad) {
        // save SS
        saveCustomerInfoData(customerInfoCookieValue);
      }
      
      // delete cookie
      deleteCookieValue(sessionKeys.COOKIE_CTC_CUSTOMER_INFO);
    }

    if (logoutCookieValue) {
      // remove unnecessary SS
      removeAllSessionStorageItem();
      
      // delete cookie
      deleteCookieValue(sessionKeys.COOKIE_CTC_LOGOUT_FLG);
    }

    // set the process to over
    setIsApiCookieProcessing(false);
  }, []);

  const onSubmit = (data) => {
    // data handling when the user submite the data
  };

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: gtmId,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields &&
            route.fields.BrowserTitle &&
            route.fields.BrowserTitle.value) ||
            "Page"}
        </title>
        <link rel="shortcut icon" href={favicon} />
        <meta name="robots" content="noindex"/>
      </Helmet>
      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />

      {/* root placeholder for the app, which we add components to using route data */}
      
      {!isApiCookieProcessing && (
        <Placeholder name="jss-header" rendering={route} />
      )}

      {showLoader && (
        <div className="screen-loading">
          <DotLoader
            loading={showLoader}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="spinner"
            color="#FFF"
          />
        </div>
      )}

      {!isApiCookieProcessing && (
        <FormProvider className="forms" {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Placeholder name="jss-main-top" rendering={route} />
            <Placeholder
              name="jss-main"
              rendering={route}
              setIsLoading={setIsLoading}
            />
            <Placeholder name="jss-main-bottom" rendering={route} />
          </form>
        </FormProvider>
      )}
      <Placeholder name="jss-footer" rendering={route} />
    </React.Fragment>
  );
};
export default Layout;
