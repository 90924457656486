export const query = `fragment ErrorMesageItem on Item {
    ... on ErrorMessage {
        errorCode {
            value
        } 
        errorMessage {
            value
        } 
    }
}

query errorMessageQuery {
    item(path : "/sitecore/content/jss/masterjss/Data/Error Messages") {
        children {
            ...ErrorMesageItem
        }
    }
}`;