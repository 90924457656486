import React, { useEffect, useState } from "react";
import DotLoader from "react-spinners/DotLoader";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import { recoilKeys } from "../../assets/recoilKeys";
import { getCookieValue, deleteCookieValue } from "../../utils/useCookies";
import { setSessionStorageItem } from "../../utils/useSessionStorage";
import { redirectToLinkUrl } from "../../utils/targetUrlType";
import { SAME_WINDOW } from "../../utils/constantVariables";
import { useCustomQuery } from "../../hooks/useGetData";
import { apiBaseURL } from "../../envSettings";
import { stringToJSON } from "../../utils/commonUtils";

const RestoreOrderInfoLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);
  const [applicationId, setApplicationId] = useState("");

  const retrieveApplicationDataURI = `${apiBaseURL}order/RetrieveApplicationData?ApplicationId=${applicationId?.toString()}`;
  const {
    data: retrieveApplicationData,
    isLoading: retrieveApplicationDataIsLoading,
    error: retrieveApplicationDataError,
    refetch: retrieveApplicationDataRefresh,
    remove: retrieveApplicationDataRemove,
  } = useCustomQuery(retrieveApplicationDataURI, false);

  const redirectToExpireErrorLink = () => {
    const linkURL =
      sitecoreContext.route.fields["RestoreOrderInfo-ExpiredErrorLink"]?.value;
    redirectToLinkUrl(linkURL, SAME_WINDOW);
  };

  useEffect(() => {
    const applicationData = getCookieValue(recoilKeys.COOKIE_APPLICATION_ID);
    setApplicationId(applicationData);
  }, []);

  useEffect(() => {
    if (applicationId) {
      retrieveApplicationDataRefresh();
    } else {
      redirectToExpireErrorLink();
    }
  }, [applicationId]);

  useEffect(() => {
    if (retrieveApplicationData) {
      if (retrieveApplicationData?.ApplicationData) {
        const cleanedApplicationData = stringToJSON(
          retrieveApplicationData.ApplicationData
        );

        if (cleanedApplicationData) {
          //set session storage keys and values dynamically
          Object.entries(cleanedApplicationData).forEach((key) => {
            setSessionStorageItem(key[0], stringToJSON(key[1]), true);
          });

          const mailAddress = getCookieValue(recoilKeys.COOKIE_MAIL_ADDRESS);
          setSessionStorageItem(
            recoilKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESS,
            mailAddress.split("@")[0],
            true
          );
          setSessionStorageItem(
            recoilKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSDOMAIN,
            mailAddress.split("@")[1],
            true
          );

          deleteCookieValue(recoilKeys.COOKIE_MAIL_ADDRESS);
          deleteCookieValue(recoilKeys.COOKIE_APPLICATION_ID);

          const linkURL =
            sitecoreContext.route.fields["RestoreOrderInfo-NextLink"]?.value;
          redirectToLinkUrl(linkURL, SAME_WINDOW);
        }
      } else {
        redirectToExpireErrorLink();
      }
    }
  }, [retrieveApplicationData]);

  return (
    <>
      <div className="screen-loading">
        <DotLoader
          loading={true}
          aria-label="Loading Spinner"
          data-testid="loader"
          className="spinner"
          color="#FFF"
        />
      </div>
    </>
  );
};

export default withSitecoreContext()(RestoreOrderInfoLayout);
