import React, { useState, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { fillBlank, getProperData } from "../../utils/commonUtils";

// @Model

// get the data

// update the context

// @Controller

// @View

const L11 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { noteText } = properData;

  const [isNoteText, setNoteText] = useState("");
  useEffect(() => {
    setNoteText(noteText);
  }, [noteText]);
  
  return (
    <React.Fragment>
      <div className="list_wrapper with-background L-11">
        <p>
          <Text field={fillBlank(noteText)} />
        </p>
      </div>
    </React.Fragment>
  );
};

export default L11;
