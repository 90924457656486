import React from "react";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const S07 = (props) => {
  const { checkBoxLabel, additionalClass } = props.fields;

  return (
    <React.Fragment>
      <div className={`input-form S-07 ${additionalClass?.value}`}>
        <div className="input-box multi-type">
          <div className="input-container">
            <input type="checkbox" name="cbQ3S5" disabled="" />
            <label htmlFor="cbQ3S5">
              <Text field={fillBlank(checkBoxLabel)} />
            </label>
            <Placeholder name="input-content" rendering={props.rendering} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default S07;
