import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import React, { useEffect, useState } from "react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank, nullChecker } from "../../utils/commonUtils";
import { useSetSelectedItem } from "../../hooks/useSelectedState";

const S01 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    titleText,
    radioBoxLabel,
    inputValue,
    initTextValues = {},
    isHidden = false,
    groupings,
    optionId,
    checked
  } = properData;

  const componentCategory = nullChecker(properData?.category)?.value;
  const additionalClass = nullChecker(properData?.additionalClass);
  const requiredText = nullChecker(properData?.requiredText);
  const selections = nullChecker(properData?.selections, []);

  // @Controller
  const [selectedItem, selectedItemSetter] = useSetSelectedItem(
    componentCategory
  );

  // @Controller
  const handleRadioButtonChange = (event) => {
    // changed context
    const value = event.target.value;
    let newVal = {};
    newVal[componentCategory] = value;
    selectedItemSetter(newVal);
    if (properData.onChangeEvent) properData.onChangeEvent(value);
  };

  const [textAreaValues, setTextAreaValues] = useState(initTextValues);
  const handleTextAreaChange = (evt, item) => {
    const value = evt?.target?.value;
    setTextAreaValues((prev) => ({ ...prev, [item.Code]: value }));
    if (item.onTextBoxChange) item.onTextBoxChange(value);
  };

  useEffect(() => {
    if (Object.keys(initTextValues).length > 0)
      setTextAreaValues(initTextValues);
  }, [initTextValues]);

  // @View
  return (
    <React.Fragment>
      {!isHidden && (
        <div className={`input-form S-01 ${additionalClass?.value}`}>
          {titleText?.value && (
            <label>
              <Text field={fillBlank(titleText)} />
              {requiredText?.value && (
                <span className="required">
                  <Text field={fillBlank(requiredText)} />
                </span>
              )}
            </label>
          )}
          <Placeholder name="description-text" rendering={props.rendering} />

          {selections?.length > 0 ? (
            selections.map((item, i) => (
              <div
                key={item.Code}
                className={`input-box ${
                  selectedItem.selected === item.Code ? "active" : ""
                }`}
              >
                <div className="input-container">
                  <input
                    type="radio"
                    id={`rdQ${i}`}
                    name={componentCategory}
                    value={item.Code}
                    checked={
                      item.Checked || selectedItem.selected === item.Code
                    }
                    onChange={handleRadioButtonChange}
                    className={inputValue?.isDisabled ? "disabled" : ""}
                    disabled={inputValue && inputValue?.isDisabled}
                  />
                  <label htmlFor={`rdQ${i}`}>
                    <Text field={fillBlank(item.Value)} />
                    {item?.HasTextArea && (
                      <textarea
                        id={`rdQ${i}`}
                        placeholder={item?.Placeholder}
                        disabled={
                          selectedItem?.selected === item.Code ? false : true
                        }
                        onChange={(e) => handleTextAreaChange(e, item)}
                        value={textAreaValues[item.Code] || ""}
                        maxLength={item?.textarea?.maxLength}
                      />
                    )}
                  </label>
                </div>
              </div>
            ))
          ) : (
          <div className="input-box">
            <div className="input-container">
              <input
                type="radio"
                name={groupings === undefined ? "cbQ2" : groupings }
                className={inputValue?.isDisabled ? "disabled" : ""}
                disabled={inputValue && inputValue?.isDisabled}
                onChange={handleRadioButtonChange}
                id={optionId}
                checked={checked}
              />
              <label htmlFor="cbQ2">
                <Text field={fillBlank(radioBoxLabel)} />
              </label>
            </div>
          </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default S01;
