import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CPad74PageLayout = (props) => {
  const { currencySign, dealsText } = props.fields;
  const testDrawLine = (tgtChart) => {
    return tgtChart?.data?.datasets?.forEach((dataset, datasetIndex) => {
      if (datasetIndex === tgtChart._sortedMetasets?.length - 1) {
        dataset.data.forEach((dataPoint, index) => {
          if (index <= dataset?.data?.length - 1 && tgtChart) {
            let bar_width = tgtChart.getDatasetMeta(datasetIndex).data[index]
              .width;
            let bar_width_half = bar_width / 2;
            tgtChart.ctx.beginPath();
            tgtChart.ctx.setLineDash([2, 2]);
            tgtChart.ctx.moveTo(
              tgtChart.getDatasetMeta(datasetIndex).data[index]?.x +
                bar_width_half,
              tgtChart.getDatasetMeta(datasetIndex).data[index]?.y
            );
            tgtChart.ctx.lineTo(
              tgtChart.getDatasetMeta(datasetIndex).data[index + 1]?.x -
                bar_width_half,
              tgtChart.getDatasetMeta(datasetIndex).data[index + 1]?.y
            );
            tgtChart.ctx.stroke();
          }
        });
      }
    });
  };

  const dataLabelSumPlugin = (chart, easing) => {
    const ctx = chart.ctx;
    const sums = [];

    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      if (!meta.hidden) {
        meta.data.forEach((element, index) => {
          if (i === 0) {
            sums[index] = 0;
          }
          const fontSize = 16;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          const padding = 10;
          const position = element.tooltipPosition();
          sums[index] = sums[index] + dataset.data[index];
          let diff = sums?.reduce((a, b) => (a = b - a), 0);
          let txw = ctx.measureText(ctx.font);
          let txw_half = 70 / 2;
          let twx_height =
            txw.actualBoundingBoxAscent + txw.actualBoundingBoxDescent;
          ctx.fillStyle = "rgba(0,0,0,0)";
          if (i === chart.data.datasets.length - 1) {
            fillRoundRect(
              ctx,
              position.x - txw_half,
              position.y - 30,
              70,
              twx_height + 11,
              13
            );
            ctx.fillStyle = "#000";
            ctx.fillText(
              sums[index].toLocaleString() + currencySign.value,
              position.x,
              position.y - fontSize / 2 - padding - 1
            );
          }

          let count = 0;
          if (index === 1 && count !== i) {
            ctx.fillStyle = "#000";
            ctx.fillText(
              diff.toLocaleString() + dealsText.value,
              position.x,
              position.y - fontSize / 2 - padding - 25
            );
          }
        });
      }
    });
  };

  const createRoundRectPath = (ctx, x, y, width, height, radius) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
    ctx.fill();
  };

  const fillRoundRect = (ctx, x, y, w, h, r) => {
    createRoundRectPath(ctx, x, y, w, h, r);
    ctx.fill();
  };
  const options = {
    responsive: false,
    maintainAspectRation: false,
    barPercentage: 1,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      testDrawLine: true,
      function: true,
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: (context) => {
          let value = "#fff";

          if (context.datasetIndex === 1) {
            return (value = "#000");
          }

          return value;
        },
        formatter: (value, context) => {
          return value?.toLocaleString() + currencySign.value;
        },
      },
    },
    layout: {
      padding: {
        left: -5,
        right: 30,
        top: 40,
        bottom: 0,
      },
    },
  };

  return (
    <React.Fragment>
      <main>
        <div className="form_container cpad_wrapper2">
          <div className="form_detail full-width">
            <div className="cpad-tables">
              <table className="border-style-1">
                <thead>
                  <tr>
                    <th className="no-border" rowSpan={2}>
                      &nbsp;
                    </th>
                    <th>現在利用回線</th>
                    <th colSpan={3}>コミュファ月額シミュレーション</th>
                    <th colSpan={3}>総費用の差額</th>
                  </tr>
                  <tr>
                    <th width="174px">NTTフレッツ光</th>
                    <th width="130px">1年目/CPあり</th>
                    <th width="130px">1年目/CP終了後</th>
                    <th width="130px">2年目以降</th>
                    <th width="105px">&nbsp;</th>
                    <th width="105px">2年目</th>
                    <th width="105px">5年目</th>
                  </tr>
                </thead>
                <tbody className="left-align-text no-border-left-fc">
                  <tr>
                    <td>基本料</td>
                    <td>7,300円</td>
                    <td>5,800円</td>
                    <td>5,800円</td>
                    <td>5,800円</td>
                    <td>現在利用回線</td>
                    <td>187,200円</td>
                    <td>468,000円</td>
                  </tr>
                  <tr>
                    <td>割引</td>
                    <td>-1,000円</td>
                    <td>
                      -500円 <br />
                      CP割引
                    </td>
                    <td>
                      -500円 <br />
                      長期継続割
                    </td>
                    <td>
                      -500円 <br />
                      長期継続割
                    </td>
                    <td>コミュファ</td>
                    <td>163,200円</td>
                    <td>408,000円</td>
                  </tr>
                  <tr>
                    <td>オプション</td>
                    <td>
                      <div className="side-td">
                        <div className="D-01">
                          <p>Hulu</p>
                        </div>
                        <div className="D-01">
                          <p>800円</p>
                        </div>
                      </div>
                      <div className="side-td">
                        <div className="D-01">
                          <p>dBook</p>
                        </div>
                        <div className="D-01">
                          <p>700円</p>
                        </div>
                      </div>
                    </td>
                    <td>1,500円</td>
                    <td>1,500円</td>
                    <td>1,500円</td>
                    <td rowSpan="2">&nbsp;</td>
                    <td rowSpan="2">&nbsp;</td>
                    <td rowSpan="2">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>合計</td>
                    <td>7,800円</td>
                    <td>6,800円</td>
                    <td>6,800円</td>
                    <td>6,300円</td>
                  </tr>
                  <tr>
                    <td>差額</td>
                    <td>&nbsp;</td>
                    <td>
                      <div className="txt-small">
                        <span>現在回線との差額</span> <br />
                        -1,000円
                      </div>
                    </td>
                    <td>
                      <div className="txt-small">
                        <span>1年目CPありとの差額</span> <br />
                        0円
                      </div>
                    </td>
                    <td>
                      <div className="txt-small">
                        <span>1年目CP終了後との差額</span> <br />
                        -500円
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td>
                      <div className="txt-small">
                        <span>2年目の差額</span> <br />
                        -24,000円
                      </div>
                    </td>
                    <td>
                      <div className="txt-small">
                        <span>5年目の差額</span> <br />
                        -60,000円
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="chart-section">
              <div className="card-estimate-section">
                <div className="card" id="ED-02">
                  <div className="card-body">
                    <div className="estimate_wrapper ED-02">
                      <div className="sp-estimate-modal">
                        <div className="sp_estimate-display">
                          <div className="estimate_wrapper-display">
                            <section>
                              <h2>コミュファ</h2>
                              <p>
                                <span className="bold">
                                  <span className="text-primary text-big">
                                    6,800
                                  </span>
                                  円{" "}
                                  <span className="regular">(税込)/月額</span>
                                </span>
                              </p>
                              <ul className="section-list">
                                <li>
                                  <dl>
                                    <dt>基本料金</dt>
                                    <dd>5,800円</dd>
                                  </dl>
                                </li>
                                <li>
                                  <dl>
                                    <dt>8%割引</dt>
                                    <dd>-500円</dd>
                                  </dl>
                                </li>
                                <li>
                                  <dl>
                                    <dt>オプション</dt>
                                    <dd>1,500円</dd>
                                  </dl>
                                </li>
                              </ul>
                            </section>

                            <section className="white-bg">
                              <h3>初期費用</h3>
                              <ul className="section-list">
                                <li>
                                  <dl>
                                    <dt>XXXXXX工事費</dt>
                                    <dd>4,000円</dd>
                                  </dl>
                                </li>
                              </ul>
                            </section>
                            <section className="white-bg">
                              <h3>特典</h3>
                              <ul className="s-list">
                                <li>Netflix最大2ヶ月分プレゼント</li>
                              </ul>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-container">
                <div className="graph-top-section">
                  <div className="input-box M-01">
                    <div className="input-form">
                      <div className="input-container">
                        <div className="pull-down">
                          <select id="AreaSelection">
                            <option>選択してください</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="graph-legends">
                    <div className="D-01">
                      <div className="chart-legend">&nbsp;</div>
                      <p>基本料金</p>
                    </div>
                    <div className="D-01">
                      <div className="chart-legend">&nbsp;</div>
                      <p>オプション</p>
                    </div>
                  </div>
                </div>

                <div className="graph-container">
                  <div className="bar-graph-item">
                    <div className="D-01">
                      <p>月額(CP期間)</p>
                    </div>
                    <Bar
                      data={{
                        labels: ["現在の利用額", "コミュファ"],
                        datasets: [
                          {
                            label: "基本料金",
                            data: [7300, 5800],
                            backgroundColor: ["#ED6C00", "#ED6C00"],
                          },
                          {
                            label: "オプション",
                            data: [1500, 1500],
                            backgroundColor: ["#AAD1EF", "#AAD1EF"],
                          },
                        ],
                      }}
                      width={190}
                      height={370}
                      options={options}
                      plugins={[
                        ChartDataLabels,
                        { afterDraw: testDrawLine },
                        { afterDraw: dataLabelSumPlugin },
                      ]}
                    />
                  </div>
                  <div className="bar-graph-item">
                    <div className="D-01">
                      <p>月額(CP終了後)</p>
                    </div>
                    <Bar
                      data={{
                        labels: ["現在利用回線", "コミュファ"],
                        datasets: [
                          {
                            label: "基本料金",
                            data: [7300, 5800],
                            backgroundColor: ["#ED6C00", "#ED6C00"],
                          },
                          {
                            label: "オプション",
                            data: [1500, 1500],
                            backgroundColor: ["#AAD1EF", "#AAD1EF"],
                          },
                        ],
                      }}
                      width={190}
                      height={370}
                      options={options}
                      plugins={[
                        ChartDataLabels,
                        { afterDraw: testDrawLine },
                        { afterDraw: dataLabelSumPlugin },
                      ]}
                    />
                  </div>
                  <div className="bar-graph-item">
                    <div className="D-01">
                      <p>2年計</p>
                    </div>
                    <Bar
                      data={{
                        labels: ["現在利用回線", "コミュファ"],
                        datasets: [
                          {
                            label: "基本料金",
                            data: [151200, 139200],
                            backgroundColor: ["#ED6C00", "#ED6C00"],
                          },
                          {
                            label: "オプション",
                            data: [36000, 36000],
                            backgroundColor: ["#AAD1EF", "#AAD1EF"],
                          },
                        ],
                      }}
                      width={190}
                      height={370}
                      options={options}
                      plugins={[
                        ChartDataLabels,
                        { afterDraw: testDrawLine },
                        { afterDraw: dataLabelSumPlugin },
                      ]}
                    />
                  </div>
                  <div className="bar-graph-item">
                    <div className="D-01">
                      <p>5年計</p>
                    </div>
                    <Bar
                      data={{
                        labels: ["現在の利用額", "コミュファ"],
                        datasets: [
                          {
                            label: "基本料金",
                            data: [438000, 348000],
                            backgroundColor: ["#ED6C00", "#ED6C00"],
                          },
                          {
                            label: "オプション",
                            data: [90000, 90000],
                            backgroundColor: ["#AAD1EF", "#AAD1EF"],
                          },
                        ],
                      }}
                      width={190}
                      height={370}
                      options={options}
                      plugins={[
                        ChartDataLabels,
                        { afterDraw: testDrawLine },
                        { afterDraw: dataLabelSumPlugin },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-section chart-bottom-section">
              <div className="btn_wrapper secondary B-02">
                <button className="btn btn_default" type="button">
                  戻る
                </button>
              </div>
              <div className="btn_wrapper primary B-01">
                <button className="btn btn_default" type="button">
                  お申し込みに進む
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default CPad74PageLayout;
