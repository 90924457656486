import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import ModalContext from "../../assets/context/ModalContext";
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const BM04 = (props) => {
  const { requiredMessageText, consentText, modalTarget } = props.fields;

  const { state, dispatch } = useContext(ModalContext);

  const modalButtonShowHandler = () => {
    dispatch({ type: "SHOW_MODAL", payload: { id: modalTarget?.value } });
  };

  return (
    <React.Fragment>
      <div className="btn_wrapper consent BM-04">
        <button type="button" onClick={() => modalButtonShowHandler()}>
          {/* className="disabled" */}
          <div className="input-container">
            <span className="required">
              <Text field={fillBlank(requiredMessageText)} />
              {/* TODO: There's a logic that the required text will be hidden */}
            </span>
            <input type="checkbox" name="consent1" />
            <label htmlFor="consent1">
              <Text field={fillBlank(consentText)} />
            </label>
          </div>
        </button>
      </div>
    </React.Fragment>
  );
};

export default BM04;
