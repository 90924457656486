import { recoilKeys as keys } from "../../../assets/recoilKeys";
import {
  getSessionStorageItem,
} from "../../../utils/useSessionStorage";
import {
  filterSelectedInitialCostProduct,
  filterSelectedRemainingDebtProduct,
  createSubtotalObj,
  calculateInitialCostTaxInc,
  calculateInitialCostTaxExc,
  calculateRemainingDebtTaxInc,
  calculateRemainingDebtTaxExc
} from "../Common/CommonProcess"

// 解約事務手数料
export const createCancellationChargeObj = () => {
  // SessionStorageから変更後のOrderRelatedInfoを読み込む。
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更後のOrderRelatedInfoから対象の初期費用商品を抽出する。
  const selectedInitialCostProduct = filterSelectedInitialCostProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 表示用オブジェクト_商品小計を作成する。
  const subtotalObj = createSubtotalObj(selectedInitialCostProduct, calculateInitialCostTaxExc, calculateInitialCostTaxInc);

  return subtotalObj;
}

// 残債
export const createDebtObj = () => {
  // SessionStorageから変更後のOrderRelatedInfoを読み込む。
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更後のOrderRelatedInfoから対象の残債商品を抽出する。
  const selectedRemainingDebtProduct = filterSelectedRemainingDebtProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 表示用オブジェクト_商品小計を作成する。
  const subtotalObj = createSubtotalObj(selectedRemainingDebtProduct, calculateRemainingDebtTaxExc, calculateRemainingDebtTaxInc);

  return subtotalObj;
}