import React, { useEffect, useState } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
} from "../../utils/commonUtils";
import {
  STRING_NO_PLAN_ERROR_MESSAGE,
  STRING_CATEGORY_NEW_CONTRACT,
  STRING_CATEGORY_TRANSFER,
  STRING_CATEGORY_MOVEMENT,
  STRING_CATEGORY_PRODUCT_CHANGE,
  STRING_CATEGORY_PLAN_CHANGE,
  STRING_CURRENT_SECTION_SELECT_PLAN,
  STRING_CURRENT_SECTION_SELECT_PRODUCT,
  STRING_OPTION_CHANGE,
  STRING_OPTION_NOT_CHANGE,
  STRING_SELECTED_PACKAGE,
  STRING_SELECTED_BUNDLE,
  STRING_SELECTED_PLAN,
  STRING_PLAN_NOT_EXIST,
  STRING_PLAN_EXIST,
} from "../../utils/constantVariables";
import {
  getSessionStorageItem,
  setSessionStorageItem,
  removeSessionStorageItem,
} from "../../utils/useSessionStorage";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { useSetSelectedItem } from "../../hooks/useSelectedState";

export function SelectPlan(props) {
  const retrieveOfferPlanListData = props.retrieveOfferPlanListData;

  let selectedPlanInfo = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_SELECTPRODUCT_SELECTEDPLANINFO,
    true
  );

  const {
    orderRelatedInfo: beforeChangeOrderRelatedInfo,
  } = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO,
    true
  );
  const { orderRelatedInfo } = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );
  const orderCategoryCurrentlySelected =
    orderRelatedInfo?.orderInfo?.orderAddition?.orderCategoryCurrentlySelected;

  const [isPackageHidden, setIsPackageHidden] = useState(
    orderCategoryCurrentlySelected === STRING_CATEGORY_MOVEMENT
  );
  const [isBundleHidden, setIsBundleHidden] = useState(true);
  const [isPlanHidden, setIsPlanHidden] = useState(true);
  const [isPackageChangeInSession, setIsPackageChangeInSession] = useState(
    false
  );

  const [selectedPackage, setSelectedPackage] = useSetSelectedItem(
    STRING_SELECTED_PACKAGE
  );
  const [selectedBundle, setSelectedBundle] = useSetSelectedItem(
    STRING_SELECTED_BUNDLE
  );
  const [selectedPlan, setSelectedPlan] = useSetSelectedItem(
    STRING_SELECTED_PLAN
  );

  useEffect(() => {
    if (props.currentSection !== STRING_CURRENT_SECTION_SELECT_PLAN) return;
    if(selectedPlanInfo.OfferPackageId !== selectedPackage?.selected) {
      props.setIsPackageChange(true);
    }

    if (typeof selectedPackage?.selected !== "undefined") {
      createSession();
    }

    if (
      typeof selectedPackage?.selected !== "undefined" &&
      typeof selectedBundle?.selected !== "undefined" &&
      typeof selectedPlan?.selected !== "undefined"
    ) {
      props.setBM05Data("");
    } else {
      props.setBM05Data("disabled");
    }
  }, [
    selectedPackage?.selected,
    selectedBundle?.selected,
    selectedPlan?.selected,
  ]);

  const planAvailedSection = getScDataFromPlaceholder(
    "plan-availed-section",
    props
  );
  const [d01Data] = getScDataFromComponent("D-01", planAvailedSection);
  const [s06Data] = getScDataFromComponent("S-06", planAvailedSection);

  const [existPlan, setExistPlan] = useState({
    isHidden: s06Data.fields.isHidden,
    isHiddenOption: s06Data.fields.isHiddenOption,
    titleText: s06Data.fields.titleText,
    descriptionText: s06Data.fields.descriptionText,
  });

  s06Data.fields.isHidden = existPlan.isHidden;
  s06Data.fields.isHiddenOption = existPlan.isHiddenOption;
  s06Data.fields.titleText = existPlan.titleText;
  s06Data.fields.descriptionText = existPlan.descriptionText;

  const [h02Data] = getScDataFromComponent(
    "H-02",
    getScDataFromPlaceholder("form-heading-1", props)
  );
  h02Data.fields.enteredClass =
    props.currentSection !== STRING_CURRENT_SECTION_SELECT_PLAN
      ? "entered"
      : "";

  const createSession = () => {
    selectedPlanInfo = {
      OfferPackageId: selectedPackage?.selected,
      OfferBundleId: selectedBundle?.selected,
      OfferPlan: selectedPlan?.selected,
      // TODO : if you need planName in the future OfferPlanName: s15PlanNameSelected,
    };

    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_SELECTPRODUCT_SELECTEDPLANINFO,
      selectedPlanInfo,
      true
    );
  };

  const checkIsPlan = () => {
    let planExistData = [];
    const packageList = retrieveOfferPlanListData?.offerPlansInfo?.packageInfos;
    packageList.find((packageItem) => {
      packageItem.bundleInfos.find((bundleInfo) => {
        const isPlanExist = bundleInfo.offerPlans.filter(
          (offerPlan) =>
            offerPlan.Id ===
            beforeChangeOrderRelatedInfo?.orderInfo?.order?.CMAP_OfferPlan__c
        );

        if (isPlanExist.length !== 0) {
          planExistData = isPlanExist;
        }
      });
    });

    return planExistData;
  };

  const triggerChangePlanWorkflow = () => {
    if (orderCategoryCurrentlySelected === STRING_CATEGORY_PLAN_CHANGE) {
      let planExistData = checkIsPlan();

      d01Data.fields.descriptionText = STRING_PLAN_EXIST;
      if (planExistData.length !== 0) {
        setExistPlan({
          isHidden: false,
          isHiddenOption: { value: true },
          titleText: planExistData[0]?.CMAP_OfferPlanNameForDisplay__c,
          descriptionText: planExistData[0]?.CMAP_Description__c,
        });
      } else {
        setExistPlan((prev) => ({ ...prev, isHidden: true }));
      }
    }
  };

  const triggerMovementWorkflow = () => {
    if (orderCategoryCurrentlySelected === STRING_CATEGORY_MOVEMENT) {
      let planExistData = checkIsPlan();

      if (planExistData.length !== 0) {
        setIsPackageHidden(true);
        setIsBundleHidden(true);
        setIsPlanHidden(true);
        setExistPlan({
          isHidden: false,
          titleText: planExistData[0]?.CMAP_OfferPlanNameForDisplay__c,
          descriptionText: planExistData[0]?.CMAP_Description__c,
        });
      } else {
        setIsPackageHidden(false);
        setExistPlan((prev) => ({ ...prev, isHidden: true }));
        d01Data.fields.descriptionText = STRING_PLAN_NOT_EXIST;
      }
    }
  };

  if (props.currentSection !== STRING_CURRENT_SECTION_SELECT_PLAN) {
    if (!existPlan.isHidden)
      setExistPlan((prev) => ({ ...prev, isHidden: true }));
    d01Data.fields.isHidden = { value: true };
    removeSessionStorageItem(STRING_SELECTED_PACKAGE);
    removeSessionStorageItem(STRING_SELECTED_BUNDLE);
    removeSessionStorageItem(STRING_SELECTED_PLAN);
  } else {
    d01Data.fields.isHidden = { value: false };
  }

  if (props.triggeredChangePlanWorkFlow) {
    props.setTriggeredChangePlanWorkFlow(false);
    triggerChangePlanWorkflow();
  }
  if (props.triggeredMovementWorkFlow) {
    props.setTriggeredMovementWorkFlow(false);
    triggerMovementWorkflow();
  }

  s06Data.fields.onChangeEvent = (event) => {
    setSelectedPackage({
      [STRING_SELECTED_PACKAGE]:
        orderRelatedInfo?.orderInfo?.order?.CMAP_OfferPackageId__c,
    });

    setSelectedPlan({
      [STRING_SELECTED_PLAN]:
        orderRelatedInfo?.orderInfo?.order?.CMAP_OfferPlan__c,
    });

    const packageList = retrieveOfferPlanListData?.offerPlansInfo?.packageInfos;
    packageList.find((packageItem) => {
      packageItem.bundleInfos.find((bundleInfo) => {
        const isPlanExist = bundleInfo.offerPlans.some(
          (offerPlan) =>
            offerPlan.Id ===
            orderRelatedInfo?.orderInfo?.order?.CMAP_OfferPlan__c
        );
        if (isPlanExist) {
          setSelectedBundle({
            [STRING_SELECTED_BUNDLE]: bundleInfo.CMAP_PackageBundle__c.Id,
          });
          return;
        }
      });
    });

    createSession();

    // TODO: in the future if you need planName just add here
    if (
      typeof selectedPackage?.selected !== "undefined" &&
      typeof selectedPlan?.selected !== "undefined"
    ) {
      props.setBM05Data("");
    } else {
      props.setBM05Data("disabled");
    }

    if (event.target.value === STRING_OPTION_CHANGE) {
      setIsPackageHidden(false);
      props.setIsPlanSelectionCompleted(false);
    }
    if (event.target.value === STRING_OPTION_NOT_CHANGE) {
      props.setBM05Data("");
      props.setIsPlanSelectionCompleted(true);
    }
  };

  // Plan Selection Logic
  const planSelectionSection = getScDataFromPlaceholder(
    "plan-selection-section",
    props
  );

  const [h03PlanSelectionData] = getScDataFromComponent(
    "H-03",
    planSelectionSection
  );
  h03PlanSelectionData.fields.isHidden = isPlanHidden;

  const [s15PlanSelectionData] = getScDataFromComponent(
    "S-15",
    planSelectionSection
  );
  s15PlanSelectionData.fields.isHidden = isPlanHidden;
  s15PlanSelectionData.fields.errorMessage = STRING_NO_PLAN_ERROR_MESSAGE;
  s15PlanSelectionData.fields.category = {
    value: STRING_SELECTED_PLAN,
  };
  s15PlanSelectionData.fields.onChangeEvent = (value) => {
    setSelectedPlan({ [STRING_SELECTED_PLAN]: value });
    // TODO: in the future if you need planName just add here setSelectedPlanName
  };

  // Bundle Selection Logic
  const bundleSelectionSection = getScDataFromPlaceholder(
    "bundle-selection-section",
    props
  );

  const [h03BundleSelectionData] = getScDataFromComponent(
    "H-03",
    bundleSelectionSection
  );
  h03BundleSelectionData.fields.isHidden = isBundleHidden;

  const [s15BundleSelectionData] = getScDataFromComponent(
    "S-15",
    bundleSelectionSection
  );
  s15BundleSelectionData.fields.isHidden = isBundleHidden;
  s15BundleSelectionData.fields.errorMessage = STRING_NO_PLAN_ERROR_MESSAGE;
  s15BundleSelectionData.fields.category = {
    value: STRING_SELECTED_BUNDLE,
  };
  s15BundleSelectionData.fields.onChangeEvent = (value) => {
    setSelectedBundle({
      [STRING_SELECTED_BUNDLE]: value,
    });
    setIsPlanHidden(false);
    setIsPackageChangeInSession(false);
  };

  // Package Selection Logic
  const pkgeSection = getScDataFromPlaceholder(
    "package-selection-section",
    props
  );
  const [h03PckgeSelectData] = getScDataFromComponent("H-03", pkgeSection);
  h03PckgeSelectData.fields.isHidden = isPackageHidden;

  const [s15PackageSelectionData] = getScDataFromComponent("S-15", pkgeSection);
  s15PackageSelectionData.fields.isHidden = isPackageHidden;
  s15PackageSelectionData.fields.data = retrieveOfferPlanListData
    ? retrieveOfferPlanListData?.offerPlansInfo?.packageInfos?.map((val) => ({
        radioBoxLabel: val["packageBundle"]["Name"],
        explanationText: val["packageBundle"]["CMAP_Description__c"],
        image: val["packageBundle"]["CMAP_PackageImageURL__c"],
        id: val["packageBundle"]["Id"],
      }))
    : [];
  s15PackageSelectionData.fields["category"] = {
    value: STRING_SELECTED_PACKAGE,
  };

  useEffect(() => {
    if (
      orderCategoryCurrentlySelected === STRING_CATEGORY_NEW_CONTRACT ||
      !orderCategoryCurrentlySelected
    ) {
      setExistPlan((prev) => ({ ...prev, isHidden: true }));
      // Note : selectedPlanInfo should have no initial value in settingTestData
      if (typeof selectedPlanInfo?.OfferPackageId !== "undefined") {
        setSelectedPackage({
          [STRING_SELECTED_PACKAGE]: selectedPlanInfo?.OfferPackageId,
        });

        setSelectedBundle({
          [STRING_SELECTED_BUNDLE]: selectedPlanInfo?.OfferBundleId,
        });

        setSelectedPlan({
          [STRING_SELECTED_PLAN]: selectedPlanInfo?.OfferPlan,
        });

        // TODO: in the future if you need planName just add here setSelectedPlanName
      }
    }

    if (
      orderCategoryCurrentlySelected === STRING_CATEGORY_TRANSFER ||
      orderCategoryCurrentlySelected === STRING_CATEGORY_PRODUCT_CHANGE
    ) {
      setExistPlan((prev) => ({ ...prev, isHidden: true }));
      props.setIsPlanSelectionCompleted(true);
      props.setCurrentSection(STRING_CURRENT_SECTION_SELECT_PRODUCT);

      setSelectedPackage({
        [STRING_SELECTED_PACKAGE]:
          orderRelatedInfo?.orderInfo?.order?.CMAP_OfferPackageId__c,
      });

      setSelectedPlan({
        [STRING_SELECTED_PLAN]:
          orderRelatedInfo?.orderInfo?.order?.CMAP_OfferPlan__c,
      });
      // TODO: in the future if you need planName just add here setSelectedPlanName
    }
  }, []);

  useEffect(() => {
    if (
      !existPlan &&
      orderCategoryCurrentlySelected !== STRING_CATEGORY_PLAN_CHANGE &&
      props.currentSection === STRING_CURRENT_SECTION_SELECT_PLAN
    ) {
      props.setBM05Data("disabled");
    }
  }, [existPlan]);

  useEffect(() => {
    if (retrieveOfferPlanListData) {
      triggerMovementWorkflow();
      triggerChangePlanWorkflow();
    }
  }, [retrieveOfferPlanListData]);

  useEffect(() => {
    if (!retrieveOfferPlanListData) return;
    if (selectedPackage.selected) {
      const pckge = retrieveOfferPlanListData?.offerPlansInfo?.packageInfos?.find(
        ({ packageBundle }) =>
          packageBundle.Id === selectedPlanInfo.OfferPackageId
      );

      if (pckge) {
        if (!isPackageHidden) setIsBundleHidden(false);
        s15BundleSelectionData.fields.data = pckge.bundleInfos.map(
          (bundle) => ({
            radioBoxLabel: bundle["CMAP_PackageBundle__c"]["Name"],
            explanationText:
              bundle["CMAP_PackageBundle__c"]["CMAP_Description__c"],
            image: bundle["CMAP_PackageBundle__c"]["CMAP_ImageURL"],
            id: bundle["CMAP_PackageBundle__c"]["Id"],
          })
        );
      }

      if (!selectedBundle.selected || !pckge) return;
      const bundles = pckge.bundleInfos.find(
        (bundle) =>
          bundle.CMAP_PackageBundle__c.Id === selectedPlanInfo.OfferBundleId
      );

      if (bundles) {
        if (!isBundleHidden) setIsPlanHidden(false);
        s15PlanSelectionData.fields.data = bundles.offerPlans.map(
          (planVal) => ({
            radioBoxLabel: planVal["CMAP_OfferPlanNameForDisplay__c"],
            explanationText: planVal["CMAP_Description__c"],
            image: planVal["CMAP_ImageURL"],
            id: planVal["Id"],
          })
        );
      }
    }
  }, [
    selectedPackage,
    selectedBundle,
    retrieveOfferPlanListData,
    isPackageHidden,
    isBundleHidden,
  ]);

  if (isPackageChangeInSession) {
    h03PlanSelectionData.fields.isHidden = true;
    s15PlanSelectionData.fields.isHidden = true;
  }

  s15PackageSelectionData.fields.errorMessage = STRING_NO_PLAN_ERROR_MESSAGE;
  s15PackageSelectionData.fields.onChangeEvent = (value) => {
    setSelectedPackage({
      [STRING_SELECTED_PACKAGE]: value,
    });

    setIsPlanHidden(true);
    setIsBundleHidden(false);
    setIsPackageChangeInSession(true);
  };

  const formButton1 = getScDataFromPlaceholder("form-button-1", props);
  const [b02Data] = getScDataFromComponent("B-02", formButton1);
  b02Data.fields.onClick = () => {
    createSession();
  };

  return (
    <React.Fragment>
      {/* SECTION 1 */}
      <div className="form_wrapper">
        <Placeholder name="form-heading-1" rendering={props.rendering} />

        <div className="form_wrapper-input">
          <Placeholder
            name="plan-availed-section"
            rendering={props.rendering}
          />
        </div>
        {!props.isPlanSelectionCompleted && (
          <>
            <div className="form_wrapper-input">
              <Placeholder
                name="package-selection-section"
                rendering={props.rendering}
              />
            </div>
            <div className="form_wrapper-input">
              <Placeholder
                name="bundle-selection-section"
                rendering={props.rendering}
              />
            </div>
            <div className="form_wrapper-input">
              <Placeholder
                name="plan-selection-section"
                rendering={props.rendering}
              />
            </div>
          </>
        )}
        {props.currentSection === STRING_CURRENT_SECTION_SELECT_PLAN && (
          <div className="btn_container">
            <Placeholder name="form-button-1" rendering={props.rendering} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
