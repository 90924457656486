import React, { useState, useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank, nullChecker } from "../../utils/commonUtils";
import { ApplyButtonContext } from "../../assets/context/ApplyButtonContext";

// @Model

// get the data

// update the context

// @Controller

// @View
const B004 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { requiredMessageText, consentText, isDisabled } = properData;
  const [agreement, setAgreement] = useState(properData?.initialValue?.value);
  const { agreementContext, setAgreementContext } = useContext(
    ApplyButtonContext
  );
  const handleAgreementChange = (evt, myChangeEvent) => {
    setAgreementContext !== undefined
      ? setAgreementContext((prev) => !prev)
      : setAgreement((prev) => !prev);
    if (myChangeEvent) return myChangeEvent();
  };

  return (
    <React.Fragment>
      <div className="btn_wrapper consent B-04">
        <button
          type="button"
          className={isDisabled === true ? "disabled" : ""}
          onChange={(e) => handleAgreementChange(e, properData?.onChangeEvent)}
        >
          {/* className="disabled" */}
          <div className="input-container">
            {requiredMessageText?.value && (
              <span className="required">
                <Text field={fillBlank(requiredMessageText)} />
              </span>
            )}
            <input
              type="checkbox"
              name="consent1"
              value={
                agreementContext !== undefined ? agreementContext : agreement
              }
              defaultChecked={
                agreementContext !== undefined ? agreementContext : agreement
              }
              readOnly
            />
            <label htmlFor="consent1">
              <Text field={fillBlank(consentText)} />
            </label>
          </div>
        </button>
      </div>
    </React.Fragment>
  );
};

export default B004;
