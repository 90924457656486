import React, { useEffect, useState } from "react";
import {
  Placeholder,
  Text,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { useGetDataViaGraphQL } from "../../hooks/useGetData";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import {
  fillBlank,
  sortKeyValuePair,
  getScDataFromPlaceholder,
  getScDataFromComponent,
  formatCurrency,
  redirectToLinkUrl,
  getProperData,
} from "../../utils/commonUtils";
import {
  HEARING_INFO_TYPE,
  STRING_LOADING,
} from "../../utils/constantVariables";
import { di_register } from "../../utils/di";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import { query as hearingBasicProductMasterQuery } from "../../assets/graphql/HearingBasicProductMaster";
import { query as otherProvidersMasterQuery } from "../../assets/graphql/OtherProvidersMaster";
import { query as hearingOptionMasterQuery } from "../../assets/graphql/HearingOptionMaster";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { validationFuncs } from "../../utils/validations";
import { getErrorMessage } from "../../utils/errorMessageHandler";
import { useFormContext } from "react-hook-form";

const CPadHearingPageLayout = (props) => {
  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();
  const { setError, clearErrors } = useFormContext();

  // get the data
  const hearingInfo = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADHEARING_HEARINGINFO,
    true
  );
  const otherProviderID = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADHEARING_SELECTEDPROVIDERID,
    true
  );
  const otherProviderName = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADHEARING_SELECTEDPROVIDERNAME,
    true
  );

  // @model
  const sitecoreContext = props?.sitecoreContext;
  const pageContextField = sitecoreContext?.route?.fields;
  di_register("thisPageSitecoreContext", sitecoreContext);
  const optionHearingButton = getScDataFromPlaceholder(
    "option-hearing-button-section",
    props
  );
  const discountHearingButton = getScDataFromPlaceholder(
    "discount-hearing-button-section",
    props
  );
  const [addOptionButton] = getScDataFromComponent("B-02", optionHearingButton);
  const [backButton] = getScDataFromComponent("B-02", discountHearingButton);
  const [nextButton] = getScDataFromComponent("B-01", discountHearingButton);

  const serviceList = hearingInfo?.BasicPrice?.ServiceList;
  const optionList = hearingInfo?.Option?.OptionList;
  const otherOptionList = hearingInfo?.Option?.OtherOptionList;

  const [basicPriceHearingListState, setBasicPriceHearingListState] = useState(
    []
  );
  const [totalBasicPriceState, setTotalBasicPriceState] = useState(0);
  const [optionHearingListState, setOptionHearingListState] = useState([]);
  const [otherOptionListState, setOtherOptionListState] = useState([
    { id: 0, OptionName: "", OptionPrice: "" },
  ]);
  const [discountState, setDiscountState] = useState(0);

  const hearingBasicProductMaster = useGetDataViaGraphQL(
    hearingBasicProductMasterQuery,
    null,
    "HearingBasicProductMaster"
  );
  const hearingOptionMaster = useGetDataViaGraphQL(
    hearingOptionMasterQuery,
    null,
    "HearingOptionMaster"
  );
  const otherProvidersMaster = useGetDataViaGraphQL(otherProvidersMasterQuery);
  const [inputValues, setInputValues] = useState({
    otherProviderHearingId: otherProviderID,
    otherProviderHearingName: otherProviderName,
    otherProviderHearingPrice: !isNaN(
      hearingInfo?.OtherProvider?.OtherProviderPrice
    )
      ? Number(hearingInfo?.OtherProvider?.OtherProviderPrice)
      : 0,
  });

  const isLoading =
    hearingBasicProductMaster === STRING_LOADING ||
    hearingOptionMaster === STRING_LOADING ||
    otherProvidersMaster === STRING_LOADING;
  
    props.setIsLoading(isLoading)

  // update context
  const getValue = (array) => {
    const keyValuePair = array?.map((item) => {
      return {
        key: item.fields.find((x) => x.name == "Code").value,
        value: item.fields.find((x) => x.name == "Value").value,
      };
    });

    return sortKeyValuePair(keyValuePair)?.map((x) => x.value);
  };

  useEffect(() => {
    if (hearingInfo?.Discount) setDiscountState(hearingInfo?.Discount);
    let newOtherOptionListOtherOption = [];
    if (otherOptionList && otherOptionList.length <= 12) {
      newOtherOptionListOtherOption = [{ OptionName: "", OptionPrice: "" }];
    }

    if (otherOptionList && otherOptionList.length > 0) {
      const newOtherOptionList = [
        ...otherOptionList,
        ...newOtherOptionListOtherOption,
      ];
      const otherOptions = newOtherOptionList.map((item, index) => {
        return {
          id: index,
          OptionName: item.OptionName,
          OptionPrice: isNaN(item.OptionPrice) ? 0 : Number(item.OptionPrice),
        };
      });
      setOtherOptionListState(otherOptions);
    }
  }, []);

  useEffect(() => {
    const basicPriceHearinglabelList = getValue(
      hearingBasicProductMaster?.HearingBasicProductMaster?.children
    );

    if (basicPriceHearinglabelList && basicPriceHearinglabelList.length > 0) {
      const newBasicPriceHearingList = basicPriceHearinglabelList.map(
        (item) => {
          const price = serviceList?.find((x) => x.ServiceName == item)
            ?.ServicePrice;

          return { ServiceName: item, ServicePrice: Number(price) ?? 0 };
        }
      );
      setBasicPriceHearingListState(newBasicPriceHearingList);
      setTotalBasicPriceState(hearingInfo?.BasicPrice?.TotalPrice ?? 0);
    }
  }, [hearingBasicProductMaster]);

  useEffect(() => {
    const optionHearinglabelList = getValue(
      hearingOptionMaster?.HearingOptionMaster?.children
    );

    if (optionHearinglabelList && optionHearinglabelList.length > 0) {
      const newOptionHearingList = optionHearinglabelList.map((item) => {
        const price = optionList?.find((x) => x?.OptionName == item)
          ?.OptionPrice;

        return { OptionName: item, OptionPrice: Number(price) ?? 0 };
      });
      setOptionHearingListState(newOptionHearingList);
    }
  }, [hearingOptionMaster]);

  const otherProviderHearingSection = getScDataFromPlaceholder(
    "other-provider-hearing-section",
    props
  );
  const [otherProviderDropDown] = getScDataFromComponent(
    "M-01",
    otherProviderHearingSection
  );
  useEffect(() => {
    if (otherProvidersMaster) {
      const otherProvidersChildrenList = otherProvidersMaster?.OtherProvidersMaster?.children.map(
        (item) => {
          if (item.fields) {
            let select = item.fields.reduce((acc, field) => {
              acc[field.name] = field.value;
              return acc;
            }, {});
            return select;
          }
          return item;
        }
      );
      const otherProvidersList = otherProvidersChildrenList
        ?.sort((a, b) => (a.Code > b.Code ? 1 : -1))
        .map((item) => {
          return { name: item.Value, value: item.Code };
        });
      otherProviderDropDown.fields.pulldownData = otherProvidersList;
      otherProviderDropDown.fields.formName = "otherProviderList";
      otherProviderDropDown.fields.initialValue = {
        value: inputValues.otherProviderHearingId,
      };
      otherProviderDropDown.fields.onChangeEvent = (evt) => {
        const selectedId = evt.target.value;
        const selectedName = otherProviderDropDown.fields.pulldownData.find(
          ({ value }) => value === selectedId
        )?.name;
        setInputValues((prevState) => {
          return {
            ...prevState,
            otherProviderHearingName: selectedName,
            otherProviderHearingId: selectedId,
          };
        });
      };
    }
  }, [otherProvidersMaster]);

  const handleBasicPriceOnChange = (name, value) => {
    const newValue = value.replace(/,/g, "");
    const isNumber = !isNaN(newValue);
    if (isNumber) {
      setBasicPriceHearingListState(
        basicPriceHearingListState.map((item) => {
          return item.ServiceName == name
            ? { ...item, ServicePrice: Number(newValue) }
            : item;
        })
      );
    }
  };

  const handleBasicPriceOnBlur = (e) => {
    let total = 0;
    basicPriceHearingListState.map((item) => {
      total = total + Number(item.ServicePrice);
    });
    setTotalBasicPriceState(total);
  };

  const handleOtherOptionPriceOnChange = (id, value) => {
    const newValue = value.replace(/,/g, "");
    const isNumber = !isNaN(newValue);
    if (isNumber) {
      setOtherOptionListState(
        otherOptionListState.map((item) => {
          return item.id == id
            ? { ...item, OptionPrice: Number(newValue) }
            : item;
        })
      );
    }
  };
  const handleOtherProviderHearingPriceOnChange = (event) => {
    const newValue = event.target.value.replace(/,/g, "");
    const name = event.target.name;

    const isNumber = !isNaN(newValue);
    if (isNumber) {
      setInputValues({ ...inputValues, [name]: Number(newValue) });
    }
  };

  const handleOtherOptionNameOnChange = (id, value) => {
    setOtherOptionListState(
      otherOptionListState.map((item) => {
        return item.id == id ? { ...item, OptionName: value } : item;
      })
    );
  };

  const handleOptionPriceOnChange = (name, value) => {
    const newValue = value.replace(/,/g, "");
    const isNumber = !isNaN(newValue);
    if (isNumber) {
      setOptionHearingListState(
        optionHearingListState.map((item) => {
          return item.OptionName == name
            ? { ...item, OptionPrice: Number(newValue) }
            : item;
        })
      );
    }
  };

  const handleDiscountOnChange = (e) => {
    const newValue = e.target.value.replace(/,/g, "");
    const isNumber = !isNaN(newValue);
    if (isNumber) {
      setDiscountState(Number(newValue));
    }
  };

  addOptionButton.fields.onClickEvent = () => {
    setOtherOptionListState([
      ...otherOptionListState,
      { id: otherOptionListState.length, OptionName: "", OptionPrice: 0 },
    ]);
  };

  const setHearingInfo = () => {
    const serviceList = basicPriceHearingListState.filter(
      (x) => x.ServicePrice > 0
    );
    const optionHearingList = optionHearingListState?.filter(
      (x) => x.OptionPrice > 0
    );
    const otherOptionHearingList = otherOptionListState
      .map((item) => {
        return { OptionName: item.OptionName, OptionPrice: item.OptionPrice };
      })
      .filter((x) => x.OptionName !== "");

    const hearingInfo = {
      BasicPrice: {
        TotalPrice: totalBasicPriceState > 0 ? totalBasicPriceState : "",
        ServiceList: serviceList,
      },
      Option: {
        OptionList: optionHearingList,
        OtherOptionList: otherOptionHearingList,
      },
      Discount: discountState > 0 ? discountState : "",
      OtherProvider: {
        OtherProviderPrice: Number(inputValues.otherProviderHearingPrice),
      },
    };

    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADHEARING_HEARINGINFO,
      hearingInfo,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADHEARING_SELECTEDPROVIDERID,
      inputValues.otherProviderHearingId,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADHEARING_SELECTEDPROVIDERNAME,
      inputValues.otherProviderHearingName,
      true
    );
  };

  backButton.fields.onClickEvent = () => {
    setHearingInfo();
    redirectToPreviousPage();
  };

  const findErrorInArray = (arr, field) => {
    let result = false;

    for (let i = 0; i < arr.length; i++) {
      let price = "";
      if (field === HEARING_INFO_TYPE.SERVICE_PRICE) {
        price = arr[i].ServicePrice;
      }
      if (field === HEARING_INFO_TYPE.OPTION_PRICE) {
        price = arr[i].OptionPrice;
      }

      if (!validationFuncs.validateIsNumber(price)) {
        result = true;
        break;
      }
    }
    return result;
  };

  nextButton.fields.onClickEvent = () => {
    clearErrors();
    let isValidate = true;
    const basicPriceHearingHasError = findErrorInArray(
      basicPriceHearingListState,
      HEARING_INFO_TYPE.SERVICE_PRICE
    );
    const optionHearingHasError = findErrorInArray(
      optionHearingListState,
      HEARING_INFO_TYPE.OPTION_PRICE
    );
    const otherOptionHasError = findErrorInArray(
      otherOptionListState,
      HEARING_INFO_TYPE.OPTION_PRICE
    );
    if (!validationFuncs.validateIsNumber(discountState)) {
      setError("Discount", {
        type: "manual",
        message: getErrorMessage("E0003", ["割引額"]),
      });
      isValidate = false;
    }

    if (
      !validationFuncs.validateIsNumber(inputValues.otherProviderHearingPrice)
    ) {
      setError("OtherProviderPrice", {
        type: "manual",
        message: getErrorMessage("E0003", ["他社プロバイダー金額"]),
      });
      isValidate = false;
    }
    if (basicPriceHearingHasError) {
      setError("BasicProductPrice", {
        type: "manual",
        message: getErrorMessage("E0003", ["基本商品金額"]),
      });
      isValidate = false;
    }
    if (optionHearingHasError) {
      setError("OptionPrice", {
        type: "manual",
        message: getErrorMessage("E0003", ["オプション金額"]),
      });
      isValidate = false;
    }
    if (otherOptionHasError) {
      setError("OtherOptionPrice", {
        type: "manual",
        message: getErrorMessage("E0003", ["その他オプション金額"]),
      });
      isValidate = false;
    }
    if (isValidate) {
      setHearingInfo();
      const { linkURL } = getProperData(sitecoreContext, nextButton?.fields);
      redirectToLinkUrl(linkURL);
    }
  };

  // @view
  return (
    <React.Fragment>
      <main>
        <div className="form_container cpad_wrapper2">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <Placeholder
                name="basic-price-hearing-heading-section"
                rendering={props.rendering}
              />

              {/* =========== Basic Price Hearing ======Start===== */}
              <div className="form_wrapper-input">
                <div className="multiple-column-input two side-label-input">
                  {basicPriceHearingListState &&
                    basicPriceHearingListState.length > 0 &&
                    basicPriceHearingListState.map(
                      (basicPrice, basicPriceIndex) => (
                        <div className="input-box I-01" key={basicPriceIndex}>
                          <div className="input-form">
                            <div className="input-container">
                              <Text
                                field={fillBlank(basicPrice?.ServiceName)}
                                tag="label"
                              />
                              <div className="currency-sign-input-container">
                                <span>
                                  {
                                    pageContextField[
                                      "BasicPriceHearing-CurrencyUnit"
                                    ]?.value
                                  }
                                </span>
                                <input
                                  className="large"
                                  type="text"
                                  value={formatCurrency(
                                    basicPriceHearingListState.find(
                                      (x) =>
                                        x.ServiceName == basicPrice.ServiceName
                                    )?.ServicePrice
                                  )}
                                  onChange={(e) =>
                                    handleBasicPriceOnChange(
                                      basicPrice.ServiceName,
                                      e.target.value
                                    )
                                  }
                                  onBlur={handleBasicPriceOnBlur}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  <div className="input-box I-01">
                    <div className="input-form">
                      <div className="input-container">
                        <Text
                          field={fillBlank(
                            pageContextField[
                              "BasicPriceHearing-TotalDisplayName"
                            ]?.value
                          )}
                          tag="label"
                        />
                        <div className="currency-sign-input-container">
                          <span>
                            {
                              pageContextField["BasicPriceHearing-CurrencyUnit"]
                                ?.value
                            }
                          </span>
                          <input
                            className="large"
                            type="text"
                            value={formatCurrency(totalBasicPriceState)}
                            readOnly
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* =========== Basic Price Hearing ======End===== */}

              {/* =========== Option Hearing ======Start===== */}
              <div className="form_wrapper-input">
                <Placeholder
                  name="option-hearing-heading-section"
                  rendering={props.rendering}
                />
                <div className="multiple-column-input two side-label-input">
                  {optionHearingListState &&
                    optionHearingListState.map((option, optionIndex) => (
                      <div className="input-box I-01" key={optionIndex}>
                        <div className="input-form">
                          <div className="input-container">
                            <Text
                              field={fillBlank(option.OptionName)}
                              tag="label"
                            />
                            <div className="currency-sign-input-container">
                              <span>
                                {
                                  pageContextField["OptionHearing-CurrencyUnit"]
                                    ?.value
                                }
                              </span>
                              <input
                                className="large"
                                type="text"
                                value={formatCurrency(option.OptionPrice)}
                                onChange={(e) =>
                                  handleOptionPriceOnChange(
                                    option.OptionName,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                {/* ===========Other Option Hearing ======Start===== */}
                <div className="one-column-input side-label-input">
                  <div className="input-box I-01">
                    <div className="input-form hearing-other-option-section">
                      {otherOptionListState.map(
                        (otherOption, otherOptionIndex) => {
                          return (
                            <div
                              className="input-container"
                              key={otherOptionIndex}
                            >
                              {otherOptionIndex == 0 ? (
                                <Text
                                  field={fillBlank(
                                    pageContextField[
                                      "OptionHearing-OtherOption"
                                    ]?.value
                                  )}
                                  tag="label"
                                />
                              ) : (
                                <label></label>
                              )}

                              <input
                                className="x-large"
                                type="text"
                                placeholder={
                                  pageContextField[
                                    "OptionHearing-OtherOptionNamePlaceholder"
                                  ]?.value
                                }
                                value={otherOption.OptionName}
                                onChange={(e) =>
                                  handleOtherOptionNameOnChange(
                                    otherOption.id,
                                    e.target.value
                                  )
                                }
                              />
                              <div className="currency-sign-input-container">
                                <span>
                                  {
                                    pageContextField[
                                      "OptionHearing-CurrencyUnit"
                                    ]?.value
                                  }
                                </span>
                                <input
                                  className="large"
                                  type="text"
                                  value={formatCurrency(
                                    otherOption.OptionPrice
                                  )}
                                  onChange={(e) =>
                                    handleOtherOptionPriceOnChange(
                                      otherOption.id,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <Placeholder
                    name="option-hearing-button-section"
                    rendering={props.rendering}
                  />
                </div>
                {/* ===========Other Option Hearing ======End===== */}
              </div>
              {/* =========== Option Hearing ======End===== */}

              {/* =========== Discount Hearing ======Start===== */}
              <div className="form_wrapper-input">
                <Placeholder
                  name="discount-hearing-heading-section"
                  rendering={props.rendering}
                />
                <div className="multiple-column-input text-right">
                  <div className="input-box I-01">
                    <div className="input-form">
                      <div className="input-container">
                        <div className="currency-sign-input-container">
                          <span>
                            {
                              pageContextField["DiscountHearing-CurrencyUnit"]
                                ?.value
                            }
                          </span>
                          <input
                            className="large"
                            type="text"
                            value={formatCurrency(discountState)}
                            onChange={handleDiscountOnChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* =========== Discount Hearing ======End===== */}

              <div
                className="form_wrapper-input"
                name="other-provider-hearing-section"
              >
                <Placeholder
                  name="other-provider-hearing-section"
                  rendering={props.rendering}
                />
                <div className="one-column-input side-label-input inline-block">
                  <div className="input-box I-01">
                    <div className="input-form ml-0">
                      <div className="input-container">
                        <div className="currency-sign-input-container">
                          <span>
                            {
                              pageContextField[
                                "OtherProviderHearing-CurrencyUnit"
                              ]?.value
                            }
                          </span>
                          <input
                            className="large"
                            type="text"
                            value={formatCurrency(
                              inputValues.otherProviderHearingPrice
                            )}
                            name="otherProviderHearingPrice"
                            onChange={(e) =>
                              handleOtherProviderHearingPriceOnChange(e)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="btn_container">
                <Placeholder
                  name="discount-hearing-button-section"
                  rendering={props.rendering}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(CPadHearingPageLayout);
