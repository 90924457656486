import { atom, atomFamily, selectorFamily, useRecoilValue, DefaultValue, useSetRecoilState, useRecoilCallback, useRecoilState  } from 'recoil';
import { recoilKeys } from '../assets/recoilKeys';
import { getSessionStorageItem, setSessionStorageItem, removeSessionStorageItem } from '../utils/useSessionStorage';

// useSessionStorage
const sessionStorageEffect = (key) => ({ setSelf, onSet }) => {

  // get data for session storage
  const savedValue = getSessionStorageItem(key);
  if (savedValue != null) {
    setSelf(savedValue);
  }

  // set data to session storage if the val is changed.
  onSet((newValue) => {
    if (newValue instanceof DefaultValue) {
      removeSessionStorageItem(key);
    } else {
      setSessionStorageItem(key, JSON.stringify(newValue));
    }
  });
};

//SELECT ITEMS
// Model
export const selectedItemsAtom = atomFamily({
  key: recoilKeys.ATM_SELECTED_CONTRACTED,
  default: null,
  effects: (itemname) => [sessionStorageEffect(itemname)],
});

//controller
const selectedItemsSelector = selectorFamily({
  key: recoilKeys.SLT_SELECTED_CONTRACTED,
  get: (itemname) => ({ get }) => {
    let valDict = get(selectedItemsAtom(itemname));
    let val = valDict[itemname];
    return { selected: val, selectedItems: [] };
  },
  set: (itemname) => ({ get, set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(selectedItemsAtom(itemname));
      return;
    }

    set(selectedItemsAtom(itemname), newValue);
    return;
  },
});

const selectedItemsSelectorCheckBox = selectorFamily({
  key: recoilKeys.SLT_SELECTED_CONTRACTED_CHECKS,
  get: (itemname) => ({ get }) => {
    let valDict = get(selectedItemsAtom(itemname));
    let vals = [];//Object.keys(valDict);
    for (let i in valDict){
      let val = valDict[i];
      vals.push(val.name);
    }
    return { selected: vals, selectedItems: [valDict] };
  },
  set: (itemname) => ({ get, set, reset }, newValueDict) => {
    let isRemove = newValueDict.isRemove;
    let newValue = newValueDict.item;
    if (newValue instanceof DefaultValue) {
      reset(selectedItemsAtom(itemname));
      return;
    }

    let oldValue = get(selectedItemsAtom(itemname));
    //let oldValue = oldValueDict.selectedItems;
    if (!oldValue) {
      oldValue = [];
    }
    let addValue = [];
      if (isRemove) {
          addValue = oldValue.filter((val) => { return val.name !== newValue.name});
    } else {
      addValue = [...oldValue, newValue];
      //addValue.push(newValue);
    }

    //set(selectedItemsAtom(itemname), newValue);
    set(selectedItemsAtom(itemname), addValue);
    return;
  },
});

// SELECT PLAN ITEMS COMPONENT NAME
const selectItemsComponentNames = atom({
  key: recoilKeys.ATM_SELECTED_PLAN_COMPONENT_NAME,
  default: { names: [] },
});

const selectItemsComponentsNamesSelector = selectorFamily({
  key: recoilKeys.SLT_SELECTED_PLAN_COMPONENT_NAME,
  get: (targetnames) => ({ get }) => {
    let return_dict = {};
    targetnames.forEach((itemname) => {
      let val = get(selectedItemsSelector(itemname));
      return_dict[itemname] = val.selected;
    });
    return return_dict;
  },
});

export const useSetSelectedItem = (itemname) => {
  const getter = useRecoilValue(selectedItemsSelector(itemname));
  const setter = useSetRecoilState(selectedItemsSelector(itemname));
  return [getter, setter];
};

export const useSelectedItems = (targetnames) => {
  const dict = useRecoilValue(selectItemsComponentsNamesSelector(targetnames));
  return [dict];
};

// for checkbox
export const useSetSelectedItems = (itemname) => {
  const getter = useRecoilValue(selectedItemsSelectorCheckBox(itemname));
  const setter = useSetRecoilState(selectedItemsSelectorCheckBox(itemname));
  return [getter, setter];
};


//save/get value for session storage via recoil]
const mySessionStorageEffect = (vals) => ({ node, setSelf, onSet }) => {
  if (vals===undefined) return [];
    let key = vals[0];
    let isNotEncrypt = false;
    if(vals.length>= 2){
      isNotEncrypt = vals[1]
    } 
  
  // get data for session storage
  const savedValue = getSessionStorageItem(key, isNotEncrypt);

  if (savedValue != null) {
    
    setSelf(savedValue);
  }

  // set data to session storage if the val is changed.
  onSet((newValue) => {

    if (newValue instanceof DefaultValue) {
      removeSessionStorageItem(key);
    } else {
      
      setSessionStorageItem(key, JSON.stringify(newValue), isNotEncrypt);
    }
  });
};

const mySessionStorageItemsAtom = atomFamily({
  key: recoilKeys.ATM_APPLICATION_SESSIONSTORAGE,
  default: null,
  effects: ([itemname, isNotEncrypt=false]) => [mySessionStorageEffect([itemname, isNotEncrypt])],
});


//controller
const mySessionStorageItemSelector = selectorFamily({
  key: recoilKeys.SLT_APPLICATION_SESSIONSTORAGE,
  get: (vals) => ({ get } ) => {
    if (vals===undefined) return [];
    let itemname = vals[0];
    let isNotEncrypt = false;
    if(vals.length >= 2){
      isNotEncrypt = vals[1]
    } 
    let valDict = get(mySessionStorageItemsAtom([itemname, isNotEncrypt]));
    return valDict;
   
    //let val = valDict[itemname];
    //return { selected: val, selectedItems: [] };
  },
  set: () => ({ get, set, reset }, [itemname, newValue, isNotEncrypt=false]) => {
    if (newValue instanceof DefaultValue) {
      reset(mySessionStorageItemsAtom([itemname, isNotEncrypt]));
      return;
    }

    let nowVal = get(mySessionStorageItemSelector([itemname, isNotEncrypt]));
    let comBeforeVal = JSON.stringify(nowVal)
    let compAfterVal = JSON.stringify(newValue)

    if (comBeforeVal != compAfterVal) {
      set(mySessionStorageItemsAtom([itemname, isNotEncrypt]), newValue);
    }
    return;
  },
});


export const useSetItems = () => {
  const useGetter = (vals) => {
    return useRecoilValue(mySessionStorageItemSelector(vals));
  };
  const useSetter = useSetRecoilState(mySessionStorageItemSelector());
  return [useGetter, useSetter];
}



//inputとButtonなど項目連携が必要なもの用
const inputValueStateAtom = atomFamily({
  key: "mycommufaApp/forinputvalues",
  default: (v) => v[1]
})

export const useInputValueState = (key, initVal={}) => {
  return useRecoilState(inputValueStateAtom([key, initVal]));
}