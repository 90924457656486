import React, { useEffect, useState } from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { di_register } from "../../utils/di";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  getProperData,
  redirectToLinkUrl,
  fullWidthSpace,
  halfWidthSpace,
  yyMMFormat,
  creditCardBrand,
  getUrlInfo,
} from "../../utils/commonUtils";
import { useSetSelectedItem } from "../../hooks/useSelectedState";
import { validationFuncs } from "../../utils/validations";
import { getErrorMessage } from "../../utils/errorMessageHandler";
import { useFormContext } from "react-hook-form";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";

import {
  useCustomQuery,
  useGetDataViaGraphQL as UseGetDataViaGraphQL,
} from "../../hooks/useGetData";
import {
  apiBaseURL,
  cpadPageURL,
  jQueryScriptURL,
  tokenScriptURL,
} from "../../envSettings";
import {
  STRING_CREDIT_CARD,
  STRING_GET,
  STRING_BANK_ACCOUNT_TRANSFER,
  STRING_APPLY,
  STRING_SIMULATOR,
  STRING_POST,
  STRING_LOADING,
} from "../../utils/constantVariables";
import { query as queryPaymentMethodChange } from "../../assets/graphql/PaymentMethodChangeTypeMaster";
import { query as queryBankRelationshipType } from "../../assets/graphql/BankRelationshipType";

if (typeof document !== "undefined") {
  const urls = [jQueryScriptURL, tokenScriptURL];

  urls.forEach((url) => {
    const tokenScript = document.createElement("script");
    tokenScript.src = url;
    tokenScript.async = true;
    document.head.appendChild(tokenScript);
  }, []);
}

const PaymentMethodSelection = (props) => {
  const { paymentMethodChangeVisibility, paymentMethodVisibility } = props;
  return (
    <>
      {paymentMethodChangeVisibility && (
        <SelectPaymentMethod rendering={props.rendering} />
      )}
      {paymentMethodVisibility && (
        <div className="form_wrapper-input" name="payment-method-section">
          <Placeholder
            name="payment-method-section"
            rendering={props.rendering}
          />
        </div>
      )}
      <BankTransferPaymentMethod rendering={props.rendering} />
    </>
  );
};

const RegisterCreditCard = (props) => {
  return (
    <>
      <div className="form_wrapper-input" name="credit-information-section">
        <Placeholder
          name="credit-information-section"
          rendering={props.rendering}
        />
      </div>
    </>
  );
};

const SelectPaymentMethod = (props) => {
  return (
    <div className="form_wrapper-input" name="preferred-payment-section">
      <Placeholder
        name="preferred-payment-section"
        rendering={props.rendering}
      />
    </div>
  );
};

const BankTransferPaymentMethod = (props) => {
  return (
    <div className="form_wrapper-input" name="bank-account-section">
      <Placeholder name="bank-account-section" rendering={props.rendering} />
    </div>
  );
};

//@model
const BillingInformationLayout = (props) => {
  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  // get ss
  const paymentMethodCode = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_INPUTBILLINGINFO_PAYMENTMETHODCODE,
    true
  );
  
  const orderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );
  let orderRelatedInfoData = orderRelatedInfo;
  const paymentMethodChangeCode = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_INPUTBILLINGINFO_PAYMENTMETHODCHANGECODE,
    true
  );

  const { setError, clearErrors } = useFormContext();
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  const [isCpad, setIsCpad] = useState(false);
  const [isApply, setIsApply] = useState(false);
  const [isSimulator, setIsSimulator] = useState(false);

  const [billingId, setBillingID] = useState("");

  useEffect(() => {
    // set url info
    const urlInfo = getUrlInfo();

    const isCpadUrl = urlInfo?.origin === cpadPageURL;
    const isApplyPath = urlInfo?.pathnames[1]?.toLocaleLowerCase() === STRING_APPLY;
    const isSimulatorPath = urlInfo?.pathnames[1]?.toLocaleLowerCase() === STRING_SIMULATOR;
    setIsCpad(isCpadUrl);
    setIsApply(isApplyPath);
    setIsSimulator(isSimulatorPath);

    // call api
    if (!isApplyPath && !isSimulatorPath)
      billToInfoListRefresh();
  }, []);

  const [inputValues, setInputValues] = useState({
    selectedCreditCardRelationship: "",
    selectedPaymentMethodRadioButton: "",
    selectedPaymentMethodChangeRadioButton: "",
    cardNumber: "",
    cardName: "",
    expirationDate: "",
    securityCode: "",
  });

  const [registerPaymentMethod, setRegisterPaymentMethod] = useState("");
  const [
    paymentMethodChangeOption1Hide,
    setPaymentMethodChangeOption1Hide,
  ] = useState(false);
  const [
    paymentMethodChangeVisibility,
    setPaymentMethodChangeVisibility,
  ] = useState(true);
  const [paymentMethodVisibility, setPaymentMethodVisibility] = useState(true);
  const [
    registerCreditCardVisibility,
    setRegisterCreditCardVisibility,
  ] = useState(true);
  const paymentMethodSection = getScDataFromPlaceholder(
    "payment-method-section",
    props
  );
  const bankAccountSection = getScDataFromPlaceholder(
    "bank-account-section",
    props
  );

  const [s01PaymentMethod] = getScDataFromComponent(
    "S-01",
    paymentMethodSection
  );

  const [d01PaymentMethod] = getScDataFromComponent("D-01", bankAccountSection);

  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const selectPaymentMethodCategory = "selectPaymentMethod";
  s01PaymentMethod.fields["selections"] = paymentMethodOptions;
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useSetSelectedItem(
    selectPaymentMethodCategory
  );
  s01PaymentMethod.fields["category"] = {
    value: selectPaymentMethodCategory,
  };

  const preferredPaymentMethodCategory = "preferredPaymentMethod";
  const [
    preferredPaymentMethod,
    setPreferredPaymentMethod,
  ] = useSetSelectedItem(preferredPaymentMethodCategory);

  const [h02PaymentMethod] = getScDataFromComponent(
    "H-02",
    paymentMethodSection
  );
  const paymentMethodTitle =
    props?.sitecoreContext?.route?.fields?.[
      "PaymentMethod-AddPaymentMethodTitle"
    ]?.value;
  const paymentMethodChangeTitle =
    props?.sitecoreContext?.route?.fields?.["PaymentMethodChange-Title"]?.value;

  const personalizeInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );
  const orderChannelCategoryMeta = personalizeInfo?.AppChannelDivision || "Web";
  const orderChannelCategoryBilling = personalizeInfo?.AppChannelDivision;

  const retrieveMetadataRequest = {
    objectNames: ["CMAP_BillTo__c"],
    orderChannelCategory: orderChannelCategoryMeta,
  };
  const retrieveMetadataURI = `${apiBaseURL}order/RetrieveMetadataDefinitions`;
  const {
    data: retrieveMetadataData,
    isLoading: retrieveMetadataLoading,
    error: retrieveMetadataError,
    refetch: retrieveMetadataRefresh,
    remove: retrieveMetadataRemove,
  } = useCustomQuery(
    retrieveMetadataURI,
    true,
    STRING_POST,
    retrieveMetadataRequest
  );

  useEffect(() => {
    let validScreen = "";
    if (isCpad) {
      validScreen = isApply;
    } else {
      validScreen = isApply || isSimulator;
    }
    onloadVisibilitySection(validScreen);
  }, [isCpad, isApply, isSimulator]);

  const onloadVisibilitySection = (valid) => {
    if (valid) {
      setPaymentMethodChangeVisibility(false);
      setPaymentMethodVisibility(true);
      if (inputValues.selectedPaymentMethodRadioButton === "") {
        d01PaymentMethod.fields.isHidden = {
          value: true,
        };
        setRegisterCreditCardVisibility(false);
      }
    } else {
      setPaymentMethodChangeVisibility(true);
      setPaymentMethodVisibility(false);
      if (inputValues.selectedPaymentMethodChangeRadioButton === "") {
        setPaymentMethodVisibility(false);
        setRegisterCreditCardVisibility(false);
        d01PaymentMethod.fields.isHidden = {
          value: true,
        };
      }
    }
  };

  useEffect(() => {
    if (retrieveMetadataData && retrieveMetadataData["metadataDefinitions"]) {
      if (retrieveMetadataData["metadataDefinitions"].length !== 0) {
        const CMAP_PaymentMethod__c = retrieveMetadataData[
          "metadataDefinitions"
        ]?.CMAP_BillTo__c?.fieldMetadataDefinitions?.CMAP_PaymentMethod__c?.fieldMetadata?.fieldMetadataExtension?.CMAP_Option__c.split(
          ","
        );
        if (CMAP_PaymentMethod__c?.length !== 0) {
          const paymentMethodOption = CMAP_PaymentMethod__c?.map((value, i) => {
            const item = value.split(":");
            const itemArr = { Code: item[0], Value: item[1] };
            return itemArr;
          });

          setPaymentMethodOptions(paymentMethodOption);

          h02PaymentMethod.fields["titleText"] = {
            value: paymentMethodChangeTitle,
          };
          if (paymentMethodOption) {
            const isPaymentMethodOptionExist = paymentMethodOption.find(
              (item) => {
                return item.Code === paymentMethodCode;
              }
            );
            if (typeof isPaymentMethodOptionExist !== "undefined") {
              setSelectedPaymentMethod({
                [selectPaymentMethodCategory]: isPaymentMethodOptionExist.Code,
              });
              setInputValues((prevState) => {
                return {
                  ...prevState,
                  selectedPaymentMethodRadioButton:
                    isPaymentMethodOptionExist.Code,
                };
              });
              paymentMethodBehavior(isPaymentMethodOptionExist.Code);
            } else {
              setSelectedPaymentMethod({
                [selectPaymentMethodCategory]: "",
              });
            }
          }
        }
      }
    }
  }, [retrieveMetadataData]);
  s01PaymentMethod.fields.onChangeEvent = (value) => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        selectedPaymentMethodRadioButton: value,
      };
    });
    paymentMethodBehavior(value);
  };

  const billToInfoListURI = `${apiBaseURL}order/RetrieveBillToInfoList?customerId=${orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account?.Id}&orderChannelCategory=${orderChannelCategoryBilling}`;
  const {
    data: billToInfoListData,
    isLoading: billToInfoListIsLoading,
    error: billToInfoListError,
    refetch: billToInfoListRefresh,
    remove: billToInfoListRemove,
  } = useCustomQuery(billToInfoListURI, false, STRING_GET);

  const paymentMethodChangeQuery = UseGetDataViaGraphQL(
    queryPaymentMethodChange,
    null,
    "PaymentMethodChangeTypeMaster"
  );

  const preferredPaymentSection = getScDataFromPlaceholder(
    "preferred-payment-section",
    props
  );
  const [s02PreferredPayment] = getScDataFromComponent(
    "S-02",
    preferredPaymentSection
  );
  const [preferredPaymentMethodOptions, setPreferredPaymentMethodOptions] = useState([]);
  s02PreferredPayment.fields["category"] = { value: preferredPaymentMethodCategory };
  s02PreferredPayment.fields["selections"] = preferredPaymentMethodOptions;

  const [h02PreferredPayment] = getScDataFromComponent(
    "H-02",
    preferredPaymentSection
  );

  useEffect(() => {
    if (billToInfoListData && billToInfoListData?.billings.length > 0) {
      const billings = billToInfoListData?.billings;
      const billingToIdFromStorage = getSessionStorageItem(
        sessionKeys.SSN_REPORT_CONTRACTLIST_CMAPBILLINGC,
        true
      );

      if (billings && billings.length > 0) {
        billings.forEach((value) => {
          const billingTo = value?.billingTo;
          const billingToId = billingTo?.Id;
          if (billingToId === billingToIdFromStorage) {
            const billingToPaymentMethod = billingTo?.CMAP_PaymentMethod__c;
            const billingToBankName = billingTo?.CMAP_BankName__c;
            const bankAccountLastFourDigits = billingTo?.CMAP_AccountNumber__c.slice(-4);
            const creditCardNumberLastFourDigits =
              billingTo?.CMAP_CreditCardNumberLastFourDigits__c;

            // display payment method
            if (billingToPaymentMethod === STRING_CREDIT_CARD) {
              setRegisterPaymentMethod(
                `クレジットカード ************ ${creditCardNumberLastFourDigits}`
              );
              setPaymentMethodChangeOption1Hide(false);
            } else if (
              billingToPaymentMethod === STRING_BANK_ACCOUNT_TRANSFER
            ) {
              setRegisterPaymentMethod(
                `銀行口座振替 ${billingToBankName} ***${bankAccountLastFourDigits}`
              );
              setPaymentMethodChangeOption1Hide(false);
            } else {
              setPaymentMethodChangeOption1Hide(true);
            }

            // save ss
            setBillingID(billingTo?.Id);

            const beforeBillingOrderRelatedInfo = {
              ...orderRelatedInfoData,
              orderRelatedInfo: {
                ...orderRelatedInfoData?.orderRelatedInfo,
                billingTo: {
                  ...orderRelatedInfoData?.orderRelatedInfo?.billingTo,
                  Id: billingTo?.Id,
                  CMAP_PaymentMethod__c: billingTo?.CMAP_PaymentMethod__c,
                  CMAP_CreditCardNumberLastFourDigits__c: billingTo?.CMAP_CreditCardNumberLastFourDigits__c,
                  CMAP_YamaguchiInfoId__c: billingTo?.CMAP_YamaguchiInfoId__c,
                  CMAP_AccountNumber__c: billingTo?.CMAP_AccountNumber__c,
                  CMAP_BankName__c: billingTo?.CMAP_BankName__c,
                  CMAP_AccountTransferBillingNumber__c: billingTo?.CMAP_AccountTransferBillingNumber__c,
                },
              },
            };

            setSessionStorageItem(
              sessionKeys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO,
              beforeBillingOrderRelatedInfo,
              true
            );
          }
        });
      }
    } else {
      setPaymentMethodChangeOption1Hide(true);
    }
  }, [billToInfoListData]);

  // receive graphQl data
  useEffect(() => {
    let paymentMethodOptionItem =
      paymentMethodChangeQuery?.PaymentMethodChangeTypeMaster?.children;

    if (paymentMethodChangeOption1Hide) {
      if (paymentMethodOptionItem && paymentMethodOptionItem.length > 0) {
        paymentMethodOptionItem = paymentMethodOptionItem.slice(1);
      }
    }
    const paymentMethodChangeOptions = paymentMethodOptionItem?.map(
      (item, index) => {
        return {
          Code: item.fields.find((x) => x.name === "Code").value,
          Description: index === 0 && registerPaymentMethod,
          Value: item.fields.find((x) => x.name === "Value").value,
        };
      }
    );

    setPreferredPaymentMethodOptions(paymentMethodChangeOptions);

    h02PreferredPayment.fields["titleText"] = paymentMethodChangeTitle;
  }, [paymentMethodChangeQuery, paymentMethodChangeOption1Hide]);


  // set radio initial value
  useEffect(() => {
    if (preferredPaymentMethodOptions) {
      const isPaymentMethodChangeOptionExist = preferredPaymentMethodOptions.find(
        (item) => {
          return parseInt(item.Code) === paymentMethodChangeCode;
        }
      );
      if (isPaymentMethodChangeOptionExist) {
        setInputValues((prevState) => {
          return {
            ...prevState,
            selectedPaymentMethodChangeRadioButton: isPaymentMethodChangeOptionExist.Code,
          };
        });
        setPreferredPaymentMethod({
          [preferredPaymentMethodCategory]:
            isPaymentMethodChangeOptionExist.Code,
        });
        if (!isApply || !isSimulator) {
          paymentMethodChangeBehavior(isPaymentMethodChangeOptionExist.Code);
        }
      } else {
        setPreferredPaymentMethod({
          [preferredPaymentMethodCategory]: "",
        });
      }
    }
  }, [
    preferredPaymentMethodOptions,
    registerPaymentMethod,
  ]);

  s02PreferredPayment.fields.onChangeEvent = (value) => {
    setSelectedPaymentMethod({
      [selectPaymentMethodCategory]: "",
    });
    setInputValues((prevState) => {
      return {
        ...prevState,
        selectedPaymentMethodChangeRadioButton: value,
        selectedPaymentMethodRadioButton: "",
      };
    });
    paymentMethodChangeBehavior(value);
  };

  const bankRelationshipTypeQuery = UseGetDataViaGraphQL(
    queryBankRelationshipType,
    null,
    "BankRelationshipType"
  );
  const creditInformationSection = getScDataFromPlaceholder(
    "credit-information-section",
    props
  );
  const onChangeEventInput = (event) => {
    const newValue = event.target.value;
    const name = event.target.name;
    setInputValues({ ...inputValues, [name]: newValue });
  };
  const [
    i01CardNumber,
    i01CardName,
    i01ExpirationDate,
    i01SecurityCode,
  ] = getScDataFromComponent("I-01", creditInformationSection);
  i01CardNumber.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  i01CardName.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  i01ExpirationDate.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  i01SecurityCode.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  i01CardNumber.fields.onBlurEvent = (e) => {
    let currentInputValue = e.target.value;
    currentInputValue = currentInputValue.replace(/ /g, "");
    currentInputValue = currentInputValue.match(/.{1,4}/g);
    currentInputValue = currentInputValue.join(" ");
    setInputValues({ ...inputValues, cardNumber: currentInputValue });
  };
  i01CardNumber.fields.inputValue = {
    value: inputValues.cardNumber,
    name: "cardNumber",
  };
  i01CardName.fields.inputValue = {
    value: inputValues.cardName,
    name: "cardName",
  };
  i01ExpirationDate.fields.inputValue = {
    value: inputValues.expirationDate,
    name: "expirationDate",
  };
  i01SecurityCode.fields.inputValue = {
    value: inputValues.securityCode,
    name: "securityCode",
  };

  const [m01CreditInformation] = getScDataFromComponent(
    "M-01",
    creditInformationSection
  );
  m01CreditInformation.fields.formName = "selectedCreditCardRelationship";
  useEffect(() => {
    const bankRelationshipTypeOptions = bankRelationshipTypeQuery?.BankRelationshipType?.children?.map(
      (item, index) => {
        return {
          data: item.fields.find((x) => x.name === "Code").value,
          name: item.fields.find((x) => x.name === "Value").value,
          value: item.fields.find((x) => x.name === "Value").value,
        };
      }
    );
    if (bankRelationshipTypeOptions && bankRelationshipTypeOptions.length > 0) {
      m01CreditInformation.fields.pulldownData = bankRelationshipTypeOptions;
      setInputValues((prevState) => {
        return {
          ...prevState,
          selectedCreditCardRelationship: bankRelationshipTypeOptions[0].value,
        };
      });
    }
  }, [bankRelationshipTypeQuery]);

  m01CreditInformation.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };

  const paymentMethodChangeBehavior = (value) => {
    if (parseInt(value) === 1) {
      setPaymentMethodVisibility(false);
      setRegisterCreditCardVisibility(false);
    } else {
      setPaymentMethodVisibility(true);
      h02PaymentMethod.fields["titleText"] = {
        value: paymentMethodTitle,
      };
    }
  };

  const paymentMethodBehavior = (value) => {
    if (value === STRING_CREDIT_CARD) {
      d01PaymentMethod.fields.isHidden = {
        value: true,
      };
      if (isCpad) {
        setRegisterCreditCardVisibility(false);
      } else {
        setRegisterCreditCardVisibility(true);
      }
    } else if (value === STRING_BANK_ACCOUNT_TRANSFER) {
      setRegisterCreditCardVisibility(false);
      d01PaymentMethod.fields.isHidden = {
        value: false,
      };
    } else {
      setRegisterCreditCardVisibility(false);
      d01PaymentMethod.fields.isHidden = {
        value: true,
      };
    }
  };

  const buttonContainerSection = getScDataFromPlaceholder(
    "button-container",
    props
  );
  const [b02Data] = getScDataFromComponent("B-02", buttonContainerSection);
  const [b01Data] = getScDataFromComponent("B-01", buttonContainerSection);

  b02Data.fields.onClickEvent = () => {
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_INPUTBILLINGINFO_PAYMENTMETHODCHANGECODE,
      inputValues.selectedPaymentMethodChangeRadioButton,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_INPUTBILLINGINFO_PAYMENTMETHODCODE,
      inputValues.selectedPaymentMethodRadioButton,
      true
    );

    redirectToPreviousPage();
  };

  const { linkURL } = getProperData(sitecoreContext, b01Data?.fields);
  const retrieveInputValueAuthorizationURI = `${apiBaseURL}order/RetrieveInputValueForAuthorization`;
  const {
    data: inputValueAuthorizationData,
    isLoading: inputValueAuthorizationIsLoading,
    error: inputValueAuthorizationError,
    refetch: inputValueAuthorizationRefresh,
    remove: inputValueAuthorizationRemove,
  } = useCustomQuery(retrieveInputValueAuthorizationURI, false, STRING_GET);

  const [authorizeSuccess, setAuthorizeSuccess] = useState(false);
  const [authorizeCreditCard, setAuthorizeCreditCard] = useState(false);
  const [inputValueAuthorization, setInputValueAuthorization] = useState(false);
  const [customerNo, setCustomerNo] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [customerNameFullWidth, setCustomerNameFullWidth] = useState("");
  const [securityCodeHalfWidth, setSecurityCodeHalfWidth] = useState("");
  const [expirationYYMMFormat, setExpirationYYMMFormat] = useState("");

  const [authorizeCreditCardInfo, setAuthorizeCreditCardInfo] = useState({});
  const authorizeCreditCardURI = `${apiBaseURL}order/AuthorizeCreditCard`;
  const {
    data: authorizeCreditCardData,
    isLoading: authorizeCreditCardIsLoading,
    error: authorizeCreditCardError,
    refetch: authorizeCreditCardRefetch,
    remove: authorizeCreditCardRemove,
  } = useCustomQuery(
    authorizeCreditCardURI,
    false,
    STRING_POST,
    authorizeCreditCardInfo
  );

  const isApiLoading =
    retrieveMetadataLoading ||
    billToInfoListIsLoading ||
    inputValueAuthorizationIsLoading ||
    authorizeCreditCardIsLoading;

  const isGQLQueryLoading =
    paymentMethodChangeQuery === STRING_LOADING ||
    bankRelationshipTypeQuery === STRING_LOADING;

  props.setIsLoading(isApiLoading || isGQLQueryLoading);

  useEffect(() => {
    if (authorizeSuccess) {
      if (authorizeCreditCardData) {
        orderRelatedInfoData = {
          ...orderRelatedInfoData,
          orderRelatedInfo: {
            ...orderRelatedInfoData?.orderRelatedInfo,
            billingTo: {
              ...orderRelatedInfoData?.orderRelatedInfo?.billingTo,
              CMAP_CreditCardNumberFirstFourDigits__c:
                authorizeCreditCardData?.CMAP_CreditCardNumberLastFourDigits__c,
              CMAP_YamaguchiInfoId__c:
                authorizeCreditCardData?.CMAP_YamaguchiInfoId__c,
            },
          },
        };
        setSessionStorageItem(
          sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
          orderRelatedInfoData,
          true
        );
        setAuthorizeCreditCard(true);
      }
      setAuthorizeSuccess(false);
    }
  }, [authorizeCreditCardData]);

  useEffect(() => {
    if (inputValueAuthorizationData && inputValueAuthorization) {
      const authorizationData = inputValueAuthorizationData?.result;

      if (inputValueAuthorizationData?.resultcode !== 0) {
        setError("API Response Error", {
          type: "manual",
          message: inputValueAuthorizationData?.message,
        });
      } else {
        setCustomerNo(authorizationData?.customerCardNo.substring(0, 10));
        setCardBrand(
          creditCardBrand(authorizationData?.customerCardNo.substring(0, 10))
        );
        setCustomerNameFullWidth(fullWidthSpace(inputValues.cardName, 32));
        setSecurityCodeHalfWidth(halfWidthSpace(inputValues.securityCode, 4));
        setExpirationYYMMFormat(yyMMFormat(inputValues.expirationDate));
        const numberSpace = "10 ";
        const token = window.Token(
          inputValues.cardNumber.replace(/ /g, ""),
          authorizationData?.transNo,
          authorizationData?.trans_date,
          authorizationData?.center_id,
          authorizationData?.yds_id,
          authorizationData?.com_id,
          customerNo,
          authorizationData?.hash
        );

        const tokenNo = token.tknno;
        const tokenStatus = token.status;
        setAuthorizeCreditCardInfo({
          trans_no: authorizationData?.transNo,
          trans_date: authorizationData?.trans_date,
          term_id: customerNo,
          item_id: "",
          item_name: "",
          div_trade: 0,
          amount: 1,
          biz_info: `500${customerNo}${tokenNo}${expirationYYMMFormat}${numberSpace}${securityCodeHalfWidth}${customerNameFullWidth}`,
          hash: authorizationData?.hash,
        });

        if (tokenStatus !== "00000") {
          if (
            tokenStatus === "T0150" ||
            tokenStatus === "T0151" ||
            tokenStatus === "T0152"
          ) {
            setError("YDS Error 1", {
              type: "manual",
              message: getErrorMessage("E0025", []),
            });
          } else if (tokenStatus === "T9600") {
            setError("YDS Error 2", {
              type: "manual",
              message: getErrorMessage("E0026", []),
            });
          } else {
            setError("YDS Error 3", {
              type: "manual",
              message: getErrorMessage("E0027", []),
            });
          }
        } else {
          setAuthorizeSuccess(true);
        }
      }
      setInputValueAuthorization(false);
    }
  }, [inputValueAuthorizationData, inputValueAuthorization]);

  useEffect(() => {
    if (authorizeSuccess) {
      authorizeCreditCardRefetch();
    }
  }, [authorizeSuccess]);

  useEffect(() => {
    if (authorizeCreditCard) {
      if (parseInt(inputValues.selectedPaymentMethodChangeRadioButton) !== 1) {
        // myCommufa (not 登録済みのお支払い方法)
        orderRelatedInfoData = {
          ...orderRelatedInfoData,
          orderRelatedInfo: {
            ...orderRelatedInfoData?.orderRelatedInfo,
            billingTo: {
              ...orderRelatedInfoData?.orderRelatedInfo?.billingTo,
              CMAP_PaymentMethod__c:
                inputValues.selectedPaymentMethodRadioButton,
              CMAP_CreditCardNumberFirstFourDigits__c: inputValues.cardNumber.substring(
                0,
                4
              ),
              CMAP_CreditCardNumberLastFourDigits__c: inputValues.cardNumber.slice(
                -4
              ),
              CMAP_CreditCardName__c: customerNameFullWidth,
              CMAP_CreditCardExpirationDate__c: expirationYYMMFormat,
              CMAP_CreditCardBankRelationship__c:
                inputValues.selectedCreditCardRelationship,
              CMAP_CardCompany__c: cardBrand,
              CMAP_PaymentRegistrationMethod__c: 1,
              CMAP_ListDisplayPaymentMethod__c: "",
              CMAP_AccountNumber__c: "",
              CMAP_BankName__c: "",
              CMAP_AccountTransferBillingNumber__c: "",
            },
          },
        };
      }
      setAuthorizeCreditCard(false);
      setSessionStorageItem(
        sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
        orderRelatedInfoData,
        true
      );
      redirectToLinkUrl(linkURL);
    }
  }, [authorizeCreditCard]);

  b01Data.fields.onClickEvent = () => {
    let isValid = true;
    clearErrors();

    if ((isCpad && isApply) || (!isCpad && (isApply || isSimulator))) {
      if (
        !validationFuncs.validateIsRequired(
          inputValues.selectedPaymentMethodRadioButton
        )
      ) {
        setError("PaymentMethod", {
          type: "manual",
          message: getErrorMessage("E0002", ["支払方法選択ラジオボタン"]),
        });
        isValid = false;
      }
    } else {
      if (
        !validationFuncs.validateIsRequired(
          inputValues.selectedPaymentMethodChangeRadioButton
        )
      ) {
        setError("PaymentMethodChange", {
          type: "manual",
          message: getErrorMessage("E0002", ["支払方法変更選択ラジオボタン"]),
        });
        isValid = false;
      }
      if (parseInt(inputValues.selectedPaymentMethodChangeRadioButton) === 2) {
        if (
          !validationFuncs.validateIsRequired(
            inputValues.selectedPaymentMethodRadioButton
          )
        ) {
          setError("PaymentMethod", {
            type: "manual",
            message: getErrorMessage("E0002", ["支払方法選択ラジオボタン"]),
          });
          isValid = false;
        }
      }
    }

    if (isValid) {
      let censoredSecurityCode = "";
      const securityCodeLength = inputValues.securityCode.length;
      for (var i = 0; i < securityCodeLength; i++) {
        censoredSecurityCode += "●";
      }
      const beforeOrderRelatedInfo = getSessionStorageItem(
        sessionKeys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_APPLICATION_INPUTBILLINGINFO_PAYMENTMETHODCODE,
        inputValues.selectedPaymentMethodRadioButton,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_APPLICATION_INPUTBILLINGINFO_PAYMENTMETHODCHANGECODE,
        inputValues.selectedPaymentMethodChangeRadioButton,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_APPLICATION_INPUTBILLINGINFO_SECURITYCODE,
        censoredSecurityCode,
        true
      );
      if (parseInt(inputValues.selectedPaymentMethodChangeRadioButton) === 1) {
        // 登録済みのお支払い方法
        orderRelatedInfoData = {
          ...orderRelatedInfoData,
          orderRelatedInfo: {
            ...orderRelatedInfoData?.orderRelatedInfo,
            order: {
              ...orderRelatedInfoData?.orderRelatedInfo?.order,
              CMAP_Billing__c: billingId,
            },
            billingTo: {
              ...orderRelatedInfoData?.orderRelatedInfo?.billingTo,
              CMAP_PaymentMethod__c:
                beforeOrderRelatedInfo?.orderRelatedInfo?.billingTo?.CMAP_PaymentMethod__c,
              CMAP_CreditCardNumberFirstFourDigits__c:
                beforeOrderRelatedInfo?.orderRelatedInfo?.billingTo
                  ?.CMAP_CreditCardNumberFirstFourDigits__c,
              CMAP_CreditCardNumberLastFourDigits__c:
                beforeOrderRelatedInfo?.orderRelatedInfo?.billingTo
                  ?.CMAP_CreditCardNumberLastFourDigits__c,
              CMAP_YamaguchiInfoId__c:
                beforeOrderRelatedInfo?.orderRelatedInfo?.billingTo
                  ?.CMAP_YamaguchiInfoId__c,
              CMAP_CreditCardName__c:
                beforeOrderRelatedInfo?.orderRelatedInfo?.billingTo
                  ?.CMAP_CreditCardName__c,
              CMAP_CreditCardExpirationDate__c:
                beforeOrderRelatedInfo?.orderRelatedInfo?.billingTo
                  ?.CMAP_CreditCardExpirationDate__c,
              CMAP_CreditCardBankRelationship__c:
                beforeOrderRelatedInfo?.orderRelatedInfo?.billingTo
                  ?.CMAP_CreditCardBankRelationship__c,
              CMAP_CardCompany__c:
                beforeOrderRelatedInfo?.orderRelatedInfo?.billingTo
                  ?.CMAP_CardCompany__c,
              CMAP_IndividualCorporationKbn__c:
                beforeOrderRelatedInfo?.orderRelatedInfo?.billingTo
                  ?.CMAP_IndividualCorporationKbn__c,
              CMAP_PaymentRegistrationMethod__c:
                beforeOrderRelatedInfo?.orderRelatedInfo?.billingTo
                  ?.CMAP_PaymentRegistrationMethod__c,
              CMAP_ListDisplayPaymentMethod__c:
                beforeOrderRelatedInfo?.orderRelatedInfo?.billingTo
                  ?.CMAP_ListDisplayPaymentMethod__c,
              CMAP_AccountNumber__c:
                beforeOrderRelatedInfo?.orderRelatedInfo?.billingTo
                  ?.CMAP_AccountNumber__c,
              CMAP_BankName__c:
                beforeOrderRelatedInfo?.orderRelatedInfo?.billingTo
                  ?.CMAP_BankName__c,
              CMAP_AccountTransferBillingNumber__c:
                beforeOrderRelatedInfo?.orderRelatedInfo?.billingTo
                  ?.CMAP_AccountTransferBillingNumber__c,
            },
          },
        };
        setSessionStorageItem(
          sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
          orderRelatedInfoData,
          true
        );
      }
    }

    if (!isCpad) {
      if (inputValues.selectedPaymentMethodRadioButton === STRING_CREDIT_CARD) {
        if (
          !validationFuncs.validateIsNumber(
            inputValues.cardNumber.replace(/ /g, "")
          )
        ) {
          setError("CardNumber", {
            type: "manual",
            message: getErrorMessage("E0007", ["カード番号"]),
          });
          isValid = false;
        }
        if (
          !validationFuncs.validateIsMaxLength(
            inputValues.cardNumber.replace(/ /g, ""),
            16
          )
        ) {
          setError("CardNumber", {
            type: "manual",
            message: getErrorMessage("E0018", ["カード番号", 16]),
          });
          isValid = false;
        }

        if (!validationFuncs.validateIsFullWidth(inputValues.cardName)) {
          setError("CardHolder", {
            type: "manual",
            message: getErrorMessage("E0009", ["カード名義"]),
          });
          isValid = false;
        }
        if (!validationFuncs.validateIsMaxLength(inputValues.cardName, 32)) {
          setError("CardHolder", {
            type: "manual",
            message: getErrorMessage("E0015", ["カード名義", 32]),
          });
          isValid = false;
        }

        if (
          !validationFuncs.validateCreditCardExpirationDate(
            inputValues.expirationDate
          )
        ) {
          setError("ExpirationDate", {
            type: "manual",
            message: getErrorMessage("E0020", ["有効期限（月/年）", "MM/yy"]),
          });
          isValid = false;
        }

        if (!validationFuncs.validateIsNumber(inputValues.securityCode)) {
          setError("SecurityCode", {
            type: "manual",
            message: getErrorMessage("E0007", ["セキュリティーコード"]),
          });
          isValid = false;
        }
        if (!validationFuncs.validateIsMaxLength(inputValues.securityCode, 4)) {
          setError("SecurityCode", {
            type: "manual",
            message: getErrorMessage("E0018", ["セキュリティーコード", 4]),
          });
          isValid = false;
        }

        if (isValid) {
          setInputValueAuthorization(true);
          inputValueAuthorizationRefresh();
        }
      } else {
        if (isValid) {
          redirectToLinkUrl(linkURL);
        }
      }
    } else {
      if (isValid) {
        if (
          parseInt(inputValues.selectedPaymentMethodChangeRadioButton) !== 1
        ) {
          // cpad (not 登録済みのお支払い方法)
          orderRelatedInfoData = {
            ...orderRelatedInfoData,
            orderRelatedInfo: {
              ...orderRelatedInfoData?.orderRelatedInfo,
              billingTo: {
                ...orderRelatedInfoData?.orderRelatedInfo?.billingTo,
                CMAP_PaymentMethod__c:
                  inputValues.selectedPaymentMethodRadioButton,
                CMAP_CreditCardNumberFirstFourDigits__c: "",
                CMAP_CreditCardNumberLastFourDigits__c: "",
                CMAP_CreditCardName__c: "",
                CMAP_CreditCardExpirationDate__c: "",
                CMAP_CreditCardBankRelationship__c: "",
                CMAP_CardCompany__c: "",
                CMAP_PaymentRegistrationMethod__c: 1,
                CMAP_ListDisplayPaymentMethod__c: "",
                CMAP_AccountNumber__c: "",
                CMAP_BankName__c: "",
                CMAP_AccountTransferBillingNumber__c: "",
              },
            },
          };
        }
        setSessionStorageItem(
          sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
          orderRelatedInfoData,
          true
        );
        redirectToLinkUrl(linkURL);
      }
    }
  };
  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <PaymentMethodSelection
                rendering={props.rendering}
                paymentMethodChangeVisibility={paymentMethodChangeVisibility}
                paymentMethodVisibility={paymentMethodVisibility}
              />
              {registerCreditCardVisibility && (
                <RegisterCreditCard rendering={props.rendering} />
              )}
              <div className="btn_container" name="button-container">
                <Placeholder
                  name="button-container"
                  rendering={props.rendering}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(BillingInformationLayout);