import React from "react";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";

// @Model

// get the data

// update the context

// @Controller

// @View

const L07 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { additionalClass, noteTitleText, noteText, richText } = properData;

  return (
    <React.Fragment>
      <div
        className={`list_wrapper note secondary L-07 ${additionalClass?.value}`}
      >
        {richText?.value ? (
          <RichText field={fillBlank(richText)} />
        ) : (
          <>
            <div className="note-title">
              <i className="bi bi-info-circle-fill"></i>
              <Text field={fillBlank(noteTitleText)} />
            </div>
            {noteText.value !== "–" && (
              <Text field={fillBlank(noteText)} tag="p" encode={false} />
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default L07;
