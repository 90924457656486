import React, { useContext, useMemo } from "react";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import ModalContext from "../../assets/context/ModalContext";
import { fillBlank, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const P001 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { titleText, descriptionText, modalTarget } = properData;
  const { state, dispatch } = useContext(ModalContext);

  // @Controller
  const {
    showModal,
    displayModal,
    paddingModal,
    roleValue,
    colorGrayClass,
  } = useMemo(() => {
    const { showModal, id } = state;
    return showModal && modalTarget?.value === id
      ? state
      : {
          showModal: "",
          displayModal: "",
          paddingModal: "",
          roleValue: "",
          colorGrayClass: "",
        };
  }, [state]);

  const handleConfirmButtonClick = () => {
    dispatch({ type: "HIDE_MODAL", payload: { id: modalTarget?.value } });
  };

  const handleCancelButtonClick = () => {
    dispatch({ type: "HIDE_MODAL", payload: { id: modalTarget?.value } });
  };

  // @View
  return (
    <React.Fragment>
      <div
        tabIndex="-1"
        aria-modal="true"
        data-backdrop="static"
        id={modalTarget?.value}
        role={roleValue}
        className={`modal popup fade P0-01 ${showModal}`}
        style={{ display: displayModal, paddingRight: paddingModal }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {titleText?.value && (
              <div className="modal-header">
                <h5 className="modal-title">
                  <Text field={fillBlank(titleText)} />
                </h5>
              </div>
            )}

            {/* Custom Modal Layout [ex: WarningMessageModalLayout] */}
            {properData?.isCustom && (
              <Placeholder name="modal-section" rendering={props.rendering} />
            )}

            {/* Default modal content */}
            {!properData?.isCustom && (
              <>
                <div className="modal-body">
                  <div className="modal-body_content">
                    {descriptionText && (
                      <Text
                        field={fillBlank(descriptionText)}
                        tag="p"
                        encode={false}
                      />
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <Placeholder
                    name="button-section-p01-ok"
                    rendering={props.rendering}
                    onClick={handleConfirmButtonClick}
                  />
                  <Placeholder
                    name="button-section-p01-cancel"
                    rendering={props.rendering}
                    onClick={handleCancelButtonClick}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={colorGrayClass}></div>
    </React.Fragment>
  );
};

export default P001;
