export const recoilKeys = {
  ATM_APPLICATION_SESSIONSTORAGE: "atom/application/sessionstorage",
  SLT_APPLICATION_SESSIONSTORAGE: "selector/application/sessionstorage",

  ATM_PLAN_SELECT: "atm_plan_select",
  SLT_PLAN_SELECT: "slt_plan_select",

  ATM_SELECTED_PLAN_COMPONENT_NAME: "atom/contract/selected/plannames",
  ATM_SELECTED_CONTRACTED: "atom/contract/selected",
  ATM_METADATA_INFO: "atom/salesforce/metadatainfo",
  SLT_SELECTED_PLAN_COMPONENT_NAME: "selector/contract/selected/plannames",
  SLT_SELECTED_CONTRACTED: "selector/contract/selected",
  SLT_SELECTED_CONTRACTED_CHECKS: "selector/contract/selected/checks",
  SLT_METADATA_INFO: "selector/salesforce/metadatainfo",

  ATM_COMPONENT_VISIBLE: "atom/component/visible/name",
  SLT_COMPONENT_VISIBLE: "selector/component/visible",

  ATM_METADATA_CONDITION_REQUIRED_EFFECTERS:
    "atom/metadata/condition/required/effecters",
  ATM_METADATA_CONDITION_REQUIRED_EFFECTCONDITON:
    "atom/metadata/condition/required/effectcondition",
  ATM_METADATA_CONDITION_REQUIRED: "atom/metadata/condition/required",

  ATM_METADATA_CONDITION_VISIBLE_EFFECTERS:
    "atom/metadata/condition/visible/effecters",
  ATM_METADATA_CONDITION_VISIBLE_EFFECTCONDITON:
    "atom/metadata/condition/visible/effectcondition",
  ATM_METADATA_CONDITION_VISIBLE: "atom/metadata/condition/visible",
  ATM_METADATA_MAPPINGTABLE: `atom/metadata/mappingtable`,
  ATM_METADATA_MAPPINGTABLE_FROM_HUMABILITY: `atom/metadata/mappingtable/humability`,
  SLT_METADATA_CONDITION_VISIBLE: "selector/metadata/condition/visible",
  SLT_METADATA_CONDITION_VISIBLE2: "selector/metadata/condition/visible2",

  ATM_METADATA_CONDITION_ACTIVE: "atom/metadata/condition/active",
  ATM_METADATA_CONDITION_OPTIONS: "atom/metadata/condition/options",
  ATM_METADATA_CONDITION_HELPTEXT: "atom/metadata/condition/helptext",
  ATM_METADATA_CONDITION_PRECAUTION: "atom/metadata/condition/precaution",

  // session cookies
  COOKIE_APPLICATION_ID: "ApplicationId",
  COOKIE_MAIL_ADDRESS: "MailAddress",
  COOKIE_PASCO_RESULT: "PascoResult",
  COOKIE_CTC_SERVER_SESSION_INFO: "ctc_server_session_info",
  COOKIE_CTC_CUSTOMER_INFO: "ctc_customer_info",
  COOKIE_CTC_LOGOUT_FLG: "ctc_logout_flg",

  //for session
  SSN_APPLICATION_RECONFIRMADDRESS_STATE: "Application.ReconfirmAddress.State",
  SSN_APPLICATION_RECONFIRMADDRESS_MUNICIPALITY:
    "Application.ReconfirmAddress.Municipality",
  SSN_APPLICATION_RECONFIRMADDRESS_DISTRICT:
    "Application.ReconfirmAddress.District",
  SSN_APPLICATION_RECONFIRMADDRESS_STREET1:
    "Application.ReconfirmAddress.Street1",
  SSN_APPLICATION_RECONFIRMADDRESS_STREET2:
    "Application.ReconfirmAddress.Street2",

  // common session
  SSN_COMMON_COMMON_ORDERRELATEDINFO: "Common.Common.OrderRelatedInfo",
  SSN_COMMON_COMMON_APPLICATIONNUMBER: "Common.Common.ApplicationNumber",
  SSN_COMMON_COMMON_PERSONALIZEINFO: "Common.Common.PersonalizeInfo",
  SSN_COMMON_COMMON_CUSTOMERNAME: "Common.Common.CustomerName",
  SSN_COMMON_COMMON_MYCOMMUFAID: "Common.Common.mycommufaID",
  SSN_COMMON_COMMON_CUSTOMERID: "Common.Common.CustomerID",
  SSN_COMMON_COMMON_ORDERCATEGORY: "Common.Common.OrderCategory",
  SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO:
    "Common.Common.BeforeChangeOrderRelatedInfo",

  SSN_APPLICATION_INPUTBILLINGINFO_BILLINGID:
    "Application.InputBillingInfo.BillingID",
  SSN_APPLICATION_INPUTBILLINGINFO_PAYMENTMETHODCODE:
    "Application.InputBillingInfo.PaymentMethodCode",
  SSN_APPLICATION_INPUTBILLINGINFO_PAYMENTMETHODCHANGECODE:
    "Application.InputBillingInfo.PaymentMethodChangeCode",
  SSN_APPLICATION_INPUTBILLINGINFO_CARDNUMBER:
    "Application.InputBillingInfo.CardNumber",
  SSN_APPLICATION_INPUTBILLINGINFO_CARDHOLDER:
    "Application.InputBillingInfo.CardHolder",
  SSN_APPLICATION_INPUTBILLINGINFO_DATEOFEXPIRY:
    "Application.InputBillingInfo.DateOfExpiry",
  SSN_APPLICATION_INPUTBILLINGINFO_SECURITYCODE:
    "Application.InputBillingInfo.SecurityCode",
  SSN_APPLICATION_INPUTBILLINGINFO_YAMAGUCHIINFOMANAGEMENTID:
    "Application.InputBillingInfo.YamaguchiInfoManagementID",
  SSN_APPLICATION_INPUTBILLINGINFO_ACCOUNTNUMBER:
    "Application.InputBillingInfo.AccountNumber",
  SSN_APPLICATION_INPUTBILLINGINFO_BANKNAME:
    "Application.InputBillingInfo.BankName",
  SSN_APPLICATION_INPUTBILLINGINFO_BANKNUMBERLASTFOURDIGITS:
    "Application.InputBillingInfo.BankNumberLastFourDigits",
  SSN_APPLICATION_INPUTBILLINGINFO_ISNEWPAYMENTMETHOD:
    "Application.InputBillingInfo.IsNewPaymentMethod",

  // session information for application detail confirmation page.
  SSN_APPLICATION_SELECTPRODUCT_PLANNAME: "Application.SelectProduct.PlanName",
  SSN_APPLICATION_SELECTPRODUCT_MONTHLYFEE:
    "Application.SelectProduct.MonthlyFee",
  SSN_APPLICATION_SELECTPRODUCT_ANNUALFEE:
    "Application.SelectProduct.AnnualFee",
  SSN_APPLICATION_SELECTPRODUCT_INITIALCOST:
    "Application.SelectProduct.InitialCost",
  SSN_APPLICATION_SELECTPRODUCT_MONTHLYFEETAXTOTAL:
    "Application.SelectProduct.MonthlyFeeTaxTotal",
  SSN_APPLICATION_SELECTPRODUCT_MONTHLYFEEREDUCEDTAXRATETOTAL:
    "Application.SelectProduct.MonthlyFeeReducedTaxRateTotal",
  SSN_APPLICATION_SELECTPRODUCT_MONTHLYFEENORMALTAXRATETOTAL:
    "Application.SelectProduct.MonthlyFeeNormalTaxRateTotal",
  SSN_APPLICATION_SELECTPRODUCT_ANNUALFEETAXTOTAL:
    "Application.SelectProduct.AnnualFeeTaxTotal",
  SSN_APPLICATION_SELECTPRODUCT_ANNUALFEEREDUCEDTAXRATETOTAL:
    "Application.SelectProduct.AnnualFeeReducedTaxRateTotal",
  SSN_APPLICATION_SELECTPRODUCT_ANNUALFEENORMALTAXRATETOTAL:
    "Application.SelectProduct.AnnualFeeNormalTaxRateTotal",
  SSN_APPLICATION_SELECTPRODUCT_INITIALCOSTTAXTOTAL:
    "Application.SelectProduct.InitialCostTaxTotal",
  SSN_APPLICATION_SELECTPRODUCT_INITIALCOSTREDUCEDTAXRATETOTAL:
    "Application.SelectProduct.InitialCostReducedTaxRateTotal",
  SSN_APPLICATION_SELECTPRODUCT_INITIALCOSTNORMALTAXRATETOTAL:
    "Application.SelectProduct.InitialCostNormalTaxRateTotal",
  SSN_APPLICATION_SELECTPRODUCT_MONTHLYPRODUCTLIST:
    "Application.SelectProduct.MonthlyProductList",
  SSN_APPLICATION_SELECTPRODUCT_ANNUALPRODUCTLIST:
    "Application.SelectProduct.AnnualProductList",
  SSN_APPLICATION_SELECTPRODUCT_INITIALCOSTLIST:
    "Application.SelectProduct.InitialCostList",
  SSN_APPLICATION_SELECTPRODUCT_PLANBENEFITLIST:
    "Application.SelectProduct.PlanBenefitList",
  SSN_APPLICATION_SELECTPRODUCT_PLANATTRIBUTELIST:
    "Application.SelectProduct.PlanAttributeList",
  SSN_APPLICATION_SELECTPRODUCT_SELECTEDPLANINFO:
    "Application.SelectProduct.SelectedPlanInfo",
  SSN_APPLICATION_SELECTPRODUCT_PRODUCTSELECTIONLIST:
    "Application.SelectProduct.ProductSelectionList",
  SSN_APPLICATION_SELECTPRODUCT_BENEFITCAMPAIGNINFOS:
    "Application.SelectProduct.BenefitCampaignInfos",
  SSN_APPLICATION_SELECTPRODUCT_NOAPPLYBENEFIT:
    "Application.SelectProduct.NoApplyBenefit",

  SSN_APPLICATION_INPUTCUSTOMERINFO_ISNEWPAYMENTMETHOD:
    "Application.InputCustomerInfo.IsNewPaymentMethod",

  SSN_APPLICATION_SELECTPLANTYPE_PLANTYPE:
    "Application.SelectPlanType.PlanType",
  SSN_APPLICATION_SELECTPLANTYPE_ADDRESSCODE:
    "Application.SelectPlanType.AddressCode",
  SSN_APPLICATION_SELECTPLANTYPE_POSTALCODE3DIGIT:
    "Application.SelectPlanType.PostalCode3Digit",
  SSN_APPLICATION_SELECTPLANTYPE_POSTALCODE4DIGIT:
    "Application.SelectPlanType.PostalCode4Digit",
  SSN_APPLICATION_SELECTPLANTYPE_INSTALLATIONLOCATIONCITYCODE:
    "Application.SelectPlanType.InstallationLocationCityCode",
  SSN_APPLICATION_SELECTPLANTYPE_INSTALLATIONLOCATIONPREFECTURECODE:
    "Application.SelectPlanType.InstallationLocationPrefectureCode",
  SSN_APPLICATION_SELECTPLANTYPE_INSTALLATIONLOCATIONADDRESS:
    "Application.SelectPlanType.InstallationLocationAddress",
  SSN_APPLICATION_SELECTPLANTYPE_STREETNAME:
    "Application.SelectPlanType.StreetName",

  SSN_MYCOMMUFA_MYCOMMUFATOP_CUSTOMERNAME:
    "mycommufa.mycommufaTOP.CustomerName",
  SSN_MYCOMMUFA_MYCOMMUFATOP_MYCOMMUFAID: "mycommufa.mycommufaTOP.mycommufaID",

  SSN_APPLICATION_SELECTPRODUCT_BEFORECHANGE_PLANNAME:
    "Application.SelectProduct.BeforeChange-PlanName",
  SSN_APPLICATION_SELECTPRODUCT_BEFORECHANGE_MONTHLYPRODUCTLIST:
    "Application.SelectProduct.BeforeChange-MonthlyProductList",
  SSN_APPLICATION_SELECTPRODUCT_BEFORECHANGE_ANNUALPRODUCTLIST:
    "Application.SelectProduct.BeforeChange-AnnualProductList",
  SSN_APPLICATION_SELECTPRODUCT_BEFORECHANGE_INITIALCOSTLIST:
    "Application.SelectProduct.BeforeChange-InitialCostList",
  SSN_APPLICATION_SELECTPRODUCT_BEFORECHANGE_PLANBENEFITLIST:
    "Application.SelectProduct.BeforeChange-PlanBenefitList",
  SSN_APPLICATION_SELECTPRODUCT_BEFORECHANGE_PLANATTRIBUTELIST:
    "Application.SelectProduct.BeforeChange-PlanAttributeList",
  SSN_APPLICATION_SELECTPRODUCT_SELECTEDPLANINFO:
    "Application.SelectProduct.SelectedPlanInfo",
  SSN_APPLICATION_SELECTPRODUCT_BENEFITLISTFORAGENCY:
    "Application.SelectProduct.BenefitListForAgency",

  SSN_REPORT_CONTRACTLIST_CONTRACTNUMBER: "Report.ContractList.ContractNumber",
  SSN_REPORT_CONTRACTLIST_CONTRACTID: "Report.ContractList.ContractID",
  SSN_REPORT_CONTRACTLIST_CMAPBILLINGC: "Report.ContractList.CMAP_Billing__c",
  SSN_REPORT_CONTRACTLIST_ADDRESS: "Report.ContractList.Address",
  SSN_REPORT_CONTRACTLIST_PLANNAME: "Report.ContractList.PlanName",
  SSN_REPORT_CONTRACTLIST_ISREBUILD: "Report.ContractList.IsRebuild",
  SSN_REPORT_CONTRACTLIST_ISTYPENOTCIRCUIT:
    "Report.ContractList.IsTypeNotCircuit",
  SSN_REPORT_CONTRACTLIST_PROGRESSIONORDERID:
    "Report.ContractList.ProgressionOrderId",

  SSN_REPORT_CONTRACTLIST_APPLICATIONID: "Report.ContractList.ApplicationId",

  SSN_APPLICATION_INPUTCUSTOMERINFO_ROOMNUMBER:
    "Application.InputCustomerInfo.RoomNumber",
  SSN_APPLICATION_SELECTPLANTYPE_DELIVERYSERVICEADDRESSSTREETNAME:
    "Application.SelectPlanType.DeliveryServiceAddressStreetName",

  SSN_REPORT_CONTRACTCANCEL_SUMOFCANCELLATIONCHARGE:
    "Report.ContractCancel.SumOfCancellationCharge",
  SSN_REPORT_CONTRACTCANCEL_BREAKDOWNOFCANCELLATIONCHARGEITEMS:
    "Report.ContractCancel.BreakdownOfCancellationChargeItems",
  SSN_REPORT_CONTRACTCANCEL_BREAKDOWNOFDEBTITEMS:
    "Report.ContractCancel.BreakdownOfDebtItems",

  SSN_APPLICATION_INPUTCUSTOMERINFO_ISFIRSTTIMEDISPLAY:
    "Application.InputCustomerInfo.IsFirstTimeDisplay",

  SSN_APPLICATION_INPUTCUSTOMERINFO_BUILDINGDIVISION:
    "Application.InputCustomerInfo.BuildingDivision",
  SSN_APPLICATION_INPUTCUSTOMERINFO_INSTALLATIONADDRESSAPARTMENTROOMNUMBER:
    "Application.InputCustomerInfo.InstallationAddress-ApartmentRoomNumber",
  SSN_APPLICATION_INPUTCUSTOMERINFO_LASTNAME:
    "Application.InputCustomerInfo.LastName",
  SSN_APPLICATION_INPUTCUSTOMERINFO_FIRSTNAME:
    "Application.InputCustomerInfo.FirstName",
  SSN_APPLICATION_INPUTCUSTOMERINFO_LASTNAMEINKANA:
    "Application.InputCustomerInfo.LastNameInKana",
  SSN_APPLICATION_INPUTCUSTOMERINFO_FIRSTNAMEINKANA:
    "Application.InputCustomerInfo.FirstNameInKana",
  SSN_APPLICATION_INPUTCUSTOMERINFO_GENDER:
    "Application.InputCustomerInfo.Gender",
  SSN_APPLICATION_INPUTCUSTOMERINFO_BIRTHYEARMONTHDATE:
    "Application.InputCustomerInfo.BirthYearMonthDate",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CORPORATIONNAME:
    "Application.InputCustomerInfo.CorporationName",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CORPORATIONNAMEINKANA:
    "Application.InputCustomerInfo.CorporationNameInKana",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CORPORATIONNAME:
    "Application.InputCustomerInfo.CorporationName",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CORPORATIONNAME:
    "Application.InputCustomerInfo.CorporationName",
  SSN_APPLICATION_INPUTCUSTOMERINFO_DEPARTMENTNAME:
    "Application.InputCustomerInfo.DepartmentName",
  SSN_APPLICATION_INPUTCUSTOMERINFO_NAMEOFPERSONINCHARGE:
    "Application.InputCustomerInfo.NameOfPersonInCharge",
  SSN_APPLICATION_INPUTCUSTOMERINFO_NAMEOFPERSONINCHARGELASTNAME:
    "Application.InputCustomerInfo.NameOfPersonInChargeLastName",
  SSN_APPLICATION_INPUTCUSTOMERINFO_NAMEOFPERSONINCHARGEFIRSTNAME:
    "Application.InputCustomerInfo.NameOfPersonInChargeFirstName",
  SSN_APPLICATION_INPUTCUSTOMERINFO_NAMEOFPERSONINCHARGELASTNAMEFURIGANA:
    "Application.InputCustomerInfo.NameOfPersonInChargeLastNameFurigana",
  SSN_APPLICATION_INPUTCUSTOMERINFO_NAMEOFPERSONINCHARGEFIRSTNAMEFURIGANA:
    "Application.InputCustomerInfo.NameOfPersonInChargeFirstNameFurigana",
  SSN_APPLICATION_INPUTCUSTOMERINFO_NAMEOFPERSONINCHARGEINKANA:
    "Application.InputCustomerInfo.NameOfPersonInChargeInKana",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSCODE:
    "Application.InputCustomerInfo.ContactAddressCode",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSPOSTALCODE:
    "Application.InputCustomerInfo.ContactAddress-PostalCode",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSSTATE:
    "Application.InputCustomerInfo.ContactAddress-State",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSMUNICIPALITY:
    "Application.InputCustomerInfo.ContactAddress-Municipality",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSDISTRICT:
    "Application.InputCustomerInfo.ContactAddress-District",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSAPARTMENTNAME:
    "Application.InputCustomerInfo.ContactAddress-ApartmentName",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSAPARTMENTROOMNUMBER:
    "Application.InputCustomerInfo.ContactAddress-ApartmentRoomNumber",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSPHONENUMBER:
    "Application.InputCustomerInfo.ContactAddress-PhoneNumber",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSAREACODE:
    "Application.InputCustomerInfo.ContactAddress-AreaCode",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSLOCALTELEPHONENUMBER:
    "Application.InputCustomerInfo.ContactAddress-LocalTelephoneNumber",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSSUBSCRIBERNAME:
    "Application.InputCustomerInfo.ContactAddress-SubscriberNumber",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSMOBILENUMBER1:
    "Application.InputCustomerInfo.ContactAddress-MobileNumber1",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSMOBILENUMBER2:
    "Application.InputCustomerInfo.ContactAddress-MobileNumber2",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSMOBILENUMBER3:
    "Application.InputCustomerInfo.ContactAddress-MobileNumber3",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSPREFECTURE:
    "Application.InputCustomerInfo.ContactAddress-Prefecture",
  SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSCITY:
    "Application.InputCustomerInfo.ContactAddress-City",
  SSN_APPLICATION_INPUTCUSTOMERINFO_MYCOMMUFAID:
    "Application.InputCustomerInfo.MyCommufaId",
  SSN_APPLICATION_INPUTCUSTOMERINFO_ISAUTHENTICATEDEMAIL:
    "Application.InputCustomerInfo.IsAuthenticatedEmail",
  SSN_APPLICATION_INPUTCUSTOMERINFO_INDIVIDUALCONTRACTADDITIONALINFORMATION:
    "Application.InputCustomerInfo.IndividualContractAdditionalInformation",

  SSN_REPORT_APPLICATIONCANCEL_REASONOFCANCELLATIONLABEL:
    "Report.ApplicationCancel.ReasonOfCancellationLabel",
  SSN_REPORT_APPLICATIONLIST_APPLICATIONID:
    "Report.ApplicationList.ApplicationID",
  SSN_REPORT_APPLICATIONLIST_ORDERRECEIPTNUMBER:
    "Report.ApplicationList.OrderReceiptNumber",
  SSN_REPORT_APPLICATIONLIST_EFFECTIVEDATE:
    "Report.ApplicationList.EffectiveDate",
  SSN_REPORT_APPLICATIONLIST_ORDERSTATUS: "Report.ApplicationList.OrderStatus",
  SSN_REPORT_APPLICATIONLIST_ADDRESS: "Report.ApplicationList.Address",
  SSN_REPORT_APPLICATIONLIST_PLANNAME: "Report.ApplicationList.PlanName",

  SSN_APPLICATION_HANDLINGPERSONALINFO_AGREEMENTFLG:
    "Application.HandlingPersonalInfo.AgreementFlg",
  SSN_APPLICATION_HANDLINGPERSONALINFO_AGREEMENTDATETIME:
    "Application.HandlingPersonalInfo.AgreementDatetime",
  SSN_REPORT_CONTRACT_CONTRACTINFO_BILLINGID:
    "Report.Contract.ContractInfo.BillingId",

  // session information for apartment-search page.
  SSN_APPLICATION_APARTMENTSEARCH_APARTMENTNAME:
    "Application.ApartmentSearch.ApartmentName",
  SSN_APPLICATION_APARTMENTSEARCH_APARTMENTADDRESS:
    "Application.ApartmentSearch.ApartmentAddress",
  SSN_APPLICATION_APARTMENTSEARCH_ISPROVIDABLE:
    "Application.ApartmentSearch.IsProvidable",
  SSN_APPLICATION_APARTMENTSEARCH_PROVISIONSTATUS:
    "Application.ApartmentSearch.ProvisionStatus",
  SSN_APPLICATION_APARTMENTSEARCH_PROVISIONMETHOD:
    "Application.ApartmentSearch.ProvisionMethod",
  SSN_APPLICATION_APARTMENTSEARCH_NOTE: "Application.ApartmentSearch.Note",
  SSN_APPLICATION_APARTMENTSEARCH_ISAPARTMENTHOME:
    "Application.ApartmentSearch.IsApartmentHome",
  SSN_APPLICATION_APARTMENTSEARCH_ISALCMPROPERTY:
    "Application.ApartmentSearch.IsALCMProperty",
  SSN_APPLICATION_APARTMENTSEARCH_APARTMENTSTREETBUNCH:
    "Application.ApartmentSearch.ApartmentStreetBunch",
  SSN_APPLICATION_INPUTCUSTOMERINFO_STREETNUMBER:
    "Application.InputCustomerInfo.StreetNumber",
  SSN_APPLICATION_INPUTCUSTOMERINFO_BUILDINGNAME:
    "Application.InputCustomerInfo.BuildingName",
  SSN_APPLICATION_INPUTCUSTOMERINFO_EMAILADDRESS:
    "Application.InputCustomerInfo.EmailAddress",
  SSN_APPLICATION_INPUTCUSTOMERINFO_EMAILADDRESSDOMAIN:
    "Application.InputCustomerInfo.EmailAddressDomain",
  SSN_APPLICATION_INPUTCUSTOMERINFO_EMAILADDRESSFORCONFIRMATION:
    "Application.InputCustomerInfo.EmailAddressForConfirmation",
  SSN_APPLICATION_INPUTCUSTOMERINFO_EMAILADDRESSDOMAINFORCONFIRMATION:
    "Application.InputCustomerInfo.EmailAddressDomainForConfirmation",
  SSN_APPLICATION_INPUTCUSTOMERINFO_PREFERREDTRANSFERDATE:
    "Application.InputCustomerInfo.PreferredTransferDate",
  SSN_APPLICATION_INPUTCUSTOMERINFO_PREFERREDSTARTDATE:
    "Application.InputCustomerInfo.PreferredStartDate",
  SSN_COMMON_COMMON_CONTRACTINFO: "Common.Common.ContractInfo",
  SSN_APPLICATION_INPUTCUSTOMERINFO_AUTHCODE:
    "Application.InputCustomerInfo.AuthCode",

  SSN_APPLICATION_CPADHEARING_HEARINGINFO:
    "Application.cPadHearing.HearingInfo",
  SSN_APPLICATION_CPADHEARING_SELECTEDPROVIDERID:
    "Application.cPadHearing.SelectedProviderID",
  SSN_APPLICATION_CPADHEARING_SELECTEDPROVIDERNAME:
    "Application.cPadHearing.SelectedProviderName",

  SSN_APPLICATION_CPADPRICESIMULATION_ACCOUNTNAME:
    "Application.cPadPriceSimulation.AccountName",
  SSN_APPLICATION_CPADPRICESIMULATION_MICNO:
    "Application.cPadPriceSimulation.MICNo",
  SSN_APPLICATION_CPADPRICESIMULATION_ESTIMATEDUEDATE:
    "Application.cPadPriceSimulation.EstimateDueDate",
  SSN_APPLICATION_CPADPRICESIMULATION_STAFFNAME:
    "Application.cPadPriceSimulation.StaffName",
  SSN_APPLICATION_CPADPRICESIMULATION_PHONE:
    "Application.cPadPriceSimulation.Phone",
  SSN_APPLICATION_CPADPRICESIMULATION_AGENCYNAME:
    "Application.cPadPriceSimulation.AgencyName",
  SSN_APPLICATION_CPADPRICESIMULATION_AGENCYNUMBER:
    "Application.cPadPriceSimulation.AgencyNumber",
  SSN_APPLICATION_CPADPRICESIMULATION_STORENAME:
    "Application.cPadPriceSimulation.StoreName",
  SSN_APPLICATION_CPADPRICESIMULATION_SALESPERSONNAME:
    "Application.cPadPriceSimulation.SalesPersonName",
  SSN_APPLICATION_CPADPRICESIMULATION_DESCRIPTIONDATE:
    "Application.cPadPriceSimulation.DescriptionDate",
  SSN_APPLICATION_CPADPRICESIMULATION_AGENCYCONTACT:
    "Application.cPadPriceSimulation.AgencyContact",

  SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ACCOUNTNAME:
    "Application.cPadAgencyInfoConfirmation.AccountName",
  SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_SELECTEDSTOREID:
    "Application.cPadAgencyInfoConfirmation.SelectedStoreID",
  SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STORENAME:
    "Application.cPadAgencyInfoConfirmation.StoreName",
  SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STAFFNAME:
    "Application.cPadAgencyInfoConfirmation.StaffName",
  SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_MICNO:
    "Application.cPadAgencyInfoConfirmation.MICNo",
  SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ESTIMATEDUEDATE:
    "Application.cPadAgencyInfoConfirmation.EstimateDueDate",
  SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_DESCRIPTIONDATE:
    "Application.cPadAgencyInfoConfirmation.DescriptionDate",
  SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_PHONE:
    "Application.cPadAgencyInfoConfirmation.Phone",

  SSN_APPLICATION_CPADCHANGEDCONTENTCONFIRMATION_ESTIMATEDUEDATE:
    "Application.cPadChangedContentConfirmation.EstimateDueDate",

  SSN_APPLICATION_INPUTROOMNUMBER_ROOMNUMBER:
    "Application.InputRoomNumber.RoomNumber",

  SSN_APPLICATION_SELECTPREFERREDDATEOFCHANGINGPLAN_PREFERREDPLANCHANGEDATE:
    "Application.SelectPreferredDateOfChangingPlan.PreferredPlanChangeDate",

  SSN_APPLICATION_COMMONSELECTPREFERREDDATE_ISUPDATEADDREQUESTDATE:
    "Application.CommonSelectPreferredDate.IsUpdateAddRequestDate",

  SSN_APPLICATION_COMMONSELECTPREFERREDDATE_ISUPDATELASTUTILIZATIONDATE:
    "Application.CommonSelectPreferredDate.IsUpdateLastUtilizationDate",

  SSN_APPLICATION_COMMONSELECTPREFERREDDATE_PRODUCTCHANGEADDREQUESTDATE:
    "Application.CommonSelectPreferredDate.ProductChangeAddRequestDate",

  SSN_APPLICATION_COMMONSELECTPREFERREDDATE_PRODUCTCHANGELASTUTILIZATIONDATE:
    "Application.CommonSelectPreferredDate.ProductChangeLastUtilizationDate",

  SSN_APPLICATION_CREATEMYCOMMUFAID_AUTHCODE:
    "Application.CreateMyCommufaID.AuthCode",
  SSN_APPLICATION_CREATEMYCOMMUFAID_REGISTRATIONCHOICE:
    "Application.CreateMyCommufaID.RegistrationChoice",
  SSN_APPLICATION_CREATEMYCOMMUFAID_ISAUTHENTICATEDEMAIL:
    "Application.CreateMyCommufaID.IsAuthenticatedEmail",
  SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESS:
    "Application.CreateMyCommufaID.EmailAddress",
  SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSDOMAIN:
    "Application.CreateMyCommufaID.EmailAddressDomain",
  SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSFORCONFIRMATION:
    "Application.CreateMyCommufaID.EmailAddressForConfirmation",
  SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSDOMAINFORCONFIRMATION:
    "Application.CreateMyCommufaID.EmailAddressDomainForConfirmation",

  SSN_APPLICATION_RECONFIRMADDRESS_ADDRESSCODE:
    "Application.ReconfirmAddress.AddressCode",
  SSN_APPLICATION_RECONFIRMADDRESS_POSTALCODE:
    "Application.ReconfirmAddress.PostalCode",
  SSN_APPLICATION_RECONFIRMADDRESS_PREFECTURE:
    "Application.ReconfirmAddress.Prefecture",
  SSN_APPLICATION_RECONFIRMADDRESS_CITY: "Application.ReconfirmAddress.City",
  SSN_APPLICATION_RECONFIRMADDRESS_DISTRICT:
    "Application.ReconfirmAddress.District",
  SSN_APPLICATION_RECONFIRMADDRESS_STREET1:
    "Application.ReconfirmAddress.Street1",

  SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_ADDRESSCODE:
    "Application.HomeSearchResultEvaluation.AddressCode",
  SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_LONGITUDE_J:
    "Application.HomeSearchResultEvaluation.longitude_j",
  SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_LATITUDE_J:
    "Application.HomeSearchResultEvaluation.latitude_j",
  SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_LONGITUDE_W:
    "Application.HomeSearchResultEvaluation.longitude_w",
  SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_LATITUDE_W:
    "Application.HomeSearchResultEvaluation.latitude_w",
  SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_BLOCKNO:
    "Application.HomeSearchResultEvaluation.blockno",
  SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_SHARED:
    "Application.HomeSearchResultEvaluation.Shared",
  SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_TENGTEIKYO:
    "Application.HomeSearchResultEvaluation.tengteikyo",
  SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_MSGCODE:
    "Application.HomeSearchResultEvaluation.msgcode",
  SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_TVPROVIDE:
    "Application.HomeSearchResultEvaluation.tvprovide",
  SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_BROADCASTCODE:
    "Application.HomeSearchResultEvaluation.broadcastcode",
  SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_ADRCD:
    "Application.HomeSearchResultEvaluation.adrcd",

  SSN_APPLICATION_CONTRACTCANCEL_LASTUTILIZATIONDATE:
    "Application.ContractCancel.LastUtilizationDate",
  SSN_APPLICATION_CONTRACTCANCEL_TERMINATIONREASON:
    "Application.ContractCancel.TerminationReason",
  SSN_APPLICATION_CONTRACTCANCEL_TERMINATIONREASONOTHER:
    "Application.ContractCancel.TerminationReasonOther",
  SSN_APPLICATION_CONTRACTCANCEL_TERMINATIONREASONLABEL:
  "Application.ContractCancel.TerminationReasonLabel",

  SSN_APPLICATION_STORESESSIONDATA_OFFERPLANPRODUCTLIST:
    "Application.StoreSessionData.OfferPlanProductList",
  SSN_APPLICATION_STORESESSIONDATA_BENEFITLIST:
    "Application.StoreSessionData.BenefitList",

  SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESS:
    "Application.CreateMyCommufaID.EmailAddress",
  SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSDOMAIN:
    "Application.CreateMyCommufaID.EmailAddressDomain",
  SSN_APPLICATION_SIMPLEOP_ORDERINFO: "Application.SimpleOp.OrderInfo",
};
