

import { useHistory } from "react-router-dom";

export const useRedirectToPreviousPage = () => {
  const history = useHistory();

  return () => {
    history.goBack()
  };
}
