import { recoilKeys as keys } from "../../../assets/recoilKeys";
import {
  getSessionStorageItem,
} from "../../../utils/useSessionStorage";
import linq from 'linq'
import {
  filterSelectedSelectionProductAndMonthlyExpenseProduct,
  filterSelectedAutomaticSelectionProductAndMonthlyExpenseProduct,
  filterSelectedBenefitAndMonthlyExpenseProduct,
  filterSelectedSelectionProductAndAnnualExpenseProduct,
  filterSelectedAutomaticSelectionProductAndAnnualExpenseProduct,
  filterSelectedBenefitAndAnnualExpenseProduct,
  filterSelectedSelectionProductAndInitialCostProduct,
  filterSelectedAutomaticSelectionProductAndInitialCostProduct,
  filterSelectedBenefitAndInitialCostProduct,
  filterSelectedBenefitProduct,
  filterSelectedMonthlyExpenseProduct,
  filterSelectedMonthlyExpenseProductWithoutBenefit,
  calculateMonthlyPriceTaxInc,
  calculateMonthlyPriceTaxExc,
  calculateAnnualPriceTaxInc,
  calculateAnnualPriceTaxExc,
  calculateInitialCostTaxInc,
  calculateInitialCostTaxExc,
  calculateTotalPrice,
  createSubtotalObj,
  createBenefitSubObj
} from "../Common/CommonProcess"

// 月額料金
export const createMonthlyExpenseObj = () => {
  // SessionStorageから変更前と変更後のOrderRelatedInfoを読み込む。
  const before = getSessionStorageItem(keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO, true);
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更前と変更後のOrderRelatedInfoから対象の月額商品を抽出する。
  const beforeSelectionProduct = filterSelectedSelectionProductAndMonthlyExpenseProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const beforeAutomaticSelectionProduct = filterSelectedAutomaticSelectionProductAndMonthlyExpenseProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const beforeBenefitProduct = filterSelectedBenefitAndMonthlyExpenseProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterSelectionProduct = filterSelectedSelectionProductAndMonthlyExpenseProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterAutomaticSelectionProduct = filterSelectedAutomaticSelectionProductAndMonthlyExpenseProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterBenefitProduct = filterSelectedBenefitAndMonthlyExpenseProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 表示用オブジェクト_商品小計を作成する。
  const beforeSelectionProductSubtotalObj = createSubtotalObj(beforeSelectionProduct, calculateMonthlyPriceTaxExc, calculateMonthlyPriceTaxInc);
  const beforeAutomaticSelectionProductSubtotalObj = createSubtotalObj(beforeAutomaticSelectionProduct, calculateMonthlyPriceTaxExc, calculateMonthlyPriceTaxInc);
  const beforeBenefitProductSubtotalObj = createSubtotalObj(beforeBenefitProduct, calculateMonthlyPriceTaxExc, calculateMonthlyPriceTaxInc);
  const afterSelectionProductSubtotalObj = createSubtotalObj(afterSelectionProduct, calculateMonthlyPriceTaxExc, calculateMonthlyPriceTaxInc);
  const afterAutomaticSelectionProductSubtotalObj = createSubtotalObj(afterAutomaticSelectionProduct, calculateMonthlyPriceTaxExc, calculateMonthlyPriceTaxInc);
  const afterBenefitProductSubtotalObj = createSubtotalObj(afterBenefitProduct, calculateMonthlyPriceTaxExc, calculateMonthlyPriceTaxInc);

  // 表示用オブジェクト_合計値を作成する。
  const afterProduct = afterSelectionProduct.concat(afterAutomaticSelectionProduct, afterBenefitProduct);
  const totalObj = calculateTotalPrice(afterProduct, calculateMonthlyPriceTaxExc);

  return {
    totalObj,
    beforeSelectionProductSubtotalObj,
    beforeAutomaticSelectionProductSubtotalObj,
    beforeBenefitProductSubtotalObj,
    afterSelectionProductSubtotalObj,
    afterAutomaticSelectionProductSubtotalObj,
    afterBenefitProductSubtotalObj
  };
}

// 料金シミュレーション
export const createFeeSimulationObj = (afterFeeSimulationResponse) => {
  // SessionStorageから変更後のOrderRelatedInfoを読み込む。
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更後のOrderRelatedInfoから対象の月額商品を抽出する。
  const afterOrderInfos = filterSelectedMonthlyExpenseProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 表示用オブジェクトを作成する。
  const totalObj = createFeeSimulationTotalObj(afterOrderInfos, afterFeeSimulationResponse);

  return totalObj;
}

// キャンペーン適用シミュレーション
export const createCampaignAppliedSimulationObj = () => {
  // SessionStorageから変更後のOrderRelatedInfoを読み込む。
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更後のOrderRelatedInfoから対象の月額商品を抽出する。
  const applied = filterSelectedMonthlyExpenseProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const notApplied = filterSelectedMonthlyExpenseProductWithoutBenefit(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 合計金額を計算する。
  const appliedTotalObj = calculateTotalPrice(applied, calculateMonthlyPriceTaxExc);
  const notAppliedTotalObj = calculateTotalPrice(notApplied, calculateMonthlyPriceTaxExc);

  // 表示用オブジェクトを作成する。
  const totalObj = {
    appliedTotal: appliedTotalObj.PriceTaxInc,
    notAppliedTotal: notAppliedTotalObj.PriceTaxInc
  };

  return totalObj;
}

// 年額料金
export const createAnnualExpenseObj = () => {
  // SessionStorageから変更前と変更後のOrderRelatedInfoを読み込む。
  const before = getSessionStorageItem(keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO, true);
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更前と変更後のOrderRelatedInfoから対象の年額商品を抽出する。
  const beforeSelectionProduct = filterSelectedSelectionProductAndAnnualExpenseProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const beforeAutomaticSelectionProduct = filterSelectedAutomaticSelectionProductAndAnnualExpenseProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const beforeBenefitProduct = filterSelectedBenefitAndAnnualExpenseProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterSelectionProduct = filterSelectedSelectionProductAndAnnualExpenseProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterAutomaticSelectionProduct = filterSelectedAutomaticSelectionProductAndAnnualExpenseProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterBenefitProduct = filterSelectedBenefitAndAnnualExpenseProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 表示用オブジェクト_商品小計を作成する。
  const beforeSelectionProductSubtotalObj = createSubtotalObj(beforeSelectionProduct, calculateAnnualPriceTaxExc, calculateAnnualPriceTaxInc);
  const beforeAutomaticSelectionProductSubtotalObj = createSubtotalObj(beforeAutomaticSelectionProduct, calculateAnnualPriceTaxExc, calculateAnnualPriceTaxInc);
  const beforeBenefitProductSubtotalObj = createSubtotalObj(beforeBenefitProduct, calculateAnnualPriceTaxExc, calculateAnnualPriceTaxInc);
  const afterSelectionProductSubtotalObj = createSubtotalObj(afterSelectionProduct, calculateAnnualPriceTaxExc, calculateAnnualPriceTaxInc);
  const afterAutomaticSelectionProductSubtotalObj = createSubtotalObj(afterAutomaticSelectionProduct, calculateAnnualPriceTaxExc, calculateAnnualPriceTaxInc);
  const afterBenefitProductSubtotalObj = createSubtotalObj(afterBenefitProduct, calculateAnnualPriceTaxExc, calculateAnnualPriceTaxInc);

  // 表示用オブジェクト_合計値を作成する。
  const afterProduct = afterSelectionProduct.concat(afterAutomaticSelectionProduct, afterBenefitProduct);
  const totalObj = calculateTotalPrice(afterProduct, calculateAnnualPriceTaxExc);

  return {
    totalObj,
    beforeSelectionProductSubtotalObj,
    beforeAutomaticSelectionProductSubtotalObj,
    beforeBenefitProductSubtotalObj,
    afterSelectionProductSubtotalObj,
    afterAutomaticSelectionProductSubtotalObj,
    afterBenefitProductSubtotalObj
  };
}

// 初期費用
export const createInitialCostObj = () => {
  // SessionStorageから変更前と変更後のOrderRelatedInfoを読み込む。
  const before = getSessionStorageItem(keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO, true);
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更前と変更後のOrderRelatedInfoから対象の初期費用商品を抽出する。
  const beforeSelectionProduct = filterSelectedSelectionProductAndInitialCostProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const beforeAutomaticSelectionProduct = filterSelectedAutomaticSelectionProductAndInitialCostProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const beforeBenefitProduct = filterSelectedBenefitAndInitialCostProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterSelectionProduct = filterSelectedSelectionProductAndInitialCostProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterAutomaticSelectionProduct = filterSelectedAutomaticSelectionProductAndInitialCostProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterBenefitProduct = filterSelectedBenefitAndInitialCostProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 表示用オブジェクト_商品小計を作成する。
  const beforeSelectionProductSubtotalObj = createSubtotalObj(beforeSelectionProduct, calculateInitialCostTaxExc, calculateInitialCostTaxInc);
  const beforeAutomaticSelectionProductSubtotalObj = createSubtotalObj(beforeAutomaticSelectionProduct, calculateInitialCostTaxExc, calculateInitialCostTaxInc);
  const beforeBenefitProductSubtotalObj = createSubtotalObj(beforeBenefitProduct, calculateInitialCostTaxExc, calculateInitialCostTaxInc);
  const afterSelectionProductSubtotalObj = createSubtotalObj(afterSelectionProduct, calculateInitialCostTaxExc, calculateInitialCostTaxInc);
  const afterAutomaticSelectionProductSubtotalObj = createSubtotalObj(afterAutomaticSelectionProduct, calculateInitialCostTaxExc, calculateInitialCostTaxInc);
  const afterBenefitProductSubtotalObj = createSubtotalObj(afterBenefitProduct, calculateInitialCostTaxExc, calculateInitialCostTaxInc);

  // 表示用オブジェクト_合計値を作成する。
  const afterProduct = afterSelectionProduct.concat(afterAutomaticSelectionProduct, afterBenefitProduct);
  const totalObj = calculateTotalPrice(afterProduct, calculateInitialCostTaxExc);

  return {
    totalObj,
    beforeSelectionProductSubtotalObj,
    beforeAutomaticSelectionProductSubtotalObj,
    beforeBenefitProductSubtotalObj,
    afterSelectionProductSubtotalObj,
    afterAutomaticSelectionProductSubtotalObj,
    afterBenefitProductSubtotalObj
  };
}

// 特典
export const createBenefitObj = () => {
  // SessionStorageから変更前と変更後のOrderRelatedInfoを読み込む。
  const before = getSessionStorageItem(keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO, true);
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更前と変更後のOrderRelatedInfoから対象の特典商品を抽出する。
  const beforeBenefitProduct = filterSelectedBenefitProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterBenefitProduct = filterSelectedBenefitProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 表示用オブジェクトを作成する。
  const beforeBenefitObj = createBenefitSubObj(beforeBenefitProduct);
  const afterBenefitObj = createBenefitSubObj(afterBenefitProduct);

  return {
    beforeBenefitObj,
    afterBenefitObj
  };
}

const createFeeSimulationTotalObj = (base, afterFeeSimulationResponse) => {
  const afterOrderItemInfos = linq.from(afterFeeSimulationResponse)
    .select(x => {
      return {
        Product2Id: x?.orderItem?.Product2Id, // 商品
        simulatedUnitPriceTaxInc1: x?.simulationInfo?.simulatedUnitPriceTaxInc1, // シミュレーション金額1(税込)
        simulatedUnitPriceTaxInc2: x?.simulationInfo?.simulatedUnitPriceTaxInc2, // シミュレーション金額2(税込)
        simulatedUnitPriceTaxInc3: x?.simulationInfo?.simulatedUnitPriceTaxInc3 // シミュレーション金額3(税込)
      };
    }
    )
    .toArray();

  const tmp = linq.from(base)
    .select(x => x?.orderItem)
    .groupJoin(afterOrderItemInfos, // LeftOuterJoinする右側の配列
      x => x?.Product2Id, // 左側テーブルの結合キー：商品
      y => y?.Product2Id, // 右側テーブルの結合キー：商品
      (x, y) => ({
        ...x, // 左側テーブルの全項目
        y
      }))
    .selectMany(x => x?.y?.defaultIfEmpty(), // LeftOuterJoinの続き
      (x, y) => {
        delete x?.y;
        return {
          simulatedUnitPriceTaxInc1: x?.CMAP_Unchargeable__c === false ? y?.simulatedUnitPriceTaxInc1 : 0, // シミュレーション金額1(税込)
          simulatedUnitPriceTaxInc2: x?.CMAP_Unchargeable__c === false ? y?.simulatedUnitPriceTaxInc2 : 0, // シミュレーション金額2(税込)
          simulatedUnitPriceTaxInc3: x?.CMAP_Unchargeable__c === false ? y?.simulatedUnitPriceTaxInc3 : 0 // シミュレーション金額3(税込)
        }
      }
    )

  const result = {
    simulatedUnitPriceTaxInc1: tmp.sum(x => x?.simulatedUnitPriceTaxInc1) || 0,
    simulatedUnitPriceTaxInc2: tmp.sum(x => x?.simulatedUnitPriceTaxInc2) || 0,
    simulatedUnitPriceTaxInc3: tmp.sum(x => x?.simulatedUnitPriceTaxInc3) || 0
  }

  return result;
}