
import React, { useEffect } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import {
	getScDataFromPlaceholder,
	getScDataFromComponent,
	addDataRowList
} from "../../utils/commonUtils";
import {
	STRING_CREDIT_CARD,
	STRING_BANK_ACCOUNT_TRANSFER
} from "../../utils/constantVariables";
import { getSessionStorageItem } from "../../utils/useSessionStorage";
import moment from "moment";

export function BillingInformationConfirmation(props) {
	const {sitecoreContext, isMyCommufa, isCpad,orderRelatedInfo} = props;
	const pageContext = sitecoreContext.route.fields;

	const billingTo = orderRelatedInfo?.orderRelatedInfo?.billingTo;
	const paymentMethod = billingTo?.CMAP_PaymentMethod__c;

	const cardHolder = billingTo?.CMAP_CreditCardName__c;
	const creditCardNumberLastFourDigits = billingTo?.CMAP_CreditCardNumberLastFourDigits__c;
	const dateOfExpiry = billingTo?.CMAP_CreditCardExpirationDate__c;

	const bankName = billingTo?.CMAP_BankName__c;
	const bankNumberLastFourDigits = billingTo?.CMAP_AccountNumber__c?.slice(-4);

	const securityCode = getSessionStorageItem(
		keys.SSN_APPLICATION_INPUTBILLINGINFO_SECURITYCODE,
		true
	);
	const paymentMethodChangeCode = getSessionStorageItem(
		keys.SSN_APPLICATION_INPUTBILLINGINFO_PAYMENTMETHODCHANGECODE,
		true
	);

	const billingInformationSection = getScDataFromPlaceholder(
		"billing-information-section",
		props
	);
	const  [customerH02Data1] = getScDataFromComponent("H-02", billingInformationSection);
	customerH02Data1.fields.titleText = pageContext["BillingInfoDetailConfirmation-Title"];
	const [billingInfoL01Data] = getScDataFromComponent("L-01", billingInformationSection);

	useEffect(() => {
		let billingInfoL01DataList = [];
		const billingInfoDetailValue1 = paymentMethod;
		const billingInfoDetailValue2 = `••••••••••••${creditCardNumberLastFourDigits}`;
		const billingInfoDetailLabel1 = pageContext["BillingInfoDetailConfirmation-PaymentMethodLabel"]?.value;
		if (isMyCommufa) {
			if (paymentMethod === STRING_CREDIT_CARD) {
				const billingInfoDetailLabel2 = pageContext["BillingInfoDetailConfirmation-CardNumberLabel"]?.value;
				if (paymentMethodChangeCode === 0) {
					const billingInfoDetailLabel3 = pageContext["BillingInfoDetailConfirmation-CardNameLabel"]?.value;
					const billingInfoDetailLabel4 = pageContext["BillingInfoDetailConfirmation-DateOfExpiryLabel"]?.value;
					const billingInfoDetailLabel5 = pageContext["BillingInfoDetailConfirmation-SecurityCodeLabel"]?.value;
					const billingInfoDetailValue3 = `${cardHolder}`;
					const billingInfoDetailValue4 = moment(dateOfExpiry, "YYYY-MM-DD").format("MM/YY");
					const billingInfoDetailValue5 = `${securityCode}`;

					billingInfoL01DataList.push(
						addDataRowList(billingInfoDetailLabel1, billingInfoDetailValue1),
						addDataRowList(billingInfoDetailLabel2, billingInfoDetailValue2), 
						addDataRowList(billingInfoDetailLabel3, billingInfoDetailValue3), 
						addDataRowList(billingInfoDetailLabel4, billingInfoDetailValue4), 
						addDataRowList(billingInfoDetailLabel5, billingInfoDetailValue5),
					);
				}
				if (paymentMethodChangeCode === 1) {
					billingInfoL01DataList.push(
						addDataRowList(billingInfoDetailLabel1, billingInfoDetailValue1),
						addDataRowList(billingInfoDetailLabel2, billingInfoDetailValue2),
					);
				}
				billingInfoL01Data.fields.dataRowList = billingInfoL01DataList;
			}

			if (paymentMethod === STRING_BANK_ACCOUNT_TRANSFER) {
				if (paymentMethodChangeCode === 0) {
					billingInfoL01DataList.push(
						addDataRowList(billingInfoDetailLabel1, billingInfoDetailValue1),
					);
				}
				if (paymentMethodChangeCode === 1) {
					const billingInfoDetailLabel7 = pageContext["BillingInfoDetailConfirmation-BankAccountLabel"]?.value;
					const billingInfoDetailValue7 = `${bankName}***${bankNumberLastFourDigits}`;
					
					billingInfoL01DataList.push(
						addDataRowList(billingInfoDetailLabel1, billingInfoDetailValue1),
						addDataRowList(billingInfoDetailLabel7, billingInfoDetailValue7),
					);
				}
				billingInfoL01Data.fields.dataRowList = billingInfoL01DataList;
			}
		}

		if (isCpad) {
			billingInfoL01DataList.push(
				addDataRowList(billingInfoDetailLabel1, billingInfoDetailValue1),
			);
			billingInfoL01Data.fields.dataRowList = billingInfoL01DataList;
		}
	}, [isMyCommufa, isCpad])

	return (
		<>
			<div className="form_wrapper-input" name="billing-information-section">
				<Placeholder
					name="billing-information-section"
					rendering={props.rendering}
				/>
			</div>
			
			<div className="btn_container" name="button-third-section">
				<Placeholder
					name="button-third-section"
					rendering={props.rendering}
				/>
			</div>
		</>
	)
}