import React, { useState, useEffect } from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import {
  redirectToLinkUrl
  , getScDataFromPlaceholder
  , getScDataFromComponent
} from "../../utils/commonUtils";
import {
  STRING_LOADING,
  STRING_GET,
  STRING_ERROR_500_URI,
} from "../../utils/constantVariables";
import { apiBaseURL } from "../../envSettings";
import { useCustomQuery } from "../../hooks/useGetData";
import { di_register } from "../../utils/di";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { setSessionStorageItem } from "../../utils/useSessionStorage";

const SimpleOpApplicationCompletedLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  
    // Retrieve login state
    const retrieveLoginStateURI = `${apiBaseURL}common/RetrieveLoginStatus`;
    const {
      data: retrieveLoginStatusData,
      isLoading: retrieveLoginStatusLoading,
    } = useCustomQuery(retrieveLoginStateURI, true, STRING_GET);
    
    const retrieveServerSessionInfoURI = `${apiBaseURL}common/RetrieveServerSessionInfo`;
    const {
      data: serverSessionInfoData,
      isLoading: serverSessionInfoIsLoading,
      error: serverSessionInfoError,
      refetch: serverSessionInfoRefresh,
      remove: serverSessionInfoRemove,
    } = useCustomQuery(
      retrieveServerSessionInfoURI,
      false,
      STRING_GET
    );

    const isLoading =
        retrieveLoginStatusLoading === STRING_LOADING || 
        serverSessionInfoIsLoading === STRING_LOADING;

    props.setIsLoading(isLoading);

  const buttonSection = getScDataFromPlaceholder("return-button-section", props);
  const [backButton] = getScDataFromComponent("B-02", buttonSection);

  backButton.fields.onClickEvent = () => {
    const pageFields = sitecoreContext?.route?.fields;
    redirectToLinkUrl(pageFields["SimpleOpApplicationCompleted-BackButtonLink"]);
  }

  useEffect(() => {
    if (retrieveLoginStatusData !== undefined) {
      if (!retrieveLoginStatusData?.Result) {
          window.location.href = STRING_ERROR_500_URI;
      }
      else {
        serverSessionInfoRemove();
        serverSessionInfoRefresh();
      }
    }
}, [retrieveLoginStatusData]);

useEffect(() => {
  if (serverSessionInfoData) {
    setSessionStorageItem(
      sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO, 
      serverSessionInfoData, 
      true
    );
  }
}, [serverSessionInfoData]);

  return (
    <>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <Placeholder name="title-section" rendering={props.rendering} />
              <div className="form_wrapper-input">
                <Placeholder name="information-section" rendering={props.rendering} />
              </div>
              <div>

              </div>
              <div className="btn_container">
                <Placeholder name="return-button-section" rendering={props.rendering} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default withSitecoreContext()(SimpleOpApplicationCompletedLayout);
