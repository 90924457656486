export const query = `query {
  SimpleOpApplicationCustomerInfo: item(path: "/sitecore/content/jss/masterjss/Data/SimpleOpApplicationCustomerInfo") {
    children {
        sortOrder : field(name : "__sortOrder") {
          value
        }
        fields(excludeStandardFields: true) {
          name
          value
          ...on LookupField {
          targetItem {
            Value: field(name: "Value") {
              value
            }
            Description: field(name: "Description") {
              value
            }
          }
        }
      }
    }
  }
}`;
