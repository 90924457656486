
import React, { useEffect } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
  addDataRowList
} from "../../utils/commonUtils";
import {
    STRING_ORDER_CHANNEL_APPLIANCE,
    STRING_ORDER_CHANNEL_AUSHOP
} from "../../utils/constantVariables";
import { getSessionStorageItem } from "../../utils/useSessionStorage";

export function AgencyInfoConfirmation(props) {
	const {sitecoreContext, isCpad, orderRelatedInfo} = props;
	const pageContext = sitecoreContext.route.fields;
	const orderInfo = orderRelatedInfo?.orderRelatedInfo?.orderInfo;
	const orderChannelCategory = orderInfo?.orderAddition?.orderChannelCategory;
	const acquisitionShop = orderInfo?.order.CMAP_AcquisitionShop__c;
	const storeBoothCode = orderInfo?.order.CMAP_StoreBoothCode__c;

	const personalizeInfo = getSessionStorageItem(
		keys.SSN_COMMON_COMMON_PERSONALIZEINFO,
		true
	);
	const accountName = personalizeInfo?.AccountName;
	const staffName = personalizeInfo?.StaffName;

	const agencyInformationSection = getScDataFromPlaceholder(
		"agency-information-section",
		props
	);
	const [agencyInfoLo1Data] = getScDataFromComponent("L-01", agencyInformationSection);
	const [agencyInfoH02Data] = getScDataFromComponent("H-02", agencyInformationSection);

	useEffect(() => {
		if (isCpad) {
			let dataRowList = [];
			agencyInfoH02Data.fields.titleText = pageContext["AgencyInfoConfirmation-Title"];
			let storeNameValue = "", storeBoothCodeValue = "";
			const agencyNameLabel = pageContext["AgencyInfoConfirmation-AgencyName"]?.value;
			const storeNameLabel = pageContext["AgencyInfoConfirmation-StoreName"]?.value;
			const storeBoothCodeLabel = pageContext["AgencyInfoConfirmation-StoreBoothCode"]?.value;
			const staffNameLabel = pageContext["AgencyInfoConfirmation-ContactPersonName"]?.value;
			const agencyNameValue = `${accountName}`;
			const staffNameValue = `${staffName}`;
			
			if (orderChannelCategory === STRING_ORDER_CHANNEL_APPLIANCE || orderChannelCategory === STRING_ORDER_CHANNEL_AUSHOP) {
				storeNameValue = `${acquisitionShop}`;
				storeBoothCodeValue = `${storeBoothCode}`;
				dataRowList.push(
					addDataRowList(agencyNameLabel, agencyNameValue),
					addDataRowList(storeNameLabel, storeNameValue),
					addDataRowList(storeBoothCodeLabel, storeBoothCodeValue),
					addDataRowList(staffNameLabel, staffNameValue),
				);
			}
			else {
				dataRowList.push(
					addDataRowList(agencyNameLabel, agencyNameValue),
					addDataRowList(staffNameLabel, staffNameValue),
				);
			}
			agencyInfoLo1Data.fields.dataRowList = dataRowList;
		}
	}, [isCpad])

	return (
		<>
			{isCpad &&
				<div className="form_wrapper-input" name="agency-information-section">
					<Placeholder
						name="agency-information-section"
						rendering={props.rendering}
					/>
				</div>
			}
		</>
	)
}