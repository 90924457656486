import React, { useRef, useState, useContext } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import ModalContext from "../../assets/context/ModalContext";
import { fillBlank, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const PS01 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);

  const { eraserIcon, eraserLabel } = properData;

  const { dataTargetValue, buttonText } = props.fields;
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const sigPadRef = useRef(null);
  const { dispatch } = useContext(ModalContext);

  const clearCanvas = () => {
    sigPadRef.current.clear();
    setTrimmedDataURL(null);
  };

  const trim = () => {
    const trimmedDataUrl = sigPadRef.current
      .getSignaturePad()
      .toDataURL("image/png");
    setTrimmedDataURL(trimmedDataUrl);

    clearCanvas();

    dispatch({ type: "CANVAS_IMG", data: trimmedDataUrl });
  };

  return (
    <React.Fragment>
      <div className="PS-01">
        <div className="form-wrapper">
          <div className="canvas-wrapper">
            {trimmedDataURL && <img alt="signature" src={trimmedDataURL} />}
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: "865",
                height: "303",
                margin: "0 auto",
                className: "sigCanvas",
              }}
              clearOnResize
              backgroundColor="transparent"
              ref={sigPadRef}
            />
            <button className="clear-btn" type="button" onClick={clearCanvas}>
              {eraserIcon?.value && (
                <img src={fillBlank(eraserIcon)?.value?.src} />
              )}
              <Text field={fillBlank(eraserLabel)} />
            </button>
          </div>

          <div className="btn_wrapper primary B-01">
            <button
              className="btn btn_default"
              type="button"
              data-toggle="modal"
              data-target={`#${dataTargetValue?.value}`}
              onClick={trim}
            >
              <Text field={fillBlank(buttonText)} />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PS01;
