import React, { useEffect, useState } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { useSetSelectedItem } from "../../hooks/useSelectedState";
import { fillBlank, nullChecker, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";
import {
  STRING_QUANTITY_LABEL,
  STRING_QUANTITY_INPUT,
  STRING_UNIT_QUANTITY,
  STRING_CHECKBOX,
  STRING_RADIO,
  STRING_BULLET,
  STRING_LUMPSUMINSTALLMENTPAYMENT,
  STRING_MONTHLYANNUALPAYMENT,
  STRING_INSTALLMENTPAYMENT,
  STRING_ANNUALPAYMENT,
  STRING_SINGLE,
  STRING_PRODUCT_SET,
  STRING_S17_ERROR_MESSAGE,
  STRING_BEFORECHANGE_SELECTED,
  STRING_BEFORECHANGE_CHECKBOX,
} from "../../utils/constantVariables";

const S17 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);

  const { id, selections, minimumSelectionValue, maximumSelectionValue } = properData;
  let this_category_name = nullChecker(properData?.category)?.value;
  const [_, selectedItemSetter] = useSetSelectedItem(this_category_name);

  const [selectionData, setSelectionData] = useState(selections);
  useEffect(() => {
    if (selections) {
      setSelectionData(selections);
    }
  }, [selections]);

  function handleParentInstallationChange(event, product, value, parentId) {
    if (!parentId) {
      props.setIsChildSelected({
        name: product.id + "_rdQ2S17-1",
        value: !value,
      });
    } else {
      const newSelectionData = selectionData.find((data) => data.id === parentId);
      const index = newSelectionData.productSet.findIndex((set) => set.id === product.id);
      newSelectionData.productSet[index].s17child.isSelected = value;
      setSelectionData(selectionData.map((obj) => (obj.id === id ? { ...obj, ...newSelectionData } : obj)));
    }

    props.setIsChildInstallationChanged(true);
    if (props.handleOnChangeInstallment) {
      props.handleOnChangeInstallment(event, product, this_category_name, parentId);
    }
  }

  const handleParentProductEventChange = (event, item) => {
    const { priceCategory, priceCategoryDefault } = item;

    props.setIsChildInstallationChanged(false);

    let newVal = {};
    newVal[this_category_name] = event.target.value;
    selectedItemSetter(newVal);

    // reformat s17

    let newSelectionData = selectionData;

    newSelectionData.map((data) => {
      if (event.target.type === STRING_CHECKBOX) {
        data.isSelected = event.target.checked;

        if (data.isSelected) {
          data.s17child.isDisabled = false;

          switch (priceCategory) {
            case STRING_LUMPSUMINSTALLMENTPAYMENT:
              if (priceCategoryDefault === STRING_INSTALLMENTPAYMENT) {
                data.s17child.isSelected = true;
              }
              break;
            case STRING_MONTHLYANNUALPAYMENT:
              if (priceCategoryDefault === STRING_ANNUALPAYMENT) {
                data.s17child.isSelected = true;
              }
              break;
            default:
              data.s17child.isSelected = false;
              break;
          }
        } else {
          data.s17child.isDisabled = true;
          data.s17child.isSelected = false;
        }
      }
      if (event.target.type === STRING_RADIO) {
        if (data.id === event.target.value) {
          data.isSelected = true;
          data.s17child.isDisabled = false;
          switch (priceCategory) {
            case STRING_LUMPSUMINSTALLMENTPAYMENT:
              if (priceCategoryDefault === STRING_INSTALLMENTPAYMENT) {
                data.s17child.isSelected = true;
              }
              break;
            case STRING_MONTHLYANNUALPAYMENT:
              if (priceCategoryDefault === STRING_ANNUALPAYMENT) {
                data.s17child.isSelected = true;
              }
              break;
            default:
              data.s17child.isSelected = false;
              break;
          }
        } else {
          data.isSelected = false;
          data.s17child.isDisabled = true;
          data.s17child.isSelected = false;
        }
      }

      // FOR CHILD
      data.showErrorMessage = false;

      if (event.target.value === data.id) {
        let sumOfProductSetSelected = 0;
        data.productSet.map((itemSet) => {
          itemSet.isSelected = true;
          if (itemSet.productSelection !== STRING_BULLET) {
            sumOfProductSetSelected += itemSet?.productPriceLabel?.value;
          }
        });

        if (sumOfProductSetSelected > data?.productPriceLabel?.value) {
          data.showErrorMessage = true;
        }
      }
    });

    setSelectionData(newSelectionData);

    if (properData.onChangeParentEvent) {
      properData.onChangeParentEvent(event, item, this_category_name);
    }
  };

  const handleChildProductEventChange = (event, item, parentId) => {
    const { priceCategory, priceCategoryDefault } = item;
    const newSelectionData = selectionData.filter((data) => data.id === parentId);

    newSelectionData.map((data) => {
      data.showErrorMessage = false;
      let sumOfProductSetSelected = 0;

      data.productSet.map((itemSet) => {
        if (event.target.value === itemSet.id) {
          itemSet.isSelected = !itemSet.isSelected;
        }
        if (itemSet.isSelected) {
          sumOfProductSetSelected += itemSet?.productPriceLabel?.value;

          itemSet.s17child.isDisabled = false;
          switch (priceCategory) {
            case STRING_LUMPSUMINSTALLMENTPAYMENT:
              if (priceCategoryDefault === STRING_INSTALLMENTPAYMENT) {
                itemSet.s17child.isSelected = true;
              }
              break;
            case STRING_MONTHLYANNUALPAYMENT:
              if (priceCategoryDefault === STRING_ANNUALPAYMENT) {
                itemSet.s17child.isSelected = true;
              }
              break;
            default:
              itemSet.s17child.isSelected = false;
              break;
          }
        } else {
          itemSet.s17child.isDisabled = true;
          itemSet.s17child.isSelected = false;
        }
      });

      if (sumOfProductSetSelected > data?.productPriceLabel?.value) {
        data.showErrorMessage = true;
      }
    });

    const updatedSelectionData = selectionData.map((obj) =>
      obj.id === parentId ? { ...obj, productSet: newSelectionData[0].productSet } : obj
    );

    if (props.handleOnChangeProductSet) {
      props.handleOnChangeProductSet(event, item, this_category_name, parentId);
    }
    setSelectionData(updatedSelectionData);
  };

  const handleQuantityChange = (event, item, parentId) => {
    const id = parentId ? parentId : item.id;
    const newSelectionData = selectionData.find((data) => data.id === id);
    if (parentId) {
      const index = newSelectionData.productSet.findIndex((set) => set.id === item.id);
      newSelectionData.productSet[index].quantityLabel.value = event.target.value;
    } else {
      newSelectionData.quantityLabel.value = event.target.value;
    }

    setSelectionData(selectionData.map((obj) => (obj.id === id ? { ...obj, ...newSelectionData } : obj)));
    if (props.handleOnChangeQuantity) {
      props.handleOnChangeQuantity(event.target.value, item, this_category_name, parentId);
    }
  };

  const handlePriceChange = (event, item, parentId) => {
    const id = parentId ? parentId : item.id;
    const value = parseFloat(event.target.value.replace(",", ""));
    const newSelectionData = selectionData.find((data) => data.id === id);
    if (parentId) {
      const index = newSelectionData.productSet.findIndex((set) => set.id === item.id);
      newSelectionData.productSet[index].productPriceLabel.value = value;
      const total = newSelectionData.productSet.reduce((sum, set) => {
        if (set.productSelection !== STRING_BULLET && set.isSelected) {
          sum += set?.productPriceLabel?.value;
        }
        return sum;
      }, 0);

      if (total > newSelectionData?.productPriceLabel?.value) {
        newSelectionData.showErrorMessage = true;
      } else {
        newSelectionData.showErrorMessage = false;
      }
    } else {
      newSelectionData.productPriceLabel.value = value;
    }

    setSelectionData(selectionData.map((obj) => (obj.id === id ? { ...obj, ...newSelectionData } : obj)));
    if (props.handleOnChangePrice) {
      props.handleOnChangePrice(value, item, this_category_name, parentId);
    }
  };

  const handleMovementChange = (event, item, parentId) => {
    const id = parentId ? parentId : item.id;
    const checked = event.target.checked;
    const newSelectionData = selectionData.find((data) => data.id === id);
    if (parentId) {
      const index = newSelectionData.productSet.findIndex((set) => set.id === item.id);
      newSelectionData.productSet[index].beforeChange.checkboxValue = checked;
    } else {
      newSelectionData.beforeChange.checkboxValue = checked;
    }

    setSelectionData(selectionData.map((obj) => (obj.id === id ? { ...obj, ...newSelectionData } : obj)));
    if (props.handleOnChangeMovement) {
      props.handleOnChangeMovement(checked, item, this_category_name, parentId);
    }
  };

  const createMovementCheckbox = (item, parentId) => {
    const key = item.id + "_movement_" + parentId;
    return (
      <>
        {item?.beforeChange?.show && item?.beforeChange?.checkboxShow && (
          <div className="checkbox movement">
            <div className="input-form S-17-1">
              <div className="input-box single-type remove-padding">
                <div className="input-container">
                  <input
                    id={key}
                    type="checkbox"
                    name={key}
                    value={key}
                    checked={item.beforeChange.checkboxValue}
                    className={item.isDisabled ? "disabled" : ""}
                    disabled={item.isDisabled}
                    onChange={(event) => handleMovementChange(event, item, parentId)}
                  />
                  <label htmlFor={key}>
                    <dl className="left">
                      <dt>{STRING_BEFORECHANGE_CHECKBOX}</dt>
                    </dl>
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const createQuantityOpts = (id) => {
    const options = [];
    for (let min = minimumSelectionValue; min <= maximumSelectionValue; min++) {
      options.push(<option key={id + "_quality_" + min}>{min}</option>);
    }
    return options;
  };

  const createProduct = (item, identifier, parentId) => {
    const key = parentId ? `${parentId}_${item.id}_child` : item.id;
    return (
      <div key={key} className={identifier === STRING_PRODUCT_SET ? identifier : ""}>
        {item?.beforeChange?.show && (
          <div className="tag">
            <span>{STRING_BEFORECHANGE_SELECTED}</span>
          </div>
        )}
        <div className="input-container">
          {item.productSelection !== STRING_BULLET && identifier !== STRING_PRODUCT_SET && (
            <input
              id={item.id}
              type={item.productSelection}
              name={this_category_name}
              value={item.id}
              checked={
                item.isDisabledBasedOnDependencyForeignProductId
                  ? !item.isDisabledBasedOnDependencyForeignProductId
                  : item.isSelected
              }
              className={
                item.isDisabledBasedOnDependencyForeignProductId ? "disabled" : item.isDisabled ? "disabled" : ""
              }
              disabled={
                item.isDisabledBasedOnDependencyForeignProductId
                  ? item.isDisabledBasedOnDependencyForeignProductId
                  : item.isDisabled
              }
              onChange={(event) => {
                handleParentProductEventChange(event, item);
              }}
            />
          )}

          {item.productSelection !== STRING_BULLET && identifier === STRING_PRODUCT_SET && (
            <input
              id={item.id}
              type={item.productSelection}
              name={item.id}
              value={item.id}
              checked={item.isSelected}
              className={item.isDisabled ? "disabled" : ""}
              disabled={item.isDisabled}
              onChange={(event) => handleChildProductEventChange(event, item, parentId)}
            />
          )}

          <label htmlFor={identifier !== STRING_PRODUCT_SET ? this_category_name : item.id}>
            <dl>
              <dt>
                {item.productSelection === STRING_BULLET && (
                  <ul>
                    <li>
                      <Text field={fillBlank(item.productName)} />
                    </li>
                  </ul>
                )}
                {item.productSelection !== STRING_BULLET && <Text field={fillBlank(item.productName)} />}
              </dt>
              {item?.tooltipText && (
                <dd className="tool-tip">
                  <i className="bi bi-info-circle-fill"></i>
                  <p className="information">
                    <Text field={fillBlank(item.tooltipText)} />
                  </p>
                </dd>
              )}
            </dl>
            {item.productSelection !== STRING_BULLET && (
              <span className="right absolute-price">
                {item?.productPriceType && <span className="price-label">{item.productPriceType}</span>}
                {item?.productPriceLabel?.value && (
                  <p className="price">
                    {!item?.productPriceLabel?.changeable && item.productPriceLabel.value}

                    {item?.productPriceLabel?.changeable && (
                      <input
                        name={id}
                        type="text"
                        value={item.productPriceLabel.value}
                        className={`small ${!item.isSelected ? "disabled" : ""}`}
                        disabled={!item.isSelected}
                        onChange={(event) => handlePriceChange(event, item, parentId)}
                      />
                    )}
                  </p>
                )}

                <span className="price-label">
                  {item?.quantityLabel?.value &&
                    item?.quantityLabel?.unit &&
                    STRING_UNIT_QUANTITY + " " + item.quantityLabel.unit}

                  {(!item?.quantityLabel?.value || !item?.quantityLabel?.unit) &&
                    item?.productPriceUnitLabel &&
                    item.productPriceUnitLabel}
                </span>
              </span>
            )}
          </label>
        </div>

        <div className={`${item.productSelection === STRING_CHECKBOX ? STRING_CHECKBOX : STRING_RADIO} `}>
          {item?.externalLink && (
            <div className={`input-form link_wrapper`}>
              <a href={item.externalLinkUrl}>
                <span>
                  <Text field={fillBlank(item.externalLink)} />
                </span>
              </a>
            </div>
          )}
          {item?.precautionText && (
            <p className="description-text">
              <Text field={fillBlank(item.precautionText)} />
            </p>
          )}

          {item?.beforeChange?.show && item?.beforeChange?.cancelText && (
            <p className="description-text">
              <Text field={fillBlank(item.beforeChange?.cancelText)} />
            </p>
          )}
          {item?.beforeChange?.show && item?.beforeChange?.terminationText && (
            <p className="description-text">
              <Text field={fillBlank(item.beforeChange?.terminationText)} />
            </p>
          )}

          {item?.productImage && (
            <div className="benefits-image">
              <img src={item.productImage} alt={item.productImageAlt} />
            </div>
          )}
        </div>

        {/* S17 CHILD */}
        {!item.s17child.isHidden && (
          <div className={`S-17-child ${item.productSelection === STRING_CHECKBOX ? STRING_CHECKBOX : STRING_RADIO} `}>
            <div className={`input-form S-17-1`}>
              <div
                className={`input-box single-type ${
                  item.productSelection === STRING_CHECKBOX ? "remove-padding" : "padding-indent-left"
                }`}
              >
                <div className="input-container">
                  <input
                    id={`${item.id}_rdQ2S17-1`}
                    type="checkbox"
                    name={`${item.id}_rdQ2S17-1`}
                    checked={
                      props.isChildInstallationChanged
                        ? props.isChildSelected?.name === `${item.id}_rdQ2S17-1`
                          ? props.isChildSelected?.value
                          : item.s17child.isSelected
                        : item.s17child.isSelected
                    }
                    className={item.s17child.isDisabled ? "disabled" : ""}
                    disabled={item.s17child.isDisabled}
                    onChange={(event) =>
                      handleParentInstallationChange(
                        event,
                        item,
                        props.isChildInstallationChanged ? props.isChildSelected?.value : item.s17child.isSelected,
                        parentId
                      )
                    }
                  />
                  <label htmlFor={`${item.id}_rdQ2S17-1`}>
                    <dl className="left">
                      <dt>{item?.s17child?.paymentLabel && <Text field={fillBlank(item.s17child.paymentLabel)} />}</dt>
                    </dl>
                    <span className="right">
                      {item?.s17child?.paymentPriceType && (
                        <span className="price-label">
                          <Text field={fillBlank(item.s17child.paymentPriceType)} />
                        </span>
                      )}
                      {item?.s17child?.paymentPriceLabel && <p className="price">{item.s17child.paymentPriceLabel}</p>}
                      {item?.s17child?.paymentPriceUnitLabel && (
                        <span className="price-label">
                          <Text field={fillBlank(item?.s17child?.paymentPriceUnitLabel)} />
                        </span>
                      )}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}

        {item?.quantityLabel?.value && (
          <div
            className={`${item.productSelection === STRING_CHECKBOX ? STRING_CHECKBOX : STRING_RADIO} quantity-input`}
          >
            <div className="input-form S-17-dropdown">
              <div className={`input-container`}>
                {item?.quantityLabel?.numerical_input === STRING_QUANTITY_INPUT && (
                  <div className="pull-down">
                    <select
                      id="S17-1"
                      name="S17-1"
                      className="small"
                      disabled={!item.isSelected}
                      value={item.quantityLabel.value}
                      onChange={(event) => handleQuantityChange(event, item, parentId)}
                    >
                      {createQuantityOpts(item.id)}
                    </select>
                  </div>
                )}
                {item?.quantityLabel?.numerical_input === STRING_QUANTITY_LABEL && (
                  <span>{item.quantityLabel.value}</span>
                )}

                <span>{item?.quantityLabel?.unit}</span>
              </div>
            </div>
          </div>
        )}

        {identifier === STRING_PRODUCT_SET && createMovementCheckbox(item, parentId)}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={`input-form S-17 with-image`}>
        {selectionData?.map((item, i) => (
          <div
            key={item.id + "_" + i}
            className={`input-box ${item.productSelection === STRING_CHECKBOX ? "single-type" : "radio-type"} ${
              item.isDisabled ? "disabled" : ""
            } ${item.isSelected ? "active" : ""} 
            `}
          >
            {createProduct(item, STRING_SINGLE)}

            {item.productSet.length > 0 && (
              <div className="product-sets">
                {item.productSet.map((itemSet) => createProduct(itemSet, STRING_PRODUCT_SET, item.id))}
              </div>
            )}

            {createMovementCheckbox(item)}

            {!item.isSelected && item.showErrorMessage && (
              <div className="error-message">
                <h4>{STRING_S17_ERROR_MESSAGE}</h4>
              </div>
            )}
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default S17;
