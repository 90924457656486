import React from "react";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import {
  getProperData,
  fillBlank,
  nullChecker,
  preventDoubleClick,
} from "../../utils/commonUtils";

// @Model

// get the data

// update the context

// @Controller

// @View

const C04 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    titleText,
    countText,
    textAreaLabel,
    explanationText,
    buttonLabel,
    additionalClass,
  } = properData;

  const itemList = nullChecker(properData?.itemList, []);

  const handleButtonClick = preventDoubleClick(
    async (item, myClickEvent = undefined) => {
      if (myClickEvent) {
        myClickEvent(item);
        return false;
      }
    }
  );

  return (
    <React.Fragment>
      {/* TODO: 
        - add no-buttons class to hide the buttons on each li 
        - add no-number class to hide the number on each li*/}
      {!properData.isHidden && (
        <div
          className={`decoration-content procedure C-04 ${additionalClass?.value}`}
        >
          <div className="content-title big">
            <h5>
              <Text field={fillBlank(titleText)} />
            </h5>
          </div>
          <div className="information">
            <ul>
              {itemList?.length > 0 ? (
                itemList.map((item) => (
                  <li key={`item_list_${item.countText}`}>
                    {item.isShowNumber && (
                      <span className="count">
                        <Text field={fillBlank(item.countText)} />
                      </span>
                    )}
                    <span className={`${!item.buttonLabel && "full-width"}`}>
                      <h6>
                        <Text field={fillBlank(item.textAreaLabel)} />
                      </h6>
                      <RichText field={fillBlank(item.explanationText)} />
                    </span>
                    {item.buttonLabel && (
                      <div className="btn_wrapper primary">
                        <button
                          className="btn btn_default btn_txt"
                          type="button"
                          onClick={() =>
                            handleButtonClick(item, properData?.myClickEvent)
                          }
                        >
                          <Text field={fillBlank(item.buttonLabel)} />
                        </button>
                      </div>
                    )}
                  </li>
                ))
              ) : (
                <li>
                  <span className="count">
                    <Text field={fillBlank(countText)} />
                  </span>
                  <span>
                    <h6>
                      <Text field={fillBlank(textAreaLabel)} />
                    </h6>
                    <p>
                      <Text field={fillBlank(explanationText)} />
                    </p>
                  </span>
                  <div className="btn_wrapper primary">
                    <button className="btn btn_default btn_txt" type="button">
                      <Text field={fillBlank(buttonLabel)} />
                    </button>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default C04;
