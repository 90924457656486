import React, { useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";

import { di_fetch } from "../../utils/di";
import {
  getProperData,
  fillBlank,
  redirect,
} from "../../utils/commonUtils";
import {
  getSessionStorageItem,
} from "../../utils/useSessionStorage";
import {
  STRING_GET,
  STRING_LOGIN_URL
} from "../../utils/constantVariables";

import { useCustomQuery } from "../../hooks/useGetData";
import { apiBaseURL } from "../../envSettings";

const HD03 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);

  // get the data
  const personalizeInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );

  // @Model
  const accountName = personalizeInfo?.AccountName;
  const staffName = personalizeInfo?.StaffName;

  // call api
  const {
    data: retrieveLoginStatusData,
    isLoading: isRetrieveLoginStatusLoading,
  } = useCustomQuery(
    `${apiBaseURL}common/RetrieveLoginStatus`,
    true,
    STRING_GET
  );

  useEffect(() => {
    if (retrieveLoginStatusData) {
      if (!retrieveLoginStatusData?.Result) {
        // force login in cpad
        redirect(STRING_LOGIN_URL);
      }
    }
  }, [retrieveLoginStatusData]);

  // @View
  return (
    <React.Fragment>
      <header>
        <div className="header cpad HD-03">
          <div className="header_pos">
            <h1 className="header_pos-left">
              {properData &&
                properData["cPadHeader-HeaderLogoImage"]?.value?.src && (
                  <a
                    href={
                      properData &&
                      properData["cPadHeader-HeaderLogoLink"]?.value?.href
                    }
                  >
                    <img
                      src={
                        properData &&
                        properData["cPadHeader-HeaderLogoImage"]?.value?.src
                      }
                      alt="コミュファ powered by ctc"
                    />
                  </a>
                )}
            </h1>
            <div className="header_pos-right">
              <ul>
                <li>
                  <span>
                    {(properData &&
                      properData["cPadHeader-GreetingText"]?.value) ||
                      ""}
                  </span>
                </li>
                <li>
                  <span>
                    <Text field={fillBlank(accountName)} />
                  </span>
                </li>
                <li>
                  <span>
                    <Text field={fillBlank(staffName)} />
                  </span>
                </li>
                <li>
                  <span>
                    <p>
                      {(properData &&
                        properData["cPadHeader-TitleText"]?.value) ||
                        ""}
                    </p>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default HD03;
