import React, { useEffect, useMemo, useContext } from "react";
import _ from "lodash";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from "../../utils/commonUtils";
import ModalContext from "../../assets/context/ModalContext";
import {
  STRING_SPECIAL_NOTES,
  STRING_BROADCASTING_CODE_CATEGORY,
} from "../../utils/constantVariables";

const ProvidedChannelsListModalLayout = (props) => {
  // @Model
  const { state } = useContext(ModalContext);
  const { channelByBroadcastingCodes: channelList } = state;

  const categorizeDataByCategory = useMemo(() => {
    return channelList.channelByBroadcastingCodes
      ? _.groupBy(
          channelList.channelByBroadcastingCodes,
          STRING_BROADCASTING_CODE_CATEGORY
        )
      : {};
  }, [channelList]);

  const ChannelListItem = ({ category, item, index }) => (
    <li className="channel-list" key={`${category}_${index}`}>
      {item.CMAP_ChannelNumber__c && (
        <span>
          {fillBlank({ value: `ch ${item.CMAP_ChannelNumber__c}` }).value}
        </span>
      )}
      {item.CMAP_ChannelName__c && (
        <p>{fillBlank({ value: item.CMAP_ChannelName__c }).value}</p>
      )}
    </li>
  );

  const ChannelListDescription = ({ item }) =>
    item.CMAP_SpecialNotes__c &&
    item.CMAP_SpecialNotesContent__c && (
      <p className="mt-0">
        {
          fillBlank({
            value: `${item.CMAP_SpecialNotes__c} ${item.CMAP_SpecialNotesContent__c}`,
          }).value
        }
      </p>
    );

  const renderSpecialNotes = (data) => {
    const specialNotesGroup = _.groupBy(data, STRING_SPECIAL_NOTES);
    return (
      <>
        {Object.entries(specialNotesGroup).map(([category, data]) =>
          data.map((item, index) => ChannelListDescription({ item }))
        )}
      </>
    );
  };

  const ChannelListBox = ({ category, data }) => (
    <>
      <div className="list_wrapper underline L-01 two-column">
        <div className="list-title">
          <h5>
            {
              fillBlank({
                value: category.includes(":")
                  ? category.split(":")[0]
                  : category,
              }).value
            }
          </h5>
        </div>
        <ul>
          {data.map((item, index) =>
            ChannelListItem({ category, item, index })
          )}
          <div className="description-content no-left-right-margin D-01">
            {renderSpecialNotes(data)}
          </div>
        </ul>
      </div>
    </>
  );

  // @View
  return (
    <React.Fragment>
      {channelList && (
        <>
          <Placeholder
            name="provided-search-result-list-header"
            rendering={props.rendering}
          />
          {Object.entries(categorizeDataByCategory).map(([category, data]) => (
            <React.Fragment key={category}>
              {ChannelListBox({ category, data })}
            </React.Fragment>
          ))}
        </>
      )}
    </React.Fragment>
  );
};

export default ProvidedChannelsListModalLayout;
