import React from "react";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const L12 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);

  const { titleText, descriptionText, data, isHidden } = properData;
  return (
    <React.Fragment>
      {!isHidden && (
        <div className="list_wrapper list-description L-12">
          <div className="list-description-title">
            <Text field={fillBlank(titleText)} tag="h5" />
          </div>
          <RichText field={fillBlank(descriptionText)} tag="p" />
          <div className="list-description-info">
            <table className="list-description-detail">
              <tbody>
                {data?.map((dat, i) => (
                  <tr key={dat.text + i} className={dat.class}>
                    <td>{dat.text}</td>
                    <td>{dat.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default L12;
