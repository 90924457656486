import React from "react";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

// @Model

// get the data

// update the context

// @Controller

// @View

const C08 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    titleText,
    explanationText,
    contentLabel,
    contentText,
    richText,
  } = properData;

  return (
    <React.Fragment>
      <div className="decoration-content instruction C-08">
        <div className="information">
          <div className="content-title">
            <h5>
              <Text field={fillBlank(titleText)} encode={false} />
            </h5>
          </div>
          {richText?.value ? (
            <RichText field={fillBlank(richText)} />
          ) : (
            <>
              <p>
                <Text field={fillBlank(explanationText)} encode={false} />
              </p>
              <ul>
                <li>
                  <p>
                    <Text field={fillBlank(contentLabel)} />
                  </p>
                  <p>
                    <Text field={fillBlank(contentText)} />
                  </p>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default C08;
