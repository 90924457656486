import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";

const SelectPlanProductRightLayout = (props) => {
  return (
    <React.Fragment>
      <div className="estimate_wrapper">
        <Placeholder name="estimate-section" rendering={props.rendering} />
      </div>
    </React.Fragment>
  );
};

export default SelectPlanProductRightLayout;
