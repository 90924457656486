import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

// @Model

// get the data

// update the context

// @Controller

// @View

const C06 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    titleText,
    priceText,
    currencyText,
    subscriptionText,
    explanationText,
    textAreaLabel,
    MultilistItems,
  } = properData;
  const properMultilistItems = [];
  for (let i = 0; i < MultilistItems?.length; i++) {
    properMultilistItems[i] = getProperData(
      sitecoreContext,
      MultilistItems[i].fields
    );
  }

  return (
    <React.Fragment>
      <div className="decoration-content amount display1 C-06">
        <div className="information">
          <ul>
            {properMultilistItems.map((item, i) => (
              <li key={i}>
                <div className="detail">
                  <div className="txt-left">
                    <div className="content-title big">
                      <h5>
                        <Text field={fillBlank(item.titleText)} />
                      </h5>
                    </div>
                  </div>
                  {item.priceText && (
                    <div className="text-right">
                      <p>
                        <span className="bold text-big">
                          <Text field={fillBlank(item.priceText)} />
                        </span>
                        <span className="bold">
                          <Text field={item.currencyText} />
                        </span>
                        <Text field={item.subscriptionText} />
                      </p>
                    </div>
                  )}
                  <div className="txt-left">
                    <Text field={item.explanationText} />
                  </div>
                </div>
                <ul>
                  <li>
                    <dl>
                      <dt>
                        <Text field={fillBlank(item.textLabel1)} />
                      </dt>
                      <dd>
                        <Text field={fillBlank(item.priceText1)} />
                        <Text field={fillBlank(item.currencyText)} />
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>
                        <Text field={fillBlank(item.textLabel2)} />
                      </dt>
                      <dd>
                        <Text field={fillBlank(item.priceText2)} />
                        <Text field={fillBlank(item.currencyText)} />
                      </dd>
                    </dl>
                  </li>
                  {item?.textLabel3 && (
                    <li>
                      <dl>
                        <dt>
                          <Text field={fillBlank(item.textLabel3)} />
                        </dt>
                        <dd>
                          <Text field={fillBlank(item.priceText3)} />
                          <Text field={item.currencyText} />
                        </dd>
                      </dl>
                    </li>
                  )}
                  {item?.textAreaLabel && (
                    <li>
                      <dl>
                        <dt>
                          <Text field={item.textAreaLabel} />
                        </dt>
                      </dl>
                    </li>
                  )}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default C06;
