import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

// Modal for 個人情報の取り扱いを別画面で確認する
const HandlingPersonalInfoModalLayout = (props) => {
  return (
    <React.Fragment>
      <RichText field={props.fields?.richTextContent} />
    </React.Fragment>
  );
};

export default HandlingPersonalInfoModalLayout;
