import axios from "axios";
import { useGQLQuery } from "./useGQLQuery";
import { useQuery, useQueries } from "react-query";

import { useFormContext } from "react-hook-form";

import {
  STRING_GET,
  STRING_POST,
  STRING_ERROR_500_URI,
  STRING_LOADING,
  STRING_LOGIN_URL
} from "../utils/constantVariables";

import { redirect, getUrlInfo } from "../utils/commonUtils";
import { di_register } from "../utils/di";

let formContext = {};

export const useGetDataViaGraphQL = (
  query,
  args,
  key = "getData",
  config = { refetchOnWindowFocus: false },
  diRegistName = "fetch_data"
) => {
  const { isLoading, error, data } = useGQLQuery(key, query, args, config);

  if (isLoading) {
    return STRING_LOADING;
  }

  if (error) {
    return "error!";
  }

  let result = [];
  if (data) {
    result = data ?? [];
  }
  di_register(diRegistName, result);

  return data;
};

// refetch前提のメソッド
async function fetchData(url, method, body, headers) {
  try {
    const response = await axios({
      method,
      url,
      data: body,
      headers,
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
}

const checkMethod = (method, body) => {
  if (method === STRING_POST) {
    const base64 = {
      inputParam_Base64: Buffer.from(JSON.stringify(body)).toString("base64"),
    };
    return base64;
  }
  return body;
};

export const useCustomQuery = (
  url,
  enabled = true,
  method = STRING_GET,
  body = {},
  headers = {},
  retry = 3,
  isBase64 = true
) => {
  const newBody = isBase64 === true ? checkMethod(method, body) : body;
  const queryKey = [url, method, newBody, headers];

  formContext = useFormContext() ?? {};

  return useQuery(queryKey, () => fetchData(url, method, newBody, headers), {
    retry,
    enabled,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: "tracked",
    onSuccess: () => {
    },
    onError: (err) => {
    },
  });
};

//for dynamic parallel queries
//call API using GET or POST methods (default is GET)
const fetchDynamicQuery = async (
  params,
  method = STRING_GET,
  uriLink,
  otherParams
) => {
  try {
    let response = "";
    if (method === STRING_POST) {
      response = await axios.post(uriLink, { inputParam_Base64: params });
    } else if (method === STRING_GET && params?.url) {
      response = await axios.get(params.url);
    } else {
      response = await axios.get(`${uriLink}${params}${otherParams}`);
    }
    return response;
  } catch (error) {
    errorHandler(error);
  }
};

//todo: extend the part of "item.productId" in order
//to accommodate other formats of request body.
export const useDynamicParallelQuery = (
  ids,
  uriLink,
  method = STRING_GET,
  otherParams
) => {
  formContext = useFormContext() ?? {};
  const results = useQueries(
    ids?.map((item) => {
      return {
        queryKey:
          method === STRING_POST
            ? ["dynamicMultipleGetQuery", item.productId]
            : ["dynamicMultipleGetQuery", item],
        queryFn: () =>
          fetchDynamicQuery(
            method === STRING_POST
              ? Buffer.from(JSON.stringify(item)).toString("base64")
              : item,
            method,
            uriLink,
            typeof otherParams !== "undefined" ? otherParams : ""
          ),
        enabled: ids?.length > 0,
        refetchOnWindowFocus: false,
      };
    })
  );

  const isLoading = results.some((query) => query.isLoading);
  return { isLoading, results };
};

export const useMultipleQueries = (
  url,
  enabled = true,
  method = STRING_GET,
  requests = [],
  headers = {}
) => {
  formContext = useFormContext() ?? {};

  const results = useQueries(
    requests?.map((req, index) => {
      return {
        queryKey: ["dynamicMultipleQuery", index],
        queryFn: () => fetchData(url, method, req, headers),
        enabled: enabled || requests.length > 0,
        refetchOnWindowFocus: false,
      };
    })
  );

  return {
    isLoading: results.some((query) => query.isLoading),
    results: results.map((result) => result.data),
  };
};

const errorHandler = (axiosError, allowRedirect = true) => {
  const { setError = () => {}, clearErrors = () => {} } = formContext;

  const urlInfo = getUrlInfo();

  clearErrors();

  let errorObjects = [];
  if (axiosError.response.data.ValidationErrors?.length > 0)
    errorObjects = axiosError.response.data.ValidationErrors.filter(
      (errorObject) =>
        errorObject.Message !== null && errorObject.Message !== ""
    );

  if (axiosError.response.data.BusinessErrors?.length > 0)
    errorObjects = errorObjects.concat(
      axiosError.response.data.BusinessErrors.filter(
        (errorObject) =>
          errorObject.Message !== null && errorObject.Message !== ""
      )
    );

  const errorCode =
    axiosError.response.data.StatusCode ?? axiosError.response.status;

  switch (errorCode) {
    case 400:
      errorObjects.forEach((errorObject, index) => {
        const errorKey = "API-error_" + index;
        setError(errorKey, {
          type: "manual",
          message: errorObject.Message,
        });
      });
      break;
    case 401:
      if (allowRedirect) {
        redirect(STRING_LOGIN_URL);
        throw new Error(
          axiosError.response?.data?.message || axiosError.message
        );
      }
    case 500:
      if (allowRedirect) {
        if (!urlInfo.href.includes(STRING_ERROR_500_URI)) {
          // avoid infinite loop
          redirect(STRING_ERROR_500_URI);
          throw new Error(
            axiosError.response?.data?.message || axiosError.message
          );
        }
      }
    default:
      throw new Error(axiosError.response?.data?.message || axiosError.message);
  }
};
