export const formatDate = (itemDate) => {
  const fDate = new Date(itemDate);
  const fullYear = fDate.getFullYear();
  const month = String(fDate.getMonth() + 1).padStart(2, "0");
  const date = String(fDate.getDate() + 1).padStart(2, "0");
  const day = fDate.getDay();
  const hours = String(fDate.getHours() + 1).padStart(2, "0");
  const minutes = String(fDate.getMinutes() + 1).padStart(2, "0");
  let week = ["日", "月", "火", "水", "木", "金", "土"];

  return itemDate === null
    ? ""
    : fullYear +
        "年" +
        month +
        "月" +
        date +
        "日" +
        "(" +
        week[day] +
        ")" +
        " " +
        hours +
        ":" +
        minutes;
};
