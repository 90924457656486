import React, { useEffect, useState } from "react";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank, getProperData, nullChecker } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";
import { useSetSelectedItem } from "../../hooks/useSelectedState";

const S15 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);

  const {
    radioBoxLabel,
    explanationText,
    additionalClass,
    data,
    isHidden,
    errorMessage,
  } = properData;

  const componentCategory = nullChecker(properData?.category)?.value;

  const [selectedItem, selectedItemSetter] = useSetSelectedItem(
    componentCategory
  );

  const clickHandler = (event) => {
    const value = event.target.value;
    selectedItemSetter({ [componentCategory]: value });
    if (properData.onChangeEvent) properData.onChangeEvent(value);
  };

  return (
    <React.Fragment>
      {!isHidden && (
        <div className={`input-form S-15 ${additionalClass?.value}`}>
          {data && data.length === 0 ? (
            <p>
              <Text field={fillBlank(errorMessage)} />
            </p>
          ) : (
            data?.map((item, key) => (
              <div className="input-box" key={key}>
                <div className="input-container">
                  <input
                    type="radio"
                    name={componentCategory}
                    onChange={clickHandler}
                    value={item.id}
                    checked={item.id === selectedItem?.selected}
                  />
                  <label htmlFor={componentCategory}>
                    <p>
                      <Text field={fillBlank(item.radioBoxLabel)} />
                    </p>
                    <p className="description-text">
                      <Text field={fillBlank(item.explanationText)} />
                    </p>
                    <div className="benefits-image">
                      <img src="" alt="" />
                    </div>
                  </label>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default S15;
