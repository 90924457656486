import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import { apiBaseURL } from "../../envSettings";
import { useCustomQuery } from "../../hooks/useGetData";
import { useSetSelectedItem } from "../../hooks/useSelectedState";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  getProperData,
  redirectToLinkUrl,
} from "../../utils/commonUtils";
import { di_register } from "../../utils/di";
import { getErrorMessage } from "../../utils/errorMessageHandler";
import { validationFuncs } from "../../utils/validations";

const ReconfirmAddressLayout = (props) => {
  // get data

  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  const { setError, clearErrors } = useFormContext();
  const sitecoreContext = props?.sitecoreContext;
  const pageContextField = sitecoreContext?.route?.fields;
  di_register("thisPageSitecoreContext", sitecoreContext);

  const S01_COMPONENT_CATEGORY = "selectedAddress";

  // get ss
  const evaluationAddressCodesSessionStorage = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_ADRCD,
    true
  );
  const reconfirmAddressCodeSessionStorage = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_RECONFIRMADDRESS_ADDRESSCODE,
    true
  );
  const orderRelatedInfoSessionStorage = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );
  const shippingLongitudeSessionStorage = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_LONGITUDE_J,
    true
  );
  const shippingLatitudeSessionStorage = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_LATITUDE_J,
    true
  );

  const addressOptionSection = getScDataFromPlaceholder(
    "address-option-section",
    props
  );
  const buttonSection = getScDataFromPlaceholder("button-section", props);

  const [s01Data] = getScDataFromComponent("S-01", addressOptionSection);
  const [d01Data] = getScDataFromComponent("D-01", addressOptionSection);
  const [nextButton] = getScDataFromComponent("B-01", buttonSection);
  const [backButton] = getScDataFromComponent("B-02", buttonSection);

  const [selectedAddress, setSelectedAddress] = useSetSelectedItem(
    S01_COMPONENT_CATEGORY
  );

  let addressCodes = "";
  if (typeof evaluationAddressCodesSessionStorage === "string") {
    evaluationAddressCodesSessionStorage.split(",").map((item, index) => {
      addressCodes += `${index > 0 ? "&" : ""}addressCodes=${item.trim()}`;
    });
  }

  const retrieveAddressInfoURI = `${apiBaseURL}order/RetrieveAddressInfo?${addressCodes}`;
  const { data: retrieveAddressInfoData, isLoading: retrieveAddressInfoIsLoading } = useCustomQuery(
    retrieveAddressInfoURI
  );
  props.setIsLoading(retrieveAddressInfoIsLoading);
  const addressList = retrieveAddressInfoData?.streets?.map((street) => ({
    Code: street.CMAP_AddressCode__c,
    Value: `〒${street.CMAP_PostCode__c} ${street.CMAP_MunicipalityName__c} ${street.CMAP_StreetOoazaName__c} ${street.CMAP_StreetAzaName__c}`,
    Checked:
      street.CMAP_AddressCode__c == reconfirmAddressCodeSessionStorage
        ? true
        : false,
  }));

  const hasAddressList = addressList !== undefined && addressList.length > 0;

  s01Data.fields["category"] = {
    value: S01_COMPONENT_CATEGORY,
  };
  d01Data.fields["descriptionText"] = hasAddressList
    ? pageContextField["ReconfirmAddress-Description"]?.value
    : pageContextField["ReconfirmAddress-NgDescription"]?.value;
  s01Data.fields["isHidden"] = !hasAddressList;
  s01Data.fields["selections"] = addressList;
  nextButton.fields["isHidden"] = !hasAddressList;

  useEffect(() => {
    let addressCode = "";
    if (
      reconfirmAddressCodeSessionStorage &&
      retrieveAddressInfoData?.streets?.length > 0
    ) {
      addressCode = retrieveAddressInfoData?.streets?.find(
        (x) => x?.CMAP_AddressCode__c == reconfirmAddressCodeSessionStorage
      )?.CMAP_AddressCode__c;
    }
    setSelectedAddress({ selectedAddress: addressCode });
  }, [retrieveAddressInfoData]);

  backButton.fields.onClickEvent = () => {
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_RECONFIRMADDRESS_ADDRESSCODE,
      selectedAddress?.selected,
      true
    );

    redirectToPreviousPage();
  };

  nextButton.fields.onClickEvent = () => {
    clearErrors();

    if (!validationFuncs.validateIsRequired(selectedAddress?.selected)) {
      setError("NearbyAddressesRadioButton", {
        type: "manual",
        message: getErrorMessage("E0002", ["周辺住所ラジオボタン"]),
      });
      return;
    }

    const street = retrieveAddressInfoData?.streets?.find(
      (x) => x.CMAP_AddressCode__c == selectedAddress?.selected
    );

    // save ss
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_RECONFIRMADDRESS_ADDRESSCODE,
      street?.CMAP_AddressCode__c,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_RECONFIRMADDRESS_POSTALCODE,
      street?.CMAP_PostCode__c,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_RECONFIRMADDRESS_PREFECTURE,
      street?.CMAP_StateName__c,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_RECONFIRMADDRESS_CITY,
      street?.CMAP_MunicipalityName__c,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_RECONFIRMADDRESS_DISTRICT,
      street?.CMAP_StreetOoazaName__c,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_RECONFIRMADDRESS_STREET1,
      street?.CMAP_StreetAzaName__c,
      true
    );



    const nextOrderRelatedInfo = {
      ...orderRelatedInfoSessionStorage,
      orderRelatedInfo: {
        ...orderRelatedInfoSessionStorage?.orderRelatedInfo,
        orderInfo: {
          ...orderRelatedInfoSessionStorage?.orderRelatedInfo?.orderInfo,
          order: {
            ...orderRelatedInfoSessionStorage?.orderRelatedInfo?.orderInfo?.order,
            CMAP_InstallationLocationPrefectureCode__c:
              street?.CMAP_StateCode__c,
            CMAP_InstallationLocationCityCode__c:
              street?.CMAP_MunicipalityCode__c,
            CMAP_InstallationAddressCode11__c: street?.CMAP_AddressCode__c,
            CMAP_PrefectureCityCode:
              street?.CMAP_StateCode__c + street?.CMAP_MunicipalityCode__c,
            ShippingLatitude: shippingLongitudeSessionStorage,
            ShippingLongitude: shippingLatitudeSessionStorage,
          },
        },
      },
    }
    setSessionStorageItem(
      sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
      nextOrderRelatedInfo,
      true
    );

    const { linkURL } = getProperData(sitecoreContext, nextButton.fields);
    redirectToLinkUrl(linkURL);
  };

  // @view
  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <div className="form_wrapper-input">
                <Placeholder
                  name="address-option-section"
                  rendering={props.rendering}
                />
              </div>
              <div className="btn_container">
                <Placeholder
                  name="button-section"
                  rendering={props.rendering}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(ReconfirmAddressLayout);
