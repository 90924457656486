import React, { useEffect, useRef } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { useFormContext } from "react-hook-form";

// @Model

// get the data

// update the context

// @Controller

// @View

const A01 = (props) => {
  const {
    setValue,
    register,
    formState: { errors },
    reset,
  } = useFormContext();

  const ref = useRef(null);
  const { alertText } = props.fields;
  let alertTextList = [];
  if (!alertText) {
    alertTextList = [alertText.value];
  } else if (Object.keys(errors).length > 0) {
    for (let k in errors) {
      alertTextList.push(errors[k]?.message);
    }
  }

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  }, [errors]);

  let addClass = alertTextList.length > 0 ? "" : "hide";
  return (
    <React.Fragment>
      <div className={`alert-message A-01 ${addClass}`} ref={ref}>
        {alertTextList.map((txt, i) => (
          <p key={i}>
            <Text field={{ value: txt }} />
          </p>
        ))}
      </div>
    </React.Fragment>
  );
};

export default A01;
