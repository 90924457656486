import React, { useEffect, useMemo, useState } from "react";
import {
  Placeholder,
  Text,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import {
  fillBlank,
  formatCurrencyChar,
  getScDataFromComponent,
  getScDataFromPlaceholder,
  redirectToLinkUrl,
  showPrintDialog,
} from "../../utils/commonUtils";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import { recoilKeys } from "../../assets/recoilKeys";
import { useFormContext } from "react-hook-form";
import { useCustomQuery } from "../../hooks/useGetData";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import { apiBaseURL } from "../../envSettings";
import {
  DATE_LOCALE,
  STRING_DESELECTED,
  STRING_POST,
  STRING_STORE,
  STRING_SUSPENDSELECTED,
  STRING_USERAGENT_CPAD_APP,
} from "../../utils/constantVariables";
import { format } from "date-fns";

import {
  createAnnualExpenseObj,
  createBenefitObj,
  createInitialCostObj,
  createMonthlyExpenseObj,
  createOptionObj
} from "../../services/calculations/C-Pad-Estimation-Result-New-Application/calculateC-PadEstimationResultNewApplication"
import { groupByGroupAndCat } from "../../services/calculations/Common/CommonProcess"

// TODO: delete test data

const mapThreePrices = (item) => {
  return {
    price1: formatCurrencyChar(item?.simulatedUnitPriceTaxInc1),
    price2: formatCurrencyChar(item?.simulatedUnitPriceTaxInc2),
    price3: formatCurrencyChar(item?.simulatedUnitPriceTaxInc3),
    isTotal: false,
  }
}

const mapOnePrice = (item) => {
  return {
    price1: formatCurrencyChar(item?.PriceTaxInc),
    isTotal: false,
  }
}

const buildGroupCatProductPricesRowData = (groupedChangeDetailsObj, mapPrices) => {
  const rowData = []
  for (const groupKey of Object.keys(groupedChangeDetailsObj)) {
    const group = groupedChangeDetailsObj[groupKey];
    let groupLength =  0;
    for (const catKey of Object.keys(group)) {
      const cat = group[catKey];
      groupLength = groupLength + cat.length;
    }
    let isNewGroup = true;

    for (const catKey of Object.keys(group)) {
      const cat = group[catKey];
      const catLength =  Object.keys(cat).length;
      let isNewCat = true;

      cat.forEach((item) => {
        const breakdown = []
        if (isNewGroup) {
          breakdown.push({
            rowSpan: groupLength,
            value: item.CMAP_MainSubProductGroupName__c
          });
          isNewGroup = false;
        }

        if (isNewCat) {
          breakdown.push({
            rowSpan: catLength,
            value: item.CMAP_ProductCategoryName__c
          });
          isNewCat = false;
        }

        breakdown.push({
          value: item.CMAP_ProductName__c
        });

        rowData.push({
          ...mapPrices(item),
          breakdown
        });
      })
    }
  }

  return rowData;
};

const buildGroupProductPricesRowData = (detailsObj, groupName, mapPrices) => {
  const rowData = [];

  const groupLength =  detailsObj.length;
  let isNewGroup = true;

  detailsObj.forEach((item) => {
    const breakdown = []
    if (isNewGroup) {
      breakdown.push({
        rowSpan: groupLength,
        colSpan: 2,
        value: groupName
      });
      isNewGroup = false;
    }

    breakdown.push({
      value: item.CMAP_ProductName__c
    });

    rowData.push({
      ...mapPrices(item),
      breakdown
    });
  })

  return rowData;
};

const TextCellFormatter = ({ row, value }) => {
  let className = "txt_left";
  if (row.isTotal) {
    className = "txt_right";
  }

  return (
    <Text
      field={fillBlank(value)}
      encode={false}
      tag="p"
      className={`${className} full-width`}
    />
  );
};

const PriceCellFormatter = ({ value }) => {
  return (
    <Text
      field={fillBlank(value)}
      encode={false}
      tag="p"
      className="txt_right full-width"
    />
  );
};

// @View
const EstimationArea = (props) => {
  const isMonthlyFeeHidden = props.isMonthlyFeeHidden ?? false;
  const isAnnualFeeHidden = props.isAnnualFeeHidden ?? false;
  const isInitialCostHidden = props.isInitialCostHidden ?? false;
  const isBenefitHidden = props.isBenefitHidden ?? false;
  const isOptionHidden = props.isOptionHidden ?? false;

  return (
    <>
      <Placeholder
        name="estimation-results-section"
        rendering={props.rendering}
      />
      {!isMonthlyFeeHidden && (
        <Placeholder
          name="monthly-fee-section"
          rendering={props.rendering}
        />
      )}
      {!isAnnualFeeHidden && (
        <Placeholder
          name="annual-fee-section"
          rendering={props.rendering}
        />
      )}
      {!isInitialCostHidden && (
        <Placeholder
          name="initial-cost-section"
          rendering={props.rendering}
        />
      )}
      {!isBenefitHidden && (
        <Placeholder
          name="campaign-benefits-section"
          rendering={props.rendering}
        />
      )}
      {!isOptionHidden && (
        <Placeholder
          name="option-free-period-section"
          rendering={props.rendering}
        />
      )}
    </>
  );
};

const AgencyInfoArea = (props) => {
  return (
    <>
      <div className="form_wrapper-input agency-info-section mx-16 mt-40">
        <Placeholder name="agency-info-section" rendering={props.rendering} />
        <div className="grid-column two side-label-input">
          <Placeholder
            name="agency-info-inputs-section"
            rendering={props.rendering}
          />
        </div>

        <Placeholder
          name="agency-info-buttons-section"
          rendering={props.rendering}
        />
      </div>
    </>
  );
};

const EstimationResultButtonArea = (props) => {
  return (
    <>
      <div className="bottom-section chart-bottom-section center">
        <Placeholder name="button-section" rendering={props.rendering} />
      </div>
    </>
  );
};

const CPadEstimationResultNewApplicationPageLayout = (props) => {
  //TODO: delete test data

  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  // display
  const [isMonthlyFeeHidden, setIsMonthlyFeeHidden] = useState(false);
  const [isAnnualFeeHidden, setIsAnnualFeeHidden] = useState(false);
  const [isInitialCostHidden, setIsInitialCostHidden] = useState(false);
  const [isBenefitHidden, setIsBenefitHidden] = useState(false);
  const [isOptionHidden, setIsOptionHidden] = useState(false);

  const {
    "Estimation-Items": ItemsText,
    "Estimation-UnitPriceWithoutTax": UnitPriceWithoutTaxText,
    "Estimation-TotalPriceWithTax": TotalPriceWithTaxText,
    "Estimation-Tax": TaxText,
    "Estimation-OptionalService": OptionalServiceText,
    "Estimation-StartMonth": StartMonthText,
    "Estimation-After1stMonth": After1stMonthText,
    "Estimation-After2ndMonth": After2ndMonthText,
    "Estimation-After3rdMonth": After3rdMonthText,
    "Estimation-After4thMonth": After4thMonthText,
    "Estimation-After5thMonth": After5thMonthText,
    "Estimation-AutoSelectedProduct": AutoSelectedProductText,
    "Estimation-BenefitProduct": BenefitProductText,
    "AgencyInfo-NextButtonLink": NextButtonLink,
  } = sitecoreContext?.route?.fields;
  const priceWithTaxText =
    TotalPriceWithTaxText?.value + "<br />" + TaxText?.value;

  const orderRelatedInfo = getSessionStorageItem(
    recoilKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );
  const personalizeInfo = getSessionStorageItem(
    recoilKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );
  const { BenefitListForAgency: benefitListAgency } = getSessionStorageItem(
    recoilKeys.SSN_APPLICATION_SELECTPRODUCT_BENEFITLISTFORAGENCY,
    true
  );
  const { ProductSelectionList: productSelectionList } = getSessionStorageItem(
    recoilKeys.SSN_APPLICATION_SELECTPRODUCT_PRODUCTSELECTIONLIST,
    true
  );

  const [inputValues, setInputValues] = useState({
    agencyName: getSessionStorageItem(
      recoilKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ACCOUNTNAME,
      true
    ),
    storeName:
      personalizeInfo?.SalesChannelSelect === STRING_STORE
        ? getSessionStorageItem(
            recoilKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STORENAME,
            true
          )
        : "",
    descriptionDate: getSessionStorageItem(
      recoilKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_DESCRIPTIONDATE,
      true
    ),
    MICNo: getSessionStorageItem(
      recoilKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_MICNO,
      true
    ),
    estimateDueDate: getSessionStorageItem(
      recoilKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ESTIMATEDUEDATE,
      true
    ),
    staffName: getSessionStorageItem(
      recoilKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STAFFNAME,
      true
    ),
    phone: getSessionStorageItem(
      recoilKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_PHONE,
      true
    ),
  });

  const { setError, clearErrors } = useFormContext();

  const benefitListArray = benefitListAgency?.map((benefit) => ({
    orderItem: {
      CMAP_MainSubProductGroupName__c: "",
      Product2Id: "",
      UnitPrice: "",
      CMAP_UnitPriceTaxIncluded__c: "",
      CMAP_PriceCategory__c: "",
      CMAP_InstallmentPaymentMonthly__c: "",
      CMAP_InstallmentPayMonthlyPriceTaxExc__c: "",
      CMAP_InstallmentPayMonthlyPriceTaxInc__c: "",
      CMAP_NumberOfInstallments__c: "",
      CMAP_InstallmentPayFirstMonthPriceTaxExc__c: "",
      CMAP_InstallmentPayFirstMonthPriceTaxInc__c: "",
      CMAP_SelectionAnnualPayment__c: "",
      CMAP_AnnualPaymentPriceTaxExcluded__c: "",
      CMAP_AnnualPaymentPriceTaxIncluded__c: "",
      CMAP_Unchargeable__c: "",
      CMAP_BenefitProduct__c: "",
      CMAP_GrantUnitPrice__c: "",
      CMAP_GrantPoint__c: "",
      CMAP_OrderItemTypeSelection__c: "",
      CMAP_OrderItemTypeBenefit__c: "",
      CMAP_OrderItemTypeAutomaticSelection__c: "",
      CMAP_ProductSelectionMethod__c: "",
      CMAP_BenefitNameForCpad__c: benefit.BenefitName,
      CMAP_BenefitPriceForCpad__c: benefit.BenefitPrice,
      Quantity: "",
    },
  }));

  const orderItemInfo = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos;
  const orderItemArray = orderItemInfo?.reduce((a, { orderItem: item }) => {
    if (
      item.CMAP_ProductSelectionState__c !== STRING_DESELECTED &&
      item.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED
    ) {
      const prodSelection = productSelectionList?.find(
        (sel) => sel.Product2Id === item.Product2Id
      );
      a.push({
        orderItem: {
          CMAP_MainSubProductGroupName__c: item.CMAP_MainSubProductGroupName__c,
          Product2Id: item.Product2Id,
          UnitPrice: item.UnitPrice,
          CMAP_UnitPriceTaxIncluded__c: item.CMAP_UnitPriceTaxIncluded__c,
          CMAP_PriceCategory__c: item.CMAP_PriceCategory__c,
          CMAP_InstallmentPaymentMonthly__c:
            item.CMAP_InstallmentPaymentMonthly__c,
          CMAP_InstallmentPayMonthlyPriceTaxExc__c:
            item.CMAP_InstallmentPayMonthlyPriceTaxExc__c,
          CMAP_InstallmentPayMonthlyPriceTaxInc__c:
            item.CMAP_InstallmentPayMonthlyPriceTaxInc__c,
          CMAP_NumberOfInstallments__c: item.CMAP_NumberOfInstallments__c,
          CMAP_InstallmentPayFirstMonthPriceTaxExc__c:
            item.CMAP_InstallmentPayFirstMonthPriceTaxExc__c,
          CMAP_InstallmentPayFirstMonthPriceTaxInc__c:
            item.CMAP_InstallmentPayFirstMonthPriceTaxInc__c,
          CMAP_SelectionAnnualPayment__c: item.CMAP_SelectionAnnualPayment__c,
          CMAP_AnnualPaymentPriceTaxExcluded__c:
            item.CMAP_AnnualPaymentPriceTaxExcluded__c,
          CMAP_AnnualPaymentPriceTaxIncluded__c:
            item.CMAP_AnnualPaymentPriceTaxIncluded__c,
          CMAP_Unchargeable__c: item.CMAP_Unchargeable__c,
          CMAP_BenefitProduct__c: item.CMAP_BenefitProduct__c,
          CMAP_GrantUnitPrice__c: item.CMAP_GrantUnitPrice__c,
          CMAP_GrantPoint__c: item.CMAP_GrantPoint__c,
          CMAP_OrderItemTypeSelection__c: item.CMAP_OrderItemTypeSelection__c,
          CMAP_OrderItemTypeBenefit__c: item.CMAP_OrderItemTypeBenefit__c,
          CMAP_OrderItemTypeAutomaticSelection__c:
            item.CMAP_OrderItemTypeAutomaticSelection__c,
          CMAP_ProductSelectionMethod__c: prodSelection
            ? prodSelection.CMAP_ProductSelection__c
            : "",
          CMAP_BenefitNameForCpad__c: "",
          CMAP_BenefitPriceForCpad__c: "",
          Quantity: item.Quantity ? item.Quantity : 1,
        },
      });
    }
    return a;
  }, []);

  const feeSimulationRequest = {
    orderRelatedInfo: {
      orderInfo: {
        order: {
          CMAP_OrderCategory__c:
            orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_OrderCategory__c,
        },
        orderItemInfos: [
          ...(orderItemArray?.length > 0 ? orderItemArray : []),
          ...(benefitListArray?.length > 0 ? benefitListArray : []),
        ],
      },
    },
  };

  const {
    data: feeSimulationData,
    isLoading: feeSimulationLoading,
    error: feeSimulationError,
    refetch: feeSimulationRefetch,
    remove: feeSimulationRemove,
  } = useCustomQuery(
    `${apiBaseURL}order/RetrieveFeeSimulation`,
    true,
    STRING_POST,
    feeSimulationRequest
  );

  props.setIsLoading(feeSimulationLoading);

  const [c10Data] = getScDataFromComponent(
    "C-10",
    getScDataFromPlaceholder("estimation-results-section", props)
  );
  c10Data.fields.detailsText =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_OfferPlanName__c;

  // 年額料金
  const [annualFeeTable] = getScDataFromComponent(
    "TB-01",
    getScDataFromPlaceholder("annual-fee-section", props)
  );

  annualFeeTable.fields.columnData = [
    {
      key: "breakdown",
      header: ItemsText,
      colSpan: 3,
      width: "60%",
      Cell: TextCellFormatter,
    },
    {
      key: "price1",
      header: UnitPriceWithoutTaxText,
      Cell: PriceCellFormatter,
    },
  ];

  // TODO: delete test data

  const annualExpenseRowData = useMemo(() => {
    const annualExpenseObj = createAnnualExpenseObj();
    let rowData = [];

    if (annualExpenseObj.subtotalObj.length !== 0) {
      // 選択商品
      const selectedItems = annualExpenseObj.subtotalObj.filter((x) => x.CMAP_OrderItemTypeSelection__c);
      const groupedSelectedItems = groupByGroupAndCat(selectedItems);
      const selectedItemsRowData = buildGroupCatProductPricesRowData(groupedSelectedItems, mapOnePrice);
      rowData = rowData.concat(selectedItemsRowData);

      // 自動選択商品
      const automaticSelectedItems = annualExpenseObj.subtotalObj.filter((x) => x.CMAP_OrderItemTypeAutomaticSelection__c);
      const automaticSelectedItemsRowData = buildGroupProductPricesRowData(automaticSelectedItems, AutoSelectedProductText, mapOnePrice);
      rowData = rowData.concat(automaticSelectedItemsRowData);

      // 特典商品
      const benefitSelectedItems = annualExpenseObj.subtotalObj.filter((x) => x.CMAP_OrderItemTypeBenefit__c);
      const benefitSelectedItemsRowData = buildGroupProductPricesRowData(benefitSelectedItems, BenefitProductText, mapOnePrice);
      rowData = rowData.concat(benefitSelectedItemsRowData);

      // total
      const annualExpenseTotalRowData = [{
        isTotal: true,
        breakdown: { colSpan: 3, value: priceWithTaxText },
        price1: formatCurrencyChar(annualExpenseObj.totalObj.PriceTaxInc) + "<br />(" + formatCurrencyChar(annualExpenseObj.totalObj.TotalTax) + ")",
      }]
      rowData = rowData.concat(annualExpenseTotalRowData);
    }
    
    return rowData;
  }, []);

  annualFeeTable.fields.rowData = annualExpenseRowData;

  // 初期費用/購入費用/その他費用
  const [initialCostTable] = getScDataFromComponent(
    "TB-01",
    getScDataFromPlaceholder("initial-cost-section", props)
  );
  initialCostTable.fields.columnData = [
    {
      key: "breakdown",
      header: ItemsText,
      width: "60%",
      colSpan: 3,
      Cell: TextCellFormatter,
    },
    {
      key: "price1",
      header: UnitPriceWithoutTaxText,
      Cell: PriceCellFormatter,
    },
  ];

  // TODO: delete test data

  const initialCostRowData = useMemo(() => {
    const initialCostObj = createInitialCostObj();
    let rowData = [];

    if (initialCostObj.subtotalObj.length !== 0) {
      // 選択商品
      const selectedItems = initialCostObj.subtotalObj.filter((x) => x.CMAP_OrderItemTypeSelection__c);
      const groupedSelectedItems = groupByGroupAndCat(selectedItems);
      const selectedItemsRowData = buildGroupCatProductPricesRowData(groupedSelectedItems, mapOnePrice);
      rowData = rowData.concat(selectedItemsRowData);

      // 自動選択商品
      const automaticSelectedItems = initialCostObj.subtotalObj.filter((x) => x.CMAP_OrderItemTypeAutomaticSelection__c);
      const automaticSelectedItemsRowData = buildGroupProductPricesRowData(automaticSelectedItems, AutoSelectedProductText, mapOnePrice);
      rowData = rowData.concat(automaticSelectedItemsRowData);

      // 特典商品
      const benefitSelectedItems = initialCostObj.subtotalObj.filter((x) => x.CMAP_OrderItemTypeBenefit__c);
      const benefitSelectedItemsRowData = buildGroupProductPricesRowData(benefitSelectedItems, BenefitProductText, mapOnePrice);
      rowData = rowData.concat(benefitSelectedItemsRowData);

      // total
      const initialCostTotalRowData = [{
        isTotal: true,
        breakdown: { colSpan: 3, value: priceWithTaxText },
        price1: formatCurrencyChar(initialCostObj.totalObj.PriceTaxInc) + "<br />(" + formatCurrencyChar(initialCostObj.totalObj.TotalTax) + ")",
      }]
      rowData = rowData.concat(initialCostTotalRowData);
    }
    
    return rowData;
  }, []);

  initialCostTable.fields.rowData = initialCostRowData;

  // キャンペーン特典
  const campaignBenefitSection = getScDataFromPlaceholder(
    "campaign-benefits-section",
    props
  );
  const [campaignBenefit] = getScDataFromComponent(
    "TB-01",
    campaignBenefitSection
  );
  campaignBenefit.fields.columnData = [
    {
      key: "breakdown",
      header: ItemsText,
      Cell: TextCellFormatter,
    },
  ];

  //TODO: delete test data

  const benefitRowData = useMemo(() => {
    // 代理店特典内訳リスト
    const benefitListForAgencyBreakdownList = benefitListAgency?.map((item) => ({
      breakdown: `${item.BenefitName} ${item.BenefitPrice ? formatCurrencyChar(item.BenefitPrice) : ""}`,
    })) ?? [];

    // プラン特典内訳リスト
    const benefitObj = createBenefitObj();
    const planBenefitListBreakdownList = benefitObj?.map((item) => ({ 
      breakdown: item.CMAP_BenefitName__c
    })) ?? [];

    return [
      ...benefitListForAgencyBreakdownList,
      ...planBenefitListBreakdownList
    ];
  }, []);

  campaignBenefit.fields.rowData = benefitRowData;

  const handleInputChange = (evt, name = undefined) => {
    if (!name) name = evt.target.name;
    const { value } = evt.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  const agencySection = getScDataFromPlaceholder(
    "agency-info-inputs-section",
    props
  );
  const [
    agencyNameInput,
    storeNameInput,
    MICNoInput,
    staffNameInput,
    phoneInput,
  ] = getScDataFromComponent("I-01", agencySection);
  const [descriptionDateInput, estimateDueDateInput] = getScDataFromComponent(
    "M-01i",
    agencySection
  );
  agencyNameInput.fields.inputValue = {
    name: "agencyName",
    value: inputValues.agencyName,
  };
  agencyNameInput.fields.onChangeEvent = handleInputChange;

  storeNameInput.fields.inputValue = {
    name: "storeName",
    value: inputValues.storeName,
  };
  storeNameInput.fields.onChangeEvent = handleInputChange;
  storeNameInput.fields.isHidden = {
    value: personalizeInfo.SalesChannelSelect !== STRING_STORE,
  };

  descriptionDateInput.fields.initialValue = {
    value: inputValues.descriptionDate ? inputValues.descriptionDate : null,
  };
  descriptionDateInput.fields.onChangeEvent = (evt) => {
    setInputValues((prev) => ({
      ...prev,
      ["descriptionDate"]: format(evt, "yyyy-MM-dd", DATE_LOCALE),
    }));
  };

  MICNoInput.fields.inputValue = {
    name: "MICNo",
    value: inputValues.MICNo,
  };
  MICNoInput.fields.onChangeEvent = handleInputChange;

  estimateDueDateInput.fields.initialValue = {
    value: inputValues.estimateDueDate ? inputValues.estimateDueDate : null,
  };
  estimateDueDateInput.fields.onChangeEvent = (evt) => {
    setInputValues((prev) => ({
      ...prev,
      ["estimateDueDate"]: format(evt, "yyyy-MM-dd", DATE_LOCALE),
    }));
  };

  staffNameInput.fields.inputValue = {
    name: "staffName",
    value: inputValues.staffName,
  };
  staffNameInput.fields.onChangeEvent = handleInputChange;

  phoneInput.fields.inputValue = {
    name: "phone",
    value: inputValues.phone,
  };
  phoneInput.fields.onChangeEvent = handleInputChange;

  const setInputSessionStorage = () => {
    setSessionStorageItem(
      recoilKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ACCOUNTNAME,
      inputValues.agencyName,
      true
    );
    setSessionStorageItem(
      recoilKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STORENAME,
      inputValues.storeName,
      true
    );
    setSessionStorageItem(
      recoilKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_DESCRIPTIONDATE,
      inputValues.descriptionDate,
      true
    );
    setSessionStorageItem(
      recoilKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_MICNO,
      inputValues.MICNo,
      true
    );
    setSessionStorageItem(
      recoilKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ESTIMATEDUEDATE,
      inputValues.estimateDueDate,
      true
    );
    setSessionStorageItem(
      recoilKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STAFFNAME,
      inputValues.staffName,
      true
    );
    setSessionStorageItem(
      recoilKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_PHONE,
      inputValues.phone,
      true
    );
  };

  const [printBtn] = getScDataFromComponent(
    "B-02i",
    getScDataFromPlaceholder("agency-info-buttons-section", props)
  );
  printBtn.fields.onClickEvent = () => {
    // TODO: Do the test on this part when we have the ctc devices.
    showPrintDialog();
  };

  const btnSection = getScDataFromPlaceholder("button-section", props);
  const [backButton] = getScDataFromComponent("B-02", btnSection);
  const [nextButton] = getScDataFromComponent("B-01", btnSection);

  backButton.fields.onClickEvent = () => {
    setInputSessionStorage();
    redirectToPreviousPage();
  };

  nextButton.fields.onClickEvent = () => {
    clearErrors();
    setInputSessionStorage();
    redirectToLinkUrl(NextButtonLink);
  };

  useEffect(() => {
    // control sections display
    setIsAnnualFeeHidden(annualExpenseRowData.length === 0);
    setIsInitialCostHidden(initialCostRowData.length === 0);
    setIsBenefitHidden(
      !orderItemInfo?.some(
        ({ orderItem: item }) =>
          item.CMAP_OrderItemTypeBenefit__c &&
          item.CMAP_ProductSelectionState__c !== STRING_DESELECTED &&
          item.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED
      ) && benefitListAgency?.length === 0
    );

    // user Agemt
    if (typeof window !== "undefined") {
      const userAgent = window.navigator.userAgent;
      printBtn.fields.isHidden = !userAgent?.includes(
        STRING_USERAGENT_CPAD_APP
      );
    }
  }, []);

  useEffect(() => {
    if (feeSimulationData && feeSimulationData.Result) {
      clearErrors();

      // 月額料金
      const [monthlyExpenseTable] = getScDataFromComponent(
        "TB-01",
        getScDataFromPlaceholder("monthly-fee-section", props)
      );

      const orderItemInfo =
        feeSimulationData.orderRelatedInfo.orderInfo.orderItemInfos[0];
      let childCol = [];

      if (orderItemInfo) {
        childCol = [
          {
            key: "price1",
            header: orderItemInfo?.simulationInfo.simulatedUnitPriceLabel1,
            Cell: PriceCellFormatter,
          },
          {
            key: "price2",
            header: orderItemInfo?.simulationInfo.simulatedUnitPriceLabel2,
            Cell: PriceCellFormatter,
          },
          {
            key: "price3",
            header: orderItemInfo?.simulationInfo.simulatedUnitPriceLabel3,
            Cell: PriceCellFormatter,
          },
        ];
      }

      monthlyExpenseTable.fields.columnData = [
        {
          key: "breakdown",
          header: ItemsText,
          colSpan: 3,
          rowSpan: 2,
          width: "50%",
          Cell: TextCellFormatter,
        },
        {
          header: UnitPriceWithoutTaxText,
          colSpan: 3,
          children: childCol,
        },
      ];

      //TODO: delete test data

      const monthlyExpenseObj = createMonthlyExpenseObj(feeSimulationData.orderRelatedInfo.orderInfo.orderItemInfos);
      let monthlyExpenseRowData = [];

      if (monthlyExpenseObj.subtotalObj.length !== 0) {
        // 選択商品
        const selectedItems = monthlyExpenseObj.subtotalObj.filter((x) => x.CMAP_OrderItemTypeSelection__c);
        const groupedSelectedItems = groupByGroupAndCat(selectedItems);

        const selectedItemsRowData = buildGroupCatProductPricesRowData(groupedSelectedItems, mapThreePrices);
        monthlyExpenseRowData = monthlyExpenseRowData.concat(selectedItemsRowData);

        // 自動選択商品
        const automaticSelectedItems = monthlyExpenseObj.subtotalObj.filter((x) => x.CMAP_OrderItemTypeAutomaticSelection__c);
        const automaticSelectedItemsRowData = buildGroupProductPricesRowData(automaticSelectedItems, AutoSelectedProductText, mapThreePrices);
        monthlyExpenseRowData = monthlyExpenseRowData.concat(automaticSelectedItemsRowData);

        // 特典商品
        const benefitSelectedItems = monthlyExpenseObj.subtotalObj.filter((x) => x.CMAP_OrderItemTypeBenefit__c);
        const benefitSelectedItemsRowData = buildGroupProductPricesRowData(benefitSelectedItems, BenefitProductText, mapThreePrices);
        monthlyExpenseRowData = monthlyExpenseRowData.concat(benefitSelectedItemsRowData);

        const monthlyExpenseTotalRowData = [{
          breakdown: {
            colSpan: 3,
            value: priceWithTaxText,
          },
          price1: `${formatCurrencyChar(monthlyExpenseObj.totalObj.simulatedUnitPriceTaxInc1)} <br /> ${formatCurrencyChar(monthlyExpenseObj.totalObj.simulatedUnitPriceTax1)}`,
          price2: `${formatCurrencyChar(monthlyExpenseObj.totalObj.simulatedUnitPriceTaxInc2)} <br /> ${formatCurrencyChar(monthlyExpenseObj.totalObj.simulatedUnitPriceTax2)}`,
          price3: `${formatCurrencyChar(monthlyExpenseObj.totalObj.simulatedUnitPriceTaxInc3)} <br /> ${formatCurrencyChar(monthlyExpenseObj.totalObj.simulatedUnitPriceTax3)}`,
          isTotal: true,
        }]
        monthlyExpenseRowData = monthlyExpenseRowData.concat(monthlyExpenseTotalRowData);
      }

      monthlyExpenseTable.fields.rowData = monthlyExpenseRowData;

      // オプション
      const [optionService] = getScDataFromComponent(
        "TB-01",
        getScDataFromPlaceholder("option-free-period-section", props)
      );

      optionService.fields.columnData = [
        {
          key: "OptionName",
          header: OptionalServiceText,
          Cell: TextCellFormatter,
          width: "35%",
        },
        {
          key: "StartMonthPrice",
          header: StartMonthText,
          Cell: PriceCellFormatter,
        },
        {
          key: "After1stMonthPrice",
          header: After1stMonthText,
          Cell: PriceCellFormatter,
        },
        {
          key: "After2ndMonthPrice",
          header: After2ndMonthText,
          Cell: PriceCellFormatter,
        },
        {
          key: "After3rdMonthPrice",
          header: After3rdMonthText,
          Cell: PriceCellFormatter,
        },
        {
          key: "After4thMonthPrice",
          header: After4thMonthText,
          Cell: PriceCellFormatter,
        },
        {
          key: "After5thMonthPrice",
          header: After5thMonthText,
          Cell: PriceCellFormatter,
        },
      ];

      //TODO: delete test data

      const optionObj = createOptionObj(feeSimulationData.orderRelatedInfo.orderInfo.orderItemInfos);
      const optionRowData = optionObj.map((item) => {
        return { 
          OptionName: item?.CMAP_ProductName__c,
          StartMonthPrice: formatCurrencyChar(item?.simulatedOptionUnitPrice0),
          After1stMonthPrice: formatCurrencyChar(item?.simulatedOptionUnitPrice1),
          After2ndMonthPrice: formatCurrencyChar(item?.simulatedOptionUnitPrice2),
          After3rdMonthPrice: formatCurrencyChar(item?.simulatedOptionUnitPrice3),
          After4thMonthPrice: formatCurrencyChar(item?.simulatedOptionUnitPrice4),
          After5thMonthPrice: formatCurrencyChar(item?.simulatedOptionUnitPrice5),
        };
      });

      optionService.fields.rowData = optionRowData;

      // control sections display
      setIsMonthlyFeeHidden(monthlyExpenseRowData.length === 0);
      setIsOptionHidden(optionRowData.length === 0);
    }

    if (feeSimulationData && !feeSimulationData.Result) {
      setError(feeSimulationData.ErrorCode, {
        type: "manual",
        message: feeSimulationData.ErrorMassage,
      });
    }
  }, [feeSimulationData]);

  // @View
  return (
    <React.Fragment>
      <main>
        <div className="form_container cpad_wrapper2">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <EstimationArea
                rendering={props.rendering}
                isMonthlyFeeHidden={isMonthlyFeeHidden}
                isAnnualFeeHidden={isAnnualFeeHidden}
                isInitialCostHidden={isInitialCostHidden}
                isBenefitHidden={isBenefitHidden}
                isOptionHidden={isOptionHidden}
              />
              <AgencyInfoArea rendering={props.rendering} />
              <EstimationResultButtonArea rendering={props.rendering} />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(
  CPadEstimationResultNewApplicationPageLayout
);
