import React, { useState, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import { useFormDataStore, useCustomerInfoStore } from "../../hooks/useSimpleOpState";

// @Model

// get the data

// update the context

// @Controller

// @View

const I05 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    inputTitleText,
    requiredText,
    inputFormClass,
    addressPlaceholder,
    domainPlaceholder,
    isHidden,
    descriptionText,
    name,
    type
  } = properData;
  let {inputValue} = properData;
  const [isHiddenField, setIsHiddenField] = useState("");
  const formData = useFormDataStore((state) => state.formData);
  const customerInfo = useCustomerInfoStore((state) => state.customerInfo);

  if(Object.keys(formData).length > 0 && type === "productAttributes" && formData[name]) {
    inputValue = [
    {
      name: "emailAddress",
      value: formData[name]?.emailAddress
    },
    {
      name: "emailDomain",
      value: formData[name]?.emailDomain
    }];
  }

  if(Object.keys(customerInfo).length > 0 && type === "customerInfo" &&  customerInfo[name]) {
    inputValue = [
    {
      name: "emailAddress",
      value: customerInfo[name]?.emailAddress
    },
    {
      name: "emailDomain",
      value: customerInfo[name]?.emailDomain
    }];
  }


  useEffect(() => {
    setIsHiddenField(isHidden);
  }, [isHidden]);
  return (
    <React.Fragment>
      {!isHiddenField?.value && (
        <div className="input-box I-05">
          <div className={`input-form ${inputFormClass?.value}`}>
            <div className="input-container email">
              <label htmlFor="I05-1">
                <Text field={fillBlank(inputTitleText)} />
                {requiredText?.value && (
                  <span className="required">
                    <Text field={fillBlank(requiredText)} />
                  </span>
                )}
              </label>
              {/* TODO: This field is optional if description is needed */}
              {/* <p>
              <Text field={fillBlank(descriptionText)} />
            </p> */}
              <input
                type="text"
                id="I05-1"
                name={inputValue && inputValue[0]?.name}
                placeholder={`${addressPlaceholder?.value}`}
                value={inputValue && inputValue[0]?.value}
                onChange={props?.fields?.onChangeEvent}
                disabled={inputValue && inputValue[0]?.isDisabled}
              />
              <span>@</span>
              <input
                type="text"
                className="medium"
                name={inputValue && inputValue[1]?.name}
                placeholder={`${domainPlaceholder?.value}`}
                value={inputValue && inputValue[1]?.value}
                onChange={props?.fields?.onChangeEvent}
                disabled={inputValue && inputValue[1]?.isDisabled}
              />
              {descriptionText && descriptionText?.value !== "" && (
                <span className="ml-0 mt-16">
                  <Text field={fillBlank(descriptionText)} />
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default I05;
