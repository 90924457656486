export const siteSettingFormatter = (param) => {
  let result = null;
  const siteSetting = param?.item?.fields;
  if (siteSetting) {
    result = {};
    siteSetting.forEach(item => {
      const name = item?.name;
      const value = item?.value;
      const rendered = item?.rendered;
  
      result[name] = {"value" : value, "rendered": rendered};
    });
  }
  return result;
};