import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import {
  preventDoubleClick,
  getProperData,
  fillBlank,
} from "../../utils/commonUtils";

const B001i = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    buttonLabel,
    btnWrapperAdditionalClass,
    additionalClass,
    buttonIcon,
    isHidden,
  } = properData;
  const { buttonClick } = props;

  // @Controller
  const handleClick = preventDoubleClick(async () => {
    // sitecore-default function jumpt to page
    let urlInfo = props?.rendering?.fields?.linkURL?.value;
    let linkType = urlInfo?.linktype;
    let url = urlInfo?.href;

    if (linkType === "javascript") {
      eval(url);
      return true;
    }
    window.location.href = url;
  });

  // @View
  return (
    <React.Fragment>
      {!isHidden && (
        <div
          className={`btn_wrapper primary B-01i ${btnWrapperAdditionalClass?.value}`}
        >
          {/* className="btn_wrapper primary disabled B-01i" */}
          <button
            className={`btn btn_icon ${additionalClass?.value}`}
            onClick={handleClick}
            type="button"
          >
            {buttonIcon?.value && (
              <img src={fillBlank(buttonIcon)?.value?.src} />
            )}
            <Text field={fillBlank(buttonLabel)} />
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default B001i;
