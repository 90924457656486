import gql from "graphql-tag";

export const query = gql`
  query {
    MembersWithBankAccountRegistrationAndConstructionSchedulingSteps: item(
      path: "/sitecore/content/jss/masterjss/Data/Steps After Ordering List/Member With Bank Account Registration and Construction Scheduling Steps"
    ) {
      fields(excludeStandardFields: true) {
        name
        value
        editable
        ...on MultilistField {
          targetItems {
            fields(excludeStandardFields: true) {
              name
              value
              editable
              ...on LookupField {
                targetItem {
                  field(name: "Code") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    MemberWithBankAccountRegistrationStep: item(
      path: "/sitecore/content/jss/masterjss/Data/Steps After Ordering List/Member With Bank Account Registration Step"
    ) {
      fields(excludeStandardFields: true) {
        name
        value
        editable
        ...on MultilistField {
          targetItems {
            fields(excludeStandardFields: true) {
              name
              value
              editable
              ...on LookupField {
                targetItem {
                  field(name: "Code") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    MemberWithConstructionSchedulingStep: item(
      path: "/sitecore/content/jss/masterjss/Data/Steps After Ordering List/Member With Construction Scheduling Step"
    ) {
      fields(excludeStandardFields: true) {
        name
        value
        editable
        ...on MultilistField {
          targetItems {
            fields(excludeStandardFields: true) {
              name
              value
              editable
              ...on LookupField {
                targetItem {
                  field(name: "Code") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    MemberWithoutAnyStep: item(
      path: "/sitecore/content/jss/masterjss/Data/Steps After Ordering List/Member Without Any Step"
    ) {
      fields(excludeStandardFields: true) {
        name
        value
        editable
        ...on MultilistField {
          targetItems {
            fields(excludeStandardFields: true) {
              name
              value
              editable
              ...on LookupField {
                targetItem {
                  field(name: "Code") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    PotentialMemberWithBankAccountRegistrationAndConstructionSchedulingSteps: item(
      path: "/sitecore/content/jss/masterjss/Data/Steps After Ordering List/Potential Member With Bank Account Registration and Construction Scheduling Steps"
    ) {
      fields(excludeStandardFields: true) {
        name
        value
        editable
        ...on MultilistField {
          targetItems {
            fields(excludeStandardFields: true) {
              name
              value
              editable
              ...on LookupField {
                targetItem {
                  field(name: "Code") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    PotentialMemberWithBankAccountRegistrationStep: item(
      path: "/sitecore/content/jss/masterjss/Data/Steps After Ordering List/Potential Member With Bank Account Registration Step"
    ) {
      fields(excludeStandardFields: true) {
        name
        value
        editable
        ...on MultilistField {
          targetItems {
            fields(excludeStandardFields: true) {
              name
              value
              editable
              ...on LookupField {
                targetItem {
                  field(name: "Code") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    PotentialMemberWithConstructionSchedulingStep: item(
      path: "/sitecore/content/jss/masterjss/Data/Steps After Ordering List/Potential Member With Construction Scheduling Step"
    ) {
      fields(excludeStandardFields: true) {
        name
        value
        editable
        ...on MultilistField {
          targetItems {
            fields(excludeStandardFields: true) {
              name
              value
              editable
              ...on LookupField {
                targetItem {
                  field(name: "Code") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    PotentialMemberWithoutAnyStep: item(
      path: "/sitecore/content/jss/masterjss/Data/Steps After Ordering List/Potential Member Without Any Step"
    ) {
      fields(excludeStandardFields: true) {
        name
        value
        editable
        ...on MultilistField {
          targetItems {
            fields(excludeStandardFields: true) {
              name
              value
              editable
              ...on LookupField {
                targetItem {
                  field(name: "Code") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    NonMemberWithBankAccountRegistrationAndConstructionSchedulingSteps: item(
      path: "/sitecore/content/jss/masterjss/Data/Steps After Ordering List/Non-Member With Bank Account Registration And Construction Scheduling Steps"
    ) {
      fields(excludeStandardFields: true) {
        name
        value
        editable
        ...on MultilistField {
          targetItems {
            fields(excludeStandardFields: true) {
              name
              value
              editable
              ...on LookupField {
                targetItem {
                  field(name: "Code") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    NonMemberWithBankAccountRegistrationStep: item(
      path: "/sitecore/content/jss/masterjss/Data/Steps After Ordering List/Non-Member With Bank Account Registration Step"
    ) {
      fields(excludeStandardFields: true) {
        name
        value
        editable
        ...on MultilistField {
          targetItems {
            fields(excludeStandardFields: true) {
              name
              value
              editable
              ...on LookupField {
                targetItem {
                  field(name: "Code") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    NonMemberWithConstructionSchedulingStep: item(
      path: "/sitecore/content/jss/masterjss/Data/Steps After Ordering List/Non-Member With Construction Scheduling Step"
    ) {
      fields(excludeStandardFields: true) {
        name
        value
        editable
        ...on MultilistField {
          targetItems {
            fields(excludeStandardFields: true) {
              name
              value
              editable
              ...on LookupField {
                targetItem {
                  field(name: "Code") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    NonMemberWithoutAnyStep: item(
      path: "/sitecore/content/jss/masterjss/Data/Steps After Ordering List/Non-Member Without Any Step"
    ) {
      fields(excludeStandardFields: true) {
        name
        value
        editable
        ...on MultilistField {
          targetItems {
            fields(excludeStandardFields: true) {
              name
              value
              editable
              ...on LookupField {
                targetItem {
                  field(name: "Code") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
