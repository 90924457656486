export const query = `query {

  SimpleOpApplication: item(path: "/sitecore/content/jss/masterjss/Data/SimpleOpApplication") {
    children {
      sortOrder : field(name : "__sortOrder") {
        value
      }
      fields(excludeStandardFields: true) {
        name
        value
      }
      children {
        sortOrder : field(name : "__sortOrder") {
          value
        }
        fields(excludeStandardFields: true) {
          name
          value
          rendered
          editable
          ...on LookupField {
          targetItem {
            Value: field(name: "Value") {
              value
            }
            Description: field(name: "Description") {
              value
            }
          }
        }
      }
        children {
          sortOrder : field(name : "__sortOrder") {
            value
          }
          fields(excludeStandardFields: true) {
          name
          value
          rendered
          editable
          ...on LookupField {
          targetItem {
            Value: field(name: "Value") {
              value
            }
            Description: field(name: "Description") {
              value
            }
          }
        }
      }
        }
    }
  }
  }
}`;
