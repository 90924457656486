import React from "react";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import {
  fillBlank,
  getScDataFromPlaceholder,
  getScDataFromComponent,
} from "../../utils/commonUtils";
import { useCustomQuery } from "../../hooks/useGetData";
import { apiBaseURL } from "../../envSettings";
import { STRING_GET } from "../../utils/constantVariables";

export function CommonInformationList(props) {
  const sitecoreContext = props?.sitecoreContext;
  const {
    "CommonInformationList-Title": CommonInformationListTitle,
    "CommonInformationList-DisplayCount": CommonInformationListDisplayCount,
  } = sitecoreContext?.route?.fields;

  const RetrieveInformationListURI = `${apiBaseURL}common/RetrieveInformationList?SiteName=mycommufa&DisplayCount=${CommonInformationListDisplayCount?.value}`;

  const {
    data: RetrieveInformationListData,
    isLoading: isRetrieveInformationListLoading,
    error: RetrieveInformationListError,
    refetch: RetrieveInformationListRefetch,
    remove: RetrieveInformationListRemove,
  } = useCustomQuery(RetrieveInformationListURI, true, STRING_GET);

  props.setIsLoading(isRetrieveInformationListLoading);

  const informationList = RetrieveInformationListData?.RetrieveInformations;

  const btnSection = getScDataFromPlaceholder("see-more-button-section", props);
  const [b02Data] = getScDataFromComponent("B-02", btnSection);
  b02Data.fields.queryString = "MyCommufaPublication=true";

  return (
    <>
      {informationList?.length > 0 && (
        <div className="list_wrapper single-column">
          <div className="h_title">
            <h2>
              <Text field={fillBlank(CommonInformationListTitle?.value)} />
            </h2>
          </div>
          <div className="column-list">
            <ul>
              {informationList?.map((infoItem, i) => (
                <li key={i}>
                  <span className="bold text-primary">
                    {`${new Date(
                      infoItem?.PublicationStartDate
                    ).getFullYear()}/${
                      new Date(infoItem?.PublicationStartDate).getMonth() + 1
                    }/${new Date(infoItem?.PublicationStartDate).getDate()}`}
                  </span>
                  {/* TODO logic for information type */}
                  <div className="tag P-03 gray">
                    <span>{infoItem?.InformationType}</span>
                  </div>
                  <a href={infoItem?.InformationDetailURL}>
                    <p>
                      <Text field={fillBlank(infoItem?.InformationTitle)} />
                    </p>
                  </a>
                </li>
              ))}
            </ul>
            <Placeholder
              name="see-more-button-section"
              rendering={props.rendering}
            />
          </div>
        </div>
      )}
    </>
  );
}
