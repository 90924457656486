import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import ModalContext from "../../assets/context/ModalContext";
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const BM02 = (props) => {
  const { additionalClass, modalButtonLabel, modalTarget } = props.fields;

  const { state, dispatch } = useContext(ModalContext);

  const modalButtonShowHandler = () => {
    dispatch({ type: "SHOW_MODAL", payload: { id: modalTarget?.value } });
  };

  return (
    <React.Fragment>
      <div className="btn_wrapper secondary BM-02">
        <button
          className={`btn btn_default ${additionalClass?.value}`}
          type="button"
          onClick={() => modalButtonShowHandler()}
        >
          <Text field={fillBlank(modalButtonLabel)} />
        </button>
      </div>
    </React.Fragment>
  );
};

export default BM02;
