import React, { useEffect, useState } from "react";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import parse from "html-react-parser";

import { apiBaseURL, cpadPageURL } from "../../envSettings";
import { query as planTypeMasterQuery } from "../../assets/graphql/planTypeMaster";
import { query as contractNameTypeMasterQuery } from "../../assets/graphql/contractNameTypeMaster";
import { query as residentTypeMasterQuery } from "../../assets/graphql/residentTypeMaster";
import { useGetDataViaGraphQL, useCustomQuery } from "../../hooks/useGetData";

import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { di_register, di_fetch } from "../../utils/di";
import { recoilKeys as keys } from "../../assets/recoilKeys";

import {
  useInputValueState,
  useSetSelectedItem,
} from "../../hooks/useSelectedState";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";

import {
  setSessionStorageItem,
  getSessionStorageItem,
} from "../../utils/useSessionStorage";
import {
  stringEmpty,
  getScDataFromPlaceholder,
  getScDataFromComponent,
  redirectToLinkUrl,
  getUrlInfo,
} from "../../utils/commonUtils";

import { validationFuncs } from "../../utils/validations";
import { useFormContext } from "react-hook-form";
import { getErrorMessage } from "../../utils/errorMessageHandler";

import {
  STRING_RESIDENCE_TYPE_HOME,
  STRING_RESIDENCE_TYPE_APARTMENT,
  STRING_CIRCUIT,
  STRING_WIRELESS_CIRCUIT,
  STRING_NOT_CIRCUIT,
  STRING_GOODS_SERVICE_SALES,
  STRING_MOVING,
  STRING_CHANGEPLAN,
  STRING_SIMULATOR,
  STRING_APPLY,
  STRING_APPINFO_PLANTYPE,
  STRING_LOADING,
  STRING_REQUEST_WEB,
} from "../../utils/constantVariables";
import { CommonServiceUnavailableArea } from "../../services/common/common-service-unavailable-area";

/** Layouts */
// rendering items
const SelectPlanType = (props) => {
  return (
    <div className="form_wrapper-input">
      <Placeholder
        name="plan-type-selection-section"
        rendering={props.rendering}
      />
    </div>
  );
};

const SelectContractNameType = (props) => {
  return (
    <div className="form_wrapper-input">
      <Placeholder
        name="money-deposit-name-selection-section"
        rendering={props.rendering}
      />
    </div>
  );
};

const SelectPlanTypePageTransitionButtons = (props) => {
  return (
    <div className="btn_container">
      <Placeholder name="btn_container" rendering={props.rendering} />
    </div>
  );
};

const AddressSearch = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const pageContext = sitecoreContext?.route?.fields;

  const [postalLabel, areaSearchLabel, residenceTypeTitle, AreaTitle] = [
    pageContext["AddressSearch-PostalCode"]?.value,
    pageContext["AddressSearch-AreaSearchTitle"]?.value,
    pageContext["AddressSearch-ResidentialTypeTitle"]?.value,
    pageContext["AddressSearch-AreaTitle"]?.value,
  ];

  const isAddressSelectHidden = props.isAddressSelectHidden ?? false;
  const isResidenceSelectHidden = props.isResidenceSelectHidden ?? false;
  const isAreaHidden = props.isAreaHidden ?? false;

  return (
    <div className="form_wrapper-input">
      <Placeholder name="address-search-section" rendering={props.rendering} />
      <div className="list_wrapper address">
        {!isResidenceSelectHidden && (
          <div className="address-section">
            <div className="content-title">
              <h5>
                <Text field={{ value: residenceTypeTitle }} />
              </h5>
            </div>
            <Placeholder
              name="address-search-residence-select-container"
              rendering={props.rendering}
            />
          </div>
        )}
        <div className="address-section">
          <div className="content-title">
            <h5>
              <Text field={{ value: postalLabel }} />
            </h5>
          </div>
          <div className="input-with-button">
            <Placeholder
              name="postal-code-container"
              rendering={props.rendering}
            />
          </div>
          <Placeholder
            name="postal-description-container"
            rendering={props.rendering}
          />
        </div>
        {!isAddressSelectHidden && (
          <div className="address-section">
            <div className="content-title">
              <h5>
                <Text field={{ value: areaSearchLabel }} />
              </h5>
            </div>
            <Placeholder
              name="address-search-address-select-container"
              rendering={props.rendering}
            />
          </div>
        )}
        {!isAreaHidden && (
          <div className="address-section">
            <div className="content-title">
              <h5>
                <Text field={{ value: AreaTitle }} />
              </h5>
            </div>
            <Placeholder
              name="address-search-area-container"
              rendering={props.rendering}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ServiceUnavailableArea = (props) => {
  const isAreaNotificationInformationListHidden =
    props.isAreaNotificationInformationListHidden ?? false;
  const isAreaApplicationInformationListHidden =
    props.isAreaApplicationInformationListHidden ?? false;
  const sitecoreContext = props?.sitecoreContext;

  return (
    <div className="form_wrapper-input">
      <CommonServiceUnavailableArea
        screen={STRING_APPINFO_PLANTYPE}
        rendering={props.rendering}
        sitecoreContext={sitecoreContext}
        isAreaNotificationInformationListHidden={
          isAreaNotificationInformationListHidden
        }
        isAreaApplicationInformationListHidden={
          isAreaApplicationInformationListHidden
        }
        setIsLoading={props.setIsLoading}
      />
    </div>
  );
};

const SelectPlanTypeLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  const pageContext = sitecoreContext?.route?.fields;
  di_register("thisPageSitecoreContext", sitecoreContext);

  // error hook
  const { setError, clearErrors } = useFormContext();

  // page url/path
  const [siteURL, setSiteURL] = useState("");
  const [pathName, setPathName] = useState("");
  const [hostName, setHostName] = useState("");

  const isChangePlanPath = pathName?.toLocaleLowerCase() === STRING_CHANGEPLAN;
  const isApplyPath = pathName?.toLocaleLowerCase() === STRING_APPLY;
  const isSimulatorPath = pathName?.toLocaleLowerCase() === STRING_SIMULATOR;
  const isMovingPath = pathName?.toLocaleLowerCase() === STRING_MOVING;
  const isCpad = siteURL === cpadPageURL;

  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  // get master data
  const planTypeMaster = useGetDataViaGraphQL(
    planTypeMasterQuery,
    null,
    "planTypeMaster"
  );
  const contractNameTypeMaster = useGetDataViaGraphQL(
    contractNameTypeMasterQuery,
    null,
    "contractNameTypeMaster"
  );
  const residentTypeMaster = useGetDataViaGraphQL(
    residentTypeMasterQuery,
    null,
    "residentTypeMaster"
  );

  // get data for session storage
  const userPlanType = getSessionStorageItem(
    keys.SSN_APPLICATION_SELECTPLANTYPE_PLANTYPE,
    true
  );
  const userPostalCode3digit = getSessionStorageItem(
    keys.SSN_APPLICATION_SELECTPLANTYPE_POSTALCODE3DIGIT,
    true
  );
  const userPostalCode4digit = getSessionStorageItem(
    keys.SSN_APPLICATION_SELECTPLANTYPE_POSTALCODE4DIGIT,
    true
  );
  const userAddressCode = getSessionStorageItem(
    keys.SSN_APPLICATION_SELECTPLANTYPE_ADDRESSCODE,
    true
  );
  const userCustomerId = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_CUSTOMERID,
    true
  );
  const personalizeInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );
  const orderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );
  const beforeOrderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO,
    true
  );

  const userContractName =
    orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account
      ?.CMAP_ContractName__c;
  const userResidenceType =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_ResidenceType__c;

  // states
  const [postalCode3, setPostalCode3] = useInputValueState("addresspost3", "");
  const [postalCode4, setPostalCode4] = useInputValueState("addresspost4", "");
  const [postalCode, setPostalCode] = useState(
    !stringEmpty(userPostalCode3digit) && !stringEmpty(userPostalCode4digit)
      ? "" + userPostalCode3digit + userPostalCode4digit
      : ""
  );
  const [addressCode, setAddressCode] = useState("");

  const orderChannelCategory = personalizeInfo?.AppChannelDivision || STRING_REQUEST_WEB;


  const searchForPostalCodeURI = `${apiBaseURL}order/RetrievePrefectureMunicipalityList?postalCode=${postalCode}`;
  const getApartmentOfferURI = `${apiBaseURL}order/RetrieveApartmentOfferMethodList?addressCode=${addressCode}&orderChannelCategory=${orderChannelCategory}&pageNo=1&itemsPerPage=1`;

  const [addressId, setAddressId] = useState("");
  const [cityName, setCityName] = useState("");
  const [cityCode, setCityCode] = useState("");
  const [
    installationLocationPrefectureCode,
    setInstallationLocationPrefectureCode,
  ] = useState("");
  const [
    installationLocationAddress,
    setInstallationLocationAddress,
  ] = useState("");
  const [stateName, setStateName] = useState("");
  const [streetName, setStreetName] = useState("");

  // エリア検索が0件の場合の表示フラグ

  // Prepare API calls
  // 郵便番号検索API
  const {
    data: searchPostalData,
    isLoading: searchPostalIsLoading,
    error: searchPostalError,
    refetch: searchPostalRefresh,
    remove: searchPostalRemove,
  } = useCustomQuery(searchForPostalCodeURI, false);

  // 物件提供方式リストAPI準備
  const {
    data: apartmentOfferData,
    isLoading: apartmentOfferIsLoading,
    error: apartmentOfferError,
    refetch: apartmentOfferRefresh,
    remove: apartmentOfferRemove,
  } = useCustomQuery(getApartmentOfferURI, false);

  const isLoading =
    searchPostalIsLoading ||
    apartmentOfferIsLoading ||
    planTypeMaster === STRING_LOADING ||
    contractNameTypeMaster === STRING_LOADING ||
    residentTypeMaster === STRING_LOADING;
  props.setIsLoading(isLoading);

  const [isFirstPostalCall, setIsFirstPostalCall] = useState(true);

  // Area display
  // ご利用先住所検索（Area - AddressSearch）
  const [isSearchErrorMsgAreaHidden, setIsSearchErrorMsgAreaHidden] = useState(
    true
  );
  const [isAddressSearchHidden, setIsAddressSearchHidden] = useState(true);
  const [isAddressSelectHidden, setIsAddressSelectHidden] = useState(true);
  const [isResidenceSelectHidden, setIsResidenceSelectHidden] = useState(true);
  const [isAreaHidden, setIsAreaHidden] = useState(true);

  //サービス提供外エリア（Area - ServiceUnavailableArea）
  const [
    isAreaServiceUnavailableAreaHidden,
    setIsAreaServiceUnavailableAreaHidden,
  ] = useState(true);

  // お申し込み案内リスト(Area - ApplicationInformationList)
  const [
    isAreaApplicationInformationListHidden,
    setIsAreaApplicationInformationListHidden,
  ] = useState(true);

  // お知らせ案内リスト(Area - NotificationInformationList)
  const [
    isAreaNotificationInformationListHidden,
    setIsAreaNotificationInformationListHidden,
  ] = useState(true);

  // Map components
  // map area D-01
  const addressAreaContainer = getScDataFromPlaceholder(
    "address-search-area-container",
    props
  );
  const [areaD01] = getScDataFromComponent("D-01", addressAreaContainer);
  const [area, setArea] = useState("");

  if (areaD01) {
    areaD01.fields.descriptionText = area;
  }

  // map address select
  const addressSelectContainer = getScDataFromPlaceholder(
    "address-search-address-select-container",
    props
  );
  const [addressM01] = getScDataFromComponent("M-01", addressSelectContainer);
  const [addressValue, setAddressValue] = useInputValueState(
    "addressValue",
    ""
  );
  const [addressList, setAddressList] = useState([]);

  if (addressM01) {
    addressM01.fields.pulldownData = addressList;
    addressM01.fields.formName = "addressList";
    addressM01.fields.initialValue = { value: addressValue };
    addressM01.fields.onChangeEvent = (event) => {
      setAddressValue(event.target.value);
    };
  }

  // map residence select
  const residenceSelectContainer = getScDataFromPlaceholder(
    "address-search-residence-select-container",
    props
  );
  const [residenceM01] = getScDataFromComponent(
    "M-01",
    residenceSelectContainer
  );
  const [residenceType, setResidenceType] = useInputValueState(
    "residenceType",
    ""
  );
  const [residenceTypeList, setResidenceTypeList] = useState([]);

  if (residenceM01) {
    residenceM01.fields.pulldownData = residenceTypeList;
    residenceM01.fields.formName = "residenceTypeList";
    residenceM01.fields.initialValue = { value: residenceType };
    residenceM01.fields.onChangeEvent = (event) => {
      setResidenceType(event.target.value);
    };
  }

  // map selectPlanType radio button
  const planTypeSelectionSection = getScDataFromPlaceholder(
    "plan-type-selection-section",
    props
  );
  const [S02Radio] = getScDataFromComponent("S-02", planTypeSelectionSection);
  const selectPlanTypeRadioCategory = "selectedPlanType";

  const [selectedPlanType, setSelectedPlanType] = useSetSelectedItem(
    selectPlanTypeRadioCategory
  );

  const [planTypeOptions, setPlanTypeOptions] = useState([]);
  S02Radio.fields["category"] = { value: selectPlanTypeRadioCategory };
  S02Radio.fields["selections"] = planTypeOptions;

  const [isSelectPlanRadioDisabled, setSelectPlanRadioDisabled] = useState(
    false
  );
  S02Radio.fields.isDisabled = { value: isSelectPlanRadioDisabled };

  // map selectedMoneyDepositName radio button
  const moneyDepositNameSelectionSection = getScDataFromPlaceholder(
    "money-deposit-name-selection-section",
    props
  );
  const [S05Radio] = getScDataFromComponent(
    "S-05",
    moneyDepositNameSelectionSection
  );
  const [moneyDepositNameOptions, setMoneyDepositNameOptions] = useState([]);
  const moneyDepositNameRadioCategory = "selectedMoneyDepositName";
  S05Radio.fields["category"] = { value: moneyDepositNameRadioCategory };
  S05Radio.fields["selections"] = moneyDepositNameOptions;

  const [
    selectedMoneyDepositName,
    setSelectedMoneyDepositName,
  ] = useSetSelectedItem(moneyDepositNameRadioCategory);

  const [
    isMoneyDepositNameRadioDisabled,
    setIsMoneyDepositNameRadioDisabled,
  ] = useState(false);

  // money deposit name
  const moneyDepositNameH02 = getScDataFromComponent(
    "H-02",
    moneyDepositNameSelectionSection
  )[0];

  if (moneyDepositNameH02?.fields && S05Radio?.fields) {
    moneyDepositNameH02.fields.isAdditionalRichTextHidden = !isMoneyDepositNameRadioDisabled;
    S05Radio.fields.isDisabled = { value: isMoneyDepositNameRadioDisabled };
  }

  // 検索結果のエラー表示エリア
  const addressSearchSection = getScDataFromPlaceholder(
    "address-search-section",
    props
  );
  const [searchErrorMsgA02] = getScDataFromComponent(
    "A-02",
    addressSearchSection
  );
  searchErrorMsgA02.fields.isShow = !isSearchErrorMsgAreaHidden;
  const [postalCodeNotApplicableMessage, areaNotApplicableMessage] = [
    pageContext["AddressSearch-PostalCodeNotApplicableMessage"]?.value,
    pageContext["AddressSearch-AreaNotApplicableMessage"]?.value,
  ];

  //郵便番号が入力された時の処理
  const searchPlaceholderData = getScDataFromPlaceholder(
    "postal-code-container",
    props
  );
  const [postalCodeSearchInputs] = getScDataFromComponent(
    "I-02",
    searchPlaceholderData
  );

  postalCodeSearchInputs.fields.inputValue = [
    {
      value: postalCode3,
      name: "postalCode3Input",
    },
    {
      value: postalCode4,
      name: "postalCode4Input",
    },
  ];

  postalCodeSearchInputs.fields.onChangeEvent = (event) => {
    // set new value
    const newValue = event.target.value;
    const name = event.target.name;
    let newPostalCode = postalCode;

    if (name === postalCodeSearchInputs.fields?.inputValue[0]?.name) {
      newPostalCode = "" + newValue + postalCode4;
      setPostalCode3(newValue);
    } else if (name === postalCodeSearchInputs.fields?.inputValue[1]?.name) {
      newPostalCode = "" + postalCode3 + newValue;
      setPostalCode4(newValue);
    }

    setPostalCode(newPostalCode);

    // reset data on change
    setIsAddressSelectHidden(true);
    setIsAreaHidden(true);

    setAddressId("");
    setCityCode("");
    setInstallationLocationPrefectureCode("");
    setInstallationLocationAddress("");
    setStateName("");
    setCityName("");
    setStreetName("");
  };

  // Search postal data Button
  const [searchBtnData] = getScDataFromComponent("B-01", searchPlaceholderData);
  searchBtnData.fields.onClickEvent = (e) => {
    searchAddress();
  };

  // Transition Buttons
  const btn_container = getScDataFromPlaceholder("btn_container", props);
  const [backButton] = getScDataFromComponent("B-02", btn_container);
  const [nextButton] = getScDataFromComponent("B-01", btn_container);

  // return btn click event
  backButton.fields.onClickEvent = () => {
    const backOrderRelatedInfo = {
      ...orderRelatedInfo,
      orderRelatedInfo: {
        ...orderRelatedInfo?.orderRelatedInfo,
        orderInfo: {
          ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
          order: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
            CMAP_ResidenceType__c: residenceType,
          },
        },
        accountInfo: {
          ...orderRelatedInfo?.orderRelatedInfo?.accountInfo,
          account: {
            ...orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account,
            CMAP_ContractName__c: selectedMoneyDepositName?.selected,
          },
        },
      },
    };

    setSessionStorageItem(
      keys.SSN_APPLICATION_SELECTPLANTYPE_PLANTYPE,
      selectedPlanType?.selected,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_SELECTPLANTYPE_POSTALCODE3DIGIT,
      postalCode3,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_SELECTPLANTYPE_POSTALCODE4DIGIT,
      postalCode4,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_SELECTPLANTYPE_ADDRESSCODE,
      addressCode,
      true
    );
    setSessionStorageItem(
      keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
      backOrderRelatedInfo,
      true
    );

    const returnBackButtonLinkForContractOrApplicationChange =
      pageContext[
        "SelectPlanTypeTransitionButtons-BackButtonLinkForContractOrApplicationChange"
      ];
    const returnBackButtonLinkForNewApplication =
      pageContext[
        "SelectPlanTypeTransitionButtons-BackButtonLinkForNewApplication"
      ];

    if (!isCpad) {
      if (isApplyPath || isSimulatorPath) {
        redirectToLinkUrl(returnBackButtonLinkForNewApplication);
      } else {
        redirectToLinkUrl(returnBackButtonLinkForContractOrApplicationChange);
      }
    } else {
      redirectToPreviousPage();
    }
  };

  // next button disable logic
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  nextButton.fields.isDisabled = { value: isNextButtonDisabled };

  // next button click event
  nextButton.fields.onClickEvent = () => {
    //バリデーション実施
    let isValidate = true;
    clearErrors();

    // プラン種類ラジオボタン
    if (!validationFuncs.validateIsRequired(selectedPlanType.selected)) {
      setError("selectedPlanType", {
        type: "manual",
        message: getErrorMessage("E0002", ["プラン種類"]),
      });
      isValidate = false;
    }
    // 契約名義区分ラジオボタン
    if (
      !validationFuncs.validateIsRequired(selectedMoneyDepositName.selected)
    ) {
      setError("selectedMoneyDepositName", {
        type: "manual",
        message: getErrorMessage("E0002", ["ご契約名義"]),
      });
      isValidate = false;
    }
    // 郵便番号チェック (3桁)
    if (!validationFuncs.validateIsRequired(postalCode3)) {
      setError("postalCode3Input-1", {
        type: "manual",
        message: getErrorMessage("E0001", ["郵便番号（3桁）"]),
      });
      isValidate = false;
    }
    if (!validationFuncs.validateIsNumber(postalCode3)) {
      setError("postalCode3Input-2", {
        type: "manual",
        message: getErrorMessage("E0007", ["郵便番号（3桁）"]),
      });
      isValidate = false;
    }
    if (!validationFuncs.validateIsFixedLength(postalCode3, 3)) {
      setError("postalCode3Input-3", {
        type: "manual",
        message: getErrorMessage("E0018", ["郵便番号（3桁）", 3]),
      });
      isValidate = false;
    }
    // 郵便番号チェック (4桁)
    if (!validationFuncs.validateIsRequired(postalCode4)) {
      setError("postalCode4Input-1", {
        type: "manual",
        message: getErrorMessage("E0001", ["郵便番号（4桁）"]),
      });
      isValidate = false;
    }
    if (!validationFuncs.validateIsNumber(postalCode4)) {
      setError("postalCode4Input-2", {
        type: "manual",
        message: getErrorMessage("E0007", ["郵便番号（4桁）"]),
      });
      isValidate = false;
    }
    if (!validationFuncs.validateIsFixedLength(postalCode4, 4)) {
      setError("postalCode4Input-3", {
        type: "manual",
        message: getErrorMessage("E0018", ["郵便番号（4桁）", 4]),
      });
      isValidate = false;
    }
    // 地域選択リスト
    if (
      !validationFuncs.validateIsRequired(cityName) ||
      !validationFuncs.validateIsRequired(cityCode) ||
      !validationFuncs.validateIsRequired(installationLocationAddress) ||
      !validationFuncs.validateIsRequired(stateName) ||
      !validationFuncs.validateIsRequired(streetName)
    ) {
      setError("address-1", {
        type: "manual",
        message: getErrorMessage("E0002", ["地域"]),
      });
      isValidate = false;
    }
    // 住戸タイプリスト
    if (
      !isResidenceSelectHidden &&
      !validationFuncs.validateIsRequired(residenceType)
    ) {
      setError("residenceType-1", {
        type: "manual",
        message: getErrorMessage("E0002", ["住戸タイプ"]),
      });
      isValidate = false;
    }

    if (!isValidate) {
      return;
    }

    apartmentOfferRefresh();
  };

  // Methods
  const switchResidenceVisibility = (selectedPlanCode) => {
    if (selectedPlanCode === STRING_CIRCUIT) {
      // CIRCUIT
      setIsResidenceSelectHidden(false);
    } else {
      // NOT CIRCUIT
      setIsResidenceSelectHidden(true);
    }
  };

  const switchAddressAreasVisibility = (selectedPlanCode) => {
    if (addressList.length > 0) {
      if (selectedPlanCode === STRING_CIRCUIT) {
        // CIRCUIT
        if (residenceType === STRING_RESIDENCE_TYPE_HOME) {
          // CIRCUIT + HOME
          setIsAddressSelectHidden(true);
          setIsAreaHidden(false);
        } else if (residenceType === STRING_RESIDENCE_TYPE_APARTMENT) {
          // CIRCUIT + APARTMENT
          setIsAddressSelectHidden(false);
          setIsAreaHidden(true);
        }
      } else {
        // NOT CIRCUIT
        setIsAreaHidden(true);
        setIsAddressSelectHidden(false);
      }
    }
  };

  const setAddressInfos = () => {
    let addressInfos = {};
    if (addressList?.length > 0) {
      if (residenceType !== STRING_RESIDENCE_TYPE_HOME) {
        // find the selected item
        addressInfos = addressList.find((x) => x.value === addressValue)?.data;
      }

      if (!addressInfos?.cityCode) {
        // set the first item by default
        addressInfos = addressList[0]?.data;
      }

      setAddressId(addressInfos?.id);
      setCityCode(addressInfos?.cityCode);
      setInstallationLocationPrefectureCode(
        addressInfos?.installationLocationPrefectureCode
      );
      setInstallationLocationAddress(addressInfos?.installationLocationAddress);
      setAddressCode(addressInfos?.addressCode);
      setStateName(addressInfos?.stateName);
      setCityName(addressInfos?.cityName);
      setStreetName(addressInfos?.streetName);

      setIsNextButtonDisabled(false);
    }
  };

  const searchAddress = (isInit = false) => {
    if (!isInit) {
      //バリデーション実施
      let isValidate = true;
      clearErrors();

      // プラン種類ラジオボタン
      if (!validationFuncs.validateIsRequired(selectedPlanType.selected)) {
        setError("selectedPlanType", {
          type: "manual",
          message: getErrorMessage("E0002", ["プラン種類"]),
        });
        isValidate = false;
      }
      // 契約名義区分ラジオボタン
      if (
        !validationFuncs.validateIsRequired(selectedMoneyDepositName.selected)
      ) {
        setError("selectedMoneyDepositName", {
          type: "manual",
          message: getErrorMessage("E0002", ["ご契約名義"]),
        });
        isValidate = false;
      }
      // 郵便番号チェック (3桁)
      if (!validationFuncs.validateIsRequired(postalCode3)) {
        setError("postalCode3Input-1", {
          type: "manual",
          message: getErrorMessage("E0001", ["郵便番号（3桁）"]),
        });
        isValidate = false;
      }
      if (!validationFuncs.validateIsNumber(postalCode3)) {
        setError("postalCode3Input-2", {
          type: "manual",
          message: getErrorMessage("E0007", ["郵便番号（3桁）"]),
        });
        isValidate = false;
      }
      if (!validationFuncs.validateIsFixedLength(postalCode3, 3)) {
        setError("postalCode3Input-3", {
          type: "manual",
          message: getErrorMessage("E0018", ["郵便番号（3桁）", 3]),
        });
        isValidate = false;
      }
      // 郵便番号チェック (4桁)
      if (!validationFuncs.validateIsRequired(postalCode4)) {
        setError("postalCode4Input-1", {
          type: "manual",
          message: getErrorMessage("E0001", ["郵便番号（4桁）"]),
        });
        isValidate = false;
      }
      if (!validationFuncs.validateIsNumber(postalCode4)) {
        setError("postalCode4Input-2", {
          type: "manual",
          message: getErrorMessage("E0007", ["郵便番号（4桁）"]),
        });
        isValidate = false;
      }
      if (!validationFuncs.validateIsFixedLength(postalCode4, 4)) {
        setError("postalCode4Input-3", {
          type: "manual",
          message: getErrorMessage("E0018", ["郵便番号（4桁）", 4]),
        });
        isValidate = false;
      }

      if (!isValidate) {
        return;
      }
    }

    if (stringEmpty(postalCode)) {
      return;
    }

    // API通信
    setAddressList([]);
    searchPostalRefresh();

    if (isInit) {
      return;
    }
  };

  // UseEffecs
  // on page load
  useEffect(() => {
    // Call API
    // postal data api
    if (userAddressCode && userPostalCode3digit && userPostalCode4digit) {
      searchAddress(true);
    } else {
      setIsFirstPostalCall(false); // API is not being called on first render
    }

    // Initial values
    if (userAddressCode) {
      setAddressCode(userAddressCode);
      setAddressValue(userAddressCode);
    } else {
      setIsNextButtonDisabled(true);
    }

    if (userResidenceType) {
      setResidenceType(userResidenceType);
    } else {
      setResidenceType(STRING_RESIDENCE_TYPE_HOME); // default
    }

    if (userPostalCode3digit) {
      setPostalCode3(userPostalCode3digit);
    }
    if (userPostalCode4digit) {
      setPostalCode4(userPostalCode4digit);
    }

    // Urls infos
    const urlInfo = getUrlInfo();

    setSiteURL(urlInfo?.origin);
    setPathName(urlInfo?.pathnames[1]);
    setHostName(urlInfo?.hostname);
  }, []);

  // receive planTypeMaster data
  useEffect(() => {
    // set selectPlanTypeRadioOptions datas
    const selectPlanTypeRadioOptions = planTypeMaster?.planTypeMaster?.children?.map(
      (item) => {
        const descriptionUrlHtml = item.fields.find(
          (x) => x.name === "PlanTypeMasterURL"
        )?.value;
        const descriptionUrl = parse(descriptionUrlHtml)?.props?.url;

        return {
          Code: item.fields.find((x) => x.name === "PlanTypeMasterCode")?.value,
          Value: item.fields.find((x) => x.name === "PlanTypeMasterLabel")
            ?.value,
          Description: item.fields.find(
            (x) => x.name === "PlanTypeMasterDescription"
          )?.value,
          DescriptionUrl: descriptionUrl,
          isLinePlan: item.fields.find(
            (x) => x.name === "PlanTypeMasterIsLinePlan"
          )?.value,
        };
      }
    );

    setPlanTypeOptions(selectPlanTypeRadioOptions);

    // Initial values
    if (!isMovingPath) {
      if (userPlanType && selectPlanTypeRadioOptions) {
        selectPlanTypeRadioOptions.forEach((item) => {
          if (item.Code === userPlanType) {
            setSelectedPlanType({ [selectPlanTypeRadioCategory]: item.Code });
          }
        });
      }
    } else {
      // MOVING PATH
      setSelectedPlanType({ [selectPlanTypeRadioCategory]: STRING_CIRCUIT });
      setSelectPlanRadioDisabled(true);
    }

    setSelectPlanRadioDisabled(!!isMovingPath);
  }, [planTypeMaster]);

  // receive contractNameTypeMaster data
  useEffect(() => {
    // set selectMoneyDepositNameRadio datas
    const selectMoneyDepositNameRadioOptions = contractNameTypeMaster?.contractNameTypeMaster?.children?.map(
      (item) => {
        const iconUrlHtml = item.fields.find((x) => x.name === "IconUrl")
          ?.editable;
        const iconUrl = parse(iconUrlHtml)?.props?.src;

        return {
          Code: item.fields.find((x) => x.name === "Value")?.value,
          Value: item.fields.find((x) => x.name === "Description")?.value,
          IconUrl: iconUrl,
        };
      }
    );

    setMoneyDepositNameOptions(selectMoneyDepositNameRadioOptions);

    // initial value
    if (userContractName && selectMoneyDepositNameRadioOptions) {
      selectMoneyDepositNameRadioOptions.forEach((item) => {
        if (item.Code === userContractName) {
          setSelectedMoneyDepositName({
            [moneyDepositNameRadioCategory]: item.Code,
          });
        }
      });
    }

    // Controls state
    setIsMoneyDepositNameRadioDisabled(!!userCustomerId);
  }, [contractNameTypeMaster]);

  // receive residentTypeMaster data
  useEffect(() => {
    // set selectResidenceTypeOptions datas
    const selectResidenceTypeOptions = residentTypeMaster?.residentTypeMaster?.children?.map(
      (item) => {
        return {
          value: item.fields.find((x) => x.name === "Value")?.value,
          name: item.fields.find((x) => x.name === "Description")?.value,
        };
      }
    );
    if (selectResidenceTypeOptions?.length) {
      setResidenceTypeList(selectResidenceTypeOptions);
    }
  }, [residentTypeMaster]);

  // receive postal data
  useEffect(() => {
    if (searchPostalData) {
      const streetData = searchPostalData?.streets;
      if (!isFirstPostalCall) {
        const isStreetDataEmpty = !streetData || streetData?.length === 0;

        searchErrorMsgA02.fields.alertText = postalCodeNotApplicableMessage;
        setIsSearchErrorMsgAreaHidden(!isStreetDataEmpty);

        setIsAreaServiceUnavailableAreaHidden(!isStreetDataEmpty);
        setIsAreaNotificationInformationListHidden(!isStreetDataEmpty);
        setIsAreaApplicationInformationListHidden(!isStreetDataEmpty);

        if (isStreetDataEmpty) {
          return;
        }
      }

      let options = [];
      if (searchPostalData.streets) {
        searchPostalData.streets.forEach((v, i) => {
          const optionNameList = [
            v?.CMAP_StateName__c,
            v?.CMAP_MunicipalityName__c,
            v?.CMAP_StreetOoazaAzaName__c,
          ];
          const optionName = optionNameList.join("");

          const optionValue = v.CMAP_AddressCode__c;

          const optionData = {
            id: v?.Id,
            cityCode: v?.CMAP_MunicipalityCode__c,
            installationLocationPrefectureCode: v?.CMAP_StateCode__c,
            installationLocationAddress: optionName,
            addressCode: v?.CMAP_AddressCode__c,
            stateName: v?.CMAP_StateName__c,
            cityName: v?.CMAP_MunicipalityName__c,
            streetName: v?.CMAP_StreetOoazaAzaName__c,
          };

          options.push({
            name: optionName,
            value: optionValue,
            data: optionData,
          });
        });
      }

      setAddressList(options);
      setArea(options[0]?.data?.stateName + "" + options[0]?.data?.cityName);

      setIsFirstPostalCall(false);
      searchPostalRemove();
    }
  }, [searchPostalData]);

  // When changing address or residence type
  useEffect(() => {
    // Set the address details
    setAddressInfos();
    switchAddressAreasVisibility(selectedPlanType?.selected);
  }, [addressValue, addressList, residenceType]);

  // On plan set
  useEffect(() => {
    if (selectedPlanType?.selected) {
      const selectedPlanObject = planTypeOptions?.find(
        (x) => x.Code === selectedPlanType?.selected
      );

      // display
      setIsAddressSearchHidden(false);
      switchAddressAreasVisibility(selectedPlanObject?.Code);
      switchResidenceVisibility(selectedPlanObject?.Code);
    }
  }, [selectedPlanType, planTypeOptions]);

  // Receiving apartmentOfferData
  useEffect(() => {
    if (!apartmentOfferData) return;

    const offerMethods =
      apartmentOfferData?.propertyOfferMethodInfo?.propertyOfferMethods;
    if (!offerMethods || offerMethods.length === 0) {
      searchErrorMsgA02.fields.alertText = areaNotApplicableMessage;
      setIsSearchErrorMsgAreaHidden(false);

      setIsAreaServiceUnavailableAreaHidden(false);
      setIsAreaNotificationInformationListHidden(false);
      setIsAreaApplicationInformationListHidden(false);
      return;
    }

    // Save session storage data
    // common
    setSessionStorageItem(
      keys.SSN_APPLICATION_SELECTPLANTYPE_PLANTYPE,
      selectedPlanType?.selected,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_SELECTPLANTYPE_POSTALCODE3DIGIT,
      postalCode3,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_SELECTPLANTYPE_POSTALCODE4DIGIT,
      postalCode4,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_SELECTPLANTYPE_INSTALLATIONLOCATIONPREFECTURECODE,
      installationLocationPrefectureCode,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_SELECTPLANTYPE_ADDRESSCODE,
      addressCode,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_SELECTPLANTYPE_INSTALLATIONLOCATIONADDRESS,
      installationLocationAddress,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_SELECTPLANTYPE_STREETNAME,
      streetName,
      true
    );

    // depends on selected plan
    let orderObj = {};
    const postalCode = postalCode3 + "-" + postalCode4;
    if (selectedPlanType?.selected === STRING_CIRCUIT) {
      orderObj = {
        CMAP_IsTypeCircuit__c: true,
        CMAP_IsTypeWirelessCircuit__c: false,
        CMAP_IsTypeNotCircuit__c: false,
        CMAP_IsTypeGoodsServiceSales__c: false,
        CMAP_ResidenceType__c: residenceType,
        CMAP_InstallationLocationAddressCode__c: addressId,
        CMAP_InstallationLocationAddressCode11__c: addressCode,
        CMAP_InstallationLocationCity__c: cityCode,
      };
    } else if (selectedPlanType?.selected === STRING_WIRELESS_CIRCUIT) {
      orderObj = {
        CMAP_IsTypeCircuit__c: false,
        CMAP_IsTypeWirelessCircuit__c: true,
        CMAP_IsTypeNotCircuit__c: false,
        CMAP_IsTypeGoodsServiceSales__c: false,
        CMAP_DeliveryServiceAddress__PostalCode__s: postalCode,
        CMAP_DeliveryServiceAddress__StateCode__s: installationLocationPrefectureCode,
        CMAP_DeliveryServiceAddress__City__s: cityName,
      };
    } else if (selectedPlanType?.selected === STRING_NOT_CIRCUIT) {
      orderObj = {
        CMAP_IsTypeCircuit__c: false,
        CMAP_IsTypeWirelessCircuit__c: false,
        CMAP_IsTypeNotCircuit__c: true,
        CMAP_IsTypeGoodsServiceSales__c: false,
        CMAP_DeliveryServiceAddress__PostalCode__s: postalCode,
        CMAP_DeliveryServiceAddress__StateCode__s: installationLocationPrefectureCode,
        CMAP_DeliveryServiceAddress__City__s: cityName,
      };
    } else if (selectedPlanType?.selected === STRING_GOODS_SERVICE_SALES) {
      orderObj = {
        CMAP_IsTypeCircuit__c: false,
        CMAP_IsTypeWirelessCircuit__c: false,
        CMAP_IsTypeNotCircuit__c: false,
        CMAP_IsTypeGoodsServiceSales__c: true,
        CMAP_DeliveryServiceAddress__PostalCode__s: postalCode,
        CMAP_DeliveryServiceAddress__StateCode__s: installationLocationPrefectureCode,
        CMAP_DeliveryServiceAddress__City__s: cityName,
      };
    }

    const nextOrderRelatedInfo = {
      ...orderRelatedInfo,
      orderRelatedInfo: {
        ...orderRelatedInfo?.orderRelatedInfo,
        orderInfo: {
          ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
          order: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
            ...orderObj,
            ShippingPostalCode: postalCode,
            ShippingState: installationLocationPrefectureCode,
            ShippingCity: cityName,
          },
        },
        accountInfo: {
          ...orderRelatedInfo?.orderRelatedInfo?.accountInfo,
          account: {
            ...orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account,
            CMAP_ContractName__c: selectedMoneyDepositName?.selected,
          },
        },
      },
    };

    setSessionStorageItem(
      keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
      nextOrderRelatedInfo,
      true
    );

    // Determine next page
    // call site
    let callSite = "";
    if (hostName !== "c-pad") {
      callSite = "c_" + pathName;
    } else {
      callSite = "a_" + pathName;
    }

    // move link
    let moveLink = "";

    if (
      nextOrderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
        ?.CMAP_IsTypeWirelessCircuit__c === true ||
      nextOrderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
        ?.CMAP_IsTypeNotCircuit__c === true ||
      nextOrderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
        ?.CMAP_IsTypeGoodsServiceSales__c === true ||
      (isChangePlanPath &&
        nextOrderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
          ?.CMAP_IsTypeCircuit__c === true &&
        beforeOrderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
          ?.CMAP_IsTypeCircuit__c === true)
    ) {
      // 契約回線が回線(無線)、または、非回線、または物販の場合、 または、親アイテムがプラン変更（/planchange）、かつ、契約回線が回線(有線)かつ、契約元の回線が回線(有線)の場合、
      moveLink =
        pageContext[
          "SelectPlanTypeTransitionButtons-NextButtonLink(SelectProduct)"
        ];
    } else {
      // APARTMENT
      if (residenceType === STRING_RESIDENCE_TYPE_APARTMENT) {
        moveLink =
          pageContext[
            "SelectPlanTypeTransitionButtons-NextButtonLink(Apartment)"
          ];
      }
      // HOME
      else if (residenceType === STRING_RESIDENCE_TYPE_HOME) {
        moveLink =
          pageContext["SelectPlanTypeTransitionButtons-NextButtonLink(Home)"];

        let url = new URL(moveLink.value.href);

        url.searchParams.set("i_area", "commufa");
        url.searchParams.set("i_iro", "");
        url.searchParams.set("i_citycode", cityCode);
        url.searchParams.set("i_lat_j", "");
        url.searchParams.set("i_lon_j", "");
        url.searchParams.set("i_callsite", callSite);

        moveLink.value.href = url.toString();
        moveLink.value.url = url.toString();
      }
    }

    apartmentOfferRemove();

    if (moveLink) redirectToLinkUrl(moveLink);
  }, [apartmentOfferData]);

  // @View
  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <SelectPlanType rendering={props.rendering} />

              <SelectContractNameType rendering={props.rendering} />

              {!isAddressSearchHidden && (
                <AddressSearch
                  rendering={props.rendering}
                  isAddressSelectHidden={isAddressSelectHidden}
                  isResidenceSelectHidden={isResidenceSelectHidden}
                  isAreaHidden={isAreaHidden}
                />
              )}

              {!isAreaServiceUnavailableAreaHidden && (
                <ServiceUnavailableArea
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                  isAreaApplicationInformationListHidden={
                    isAreaApplicationInformationListHidden
                  }
                  isAreaNotificationInformationListHidden={
                    isAreaNotificationInformationListHidden
                  }
                  setIsLoading={props.setIsLoading}
                />
              )}

              <SelectPlanTypePageTransitionButtons
                rendering={props.rendering}
              />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(SelectPlanTypeLayout);
