export const query = `query {
    BannerGeneral: item(path: "/sitecore/content/jss/masterjss/Data/cPad Banner Folder/cPad Banner") {
      fields(excludeStandardFields: true) {
        name
        value
      }
      children {
        name
          fields(excludeStandardFields: true) {
              name
              value
            editable
          }
      }
    }
}`;