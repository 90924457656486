import { Text } from "@sitecore-jss/sitecore-jss-react";
import React, { useEffect, useState } from "react";
import { fillBlank, getProperData, nullChecker } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";
import { useSetSelectedItem } from "../../hooks/useSelectedState";

const S09 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);

  const {
    checkBoxLabel,
    checkBoxIsCheck,
    titleText,
    additionalClass,
    isHidden,
    formName,
  } = properData;

  const [isOnChecked, setIsOnChecked] = useState(false);
  const [isHiddenField, setIsHiddenField] = useState("");

  // get the data
  const componentCategory = nullChecker(properData?.category)?.value;
  const [selected, selectedItemSetter] = useSetSelectedItem(componentCategory);

  // update the context
  const checkHandler = () => {
    setIsOnChecked(!isOnChecked);

    let newVal = {};
    newVal[componentCategory] = !isOnChecked;
    selectedItemSetter(newVal);

    if (properData?.onChangeEvent) {
      properData.onChangeEvent(!isOnChecked);
    }
  };

  // @Controller
  useEffect(() => {
    setIsOnChecked(checkBoxIsCheck);
  }, [checkBoxIsCheck]);

  useEffect(() => {
    if (typeof isHidden === "boolean") setIsHiddenField(isHidden);
    if (typeof isHidden?.value === "boolean") setIsHiddenField(isHidden.value);
  }, [isHidden]);

  // @View
  return (
    <React.Fragment>
      {!isHiddenField && (
        <div className={`input-form S-09 ${additionalClass?.value}`}>
          {titleText?.value && (
            <label>
              <Text field={fillBlank(titleText)} />
            </label>
          )}
          <div className="input-box single-type">
            <div className="input-container">
              <input
                type="checkbox"
                name={formName ? formName : "cbQ2"}
                checked={isOnChecked}
                onChange={checkHandler}
              />
              <label htmlFor="cbQ2">
                <Text field={fillBlank(checkBoxLabel)} />
              </label>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default S09;
