import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";

// Hooks/Utils/Assets
import { validationFuncs } from "../../utils/validations";
import { useCustomQuery, useGetDataViaGraphQL } from "../../hooks/useGetData";
import { getErrorMessage } from "../../utils/errorMessageHandler";
import {
  setSessionStorageItem,
  getSessionStorageItem,
} from "../../utils/useSessionStorage";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
  decryptAuthCode,
  generateAuthCode,
  redirectToLinkUrl,
  getUrlInfo,
} from "../../utils/commonUtils";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { di_register } from "../../utils/di";
import {
  MEMBER_REGISTER_TYPE_1,
  MEMBER_REGISTER_TYPE_2,
  MEMBER_REGISTER_TYPE_3,
  STRING_LOADING,
  STRING_POST,
} from "../../utils/constantVariables";
import { query as MemberRegisterTypeMasterQuery } from "../../assets/graphql/MemberRegisterTypeMaster";
import {
  apiBaseURL as API_BASE_URL,
  postConversionAzureFuncURL as POST_CONVERSION_AZURE_FUNC_URL,
  cpadPageURL as CPAD_PAGE_URL,
} from "../../envSettings";
import { useSetSelectedItem } from "../../hooks/useSelectedState";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";

const HIDDEN_COMP_INIT = {
  mycommufaIdWithoutAuth: false,
  mycommufaIdWithAuth: false,
  nextButton: false,
  authCodeTextBox: false,
  authCodeNotes: false,
  authCodeCompletedMessage: false,
  commufaIdDescription: false,
};

const MEMBER_REGISTER_COMP_CATEGORY = "MemberRegisterType";

const CpadCreateMyCommufaId = (props) => {
  const {
    hiddenComponentState,
    inputValues,
    isAuthEmail,
    sitecoreContext,
  } = props;
  const {
    "CreateMyCommufaID-DecriptionForPotentialMember": descForPotentialMemberText,
    "CreateMyCommufaID-DecriptionForNonMember": descForNonMemberText,
  } = sitecoreContext?.route?.fields;

  const graphQlData = useGetDataViaGraphQL(
    MemberRegisterTypeMasterQuery,
    null,
    "MemberRegisterTypeMaster"
  );

  const memberRegisterTypeMasterData = graphQlData?.item;

  props.setIsLoading(graphQlData === STRING_LOADING);

  const registrationCode = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_REGISTRATIONCHOICE,
    true
  ).toString();

  const [hiddenComp, setHiddenComp] = hiddenComponentState;
  const [commufaIdDescription, setCommufaIdDescription] = useState({
    value: "",
  });

  const [memberRegisterType, setMemberRegisterType] = useSetSelectedItem(
    MEMBER_REGISTER_COMP_CATEGORY
  );
  const cpadSection = getScDataFromPlaceholder(
    "commufa-ID-cpad-section",
    props
  );

  const [commufaIdDescriptionComp] = getScDataFromComponent(
    "L-06",
    cpadSection
  );
  commufaIdDescriptionComp.fields.isHidden = hiddenComp?.commufaIdDescription;
  commufaIdDescriptionComp.fields.notes = commufaIdDescription;

  const [memberRegisterTypeComp] = getScDataFromComponent("S-01", cpadSection);
  memberRegisterTypeComp.fields.category = {
    value: MEMBER_REGISTER_COMP_CATEGORY,
  };
  memberRegisterTypeComp.fields.selections = memberRegisterTypeMasterData?.children
    ?.map((item) => ({
      Code: item.code.value,
      Value: item.value.value,
      Checked: registrationCode === item.value.value,
    }))
    ?.sort((a, b) => a.Code - b.Code);
  const [emailComp, confirmEmailComp] = getScDataFromComponent(
    "I-05",
    getScDataFromPlaceholder("mycommufa-id-without-auth-section", props)
  );
  emailComp.fields.onChangeEvent = props.handleInputChange;
  emailComp.fields.inputValue = [
    {
      name: "emailAddress",
      value: inputValues.emailAddress,
    },
    {
      name: "emailAddressDomain",
      value: inputValues.emailAddressDomain,
    },
  ];

  confirmEmailComp.fields.onChangeEvent = props.handleInputChange;
  confirmEmailComp.fields.inputValue = [
    {
      name: "emailAddressConfirmation",
      value: inputValues.emailAddressConfirmation,
    },
    {
      name: "emailAddressConfirmationDomain",
      value: inputValues.emailAddressConfirmationDomain,
    },
  ];

  useState(() => {
    if (!registrationCode) {
      setHiddenComp({
        ...HIDDEN_COMP_INIT,
        commufaIdDescription: true,
        mycommufaIdWithAuth: true,
        mycommufaIdWithoutAuth: true,
        nextButton: true,
      });
      return;
    }

    setMemberRegisterType({
      [MEMBER_REGISTER_COMP_CATEGORY]: registrationCode,
    });
  }, [registrationCode]);

  useEffect(() => {
    // MyコミュファID登録を使う（その場でメールの確認ができる）
    if (memberRegisterType.selected === MEMBER_REGISTER_TYPE_1) {
      const hideObj = {
        ...HIDDEN_COMP_INIT,
        commufaIdDescription: true,
        mycommufaIdWithoutAuth: true,
      };

      if (isAuthEmail) {
        setHiddenComp({
          ...hideObj,
          authCodeTextBox: true,
          authCodeNotes: true,
        });
      } else {
        setHiddenComp({
          ...hideObj,
          authCodeCompletedMessage: true,
          nextButton: true,
        });
      }
    }

    // MyコミュファID登録を使う（その場でメールの確認ができない）
    if (memberRegisterType.selected === MEMBER_REGISTER_TYPE_2) {
      setCommufaIdDescription(descForPotentialMemberText);
      setHiddenComp({
        ...HIDDEN_COMP_INIT,
        mycommufaIdWithAuth: true,
        sendAuthBtn: true,
      });
    }

    // MyコミュファID登録を使わない
    if (memberRegisterType.selected === MEMBER_REGISTER_TYPE_3) {
      setCommufaIdDescription(descForNonMemberText);
      setHiddenComp({
        ...HIDDEN_COMP_INIT,
        mycommufaIdWithAuth: true,
        mycommufaIdWithoutAuth: true,
      });
    }
  }, [memberRegisterType]);

  return (
    <React.Fragment>
      <div className="form_wrapper-input">
        <Placeholder name="commufa-ID-section" rendering={props.rendering} />
        <Placeholder
          name="commufa-ID-cpad-section"
          rendering={props.rendering}
        />
        {!hiddenComp?.mycommufaIdWithAuth && (
          <div className="input-form no-left-right-margin">
            <div className="list_wrapper note option-result-box">
              <div className="option-result-container">
                <Placeholder
                  name="form-content-input-section-1"
                  rendering={props.rendering}
                />
              </div>

              <div className="option-result-container">
                <Placeholder
                  name="form-content-input-section-2"
                  rendering={props.rendering}
                />
              </div>
            </div>
          </div>
        )}
        {!hiddenComp?.mycommufaIdWithoutAuth && (
          <div className="with-left-right-margin">
            <Placeholder
              name="mycommufa-id-without-auth-section"
              rendering={props.rendering}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const MyCommufaCreateMyCommufaId = (props) => {
  useEffect(() => {
    props.setHiddenComponent({
      ...HIDDEN_COMP_INIT,
      authCodeTextBox: props.isAuthEmail,
      authCodeNotes: props.isAuthEmail,
      authCodeCompletedMessage: !props.isAuthEmail,
      nextButton: !props.isAuthEmail,
    });
  }, []);

  return (
    <React.Fragment>
      <div className="form_wrapper-input">
        <Placeholder name="commufa-ID-section" rendering={props.rendering} />
        <div className="input-form no-left-right-margin">
          <div className="list_wrapper note option-result-box">
            <div className="option-result-container">
              <Placeholder
                name="form-content-input-section-1"
                rendering={props.rendering}
              />
            </div>
            <div className="option-result-container">
              <Placeholder
                name="form-content-input-section-2"
                rendering={props.rendering}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const CreateMyCommufaId = (props) => {
  // TODO: Remove TEST Data

  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  const {
    "AuthenticationCodeInputSection-ButtonText": btnTxt,
    "AuthenticationCodeInputSection-AutenticatedButtonText": authBtnText,
    "MyCommufaIDRegisterButton-NextButtonLink": nextButtonLink,
  } = sitecoreContext?.route?.fields;

  const { setError, clearErrors } = useFormContext();
  const isAuthEmail = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_ISAUTHENTICATEDEMAIL,
    true
  );

  const [memberRegisterType] = useSetSelectedItem(
    MEMBER_REGISTER_COMP_CATEGORY
  );

  const hiddenComponentState = useState(HIDDEN_COMP_INIT);
  const [hiddenComp, setHiddenComp] = hiddenComponentState;

  const [isCPAD, setIsCPAD] = useState(null);
  const [isFromNextButton, setIsFromNextButton] = useState(false);

  const [authButtonProps, setAuthButtonProps] = useState({
    text: isAuthEmail ? authBtnText : btnTxt,
    disabled: false,
  });
  const [inputValues, setInputValues] = useState({
    emailAddress: getSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESS,
      true
    ),
    emailAddressDomain: getSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSDOMAIN,
      true
    ),
    emailAddressConfirmation: getSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSFORCONFIRMATION,
      true
    ),
    emailAddressConfirmationDomain: getSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSDOMAINFORCONFIRMATION,
      true
    ),
    encryptedAuthCode: "",
    authCode: "",
  });

  const [sendAuthRequest, setSendAuthRequest] = useState();
  const {
    data: sendAuthData,
    isLoading: isSendAuthLoading,
    error: sendAuthError,
    refetch: sendAuthRefetch,
    remove: sendAuthRemove,
  } = useCustomQuery(
    `${API_BASE_URL}order/SendAuthenticationCode`,
    false,
    STRING_POST,
    sendAuthRequest
  );

  const {
    data: checkCommufaIdData,
    isLoading: checkCommufaIdIsLoading,
    refetch: checkCommufaIdRefetch,
    remove: checkCommufaIdRemove,
  } = useCustomQuery(
    `${API_BASE_URL}order/CheckMycommufaId`,
    false,
    STRING_POST,
    { Email: `${inputValues?.emailAddress}@${inputValues.emailAddressDomain}` }
  );

  const isLoading = isSendAuthLoading || checkCommufaIdIsLoading;
  props.setIsLoading(isLoading);

  const validateEmail = () => {
    const {
      emailAddress: email,
      emailAddressDomain: domain,
      emailAddressConfirmation: confirmEmail,
      emailAddressConfirmationDomain: confirmDomain,
    } = inputValues;
    const emailAddress = `${email}@${domain}`;
    const confirmEmailAddress = `${confirmEmail}@${confirmDomain}`;
    let isValid = true;
    if (!validationFuncs.validateIsRequired(email)) {
      setError("EmailAddress-Required", {
        type: "manual",
        message: getErrorMessage("E0001", ["メールアドレス"]),
      });
      isValid = false;
    }
    if (!validationFuncs.validateIsRequired(domain)) {
      setError("EmailAddressDomain-Required", {
        type: "manual",
        message: getErrorMessage("E0001", ["メールアドレスドメイン"]),
      });
      isValid = false;
    }
    if (!validationFuncs.validateIsRequired(confirmEmail)) {
      setError("ConfirmEmailAddress-Required", {
        type: "manual",
        message: getErrorMessage("E0001", ["メールアドレス（確認用）"]),
      });
      isValid = false;
    }
    if (!validationFuncs.validateIsRequired(confirmDomain)) {
      setError("ConfirmEmailAddressDomain-Required", {
        type: "manual",
        message: getErrorMessage("E0001", ["メールアドレスドメイン（確認用）"]),
      });
      isValid = false;
    }

    if (
      !validationFuncs.validateEmailAddress(emailAddress) ||
      !validationFuncs.validateEmailAddress(confirmEmailAddress)
    ) {
      setError("EmailAddress", {
        type: "manual",
        message: getErrorMessage("E0024", []),
      });
      isValid = false;
    }

    if (!validationFuncs.validateIsMaxLength(emailAddress, 80)) {
      setError("EmailAddress-MaxLength", {
        type: "manual",
        message: getErrorMessage("E0015", ["メールアドレス", 80]),
      });
      isValid = false;
    }
    if (!validationFuncs.validateIsMaxLength(confirmEmailAddress, 80)) {
      setError("ConfirmEmailAddressDomain-MaxLength", {
        type: "manual",
        message: getErrorMessage("E0015", ["メールアドレス（確認用）", 80]),
      });
      isValid = false;
    }

    if (emailAddress !== confirmEmailAddress) {
      setError("EmailAddress-NotSame", {
        type: "manual",
        message: getErrorMessage("E0031", []),
      });
      isValid = false;
    }

    return isValid;
  };

  const validateAuthCode = () => {
    const { authCode } = inputValues;

    let isValid = true;
    if (!validationFuncs.validateIsRequired(authCode)) {
      setError("AuthCode-Required", {
        type: "manual",
        message: getErrorMessage("E0001", ["認証コード入力テキストボックス"]),
      });
      isValid = false;
    }
    if (!validationFuncs.validateIsMaxLength(authCode, 4)) {
      setError("AuthCode-Required", {
        type: "manual",
        message: getErrorMessage("E0015", ["認証コード入力テキストボックス"]),
      });
      isValid = false;
    }

    return isValid;
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  const emailSection = getScDataFromPlaceholder(
    "form-content-input-section-1",
    props
  );
  const [emailComp, confirmEmailComp] = getScDataFromComponent(
    "I-05",
    emailSection
  );
  emailComp.fields.onChangeEvent = handleInputChange;
  emailComp.fields.inputValue = [
    {
      name: "emailAddress",
      value: inputValues.emailAddress,
    },
    {
      name: "emailAddressDomain",
      value: inputValues.emailAddressDomain,
    },
  ];

  confirmEmailComp.fields.onChangeEvent = handleInputChange;
  confirmEmailComp.fields.inputValue = [
    {
      name: "emailAddressConfirmation",
      value: inputValues.emailAddressConfirmation,
    },
    {
      name: "emailAddressConfirmationDomain",
      value: inputValues.emailAddressConfirmationDomain,
    },
  ];

  const [sentAuthBtn] = getScDataFromComponent("B-01", emailSection);
  sentAuthBtn.fields.onClickEvent = () => {
    clearErrors();
    if (!validateEmail()) {
      return;
    }
    checkCommufaIdRefetch();
  };

  const authSection = getScDataFromPlaceholder(
    "form-content-input-section-2",
    props
  );
  const [authBtnComp] = getScDataFromComponent("B-01", authSection);
  authBtnComp.fields.buttonText = authButtonProps.text;
  authBtnComp.fields.isDisabled = {
    value: authButtonProps.disabled,
  };
  authBtnComp.fields.onClickEvent = () => {
    clearErrors();
    if (!validateEmail() || !validateAuthCode()) {
      return;
    }

    const authCode = getSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_AUTHCODE,
      true
    );

    if (!authCode || decryptAuthCode(authCode) !== inputValues.authCode) {
      setError("AuthCode-Required", {
        type: "manual",
        message: getErrorMessage("E0028", ["認証コード入力テキストボックス"]),
      });

      return;
    }

    setAuthButtonProps({ text: authBtnText, disabled: true });
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_ISAUTHENTICATEDEMAIL,
      true,
      true
    );
    setHiddenComp({
      ...HIDDEN_COMP_INIT,
      commufaIdDescription: true,
      mycommufaIdWithoutAuth: true,
      nextButton: false,
    });
  };

  const [authCodeComp] = getScDataFromComponent("I-01", authSection);
  authCodeComp.fields.isHiddenTextBox = hiddenComp?.authCodeTextBox;
  authCodeComp.fields.onChangeEvent = handleInputChange;
  authCodeComp.fields.inputValue = {
    name: "authCode",
    value: inputValues.authCode,
  };

  const [authNotes] = getScDataFromComponent("L-06", authSection);
  authNotes.fields.isHidden = hiddenComp?.authCodeNotes;

  const [_, completedMessageComp] = getScDataFromComponent("D-01", authSection);
  completedMessageComp.fields.isHidden = {
    value: hiddenComp.authCodeCompletedMessage,
  };

  const setDataToSessionStorage = () => {
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_REGISTRATIONCHOICE,
      memberRegisterType.selected,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESS,
      inputValues.emailAddress,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSDOMAIN,
      inputValues.emailAddressDomain,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSFORCONFIRMATION,
      inputValues.emailAddressConfirmation,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSDOMAINFORCONFIRMATION,
      inputValues.emailAddressConfirmationDomain,
      true
    );
  };

  const btnSection = getScDataFromPlaceholder("button-section", props);
  const [nextButton] = getScDataFromComponent("B-01", btnSection);
  nextButton.fields.isHidden = hiddenComp?.nextButton;
  nextButton.fields.onClickEvent = () => {
    clearErrors();

    if (
      ((isCPAD && memberRegisterType.selected !== MEMBER_REGISTER_TYPE_3) ||
        !isCPAD) &&
      !validateEmail()
    ) {
      return;
    }

    if (
      (!isCPAD || memberRegisterType.selected === MEMBER_REGISTER_TYPE_1) &&
      !authButtonProps.disabled &&
      !validateAuthCode()
    ) {
      return;
    }

    if (memberRegisterType.selected === MEMBER_REGISTER_TYPE_2) {
      setIsFromNextButton(true);
      checkCommufaIdRefetch();
      return;
    }

    setDataToSessionStorage();
    redirectToLinkUrl(nextButtonLink);
  };

  const [backButton] = getScDataFromComponent("B-02", btnSection);
  backButton.fields.onClickEvent = () => {
    setDataToSessionStorage();

    redirectToPreviousPage();
  };

  useEffect(() => {
    const urlInfo = getUrlInfo();
    setIsCPAD(urlInfo?.origin === CPAD_PAGE_URL);
  }, []);

  useEffect(() => {
    setAuthButtonProps((prev) => ({ ...prev, disabled: isAuthEmail }));
  }, [isAuthEmail]);

  useEffect(() => {
    if (sendAuthRequest) {
      sendAuthRefetch();
    }
  }, [sendAuthRequest]);

  useEffect(() => {
    if (!checkCommufaIdData) return;
    if (!checkCommufaIdData.Result) {
      setError("EmailAddress-NotSame", {
        type: "manual",
        message: getErrorMessage("E0030", []),
      });
      return;
    }

    if (isCPAD && isFromNextButton) {
      setDataToSessionStorage();
      redirectToLinkUrl(nextButtonLink);
      return checkCommufaIdRemove();
    }

    const email = `${inputValues.emailAddress}@${inputValues.emailAddressDomain}`;

    // Generate AUTH Code
    const authCode = generateAuthCode();

    // Generate Resume URL
    const url = window.location.href;
    const azureURL = new URL(POST_CONVERSION_AZURE_FUNC_URL);
    const queryParams = new URLSearchParams(azureURL.search);
    queryParams.set("m", email);
    queryParams.set(
      "from",
      url.substring(0, url.lastIndexOf("/") + 1).replace(/\/+$/, "")
    );
    azureURL.search = queryParams.toString();
    const resumeURL = azureURL.toString();

    const applicationDataKeys = [
      sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_APARTMENTNAME,
      sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_APARTMENTSTREETBUNCH,
      sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_ISALCMPROPERTY,
      sessionKeys.SSN_APPLICATION_RECONFIRMADDRESS_CITY,
      sessionKeys.SSN_APPLICATION_RECONFIRMADDRESS_DISTRICT,
      sessionKeys.SSN_APPLICATION_RECONFIRMADDRESS_PREFECTURE,
      sessionKeys.SSN_APPLICATION_RECONFIRMADDRESS_STREET1,
      sessionKeys.SSN_APPLICATION_SELECTPLANTYPE_INSTALLATIONLOCATIONADDRESS,
      sessionKeys.SSN_APPLICATION_SELECTPLANTYPE_PLANTYPE,
      sessionKeys.SSN_APPLICATION_SELECTPRODUCT_BENEFITCAMPAIGNINFOS,
      sessionKeys.SSN_APPLICATION_SELECTPRODUCT_BENEFITLISTFORAGENCY,
      sessionKeys.SSN_APPLICATION_SELECTPRODUCT_NOAPPLYBENEFIT,
      sessionKeys.SSN_APPLICATION_SELECTPRODUCT_PRODUCTSELECTIONLIST,
      sessionKeys.SSN_COMMON_COMMON_CUSTOMERID,
      sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    ];
    const applicationData = applicationDataKeys.reduce((prev, current) => {
      if (!prev[current]) {
        prev[current] = getSessionStorageItem(current, true);
      }
      return prev;
    }, {});

    setInputValues((prev) => ({ ...prev, encryptedAuthCode: authCode }));
    setSendAuthRequest({
      EmailAddress: email,
      AuthCode: authCode,
      ResumeURL: resumeURL,
      ApplicationData: JSON.stringify(applicationData),
    });

    return checkCommufaIdRemove();
  }, [checkCommufaIdData]);

  useEffect(() => {
    if (sendAuthData) {
      const { Result, ErrorCode, ErrorMessage } = sendAuthData;
      if (!Result && ErrorCode) {
        setError(ErrorCode, {
          type: "manual",
          message: ErrorMessage,
        });

        return;
      }

      if (isAuthEmail) {
        setAuthButtonProps({
          text: btnTxt,
          disabled: false,
        });

        setHiddenComp({
          ...HIDDEN_COMP_INIT,
          commufaIdDescription: true,
          mycommufaIdWithoutAuth: true,
          authCodeCompletedMessage: true,
          nextButton: true,
        });
      }

      setSessionStorageItem(
        sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESS,
        inputValues.emailAddress,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSDOMAIN,
        inputValues.emailAddressDomain,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSFORCONFIRMATION,
        inputValues.emailAddressConfirmation,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_EMAILADDRESSDOMAINFORCONFIRMATION,
        inputValues.emailAddressConfirmationDomain,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_AUTHCODE,
        inputValues.encryptedAuthCode,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_APPLICATION_CREATEMYCOMMUFAID_ISAUTHENTICATEDEMAIL,
        "false",
        true
      );
    }
  }, [sendAuthData]);

  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <Placeholder
                name="warning-modal-section"
                rendering={props.rendering}
              />

              {isCPAD && (
                <CpadCreateMyCommufaId
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                  isAuthEmail={isAuthEmail}
                  hiddenComponentState={hiddenComponentState}
                  inputValues={inputValues}
                  setIsLoading={props.setIsLoading}
                  handleInputChange={handleInputChange}
                />
              )}
              {!isCPAD && (
                <MyCommufaCreateMyCommufaId
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                  isAuthEmail={isAuthEmail}
                  setHiddenComponent={setHiddenComp}
                />
              )}
              <div className="btn_container" name="button-section">
                <Placeholder
                  name="button-section"
                  rendering={props.rendering}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(CreateMyCommufaId);
