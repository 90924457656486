import React, { useEffect, useState } from "react";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const P06 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { tabTitle1, tabTitle2, isHidden } = properData;

  const isTabsHidden = properData?.isTabsHidden ?? false;
  const isTab1Hidden = properData?.isTab1Hidden ?? false;
  const isTab2Hidden = properData?.isTab2Hidden ?? false;
  const showOnlyTab1 = properData?.showOnlyTab1 ?? false;
  const showOnlyTab2 = properData?.showOnlyTab2 ?? false;

  const isSecondTabActiveByDefault = properData?.isSecondTabActiveByDefault ?? false;
  const defaultState = !isSecondTabActiveByDefault ? 'P06tab1' : 'P06tab2';

  const [activeTab, setActiveTab] = useState(defaultState);
  const handleTabChange = (event) => {
    setActiveTab(event.target.id);
  };

  useEffect(() => {
    if (isSecondTabActiveByDefault)
      setActiveTab(defaultState);
  }, [isSecondTabActiveByDefault]);

  return (
    <React.Fragment>
      <div className="list_wrapper tabset P-06">
        {!isTabsHidden ? (
          <>
            <div className="tab-menu">
              <input
                type="radio"
                name="P06tab"
                id="P06tab1"
                aria-controls="tabPanelP01"
                checked={activeTab === "P06tab1"}
                onChange={handleTabChange}
              />
              <label htmlFor="P06tab1">
                <Text field={fillBlank(tabTitle1)} />
              </label>
              <input
                type="radio"
                name="P06tab"
                id="P06tab2"
                aria-controls="tabPanelP02"
                checked={activeTab === "P06tab2"}
                onChange={handleTabChange}
              />
              <label htmlFor="P06tab2">
                <Text field={fillBlank(tabTitle2)} />
              </label>
            </div>
            <div className="tab-panels">
              {!isTab1Hidden &&
                <div id="tabPanelP01" className={`tab-panel ${activeTab === 'P06tab1' ? 'active' : ''}`}>
                  <Placeholder name="tab-1-content" rendering={props.rendering} />
                </div>
              }
              {!isTab2Hidden &&
                <div id="tabPanelP02" className={`tab-panel ${activeTab === 'P06tab2' ? 'active' : ''}`}>
                  <Placeholder name="tab-2-content" rendering={props.rendering} />
                </div>
              }
            </div>
          </>
        ) : (
          <div className="tab-panels">
            {showOnlyTab1 &&
              <>
                <div className="tab-panel active pt-0">
                  {!isTab1Hidden &&
                    <Placeholder name="tab-1-content" rendering={props.rendering} />
                  }
                </div>
              </>
            }
            {showOnlyTab2 &&
              <>
                {!isTab2Hidden &&
                  <div className="tab-panel active pt-0">
                    <Placeholder name="tab-2-content" rendering={props.rendering} />
                  </div>
                }
              </>
            }
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default P06;
