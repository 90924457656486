import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const L09 = (props) => {
  const { deviceTitleText, deviceText } = props.fields;
  return (
    <React.Fragment>
      <div className="list_wrapper with-background L-09">
        <ul>
          <li>
            <span>
              <Text field={fillBlank(deviceTitleText)} />
            </span>
            <p>
              <Text field={fillBlank(deviceText)} />
            </p>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default L09;
