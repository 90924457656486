import React, { useEffect, useState, useRef } from "react";
import { Image, Text } from "@sitecore-jss/sitecore-jss-react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";
import ja from "date-fns/locale/ja";
import {
  preventDoubleClick,
  openButtonLink,
  getProperData,
  fillBlank,
  nullChecker,
} from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";
import { useFormDataStore, useCustomerInfoStore } from "../../hooks/useSimpleOpState";

registerLocale("ja", ja);

const M01i = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    isHidden,
    titleText,
    requiredText,
    calendarPlaceholder,
    calendarIcon,
    inputValue,
    minDate,
    maxDate,
    name,
    type
  } = properData;

  let initialValue = properData?.initialValue;
  const formData = useFormDataStore((state) => state.formData);
  const customerInfo = useCustomerInfoStore((state) => state.customerInfo);
  if(Object.keys(formData).length > 0 && type === "productAttributes" && formData[name]) {
    initialValue = 
    {
      name: titleText,
      value: formData[name]      
    };
  }
  if(Object.keys(customerInfo).length > 0 && type === "customerInfo" && customerInfo[name]) {
    initialValue = 
    {
      name: titleText,
      value: customerInfo[name]      
    };
  }
  const calendarPlaceholderFormat =
    properData?.calendarPlaceholderFormat ?? "yyyy年MM月dd日(EEEEE)";

  const [date, setDate] = useState();
  const datePickerRef = useRef(null);
  const [minDateValue, setMinDateValue] = useState();
  const [maxDateValue, setMaxDateValue] = useState();

  // @Controller
  useEffect(() => {
   
    if (initialValue?.value) {
      const parsedDate = parse(initialValue?.value, "yyyy-MM-dd", new Date());
      if( parsedDate?.toString() !== date?.toString()){
        setDate(parse(initialValue?.value, "yyyy-MM-dd", new Date()));
      }
    }
  }, [initialValue]);

  useEffect(() => {
    if (minDate && maxDate) {
      setMinDateValue(parse(minDate, "yyyy-MM-dd", new Date()));
      setMaxDateValue(parse(maxDate, "yyyy-MM-dd", new Date()));
    }
  }, [minDate, maxDate]);

  const handleDateChange = (date, onChangeEvent) => {
    setDate(date);
    if (onChangeEvent) onChangeEvent(date);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    handleInputClick();
  };

  const handleInputClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  // @View
  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <>
        <input
          onClick={props.onClick}
          value={props.value}
          type="text"
          readOnly
          ref={ref}
        />
        {calendarIcon?.value?.src && (
          <Image field={calendarIcon} className="custom-calendar-icon" />
        )}
      </>
    );
  });

  return (
    <React.Fragment>
      {!isHidden && (
        <div className="input-box M-01i">
          <div className="input-form">
            <div className="input-container">
              <label htmlFor="M01i-1">
                <Text field={fillBlank(titleText)} />
                {requiredText?.value !== "" ? (
                  <span className="required">
                    <Text field={fillBlank(requiredText)} />
                  </span>
                ) : (
                  ""
                )}
              </label>
              <div
                className={`pull-down calendar ${
                  !calendarIcon?.value?.src && "no-icon"
                } ${inputValue && inputValue?.isDisabled && "disabled"}`}
              >
                <DatePicker
                  ref={datePickerRef}
                  popperClassName="myDatePicker"
                  selected={date}
                  dateFormat="yyyy/MM/dd"
                  locale="ja"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                  dropdownMode="select"
                  customInput={<CustomInput />}
                  onChange={(date) =>
                    handleDateChange(date, properData?.onChangeEvent)
                  }
                  disabled={inputValue && inputValue?.isDisabled}
                  minDate={minDateValue}
                  maxDate={maxDateValue}
                />
                <input
                  id="M01i-1"
                  name="M01i-1"
                  placeholder={fillBlank(calendarPlaceholder)?.value}
                  readOnly
                  value={
                    date
                      ? format(date, calendarPlaceholderFormat, { locale: ja })
                      : ""
                  }
                  onChange={() => {}}
                  onMouseDown={handleMouseDown}
                  disabled={inputValue && inputValue?.isDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default M01i;
