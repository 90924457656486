import React from "react";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const S04 = (props) => {
  const {
    radioBoxLabel,
    explanationText,
    priceText,
    additionalClass,
  } = props.fields;

  return (
    <React.Fragment>
      <div className={`input-form S-04 ${additionalClass?.value}`}>
        <div className="input-box">
          <div className="input-container">
            <Placeholder name="required-tag" rendering={props.rendering} />
            <input type="radio" name="rdQ3" />
            <label htmlFor="rdQ3">
              <p>
                <Text field={fillBlank(radioBoxLabel)} />
              </p>
              <span className="right">
                <p>
                  <Text field={fillBlank(priceText)} />
                </p>
                円(税込)/月〜
              </span>
              <p>
                <Text field={fillBlank(explanationText)} />
              </p>
              <div className="benefits-image">
                <img src="" alt="" />
              </div>
            </label>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default S04;
