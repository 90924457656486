import React, { useEffect, useState } from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  deepcopy,
  getProperData,
} from "../../utils/commonUtils";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { di_register } from "../../utils/di";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import {
  useCustomQuery,
  useDynamicParallelQuery,
} from "../../hooks/useGetData";
import { apiBaseURL } from "../../envSettings";
import {
  STRING_CATEGORY_MOVEMENT,
  STRING_CATEGORY_TRANSFER,
  STRING_CATEGORY_PLAN_CHANGE,
  STRING_CATEGORY_PRODUCT_CHANGE,
  STRING_CATEGORY_TERMINATION_REMAINING_DEBT_LUMPSUM_PAYMENT,
  STRING_GET,
  STRING_INDIVIDUAL_CONTRACT_INFO,
  STRING_INDIVIDUAL_CONTRACT,
  STRING_CATEGORY_ORDER_CHANGE,
  STRING_CATEGORY_INDIVIDUAL_CONTRACT_CHANGE,
} from "../../utils/constantVariables";
import { getURLType, redirectToLinkUrl } from "../../utils/targetUrlType";
import { SAME_WINDOW } from "../../utils/constantVariables"

// @Controller
const Contract = (props) => {
  return (
    <>
      <div className="form_wrapper-input">
        <Placeholder name="contract-list-section" rendering={props.rendering} />
      </div>
    </>
  );
};

const addDataRowList = (key, value) => {
  return {
    fields: {
      explanationText: value,
      textAreaLabel: key,
    },
  };
};

const setObjectToSession = (obj) => {
  Object.keys(obj).forEach((key) => {
    setSessionStorageItem(key, obj[key], true);
  });
};

const createContractList = (
  componentList,
  contractListData,
  orderCategoryData,
  l02Component,
  pageContext,
  uid
) => {
  for (let i = 0; i < contractListData.length; i++) {
    let contractDetail = deepcopy(l02Component);
    let MultilistItems = contractDetail?.fields?.MultilistItems;
    contractDetail.uid = l02Component.uid.concat(uid).concat(i.toString());

    const individualContract =
      contractListData[i][STRING_INDIVIDUAL_CONTRACT_INFO][
        STRING_INDIVIDUAL_CONTRACT
      ];

    contractDetail.fields.titleNumber = individualContract?.Name;
    contractDetail.fields.Id = individualContract?.Id;

    MultilistItems[MultilistItems.length] = addDataRowList(
      pageContext["Contract-AddressLabel"]?.value,
      individualContract?.CMAP_InstallationAddress__c
    );

    MultilistItems[MultilistItems.length] = addDataRowList(
      pageContext["Contract-PlanLabel"]?.value,
      individualContract?.CMAP_OfferPlanName__c
    );

    const actionButtonsPlaceholder = getScDataFromPlaceholder(
      "cancel-and-schedule-button-section",
      contractDetail
    );

    const [
      transferButton,
      relocateButton,
      changePlanButton,
      changeProductButton,
      cancelContractButton,
      rebuildButton,
    ] = getScDataFromComponent("B-02", actionButtonsPlaceholder);

    const orderCategoryList = orderCategoryData[i];
    if (orderCategoryList && orderCategoryList?.length > 0) {
      transferButton.fields.isHidden = false;
      rebuildButton.fields.isHidden = false;
      relocateButton.fields.isHidden = false;
      changePlanButton.fields.isHidden = false;
      changeProductButton.fields.isHidden = false;
      cancelContractButton.fields.isHidden = false;

      orderCategoryList.forEach((cat) => {
        switch (cat.orderCategory) {
          case STRING_CATEGORY_MOVEMENT:
            transferButton.fields.buttonText = cat.orderCategoryLabel;
            break;
          case STRING_CATEGORY_TRANSFER:
            relocateButton.fields.buttonText = cat.orderCategoryLabel;
            break;
          case STRING_CATEGORY_PLAN_CHANGE:
            changePlanButton.fields.buttonText = cat.orderCategoryLabel;
            break;
          case STRING_CATEGORY_PRODUCT_CHANGE:
            changeProductButton.fields.buttonText = cat.orderCategoryLabel;
            break;
          case STRING_CATEGORY_TERMINATION_REMAINING_DEBT_LUMPSUM_PAYMENT:
            cancelContractButton.fields.buttonText = cat.orderCategoryLabel;
            break;
        }
      });

      if (
        !orderCategoryList.some(
          ({ orderCategory }) => orderCategory === STRING_CATEGORY_MOVEMENT
        )
      ) {
        transferButton.fields.isHidden = true;
        rebuildButton.fields.isHidden = true;
      }
      if (
        !orderCategoryList.some(
          ({ orderCategory }) => orderCategory === STRING_CATEGORY_TRANSFER
        )
      ) {
        relocateButton.fields.isHidden = true;
      }
      if (
        !orderCategoryList.some(
          ({ orderCategory }) => orderCategory === STRING_CATEGORY_PLAN_CHANGE
        )
      ) {
        changePlanButton.fields.isHidden = true;
      }
      if (
        !orderCategoryList.some(
          ({ orderCategory }) =>
            orderCategory === STRING_CATEGORY_PRODUCT_CHANGE
        )
      ) {
        changeProductButton.fields.isHidden = true;
      }
      if (
        !orderCategoryList.some(
          ({ orderCategory }) =>
            orderCategory ===
            STRING_CATEGORY_TERMINATION_REMAINING_DEBT_LUMPSUM_PAYMENT
        )
      ) {
        cancelContractButton.fields.isHidden = true;
      }
    } else {
      // if orderCategoryData isEmpty or undefined hide All buttons
      transferButton.fields.isHidden = true;
      relocateButton.fields.isHidden = true;
      changePlanButton.fields.isHidden = true;
      changeProductButton.fields.isHidden = true;
      cancelContractButton.fields.isHidden = true;
      rebuildButton.fields.isHidden = true;
    }

    // onClick ShowPlanDetailButton
    const [showPlanDetailButton] = getScDataFromComponent(
      "B-01",
      getScDataFromPlaceholder("dynamic-button-section", contractDetail)
    );
    showPlanDetailButton.fields.onClickEvent = () => {
      setObjectToSession({
        [sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTID]: individualContract.Id,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_PROGRESSIONORDERID]:
          individualContract.CMAP_ProgressionOrder__c,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTNUMBER]:
          individualContract.Name,
      });

      const link = pageContext["Contract-ShowPlanDetailButtonLink"];
      link.value.href = link.value.href + individualContract.Id;
      redirectToLinkUrl(
        link.value,
        getURLType(pageContext["Contract-ShowPlanDetailButtonUrlType"])
      );
    };

    // onClick TransferButton
    transferButton.fields.onClickEvent = () => {
      setObjectToSession({
        [sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTID]: individualContract.Id,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_PROGRESSIONORDERID]:
          individualContract.CMAP_ProgressionOrder__c,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_ISREBUILD]: false,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTNUMBER]:
          individualContract.Name,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_ISTYPENOTCIRCUIT]:
          individualContract.CMAP_IsTypeNotCircuit__c,
      });

      redirectToLinkUrl(
        pageContext["Contract-TransferButtonLink"]?.value,
        SAME_WINDOW
      );
    };

    // onClick RelocateButton
    relocateButton.fields.onClickEvent = () => {
      setObjectToSession({
        [sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTID]: individualContract.Id,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_PROGRESSIONORDERID]:
          individualContract.CMAP_ProgressionOrder__c,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_ISREBUILD]: false,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTNUMBER]:
          individualContract.Name,
      });

      redirectToLinkUrl(
        pageContext["Contract-RelocateButtonLink"]?.value,
        SAME_WINDOW
      );
    };

    // onClick ChangePlanButton
    changePlanButton.fields.onClickEvent = () => {
      setObjectToSession({
        [sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTID]: individualContract.Id,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_PROGRESSIONORDERID]:
          individualContract.CMAP_ProgressionOrder__c,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_ISREBUILD]: false,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTNUMBER]:
          individualContract.Name,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_ISTYPENOTCIRCUIT]:
          individualContract.CMAP_IsTypeNotCircuit__c,
      });

      redirectToLinkUrl(
        pageContext["Contract-ChangePlanButtonLink"]?.value,
        SAME_WINDOW
      );
    };

    // onClick ChangeProductButton
    changeProductButton.fields.onClickEvent = () => {
      setObjectToSession({
        [sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTID]: individualContract.Id,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_PROGRESSIONORDERID]:
          individualContract.CMAP_ProgressionOrder__c,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_ISREBUILD]: false,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTNUMBER]:
          individualContract.Name,
      });

      redirectToLinkUrl(
        pageContext["Contract-ChangeProductButtonLink"]?.value,
        SAME_WINDOW
      );
    };

    // onClick CancelContractButton
    cancelContractButton.fields.onClickEvent = () => {
      setObjectToSession({
        [sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTID]: individualContract.Id,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_PROGRESSIONORDERID]:
          individualContract.CMAP_ProgressionOrder__c,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_ISREBUILD]: false,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTNUMBER]:
          individualContract.Name,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_ADDRESS]:
          individualContract?.CMAP_InstallationAddress__c,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_PLANNAME]:
          individualContract?.CMAP_OfferPlanName__c,
      });

      redirectToLinkUrl(
        pageContext["Contract-CancelContractButtonLink"]?.value,
        SAME_WINDOW
      );
    };

    // onClick RebuildButton
    rebuildButton.fields.onClickEvent = () => {
      setObjectToSession({
        [sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTID]: individualContract.Id,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_PROGRESSIONORDERID]:
          individualContract.CMAP_ProgressionOrder__c,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_ISREBUILD]: true,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTNUMBER]:
          individualContract.Name,
        [sessionKeys.SSN_REPORT_CONTRACTLIST_ISTYPENOTCIRCUIT]:
          individualContract.CMAP_IsTypeNotCircuit__c,
      });

      redirectToLinkUrl(
        pageContext["Contract-RebuildButtonLink"]?.value,
        SAME_WINDOW
      );
    };

    // リストにデータを追加
    componentList[componentList.length] = contractDetail;
  }
};

const mappingForContractList = (
  props,
  isLoading,
  orderCategoryData,
  contractListData,
  individualContractIds
) => {
  const pageContext = props.sitecoreContext.route.fields;
  const selectContractListSection = getScDataFromPlaceholder(
    "contract-list-section",
    props
  );
  if (
    contractListData &&
    contractListData?.length > 0 &&
    individualContractIds &&
    individualContractIds?.length > 0 &&
    !isLoading
  ) {
    let componentList = [];
    let c05Data = getScDataFromComponent("C-05", selectContractListSection);
    let copiedC05 = deepcopy(getScDataFromComponent("C-05", c05Data)[0]);

    // レンダリング項目を初期化
    copiedC05.fields.MultilistItems.splice(0);
    createContractList(
      componentList,
      contractListData,
      orderCategoryData,
      copiedC05,
      pageContext,
      "c05001"
    );
    selectContractListSection.splice(1);
    for (let i = 0; i < componentList.length; i++) {
      selectContractListSection[selectContractListSection.length] =
        componentList[i];
    }
  } else {
    selectContractListSection.splice(2);
  }
};

const ContractListLayout = (props) => {
  // todo: remove test data

  const customerID = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_CUSTOMERID,
    true
  );

  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  const [individualContractIds, setIndividualContractIds] = useState([]);
  const contractListURI = `${apiBaseURL}contract/RetrieveContractList?accountId=${customerID}&maxAcquisitionNumber=999&sortOrder=DESC`;
  const orderCategoryListURI = `${apiBaseURL}order/RetrieveOrderCategoryList?`;

  const {
    data: contractListData,
    isLoading: isContractListLoading,
    error: contractListError,
    refetch: contractListRefetch,
    remove: contractListRemove,
  } = useCustomQuery(contractListURI, false, STRING_GET);

  const {
    isLoading: isOrderCategoryLoading,
    results: orderCategoryData,
  } = useDynamicParallelQuery(individualContractIds);

  const isLoading = isOrderCategoryLoading || isContractListLoading;
  props.setIsLoading(isLoading);
  mappingForContractList(
    props,
    isLoading,
    orderCategoryData?.map(
      (contract) => contract?.data?.data?.orderCategoryInfos
    ) || [],
    contractListData?.individualContractInfos,
    individualContractIds
  );

  useEffect(() => {
    contractListRefetch();
  }, []);

  useEffect(() => {
    if (
      contractListData?.individualContractInfos &&
      contractListData?.individualContractInfos?.length > 0
    ) {
      let ids = [];
      contractListData.individualContractInfos.forEach((item) => {
        const progressOrder =
          item?.individualContractInfo?.individualContract
            ?.CMAP_ProgressionOrder__c;

        const queryParams = new URLSearchParams();
        queryParams.set(
          "changeType",
          progressOrder
            ? STRING_CATEGORY_ORDER_CHANGE
            : STRING_CATEGORY_INDIVIDUAL_CONTRACT_CHANGE
        );
        queryParams.set("orderChannelCategory", "Web");
        queryParams.set("orderId", progressOrder ? progressOrder : "");
        queryParams.set(
          "individualContractId",
          item?.individualContractInfo?.individualContract?.Id
        );

        ids.push({
          individualContractId:
            item?.individualContractInfo?.individualContract?.Id,
          url: orderCategoryListURI + queryParams.toString(),
        });
      });

      setIndividualContractIds(ids || []);
    }
  }, [contractListData]);

  // @View
  return (
    <React.Fragment>
      <main>
        <Placeholder
          name="contract-list-title-wrapper"
          rendering={props.rendering}
        />
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <Contract rendering={props.rendering} />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(ContractListLayout);
