import React, { useState, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";

const I02 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  // @Model
  const {
    hideRequiredTextClass,
    textInputLabel,
    requiredText,
    digitPlaceholder1,
    digitPlaceholder2,
    inputValue,
    isHidden,
    additionalClass
  } = properData;

  // @Controller
  let formName1 = props?.fields?.formName1 ? props.fields.formName1 : "I02-1";
  let formName2 = props?.fields?.formName2 ? props.fields.formName2 : "I02-2";

  const [isHiddenField, setIsHiddenField] = useState("");
  useEffect(() => {
    setIsHiddenField(isHidden);
  }, [isHidden]);
  // @View
  return (
    <React.Fragment>
      {!isHiddenField?.value ? (
        <div className="input-box I-02">
          <div className={`input-form ${additionalClass?.value}`}>
            <div className="input-container">
              <label className={hideRequiredTextClass?.value} htmlFor="I02-1">
                <Text field={fillBlank(textInputLabel)} />
                {requiredText?.value && (
                  <span className="required">
                    <Text field={fillBlank(requiredText)} />
                  </span>
                )}
              </label>
              <input
                name={inputValue ? inputValue[0]?.name : formName1}
                id="I02-1"
                type="text"
                className="xsmall numberOnly"
                onChange={props?.fields?.onChangeEvent}
                onBlur={props?.fields?.onBlurEvent}
                placeholder={digitPlaceholder1?.value}
                value={inputValue && inputValue[0]?.value}
                disabled={inputValue && inputValue[0]?.isDisabled}
              />
              <span>-</span>
              <input
                name={inputValue ? inputValue[1]?.name : formName2}
                type="text"
                className="small numberOnly"
                onChange={props?.fields?.onChangeEvent}
                onBlur={props?.fields?.onBlurEvent}
                placeholder={digitPlaceholder2?.value}
                value={inputValue && inputValue[1]?.value}
                disabled={inputValue && inputValue[1]?.isDisabled}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default I02;
