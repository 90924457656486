import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const B003 = (props) => {
  const { buttonLabel } = props.fields;
  return (
    <React.Fragment>
      <div className="btn_wrapper B-03">
        <button className="btn btn_logout" type="button">
          <Text field={fillBlank(buttonLabel)} />
        </button>
      </div>
    </React.Fragment>
  );
};

export default B003;
