export const query = `query {
  SelectPlanType: item(path: "/sitecore/content/jss/masterjss/Data/Application Information List/Select Plan Type") {
    fields(excludeStandardFields: true) {
      ...on MultilistField {
        targetItems {
          fields(excludeStandardFields: true) {
            name
            editable
          }
        }
      }
    }
  }
  ApartmentSearch: item(path: "/sitecore/content/jss/masterjss/Data/Application Information List/Apartment Search") {
    fields(excludeStandardFields: true) {
      ...on MultilistField {
        targetItems {
          fields(excludeStandardFields: true) {
            name
            editable
          }
        }
      }
    }
  }
  ServiceAvailableAreaForApartment: item(path: "/sitecore/content/jss/masterjss/Data/Application Information List/Service Available Area For Apartment") {
    fields(excludeStandardFields: true) {
      ...on MultilistField {
        targetItems {
          fields(excludeStandardFields: true) {
            name
            editable
          }
        }
      }
    }
  }
  ServiceAvailableAreaForHome: item(path: "/sitecore/content/jss/masterjss/Data/Application Information List/Service Available Area For Home") {
    fields(excludeStandardFields: true) {
      ...on MultilistField {
        targetItems {
          fields(excludeStandardFields: true) {
            name
            editable
          }
        }
      }
    }
  }
  ServiceUnavailableAreaForApartmentHome: item(path: "/sitecore/content/jss/masterjss/Data/Application Information List/Service Unavailable Area For Apartment Home") {
    fields(excludeStandardFields: true) {
      ...on MultilistField {
        targetItems {
          fields(excludeStandardFields: true) {
            name
            editable
          }
        }
      }
    }
  }
  ServiceUnavailableAreaForNotApartmentHome: item(path: "/sitecore/content/jss/masterjss/Data/Application Information List/Service Unavailable Area For Not Apartment Home") {
    fields(excludeStandardFields: true) {
      ...on MultilistField {
        targetItems {
          fields(excludeStandardFields: true) {
            name
            editable
          }
        }
      }
    }
  }
  ServiceUnavailableAreaForHome: item(path: "/sitecore/content/jss/masterjss/Data/Application Information List/Service Unavailable Area For Home") {
    fields(excludeStandardFields: true) {
      ...on MultilistField {
        targetItems {
          fields(excludeStandardFields: true) {
            name
            editable
          }
        }
      }
    }
  }
  }`;
  