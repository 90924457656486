import React from "react";
import { Placeholder, RichText } from "@sitecore-jss/sitecore-jss-react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";

const ContractCancellationSuppressionLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  const pageContext = sitecoreContext?.route?.fields;
  di_register("thisPageSitecoreContext", sitecoreContext);

  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <RichText
                field={
                  pageContext["Contract Cancellation Suppression-Contents"]
                }
                className="no-left-right-margin img-max-width"
              />
              <div className="btn_container mt-40">
                <Placeholder
                  name="cancellation-suppression-button-section"
                  rendering={props.rendering}
                />
              </div>
              <div className="btn_container">
                <Placeholder
                  name="back-next-button-section"
                  rendering={props.rendering}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(ContractCancellationSuppressionLayout);
