import { di_fetch } from "./di";

const optionRequiredType = ["radiogroup", "combobox"];

export const parseFieldMetadata = (metadataDefinition) => {
  const result = {};
  const storage = di_fetch("storage");
  const fieldMetadata = metadataDefinition?.fieldMetadata;
  const metadataExt = fieldMetadata?.fieldMetadataExtension;

  result["name"] = metadataExt?.CMAP_FieldName__c?.trim();
  result["label"] = metadataExt?.CMAP_FieldLabel__c?.trim();
  result["type"] = metadataExt?.CMAP_Type__c;
  result["numberOfLetters"] = metadataExt?.CMAP_NumberOfLetters__c;
  result["maxValue"] = metadataExt?.CMAP_MaximumValue__c;
  result["minValue"] = metadataExt?.CMAP_MinimumValue__c;
  result["placeholder"] = metadataExt?.CMAP_Placeholder__c;
  result["isReadOnly"] = metadataExt?.CMAP_ReadOnly__c == "readonly";
  result["validationErrorMessage"] =
    metadataExt?.CMAP_ValidationErrorMessage__c;
  result["validationErrorPattern"] = metadataExt?.CMAP_ValidationPattern__c;

  // 条件付き制御
  if (metadataExt?.CMAP_Required__c === "required") {
    result["isRequired"] = true;
  } else if (metadataExt?.CMAP_Required__c === "option") {
    result["isRequired"] = evaluateCondition(
      storage,
      metadataExt?.CMAP_RequiredCondition__c
    );
  }
  result["isActive"] = evaluateCondition(
    storage,
    metadataExt?.CMAP_ActiveCondition__c
  );
  result["isVisible"] = evaluateCondition(
    storage,
    metadataExt?.CMAP_VisibleCondition__c
  );

  if (optionRequiredType.includes(metadataExt?.CMAP_Type__c)) {
    const conditionalOptions = processConditions(
      storage,
      metadataExt?.CMAP_OptionCondition__c
    );
    if (conditionalOptions?.listitem) {
      result["options"] = conditionalOptions?.listitem;
    } else {
      result["options"] = parseOptionString(metadataExt?.CMAP_Option__c);
    }
  }
  return result;
};

const evaluateCondition = (storage, conditionJsonStr) => {
  if (!storage || !conditionJsonStr) {
    return;
  }
  const { condition, logic } = JSON.parse(conditionJsonStr);
  const results = condition.map((cond) => {
    const { path, apiname, operator, value } = cond;
    const storageValue = path
      .split(".")
      .reduce((o, k) => (o || {})[k], storage)[apiname];

    // セッションストレージのデータを比較
    switch (operator) {
      case "=":
        return storageValue == value;
      case "!=":
        return storageValue != value;
      case ">":
        return storageValue > value;
      case "<":
        return storageValue < value;
      case ">=":
        return storageValue >= value;
      case "<=":
        return storageValue <= value;
    }
  });
  return logic === "AND" ? results.every(Boolean) : results.some(Boolean);
};

export const processConditions = (storage, conditionArrayStr) => {
  if (!conditionArrayStr) return null;
  const conditionsArray = JSON.parse(conditionArrayStr);
  for (let conditionObject of conditionsArray) {
    const { condition, logic } = conditionObject;

    if (evaluateCondition(storage, JSON.stringify({ condition, logic }))) {
      return conditionObject;
    }
  }
  return null;
};

export const parseOptionString = (optionString) => {
  const options = optionString?.split(",") ?? [];

  return options.map((option) => {
    const optionItemValue = option.substring(0, option.indexOf(':'));
    const optionItemLabel = option.substring(option.indexOf(':') + 1);

    return {
      label: optionItemLabel,
      value: optionItemValue
    };
  });
};

export const matchMetaOptions = (parsedMetaOptions, matchKey, defaultValue = "") => {
  let result = defaultValue;

  parsedMetaOptions.map((optionItem) => {
    if (optionItem?.value === matchKey) {
      result = optionItem?.label;
    }
  });

  return result;
};