export const query = `query {
    CtcMember: item(path: "/sitecore/content/jss/masterjss/Data/Common Menu Button List/ctc Member") {
      fields(excludeStandardFields: true) {
        ...on MultilistField {
          targetItems {
            fields(excludeStandardFields: true) {
              definition {
                sortOrder
              }
              name
              editable
              ...on LookupField {
                targetItem {
                  field(name: "Code") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    CtcMemberWithContract: item(path: "/sitecore/content/jss/masterjss/Data/Common Menu Button List/ctc Member with contract") {
      fields(excludeStandardFields: true) {
        ...on MultilistField {
          targetItems {
            fields(excludeStandardFields: true) {
              definition {
                sortOrder
              }
              name
              editable
              ...on LookupField {
                targetItem {
                  field(name: "Code") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }`;
  