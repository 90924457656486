import { recoilKeys as keys } from "../../../assets/recoilKeys";
import {
  STRING_STANDARD,
  STRING_DISCOUNT,
  STRING_OPTION
} from "../../../utils/constantVariables";
import {
  getSessionStorageItem,
} from "../../../utils/useSessionStorage";
import linq from 'linq'
import {
  filterSelectedSelectionProduct,
  filterSelectedAutomaticSelectionProduct
} from "../Common/CommonProcess"

// 変更内容
export const createChangeDetailsObj = () => {
  // SessionStorageから変更前と変更後のOrderRelatedInfoを読み込む。
  const before = getSessionStorageItem(keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO, true);
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更前と変更後のOrderRelatedInfoから対象の選択商品を抽出する。
  const beforeOrderInfos = filterSelectedSelectionProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterOrderInfos = filterSelectedSelectionProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 対象の商品を表示用オブジェクトに整形する。
  const target = createChangeDetailsBaseObj(beforeOrderInfos, afterOrderInfos);
  const result = sortChangeDetailsObj(target, after);

  return result;
}

// 自動選択商品
export const createAutomaticSelectionProductObj = () => {
  // SessionStorageから変更前と変更後のOrderRelatedInfoを読み込む。
  const before = getSessionStorageItem(keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO, true);
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更前と変更後のOrderRelatedInfoから対象の自動選択商品を抽出する。
  const beforeOrderInfos = filterSelectedAutomaticSelectionProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterOrderInfos = filterSelectedAutomaticSelectionProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 対象の商品を表示用オブジェクトに整形する。
  const target = createChangeDetailsBaseObj(beforeOrderInfos, afterOrderInfos);
  const result = sortChangeDetailsObj(target, after);

  return result;
}

// 月額料金
export const createMonthlyExpenseObj = (beforeFeeSimulationResponse, afterFeeSimulationResponse) => {
  // SessionStorageから変更前と変更後のOrderRelatedInfoを読み込む。
  const before = getSessionStorageItem(keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO, true);
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更前と変更後の合計金額を計算する。
  const beforeTotalPrice = calculateTotalPriceByFeeSimulationResponse(beforeFeeSimulationResponse, before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterTotalPrice = calculateTotalPriceByFeeSimulationResponse(afterFeeSimulationResponse, after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 合計金額を表示用オブジェクトに整形する。
  const result = {
    'BeforeMonthlyBasic': 0,
    'BeforeMonthlyDiscount': 0,
    'BeforeMonthlyOption': 0,
    'AfterMonthlyBasic': 0,
    'AfterMonthlyDiscount': 0,
    'AfterMonthlyOption': 0
  };
  [beforeTotalPrice, afterTotalPrice].forEach((totalPrice, index) => {
    const prefix = index === 0 ? 'Before' : 'After';
    totalPrice.forEach(item => {
      if (item.simulationOrderItemCategory === STRING_STANDARD) {
        result[prefix + 'MonthlyBasic'] = item.totalPriceTaxInc;
      } else if (item.simulationOrderItemCategory === STRING_DISCOUNT) {
        result[prefix + 'MonthlyDiscount'] = item.totalPriceTaxInc;
      } else if (item.simulationOrderItemCategory === STRING_OPTION) {
        result[prefix + 'MonthlyOption'] = item.totalPriceTaxInc;
      }
    });
  });

  return result;
}

const createChangeDetailsBaseObj = (before, after) => {
  const result = {};

  // 変更前と変更後の配列を順にループする。
  [before, after].forEach((expenses, index) => {
    expenses.forEach(item => {

      // 商品をキーにする。
      const key = item.orderItem.Product2Id;

      // キー毎に重複しないように結果用オブジェクトに追加する。
      if (!result[key]) {
        result[key] = {
          CMAP_MainSubProductGroupId__c: item.orderItem.CMAP_MainSubProductGroupId__c,
          CMAP_MainSubProductGroupName__c: item?.orderItem?.CMAP_MainSubProductGroupName__c,
          CMAP_ProductCategoryId__c: item.orderItem.CMAP_ProductCategoryId__c,
          CMAP_ProductCategoryName__c: item?.orderItem?.CMAP_ProductCategoryName__c,
          Product2Id: item?.orderItem?.Product2Id,
          Before_CMAP_ProductName__c: null,
          After_CMAP_ProductName__c: null
        };
      }

      // 変更前(index=0)の時は変更前商品名に設定し、変更後(index=1)の時は、変更後商品名に設定する。
      if (index === 0) {
        result[key]['Before_CMAP_ProductName__c'] = item?.orderItem?.CMAP_ProductName__c;
      } else if (index === 1) {
        result[key]['After_CMAP_ProductName__c'] = item?.orderItem?.CMAP_ProductName__c;
      }
    });
  });

  // 配列に変換する。
  return Object.keys(result).map(key => result[key]);
}

const sortChangeDetailsObj = (target, after) => {
  const afterOrderItemInfos = linq.from(after?.orderRelatedInfo?.orderInfo?.orderItemInfos).select(x => x?.orderItem).toArray();
  const result = linq.from(target)
    .groupJoin(afterOrderItemInfos, // LeftOuterJoinする右側の配列
      x => x?.Product2Id, // 左側テーブルの結合キー：商品
      y => y?.Product2Id, // 右側テーブルの結合キー：商品
      (x, y) => ({
        ...x, // 左側テーブルの全項目
        y
      }))
    .selectMany(x => x.y.defaultIfEmpty(), // LeftOuterJoinの続き
      (x, y) => {
        delete x.y;
        return {
          ...x, // 左側テーブルの全項目
          CMAP_MainSubProGroupBenefitCPSortOrder__c: y?.CMAP_MainSubProGroupBenefitCPSortOrder__c, // メイン商品/サブ商品グループ・特典キャンペーン並び順
          CMAP_ProductCategoryBenefitSortOrder__c: y?.CMAP_ProductCategoryBenefitSortOrder__c, // 商品カテゴリ・特典並び順
          CMAP_ProductSortOrder__c: y?.CMAP_ProductSortOrder__c // 商品並び順
        }
      }
    )
    .orderBy(x => x?.CMAP_MainSubProGroupBenefitCPSortOrder__c) // 第1ソート：メイン商品/サブ商品グループ・特典キャンペーン並び順
    .thenBy(x => x?.CMAP_ProductCategoryBenefitSortOrder__c) // 第2ソート：商品カテゴリ・特典並び順
    .thenBy(x => x?.CMAP_ProductSortOrder__c) // 第3ソート：商品並び順
    .toArray();

  return result;
}

const calculateTotalPriceByFeeSimulationResponse = (feeSimulationResponse, orderItemInfos) => {
  const result = linq.from(feeSimulationResponse)
    .groupJoin(orderItemInfos, // LeftOuterJoinする右側の配列
      x => x?.orderItem?.Product2Id, // 左側テーブルの結合キー：商品
      y => y?.orderItem?.Product2Id, // 右側テーブルの結合キー：商品
      (x, y) => ({
        ...x, // 左側テーブルの全項目
        y
      }))
    .selectMany(x => x.y.defaultIfEmpty(), // LeftOuterJoinの続き
      (x, y) => {
        delete x.y;
        return {
          simulationOrderItemCategory: x?.simulationInfo?.simulationOrderItemCategory, // 料金シミュレーション区分（基本/オプション/割引）
          unitPriceTaxInc: y?.orderItem?.CMAP_Unchargeable__c === true ? 0 : Math.trunc(x?.simulationInfo?.simulatedUnitPriceTaxExc1 * (100 + y?.orderItem?.CMAP_ComsumptionTaxRate__c) / 100) // 商品毎の税込み金額
        }
      }
    )
    .groupBy(
      x => x.simulationOrderItemCategory, // グループ化するキー：料金シミュレーション区分（基本/オプション/割引）
      x => x.unitPriceTaxInc, // 集計対象の値：商品毎の税込み金額
      (key, group) => ({
        simulationOrderItemCategory: key, // 料金シミュレーション区分（基本/オプション/割引）
        totalPriceTaxInc: group.sum() // 料金シミュレーション区分（基本/オプション/割引）: 合計金額
      }))
    .toArray();

  return result;
}