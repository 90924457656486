export const query = `query {
  SimpleApplicationDropdownItems: item(path: "/sitecore/content/jss/masterjss/Data/CTC Master/SimpleOpApplicationDropdownMaster") {
    children {
      children{
        fields(excludeStandardFields: true) {
        name
        value
        rendered
        }
      }
      name
  }
  }
}`;