import React, { useRef, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from '../../utils/commonUtils';

const B005 = (props) => {
  // @Model
  const { additionalClass, buttonLabel } = props.fields;
  const btnWrapperRef = useRef(null);

  // @Controller
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const {
      innerWidth: windowWidth,
      innerHeight: windowHeight,
      scrollY: scrollTop,
    } = window;
    const { scrollHeight: documentHeight } = document.documentElement;
    const btnWrapperEl = btnWrapperRef?.current;
    let bottomPx = 0;

    if (scrollTop + windowHeight >= documentHeight && windowWidth <= 640) {
      const spDisplayEl = document.querySelector(".sp-display");
      bottomPx = spDisplayEl ? spDisplayEl?.offsetHeight : 0;
    }

    btnWrapperEl.style.bottom = bottomPx + "px";
  };

  // @View
  return (
    <React.Fragment>
      {/* TODO: Assign the value of additionalClass field to "disabled" if you want to disable this button otherwise, leave as empty */}
      <div
        className={`btn_wrapper primary fix B-05 
          ${additionalClass?.value || ""}`}
        ref={btnWrapperRef}
      >
        <button type="button" className="btn btn_default">
          <Text field={fillBlank(buttonLabel)} />
        </button>
      </div>
    </React.Fragment>
  );
};

export default B005;
