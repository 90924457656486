export const query = `query {
    HearingOptionMaster: item(path: "/sitecore/content/jss/masterjss/Data/CTC Master/HearingOptionMaster") {
      children {
        fields(excludeStandardFields: true) {
          name
          value
        }
      }
    }
  }`;
