import React, { useContext, useMemo, useRef } from "react";
import { Placeholder, RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import ModalContext from "../../assets/context/ModalContext";
import { getProperData, preventDoubleClick } from "../../utils/commonUtils";
import { fillBlank } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const M001 = (props) => {
  // get the data
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);

  // @Model
  const {
    closeText,
    additionalClass,
    infoModalText,
    modalTarget,
    richTextContent,
    importantMatterDescriptionList,
  } = properData;

  const { state, dispatch } = useContext(ModalContext);
  const modalBodyContentRef = useRef(null);

  const {
    showModal,
    displayModal,
    paddingModal,
    roleValue,
    colorGrayClass,
  } = useMemo(() => {
    const { showModal, id } = state;
    return showModal && modalTarget?.value === id
      ? state
      : {
          showModal: "",
          displayModal: "",
          paddingModal: "",
          roleValue: "",
          colorGrayClass: "",
        };
  }, [state]);

  // @Controller
  const modalCloseButtonHandler = () => {
    modalBodyContentRef.current.scrollTop = 0;
    dispatch({ type: "HIDE_MODAL", payload: { id: modalTarget?.value } });
  };

  const handleCloseButtonClick = preventDoubleClick(async () =>
    modalCloseButtonHandler()
  );

  const ImportantMatterDescriptionItem = (item) => {
    const importantMatterDescription =
      item.importantMatterDescriptionInfo?.importantMatterDescription;
    const importantMatterDescriptionContents =
      item.importantMatterDescriptionInfo?.importantMatterDescriptionContents;
    return (
      <>
        <p className={`title ${additionalClass?.value}`}>
          <i className="bi bi-info-circle-fill"></i>
          <Text field={fillBlank(importantMatterDescription?.Name)} />
        </p>
        {importantMatterDescriptionContents &&
          importantMatterDescriptionContents.length > 0 &&
          importantMatterDescriptionContents.map((content) =>
            ImportantMatterDescriptionContent(content)
          )}
        <br />
      </>
    );
  };

  const ImportantMatterDescriptionContent = (content) => {
    const CMAP_Title__c = content.CMAP_Title__c;
    const CMAP_ProductImageURL__c = content.CMAP_ProductImageURL__c;
    const CMAP_Content__c = content.CMAP_Content__c;
    const CMAP_AltMessage__c = content.CMAP_AltMessage__c;
    if (CMAP_Title__c !== "" && CMAP_ProductImageURL__c !== "") {
      return (
        <>
          <p className="bold mb-10">{CMAP_Title__c}</p>
          <p>{CMAP_Content__c}</p>
          <img src={CMAP_ProductImageURL__c} alt={CMAP_AltMessage__c} />
        </>
      )
    }
  };

  // @View
  return (
    <React.Fragment>
      <div
        className={`modal fade M0-01 ${showModal}`}
        tabIndex="-1"
        data-backdrop="static"
        role={roleValue}
        aria-modal="true"
        style={{ display: displayModal, paddingRight: paddingModal }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseButtonClick}
              >
                <span className="close-text">
                  <Text field={fillBlank(closeText)} />
                </span>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-body_content" ref={modalBodyContentRef}>
                {importantMatterDescriptionList &&
                importantMatterDescriptionList.length > 0 ? (
                  importantMatterDescriptionList.map((item, index) =>
                    ImportantMatterDescriptionItem(item)
                  )
                ) : (
                  <>
                    {infoModalText && infoModalText.value !== '' &&
                      <p className={`title ${additionalClass?.value}`}>
                      <i className="bi bi-info-circle-fill"></i>
                      <Text field={fillBlank(infoModalText)} />
                    </p>
                    }
                    
                    <Placeholder
                      name="modal-section"
                      rendering={props.rendering}
                    />
                    {richTextContent?.value && (
                      <RichText field={richTextContent} />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={colorGrayClass}></div>
    </React.Fragment>
  );
};

export default M001;
