import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import {
  fillBlank,
  generateGUID,
  getProperData,
} from "../../utils/commonUtils";

const TB01 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);

  const { additionalClass, columnData, rowData, isHidden } = properData;
  const columns = columnData?.value === "" ? [] : columnData;
  const rows = rowData?.value === "" ? [] : rowData;

  const renderTableColumns = () => {
    return (
      <thead>
        <tr>
          {columns?.map((col, i) => {
            const key = col.header + "_" + generateGUID();
            if (col.children) {
              return (
                <th key={key} colSpan={col.colSpan} width={col.width}>
                  <Text field={fillBlank(col.header)} />
                </th>
              );
            }

            return (
              <th
                key={key}
                colSpan={col.colSpan}
                rowSpan={col.rowSpan}
                width={col.width}
                className={col.class}
              >
                <Text field={fillBlank(col.header)} />
              </th>
            );
          })}
        </tr>
        <tr>
          {columns?.map((col) => {
            if (col.children) {
              return col.children.map((child) => (
                <th key={child.header + "_" + generateGUID()}>
                  <Text field={fillBlank(child.header)} />
                </th>
              ));
            }
            return null;
          })}
        </tr>
      </thead>
    );
  };

  const renderTableRows = () => {
    return (
      <tbody>
        {rows?.map((row, i) => (
          <tr key={"tr_" + generateGUID()}>
            {columns?.map((col) => {
              const cell = row[col.key];
              const cellData = {
                key: col.key,
                row: row,
                value: row[col.key],
              };
              return (
                <React.Fragment key={"td_" + generateGUID()}>
                  {col.children ? (
                    col.children.map((child) => (
                      <td key={"td_child_" + generateGUID()}>
                        {child.Cell
                          ? child.Cell({ ...cellData, value: row[child.key] })
                          : row[child.key]}
                      </td>
                    ))
                  ) : (
                    <>
                      {Array.isArray(row[col.key]) ? (
                        <>
                          {row[col.key].map((sub) => (
                            <td
                              colSpan={sub.colSpan}
                              rowSpan={sub.rowSpan}
                              key={sub.text + "_" + generateGUID()}
                            >
                              {col.Cell
                                ? col.Cell({ ...cellData, value: sub.value })
                                : sub.value}
                            </td>
                          ))}
                        </>
                      ) : row[col.key] instanceof Object ? (
                        <>
                          <td
                            colSpan={row[col.key].colSpan}
                            rowSpan={row[col.key].rowSpan}
                          >
                            {col.Cell
                              ? col.Cell({
                                  ...cellData,
                                  value: row[col.key].value,
                                })
                              : row[col.key].value}
                          </td>
                        </>
                      ) : (
                        <td>{col.Cell ? col.Cell(cellData) : row[col.key]}</td>
                      )}
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <React.Fragment>
      {!isHidden && (
        <div className={`cpad-tables ${additionalClass?.value}`}>
          <table className="border-style-1">
            {renderTableColumns()}
            {renderTableRows()}
          </table>
        </div>
      )}
    </React.Fragment>
  );
};

export default TB01;