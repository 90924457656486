import React, { useRef, useEffect, useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import ModalContext from "../../assets/context/ModalContext";
import { fillBlank, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const BM05 = (props) => {
  // @Model
  const btnWrapperRef = useRef(null);
  const { additionalClass, buttonLabel, modalTarget } = props.fields;
  const { dispatch } = useContext(ModalContext);

  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);

  // @Controller
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const {
      innerWidth: windowWidth,
      innerHeight: windowHeight,
      scrollY: scrollTop,
    } = window;
    const { scrollHeight: documentHeight } = document.documentElement;
    const btnWrapperEl = btnWrapperRef?.current;
    let bottomPx = 0;

    if (scrollTop + windowHeight >= documentHeight && windowWidth <= 640) {
      const spDisplayEl = document.querySelector(".sp-display");
      bottomPx = spDisplayEl ? spDisplayEl?.offsetHeight : 0;
    }

    btnWrapperEl.style.bottom = bottomPx + "px";
  };

  const modalButtonShowHandler = () => {
    const hideModal = properData.onClickEvent();
    if (!hideModal)
      dispatch({ type: "SHOW_MODAL", payload: { id: modalTarget?.value } });
  };

  // @View
  return (
    <React.Fragment>
      {/* TODO: Assign the value of additionalClass field to "disabled" if you want to disable this button otherwise, leave as empty */}
      <div
        className={`btn_wrapper primary fix BM-05 
          ${additionalClass?.value || ""}`}
        ref={btnWrapperRef}
      >
        <button
          type="button"
          className="btn btn_default"
          onClick={modalButtonShowHandler}
        >
          <Text field={fillBlank(buttonLabel)} />
        </button>
      </div>
    </React.Fragment>
  );
};

export default BM05;
