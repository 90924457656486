export const query = `query {
  contractNameTypeMaster: item(path: "/sitecore/content/jss/masterjss/Data/CTC Master/ContractNameTypeMaster") {
    children {
      fields(excludeStandardFields: true) {
        name
        value
        editable
      }
    }
  }
}`;


