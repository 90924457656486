import React, { useEffect } from "react";

const CPadLogoutLayout = (props) => {
  useEffect(() => {
    props.setIsLoading(true);
    if (window?.webkit?.messageHandlers?.startLogout !== undefined) {
      window.webkit.messageHandlers.startLogout.postMessage("");
    } else if (window?.android?.startLogout !== undefined) {
      window.android.startLogout("");
    }
  }, []);

  return <></>;
};

export default CPadLogoutLayout;
