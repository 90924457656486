import React, { useEffect, useState } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { apiBaseURL } from "../../envSettings";
import { useCustomQuery } from "../../hooks/useGetData";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import {
  setSessionStorageItem,
  getSessionStorageItem,
} from "../../utils/useSessionStorage";
import {
  getParamValue,
  getUrlInfo,
  redirectToLinkUrl,
} from "../../utils/commonUtils";
import {
  updateOrderRelatedInfoRequestMapper,
  updateOrderRelatedInfoResponseMapper,
} from "./updateOrderRelatedInfoMapper";
import { getGaClientIdFromCookie } from "../../utils/useCookies";
import {
  STRING_CHANGEPLAN,
  STRING_TERMINATE,
  STRING_CHANGEITEM,
  STRING_CANCEL,
  STRING_MOVING,
  STRING_RELOCATE,
  STRING_POST,
  API_RESPONSE_STATUS,
  STRING_CATEGORY_TERMINATION_REMAINING_DEBT_LUMPSUM_PAYMENT,
  STRING_CATEGORY_CANCEL,
  STRING_CATEGORY_PLAN_CHANGE,
  STRING_CATEGORY_PRODUCT_CHANGE,
  STRING_CATEGORY_MOVEMENT,
  STRING_CATEGORY_TRANSFER,
  STRING_REBUILDING,
} from "../../utils/constantVariables";

const ContractAssignmentLayout = (props) => {
  let orderCategory;
  const urlInfo = getUrlInfo();
  const parentPath = urlInfo?.pathnames[urlInfo?.pathnames?.length - 2];

  switch (parentPath) {
    case STRING_TERMINATE:
      orderCategory = STRING_CATEGORY_TERMINATION_REMAINING_DEBT_LUMPSUM_PAYMENT;
      break;
    case STRING_CANCEL:
      orderCategory = STRING_CATEGORY_CANCEL;
      break;
    case STRING_CHANGEPLAN:
      orderCategory = STRING_CATEGORY_PLAN_CHANGE;
      break;
    case STRING_CHANGEITEM:
      orderCategory = STRING_CATEGORY_PRODUCT_CHANGE;
      break;
    case STRING_MOVING:
      orderCategory = STRING_CATEGORY_MOVEMENT;
      break;
    case STRING_RELOCATE:
      orderCategory = STRING_CATEGORY_TRANSFER;
      break;
    case STRING_REBUILDING:
      orderCategory = STRING_CATEGORY_MOVEMENT;
      break;
  }

  const pageContext = props.sitecoreContext.route.fields;

  const orderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );

  let contractId = getSessionStorageItem(
    sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTID,
    true
  );

  let applicationId = getSessionStorageItem(
    sessionKeys.SSN_REPORT_APPLICATIONLIST_APPLICATIONID,
    true
  );

  const individualContractId = getParamValue("individualContractId");
  const orderId = getParamValue("orderId");

  if (individualContractId) {
    contractId = individualContractId;
    setSessionStorageItem(
      sessionKeys.SSN_REPORT_CONTRACTLIST_CONTRACTID,
      contractId,
      true
    );
  }

  if (orderId) {
    applicationId = orderId;
    setSessionStorageItem(
      sessionKeys.SSN_REPORT_APPLICATIONLIST_APPLICATIONID,
      applicationId,
      true
    );
  }

  const createOrderRelatedInfoURI = `${apiBaseURL}order/CreateOrderRelatedInfo`;
  const updateOrderRelatedInfoURI = `${apiBaseURL}order/UpdateOrderRelatedInfo`;

  const [requestParam, setRequestParam] = useState();
  const [gaCookieClientId, setGaCookieClientId] = useState("");
  useEffect(() => {
    setGaCookieClientId(getGaClientIdFromCookie());
    setRequestParam({
      orderId: applicationId,
      individualContractId: contractId,
      caseId: "",
      customerId: "",
      leadId: "",
      orderCategorySelected: orderCategory,
    });
  }, []);

  const [
    updateOrderRelatedInfoRequest,
    setUpdateOrderRelatedInfoRequest,
  ] = useState();

  const {
    data: createOrderRelatedInfoData,
    isLoading: createOrderRelatedInfoIsLoading,
    status: createOrderRelatedInfoStatus,
    refetch: createOrderRelatedInfoRefresh,
  } = useCustomQuery(
    createOrderRelatedInfoURI,
    false,
    STRING_POST,
    requestParam
  );

  useEffect(() => {
    if (requestParam) createOrderRelatedInfoRefresh();
  }, [requestParam]);

  useEffect(() => {
    if (createOrderRelatedInfoStatus === API_RESPONSE_STATUS.SUCCESS) {
      const accountData =
        createOrderRelatedInfoData?.orderRelatedInfo.accountInfo.account;
      const accountAdditionData =
        createOrderRelatedInfoData?.orderRelatedInfo.accountInfo
          .accountAddition;
      const orderData =
        createOrderRelatedInfoData?.orderRelatedInfo.orderInfo.order;
      const orderAdditionData =
        createOrderRelatedInfoData?.orderRelatedInfo.orderInfo.orderAddition;

      const orderRelatedInfoFromCreate = {
        orderRelatedInfo: {
          accountInfo: {
            account: {
              Id: accountData?.Id,
              CMAP_ContractName__c: accountData?.CMAP_ContractName__c,
              LastName: accountData?.LastName,
              FirstName: accountData?.FirstName,
              LastNameKana__pc: accountData?.LastNameKana__pc,
              FirstNameKana__pc: accountData?.FirstNameKana__pc,
              CMAP_Gender__c: accountData?.CMAP_Gender__c,
              PersonBirthdate: accountData?.PersonBirthdate,
              CMAP_CorporateGroupName__c:
                accountData?.CMAP_CorporateGroupName__c,
              CMAP_DepartmentName__c: accountData?.CMAP_DepartmentName__c,
              CMAP_ContactPersonFamilyName__c:
                accountData?.CMAP_ContactPersonFamilyName__c,
              CMAP_ContactPersonName__c: accountData?.CMAP_ContactPersonName__c,
              CMAP_ContactPersonLastNameKana__pc:
                accountData?.CMAP_ContactPersonLastNameKana__pc,
              CMAP_ContactPersonFirstNameKana__pc:
                accountData?.CMAP_ContactPersonFirstNameKana__pc,
              CMAP_ContactPostalCode__c: accountData?.CMAP_ContactPostalCode__c,
              CMAP_ContactPostalCodeLowerFour__c:
                accountData?.CMAP_ContactPostalCodeLowerFour__c,
              CMAP_ContactState__c: accountData?.CMAP_ContactState__c,
              CMAP_ContactCity__c: accountData?.CMAP_ContactCity__c,
              CMAP_ContactHouseNumber__c:
                accountData?.CMAP_ContactHouseNumber__c,
              CMAP_MansionName__c: accountData?.CMAP_MansionName__c,
              CMAP_RoomNo__c: accountData?.CMAP_RoomNo__c,
              CMAP_AreaCode__c: accountData?.CMAP_AreaCode__c,
              CMAP_LocalTelephoneNumber__c:
                accountData?.CMAP_LocalTelephoneNumber__c,
              CMAP_SubscriberNumber__c: accountData?.CMAP_SubscriberNumber__c,
              CMAP_MobileNumber1__c: accountData?.CMAP_MobileNumber1__c,
              CMAP_MobileNumber2__c: accountData?.CMAP_MobileNumber2__c,
              CMAP_MobileNumber3__c: accountData?.CMAP_MobileNumber3__c,
              CMAP_MyCommufaID__c: accountData?.CMAP_MyCommufaID__c,
              CMAP_MultilingualReexplainCenter__c:
                accountData?.CMAP_MultilingualReexplainCenter__c,
            },
            accountAddition: {
              isNewAccount: accountAdditionData?.isNewAccount,
              leadId: accountAdditionData?.leadId,
            },
          },
          orderInfo: {
            order: {
              AccountId: orderData?.AccountId,
              CMAP_ContractName__c: orderData?.CMAP_ContractName__c,
              CMAP_Case__c: orderData?.CMAP_Case__c,
              CMAP_Billing__c: orderData?.CMAP_Billing__c,
              CMAP_BillingPaymentMethodAgent__c:
                orderData?.CMAP_BillingPaymentMethodAgent__c,
              CMAP_BillingPaymentMethodRegistered__c:
                orderData?.CMAP_BillingPaymentMethodRegistered__c,
              CMAP_NeedsSendingDocumentOfBilling__c:
                orderData?.CMAP_NeedsSendingDocumentOfBilling__c,
              CMAP_AcquisitionAgency__c: orderData?.CMAP_AcquisitionAgency__c,
              CMAP_AcquisitionShop__c: orderData?.CMAP_AcquisitionShop__c,
              CMAP_IndividualContractComplementary__c:
                orderData?.CMAP_IndividualContractComplementary__c,
              CMAP_ContractDocDeliveryCategory__c:
                orderData?.CMAP_ContractDocDeliveryCategory__c,
              CMAP_StandardProductOffer__c:
                orderData?.CMAP_StandardProductOffer__c,
              CMAP_MovementStandardProductOffer__c:
                orderData?.CMAP_MovementStandardProductOffer__c,
            },
            orderAddition: {
              orderChannelCategory: orderAdditionData?.orderChannelCategory,
            },
            orderItemInfos: createOrderRelatedInfoData?.orderRelatedInfo?.orderInfo?.orderItemInfos?.map(
              (orderItemInfo) => {
                const orderItem = orderItemInfo.orderItem;
                const orderItemAddition = orderItemInfo.orderItemAddition;
                return {
                  orderItem: {
                    CMAP_OfferPlanProduct__c:
                      orderItem?.CMAP_OfferPlanProduct__c,
                    CMAP_MainSubProductGroupId__c:
                      orderItem?.CMAP_MainSubProductGroupId__c,
                    CMAP_MainSubProductGroupCategory__c:
                      orderItem?.CMAP_MainSubProductGroupCategory__c,
                    CMAP_MainSubProductGroupName__c:
                      orderItem?.CMAP_MainSubProductGroupName__c,
                    CMAP_ProductCategoryId__c:
                      orderItem?.CMAP_ProductCategoryId__c,
                    CMAP_ProductCategoryName__c:
                      orderItem?.CMAP_ProductCategoryName__c,
                    Product2Id: orderItem?.Product2Id,
                    CMAP_ProductName__c: orderItem?.CMAP_ProductName__c,
                    UnitPrice: orderItem?.UnitPrice,
                    CMAP_ComsumptionTaxRate__c:
                      orderItem?.CMAP_ComsumptionTaxRate__c,
                    Quantity: orderItem?.Quantity,
                    CMAP_PriceCategory__c: orderItem?.CMAP_PriceCategory__c,
                    CMAP_InstallmentPaymentMonthly__c:
                      orderItem?.CMAP_InstallmentPaymentMonthly__c,
                    CMAP_InstallmentPayMonthlyPriceTaxExc__c:
                      orderItem?.CMAP_InstallmentPayMonthlyPriceTaxExc__c,
                    CMAP_NumberOfInstallments__c:
                      orderItem?.CMAP_NumberOfInstallments__c,
                    CMAP_InstallmentPayFirstMonthPriceTaxExc__c:
                      orderItem?.CMAP_InstallmentPayFirstMonthPriceTaxExc__c,
                    CMAP_InstallmentPaymentCurrently__c:
                      orderItem?.CMAP_InstallmentPaymentCurrently__c,
                    CMAP_SelectionAnnualPayment__c:
                      orderItem?.CMAP_SelectionAnnualPayment__c,
                    CMAP_AnnualPaymentPriceTaxExcluded__c:
                      orderItem?.CMAP_AnnualPaymentPriceTaxExcluded__c,
                    CMAP_Unchargeable__c: orderItem?.CMAP_Unchargeable__c,
                    CMAP_ProductSelectionState__c:
                      orderItem?.CMAP_ProductSelectionState__c,
                    CMAP_LastTimeChangeCategory__c:
                      orderItem?.CMAP_LastTimeChangeCategory__c,
                    CMAP_HasOrderItemAttribute__c:
                      orderItem?.CMAP_HasOrderItemAttribute__c,
                    CMAP_AvailableDailyPricing__c:
                      orderItem?.CMAP_AvailableDailyPricing__c,
                    CMAP_UnchangeableContract__c:
                      orderItem?.CMAP_UnchangeableContract__c,
                    CMAP_SetProduct__c: orderItem?.CMAP_SetProduct__c,
                    CMAP_HasPriceChanged__c: orderItem?.CMAP_HasPriceChanged__c,
                    CMAP_TaxationCategory__c:
                      orderItem?.CMAP_TaxationCategory__c,
                    CMAP_RemainingDebtLumpSumPayment__c:
                      orderItem?.CMAP_RemainingDebtLumpSumPayment__c,
                    CMAP_ChangeSourceDestinationCategory__c:
                      orderItem?.CMAP_ChangeSourceDestinationCategory__c,
                    CMAP_InheritsMovementAssetAttribute__c:
                      orderItem?.CMAP_InheritsMovementAssetAttribute__c,
                    CMAP_OrderItemTypeSelection__c:
                      orderItem?.CMAP_OrderItemTypeSelection__c,
                    CMAP_OrderItemTypeBenefit__c:
                      orderItem?.CMAP_OrderItemTypeBenefit__c,
                    CMAP_OrderItemTypeAutomaticSelection__c:
                      orderItem?.CMAP_OrderItemTypeAutomaticSelection__c,
                    CMAP_BeforeOffer__c: orderItem?.CMAP_BeforeOffer__c,
                    CMAP_MainSubProGroupBenefitCPSortOrder__c:
                      orderItem?.CMAP_MainSubProGroupBenefitCPSortOrder__c,
                    CMAP_ProductCategoryBenefitSortOrder__c:
                      orderItem?.CMAP_ProductCategoryBenefitSortOrder__c,
                    CMAP_ProductSortOrder__c:
                      orderItem?.CMAP_ProductSortOrder__c,
                    CMAP_StandardProduct__c: orderItem?.CMAP_StandardProduct__c,
                    CMAP_PredecessorOfferStartRequest__c:
                      orderItem?.CMAP_PredecessorOfferStartRequest__c,
                    CMAP_ExemptionPeriod__c: orderItem?.CMAP_ExemptionPeriod__c,
                  },
                  orderItemAddition: {
                    precaution: orderItemAddition?.precaution,
                    tooltip: orderItemAddition?.tooltip,
                    cancellationPrecaution:
                      orderItemAddition?.cancellationPrecaution,
                    terminationPrecaution:
                      orderItemAddition?.terminationPrecaution,
                    otherCompanyScreenTransitionLinkDescription:
                      orderItemAddition?.otherCompanyScreenTransitionLinkDescription,
                    otherCompanyScreenTransitionLinkURL:
                      orderItemAddition?.otherCompanyScreenTransitionLinkURL,
                    quantityCategory: orderItemAddition?.quantityCategory,
                    totalPrice: orderItemAddition?.totalPrice,
                  },
                };
              }
            ),
          },
        },
      };

      setSessionStorageItem(
        sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
        orderRelatedInfoFromCreate,
        true
      );
    }
  }, [createOrderRelatedInfoData, createOrderRelatedInfoStatus]);

  const {
    data: updateOrderRelatedInfoData,
    isLoading: updateOrderRelatedInfoIsLoading,
    status: updateOrderRelatedInfoStatus,
    refetch: updateOrderRelatedInfoRefresh,
  } = useCustomQuery(
    updateOrderRelatedInfoURI,
    false,
    STRING_POST,
    updateOrderRelatedInfoRequest
  );

  useEffect(() => {
    if (createOrderRelatedInfoStatus === API_RESPONSE_STATUS.SUCCESS) {
      const updateRequest = updateOrderRelatedInfoRequestMapper(
        orderRelatedInfo
      );
      setUpdateOrderRelatedInfoRequest(updateRequest);
    }
  }, [createOrderRelatedInfoStatus]);

  useEffect(() => {
    if (updateOrderRelatedInfoRequest) updateOrderRelatedInfoRefresh();
  }, [updateOrderRelatedInfoRequest]);

  useEffect(() => {
    if (updateOrderRelatedInfoStatus === API_RESPONSE_STATUS.SUCCESS) {
      const updateOrderRelatedInfo = updateOrderRelatedInfoResponseMapper(
        updateOrderRelatedInfoData,
        orderCategory,
        gaCookieClientId
      );
      setSessionStorageItem(
        sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
        updateOrderRelatedInfo,
        true
      );

      setSessionStorageItem(
        sessionKeys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO,
        updateOrderRelatedInfo,
        true
      );
      const nextPageLink = pageContext["ContractAssignment-NextPageLink"];
      redirectToLinkUrl(nextPageLink);
    }
  }, [updateOrderRelatedInfoStatus]);

  const isApiLoading =
    createOrderRelatedInfoIsLoading || updateOrderRelatedInfoIsLoading;
  props.setIsLoading(isApiLoading);

  return <></>;
};

export default withSitecoreContext()(ContractAssignmentLayout);
