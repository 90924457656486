import React, { createContext, useEffect, useReducer } from "react";

const ModalContext = createContext("");

const initialModalState = {
  id: "",
  showModal: "",
  displayModal: "none",
  paddingModal: "",
  roleValue: "",
  colorGrayClass: "",
  imgPath: "",
  channelByBroadcastingCodes: [],
};

const modalReducer = (state, action) => {
  switch (action.type) {
    case "SHOW_MODAL": {
      const newState = { ...state };
      newState.id = action.payload?.id ?? "";
      newState.showModal = "show";
      newState.displayModal = "block";
      newState.paddingModal = "5px";
      newState.roleValue = "dialog";
      newState.colorGrayClass = "modal-backdrop fade show";
      newState.bodyModalClass = document.body.classList.add("modal-open");
      newState.bodyModalStyle = document.body.style.paddingRight = "5px";
      return newState;
    }
    case "HIDE_MODAL": {
      const newState = { ...state };
      newState.id = action.payload.id ?? "";
      newState.showModal = "";
      newState.displayModal = "none";
      newState.paddingModal = "";
      newState.roleValue = "";
      newState.colorGrayClass = "";
      newState.bodyModalClass = document.body.classList.remove("modal-open");
      newState.bodyModalStyle = document.body.style.paddingRight = "";
      return newState;
    }
    case "CANVAS_IMG": {
      const newState = { ...state };
      newState.showModal = "";
      newState.displayModal = "none";
      newState.paddingModal = "";
      newState.roleValue = "";
      newState.colorGrayClass = "";
      newState.bodyModalClass = document.body.classList.remove("modal-open");
      newState.bodyModalStyle = document.body.style.paddingRight = "";
      newState.imgPath = action.data;
      return newState;
    }

    case "UPDATE_CHANNEL_LIST": {
      const newState = { ...state };
      newState.channelByBroadcastingCodes = action.data;
      return newState;
    }

    default:
      return state;
  }
};

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialModalState);
  const modalValue = { state, dispatch };
  return (
    <React.Fragment>
      <ModalContext.Provider value={modalValue}>
        {children}
      </ModalContext.Provider>
    </React.Fragment>
  );
};

export default ModalContext;
