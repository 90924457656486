import React, { useEffect, useState } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";

const P05 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { titleText, descriptionLabel, isHidden } = properData;

  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    setHidden(isHidden);
  }, [isHidden]);

  // @View
  return (
    <React.Fragment>
      {!hidden && (
        <div className={`input-form P-05`}>
          <dl className="receipt">
            <dt>
              <Text field={fillBlank(titleText)} />
            </dt>
            <dd>
              <Text field={fillBlank(descriptionLabel)} />
            </dd>
          </dl>
        </div>
      )}
    </React.Fragment>
  );
};

export default P05;
