import React, { useEffect, useState } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { getScDataFromComponent, getScDataFromPlaceholder } from "../../utils/commonUtils";
import {
  STRING_CURRENT_SECTION_SELECT_PLAN,
  STRING_CURRENT_SECTION_SELECT_PRODUCT,
  STRING_CURRENT_SECTION_SELECT_BENEFIT,
  STRING_CURRENT_SECTION_SELECT_PLAN_ATTRIBUTES,
  STRING_CATEGORY_TRANSFER,
  STRING_CATEGORY_PRODUCT_CHANGE,
} from "../../utils/constantVariables";
import { getSessionStorageItem, setSessionStorageItem } from "../../utils/useSessionStorage";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";

const BM05_SECTION_1 = "商品選択へ進む";
const BM05_SECTION_2_1 = "商品を決定して見積もりに反映";
const BM05_SECTION_2_2 = "プラン特典選択へ進む";
const BM05_SECTION_3 = "お申込みプラン属性へ進む";

export const PRODUCTSELECT_AUTOSELECTPRODUCT = "auto-select-product";
export const PRODUCTSELECT_NEXT = "next";

const SelectPlanProductLayout = (props) => {
  const orderRelatedInfo = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);
  const orderCategoryCurrentlySelected =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition?.orderCategoryCurrentlySelected;

  const [isPlanSelectionCompleted, setIsPlanSelectionCompleted] = useState(true);
  const [isProductSelectionCompleted, setIsProductSelectionCompleted] = useState(true);
  const [isPlanBenefitCompleted, setIsPlanBenefitCompleted] = useState(true);
  const [isPlanAttributeCompleted, setIsPlanAttributeCompleted] = useState(false);
  const [currentSection, setCurrentSection] = useState(STRING_CURRENT_SECTION_SELECT_PLAN_ATTRIBUTES);

  const [selectProductSectionState, setSelectProductSectionState] = useState(null);
  const [additionalClass, setAdditionalClass] = useState({ value: "disabled" });
  const [isPackageChange, setIsPackageChange] = useState(false);

  const setBM05Data = (additionalClassParam) => {
    setAdditionalClass({ value: additionalClassParam });
  };

  const getNextButtonText = () => {
    if (currentSection === STRING_CURRENT_SECTION_SELECT_PLAN) {
      return BM05_SECTION_1;
    } else if (currentSection === STRING_CURRENT_SECTION_SELECT_PRODUCT) {
      switch (selectProductSectionState) {
        case null:
          return BM05_SECTION_2_1;
        case PRODUCTSELECT_AUTOSELECTPRODUCT:
          return BM05_SECTION_2_2;
        case PRODUCTSELECT_NEXT:
          return BM05_SECTION_3;
      }
    }
    return BM05_SECTION_1;
  };

  const [bm05Data] = getScDataFromComponent("BM-05", getScDataFromPlaceholder("plan-selection-one-column", props));
  bm05Data.fields.buttonLabel = getNextButtonText();
  bm05Data.fields.additionalClass = additionalClass;
  bm05Data.fields.onClickEvent = () => {
    if (currentSection === STRING_CURRENT_SECTION_SELECT_PLAN) {
      setIsPackageChange(false);
      setIsPlanSelectionCompleted(true);
      setCurrentSection(STRING_CURRENT_SECTION_SELECT_PRODUCT);

      if (setIsPackageChange) {
        const orderRelatedInfo = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);
        const beforeChange = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO, true);

        orderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos =
          beforeChange.orderRelatedInfo.orderInfo.orderItemInfos;
        setSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO, orderRelatedInfo, true);
      }
      return true;
    } else if (currentSection === STRING_CURRENT_SECTION_SELECT_PRODUCT) {
      switch (selectProductSectionState) {
        case null:
          setSelectProductSectionState(PRODUCTSELECT_AUTOSELECTPRODUCT);
          return true;
        case PRODUCTSELECT_AUTOSELECTPRODUCT:
          setIsProductSelectionCompleted(true);
          setSelectProductSectionState(PRODUCTSELECT_NEXT);
          setCurrentSection(STRING_CURRENT_SECTION_SELECT_BENEFIT);
          return true;
      }
    } else if (currentSection === STRING_CURRENT_SECTION_SELECT_BENEFIT) {
      setIsPlanBenefitCompleted(true);
      setCurrentSection(STRING_CURRENT_SECTION_SELECT_PLAN_ATTRIBUTES);
    }

    return false;
  };

  useEffect(() => {
    if (
      orderCategoryCurrentlySelected === STRING_CATEGORY_TRANSFER ||
      orderCategoryCurrentlySelected === STRING_CATEGORY_PRODUCT_CHANGE
    ) {
      setIsPlanSelectionCompleted(true);
      setCurrentSection(STRING_CURRENT_SECTION_SELECT_PRODUCT);
    }
  }, []);

  return (
    <React.Fragment>
      <main>
        <Placeholder name="plan-selection-one-column" rendering={props.rendering} />
        <div className="form_container">
          <Placeholder
            name="plan-selection-left-step1"
            rendering={props.rendering}
            setIsLoading={props.setIsLoading}
            currentSection={currentSection}
            isPlanSelectionCompleted={isPlanSelectionCompleted}
            isProductSelectionCompleted={isProductSelectionCompleted}
            isPlanBenefitCompleted={isPlanBenefitCompleted}
            isPlanAttributeCompleted={isPlanAttributeCompleted}
            selectProductSectionState={selectProductSectionState}
            setBM05Data={setBM05Data}
            setCurrentSection={setCurrentSection}
            setIsPlanSelectionCompleted={setIsPlanSelectionCompleted}
            setIsProductSelectionCompleted={setIsProductSelectionCompleted}
            setIsPlanBenefitCompleted={setIsPlanBenefitCompleted}
            setIsPlanAttributeCompleted={setIsPlanAttributeCompleted}
            setSelectProductSectionState={setSelectProductSectionState}
            setIsPackageChange={setIsPackageChange}
          />
          <Placeholder name="plan-selection-right" rendering={props.rendering} />
        </div>
      </main>
      <Placeholder name="plan-selection-footer" rendering={props.rendering} />
    </React.Fragment>
  );
};

export default SelectPlanProductLayout;
