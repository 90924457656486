import gql from "graphql-tag";

export const query = gql`
  query {
    AgreementContent: item(
      path: "/sitecore/content/jss/masterjss/Data/Agreement Content"
    ) {
      fields(excludeStandardFields: true) {
        name
        value
      }
      children {
        fields(excludeStandardFields: true) {
          name
          value
        }
      }
    }
  }
`;
