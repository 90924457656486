import React, { useEffect } from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { di_register, di_fetch } from "../../utils/di";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
} from "../../utils/commonUtils";
import { useGetDataViaGraphQL } from "../../hooks/useGetData";
import { query } from "../../assets/graphql/MemberTypeMaster";
import { useSetSelectedItem } from "../../hooks/useSelectedState";
import { STRING_LOADING } from "../../utils/constantVariables";

const mapNextButtonLinkURL = (props, selected) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const pageFields = sitecoreContext?.route?.fields;

  let link = null;
  const btnSection = getScDataFromPlaceholder("next-button-section", props);
  const [nextButton] = getScDataFromComponent("B-01", btnSection);

  if (selected === "1") {
    link = pageFields?.["Member-NonMemberSelection-NextButtonLinkForMember"];
  } else if (selected === "2") {
    link = pageFields?.["Member-NonMemberSelection-NextButtonLinkForNonMember"];
  }

  nextButton.fields["linkURL"] = link;
};

const mapRadioOptions = (props, category, graphQlData) => {
  const appSection = getScDataFromPlaceholder("new-application-section", props);
  const [S01Data] = getScDataFromComponent("S-01", appSection);

  if (graphQlData) {
    const data = graphQlData?.MemberTypeMaster?.children.map((item) => {
      if (item.fields) {
        let select = item.fields.reduce((acc, field) => {
          acc[field.name] = field.value;
          return acc;
        }, {});
        return select;
      }

      return item;
    });

    S01Data.fields["category"] = { value: category };
    S01Data.fields["selections"] = data;
  }
};

const MemberNonMemberSelection = (props) => {
  const category = "member-type";
  const graphQlData = props.graphQlData;
  const [selectedItem, setSelectedItem] = useSetSelectedItem(category);
  
  // receive master data
  useEffect(() => {
    mapRadioOptions(props, category, graphQlData);

    // Initial values
    setSelectedItem({ [category]: "1" });
  }, [graphQlData]);

  mapNextButtonLinkURL(props, selectedItem.selected);

  return (
    <>
      <div className="form_wrapper-input">
        <Placeholder
          name="new-application-section"
          rendering={props.rendering}
        />
      </div>

      <div className="btn_container">
        <Placeholder name="next-button-section" rendering={props.rendering} />
      </div>
    </>
  );
};

const MemberNonMemberLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  const graphQlData = useGetDataViaGraphQL(query);

  const isLoading = graphQlData === STRING_LOADING;
  props.setIsLoading(isLoading)

  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <MemberNonMemberSelection
                rendering={props.rendering}
                graphQlData={graphQlData}
              />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(MemberNonMemberLayout);
