export const benefitCampaignData = {
  // 特典キャンペーン情報リスト
  benefitCampaignInfos: [
    {
      // 特典キャンペーン
      benefitCampaign: {
        // 特典キャンペーンID
        Id: "CP01",
        // 特典キャンペーン名
        CMAP_BenefitCampaignName__c: "オプション加入キャンペーン",
        CMAP_BenefitCampaignImageURL__c: "XXXXX",
        CMAP_BenefitCampaignAltMessage__c: "XXXXX",
        // キャンペーン開始日時
        CMAP_BenefitCampaignFrom__c: "2023-01-01 00:00:00",
        // キャンペーン終了日時
        CMAP_BenefitCampaignTo__c: "2024-12-31 00:00:00",
        // キャンペーン附則
        CMAP_BenefitCampaignAdditionalRule__c: "「光乗りかえキャンペーン」の特典還元に加え…",
        // 再適用可能
        CMAP_Reapply__c: false,
      },
      // 適用除外キャンペーンIDリスト
      excludedCampaignIds: ["hoge01", "hoge02"],
      // 特典グループ番号情報リスト
      benefitGroupNumberInfos: [
        {
          // 特典グループ番号
          benefitGroupNumber: 1,
          // 特典情報リスト
          benefitInfos: [
            {
              // 特典
              benefit: {
                // 特典ID
                Id: "CP1_1",
                // 特典名
                CMAP_BenefitName__c: "Pontaポイント還元+3,000円相当分",
                // 特典タイプ
                CMAP_BenefitType__c: "CashGrant",
                // 特典内容
                CMAP_BenefitContent__c: "Pontaポイントの3,000円相当分を還元します。",
                // 画像URL
                CMAP_BenefitImageURL__c: "https://images.ctfassets.net/4cd45et68cgf/7LrExJ6PAj6MSIPkDyCO86/542b1dfabbf3959908f69be546879952/Netflix-Brand-Logo.png?w=700&h=456",
                // Altメッセージ
                CMAP_AltMessage__c: "Netflix",
                // デフォルト選択
                CMAP_DefaultSelection__c: false,
                // 自動適用
                CMAP_AutoApplication__c: false,
                // 注意事項
                CMAP_Precaution__c: "注意事項",
              },
              // 特典商品情報リスト
              benefitProductList: [
                {
                  // 特典商品
                  benefitProduct: {
                    // 特典商品ID
                    Id: "CP1_1_B1",
                    // 還元期間月数
                    CMAP_NumberOfMonthsReturnPeriod__c: 1.5,
                    // 供与単価
                    CMAP_GrantUnitPrice__c: 3000,
                    // 供与ポイント
                    CMAP_GrantPoint__c: 200,
                    // 特典キャンペーン並び順
                    CMAP_BenefitCampaignSortOrder__c: 1.1,
                    // 特典並び順
                    CMAP_BenefitSortOrder__c: 2.1,
                  },
                  // 商品
                  product: {
                    // 商品ID
                    Id: "CP1_1_P1",
                    // 商品名
                    Name: "商品A",
                    // 商品区分
                    CMAP_ItemCategory__c: "SingleProduct",
                    // 課税区分
                    CMAP_TaxationCategory__c: "ReducedTaxRate",
                    CMAP_IsUnchargeable__c: false,
                    // 並び順
                    CMAP_SortOrder__c: 3.1,
                  },
                  // 価格表エントリ
                  pricebookEntry: {
                    // 価格区分
                    CMAP_PriceCategory__c: "AnnualPayment",
                    // リスト価格
                    UnitPrice: 38273,
                    // リスト価格（税込）
                    CMAP_UnitPriceTaxIncluded__c: 55433,
                    // 消費税率
                    CMAP_ConsumptionTaxRate__c: 10.5,
                  },
                },
                {
                  // 特典商品
                  benefitProduct: {
                    // 特典商品ID
                    Id: "CP1_1_B1",
                    // 還元期間月数
                    CMAP_NumberOfMonthsReturnPeriod__c: 1.5,
                    // 供与単価
                    CMAP_GrantUnitPrice__c: 3000,
                    // 供与ポイント
                    CMAP_GrantPoint__c: 200,
                    // 特典キャンペーン並び順
                    CMAP_BenefitCampaignSortOrder__c: 1.1,
                    // 特典並び順
                    CMAP_BenefitSortOrder__c: 2.1,
                  },
                  // 商品
                  product: {
                    // 商品ID
                    Id: "CP1_1_P1",
                    // 商品名
                    Name: "商品A",
                    // 商品区分
                    CMAP_ItemCategory__c: "SingleProduct",
                    // 課税区分
                    CMAP_TaxationCategory__c: "ReducedTaxRate",
                    CMAP_IsUnchargeable__c: false,
                    // 並び順
                    CMAP_SortOrder__c: 3.1,
                  },
                  // 価格表エントリ
                  pricebookEntry: {
                    // 価格区分
                    CMAP_PriceCategory__c: "AnnualPayment",
                    // リスト価格
                    UnitPrice: 38273,
                    // リスト価格（税込）
                    CMAP_UnitPriceTaxIncluded__c: 55433,
                    // 消費税率
                    CMAP_ConsumptionTaxRate__c: 10.5,
                  },
                }
              ],
            },
            
            
            {
              // 特典
              benefit: {
                // 特典ID
                Id: "CP1_2",
                // 特典名
                CMAP_BenefitName__c: "現金キャッシュバック+3,000円",
                // 特典タイプ
                CMAP_BenefitType__c: "CashGrant",
                // 特典内容
                CMAP_BenefitContent__c: "現金の3,000円をキャッシュバックします。。",
                // 画像URL
                CMAP_BenefitImageURL__c: "https://images.ctfassets.net/4cd45et68cgf/7LrExJ6PAj6MSIPkDyCO86/542b1dfabbf3959908f69be546879952/Netflix-Brand-Logo.png?w=700&h=456",
                // Altメッセージ
                CMAP_AltMessage__c: "Netflix",
                // デフォルト選択
                CMAP_DefaultSelection__c: false,
                // 自動適用
                CMAP_AutoApplication__c: false,
                // 注意事項
                CMAP_Precaution__c: "注意事項",
              },
              // 特典商品情報リスト
              benefitProductList: [
                {
                  // 特典商品
                  benefitProduct: {
                    // 特典商品ID
                    Id: "CP1_2_B1",
                    // 還元期間月数
                    CMAP_NumberOfMonthsReturnPeriod__c: 1.5,
                    // 供与単価
                    CMAP_GrantUnitPrice__c: 3000,
                    // 供与ポイント
                    CMAP_GrantPoint__c: 200,
                    // 特典キャンペーン並び順
                    CMAP_BenefitCampaignSortOrder__c: 1.1,
                    // 特典並び順
                    CMAP_BenefitSortOrder__c: 2.1,
                  },
                  // 商品
                  product: {
                    // 商品ID
                    Id: "CP1_2_P1",
                    // 商品名
                    Name: "商品B",
                    // 商品区分
                    CMAP_ItemCategory__c: "SingleProduct",
                    // 課税区分
                    CMAP_TaxationCategory__c: "ReducedTaxRate",
                    CMAP_IsUnchargeable__c: false,
                    // 並び順
                    CMAP_SortOrder__c: 3.1,
                  },
                  // 価格表エントリ
                  pricebookEntry: {
                    // 価格区分
                    CMAP_PriceCategory__c: "AnnualPayment",
                    // リスト価格
                    UnitPrice: 38273,
                    // リスト価格（税込）
                    CMAP_UnitPriceTaxIncluded__c: 55433,
                    // 消費税率
                    CMAP_ConsumptionTaxRate__c: 10.5,
                  },
                }
              ],
            },

            
            {
              // 特典
              benefit: {
                // 特典ID
                Id: "CP1_3",
                // 特典名
                CMAP_BenefitName__c: "コミュファ光4K-LINKプレゼント",
                // 特典タイプ
                CMAP_BenefitType__c: "CashGrant",
                // 特典内容
                CMAP_BenefitContent__c: "コミュファ光4K-LINKをプレゼントします。",
                // 画像URL
                CMAP_BenefitImageURL__c: "https://images.ctfassets.net/4cd45et68cgf/7LrExJ6PAj6MSIPkDyCO86/542b1dfabbf3959908f69be546879952/Netflix-Brand-Logo.png?w=700&h=456",
                // Altメッセージ
                CMAP_AltMessage__c: "Netflix",
                // デフォルト選択
                CMAP_DefaultSelection__c: false,
                // 自動適用
                CMAP_AutoApplication__c: false,
                // 注意事項
                CMAP_Precaution__c: "注意事項",
              },
              // 特典商品情報リスト
              benefitProductList: [
                {
                  // 特典商品
                  benefitProduct: {
                    // 特典商品ID
                    Id: "CP1_3_B1",
                    // 還元期間月数
                    CMAP_NumberOfMonthsReturnPeriod__c: 1.5,
                    // 供与単価
                    CMAP_GrantUnitPrice__c: 3000,
                    // 供与ポイント
                    CMAP_GrantPoint__c: 200,
                    // 特典キャンペーン並び順
                    CMAP_BenefitCampaignSortOrder__c: 1.1,
                    // 特典並び順
                    CMAP_BenefitSortOrder__c: 2.1,
                  },
                  // 商品
                  product: {
                    // 商品ID
                    Id: "CP1_3_P1",
                    // 商品名
                    Name: "商品B",
                    // 商品区分
                    CMAP_ItemCategory__c: "SingleProduct",
                    // 課税区分
                    CMAP_TaxationCategory__c: "ReducedTaxRate",
                    CMAP_IsUnchargeable__c: false,
                    // 並び順
                    CMAP_SortOrder__c: 3.1,
                  },
                  // 価格表エントリ
                  pricebookEntry: {
                    // 価格区分
                    CMAP_PriceCategory__c: "AnnualPayment",
                    // リスト価格
                    UnitPrice: 38273,
                    // リスト価格（税込）
                    CMAP_UnitPriceTaxIncluded__c: 55433,
                    // 消費税率
                    CMAP_ConsumptionTaxRate__c: 10.5,
                  },
                }
              ],
            },
          ],
        },
        
      ],
    },
    {
      // 特典キャンペーン
      benefitCampaign: {
        // 特典キャンペーンID
        Id: "CP02",
        // 特典キャンペーン名
        CMAP_BenefitCampaignName__c: "オプション加入キャンペーン",
        CMAP_BenefitCampaignImageURL__c: "XXXXX",
        CMAP_BenefitCampaignAltMessage__c: "XXXXX",
        // キャンペーン開始日時
        CMAP_BenefitCampaignFrom__c: "2023-01-01 00:00:00",
        // キャンペーン終了日時
        CMAP_BenefitCampaignTo__c: "2024-12-31 00:00:00",
        // キャンペーン附則
        CMAP_BenefitCampaignAdditionalRule__c: "「光乗りかえキャンペーン」の特典還元に加え…",
        // 再適用可能
        CMAP_Reapply__c: false,
      },
      // 適用除外キャンペーンIDリスト
      excludedCampaignIds: ["hoge01", "hoge02"],
      // 特典グループ番号情報リスト
      benefitGroupNumberInfos: [
        {
          // 特典グループ番号
          benefitGroupNumber: 1,
          // 特典情報リスト
          benefitInfos: [
            {
              // 特典
              benefit: {
                // 特典ID
                Id: "CP1_1",
                // 特典名
                CMAP_BenefitName__c: "Pontaポイント還元+3,000円相当分",
                // 特典タイプ
                CMAP_BenefitType__c: "CashGrant",
                // 特典内容
                CMAP_BenefitContent__c: "Pontaポイントの3,000円相当分を還元します。",
                // 画像URL
                CMAP_BenefitImageURL__c: "https://images.ctfassets.net/4cd45et68cgf/7LrExJ6PAj6MSIPkDyCO86/542b1dfabbf3959908f69be546879952/Netflix-Brand-Logo.png?w=700&h=456",
                // Altメッセージ
                CMAP_AltMessage__c: "Netflix",
                // デフォルト選択
                CMAP_DefaultSelection__c: false,
                // 自動適用
                CMAP_AutoApplication__c: false,
                // 注意事項
                CMAP_Precaution__c: "注意事項",
              },
              // 特典商品情報リスト
              benefitProductList: [
                {
                  // 特典商品
                  benefitProduct: {
                    // 特典商品ID
                    Id: "CP1_1_B1",
                    // 還元期間月数
                    CMAP_NumberOfMonthsReturnPeriod__c: 1.5,
                    // 供与単価
                    CMAP_GrantUnitPrice__c: 3000,
                    // 供与ポイント
                    CMAP_GrantPoint__c: 200,
                    // 特典キャンペーン並び順
                    CMAP_BenefitCampaignSortOrder__c: 1.1,
                    // 特典並び順
                    CMAP_BenefitSortOrder__c: 2.1,
                  },
                  // 商品
                  product: {
                    // 商品ID
                    Id: "CP1_1_P1",
                    // 商品名
                    Name: "商品A",
                    // 商品区分
                    CMAP_ItemCategory__c: "SingleProduct",
                    // 課税区分
                    CMAP_TaxationCategory__c: "ReducedTaxRate",
                    CMAP_IsUnchargeable__c: false,
                    // 並び順
                    CMAP_SortOrder__c: 3.1,
                  },
                  // 価格表エントリ
                  pricebookEntry: {
                    // 価格区分
                    CMAP_PriceCategory__c: "AnnualPayment",
                    // リスト価格
                    UnitPrice: 38273,
                    // リスト価格（税込）
                    CMAP_UnitPriceTaxIncluded__c: 55433,
                    // 消費税率
                    CMAP_ConsumptionTaxRate__c: 10.5,
                  },
                },
                {
                  // 特典商品
                  benefitProduct: {
                    // 特典商品ID
                    Id: "CP1_1_B1",
                    // 還元期間月数
                    CMAP_NumberOfMonthsReturnPeriod__c: 1.5,
                    // 供与単価
                    CMAP_GrantUnitPrice__c: 3000,
                    // 供与ポイント
                    CMAP_GrantPoint__c: 200,
                    // 特典キャンペーン並び順
                    CMAP_BenefitCampaignSortOrder__c: 1.1,
                    // 特典並び順
                    CMAP_BenefitSortOrder__c: 2.1,
                  },
                  // 商品
                  product: {
                    // 商品ID
                    Id: "CP1_1_P1",
                    // 商品名
                    Name: "商品A",
                    // 商品区分
                    CMAP_ItemCategory__c: "SingleProduct",
                    // 課税区分
                    CMAP_TaxationCategory__c: "ReducedTaxRate",
                    CMAP_IsUnchargeable__c: false,
                    // 並び順
                    CMAP_SortOrder__c: 3.1,
                  },
                  // 価格表エントリ
                  pricebookEntry: {
                    // 価格区分
                    CMAP_PriceCategory__c: "AnnualPayment",
                    // リスト価格
                    UnitPrice: 38273,
                    // リスト価格（税込）
                    CMAP_UnitPriceTaxIncluded__c: 55433,
                    // 消費税率
                    CMAP_ConsumptionTaxRate__c: 10.5,
                  },
                }
              ],
            },
            
            
            {
              // 特典
              benefit: {
                // 特典ID
                Id: "CP1_2",
                // 特典名
                CMAP_BenefitName__c: "現金キャッシュバック+3,000円",
                // 特典タイプ
                CMAP_BenefitType__c: "CashGrant",
                // 特典内容
                CMAP_BenefitContent__c: "現金の3,000円をキャッシュバックします。。",
                // 画像URL
                CMAP_BenefitImageURL__c: "https://images.ctfassets.net/4cd45et68cgf/7LrExJ6PAj6MSIPkDyCO86/542b1dfabbf3959908f69be546879952/Netflix-Brand-Logo.png?w=700&h=456",
                // Altメッセージ
                CMAP_AltMessage__c: "Netflix",
                // デフォルト選択
                CMAP_DefaultSelection__c: false,
                // 自動適用
                CMAP_AutoApplication__c: false,
                // 注意事項
                CMAP_Precaution__c: "注意事項",
              },
              // 特典商品情報リスト
              benefitProductList: [
                {
                  // 特典商品
                  benefitProduct: {
                    // 特典商品ID
                    Id: "CP1_2_B1",
                    // 還元期間月数
                    CMAP_NumberOfMonthsReturnPeriod__c: 1.5,
                    // 供与単価
                    CMAP_GrantUnitPrice__c: 3000,
                    // 供与ポイント
                    CMAP_GrantPoint__c: 200,
                    // 特典キャンペーン並び順
                    CMAP_BenefitCampaignSortOrder__c: 1.1,
                    // 特典並び順
                    CMAP_BenefitSortOrder__c: 2.1,
                  },
                  // 商品
                  product: {
                    // 商品ID
                    Id: "CP1_2_P1",
                    // 商品名
                    Name: "商品B",
                    // 商品区分
                    CMAP_ItemCategory__c: "SingleProduct",
                    // 課税区分
                    CMAP_TaxationCategory__c: "ReducedTaxRate",
                    CMAP_IsUnchargeable__c: false,
                    // 並び順
                    CMAP_SortOrder__c: 3.1,
                  },
                  // 価格表エントリ
                  pricebookEntry: {
                    // 価格区分
                    CMAP_PriceCategory__c: "AnnualPayment",
                    // リスト価格
                    UnitPrice: 38273,
                    // リスト価格（税込）
                    CMAP_UnitPriceTaxIncluded__c: 55433,
                    // 消費税率
                    CMAP_ConsumptionTaxRate__c: 10.5,
                  },
                }
              ],
            },

            
            {
              // 特典
              benefit: {
                // 特典ID
                Id: "CP1_3",
                // 特典名
                CMAP_BenefitName__c: "コミュファ光4K-LINKプレゼント",
                // 特典タイプ
                CMAP_BenefitType__c: "CashGrant",
                // 特典内容
                CMAP_BenefitContent__c: "コミュファ光4K-LINKをプレゼントします。",
                // 画像URL
                CMAP_BenefitImageURL__c: "https://images.ctfassets.net/4cd45et68cgf/7LrExJ6PAj6MSIPkDyCO86/542b1dfabbf3959908f69be546879952/Netflix-Brand-Logo.png?w=700&h=456",
                // Altメッセージ
                CMAP_AltMessage__c: "Netflix",
                // デフォルト選択
                CMAP_DefaultSelection__c: false,
                // 自動適用
                CMAP_AutoApplication__c: false,
                // 注意事項
                CMAP_Precaution__c: "注意事項",
              },
              // 特典商品情報リスト
              benefitProductList: [
                {
                  // 特典商品
                  benefitProduct: {
                    // 特典商品ID
                    Id: "CP1_3_B1",
                    // 還元期間月数
                    CMAP_NumberOfMonthsReturnPeriod__c: 1.5,
                    // 供与単価
                    CMAP_GrantUnitPrice__c: 3000,
                    // 供与ポイント
                    CMAP_GrantPoint__c: 200,
                    // 特典キャンペーン並び順
                    CMAP_BenefitCampaignSortOrder__c: 1.1,
                    // 特典並び順
                    CMAP_BenefitSortOrder__c: 2.1,
                  },
                  // 商品
                  product: {
                    // 商品ID
                    Id: "CP1_3_P1",
                    // 商品名
                    Name: "商品B",
                    // 商品区分
                    CMAP_ItemCategory__c: "SingleProduct",
                    // 課税区分
                    CMAP_TaxationCategory__c: "ReducedTaxRate",
                    CMAP_IsUnchargeable__c: false,
                    // 並び順
                    CMAP_SortOrder__c: 3.1,
                  },
                  // 価格表エントリ
                  pricebookEntry: {
                    // 価格区分
                    CMAP_PriceCategory__c: "AnnualPayment",
                    // リスト価格
                    UnitPrice: 38273,
                    // リスト価格（税込）
                    CMAP_UnitPriceTaxIncluded__c: 55433,
                    // 消費税率
                    CMAP_ConsumptionTaxRate__c: 10.5,
                  },
                }
              ],
            },
          ],
        },
        
      ],
    }
    
  ],
  processResult: {
    returnCode: "Success",
    resultInfos: [
      {
        resultCategory: "Error",
        resultCode: "XXXXX",
        resultMessage: "XXXXX",
      },
    ],
  },
};