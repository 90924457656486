import React, { useEffect, useState } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { sitecoreApiHost } from "../../temp/config";
import parse from "html-react-parser";
import { useGetDataViaGraphQL as UseGetDataViaGraphQL } from "../../hooks/useGetData";
import { query as queryApplicationInfoList } from "../../assets/graphql/CommonApplicationInformationList";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { getSessionStorageItem } from "../../utils/useSessionStorage";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  getUrlInfo,
} from "../../utils/commonUtils";
import {
  STRING_APPINFO_APARTMENTAVAILABLE,
  STRING_APPINFO_APARTMENTSEARCH,
  STRING_APPINFO_APARTMENTUNAVAILABLE,
  STRING_APPINFO_HOMEAVAILABLE,
  STRING_APPINFO_HOMEUNAVAILABLE,
  STRING_APPINFO_PASCO,
  STRING_APPINFO_PLANTYPE,
  STRING_APPINFO_SERVICEAVAILABLE,
  STRING_APPINFO_SERVICEUNAVAILABLE,
  STRING_LOADING,
} from "../../utils/constantVariables";

const orderRelatedInfo = getSessionStorageItem(
  sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
  true
);

const isApartmentHome = getSessionStorageItem(
  sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_ISAPARTMENTHOME,
  true
);

// data mapping
const ApplicationInformationListDataMapping = (data, code, compData) => {
  data = {
    targetItems: data?.map((item) => {
      return item.fields?.reduce((a, field) => {
        if (field.name === "ApplicationInformation-Icon")
          a[field.name] = sitecoreApiHost + parse(field?.editable).props?.src;
        else if (field.name === "ApplicationInformation-Description")
          a[field.name] = field?.editable;
        else if (field.name === "ApplicationInformation-ButtonText")
          a[field.name] = field?.editable;
        else if (field.name === "ApplicationInformation-LinkUrl")
          a[field.name] = parse(field?.editable).props?.href;
        else a[field.name] = field?.editable;
        return a;
      }, {});
    }),
  };

  const [getRequest, setGetRequest] = useState("");

  useEffect(() => {
    const urlInfo = getUrlInfo();
    const hostName = urlInfo?.hostname;
    const directoryName = urlInfo?.pathnames[1];
    const pascoReqData = {
      i_area: "commufa",
      i_iro: "",
      i_citycode: code,
      i_lat_j: "",
      i_lon_j: "",
      i_callsite:
        hostName === "c-pad" ? `a_${directoryName}` : `c_${directoryName}`,
    };
    setGetRequest(
      `?i_area=${pascoReqData.i_area}&i_iro=${pascoReqData.i_iro}&i_citycode=${pascoReqData.i_citycode}&i_lat_j=${pascoReqData.i_lat_j}&i_lon_j=${pascoReqData.i_lon_j}&i_callsite=${pascoReqData.i_callsite}`
    );
  }, [getRequest]);

  compData = data.targetItems?.map((targetItem) => ({
    ApplicationInformationIcon: {
      value: {
        src: targetItem["ApplicationInformation-Icon"],
      },
    },
    ApplicationInformationDescription: {
      value: targetItem["ApplicationInformation-Description"],
    },
    ApplicationInformationButtonText: {
      value: targetItem["ApplicationInformation-ButtonText"],
    },
    ApplicationInformationLinkUrl: {
      value: {
        href: targetItem["ApplicationInformation-LinkUrl"]
          ?.toLowerCase()
          ?.includes(STRING_APPINFO_PASCO)
          ? `${targetItem["ApplicationInformation-LinkUrl"]}${getRequest}`
          : targetItem["ApplicationInformation-LinkUrl"],
      },
    },
  }));

  return compData;
};

export const ApplicationInformationList = (props) => {
  const { screen: screen } = props;
  // GraphQL data for Application Information List
  const applicationInformationListQuery = UseGetDataViaGraphQL(
    queryApplicationInfoList,
    null,
    "ApplicationInformationList-Query"
  );

  let applicationInformationListData = [];
  let isServiceAvailable = false;

  switch (screen) {
    case STRING_APPINFO_PLANTYPE:
      applicationInformationListData =
        applicationInformationListQuery?.SelectPlanType?.fields[0]?.targetItems;
      isServiceAvailable = false;
      break;
    case STRING_APPINFO_APARTMENTSEARCH:
      applicationInformationListData =
        applicationInformationListQuery?.ApartmentSearch?.fields[0]
          ?.targetItems;
      isServiceAvailable = false;
      break;
    case STRING_APPINFO_APARTMENTAVAILABLE:
      applicationInformationListData =
        applicationInformationListQuery?.ServiceAvailableAreaForApartment
          ?.fields[0]?.targetItems;
      isServiceAvailable = true;
      break;
    case STRING_APPINFO_APARTMENTUNAVAILABLE:
      applicationInformationListData = isApartmentHome
        ? applicationInformationListQuery
            ?.ServiceUnavailableAreaForApartmentHome?.fields[0]?.targetItems
        : applicationInformationListQuery
            ?.ServiceUnavailableAreaForNotApartmentHome?.fields[0]?.targetItems;
      isServiceAvailable = false;
      break;
    case STRING_APPINFO_HOMEAVAILABLE:
      applicationInformationListData =
        applicationInformationListQuery?.ServiceAvailableAreaForHome?.fields[0]
          ?.targetItems;
      isServiceAvailable = true;
      break;
    case STRING_APPINFO_HOMEUNAVAILABLE:
      applicationInformationListData =
        applicationInformationListQuery?.ServiceUnavailableAreaForHome
          ?.fields[0]?.targetItems;
      isServiceAvailable = false;
      break;
  }

  let appInfoListSection = getScDataFromPlaceholder(
    isServiceAvailable
      ? STRING_APPINFO_SERVICEAVAILABLE
      : STRING_APPINFO_SERVICEUNAVAILABLE,
    props
  );
  let [appInfoListData] = getScDataFromComponent(
    "ApplicationInformation",
    appInfoListSection
  );

  const installationLocCity =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
      ?.CMAP_InstallationLocationCity__c;

  // button class
  appInfoListData.fields.btnWrapperAdditionalClass.value = "secondary";

  appInfoListData.fields.ApplicationInformationListItemList = ApplicationInformationListDataMapping(
    applicationInformationListData,
    installationLocCity,
    appInfoListData.fields.ApplicationInformationListItemList
  );

  const isLoading = applicationInformationListQuery === STRING_LOADING;

  props.setIsLoading(isLoading);

  return (
    <>
      {isServiceAvailable ? (
        <Placeholder
          name={STRING_APPINFO_SERVICEAVAILABLE}
          rendering={props.rendering}
        />
      ) : (
        <Placeholder
          name={STRING_APPINFO_SERVICEUNAVAILABLE}
          rendering={props.rendering}
        />
      )}
    </>
  );
};
