import React, { useEffect, useState, useMemo } from "react";
import {
  Placeholder,
  Text,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { useFormContext } from "react-hook-form";
import { format } from "date-fns";
import { di_register } from "../../utils/di";
import {
  fillBlank,
  getScDataFromComponent,
  getScDataFromPlaceholder,
  redirectToLinkUrl,
  showPrintDialog,
  formatCurrencyChar
} from "../../utils/commonUtils";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import {
  DATE_LOCALE,
  STRING_DESELECTED,
  STRING_POST,
  STRING_STORE,
  STRING_SUSPENDSELECTED,
  STRING_USERAGENT_CPAD_APP,
} from "../../utils/constantVariables";
import { useCustomQuery } from "../../hooks/useGetData";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import { apiBaseURL } from "../../envSettings";

import { createChangeDetailsObj, createAutomaticSelectionProductObj, createMonthlyExpenseObj } from "../../services/calculations/C-Pad-Change-Content-Confirmation/calculateC-PadChangedContentConfirmationResult";
import { groupByGroupAndCat } from "../../services/calculations/Common/CommonProcess";

// TODO delete test data

const TextCellFormatter = ({ row, value }) => {
  return (
    <Text
      field={fillBlank(value)}
      encode={false}
      tag="p"
      className="txt_left full-width v-align-top"
    />
  );
};

const PriceCellFormatter = ({ value }) => {
  return (
    <Text
      field={fillBlank(value)}
      encode={false}
      tag="p"
      className="txt_right full-width"
    />
  );
};

const buildChangeDetailsRowData = (groupedChangeDetailsObj) => {
  const rowData = []
  for (const groupKey of Object.keys(groupedChangeDetailsObj)) {
    const group = groupedChangeDetailsObj[groupKey];
    let groupLength =  0;
    for (const catKey of Object.keys(group)) {
      const cat = group[catKey];
      groupLength = groupLength + cat.length;
    }
    let isNewGroup = true;

    for (const catKey of Object.keys(group)) {
      const cat = group[catKey];
      const catLength =  Object.keys(cat).length;
      let isNewCat = true;

      cat.forEach((item) => {
        const grouped = []
        if (isNewGroup) {
          grouped.push({
            rowSpan: groupLength,
            value: item.CMAP_MainSubProductGroupName__c
          })
          isNewGroup = false;
        }

        if (isNewCat) {
          grouped.push({
            rowSpan: catLength,
            value: item.CMAP_ProductCategoryName__c
          })
          isNewCat = false;
        }

        rowData.push({
          grouped,
          BeforeCol: item.Before_CMAP_ProductName__c,
          AfterCol: item.After_CMAP_ProductName__c,
        })
      });
    }
  }

  return rowData;
}

const buildAutomaticSelectionRowData = (automaticSelectionProductObj, groupText) => {
  const automaticSelectionProductObjLength = automaticSelectionProductObj.length;

  let isNewGroup = true;
  const rowData = automaticSelectionProductObj.map((item) => {
    const grouped = []
    if (isNewGroup) {
      grouped.push({
        rowSpan: automaticSelectionProductObjLength,
        colSpan: automaticSelectionProductObjLength,
        value: groupText
      })
      isNewGroup = false;
    }

    return {
      grouped,
      BeforeCol: item.Before_CMAP_ProductName__c,
      AfterCol: item.After_CMAP_ProductName__c,
    }
  });

  return rowData;
}


const CPadChangeContentConfirmationPageLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  const { setError, clearErrors } = useFormContext();

  const {
    "ChangedContent-BeforeCol": beforeColText,
    "ChangedContent-AfterCol": afterColText,
    "ChangedContent-PlanRow": planRowText,
    "ChangedContent-AutomaticSelectionProductRow": automaticSelectionProductRowText,
    "ChangedContent-BasicPriceRow": basicPriceRow,
    "ChangedContent-DiscountRow": discountRow,
    "ChangedContent-OptionRow": optionRow,
    "ChangedContent-TotalPriceRow": totalPriceRow,
    "ChangedContentConfirmationTransitionButtonArea-NextButtonLink": nextButtonLink,
  } = props.sitecoreContext.route.fields;

  let orderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );
  let beforeChangeOrderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO,
    true
  );
  const { ProductSelectionList: productSelectionList } = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_SELECTPRODUCT_PRODUCTSELECTIONLIST,
    true
  );
  const { BenefitListForAgency: benifitListAgency } = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_SELECTPRODUCT_BENEFITLISTFORAGENCY,
    true
  );
  const personalizeInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );
  const selectedStoreID = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STORENAME,
    true
  );

  const [inputValues, setInputValues] = useState({
    agencyName: getSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ACCOUNTNAME,
      true
    ),
    storeName:
      personalizeInfo?.SalesChannelSelect === STRING_STORE
        ? selectedStoreID
        : "",
    descriptionDate: getSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_DESCRIPTIONDATE,
      true
    ),
    MICNo: getSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_MICNO,
      true
    ),
    estimateDueDate: getSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ESTIMATEDUEDATE,
      true
    ),
    staffName: getSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STAFFNAME,
      true
    ),
    phone: getSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_PHONE,
      true
    ),
  });
  const [feeSimulationResponses, setFeeSimulationResponses] = useState({
    beforeChangePriceSimulation: null,
    afterChangePriceSimulation: null,
  });

  const [feeSimulationRequest, setFeeSimulationRequest] = useState({
    isBeforeChangeAPI: true,
    isAfterChangeAPI: false,
    request: null,
  });
  const {
    data: feeSimulationData,
    isLoading: feeSimulationLoading,
    error: feeSimulationError,
    refetch: feeSimulationRefetch,
    remove: feeSimulationRemove,
  } = useCustomQuery(
    `${apiBaseURL}order/RetrieveFeeSimulation`,
    false,
    STRING_POST,
    feeSimulationRequest?.request
  );

  props.setIsLoading(feeSimulationLoading)

  const [changeDetailTable] = getScDataFromComponent(
    "TB-01",
    getScDataFromPlaceholder("change-details-section", props)
  );
  changeDetailTable.fields.columnData = [
    {
      key: "grouped",
      header: "",
      colSpan: 2,
      class: "no-border",
      width: "40%",
      Cell: TextCellFormatter,
    },
    {
      key: "BeforeCol",
      header: beforeColText,
      Cell: TextCellFormatter,
    },
    {
      key: "AfterCol",
      header: afterColText,
      Cell: TextCellFormatter,
    },
  ];

  // build first row data
  let changeDetailsAutomaticSelectionProductRowData = [
    {
      grouped: [{ colSpan: 2, value: planRowText }],
      BeforeCol:
      orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_ChangeSourceOfferPlanName__c,
      AfterCol: orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_OfferPlanName__c,
    }
  ];


  // build changeDetails row data
  // TODO: delete test data
  
  const changeDetailsRowData = useMemo(() => {
    const changeDetailsObj = createChangeDetailsObj();
    const groupedChangeDetailsObj = groupByGroupAndCat(changeDetailsObj);
    return buildChangeDetailsRowData(groupedChangeDetailsObj);
  }, []);

  changeDetailsAutomaticSelectionProductRowData = changeDetailsAutomaticSelectionProductRowData.concat(changeDetailsRowData);

  // build AutomaticSelection row data
  // TODO: delete test data

  const automaticSelectionRowData = useMemo(() => {
    const automaticSelectionProductObj = createAutomaticSelectionProductObj();
    return buildAutomaticSelectionRowData(automaticSelectionProductObj, automaticSelectionProductRowText);
  }, [automaticSelectionProductRowText]);

  changeDetailsAutomaticSelectionProductRowData = changeDetailsAutomaticSelectionProductRowData.concat(automaticSelectionRowData);

  changeDetailTable.fields.rowData = changeDetailsAutomaticSelectionProductRowData;

  const [monthlyFeeTable] = getScDataFromComponent(
    "TB-01",
    getScDataFromPlaceholder("monthly-fee-section", props)
  );
  monthlyFeeTable.fields.columnData = [
    {
      key: "title",
      header: "",
      class: "no-border",
      width: "50%",
      Cell: TextCellFormatter,
    },
    {
      key: "BeforeCol",
      header: beforeColText,
      Cell: PriceCellFormatter,
    },
    {
      key: "AfterCol",
      header: afterColText,
      Cell: PriceCellFormatter,
    },
  ];

  // TODO: delete test data

  let monthlyFeeDataObj = {};
  monthlyFeeDataObj = useMemo(() => {
    let monthlyExpenseObj = {};
    if (feeSimulationResponses.beforeChangePriceSimulation?.Result && feeSimulationResponses.afterChangePriceSimulation?.Result) {
      monthlyExpenseObj = createMonthlyExpenseObj(feeSimulationResponses.beforeChangePriceSimulation.orderRelatedInfo.orderInfo.orderItemInfos, feeSimulationResponses.afterChangePriceSimulation.orderRelatedInfo.orderInfo.orderItemInfos);
      
      // set the value to zero if the value is NaN or null
      for (let monthlyExpenseKey in monthlyExpenseObj) {
        if (!monthlyExpenseObj[monthlyExpenseKey] || isNaN(monthlyExpenseObj[monthlyExpenseKey])) {
          monthlyExpenseObj[monthlyExpenseKey] = 0;
        }
      }

      monthlyExpenseObj.BeforeTotal = monthlyExpenseObj.BeforeMonthlyBasic - monthlyExpenseObj.BeforeMonthlyDiscount + monthlyExpenseObj.BeforeMonthlyOption;
      monthlyExpenseObj.AfterTotal = monthlyExpenseObj.AfterMonthlyBasic - monthlyExpenseObj.AfterMonthlyDiscount + monthlyExpenseObj.AfterMonthlyOption; 
    }
    return monthlyExpenseObj;
  }, [feeSimulationResponses]);

  monthlyFeeTable.fields.rowData = [
    { title: basicPriceRow, BeforeCol: formatCurrencyChar(monthlyFeeDataObj?.BeforeMonthlyBasic), AfterCol: formatCurrencyChar(monthlyFeeDataObj?.AfterMonthlyBasic) },
    { title: discountRow, BeforeCol: formatCurrencyChar(monthlyFeeDataObj?.BeforeMonthlyDiscount), AfterCol: formatCurrencyChar(monthlyFeeDataObj?.AfterMonthlyDiscount) },
    { title: optionRow, BeforeCol: formatCurrencyChar(monthlyFeeDataObj?.BeforeMonthlyOption), AfterCol: formatCurrencyChar(monthlyFeeDataObj?.AfterMonthlyOption) },
    { title: totalPriceRow, BeforeCol: formatCurrencyChar(monthlyFeeDataObj?.BeforeTotal), AfterCol: formatCurrencyChar(monthlyFeeDataObj?.AfterTotal) }
  ];

  const handleInputChange = (evt, name = undefined) => {
    if (!name) name = evt.target.name;
    const { value } = evt.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  const agencySection = getScDataFromPlaceholder(
    "agency-info-inputs-section",
    props
  );
  const [
    agencyNameInput,
    storeNameInput,
    MICNoInput,
    staffNameInput,
    phoneInput,
  ] = getScDataFromComponent("I-01", agencySection);
  const [descriptionDateInput, estimateDueDateInput] = getScDataFromComponent(
    "M-01i",
    agencySection
  );
  agencyNameInput.fields.inputValue = {
    name: "agencyName",
    value: inputValues.agencyName,
  };
  agencyNameInput.fields.onChangeEvent = handleInputChange;

  storeNameInput.fields.inputValue = {
    name: "storeName",
    value: inputValues.storeName,
  };
  storeNameInput.fields.onChangeEvent = handleInputChange;
  storeNameInput.fields.isHidden = {
    value: personalizeInfo.SalesChannelSelect !== STRING_STORE,
  };

  descriptionDateInput.fields.initialValue = {
    value: inputValues.descriptionDate ? inputValues.descriptionDate : null,
  };
  descriptionDateInput.fields.onChangeEvent = (evt) => {
    setInputValues((prev) => ({
      ...prev,
      ["descriptionDate"]: format(evt, "yyyy-MM-dd", DATE_LOCALE),
    }));
  };

  MICNoInput.fields.inputValue = {
    name: "MICNo",
    value: inputValues.MICNo,
  };
  MICNoInput.fields.onChangeEvent = handleInputChange;

  estimateDueDateInput.fields.initialValue = {
    value: inputValues.estimateDueDate ? inputValues.estimateDueDate : null,
  };
  estimateDueDateInput.fields.onChangeEvent = (evt) => {
    setInputValues((prev) => ({
      ...prev,
      ["estimateDueDate"]: format(evt, "yyyy-MM-dd", DATE_LOCALE),
    }));
  };

  staffNameInput.fields.inputValue = {
    name: "staffName",
    value: inputValues.staffName,
  };
  staffNameInput.fields.onChangeEvent = handleInputChange;

  phoneInput.fields.inputValue = {
    name: "phone",
    value: inputValues.phone,
  };
  phoneInput.fields.onChangeEvent = handleInputChange;

  const setInputSessionStorage = () => {
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ACCOUNTNAME,
      inputValues.agencyName,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STORENAME,
      inputValues.storeName,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_DESCRIPTIONDATE,
      inputValues.descriptionDate,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_MICNO,
      inputValues.MICNo,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ESTIMATEDUEDATE,
      inputValues.estimateDueDate,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STAFFNAME,
      inputValues.staffName,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_PHONE,
      inputValues.phone,
      true
    );
  };

  const [printBtn] = getScDataFromComponent(
    "B-02i",
    getScDataFromPlaceholder("agency-info-buttons-section", props)
  );
  printBtn.fields.onClickEvent = () => {
    // TODO: Do the test on this part when we have the ctc devices.
    showPrintDialog();
  };

  const btnSection = getScDataFromPlaceholder("button-section", props);
  const [backButton] = getScDataFromComponent("B-02", btnSection);
  const [nextButton] = getScDataFromComponent("B-01", btnSection);

  backButton.fields.onClickEvent = () => {
    setInputSessionStorage();

    redirectToPreviousPage();
  };

  nextButton.fields.onClickEvent = () => {
    clearErrors();
    setInputSessionStorage();
    redirectToLinkUrl(nextButtonLink);
  };

  const createFeeSimulationRequest = (data, isBeforeChange, isAfterChange) => {
    const orderItemInfo = data.orderRelatedInfo.orderInfo?.orderItemInfos;
    const orderItemArray = orderItemInfo?.reduce((a, { orderItem: item }) => {
      if (
        item.CMAP_ProductSelectionState__c !== STRING_DESELECTED &&
        item.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED
      ) {
        const prodSelection = productSelectionList?.find(
          (sel) => sel.Product2Id === item.Product2Id
        );
        a.push({
          orderItem: {
            CMAP_MainSubProductGroupName__c:
              item.CMAP_MainSubProductGroupName__c,
            Product2Id: item.Product2Id,
            UnitPrice: item.UnitPrice,
            CMAP_UnitPriceTaxIncluded__c: item.CMAP_UnitPriceTaxIncluded__c,
            CMAP_PriceCategory__c: item.CMAP_PriceCategory__c,
            CMAP_InstallmentPaymentMonthly__c:
              item.CMAP_InstallmentPaymentMonthly__c,
            CMAP_InstallmentPayMonthlyPriceTaxExc__c:
              item.CMAP_InstallmentPayMonthlyPriceTaxExc__c,
            CMAP_InstallmentPayMonthlyPriceTaxInc__c:
              item.CMAP_InstallmentPayMonthlyPriceTaxInc__c,
            CMAP_NumberOfInstallments__c: item.CMAP_NumberOfInstallments__c,
            CMAP_InstallmentPayFirstMonthPriceTaxExc__c:
              item.CMAP_InstallmentPayFirstMonthPriceTaxExc__c,
            CMAP_InstallmentPayFirstMonthPriceTaxInc__c:
              item.CMAP_InstallmentPayFirstMonthPriceTaxInc__c,
            CMAP_SelectionAnnualPayment__c: item.CMAP_SelectionAnnualPayment__c,
            CMAP_AnnualPaymentPriceTaxExcluded__c:
              item.CMAP_AnnualPaymentPriceTaxExcluded__c,
            CMAP_AnnualPaymentPriceTaxIncluded__c:
              item.CMAP_AnnualPaymentPriceTaxIncluded__c,
            CMAP_Unchargeable__c: item.CMAP_Unchargeable__c,
            CMAP_BenefitProduct__c: item.CMAP_BenefitProduct__c,
            CMAP_GrantUnitPrice__c: item.CMAP_GrantUnitPrice__c,
            CMAP_GrantPoint__c: item.CMAP_GrantPoint__c,
            CMAP_OrderItemTypeSelection__c: item.CMAP_OrderItemTypeSelection__c,
            CMAP_OrderItemTypeBenefit__c: item.CMAP_OrderItemTypeBenefit__c,
            CMAP_OrderItemTypeAutomaticSelection__c:
              item.CMAP_OrderItemTypeAutomaticSelection__c,
            CMAP_ProductSelectionMethod__c: prodSelection
              ? prodSelection.CMAP_ProductSelection__c
              : "",
            CMAP_BenefitNameForCpad__c: "",
            CMAP_BenefitPriceForCpad__c: "",
            Quantity: item.Quantity ? item.Quantity : 1,
          },
        });
      }
      return a;
    }, []);

    const benefitListArray = benifitListAgency?.map((benefit) => ({
      orderItem: {
        CMAP_MainSubProductGroupName__c: "",
        Product2Id: "",
        UnitPrice: "",
        CMAP_UnitPriceTaxIncluded__c: "",
        CMAP_PriceCategory__c: "",
        CMAP_InstallmentPaymentMonthly__c: "",
        CMAP_InstallmentPayMonthlyPriceTaxExc__c: "",
        CMAP_InstallmentPayMonthlyPriceTaxInc__c: "",
        CMAP_NumberOfInstallments__c: "",
        CMAP_InstallmentPayFirstMonthPriceTaxExc__c: "",
        CMAP_InstallmentPayFirstMonthPriceTaxInc__c: "",
        CMAP_SelectionAnnualPayment__c: "",
        CMAP_AnnualPaymentPriceTaxExcluded__c: "",
        CMAP_AnnualPaymentPriceTaxIncluded__c: "",
        CMAP_Unchargeable__c: "",
        CMAP_BenefitProduct__c: "",
        CMAP_GrantUnitPrice__c: "",
        CMAP_GrantPoint__c: "",
        CMAP_OrderItemTypeSelection__c: "",
        CMAP_OrderItemTypeBenefit__c: "",
        CMAP_OrderItemTypeAutomaticSelection__c: "",
        CMAP_ProductSelectionMethod__c: "",
        CMAP_BenefitNameForCpad__c: benefit.BenefitName,
        CMAP_BenefitPriceForCpad__c: benefit.BenefitPrice,
        Quantity: "",
      },
    }));

    return {
      isBeforeChangeAPI: isBeforeChange,
      isAfterChangeAPI: isAfterChange,
      request: {
        orderRelatedInfo: {
          orderInfo: {
            order: {
              CMAP_OrderCategory__c:
                data?.orderRelatedInfo?.orderInfo?.order?.CMAP_OrderCategory__c,
            },
            orderItemInfos: [
              ...(orderItemArray?.length > 0 ? orderItemArray : []),
              ...(isAfterChange && benefitListArray?.length > 0
                ? benefitListArray
                : []),
            ],
          },
        },
      },
    };
  };

  useEffect(() => {
    if (feeSimulationRequest.isBeforeChangeAPI) {
      setFeeSimulationRequest(
        createFeeSimulationRequest(beforeChangeOrderRelatedInfo, true, false)
      );
    }

    if (typeof window !== "undefined") {
      const userAgent = window.navigator.userAgent;
      printBtn.fields.isHidden = !userAgent?.includes(
        STRING_USERAGENT_CPAD_APP
      );
    }
  }, []);

  useEffect(() => {
    if (
      feeSimulationRequest?.request &&
      (feeSimulationRequest?.isBeforeChangeAPI ||
        feeSimulationRequest?.isAfterChangeAPI)
    ) {
      feeSimulationRefetch();
    }
  }, [feeSimulationRequest]);

  useEffect(() => {
    if (!feeSimulationData) return;
    if (!feeSimulationData.Result) {
      setError(feeSimulationData.ErrorCode, {
        type: "manual",
        message: feeSimulationData.ErrorMassage,
      });
      return;
    }

    if (feeSimulationRequest.isBeforeChangeAPI) {
      setFeeSimulationRequest(
        createFeeSimulationRequest(orderRelatedInfo, false, true)
      );

      setFeeSimulationResponses((prev) => ({
        ...prev,
        beforeChangePriceSimulation: feeSimulationData,
      }));

      return;
    }

    if (feeSimulationRequest.isAfterChangeAPI) {
      setFeeSimulationRequest({
        request: null,
        isAfterChangeAPI: false,
        isBeforeChangeAPI: false,
      });

      setFeeSimulationResponses((prev) => ({
        ...prev,
        afterChangePriceSimulation: feeSimulationData,
      }));

      return;
    }
  }, [
    feeSimulationData,
    feeSimulationRequest.isBeforeChangeAPI,
    feeSimulationRequest.isAfterChangeAPI,
  ]);

  return (
    <>
      <main>
        <div className="form_container cpad_wrapper2">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <Placeholder
                name="change-details-section"
                rendering={props.rendering}
              />
              <Placeholder
                name="monthly-fee-section"
                rendering={props.rendering}
              />
              <div className="form_wrapper-input agency-info-section mx-16 mt-40">
                <Placeholder
                  name="agency-info-section"
                  rendering={props.rendering}
                />
                <div className="grid-column two side-label-input">
                  <Placeholder
                    name="agency-info-inputs-section"
                    rendering={props.rendering}
                  />
                </div>
                <Placeholder
                  name="agency-info-buttons-section"
                  rendering={props.rendering}
                />
              </div>
              <div className="bottom-section chart-bottom-section center">
                <Placeholder
                  name="button-section"
                  rendering={props.rendering}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default withSitecoreContext()(CPadChangeContentConfirmationPageLayout);
