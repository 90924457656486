import React, { useEffect, useState } from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import parse from "html-react-parser";

// @Model

// get the data

// update the context

// @Controller

// @View

const L06 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    noteTitleText,
    notes,
    additionalClass,
    isHidden,
    isVisible,
    registercommufaIdAuthenticationCompletedMessage,
  } = properData;

  const [visibility, setVisibility] = useState(true);
  useEffect(() => {
    if (isVisible === undefined) {
      setVisibility(true);
    } else {
      setVisibility(isVisible);
    }
  }, [isVisible]);

  // @View
  return (
    <React.Fragment>
      {visibility && (
        <>
          {!isHidden && (
            <>
              {notes?.value && (
                <div
                  className={`list_wrapper note secondary ${additionalClass?.value}  medium L-06`}
                >
                  {parse(notes?.value)}
                </div>
              )}
              {registercommufaIdAuthenticationCompletedMessage && (
                <div
                  className={`list_wrapper note secondary ${additionalClass?.value}  medium L-06`}
                >
                  <RichText
                    field={fillBlank(
                      registercommufaIdAuthenticationCompletedMessage
                    )}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default L06;
