import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import {
  fillBlank,
  getProperData,
  preventDoubleClick,
} from "../../utils/commonUtils";
import ModalContext from "../../assets/context/ModalContext";
import { di_fetch } from "../../utils/di";

const LiM01 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { modalLinkText, additionalClass, modalTarget, linkIcon } = properData;
  const { dispatch } = useContext(ModalContext);

  const handleClick = preventDoubleClick(
    async (event, myClickEvent = undefined) => {
      event.preventDefault();
      if (myClickEvent) {
        myClickEvent(event);
        return false;
      } else {
        handleButtonClick();
      }
    }
  );

  // @Controller
  const modalShowHandler = () => {
    dispatch({ type: "SHOW_MODAL", payload: { id: modalTarget?.value } });
  };

  const handleButtonClick = preventDoubleClick(async () => {
    modalShowHandler();
  });

  // @View
  return (
    <React.Fragment>
      <div className={`link_wrapper icon LiM-01 ${additionalClass?.value}`}>
        <a
          href="#"
          onClick={(e) => handleClick(e, props?.fields?.onClickEvent)}
        >
          {linkIcon?.value && <img src={fillBlank(linkIcon)?.value?.src} />}
          <span>
            <Text field={fillBlank(modalLinkText)} />
          </span>
        </a>
      </div>
    </React.Fragment>
  );
};

export default LiM01;
