import React from "react";
import { di_fetch } from "../../utils/di";
import { getProperData } from "../../utils/commonUtils";
import { STRING_REQUIRED } from "../../utils/constantVariables";

import TextComponent from "../D-01";
import DateComponent from "../M-01i";
import CheckBoxComponent from "../S-09";

const S19 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const { metadata = {}, state, update, category } = getProperData(sitecoreContext, props.fields);

  const createDynamicComponent = () => {
    switch (metadata.type) {
      case "date":
        return (
          <DateComponent
            rendering={props.rendering}
            fields={{
              requiredText: { value: metadata.isRequired ? STRING_REQUIRED : "" },
              titleText: metadata.label,
              calendarPlaceholder: metadata.placeholder,
              initialValue: null,
              inputValue: {
                isDisabled: false,
              },
              onChangeEvent: (date) => {
                if (props.onChangeEvent) props.onChangeEvent(date);
                update((prev) => ({ ...prev, [category]: date }));
              },
            }}
          />
        );
      case "checkbox":
        return (
          <CheckBoxComponent
            rendering={props.rendering}
            fields={{
              titleText: { value: metadata.label },
              checkBoxLabel: metadata.label,
              checkBoxIsCheck: state[category] ?? false,
              onChangeEvent: (checked) => {
                if (props.onChangeEvent) props.onChangeEvent(checked);
                update((prev) => ({ ...prev, [category]: checked }));
              },
            }}
          />
        );
      case "text":
      default:
        return <TextComponent rendering={props.rendering} fields={{ descriptionText: metadata?.label }} />;
    }
  };

  return (
    <React.Fragment>
      <div>{createDynamicComponent()}</div>
    </React.Fragment>
  );
};

export default S19;
