import { Text } from "@sitecore-jss/sitecore-jss-react";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import getSelectData from "../../utils/getSelectData";
import { useSetSelectedItem } from "../../hooks/useSelectedState";
import { useMetadataConditionVisible } from "../../hooks/useMetadataConditionVisible";
import { di_fetch } from "../../utils/di";
import {
  getProperData,
  fillBlank,
  nullChecker,
  renderErrorMsg,
} from "../../utils/commonUtils";

const S02 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { radioBoxLabel, isRequired, isHidden } = properData;
  // The key of the metadata
  // The path to for the API to retrieve the metadata
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [isHiddenField, setIsHiddenField] = useState("");
  //data from master
  const componentCategory = nullChecker(properData?.category)?.value;
  const additionalClass = nullChecker(properData?.additionalClass);
  const isDisabled = nullChecker(properData?.isDisabled, { value: false });
  const selections = nullChecker(properData?.selections, []);

  useEffect(() => {
    setIsHiddenField(isHidden);
  }, [isHidden]);

  // @Controller
  const [selectedItem, selectedItemSetter] = useSetSelectedItem(
    componentCategory
  );

  const handleChange = (event) => {
    // changed context
    let newVal = {};
    newVal[componentCategory] = event.target.value;
    selectedItemSetter(newVal);
    if (properData.onChangeEvent) properData.onChangeEvent(event.target.value);
  };

  let errorMessage = `${radioBoxLabel?.value} を選択してください`;
  let requiredSettings = {};
  if (isRequired) {
    requiredSettings = { required: errorMessage };
  }

  // @View
  return (
    <React.Fragment>
      {!isHiddenField?.value && (
        <div
          className={`S-02 input-form ${additionalClass?.value}  ${
            errors[componentCategory] && "hasError"
          }`}
        >
          {renderErrorMsg(errors[componentCategory], errorMessage)}
          {selections?.length > 0 ? (
            selections?.map((item, i) => (
              <div
                key={i}
                className={`input-box ${
                  selectedItem.selected === item.Code ? "active" : ""
                }`}
              >
                <div className="input-container">
                  <input
                    type="radio"
                    name={componentCategory}
                    className={isDisabled.value ? "disabled" : ""}
                    disabled={isDisabled?.value}
                    value={item.Code}
                    checked={
                      item.Checked || selectedItem.selected === item.Code
                    }
                    // defaultChecked={item.isLinePlan}
                    {...register(componentCategory, requiredSettings)}
                    onChange={handleChange}
                  />
                  <label htmlFor={componentCategory}>
                    <Text field={fillBlank({ value: item.Value })} />
                    <span>
                      {!item?.DescriptionUrl ? (
                        item.Description && <Text field={fillBlank({ value: item.Description })} />
                      ) : (
                        <a
                          href={item.DescriptionUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.Description && 
                            <Text
                              field={fillBlank({ value: item.Description })}
                            />
                          }
                        </a>
                      )}
                    </span>
                  </label>
                </div>
              </div>
            ))
          ) : (
            <div className="input-box">
              <div className="input-container">
                <input type="radio" name="cbQ2" />
                <label htmlFor="cbQ2">
                  <Text field={fillBlank(radioBoxLabel)} />
                </label>
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default S02;
