import React, { useContext } from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import ModalContext from "../../assets/context/ModalContext";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";

const H02 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    titleText,
    additionalClass,
    indexNumber,
    modalTarget,
    additionalRichText,
    isHidden,
    enteredClass,
    onEditButtonClick,
  } = properData;
  const { dispatch } = useContext(ModalContext);
  const entered = enteredClass ? enteredClass : "";

  const isAdditionalRichTextHidden =
    props?.fields?.isAdditionalRichTextHidden ?? true;

  // @Controller
  const handleEditButtonClick = () => {
    if(modalTarget?.value) {
      dispatch({ type: "SHOW_MODAL", payload: { id: modalTarget?.value ?? "" } });
    }
    
    if (onEditButtonClick) {
      onEditButtonClick();
    }
  };

  // @View
  return (
    <React.Fragment>
      {!isHidden && (
        <div
          className={`H-02 heading_wrapper  ${additionalClass?.value} ${entered}`}
        >
          <span>
            <Text field={fillBlank(indexNumber)} />
          </span>
          <h2>
            <Text field={fillBlank(titleText)} />
          </h2>
          {!isAdditionalRichTextHidden && (
            <em>
              <RichText field={fillBlank(additionalRichText)} />
            </em>
          )}
          <button
            className="btn btn_plan"
            type="button"
            onClick={handleEditButtonClick}
          >
            変更
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default H02;
