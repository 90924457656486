import { COOKIE_EXPIRATION_DATE } from "./constantVariables";
import { domain } from "../envSettings";
export const setCookieValue = (key, value, expirationInDays) => {
  var expires = "";
  if (expirationInDays) {
    var date = new Date();
    date.setTime(date.getTime() + expirationInDays * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = key + "=" + (value || "") + expires + "; path=/";
};

export const getGaClientIdFromCookie = () => {
  var cookie = {};
  document.cookie.split(';').forEach(function(el) {
    var splitCookie = el.split('=');
    var key = splitCookie[0].trim();
    var value = splitCookie[1];
    cookie[key] = value;
  });
  return cookie["_ga"]?.substring(6);
}

export const getCookieValue = (cookieName) => {
  var name = cookieName + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1);
    if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
  }
  return "";
};

export const deleteCookieValue = (cookieName) => {
  document.cookie =
    cookieName +
    `=; Path=/; domain=${domain};expires=` +
    COOKIE_EXPIRATION_DATE +
    ";";
};
