import { recoilKeys as keys } from "../../../assets/recoilKeys";
import {
  STRING_LUMPSUMPAYMENTINSTALLMENTPAYMENT
} from "../../../utils/constantVariables";
import {
  getSessionStorageItem,
} from "../../../utils/useSessionStorage";
import linq from 'linq'
import {
  filterSelectedMonthlyExpenseProduct,
  filterSelectedMonthlyExpenseProductWithoutBenefit,
  filterSelectedAnnualExpenseProduct,
  filterSelectedInitialCostProduct,
  filterSelectedRemainingDebtProduct,
  filterSelectedBenefitProduct,
  calculateMonthlyPriceTaxInc,
  calculateMonthlyPriceTaxExc,
  calculateAnnualPriceTaxInc,
  calculateAnnualPriceTaxExc,
  calculateInitialCostTaxInc,
  calculateInitialCostTaxExc,
  calculateRemainingDebtTaxInc,
  calculateRemainingDebtTaxExc
} from "../Common/CommonProcess";

// 月額料金
export const createMonthlyExpenseObj = () => {
  // SessionStorageから変更前と変更後のOrderRelatedInfoを読み込む。
  const before = getSessionStorageItem(keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO, true);
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更前と変更後のOrderRelatedInfoから対象の月額商品を抽出する。
  const beforeOrderInfos = filterSelectedMonthlyExpenseProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterOrderInfos = filterSelectedMonthlyExpenseProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterOrderInfosWithoutBenefit = filterSelectedMonthlyExpenseProductWithoutBenefit(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 商品毎の月額料金を作成する。
  const baseObj = createMonthlyExpenseBaseObj(beforeOrderInfos, afterOrderInfos, afterOrderInfosWithoutBenefit);

  // 表示用オブジェクト_商品小計を作成する。
  const subtotalObj = createMonthlySubtotalObj(baseObj, after);

  // 表示用オブジェクト_合計値を作成する。
  const totalObj = createMonthlyTotalObj(baseObj);

  return { totalObj, subtotalObj };
}

// 年額料金
export const createAnnualExpenseObj = () => {
  // SessionStorageから変更前と変更後のOrderRelatedInfoを読み込む。
  const before = getSessionStorageItem(keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO, true);
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更前と変更後のOrderRelatedInfoから対象の年額商品を抽出する。
  const beforeOrderInfos = filterSelectedAnnualExpenseProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterOrderInfos = filterSelectedAnnualExpenseProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 商品毎の年額料金を作成する。
  const baseObj = createBaseObj(beforeOrderInfos, afterOrderInfos, calculateAnnualPriceTaxInc, calculateAnnualPriceTaxExc);

  // 表示用オブジェクト_商品小計を作成する。
  const subtotalObj = createSubtotalObj(baseObj, after);

  // 表示用オブジェクト_合計値を作成する。
  const totalObj = createTotalObj(baseObj);

  return { totalObj, subtotalObj };
}

// 初期費用
export const createInitialCostObj = () => {
  // SessionStorageから変更前と変更後のOrderRelatedInfoを読み込む。
  const before = getSessionStorageItem(keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO, true);
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更前と変更後のOrderRelatedInfoから対象の初期費用商品を抽出する。
  const beforeOrderInfos = filterSelectedInitialCostProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterOrderInfos = filterSelectedInitialCostProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 商品毎の初期費用を作成する。
  const baseObj = createBaseObj(beforeOrderInfos, afterOrderInfos, calculateInitialCostTaxInc, calculateInitialCostTaxExc);

  // 表示用オブジェクト_商品小計を作成する。
  const subtotalObj = createSubtotalObj(baseObj, after);

  // 表示用オブジェクト_合計値を作成する。
  const totalObj = createTotalObj(baseObj);

  return { totalObj, subtotalObj };
}

// 残債
export const createRemainingDebtObj = () => {
  // SessionStorageから変更前と変更後のOrderRelatedInfoを読み込む。
  const before = getSessionStorageItem(keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO, true);
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更前と変更後のOrderRelatedInfoから対象の残債商品を抽出する。
  const beforeOrderInfos = filterSelectedRemainingDebtProduct(before?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  const afterOrderInfos = filterSelectedRemainingDebtProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 商品毎の残債を作成する。
  const baseObj = createBaseObj(beforeOrderInfos, afterOrderInfos, calculateRemainingDebtTaxInc, calculateRemainingDebtTaxExc);

  // 表示用オブジェクト_商品小計を作成する。
  const subtotalObj = createSubtotalObj(baseObj, after);

  // 表示用オブジェクト_合計値を作成する。
  const totalObj = createTotalObj(baseObj);

  return { totalObj, subtotalObj };
}

// 特典
export const createBenefitObj = () => {
  // SessionStorageから変更後のOrderRelatedInfoを読み込む。
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更後のOrderRelatedInfoから対象の特典商品を抽出する。
  const afterOrderInfos = filterSelectedBenefitProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos, true);

  // 特典商品をソートする。
  const result = {};
  linq.from(afterOrderInfos)
    .orderBy(x => x?.orderItem?.CMAP_MainSubProGroupBenefitCPSortOrder__c) // 第1ソート：メイン商品/サブ商品グループ・特典キャンペーン並び順
    .thenBy(x => x?.orderItem?.CMAP_ProductCategoryBenefitSortOrder__c) // 第2ソート：商品カテゴリ・特典並び順
    .thenBy(x => x?.orderItem?.CMAP_ProductSortOrder__c) // 第3ソート：商品並び順
    .forEach(x => {
      // 特典ID毎に重複しないように結果用オブジェクトに追加する。
      if (!result[x?.orderItem?.CMAP_BenefitId__c]) {
        result[x?.orderItem?.CMAP_BenefitId__c] = {
          CMAP_BenefitId__c: x?.orderItem?.CMAP_BenefitId__c,
          CMAP_BenefitName__c: x?.orderItem?.CMAP_BenefitName__c,
          CMAP_MainSubProGroupBenefitCPSortOrder__c: x?.orderItem?.CMAP_MainSubProGroupBenefitCPSortOrder__c,
          CMAP_ProductCategoryBenefitSortOrder__c: x?.orderItem?.CMAP_ProductCategoryBenefitSortOrder__c,
          CMAP_ProductSortOrder__c: x?.orderItem?.CMAP_ProductSortOrder__c
        }
      }
    });

  // 配列に変換する。
  return Object.keys(result).map(key => result[key]);
}

const createMonthlyExpenseBaseObj = (before, after, afterWithoutBenefit) => {
  const result = {};

  // 変更前と変更後と変更後(キャンペーン終了後)の配列を順にループする。
  [before, after, afterWithoutBenefit].forEach((expenses, index) => {
    expenses.forEach(item => {

      // 商品IDをキーにする。
      const key = item?.orderItem?.Product2Id;

      // キー毎に重複しないように結果用オブジェクトに追加する。
      if (!result[key]) {
        result[key] = {
          CMAP_MainSubProductGroupId__c: item.orderItem.CMAP_MainSubProductGroupId__c,
          CMAP_MainSubProductGroupName__c: item?.orderItem?.CMAP_MainSubProductGroupName__c,
          CMAP_ProductCategoryId__c: item.orderItem.CMAP_ProductCategoryId__c,
          CMAP_ProductCategoryName__c: item?.orderItem?.CMAP_ProductCategoryName__c,
          Product2Id: item?.orderItem?.Product2Id,
          CMAP_ProductName__c: item?.orderItem?.CMAP_ProductName__c,
          CMAP_OrderItemTypeSelection__c: item?.orderItem?.CMAP_OrderItemTypeSelection__c,
          CMAP_OrderItemTypeBenefit__c: item?.orderItem?.CMAP_OrderItemTypeBenefit__c,
          CMAP_OrderItemTypeAutomaticSelection__c: item?.orderItem?.CMAP_OrderItemTypeAutomaticSelection__c,
          BeforeMonthlyPriceTaxInc: null,
          BeforeMonthlyPriceTaxExc: null,
          AfterMonthlyPriceOfferingCPTaxInc: null,
          AfterMonthlyPriceOfferingCPTaxExc: null,
          AfterMonthlyPriceFinishedCPTaxInc: null,
          AfterMonthlyPriceFinishedCPTaxExc: null
        };
      }

      const priceTaxInc = calculateMonthlyPriceTaxInc(item?.orderItem);
      const priceTaxExc = calculateMonthlyPriceTaxExc(item?.orderItem);

      // 変更前(index=0)の時は、変更前月額料金に設定する
      // 変更後(index=1)の時は、変更後CP適用期間月額料金に設定する
      // 変更後(キャンペーン終了後)(index=2)の時は、変更後CP終了後月額料金に設定する
      if (index === 0) {
        result[key]['BeforeMonthlyPriceTaxInc'] = priceTaxInc;
        result[key]['BeforeMonthlyPriceTaxExc'] = priceTaxExc;
      } else if (index === 1) {
        result[key]['AfterMonthlyPriceOfferingCPTaxInc'] = priceTaxInc;
        result[key]['AfterMonthlyPriceOfferingCPTaxExc'] = priceTaxExc;
      } else if (index === 2) {
        result[key]['AfterMonthlyPriceFinishedCPTaxInc'] = priceTaxInc;
        result[key]['AfterMonthlyPriceFinishedCPTaxExc'] = priceTaxExc;
      }
    });
  });

  // 配列に変換する。
  return Object.keys(result).map(key => result[key]);
}

const createMonthlySubtotalObj = (base, after) => {
  const afterOrderItemInfos = linq.from(after?.orderRelatedInfo?.orderInfo?.orderItemInfos).select(x => x?.orderItem).toArray();
  const result = linq.from(base)
    .groupJoin(afterOrderItemInfos, // LeftOuterJoinする右側の配列
      x => x?.Product2Id, // 左側テーブルの結合キー：商品
      y => y?.Product2Id, // 右側テーブルの結合キー：商品
      (x, y) => ({
        ...x, // 左側テーブルの全項目
        y
      }))
    .selectMany(x => x?.y?.defaultIfEmpty(), // LeftOuterJoinの続き
      (x, y) => {
        delete x?.y;
        return {
          CMAP_MainSubProductGroupId__c: x?.CMAP_MainSubProductGroupId__c,
          CMAP_MainSubProductGroupName__c: x?.CMAP_MainSubProductGroupName__c,
          CMAP_ProductCategoryId__c: x?.CMAP_ProductCategoryId__c,
          CMAP_ProductCategoryName__c: x?.CMAP_ProductCategoryName__c,
          Product2Id: x?.Product2Id,
          CMAP_ProductName__c: x?.CMAP_ProductName__c,
          CMAP_OrderItemTypeSelection__c: x?.CMAP_OrderItemTypeSelection__c,
          CMAP_OrderItemTypeBenefit__c: x?.CMAP_OrderItemTypeBenefit__c,
          CMAP_OrderItemTypeAutomaticSelection__c: x?.CMAP_OrderItemTypeAutomaticSelection__c,
          BeforeMonthlyPriceTaxInc: x?.BeforeMonthlyPriceTaxInc,
          AfterMonthlyPriceOfferingCPTaxInc: x?.AfterMonthlyPriceOfferingCPTaxInc,
          AfterMonthlyPriceFinishedCPTaxInc: x?.AfterMonthlyPriceFinishedCPTaxInc,
          CMAP_MainSubProGroupBenefitCPSortOrder__c: y?.CMAP_MainSubProGroupBenefitCPSortOrder__c, // メイン商品/サブ商品グループ・特典キャンペーン並び順
          CMAP_ProductCategoryBenefitSortOrder__c: y?.CMAP_ProductCategoryBenefitSortOrder__c, // 商品カテゴリ・特典並び順
          CMAP_ProductSortOrder__c: y?.CMAP_ProductSortOrder__c // 商品並び順
        }
      }
    )
    .orderBy(x => x?.CMAP_MainSubProGroupBenefitCPSortOrder__c) // 第1ソート：メイン商品/サブ商品グループ・特典キャンペーン並び順
    .thenBy(x => x?.CMAP_ProductCategoryBenefitSortOrder__c) // 第2ソート：商品カテゴリ・特典並び順
    .thenBy(x => x?.CMAP_ProductSortOrder__c) // 第3ソート：商品並び順
    .toArray();

  return result;
}

const createMonthlyTotalObj = (base) => {
  const result = {
    BeforeMonthlyPriceTax: null,
    BeforeMonthlyPriceTaxInc: null,
    BeforeMonthlyPriceTaxExc: null,
    AfterMonthlyPriceOfferingCPTax: null,
    AfterMonthlyPriceOfferingCPTaxInc: null,
    AfterMonthlyPriceOfferingCPTaxExc: null,
    AfterMonthlyPriceFinishedCPTax: null,
    AfterMonthlyPriceFinishedCPTaxInc: null,
    AfterMonthlyPriceFinishedCPTaxExc: null
  };

  linq.from(base)
    .forEach(x => {
      result.BeforeMonthlyPriceTax += (x?.BeforeMonthlyPriceTaxInc - x?.BeforeMonthlyPriceTaxExc);
      result.BeforeMonthlyPriceTaxInc += x?.BeforeMonthlyPriceTaxInc;
      result.BeforeMonthlyPriceTaxExc += x?.BeforeMonthlyPriceTaxExc;
      result.AfterMonthlyPriceOfferingCPTax += (x?.AfterMonthlyPriceOfferingCPTaxInc - x?.AfterMonthlyPriceOfferingCPTaxExc);
      result.AfterMonthlyPriceOfferingCPTaxInc += x?.AfterMonthlyPriceOfferingCPTaxInc;
      result.AfterMonthlyPriceOfferingCPTaxExc += x?.AfterMonthlyPriceOfferingCPTaxExc;
      result.AfterMonthlyPriceFinishedCPTax += (x?.AfterMonthlyPriceFinishedCPTaxInc - x?.AfterMonthlyPriceFinishedCPTaxExc);
      result.AfterMonthlyPriceFinishedCPTaxInc += x?.AfterMonthlyPriceFinishedCPTaxInc;
      result.AfterMonthlyPriceFinishedCPTaxExc += x?.AfterMonthlyPriceFinishedCPTaxExc;
    });

  return result;
}

const createBaseObj = (before, after, calculatePriceTaxInc, calculatePriceTaxExc) => {
  const result = {};

  // 変更前と変更後の配列を順にループする。
  [before, after].forEach((expenses, index) => {
    expenses.forEach(item => {

      // 商品IDをキーにする。
      const key = item?.orderItem?.Product2Id;

      // キー毎に重複しないように結果用オブジェクトに追加する。
      if (!result[key]) {
        result[key] = {
          CMAP_MainSubProductGroupId__c: item?.orderItem?.CMAP_MainSubProductGroupId__c,
          CMAP_MainSubProductGroupName__c: item?.orderItem?.CMAP_MainSubProductGroupName__c,
          CMAP_ProductCategoryId__c: item?.orderItem?.CMAP_ProductCategoryId__c,
          CMAP_ProductCategoryName__c: item?.orderItem?.CMAP_ProductCategoryName__c,
          Product2Id: item?.orderItem?.Product2Id,
          CMAP_ProductName__c: item?.orderItem?.CMAP_ProductName__c,
          CMAP_OrderItemTypeSelection__c: item?.orderItem?.CMAP_OrderItemTypeSelection__c,
          CMAP_OrderItemTypeBenefit__c: item?.orderItem?.CMAP_OrderItemTypeBenefit__c,
          CMAP_OrderItemTypeAutomaticSelection__c: item?.orderItem?.CMAP_OrderItemTypeAutomaticSelection__c,
          BeforePriceTaxInc: null,
          BeforePriceTaxExc: null,
          AfterPriceTaxInc: null,
          AfterPriceTaxExc: null
        };
      }

      const priceTaxInc = calculatePriceTaxInc(item?.orderItem);
      const priceTaxExc = calculatePriceTaxExc(item?.orderItem);

      // 変更前(index=0)の時は、変更前年額料金に設定する
      // 変更後(index=1)の時は、変更後年額料金に設定する
      if (index === 0) {
        result[key]['BeforePriceTaxInc'] = priceTaxInc;
        result[key]['BeforePriceTaxExc'] = priceTaxExc;
      } else if (index === 1) {
        result[key]['AfterPriceTaxInc'] = priceTaxInc;
        result[key]['AfterPriceTaxExc'] = priceTaxExc;
      }
    });
  });

  // 配列に変換する。
  return Object.keys(result).map(key => result[key]);
}


const createSubtotalObj = (base, after) => {
  const afterOrderItemInfos = linq.from(after?.orderRelatedInfo?.orderInfo?.orderItemInfos).select(x => x?.orderItem).toArray();
  const result = linq.from(base)
    .groupJoin(afterOrderItemInfos, // LeftOuterJoinする右側の配列
      x => x?.Product2Id, // 左側テーブルの結合キー：商品
      y => y?.Product2Id, // 右側テーブルの結合キー：商品
      (x, y) => ({
        ...x, // 左側テーブルの全項目
        y
      }))
    .selectMany(x => x?.y?.defaultIfEmpty(), // LeftOuterJoinの続き
      (x, y) => {
        delete x?.y;
        return {
          CMAP_MainSubProductGroupId__c: x?.CMAP_MainSubProductGroupId__c,
          CMAP_MainSubProductGroupName__c: x?.CMAP_MainSubProductGroupName__c,
          CMAP_ProductCategoryId__c: x?.CMAP_ProductCategoryId__c,
          CMAP_ProductCategoryName__c: x?.CMAP_ProductCategoryName__c,
          Product2Id: x?.Product2Id,
          CMAP_ProductName__c: x?.CMAP_ProductName__c,
          CMAP_OrderItemTypeSelection__c: x?.CMAP_OrderItemTypeSelection__c,
          CMAP_OrderItemTypeBenefit__c: x?.CMAP_OrderItemTypeBenefit__c,
          CMAP_OrderItemTypeAutomaticSelection__c: x?.CMAP_OrderItemTypeAutomaticSelection__c,
          BeforePriceTaxInc: x?.BeforePriceTaxInc,
          AfterPriceTaxInc: x?.AfterPriceTaxInc,
          CMAP_MainSubProGroupBenefitCPSortOrder__c: y?.CMAP_MainSubProGroupBenefitCPSortOrder__c, // メイン商品/サブ商品グループ・特典キャンペーン並び順
          CMAP_ProductCategoryBenefitSortOrder__c: y?.CMAP_ProductCategoryBenefitSortOrder__c, // 商品カテゴリ・特典並び順
          CMAP_ProductSortOrder__c: y?.CMAP_ProductSortOrder__c // 商品並び順
        }
      }
    )
    .orderBy(x => x?.CMAP_MainSubProGroupBenefitCPSortOrder__c) // 第1ソート：メイン商品/サブ商品グループ・特典キャンペーン並び順
    .thenBy(x => x?.CMAP_ProductCategoryBenefitSortOrder__c) // 第2ソート：商品カテゴリ・特典並び順
    .thenBy(x => x?.CMAP_ProductSortOrder__c) // 第3ソート：商品並び順
    .toArray();

  return result;
}

const createTotalObj = (base) => {
  const result = {
    BeforePriceTax: null,
    BeforePriceTaxInc: null,
    BeforePriceTaxExc: null,
    AfterPriceTax: null,
    AfterPriceTaxInc: null,
    AfterPriceTaxExc: null
  };

  linq.from(base)
    .forEach(x => {
      result.BeforePriceTax += (x?.BeforePriceTaxInc - x?.BeforePriceTaxExc);
      result.BeforePriceTaxInc += x?.BeforePriceTaxInc;
      result.BeforePriceTaxExc += x?.BeforePriceTaxExc;
      result.AfterPriceTax += (x?.AfterPriceTaxInc - x?.AfterPriceTaxExc);
      result.AfterPriceTaxInc += x?.AfterPriceTaxInc;
      result.AfterPriceTaxExc += x?.AfterPriceTaxExc;
    });

  return result;
}
