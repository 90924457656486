import React, { useEffect, useState } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import {
  deepcopy, getUrlInfo,
} from "../../utils/commonUtils";
import { getSessionStorageItem, setSessionStorageItem } from "../../utils/useSessionStorage";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { ApplyButtonContext } from "../../assets/context/ApplyButtonContext";
import {
  useCustomQuery,
} from "../../hooks/useGetData";
import {
  STRING_POST,
  STRING_GET,
  STRING_REQUEST_WEB
} from "../../utils/constantVariables";
import { format } from "date-fns";
import { CommonEstimateConfirmationDetails } from "../../services/common/common-estimate-confirmation-details";
import { CommonProductDetailConfirmation } from "../../services/common/common-product-details-confirmation";
import { UserInfoDetailConfirmation } from "./userInfoDetailConfirmation";
import { BillingInformationConfirmation } from "./billingInformationConfirmation";
import { CautionConfirmation } from "./cautionConfirmation";
import { AdditionalInfoForAgencyConfirmation } from "./additionalInfoForAgencyConfirmation";
import { AgencyInfoConfirmation } from "./agencyInfoConfirmation";
import { ImportantMatterConfirmation } from "./importantMatterConfirmation";
import {
  apiBaseURL,
  cpadPageURL,
  mycommufaPageURL
} from "../../envSettings";

const ApplicationDetailConfirmationLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  
  const [retrieveMetadataData, setRetrieveMetadataData] = useState({});

  const [retrieveSystemDateTime, setRetrieveSystemDateTime] = useState({
    systemDateTime: null,
    effectiveDate: null,
    effectiveTime: null,
    isFirstCall: null,
  });

  const orderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );
  const beforeChangeOrderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO,
    true
  );
  const personalizeInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );
  const { BenefitListForAgency: benefitListAgency } = getSessionStorageItem(
    keys.SSN_APPLICATION_SELECTPRODUCT_BENEFITLISTFORAGENCY,
    true
  );
  
  const orderChannelCategory = personalizeInfo?.AppChannelDivision ?? STRING_REQUEST_WEB;

  const [isCpad, setCpad] = useState(false);
  const [isMyCommufa, setMyCommufa] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const siteURL = getUrlInfo();
      setCpad(siteURL?.origin === cpadPageURL);
      setMyCommufa(siteURL?.origin === mycommufaPageURL);
    }
  }, []);
  const order = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order;
  const orderCategory = order?.CMAP_OrderCategory__c;
  const orderItemInfo = orderRelatedInfo?.orderRelatedInfo?.orderItemInfos;

  const [agreementContext, setAgreementContext] = useState(false);
  const retrieveMetadataDefinitionsEndpoint = `${apiBaseURL}order/RetrieveMetadataDefinitions`;
  const retrieveMetadataRequest = {
      objectNames: ["Order"],
      orderChannelCategory: orderChannelCategory,
    };
  const {
    data: retrieveMetadataResponse,
    isLoading: retrieveMetadataLoading,
    error: retrieveMetadataError,
    refetch: retrieveMetadataRefresh,
    remove: retrieveMetadataRemove,
  } = useCustomQuery(
    retrieveMetadataDefinitionsEndpoint,
    false,
    STRING_POST,
    retrieveMetadataRequest
  );

  props.setIsLoading(isApiLoading);

  useEffect(() => {
    retrieveMetadataResponse &&
      setRetrieveMetadataData(retrieveMetadataResponse);
  }, [retrieveMetadataResponse]);

  useEffect(() => {
    isCpad && retrieveMetadataRefresh();
  }, [isCpad]);

  const retrieveSystemDateTimeURI = `${apiBaseURL}common/RetrieveSystemDateTime`;
  const {
    data: retrieveSystemDateTimeData,
    isLoading: retrieveSystemDateTimeIsLoading,
    error: retrieveSystemDateTimeError,
    refetch: retrieveSystemDateTimeRefresh,
    remove: retrieveSystemDateTimeRemove,
  } = useCustomQuery(retrieveSystemDateTimeURI, true, STRING_GET);

  // retrieve systemDateTimeData
  useEffect(() => {
    if (retrieveSystemDateTimeData) {
      const systemDateTime = retrieveSystemDateTimeData?.SystemDateTime;
      let effectiveDate = "";
      let effectiveTime = "";

      const formattedSystemDateTimeData = format(new Date(retrieveSystemDateTimeData?.SystemDateTime), "yyyy-MM-dd HH:mm:ss").split(" ");

      if (formattedSystemDateTimeData.length > 0) {
        // if cpad
        if (getUrlInfo()?.cpadHostName === getUrlInfo()?.hostname) {
          effectiveDate = order?.EffectiveDate;
          effectiveTime = order?.CMAP_EffectiveTime__c;
        }
        else {
          effectiveDate = formattedSystemDateTimeData[0];
          effectiveTime = formattedSystemDateTimeData[1];
        }
      }

      setRetrieveSystemDateTime((prevState) => {
        return {
          ...prevState,
          systemDateTime,
          effectiveDate,
          effectiveTime,
          isFirstCall: prevState.isFirstCall === null ? true : false
        };
      });
    }
  }, [retrieveSystemDateTimeData]);

  const urlInfo = getUrlInfo();
  const urlParentName = urlInfo?.pathnames?.[1];

  const isApiLoading = retrieveMetadataLoading || retrieveSystemDateTimeIsLoading
  props.setIsLoading(isApiLoading);

  return (
    <React.Fragment>
      <ApplyButtonContext.Provider
        value={{ agreementContext, setAgreementContext }}
      >
        <main>
          <Placeholder name="title-wrapper" rendering={props.rendering} />
          <div className="form_container">
            <div className="form_detail full-width">
              <div className="form_wrapper">
                <Placeholder
                  name="warning-modal-section"
                  rendering={props.rendering}
                />
                <CommonEstimateConfirmationDetails
                  rendering={props.rendering}
                  setIsLoading={props.setIsLoading}
                  sitecoreContext={sitecoreContext}
                  orderCategory={orderCategory}
                  orderItemInfo={orderItemInfo}
                  benefitListAgency={benefitListAgency}
                  urlParentName={urlParentName}
                />
                <CommonProductDetailConfirmation
                  rendering={props.rendering}
                  setIsLoading={props.setIsLoading}
                  sitecoreContext={sitecoreContext}
                  orderCategory={orderCategory}
                  orderChannelCategory={orderChannelCategory}
                  benefitListAgency={benefitListAgency}
                  orderRelatedInfo={orderRelatedInfo}
                  beforeChangeOrderRelatedInfo={beforeChangeOrderRelatedInfo}
                  urlParentName={urlParentName}
                />
                <UserInfoDetailConfirmation
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                  orderCategory={orderCategory}
                  orderRelatedInfo={orderRelatedInfo}
                  urlParentName={urlParentName}
                />
                <BillingInformationConfirmation
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                  isMyCommufa={isMyCommufa}
                  isCpad={isCpad}
                  orderRelatedInfo={orderRelatedInfo}
                />
                <AdditionalInfoForAgencyConfirmation 
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                  isCpad={isCpad}
                  orderRelatedInfo={orderRelatedInfo}
                  retrieveMetadataData={retrieveMetadataData}
                />
                <AgencyInfoConfirmation
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                  isCpad={isCpad}
                  orderRelatedInfo={orderRelatedInfo}
                />
                <CautionConfirmation
                  rendering={props.rendering}
                  sitecoreContext={sitecoreContext}
                />
                <ImportantMatterConfirmation
                  rendering={props.rendering}
                  setIsLoading={props.setIsLoading}
                  sitecoreContext={sitecoreContext}
                  orderRelatedInfo={orderRelatedInfo}
                  isCpad={isCpad}
                  orderChannelCategory={orderChannelCategory}
                  retrieveSystemDateTime={retrieveSystemDateTime}
                  retrieveSystemDateTimeRefresh={retrieveSystemDateTimeRefresh}
                />
              </div>
            </div>
          </div>
        </main>
      </ApplyButtonContext.Provider>
    </React.Fragment>
  );
};

export default withSitecoreContext()(ApplicationDetailConfirmationLayout);
