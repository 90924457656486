import {
  STRING_DESELECTED,
  STRING_SUSPENDSELECTED,
  STRING_MONTHLYPAYMENT,
  STRING_MONTHLYPAYMENTANNUALPAYMENT,
  STRING_LUMPSUMPAYMENTINSTALLMENTPAYMENT,
  STRING_ANNUALPAYMENT,
  STRING_LUMPSUMPAYMENT,
  TAX_RATE_REDUCED,
  TAX_RATE_NORMAL
} from "../../../utils/constantVariables";
import linq from 'linq'
import _ from "lodash";

export const filterSelectedSelectionProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item => item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && item?.orderItem?.CMAP_OrderItemTypeSelection__c === true // 申込商品種別：選択商品が「true」を対象とする
    ).toArray();

  return result;
}

export const filterSelectedAutomaticSelectionProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item => item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && item?.orderItem?.CMAP_OrderItemTypeAutomaticSelection__c === true // 申込商品種別：自動選択商品が「true」を対象とする
    ).toArray();

  return result;
}


export const filterSelectedMonthlyExpenseProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item => item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && (
          item?.orderItem?.CMAP_PriceCategory__c === STRING_MONTHLYPAYMENT // 価格区分が「月額払い」を対象とする
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_MONTHLYPAYMENTANNUALPAYMENT // 価格区分が「月額払い／年額払い」かつ【月額払い／年額払い】年額払いを選択が「false」を対象とする
            && item?.orderItem?.CMAP_SelectionAnnualPayment__c === false
          )
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENTINSTALLMENTPAYMENT // 価格区分が「一括払い／分割払い」かつ分割払い/月が「true」を対象とする
            && item?.orderItem?.CMAP_InstallmentPaymentMonthly__c === true
          )
        )
    ).toArray();

  return result;
}

export const filterSelectedMonthlyExpenseProductWithoutBenefit = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item => item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && (
          item?.orderItem?.CMAP_PriceCategory__c === STRING_MONTHLYPAYMENT // 価格区分が「月額払い」を対象とする
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_MONTHLYPAYMENTANNUALPAYMENT // 価格区分が「月額払い／年額払い」かつ【月額払い／年額払い】年額払いを選択が「false」を対象とする
            && item?.orderItem?.CMAP_SelectionAnnualPayment__c === false
          )
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENTINSTALLMENTPAYMENT // 価格区分が「一括払い／分割払い」かつ分割払い/月が「true」を対象とする
            && item?.orderItem?.CMAP_InstallmentPaymentMonthly__c === true
          )
        )
        && item?.orderItem?.CMAP_BenefitProduct__c === null // 特典商品IDが「null」を除く
    ).toArray();

  return result;
}

export const filterSelectedAnnualExpenseProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item => item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && (
          item?.orderItem?.CMAP_PriceCategory__c === STRING_ANNUALPAYMENT // 価格区分が「年額払い」を対象とする
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_MONTHLYPAYMENTANNUALPAYMENT // 価格区分が「月額払い／年額払い」かつ【月額払い／年額払い】年額払いを選択が「true」を対象とする
            && item?.orderItem?.CMAP_SelectionAnnualPayment__c === true
          )
        )
    ).toArray();

  return result;
}

export const filterSelectedInitialCostProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item => item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && (
          item?.orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENT // 価格区分が「一括払い」を対象とする
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENTINSTALLMENTPAYMENT // 価格区分が「一括払い／分割払い」かつ分割払い/月が「false」を対象とする
            && item?.orderItem?.CMAP_InstallmentPaymentMonthly__c === false
          )
        )
    ).toArray();

  return result;
}

export const filterSelectedRemainingDebtProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item => item?.orderItem?.CMAP_ProductSelectionState__c === STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を対象とする
        && item?.orderItem?.CMAP_InstallmentPaymentCurrently__c === true  // 分割払い中がtrueを対象とする
        && item?.orderItem?.CMAP_RemainingDebtLumpSumPayment__c === false // 残債一括払いがfalseを対象とする
    ).toArray();

  return result;
}

export const filterSelectedBenefitProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item => item?.orderItem?.CMAP_OrderItemTypeBenefit__c === true // 申込商品種別：特典商品が「true」を対象とする
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
    ).toArray();

  return result;
}

export const filterSelectedSelectionProductAndMonthlyExpenseProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item =>
        item?.orderItem?.CMAP_OrderItemTypeSelection__c === true // 申込商品種別：選択商品が「true」を対象とする 
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && (
          item?.orderItem?.CMAP_PriceCategory__c === STRING_MONTHLYPAYMENT // 価格区分が「月額払い」を対象とする
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_MONTHLYPAYMENTANNUALPAYMENT // 価格区分が「月額払い／年額払い」かつ【月額払い／年額払い】年額払いを選択が「false」を対象とする
            && item?.orderItem?.CMAP_SelectionAnnualPayment__c === false
          )
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENTINSTALLMENTPAYMENT // 価格区分が「一括払い／分割払い」かつ分割払い/月が「true」を対象とする
            && item?.orderItem?.CMAP_InstallmentPaymentMonthly__c === true
          )
        )
    ).toArray();

  return result;
}

export const filterSelectedAutomaticSelectionProductAndMonthlyExpenseProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item =>
        item?.orderItem?.CMAP_OrderItemTypeAutomaticSelection__c === true // 申込商品種別：自動選択商品が「true」を対象とする 
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && (
          item?.orderItem?.CMAP_PriceCategory__c === STRING_MONTHLYPAYMENT // 価格区分が「月額払い」を対象とする
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_MONTHLYPAYMENTANNUALPAYMENT // 価格区分が「月額払い／年額払い」かつ【月額払い／年額払い】年額払いを選択が「false」を対象とする
            && item?.orderItem?.CMAP_SelectionAnnualPayment__c === false
          )
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENTINSTALLMENTPAYMENT // 価格区分が「一括払い／分割払い」かつ分割払い/月が「true」を対象とする
            && item?.orderItem?.CMAP_InstallmentPaymentMonthly__c === true
          )
        )
    ).toArray();

  return result;
}

export const filterSelectedBenefitAndMonthlyExpenseProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item =>
        item?.orderItem?.CMAP_OrderItemTypeBenefit__c === true // 申込商品種別：特典商品が「true」を対象とする 
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && (
          item?.orderItem?.CMAP_PriceCategory__c === STRING_MONTHLYPAYMENT // 価格区分が「月額払い」を対象とする
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_MONTHLYPAYMENTANNUALPAYMENT // 価格区分が「月額払い／年額払い」かつ【月額払い／年額払い】年額払いを選択が「false」を対象とする
            && item?.orderItem?.CMAP_SelectionAnnualPayment__c === false
          )
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENTINSTALLMENTPAYMENT // 価格区分が「一括払い／分割払い」かつ分割払い/月が「true」を対象とする
            && item?.orderItem?.CMAP_InstallmentPaymentMonthly__c === true
          )
        )
    ).toArray();

  return result;
}

export const filterSelectedSelectionProductAndAnnualExpenseProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item =>
        item?.orderItem?.CMAP_OrderItemTypeSelection__c === true // 申込商品種別：選択商品が「true」を対象とする 
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && (
          item?.orderItem?.CMAP_PriceCategory__c === STRING_ANNUALPAYMENT // 価格区分が「年額払い」を対象とする
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_MONTHLYPAYMENTANNUALPAYMENT // 価格区分が「月額払い／年額払い」かつ【月額払い／年額払い】年額払いを選択が「true」を対象とする
            && item?.orderItem?.CMAP_SelectionAnnualPayment__c === true
          )
        )
    ).toArray();

  return result;
}

export const filterSelectedAutomaticSelectionProductAndAnnualExpenseProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item =>
        item?.orderItem?.CMAP_OrderItemTypeAutomaticSelection__c === true // 申込商品種別：自動選択商品が「true」を対象とする  
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && (
          item?.orderItem?.CMAP_PriceCategory__c === STRING_ANNUALPAYMENT // 価格区分が「年額払い」を対象とする
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_MONTHLYPAYMENTANNUALPAYMENT // 価格区分が「月額払い／年額払い」かつ【月額払い／年額払い】年額払いを選択が「true」を対象とする
            && item?.orderItem?.CMAP_SelectionAnnualPayment__c === true
          )
        )
    ).toArray();

  return result;
}

export const filterSelectedBenefitAndAnnualExpenseProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item =>
        item?.orderItem?.CMAP_OrderItemTypeBenefit__c === true // 申込商品種別：特典商品が「true」を対象とする 
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && (
          item?.orderItem?.CMAP_PriceCategory__c === STRING_ANNUALPAYMENT // 価格区分が「年額払い」を対象とする
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_MONTHLYPAYMENTANNUALPAYMENT // 価格区分が「月額払い／年額払い」かつ【月額払い／年額払い】年額払いを選択が「true」を対象とする
            && item?.orderItem?.CMAP_SelectionAnnualPayment__c === true
          )
        )
    ).toArray();

  return result;
}

export const filterSelectedSelectionProductAndInitialCostProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item =>
        item?.orderItem?.CMAP_OrderItemTypeSelection__c === true // 申込商品種別：選択商品が「true」を対象とする
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && (
          item?.orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENT // 価格区分が「一括払い」を対象とする
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENTINSTALLMENTPAYMENT // 価格区分が「一括払い／分割払い」かつ分割払い/月が「false」を対象とする
            && item?.orderItem?.CMAP_InstallmentPaymentMonthly__c === false
          )
        )
    ).toArray();

  return result;
}

export const filterSelectedAutomaticSelectionProductAndInitialCostProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item =>
        item?.orderItem?.CMAP_OrderItemTypeAutomaticSelection__c === true // 申込商品種別：自動選択商品が「true」を対象とする 
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && (
          item?.orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENT // 価格区分が「一括払い」を対象とする
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENTINSTALLMENTPAYMENT // 価格区分が「一括払い／分割払い」かつ分割払い/月が「false」を対象とする
            && item?.orderItem?.CMAP_InstallmentPaymentMonthly__c === false
          )
        )
    ).toArray();

  return result;
}

export const filterSelectedBenefitAndInitialCostProduct = (orderRelatedInfo) => {
  const result = linq.from(orderRelatedInfo)
    .where(
      item =>
        item?.orderItem?.CMAP_OrderItemTypeBenefit__c === true // 申込商品種別：特典商品が「true」を対象とする
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_DESELECTED // 商品選択状態が「選択解除(Deselected)」を除く
        && item?.orderItem?.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED  // 商品選択状態が「選択・休止(SuspendSelected)」を除く
        && (
          item?.orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENT // 価格区分が「一括払い」を対象とする
          || (
            item?.orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENTINSTALLMENTPAYMENT // 価格区分が「一括払い／分割払い」かつ分割払い/月が「false」を対象とする
            && item?.orderItem?.CMAP_InstallmentPaymentMonthly__c === false
          )
        )
    ).toArray();

  return result;
}

export const calculateMonthlyPriceTaxInc = (orderItem) => {

  // 請求対象外の場合は、0を返す。
  if (orderItem?.CMAP_Unchargeable__c === true) {
    return 0;
  }
  // 価格区分が「一括払い／分割払い」かつ分割払い/月が「true」の場合は、分割払い価格/月(税込) * 数量で計算する。
  else if (orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENTINSTALLMENTPAYMENT
    && orderItem?.CMAP_InstallmentPaymentMonthly__c === true) {
    return orderItem?.CMAP_InstallmentPayMonthlyPriceTaxInc__c * (orderItem?.Quantity ?? 1);
  }
  // それ以外の場合は、単価(税込) * 数量で計算する。
  else {
    return orderItem?.CMAP_UnitPriceTaxIncluded__c * (orderItem?.Quantity ?? 1);
  }
}

export const calculateMonthlyPriceTaxExc = (orderItem) => {

  // 請求対象外の場合は、0を返す。
  if (orderItem?.CMAP_Unchargeable__c === true) {
    return 0;
  }
  // 価格区分が「一括払い／分割払い」かつ分割払い/月が「true」の場合は、分割払い価格/月(税抜) * 数量で計算する。
  else if (orderItem?.CMAP_PriceCategory__c === STRING_LUMPSUMPAYMENTINSTALLMENTPAYMENT
    && orderItem?.CMAP_InstallmentPaymentMonthly__c === true) {
    return orderItem?.CMAP_InstallmentPayMonthlyPriceTaxExc__c * (orderItem?.Quantity ?? 1);
  }
  // それ以外の場合は、単価 * 数量で計算する。
  else {
    return orderItem?.UnitPrice * (orderItem?.Quantity ?? 1);
  }
}

export const calculateAnnualPriceTaxInc = (orderItem) => {

  // 請求対象外の場合は、0を返す。
  if (orderItem?.CMAP_Unchargeable__c === true) {
    return 0;
  }
  // それ以外の場合は、年額払い価格(税込)* 数量で計算する。
  else {
    return orderItem?.CMAP_AnnualPaymentPriceTaxIncluded__c * (orderItem?.Quantity ?? 1);
  }
}

export const calculateAnnualPriceTaxExc = (orderItem) => {

  // 請求対象外の場合は、0を返す。
  if (orderItem?.CMAP_Unchargeable__c === true) {
    return 0;
  }
  // それ以外の場合は、年額払い価格(税抜) * 数量で計算する。
  else {
    return orderItem?.CMAP_AnnualPaymentPriceTaxExcluded__c * (orderItem?.Quantity ?? 1);
  }
}

export const calculateInitialCostTaxInc = (orderItem) => {

  // 請求対象外の場合は、0を返す。
  if (orderItem?.CMAP_Unchargeable__c === true) {
    return 0;
  }
  // それ以外の場合は、単価(税込)* 数量で計算する。
  else {
    return orderItem?.CMAP_UnitPriceTaxIncluded__c * (orderItem?.Quantity ?? 1);
  }
}

export const calculateInitialCostTaxExc = (orderItem) => {

  // 請求対象外の場合は、0を返す。
  if (orderItem?.CMAP_Unchargeable__c === true) {
    return 0;
  }
  // それ以外の場合は、単価 * 数量で計算する。
  else {
    return orderItem?.UnitPrice * (orderItem?.Quantity ?? 1);
  }
}

export const calculateRemainingDebtTaxInc = (orderItem) => {

  // 請求対象外の場合は、0を返す。
  if (orderItem?.CMAP_Unchargeable__c === true) {
    return 0;
  }
  // それ以外の場合は、残債(税抜)*(100+消費税率)/100の小数点以下切り捨てで計算する。
  else {
    return Math.trunc(orderItem?.CMAP_RemainingDebtTaxExcluded__c * (100 + orderItem?.CMAP_ComsumptionTaxRate__c) / 100);
  }
}

export const calculateRemainingDebtTaxExc = (orderItem) => {

  // 請求対象外の場合は、0を返す。
  if (orderItem?.CMAP_Unchargeable__c === true) {
    return 0;
  }
  // それ以外の場合は、残債(税抜)で計算する。
  else {
    return orderItem?.CMAP_RemainingDebtTaxExcluded__c;
  }
}

export const calculateTotalPrice = (orderItemInfos, calculatePriceTaxExc) => {

  const tmp = linq.from(orderItemInfos)
    .select((x) => {
      return {
        PriceTaxExc: calculatePriceTaxExc(x?.orderItem),
        CMAP_ComsumptionTaxRate__c: x?.orderItem?.CMAP_ComsumptionTaxRate__c
      }
    })
    .groupBy(
      x => x.CMAP_ComsumptionTaxRate__c, // グループ化するキー：消費税率
      x => x.PriceTaxExc, // 集計対象の値：商品毎の税抜金額
      (key, group) => ({
        CMAP_ComsumptionTaxRate__c: key, // 消費税率
        PriceTaxExc: group.sum() // 税抜金額
      }))
    .toArray();

  const reducedTaxObj = tmp.find(x => x.CMAP_ComsumptionTaxRate__c === TAX_RATE_REDUCED);
  const normalTaxObj = tmp.find(x => x.CMAP_ComsumptionTaxRate__c === TAX_RATE_NORMAL);

  const result = {
    ReducedTax: Math.trunc(reducedTaxObj?.PriceTaxExc * reducedTaxObj?.CMAP_ComsumptionTaxRate__c / 100) || 0,
    NormalTax: Math.trunc(normalTaxObj?.PriceTaxExc * normalTaxObj?.CMAP_ComsumptionTaxRate__c / 100) || 0,
    PriceTaxExc: tmp.reduce((prev, current) => prev + current.PriceTaxExc, 0),
    TotalTax: 0,
    PriceTaxInc: 0
  };

  result['TotalTax'] = result['ReducedTax'] + result['NormalTax'];
  result['PriceTaxInc'] = result['PriceTaxExc'] + result['TotalTax'];

  return result;
}

export const createSubtotalObj = (target, calculatePriceTaxExc, calculatePriceTaxInc) => {
  const result = linq.from(target)
    .select(x => {
      return {
        CMAP_MainSubProductGroupId__c: x?.orderItem?.CMAP_MainSubProductGroupId__c, // メインサブ商品グループID
        CMAP_MainSubProductGroupName__c: x?.orderItem?.CMAP_MainSubProductGroupName__c, // メインサブ商品グループ名
        CMAP_ProductCategoryId__c: x?.orderItem?.CMAP_ProductCategoryId__c, // 商品カテゴリ
        CMAP_ProductCategoryName__c: x?.orderItem?.CMAP_ProductCategoryName__c, // 商品カテゴリ名
        Product2Id: x?.orderItem?.Product2Id, // 商品ID
        CMAP_ProductName__c: x?.orderItem?.CMAP_ProductName__c,   // 商品名
        Quantity: x?.orderItem?.Quantity, // 数量
        PriceTaxExc: calculatePriceTaxExc(x?.orderItem), // 税抜金額
        PriceTaxInc: calculatePriceTaxInc(x?.orderItem), // 税込金額
        CMAP_ComsumptionTaxRate__c: x?.orderItem?.CMAP_ComsumptionTaxRate__c, // 消費税率
        CMAP_OrderItemTypeSelection__c: x?.orderItem?.CMAP_OrderItemTypeSelection__c, // 申込商品種別：選択商品
        CMAP_OrderItemTypeBenefit__c: x?.orderItem?.CMAP_OrderItemTypeBenefit__c, // 申込商品種別：特典商品
        CMAP_OrderItemTypeAutomaticSelection__c: x?.orderItem?.CMAP_OrderItemTypeAutomaticSelection__c, // 申込商品種別：自動選択商品
        CMAP_MainSubProGroupBenefitCPSortOrder__c: x?.orderItem?.CMAP_MainSubProGroupBenefitCPSortOrder__c, // メイン商品/サブ商品グループ・特典キャンペーン並び順
        CMAP_ProductCategoryBenefitSortOrder__c: x?.orderItem?.CMAP_ProductCategoryBenefitSortOrder__c, // 商品カテゴリ・特典並び順
        CMAP_ProductSortOrder__c: x?.orderItem?.CMAP_ProductSortOrder__c // 商品並び順
      };
    })
    .orderBy(x => x?.CMAP_MainSubProGroupBenefitCPSortOrder__c) // 第1ソート：メイン商品/サブ商品グループ・特典キャンペーン並び順
    .thenBy(x => x?.CMAP_ProductCategoryBenefitSortOrder__c) // 第2ソート：商品カテゴリ・特典並び順
    .thenBy(x => x?.CMAP_ProductSortOrder__c) // 第3ソート：商品並び順
    .toArray();

  return result;
}

export const createBenefitSubObj = (target) => {
  // 特典商品をソートする。
  const result = {};
  linq.from(target)
    .orderBy(x => x?.orderItem?.CMAP_MainSubProGroupBenefitCPSortOrder__c) // 第1ソート：メイン商品/サブ商品グループ・特典キャンペーン並び順
    .thenBy(x => x?.orderItem?.CMAP_ProductCategoryBenefitSortOrder__c) // 第2ソート：商品カテゴリ・特典並び順
    .thenBy(x => x?.orderItem?.CMAP_ProductSortOrder__c) // 第3ソート：商品並び順
    .forEach(x => {
      // 特典ID毎に重複しないように結果用オブジェクトに追加する。
      const key = x?.orderItem?.CMAP_BenefitId__c;
      if (!result[key]) {
        result[key] = {
          CMAP_BenefitCampaignId__c: x?.orderItem?.CMAP_BenefitCampaignId__c,
          CMAP_BenefitCampaignName__c: x?.orderItem?.CMAP_BenefitCampaignName__c,
          CMAP_BenefitId__c: x?.orderItem?.CMAP_BenefitId__c,
          CMAP_BenefitName__c: x?.orderItem?.CMAP_BenefitName__c,
          CMAP_MainSubProGroupBenefitCPSortOrder__c: x?.orderItem?.CMAP_MainSubProGroupBenefitCPSortOrder__c,
          CMAP_ProductCategoryBenefitSortOrder__c: x?.orderItem?.CMAP_ProductCategoryBenefitSortOrder__c,
          CMAP_ProductSortOrder__c: x?.orderItem?.CMAP_ProductSortOrder__c
        }
      }
    });

  // 配列に変換する。
  return Object.keys(result).map(key => result[key]);
}

export const groupByGroup = (calcObj, groupPropName = "CMAP_MainSubProductGroupName__c") => {
  const result = _.groupBy(calcObj, groupPropName);
  return result;
}

export const groupByGroupAndCat = (calcObj, groupPropName = "CMAP_MainSubProductGroupName__c", catPropName = "CMAP_ProductCategoryName__c") => {
  const result = _.groupBy(calcObj, groupPropName);
  for (const group of Object.keys(result)) {
    result[group] = _.groupBy(result[group], catPropName);
  }
  return result;
}