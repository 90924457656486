import React from "react";
import { Text, Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const C09 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  // @Model
  const {
    titleText,
    descriptionText,
    extraDescriptionText,
    descriptionText2,
    extraDescriptionText2,
    isHidden,
  } = properData;
  const hasPlaceholder = props?.rendering?.placeholders !== undefined;
  // @Controller

  // @View
  return (
    <React.Fragment>
      <div className="decoration-content service C-09">
        <h5>
          <Text field={fillBlank(titleText)} />
        </h5>
        <div className="information">
          {descriptionText?.value || extraDescriptionText?.value ? (
            <dl>
              <dt>
                <Text field={descriptionText} />
              </dt>
              <dd>
                <Text field={extraDescriptionText} />
              </dd>
            </dl>
          ) : (
            ""
          )}

          {descriptionText2?.value || extraDescriptionText2?.value ? (
            <dl>
              <dt>
                <Text field={descriptionText2} />
              </dt>
              <dd>
                <Text field={extraDescriptionText2} />
              </dd>
            </dl>
          ) : (
            ""
          )}

          {hasPlaceholder && !isHidden ? (
            <dl>
              <dt>
                <Placeholder name="link-section" rendering={props.rendering} />
              </dt>
              <dd></dd>
            </dl>
          ) : (
            ""
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default C09;
