import React, { useEffect } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
} from "../../utils/commonUtils";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import {
  STRING_APPLY,
  STRING_CHANGEPLAN,
  STRING_CORPORATION,
  STRING_MOVING,
  STRING_REBUILDING,
  STRING_SIMULATOR,
} from "../../utils/constantVariables";
import { getSessionStorageItem } from "../../utils/useSessionStorage";

export function RegisterCorporateCustomerInfo(props) {
  const {
    orderRelatedInfo,
    inputValues,
    pageContext,
    setInputValues,
    onChangeEventInput,
    isNotEncrypt,
    isFirstTimeDisplay,
    retrieveCustomerInfo,
    cMAPContractName,
    urlParentItem,
    isLoginStatus,
  } = props;

  const registerCorporateCustomerInfo = getScDataFromPlaceholder(
    "corporate-customer-info-section",
    props
  );

  const ssnCorporationName = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CORPORATIONNAME,
    isNotEncrypt
  );
  const ssnCorporationNameKana = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CORPORATIONNAMEINKANA,
    isNotEncrypt
  );
  const ssnDepartmentName = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_DEPARTMENTNAME,
    isNotEncrypt
  );
  const ssnPersonInChargeLastName = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_NAMEOFPERSONINCHARGELASTNAME,
    isNotEncrypt
  );
  const ssnPersonInChargeFirstName = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_NAMEOFPERSONINCHARGEFIRSTNAME,
    isNotEncrypt
  );
  const ssnPersonInChargeLastNameFurigana = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_NAMEOFPERSONINCHARGELASTNAMEFURIGANA,
    isNotEncrypt
  );
  const ssnPersonInChargeFirstNameFurigana = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_NAMEOFPERSONINCHARGEFIRSTNAMEFURIGANA,
    isNotEncrypt
  );

  const [
    corporateNameField,
    corporateNameFuriganaField,
    departmentNameField,
    personInChargeNameField,
    personInChargeNameFuriganaField,
  ] = getScDataFromComponent("I-01", registerCorporateCustomerInfo);

  corporateNameField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  corporateNameFuriganaField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  departmentNameField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  personInChargeNameField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };

  personInChargeNameFuriganaField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };

  let corporateCustomerInfoCorporateName = "",
    corporateCustomerInfoCorporateNameFurigana = "",
    corporateCustomerInfoDepartmentName = "",
    corporateCustomerInfoPersonInChargeLastName = "",
    corporateCustomerInfoPersonInChargeFirstName = "",
    corporateCustomerInfoPersonInChargeLastNameFurigana = "",
    corporateCustomerInfoPersonInChargeFirstNameFurigana = "";

  const orderRelatedInfoAccount =
    orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account;

  useEffect(() => {
    if (isFirstTimeDisplay) {
      if (
        (urlParentItem === STRING_APPLY ||
          urlParentItem === STRING_SIMULATOR) &&
        isLoginStatus
      ) {
        const account = retrieveCustomerInfo?.accountInfo?.account;
        if (account) {
          corporateCustomerInfoCorporateName = account?.LastName;
          corporateCustomerInfoCorporateNameFurigana =
            account?.CMAP_CorporateGroupName__c;
          corporateCustomerInfoDepartmentName = account?.CMAP_DepartmentName__c;
          corporateCustomerInfoPersonInChargeLastName =
            account?.CMAP_ContactPersonFamilyName__c;
          corporateCustomerInfoPersonInChargeFirstName =
            account?.CMAP_ContactPersonName__c;
          corporateCustomerInfoPersonInChargeLastNameFurigana =
            account?.CMAP_ContactPersonLastNameKana__pc;
          corporateCustomerInfoPersonInChargeFirstNameFurigana =
            account?.CMAP_ContactPersonFirstNameKana__pc;
        }
      } else if (
        urlParentItem !== STRING_APPLY &&
        urlParentItem !== STRING_SIMULATOR
      ) {
        corporateCustomerInfoCorporateName = orderRelatedInfoAccount?.LastName;
        corporateCustomerInfoCorporateNameFurigana =
          orderRelatedInfoAccount?.CMAP_CorporateGroupName__c;
        corporateCustomerInfoDepartmentName =
          orderRelatedInfoAccount?.CMAP_DepartmentName__c;
        corporateCustomerInfoPersonInChargeLastName =
          orderRelatedInfoAccount?.CMAP_ContactPersonFamilyName__c;
        corporateCustomerInfoPersonInChargeFirstName =
          orderRelatedInfoAccount?.CMAP_ContactPersonName__c;
        corporateCustomerInfoPersonInChargeLastNameFurigana =
          orderRelatedInfoAccount?.CMAP_ContactPersonLastNameKana__pc;
        corporateCustomerInfoPersonInChargeFirstNameFurigana =
          orderRelatedInfoAccount?.CMAP_ContactPersonFirstNameKana__pc;
      }
    } else {
      corporateCustomerInfoCorporateName = ssnCorporationName;
      corporateCustomerInfoCorporateNameFurigana = ssnCorporationNameKana;
      corporateCustomerInfoDepartmentName = ssnDepartmentName;
      corporateCustomerInfoPersonInChargeLastName = ssnPersonInChargeLastName;
      corporateCustomerInfoPersonInChargeFirstName = ssnPersonInChargeFirstName;
      corporateCustomerInfoPersonInChargeLastNameFurigana = ssnPersonInChargeLastNameFurigana;
      corporateCustomerInfoPersonInChargeFirstNameFurigana = ssnPersonInChargeFirstNameFurigana;
    }
  }, [retrieveCustomerInfo, isLoginStatus]);

  useEffect(() => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        registerCorporateCustomerInfoCorporateName: corporateCustomerInfoCorporateName,
        registerCorporateCustomerInfoCorporateNameFurigana: corporateCustomerInfoCorporateNameFurigana,
        registerCorporateCustomerInfoDepartmentName: corporateCustomerInfoDepartmentName,
        registerCorporateCustomerInfoPersonInChargeLastName: corporateCustomerInfoPersonInChargeLastName,
        registerCorporateCustomerInfoPersonInChargeFirstName: corporateCustomerInfoPersonInChargeFirstName,
        registerCorporateCustomerInfoPersonInChargeLastNameFurigana: corporateCustomerInfoPersonInChargeLastNameFurigana,
        registerCorporateCustomerInfoPersonInChargeFirstNameFurigana: corporateCustomerInfoPersonInChargeFirstNameFurigana,
      };
    });
  }, [retrieveCustomerInfo]);

  let isDisabled = false;
  if (
    (urlParentItem === STRING_MOVING ||
      urlParentItem === STRING_REBUILDING ||
      urlParentItem === STRING_CHANGEPLAN) &&
    cMAPContractName === STRING_CORPORATION
  ) {
    isDisabled = true;
  }

  corporateNameField.fields.inputValue = {
    value: inputValues.registerCorporateCustomerInfoCorporateName,
    name: "registerCorporateCustomerInfoCorporateName",
    isDisabled: isDisabled,
  };

  corporateNameFuriganaField.fields.inputValue = {
    value: inputValues.registerCorporateCustomerInfoCorporateNameFurigana,
    name: "registerCorporateCustomerInfoCorporateNameFurigana",
    isDisabled: isDisabled,
  };

  departmentNameField.fields.inputValue = {
    value: inputValues.registerCorporateCustomerInfoDepartmentName,
    name: "registerCorporateCustomerInfoDepartmentName",
    isDisabled: isDisabled,
  };

  const personInChargeNameData = [
    {
      fields: {
        Value: {
          value:
            pageContext[
              "RegisterCorporateCustomerInfo-PersonInChargeLastNamePlaceholder"
            ]?.value,
        },
        inputValue: {
          value:
            inputValues.registerCorporateCustomerInfoPersonInChargeLastName,
          name: "registerCorporateCustomerInfoPersonInChargeLastName",
        },
      },
    },
    {
      fields: {
        Value: {
          value:
            pageContext[
              "RegisterCorporateCustomerInfo-PersonInChargeFirstNamePlaceholder"
            ]?.value,
        },
        inputValue: {
          value:
            inputValues.registerCorporateCustomerInfoPersonInChargeFirstName,
          name: "registerCorporateCustomerInfoPersonInChargeFirstName",
        },
      },
    },
  ];

  personInChargeNameField.fields.dataRowList = personInChargeNameData;
  personInChargeNameField.fields.inputValue = { isDisabled: isDisabled };

  const personInChargeNameFuriganaData = [
    {
      fields: {
        Value: {
          value:
            pageContext[
              "RegisterCorporateCustomerInfo-PersonInChargeLastNameFuriganaPlaceholder"
            ]?.value,
        },
        inputValue: {
          value:
            inputValues.registerCorporateCustomerInfoPersonInChargeLastNameFurigana,
          name: "registerCorporateCustomerInfoPersonInChargeLastNameFurigana",
        },
      },
    },
    {
      fields: {
        Value: {
          value:
            pageContext[
              "RegisterCorporateCustomerInfo-PersonInChargeFirstNameFuriganaPlaceholder"
            ]?.value,
        },
        inputValue: {
          value:
            inputValues.registerCorporateCustomerInfoPersonInChargeFirstNameFurigana,
          name: "registerCorporateCustomerInfoPersonInChargeFirstNameFurigana",
        },
      },
    },
  ];

  personInChargeNameFuriganaField.fields.dataRowList = personInChargeNameFuriganaData;
  personInChargeNameFuriganaField.fields.inputValue = {
    isDisabled: isDisabled,
  };

  return (
    <div className="form_wrapper-input" name="corporate-customer-info-section">
      <Placeholder
        name="corporate-customer-info-section"
        rendering={props.rendering}
      />
    </div>
  );
}
