import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import { getURLType, redirectToLinkUrl } from "../../utils/targetUrlType";

const CPadToolButton = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { profileImage, imagePosition, buttonData } = properData;

  return (
    <React.Fragment>
      <div className="profile-button_container">
        {imagePosition.value === "Left" && (
          <div className="profile_img">
            <img src={fillBlank(profileImage.value.src)?.value} />
          </div>
        )}

        <div className="btn_simple-body">
          {buttonData?.map((item, index) => {
            if (!item.text?.value) {
              return (
                <div className="btn_simple_wrapper" key={"toolButton_" + index}>
                  <a
                    onClick={() =>
                      redirectToLinkUrl(
                        item.linkUrl?.value,
                        getURLType(item.urlType)
                      )
                    }
                  >
                    <button
                      className={`btn btn_simple btn_approach ${item.buttonColor?.value}`}
                      type="button"
                    >
                      <img src={fillBlank(item.image.value.src)?.value} />
                    </button>
                  </a>
                </div>
              );
            } else {
              return (
                <div className="btn_simple_wrapper" key={"toolButton_" + index}>
                  <a
                    onClick={() =>
                      redirectToLinkUrl(
                        item.linkUrl?.value,
                        getURLType(item.urlType)
                      )
                    }
                  >
                    <button
                      className={`btn btn_simple ${item.buttonColor?.value}`}
                      type="button"
                    >
                      {item.text?.value}
                    </button>
                  </a>
                </div>
              );
            }
          })}
        </div>

        {imagePosition.value === "Right" && (
          <div className="profile_img">
            <img src={fillBlank(profileImage.value.src)?.value} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CPadToolButton;
