export const query = `fragment MemberRegisterType on Item {
  ... on VersatileMaster  {
    code  {
      value
    }
    value {
      value
    }
  }
}

query errorMessageQuery {
  item(path : "/sitecore/content/jss/masterjss/Data/CTC Master/MemberRegisterTypeMaster") {
    children {
      ...MemberRegisterType
    }
  }
}`;
