import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import { STRING_MYCOMMUFFA_CAROUSEL_PLACEHOLDER } from "../../utils/constantVariables";
import { getSessionStorageItem } from "../../utils/useSessionStorage";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { CommonCarousel } from "../../services/common/common-carousel";
import { CommonUsageFee } from "../../services/common/common-usage-fee";
import { CommonInformationList } from "../../services/common/common-information-list";
import { CommonMaintenanceList } from "../../services/common/common-maintenance-list";
import { CommonTroubleList } from "../../services/common/common-trouble-list";
import { CommonMenuButtonList } from "../../services/common/common-menu-button-link";

const MyCommufaTopPageLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  const personalizeInfoData = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );

  return (
    <React.Fragment>
      <main>
        {personalizeInfoData && personalizeInfoData?.Contract?.length > 0 && (
          <>
            <CommonTroubleList
              rendering={props.rendering}
              sitecoreContext={sitecoreContext}
              contractInfo={personalizeInfoData?.Contract}
              setIsLoading={props.setIsLoading}
            />
            <CommonMaintenanceList
              rendering={props.rendering}
              sitecoreContext={sitecoreContext}
              contractInfo={personalizeInfoData?.Contract}
              setIsLoading={props.setIsLoading}
            />
          </>
        )}
        <CommonCarousel
          rendering={props.rendering}
          sectionName={STRING_MYCOMMUFFA_CAROUSEL_PLACEHOLDER}
          multipleSlide={true}
          sitecoreContext={sitecoreContext}
        />
        <CommonUsageFee
          rendering={props.rendering}
          sitecoreContext={sitecoreContext}
          setIsLoading={props.setIsLoading}
        />
        <CommonMenuButtonList
          rendering={props.rendering}
          sitecoreContext={sitecoreContext}
          personalizeInfoData={personalizeInfoData}
          setIsLoading={props.setIsLoading}
        />
        <CommonInformationList
          rendering={props.rendering}
          sitecoreContext={sitecoreContext}
          setIsLoading={props.setIsLoading}
        />
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(MyCommufaTopPageLayout);
