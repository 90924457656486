import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const S08 = (props) => {
  const { tagLabel, titleText, descriptionText } = props.fields;

  return (
    <React.Fragment>
      <div className="input-form">
        <div className="input-box with-tag S-08">
          <div className="input-container">
            <input type="radio" name="rdOptionS08" />
            <label htmlFor="rdOptionS08">
              <div className="tag P-03">
                <span>
                  <Text field={fillBlank(tagLabel)} />
                </span>
              </div>
              <Text field={fillBlank(titleText)} />
              <span>
                <Text field={fillBlank(descriptionText)} />
              </span>
            </label>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default S08;
