export const query = `query {
  SimpleOpApplicationImportantMatters: item(path: "/sitecore/content/jss/masterjss/Data/SimpleOpApplicationImportantMatters") {
    children {
      fields(excludeStandardFields: true) {
        name
        value
        rendered
        ...on LookupField {
          targetItem {
            field(name: "SimpleOpApplicationTitle-Title") {
              value
            }
          }
        }
      }
      children {
        fields(excludeStandardFields: true) {
          name
          value
          ...on LookupField {
          targetItem {
            field(name: "SimpleOpApplicationTitle-Title") {
              value
            }
          }
        }
      }
    }
  }
  }
}`;
