import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const FT02 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    MultilistItems
  } = properData;
  let properMultilistItems = [];
  for (let i = 0; i < MultilistItems?.length; i++) {
    properMultilistItems[i] = getProperData(sitecoreContext, MultilistItems[i].fields);
  }

  // @View
  return (
    <React.Fragment>
      <footer>
        <div className="footer mFT-02">
          <div className="footer_pos">
            <div className="footer_pos-left">
              {properData && properData["mycommufaFooter-Icon"]?.value?.src && (
                <img
                  src={
                    properData && properData["mycommufaFooter-Icon"]?.value?.src
                  }
                  alt="footer_img"
                />
              )}
            </div>
            <div className="footer_pos-right">
              <ul>
                {
                  properMultilistItems.map((item, i) => (
                    <li key={i}>
                      <a href={item.Link.value.href} target={item.Link.value.target != null || undefined ? item.Link.value.target : "_self"}><Text field={fillBlank(item.LinkText)} /></a>
                    </li>
                  ))
                }
              </ul>
              <p>
                {properData &&
                  properData["mycommufaFooter-Copyright"]?.value}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default FT02;
