import React from "react";
import { fillBlank, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const PG01 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);

  const { arrowIcon } = properData;

  // @View
  return (
    <React.Fragment>
      <div className="page-top PG-01">
        <a href="#">
          <span className="arrow-up">
            <img src={fillBlank(arrowIcon)?.value?.src} />
          </span>
        </a>
      </div>
    </React.Fragment>
  );
};

export default PG01;
