import React from "react";
// Common pages
import { CommonApplicationCompletedLayout } from "../../../src/services/common/common-application-completed";
import { di_register } from "../../utils/di";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

const ApplicationCancelCompletedLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);
  // @View
  return (
    <main>
      <div className="form_container">
        <div className="form_detail full-width">
          <div className="form_wrapper">
            <CommonApplicationCompletedLayout
              rendering={props.rendering}
              sitecoreContext={sitecoreContext}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default withSitecoreContext()(ApplicationCancelCompletedLayout);
