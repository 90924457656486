import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import { HandlingPersonalInfo } from "../../services/common/common-handling-personal-info";

const HandlingPersonalInfoLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width no-text-ident">
            <div className="form_wrapper">
              <HandlingPersonalInfo
                rendering={props.rendering}
                setIsLoading={props.setIsLoading}
              />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(HandlingPersonalInfoLayout);
