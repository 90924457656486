import React, { useState, useEffect, useContext } from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import ModalContext from "../../assets/context/ModalContext";
import { query as querySimpleOpApplication } from "../../assets/graphql/SimpleOpApplication";
import { query as querySimpleOpApplicationAttr } from "../../assets/graphql/SimpleOpApplicationAttr";
import { query as querySimpleOpApplicationCustomerInfo } from "../../assets/graphql/SimpleOpApplicationCustomerInfo";
import { query as querySimpleApplicationDropDown } from "../../assets/graphql/SimpleApplicationDropDown";
import {
  useGetDataViaGraphQL as UseGetDataViaGraphQL,
  useGetDataViaGraphQLDelayedQuery as UseGetDataViaGraphQLDelayedQuery,
} from "../../hooks/useGetData";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  deepcopy,
  generateNumberArray,
  redirectToLinkUrl,
  generateGUID,
  getParamValue,
  isInvalidLoginStatus,
} from "../../utils/commonUtils";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { getSessionStorageItem, setSessionStorageItem } from "../../utils/useSessionStorage";
import { format } from "date-fns";
import {
  QUANTITY_DROPDOWN_VALUE,
  STRING_LOADING,
  STRING_METHOD_SELECTABLEOPTION,
  STRING_GET,
  STRING_POST,
  STRING_VALUE,
  STRING_DESCRIPTION,
  STRING_ANNUALPAYMENT,
  STRING_MONTHLYPAYMENT,
  STRING_LUMPSUMPAYMENT,
  STRING_SIXTY_INSTALLPAYMENT,
  STRING_SIMPLE_OP_APPLICABLE_MINDATE,
  STRING_ERROR_500_URI,
  STRING_SIMPLE_OP_SESSION_KEY,
  STRING_SIMPLE_OP_WEB_APPLIACTION,
  STRING_SIMPLE_OP_LOGIN_USER,
  STRING_SIMPLE_OP_NOT_LOGIN_USER,
  STRING_SIMPLE_OP_CONFIRMATION_TEXT,
} from "../../utils/constantVariables";
import {
  validateIsNumber,
  validateIsFixedLength,
  validateEmailAddress,
} from "../../utils/validations";
import { useCustomQuery } from "../../hooks/useGetData";
import { apiBaseURL } from "../../envSettings";
import { HandlingPersonalInfo } from "../../services/common/common-handling-personal-info";
import { ApplyButtonContext } from "../../assets/context/ApplyButtonContext";
import { useFormDataStore, useCustomerInfoStore } from "../../hooks/useSimpleOpState";

const SimpleOpApplicationLayout = (props) => {

  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);
  const pageFields = sitecoreContext?.route?.fields;

  const { dispatch } = useContext(ModalContext);
  const [isStep1Rendered, setIsStep1Rendered] = useState(false);
  const [isStep2Rendered, setIsStep2Rendered] = useState(false);
  const [isStep3Rendered, setIsStep3Rendered] = useState(false);
  const [sectionChangeNum, setSectionChangeNum] = useState(0);
  const [productSelections, setProductSelections] = useState({});
  const [isStep1Done, setIsStep1Done] = useState(false);
  const [step1BackUp, setIsStep1BackUp] = useState([]);
  const [isStep2Done, setIsStep2Done] = useState(false);
  const [step2BackUp, setIsStep2BackUp] = useState([]);
  const [step3BackUp, setIsStep3BackUp] = useState([]);
  const [isNoInputProduct, setIsNoInputProduct] = useState(false);
  const [requiredAttrFields, setRequiredAttrFields] = useState([]);
  const [requiredCustomerInfoFields, setRequiredCustomerInfoFields] = useState(
    []
  );
  const [isLogged, setIsLogged] = useState(false);
  const [isPersonalizeCalled, setIsPersonalizeCalled] = useState(false);
  const [isProceedingStep2, setIsProceedingStep2] = useState(false);
  const [isProceedingStep3, setIsProceedingStep3] = useState(false);
  const [validFieldsStep2, setValidFieldsStep2] = useState(false);
  const [validFieldsStep3, setValidFieldsStep3] = useState(false);
  const {
    setFormDataEmail,
    setFormDataTelephone,
    setFormDataInput,
    formData,
    setFormData
  } = useFormDataStore((state) => state);
  const {
    setCustomerInfoEmail,
    setCustomerInfoTelephone,
    setCustomerInfoInput,
    customerInfo,
    setCustomerInfo
  } = useCustomerInfoStore((state) => state);
  const paymentMethodChangeQuery = UseGetDataViaGraphQL(
    querySimpleOpApplication,
    null,
    "SimpleOpApplication"
  );

  const SimpleOpApplicationAttrQuery = UseGetDataViaGraphQL(
    querySimpleOpApplicationAttr,
    null,
    "SimpleOpApplicationAttr"
  );

  const SimpleOpApplicationCustomerInfoQuery = UseGetDataViaGraphQL(
    querySimpleOpApplicationCustomerInfo,
    null,
    "SimpleOpApplicationCustomerInfo"
  );

  const SimpleApplicationDropDownItemSearchQuery = UseGetDataViaGraphQL(
    querySimpleApplicationDropDown,
    null,
    "SimpleApplicationDropdownItems"
  );

  // Retrieve login state
  const retrieveLoginStateURI = `${apiBaseURL}common/RetrieveLoginStatus`;
  const {
    data: retrieveLoginStatusData,
    isLoading: retrieveLoginStatusLoading,
  } = useCustomQuery(retrieveLoginStateURI, true, STRING_GET);

  const registerSimpleOpSessionURI = `${apiBaseURL}simpleop/RegisterSimpleOpSession`;
  const {
    data: registerSimpleOpSessionData,
    isLoading: registerSimpleOpSessionIsLoading,
    error: registerSimpleOpSessionError,
    refetch: registerSimpleOpSessionRefresh,
  } = useCustomQuery(
    registerSimpleOpSessionURI,
    false,
    STRING_POST,
    { "key": STRING_SIMPLE_OP_SESSION_KEY, "value": "true" },
  );

  const retrieveServerSessionInfoURI = `${apiBaseURL}common/RetrieveServerSessionInfo`;
  const {
      data: serverSessionInfoData,
      isLoading: serverSessionInfoIsLoading,
      error: serverSessionInfoError,
      refetch: serverSessionInfoRefresh,
      remove: serverSessionInfoRemove,
  } = useCustomQuery(
      retrieveServerSessionInfoURI,
      false,
      STRING_GET
  );

  const redirectToPreviousPage = useRedirectToPreviousPage();

  const transformedData = (data, stringReplace) =>
    data?.reduce((acc, curr) => {
      if (curr.targetItem?.Value?.value || curr.targetItem?.Description?.value) {
        acc[curr.name?.replace(`${stringReplace}-`, "")] =
          curr.targetItem?.Value?.value;
        acc[`${curr.name?.replace(`${stringReplace}-`, "")}Label`] =
          curr.targetItem?.Description?.value;
      } else if (curr.name === `${stringReplace}-Image` || curr.name === `${stringReplace}-ImageForSp`) {
        const temp = document.createElement('div');
        temp.innerHTML = curr.rendered;
        var imgSrc = temp.firstChild?.getAttribute("src");
        acc[curr.name?.replace(`${stringReplace}-`, "")] = imgSrc;
      } else {
        acc[curr.name?.replace(`${stringReplace}-`, "")] = curr.value;
      }
      return acc;
    }, {});

    const fixProductAttributeFormat = (data) => {
      const result = [];
      if (!data?.children) {
        return result;
      }
    
      data.children.forEach((child) => {
        if (!child?.fields) return;
    
        const parentField = child.fields.find(
          field => field.name === "SimpleOpApplicationAttrTitle-Parent"
        );
        const titleField = child.fields.find(
          field => field.name === "SimpleOpApplicationAttrTitle-Title"
        );
    
        const entry = {
          parent: parentField?.targetItem?.Title?.value || "",
          label: titleField?.value || "",
          child: []
        };
    
        if (!child.children) {
          result.push(entry);
          return;
        }
    
        child.children.forEach((grandChild) => {
          if (!grandChild?.fields) return;
    
          const transformedObject = {};
    
          grandChild.fields.forEach((field) => {
            if (!field) return;
    
            const fieldName = field.name.replace("SimpleOpApplicationAttr-", "");
            const fieldValue = field.targetItem?.Value?.value || field.value;
            transformedObject[fieldName] = fieldValue;
    
            if (fieldName === "Type" && field.targetItem?.Description?.value) {
              transformedObject["TypeLabel"] = field.targetItem.Description.value;
            }
          });
          entry.child.push(transformedObject);
        });
        result.push(entry);
      });
      return result;
    };

  const fixDataFormat = (data, replaceString, type) => {
    if (type == "step3") {
      return data?.children?.map(({ fields }) => {
        return {
          ...transformedData(fields, replaceString)
        };
      });
    } else {
      return data?.children?.map(({ fields, children }) => {
        return fields.reduce((a, field) => {

          if (field.name === "SimpleOpApplicationTitle-NotApply") {
            a.NotApply = field.value || undefined;
          }
          else if (field.name === "SimpleOpApplicationTitle-PrecautionText") {
            a.PrecautionText = field.value || undefined;
          }
          else {
            a.label = field.value;
          }
          a.child = children?.map(({ fields, children }) => {
            if (children && children.length > 0) {
              const mapSubChild = children?.map(({ fields }) => {
                return {
                  ...transformedData(fields, "SimpleOpApplicationProduct")
                };
              });
              return {
                ...transformedData(fields, replaceString),
                subChild: mapSubChild
              };
            } else {
              return {
                ...transformedData(fields, replaceString)
              };
            }
          });
          return a;
        }, {});
      });
    }
  };

  const initDefaultSelection = (paymentInfo) => {
    let initArr = [];
    if (paymentInfo?.length) {
      paymentInfo.forEach(paymentInfo => {
        const paymentInfoMap = paymentInfo.product.map(
          selectedProd => ({
            name: selectedProd?.name,
            quantity: selectedProd?.quantity,
            isSelectedAlternativePayment: selectedProd?.isSelectedAlternativePayment,
          })
        );
        initArr.push(...paymentInfoMap);
      });
    }
    return initArr;
  }

  const handleSelectionChange = (
    parentName,
    selectedChild,
    selectionType,
    parentSortOrder,
  ) => {
    setProductSelections((prevFormData) => ({
      ...prevFormData,
      [parentName]: {
        title: parentName,
        product: selectedChild,
        selectionType: selectionType,
        sortOrder: parentSortOrder,
      },
    }));
  };

  const sortProducts = (productData) => {
    if (productData?.length > 0) {
      productData.forEach(
        (titleItem, titleIndex) => {
          titleItem.sortOrder = titleIndex*10000;
          const items = titleItem?.child;
          if (items) {
            items.forEach(
              (item, itemIndex) => {
                item.sortOrder = titleIndex*10000 + itemIndex*100;
                const subItems = item?.subChild;
                if (subItems) {
                  subItems.forEach(
                    (subItem, subItemIndex) => {
                      subItem.sortOrder = titleIndex*10000 + itemIndex*100 + subItemIndex;
                    }
                  );
                }
              }
            );
          }
        }
      )
    }
  };

  const filterProductAttributes = (attrList, isLoggedIn) => {
    const filterKey = isLoggedIn ? "IsLoggedIn" : "IsNotLoggedIn";
    return attrList.filter(item => item?.[filterKey] === "1");
  }

  const handleEmail = (name, value, type) => {
    const email = [
      "emailAddress",
      "emailDomain",
      "emailAddressConfirmation",
      "emailDomainConfirmation",
      "authCode",
      "isVerified",
    ];
    const inputValue = value.split("@");

    email.forEach((item, i) => {
      let newValue;
      if (item === "isVerified") {
        newValue = true;
      }
      else if (item.includes("emailAddress")) {
        newValue = inputValue[0];
      }
      else if (item.includes("emailDomain")) {
        newValue = inputValue[1];
      }
      const currentTarget = {
        target: {
          name: item,
          value: newValue,
        }
      }
      if (type === "productAttributes") {
        setFormDataEmail(currentTarget, name);
      } else {
        setCustomerInfoEmail(currentTarget, name);
      }

    });
  }
  const handleTelephone = (name, value, type) => {
    const telephone = ["PhoneNumber1", "PhoneNumber2", "PhoneNumber3"];
    const inputValue = value.split("-");
    telephone.forEach((item, i) => {
      const currentTarget = {
        target: {
          name: item,
          value: inputValue[i]
        }
      }
      if (type === "productAttributes") {
        setFormDataTelephone(currentTarget, name);
      } else {
        setCustomerInfoTelephone(currentTarget, name);
      }
    });
  }

  const handleInput = (name, value) => {
    return {
      target: {
        name,
        value
      }
    }
  }

  const categorizeProductList = (productData, paymentType) => {
    const categorizedData = productData.map(item => {
      return {
        ...item,
        product: item.product.filter(product =>
          (
            product.isSelectedAlternativePayment &&
            product.alternativePaymentType === paymentType
          )
          || (
            !product.isSelectedAlternativePayment &&
            product.paymentType === paymentType
          )
        )
      };
    });

    return categorizedData.filter(
      product => product?.product?.length > 0
    );
  };

  const updateNotApplyDefaultValue = (personalInfo) => {
    if (personalInfo) {
      personalInfo["OptionNotApply"] = personalInfo["OptionNotApply"] ?? true;
      personalInfo["DekitusNotApply"] = personalInfo["DekitusNotApply"] ?? true;
      personalInfo["DAZNNotApply"] = personalInfo["DAZNNotApply"] ?? true;
      personalInfo["NetflixNotApply"] = personalInfo["NetflixNotApply"] ?? true;
      personalInfo["GigazouNotApply"] = personalInfo["GigazouNotApply"] ?? true;
      personalInfo["GamingNotApply"] = personalInfo["GamingNotApply"] ?? true;
    }
    return personalInfo;
  };

  const filterNotApplyProducts = (personalizeInfo) => {

    let products = getParamValue("productId")?.split(",")?.map(item => ({
      name: item,
      quantity: "1"
    })) ?? [];
    
    const filters = [
      { key: 'DekitusNotApply', keyword: 'dekitus' },
      { key: 'DAZNNotApply', keyword: 'dazn' },
      { key: 'NetflixNotApply', keyword: 'netflix' },
      { key: 'GigazouNotApply', keyword: 'gigazou' },
      { key: 'GamingNotApply', keyword: 'gamingcustom' }
    ];
    
    if (personalizeInfo?.OptionNotApply) {
      return [];
    }

    filters.forEach(filter => {
      if (personalizeInfo?.[filter.key]) {
        products = products.filter(product => !product?.name?.includes(filter.keyword));
      }
    });

    return products;
  };

  //STEP 1
  const SimpleOpApplicationData = fixDataFormat(
    paymentMethodChangeQuery?.SimpleOpApplication,
    "SimpleOpApplication"
  );
  sortProducts(SimpleOpApplicationData);

  let personalizeInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );

  const SimpleOpSessionData = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_SIMPLEOP_ORDERINFO,
    true
  );

  // Show modal widow if user isn't logged in.
  useEffect(() => {

    setIsLogged(retrieveLoginStatusData?.Result ?? false);
    if (
      retrieveLoginStatusData !== undefined &&
      !retrieveLoginStatusData?.Result
    ) {
      dispatch({ type: "SHOW_MODAL", payload: { id: "login_warning_modal" } });
    }

    if (retrieveLoginStatusData?.Result) {
      serverSessionInfoRemove();
      serverSessionInfoRefresh();
    }
  }, [retrieveLoginStatusData]);

  //check if there's productId from getParams and SimpleOpApplicationData then add default values
  useEffect(() => {
    if (
      SimpleOpApplicationData?.length &&
      Object.keys(productSelections).length === 0 &&
      SimpleOpSessionData === "" &&
      isPersonalizeCalled
    ) {
      let productIds = filterNotApplyProducts(personalizeInfo);

      const defaultProducts = handleDefaultSelectedProducts(
        SimpleOpApplicationData,
        productIds
      );

      setProductSelections(defaultProducts);
    } 
  }, [isPersonalizeCalled]);
  
  
  //check if there's productId from getParams and SimpleOpApplicationData then add default values
  useEffect(() => {
    if (SimpleOpApplicationData?.length && Object.keys(productSelections).length === 0) {
      let selectedItemSession = [];
      if (SimpleOpSessionData) {
        const storedSessionInfo = SimpleOpSessionData?.SimpleOpApplication;
        // populate step 1 using SSN_APPLICATION_SIMPLEOP_ORDERINFO session storage 
        selectedItemSession = [...selectedItemSession, ...initDefaultSelection(storedSessionInfo?.monthlyPayment)];
        selectedItemSession = [...selectedItemSession, ...initDefaultSelection(storedSessionInfo?.annualPayment)];
        selectedItemSession = [...selectedItemSession, ...initDefaultSelection(storedSessionInfo?.lumpSumPayment)];
        selectedItemSession = [...selectedItemSession, ...initDefaultSelection(storedSessionInfo?.sixtyInstallmentPayment)];
      }
      
      if (selectedItemSession?.length > 0) {
        const selectedSessionProducts = handleDefaultSelectedProducts(
          SimpleOpApplicationData,
          selectedItemSession
        )
  
        setProductSelections(selectedSessionProducts);
      }
    }
  }, [SimpleOpApplicationData, productSelections]);

  useEffect(() => {

    if (
      SimpleOpApplicationData &&
      SimpleOpApplicationData.length > 0 &&
      !isStep1Rendered &&
      isPersonalizeCalled &&
      Object.keys(productSelections).length !== 0
    ) {
      let tmpS17DataCopy = [];

      const originalComponents = [
        productSelectionSection[0],
        productSelectionSection[1],
        productSelectionSection[2],
        productSelectionSection[3],
      ];
      setIsStep1BackUp(originalComponents);
      productSelectionSection.splice(-2);
      SimpleOpApplicationData.forEach((productItem) => {
        //Clone H-02 (Header Title)
        const h02DataCopy = deepcopy(h02Step1Data[1]);
        if (h02DataCopy) {
          h02DataCopy.uid = generateGUID();
          h02DataCopy.fields.titleText = productItem?.label;
          h02DataCopy.fields.additionalClass = { value: "" };
          productSelectionSection.push(h02DataCopy);

          //Clone S-18
          const s18DataCopy = deepcopy(s18Data);
          s18DataCopy.uid = generateGUID();
          s18DataCopy.fields.data = productItem?.child;
          s18DataCopy.fields.productName = productItem?.label;
          tmpS17DataCopy = s18DataCopy;
          const precautionText = productItem?.PrecautionText;
          if (precautionText) {
            s18DataCopy.fields.precautionText = precautionText;
          }

          const notApply = productItem?.NotApply;
          if (
            (personalizeInfo?.OptionNotApply ?? true) ||
            (productItem?.NotApply && personalizeInfo[notApply])) {
            s18DataCopy.fields.data = [];
            s18DataCopy.fields.emptyChildText = pageFields["SelectProductSection-NotApplyText"]?.value;
          }

          if (productSelections.hasOwnProperty(productItem?.label)) {
            s18DataCopy.fields.defaultValues =
              productSelections[productItem?.label];
          }

          s18DataCopy.fields.onChangeEvent = (
            parentName,
            selectedChild,
            selectionType,
          ) => {
            handleSelectionChange(
              parentName,
              selectedChild,
              selectionType,
              productItem.sortOrder,
            );
          };

          productSelectionSection.push(tmpS17DataCopy);
        }
      });

      setIsStep1Rendered(true);
    }

  }, [productSelections, isStep1Rendered, isPersonalizeCalled]);

  useEffect(() => {
    setValidFieldsStep2(validateCustomerInfoFields(requiredAttrFields, formData));
  }, [formData, validFieldsStep2, requiredAttrFields]);

  useEffect(() => {
    setValidFieldsStep3(validateCustomerInfoFields(requiredCustomerInfoFields, customerInfo));
  }, [customerInfo, validFieldsStep3, requiredCustomerInfoFields]);

  let productSelectionSection = getScDataFromPlaceholder(
    "product-selection-section",
    props
  );
  const inputProductAttrSection = getScDataFromPlaceholder(
    "input-product-attribute-section",
    props
  );
  
  const step1ButtonSection = getScDataFromPlaceholder("button-section", props);
  const modalSection = getScDataFromPlaceholder("modal-section", props);
  const h02Step1Data = getScDataFromComponent("H-02", productSelectionSection);
  const [s18Data] = getScDataFromComponent("S-18", productSelectionSection);
  const [modalData] = getScDataFromComponent("P0-01", modalSection);
  const [agreementContext, setAgreementContext] = useState(false);

  if (h02Step1Data && h02Step1Data?.length > 0) {
    h02Step1Data[0].fields.modalTarget = { value: "confirmation_modal" };
    h02Step1Data[0].fields.additionalClass = {
      value: isStep1Done ? "number entered" : "number",
    };
    h02Step1Data[0].fields.onEditButtonClick = () => {
      setSectionChangeNum(1);
    };
  }

  const [nextButtonStep1] = getScDataFromComponent("B-01", step1ButtonSection);
  const [backButtonStep1] = getScDataFromComponent("B-02", step1ButtonSection);

  const filterSelectedProducts = Object.entries(productSelections).map(
    ([key, value]) => value
  );

  let nextButtonStep1Deactivate = true;
  for (let SelectedProduct of filterSelectedProducts) {
    if (SelectedProduct.product && SelectedProduct.product.length > 0) {
      nextButtonStep1Deactivate = false;
      break;
    }
  }

  nextButtonStep1.fields.isDisabled = {
    value:
      nextButtonStep1Deactivate,
  };

  if (isStep1Done) {
    productSelectionSection.splice(1);
  }

  // =============== TODO:不要となった利用約款を削除 =============== 
  const personalInfoSection = getScDataFromPlaceholder(
    "personal-info-section-1",
    props
  );
  const modalWindowSection = getScDataFromPlaceholder(
    "modal-window-1",
    props
  );
  if (personalInfoSection?.length && modalWindowSection?.length) {
    personalInfoSection.splice(1);
    modalWindowSection.splice(0);
  }
  // =============== TODO:不要となった利用約款を削除 =============== 

  //STEP 2
  const SimpleOpApplicationAttrData = fixProductAttributeFormat(
    SimpleOpApplicationAttrQuery?.SimpleOpApplicationAttr
  );
  sortProducts(SimpleOpApplicationAttrData);

  const h02DataStep2 = getScDataFromComponent("H-02", inputProductAttrSection);
  if (h02DataStep2 && h02DataStep2?.length > 0) {
    h02DataStep2[0].fields.modalTarget = { value: "confirmation_modal" };
    h02DataStep2[0].fields.additionalClass = {
      value: isStep2Done ? "number entered" : "number",
    };
    h02DataStep2[0].fields.onEditButtonClick = () => {
      setSectionChangeNum(2);
    };
  }
  const [DateM01iData] = getScDataFromComponent(
    "M-01i",
    inputProductAttrSection
  );
  const [PullDownM01Data] = getScDataFromComponent(
    "M-01",
    inputProductAttrSection
  );
  const [EmailI06Data] = getScDataFromComponent(
    "I-06",
    inputProductAttrSection
  );
  const [PhoneI03Data] = getScDataFromComponent(
    "I-03",
    inputProductAttrSection
  );

  const createDynamicInputs = (
    productItem,
    DateM01iData,
    PullDownM01Data,
    EmailI06Data,
    PhoneI03Data,
    type,
    inputProductAttrSection,
    NameI01Data,
    titleText
  ) => {
    const productHeader = [];
    productItem.forEach((childItem, childIndex) => {
      let CloneInput;
      if (
        isInvalidLoginStatus(
          childItem?.IsLoggedIn,
          childItem?.IsNotLoggedIn,
          isLogged
        )
      ) {
        return;
      }
      //Clone H-02 (Header Title)
      if (titleText !== "" && !productHeader.includes(titleText)) {
        const h02DataCopy = deepcopy(h02DataStep2[1]);
        h02DataCopy.uid = generateGUID();
        h02DataCopy.fields.titleText = titleText;
        h02DataCopy.fields.additionalClass = { value: "" };
        inputProductAttrSection.push(h02DataCopy);
        productHeader.push(titleText);
      }

      switch (childItem.Type) {
        case "Date":
          CloneInput = deepcopy(DateM01iData);
          CloneInput.fields.titleText = { value: childItem.Title };
          CloneInput.fields.name = childItem.Name;
          CloneInput.fields.type = type;
          CloneInput.fields.onChangeEvent = (date) => {
            if (type === "customerInfo") {
              setCustomerInfoInput(format(date, "yyyy-MM-dd"), childItem.Name);
            } else {
              setFormDataInput(format(date, "yyyy-MM-dd"), childItem.Name);
            }

          };
          let { minDate, maxDate } = getMinAndMaxDates(new Date());
          CloneInput.fields.minDate = minDate.toISOString().split("T")[0];
          CloneInput.fields.maxDate = maxDate.toISOString().split("T")[0];
          break;

        case "NumberDropdownList":
          CloneInput = deepcopy(PullDownM01Data);
          CloneInput.fields.isNeedEmptyPlaceholder = true;
          CloneInput.fields.name = childItem.Name;
          CloneInput.fields.type = type;
          CloneInput.fields.formName = childItem.Name;
          CloneInput.fields.titleText = { value: childItem.Title };
          CloneInput.fields.pulldownData = generateNumberArray(
            QUANTITY_DROPDOWN_VALUE
          ).map((num) => ({
            name: num,
            value: num,
          }));

          CloneInput.fields.onChangeEvent = (e) => {
            if (type === "customerInfo") {
              setCustomerInfoInput(e);
            } else {
              setFormDataInput(e);
            }
          };
          break;

        case "Email":
          CloneInput = deepcopy(EmailI06Data);
          CloneInput.fields.inputTitleText = childItem?.Title;
          CloneInput.fields.confirmationTitleText = `${childItem?.Title}${STRING_SIMPLE_OP_CONFIRMATION_TEXT}`;
          CloneInput.fields.addressPlaceholder = pageFields["EmailVerificationSetting-EmailAddressPlaceholder"];
          CloneInput.fields.domainPlaceholder = pageFields["EmailVerificationSetting-EmailDomainPlaceholder"];
          CloneInput.fields.name = childItem?.Name;
          CloneInput.fields.type = type;
          CloneInput.fields.inputValue = [
            { name: "emailAddress" },
            { name: "emailDomain" },
            { name: "emailAddressConfirmation" },
            { name: "emailDomainConfirmation" },
            { name: "authCode" },
            { name: "isVerified" },
          ]
          CloneInput.fields.sendAuthCodeText =
            pageFields["EmailVerificationSetting-SendingAuthCodeText"];
          CloneInput.fields.inputAuthCodeLabel =
            pageFields["EmailVerificationSetting-inputAuthCodeLabel"];
          CloneInput.fields.authCodePlaceholder =
            pageFields["EmailVerificationSetting-AuthCodePlaceholder"];
          CloneInput.fields.verificationText =
            pageFields["EmailVerificationSetting-VerificationButtonText"];
          CloneInput.fields.completeVerificationText =
            pageFields["EmailVerificationSetting-CompleteVerificationButtonText"];
          CloneInput.fields.mailItem = childItem?.EmailProp;
          CloneInput.fields.sendingMailFailureMsg =
            pageFields["EmailVerificationSetting-SendingMailFailureMessage"];
          CloneInput.fields.authGuideMsg =
            pageFields["EmailVerificationSetting-AuthGuideMsg"];
          CloneInput.fields.authCompleteGuideMsg =
            pageFields["EmailVerificationSetting-AuthCompleteGuideMsg"];
          CloneInput.fields.onChangeEvent = (e) => {
            if (type === "customerInfo") {
              setCustomerInfoEmail(e, childItem?.Name)
            } else {
              setFormDataEmail(e, childItem?.Name);
            }
          };
          if (type === "customerInfo") {
            CloneInput.fields.descriptionText = childItem.Description;
          }
          break;

        case "Telephone":
          CloneInput = deepcopy(PhoneI03Data);
          CloneInput.fields.inputTitleText = { value: childItem.Title };
          CloneInput.fields.name = childItem.Name;
          CloneInput.fields.type = type;
          CloneInput.fields.inputValue = [
            {
              name: "PhoneNumber1"
            },
            {
              name: "PhoneNumber2"
            },
            {
              name: "PhoneNumber3"
            }]

          CloneInput.fields.onChangeEvent = (e) => {
            if (type === "customerInfo") {
              setCustomerInfoTelephone(e, childItem.Name);
            } else {
              setFormDataTelephone(e, childItem.Name);
            }

          };
          break;

        case "Name":
        case "Text":
          CloneInput = deepcopy(NameI01Data);
          CloneInput.fields.textInputLabel = { value: childItem.Title };
          CloneInput.fields.name = childItem.Name;
          CloneInput.fields.type = type;
          CloneInput.fields.inputValue = {
            name: childItem.Name
          }
          CloneInput.fields.onChangeEvent = (e) => {
            if (type === "customerInfo") {
              setCustomerInfoInput(e);
            } else {
              setFormDataInput(e);
            }
          };

          if (type === "customerInfo") {
            CloneInput.fields.descriptionTextBottom = childItem.Description;
          }
          break;

        default:
          let dropDownItems = getDropdownList(childItem.Type);
          CloneInput = deepcopy(PullDownM01Data);
          CloneInput.fields.isNeedEmptyPlaceholder = true;
          CloneInput.fields.name = childItem.Name;
          CloneInput.fields.type = type;
          CloneInput.fields.formName = childItem.Name;
          CloneInput.fields.titleText = { value: childItem.Title };
          CloneInput.fields.pulldownData = dropDownItems;
          CloneInput.fields.mobileClass = { value: "attr-dropdown" };
          CloneInput.fields.onChangeEvent = (e) => {
            if (type === "customerInfo") {
              setCustomerInfoInput(e);
            } else {
              setFormDataInput(e);
            }
          };
          break;
      }

      CloneInput.uid = generateGUID();
      if (childItem["Required"]) {
        CloneInput.fields.requiredText = {
          value: "必須",
        };
      }
      inputProductAttrSection.push(CloneInput);
    });
  };

  const getMinAndMaxDates = (today) => {
    const currentDate = new Date(today);
  
    const minDate = new Date(currentDate);
    minDate.setDate(minDate.getDate() + STRING_SIMPLE_OP_APPLICABLE_MINDATE);
  
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const lastDayOfCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    
    let maxDate = new Date(currentDate);
    if (currentDate.getDate() === lastDayOfCurrentMonth) {
      maxDate = new Date(currentYear, currentMonth + 2, 0);
    } else {
      maxDate.setMonth(currentMonth + 1);
      const nextMonthSameDate = new Date(currentYear, currentMonth + 1, currentDate.getDate());
  
      if (nextMonthSameDate.getMonth() !== (currentMonth + 1) % 12) {
        maxDate = new Date(currentYear, currentMonth + 2, 0);
      } else {
        maxDate = nextMonthSameDate;
      }
    }
    maxDate.setDate(maxDate.getDate() + 1);
    return { minDate, maxDate };
  };

  const getDropdownList = (childItemType) => {
    let dropDownItems = [];
    if (
      SimpleApplicationDropDownItemSearchQuery !== undefined &&
      SimpleApplicationDropDownItemSearchQuery?.SimpleApplicationDropdownItems
        ?.children.length > 0
    ) {
      const dropdownMaster = SimpleApplicationDropDownItemSearchQuery?.SimpleApplicationDropdownItems?.children.filter(
        (child) => {
          return child.name === childItemType;
        }
      );
      if (dropdownMaster.length > 0) {
        dropDownItems = dropdownMaster[0].children.map((child) => {
          const dropDownItem = child.fields.reduce((acc, field) => {
            if (field.name === STRING_VALUE) {
              acc.value = field.value;
            } else if (field.name === STRING_DESCRIPTION) {
              acc.name = field.value;
            }
            return acc;
          }, {});
          return dropDownItem;
        });
      }
    }
    return dropDownItems;
  };

  if (
    SimpleOpApplicationAttrData &&
    SimpleOpApplicationAttrData.length > 0 &&
    !isStep2Rendered &&
    Object.keys(productSelections).length > 0
  ) {
    let tmpS17DataCopy = [];
    let counter = 0;
    setIsStep2BackUp([
      inputProductAttrSection[0],
      inputProductAttrSection[1],
      inputProductAttrSection[2],
      DateM01iData,
      PullDownM01Data,
      EmailI06Data,
      PhoneI03Data,
    ]);
    inputProductAttrSection.splice(-5);

    SimpleOpApplicationAttrData.forEach((productItem) => {
      if (
        productSelections.hasOwnProperty(productItem.parent) &&
        productSelections[productItem.parent]?.product?.length > 0
      ) {
        createDynamicInputs(
          productItem.child,
          DateM01iData,
          PullDownM01Data,
          EmailI06Data,
          PhoneI03Data,
          "productAttributes",
          inputProductAttrSection,
          undefined,
          productItem?.label
        );
        //check if there's selected product that requires customer Input
        counter = counter + 1;
      }
    });
    //Hide section description if there is no input attribute
    if (counter === 0) {
      inputProductAttrSection.splice(1);
    }
    setIsNoInputProduct(counter === 0 ? true : false);
    setIsStep2Rendered(true);
  }

  const step2ButtonSection = getScDataFromPlaceholder(
    "button-section-step-2",
    props
  );
  const [nextButtonStep2] = getScDataFromComponent("B-01", step2ButtonSection);
  const [backButtonStep2] = getScDataFromComponent("B-02", step2ButtonSection);

  //STEP 3
  const SimpleOpApplicationCustomerInfoData = fixDataFormat(
    SimpleOpApplicationCustomerInfoQuery?.SimpleOpApplicationCustomerInfo,
    "SimpleOpApplicationCustomerInfo",
    "step3"
  );
  sortProducts(SimpleOpApplicationCustomerInfoData);
  
  const inputCustomerInfoSection = getScDataFromPlaceholder(
    "input-customer-information-section",
    props
  );
  const buttonsSectionStep3 = getScDataFromPlaceholder(
    "button-section-step-3",
    props
  );
  const [nextButtonStep3] = getScDataFromComponent("B-01", buttonsSectionStep3);
  const [backButtonStep3] = getScDataFromComponent("B-02", buttonsSectionStep3);

  if (isStep2Done) {
    inputProductAttrSection.splice(1);
  }

  const [I01Name] = getScDataFromComponent("I-01", inputCustomerInfoSection);
  const [EmailInput] = getScDataFromComponent("I-06", inputCustomerInfoSection);
  const [PhoneInput] = getScDataFromComponent("I-03", inputCustomerInfoSection);
  if (
    SimpleOpApplicationCustomerInfoData &&
    SimpleOpApplicationCustomerInfoData.length > 0 &&
    isStep2Done &&
    !isStep3Rendered
  ) {
    setIsStep3BackUp([I01Name, EmailInput, PhoneInput]);
    inputCustomerInfoSection.splice(2);
    createDynamicInputs(
      SimpleOpApplicationCustomerInfoData,
      "",
      "",
      EmailInput,
      PhoneInput,
      "customerInfo",
      inputCustomerInfoSection,
      I01Name,
      ""
    );
    setIsStep3Rendered(true);
  }
  modalData.fields.isCustom = true;
  modalData.fields.titleText = {
    value:
      sectionChangeNum === 1
        ? pageFields["SelectProductModalSection-Title"].value
        : pageFields["InputProductAttributeModalSection-Title"].value,
  };

  const planChangeModal = getScDataFromComponent(
    "Plan-Change-Modal-Layout",
    getScDataFromPlaceholder("modal-section", modalData)
  );

  planChangeModal[0].fields = {
    descriptionText:
      sectionChangeNum === 1
        ? pageFields["SelectProductModalSection-Description"].value
        : pageFields["InputProductAttributeModalSection-Description"].value,
    closeText: {
      value:
        sectionChangeNum === 1
          ? pageFields["SelectProductModalSection-BackButtonTitle"].value
          : pageFields["InputProductAttributeModalSection-BackButtonTitle"]
            .value,
    },
    confirmText: {
      value:
        sectionChangeNum === 1
          ? pageFields["SelectProductModalSection-NextButtonTitle"].value
          : pageFields["InputProductAttributeModalSection-NextButtonTitle"]
            .value,
    },
    modalTarget: { value: "confirmation_modal" },
    onConfirmClick: () => {
      if (sectionChangeNum === 1) {
        productSelectionSection.splice(0);
        productSelectionSection.push(...step1BackUp);
        setIsStep1Rendered(false);
        setIsStep1Done(false);
        setIsStep2Done(false);
      } else if (sectionChangeNum === 2) {
        inputProductAttrSection.splice(0);
        inputProductAttrSection.push(...step2BackUp);
        setIsStep2Rendered(false);
        setIsStep2Done(false);
      }
    },
    onCancelClick: () => {
      return false;
    },
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  //NEXT BUTTON STEP 1
  nextButtonStep1.fields.onClickEvent = () => {
    setIsStep1Done(true);
    setIsStep2Done(false);
    setIsStep2Rendered(false);
    inputProductAttrSection.splice(0);
    inputProductAttrSection.push(...step2BackUp);
    scrollToTop();
    setIsProceedingStep2(true);
    setValidFieldsStep2(false);
  };

  //BACK BUTTON STEP 2
  backButtonStep1.fields.onClickEvent = () => {
    redirectToPreviousPage();
  };

  const validateCustomerInfoFields = (requiredArrays, formData) => {
    if (requiredArrays.length === 0) {
      return true;
    }
    
    let hasEveryValue = true;
    for (var i=0; i<requiredArrays.length; i++) {
      if (!formData[requiredArrays[i]]) {
        hasEveryValue = false;
        break;
      }
    }

    if (!hasEveryValue) {
      return false;
    }

    const validationResult = [];
    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] === "object") {
        if (Object.keys(formData[key]).includes("phoneNumber1")) {
          validationResult.push(
            validateIsNumber(formData[key]["phoneNumber1"]) &&
            validateIsFixedLength(formData[key]["phoneNumber1"], 3) &&
            validateIsNumber(formData[key]["phoneNumber2"]) &&
            validateIsFixedLength(formData[key]["phoneNumber2"], 4) &&
            validateIsNumber(formData[key]["phoneNumber3"]) &&
            validateIsFixedLength(formData[key]["phoneNumber3"], 4)
          );
        } else if (Object.keys(formData[key]).includes("emailAddress")) {
          validationResult.push(formData[key]["isVerified"] ?? false);
        }
        else {
          validationResult.push(true);
        }
      }
    });

    const isValidate = !validationResult.includes(false);
    return isValidate;
  }

  nextButtonStep2.fields.onClickEvent = () => {
    if (
      validFieldsStep2 ||
      isNoInputProduct ||
      requiredAttrFields?.length === 0
    ) {
      setIsStep1Done(true);
      setIsStep2Done(true);
      scrollToTop();
      setIsStep3Rendered(false);
      setIsProceedingStep3(true);
      setValidFieldsStep3(false);
    }
  };

  //NEXT BUTTON STEP 2
  nextButtonStep2.fields.isDisabled = {
    value:
      !validFieldsStep2,
  };
  //BACK BUTTON STEP 2
  backButtonStep2.fields.onClickEvent = () => {
    productSelectionSection.splice(0);
    productSelectionSection.push(...step1BackUp);
    setIsStep1Done(false);
    setIsStep2Done(false);
    setIsStep1Rendered(false);
    setFormData({});
    scrollToTop();
    setIsProceedingStep2(false);
    setIsProceedingStep3(false);
  };

  //NEXT BUTTON STEP 3
  nextButtonStep3.fields.additionalId={value: "input"};
  nextButtonStep3.fields.onClickEvent = () => {
    if (validFieldsStep3) {
      const selectedProduct = Object.values(productSelections);
      const separatedProductData = selectedProduct.flatMap((item) => {
        return {
          ...item,
          sortOrder: item?.sortOrder,
        };
      });
      let productAttributes = [];
      let customerInformation = {};

      SimpleOpApplicationAttrData &&
        SimpleOpApplicationAttrData.forEach((productItem) => {
          if (
            productSelections.hasOwnProperty(productItem.parent) &&
            productSelections[productItem.parent]?.product?.length > 0
          ) {

            const attributeList = filterProductAttributes(productItem.child, isLogged);

            productAttributes.push({
              title: productItem.parent,
              attributes: attributeList.map((child) => {
                return {
                  name: child.Name,
                  title: child.Title,
                  value:
                    child.Type === "Email"
                      ? formData[child.Name]?.emailAddress &&
                        formData[child.Name]?.emailDomain
                        ? `${formData[child.Name]?.emailAddress}@${formData[child.Name]?.emailDomain
                        }`
                        : ""
                      : child.Type === "Telephone"
                        ? formData[child.Name]?.phoneNumber1
                          ? `${formData[child.Name]?.phoneNumber1}-${formData[child.Name]?.phoneNumber2
                          }-${formData[child.Name]?.phoneNumber3}`
                          : ""
                        : formData[child.Name] || "",
                };
              }),
            });
          }
        });
      //Map Customer Info data
      const filteredCustomerInfo = isLogged ?
        SimpleOpApplicationCustomerInfoData.filter(info => info.IsLoggedIn === "1")
        : SimpleOpApplicationCustomerInfoData.filter(info => info.IsNotLoggedIn === "1");


      filteredCustomerInfo &&
        filteredCustomerInfo.forEach((productItem) => {
          customerInformation[productItem.Name] =
            productItem.Type === "Email"
              ? `${customerInfo[productItem.Name]?.emailAddress}@${customerInfo[productItem.Name]?.emailDomain
              }`
              : productItem.Type === "Telephone"
                ? `${customerInfo[productItem.Name]?.phoneNumber1}-${customerInfo[productItem.Name]?.phoneNumber2
                }-${customerInfo[productItem.Name]?.phoneNumber3}`
                : customerInfo[productItem.Name] || "";
        });

      customerInformation = {
        ...customerInformation,
        UserIdText__c: personalizeInfo?.UserId,
        SendingExplanationDocument__c: STRING_SIMPLE_OP_WEB_APPLIACTION,
        PersonalInformationConsent__c: true,
        FormType__c: isLogged ? STRING_SIMPLE_OP_LOGIN_USER : STRING_SIMPLE_OP_NOT_LOGIN_USER,
      }

      const simpleOpOrderInfo = {
        SimpleOpApplication: {
          monthlyPayment: categorizeProductList(separatedProductData, STRING_MONTHLYPAYMENT),
          annualPayment: categorizeProductList(separatedProductData, STRING_ANNUALPAYMENT),
          lumpSumPayment: categorizeProductList(separatedProductData, STRING_LUMPSUMPAYMENT),
          sixtyInstallmentPayment: categorizeProductList(separatedProductData, STRING_SIXTY_INSTALLPAYMENT),
          productAttributes: productAttributes,
          customerInfo: customerInformation,
        },
      };
      setSessionStorageItem(
        sessionKeys.SSN_APPLICATION_SIMPLEOP_ORDERINFO,
        simpleOpOrderInfo,
        true
      );

      registerSimpleOpSessionRefresh();
    }
  };
  nextButtonStep3.fields.isDisabled = {
    value: !validFieldsStep3,
  };
  //BACK BUTTON STEP 3
  backButtonStep3.fields.onClickEvent = () => {
    inputProductAttrSection.splice(0);
    inputProductAttrSection.push(...step2BackUp);
    setIsStep2Rendered(false);
    setIsStep2Done(false);
    inputCustomerInfoSection.push(...step3BackUp);
    setCustomerInfo({});
    setIsProceedingStep3(false);
  };

  //mapping required fields for Product Attributes Fields
  useEffect(() => {

    if (
      Object.keys(productSelections).length > 0 &&
      SimpleOpApplicationAttrData
    ) {
      const requiredAttr = SimpleOpApplicationAttrData
        .filter(attr => {
          const key = attr.parent;
          return productSelections.hasOwnProperty(key) && productSelections[key].product.length > 0;
        })
        .flatMap(attr => attr.child.filter(
          item => !isInvalidLoginStatus(item.IsLoggedIn, item.IsNotLoggedIn, isLogged)
        ).map(item => item.Name));

      setRequiredAttrFields(requiredAttr);

      const productAttributes = SimpleOpSessionData?.SimpleOpApplication?.productAttributes;
      if (productAttributes && productAttributes.length > 0) {
        productAttributes.forEach(itemProdAttr => {
          const filteredAttrData = SimpleOpApplicationAttrData?.filter(item => item.parent === itemProdAttr.title);
          if (filteredAttrData.length > 0) {
            itemProdAttr.attributes.forEach(itemAttr => {
              const filteredAttrDataChild = filteredAttrData[0]?.child?.filter(item => item.Name === itemAttr.name);
              if (filteredAttrDataChild[0]?.Type === "Email") {
                handleEmail(itemAttr.name, itemAttr.value, "productAttributes");
              } else if (filteredAttrDataChild[0]?.Type === "Telephone") {
                handleTelephone(itemAttr.name, itemAttr.value, "productAttributes");
              } else if (filteredAttrDataChild[0]?.Type === "Date") {
                setFormDataInput(itemAttr.value, itemAttr.name);
              } else {
                const currentTarget = handleInput(itemAttr.name, itemAttr.value);
                setFormDataInput(currentTarget);
              }
            });
          }
        });
      }
    }
  }, [isProceedingStep2]);

  //mapping required fields for Customer Information Fields
  useEffect(() => {

    if (
      SimpleOpApplicationCustomerInfoData &&
      SimpleOpApplicationCustomerInfoData?.length > 0
    ) {
      const requiredCustomerInfo = SimpleOpApplicationCustomerInfoData?.filter(
        item => !isInvalidLoginStatus(item.IsLoggedIn, item.IsNotLoggedIn, isLogged)
      ).map(item => item.Name);
      setRequiredCustomerInfoFields(requiredCustomerInfo);

      const customerInfo = SimpleOpSessionData?.SimpleOpApplication?.customerInfo;
      if (customerInfo) {
        for (const [key, value] of Object.entries(customerInfo)) {
          const filterCustomerInfo = SimpleOpApplicationCustomerInfoData?.filter(item => item.Name === key);
          if (filterCustomerInfo.length > 0) {
            if (filterCustomerInfo[0]?.Type === "Email") {
              handleEmail(key, value, "customerInfo");
            } else if (filterCustomerInfo[0]?.Type === "Telephone") {
              handleTelephone(key, value, "customerInfo");
            } else if (filterCustomerInfo[0]?.Type === "Date") {
              setCustomerInfoInput(value, key);
            } else {
              const currentTarget = handleInput(key, value);
              setCustomerInfoInput(currentTarget);
            }
          }
        }
      }
    }
  }, [isProceedingStep3]);


  useEffect(() => {
    if (registerSimpleOpSessionData?.StatusCode) {
      switch (registerSimpleOpSessionData?.StatusCode) {
        case 200:
          redirectToLinkUrl(pageFields["InputCustomerInfoSection-NextButtonLink"]);
          break;
        case 500:
          window.location.href = STRING_ERROR_500_URI;
          break;
      }
    }
  }, [registerSimpleOpSessionData]);

  useEffect(() => {
    if (serverSessionInfoData) {
      personalizeInfo = updateNotApplyDefaultValue(serverSessionInfoData);
      setSessionStorageItem(
        sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
        personalizeInfo,
        true
      );
      setIsPersonalizeCalled(true);
    }
  }, [serverSessionInfoData]);

  const isLoading =
    paymentMethodChangeQuery === STRING_LOADING ||
    SimpleOpApplicationAttrQuery === STRING_LOADING ||
    SimpleOpApplicationCustomerInfoQuery === STRING_LOADING ||
    retrieveLoginStatusLoading === STRING_LOADING ||
    registerSimpleOpSessionIsLoading === STRING_LOADING || 
    serverSessionInfoIsLoading === STRING_LOADING;

  props.setIsLoading(isLoading);

  const handleDefaultSelectedProducts = (
    SimpleOpApplicationData,
    defaultSelectedProducts
  ) => {
    let selectedProducts = {};
    SimpleOpApplicationData.forEach((parentItem) => {
      const parentName = parentItem.label;
      const matchingProducts = [];
      parentItem.child.forEach((childItem) => {
        //check if the product does includes in the GET parameters
        if (defaultSelectedProducts.some(item => item.name === childItem.Name)) {
          const additionalInfo = defaultSelectedProducts.filter(item => item.name === childItem.Name);
          matchingProducts.push({
            name: childItem?.Name,
            title: childItem?.Title,
            titleClass: childItem?.TitleClass,
            paymentType: childItem?.PaymentType,
            price: childItem?.Price,
            priceWithoutTax: childItem?.PriceWithoutTax,
            quantity: additionalInfo[0].quantity,
            sortOrder: childItem?.sortOrder,
            key: childItem?.Key,
            value: childItem?.Value,
            isSelectedAlternativePayment: additionalInfo[0].isSelectedAlternativePayment,
            alternativePrice: childItem?.AlternativePrice,
            alternativePriceWithoutTax: childItem?.AlternativePriceWithoutTax,
            alternativePaymentType: childItem?.AlternativePaymentType,
          });
        }

        if (childItem.subChild) {
          childItem.subChild.forEach((subChildItem) => {
            if (defaultSelectedProducts.some(item => item.name === subChildItem.Name)) {
              const additionalInfo = defaultSelectedProducts.filter(item => item.name === subChildItem.Name);
              matchingProducts.push({
                name: subChildItem?.Name,
                title: subChildItem?.Title,
                titleClass: subChildItem?.TitleClass,
                paymentType: subChildItem?.PaymentType,
                price: subChildItem?.Price,
                priceWithoutTax: subChildItem?.PriceWithoutTax,
                quantity: additionalInfo[0].quantity,
                sortOrder: subChildItem?.sortOrder,
                key: subChildItem?.Key,
                value: subChildItem?.Value,
                isSelectedAlternativePayment: additionalInfo[0].isSelectedAlternativePayment,
                alternativePrice: subChildItem?.AlternativePrice,
                alternativePriceWithoutTax: subChildItem?.AlternativePriceWithoutTax,
                alternativePaymentType: subChildItem?.AlternativePaymentType,
              });
            }
          });
        }

        if (matchingProducts.length > 0) {
          selectedProducts[parentName] = {
            title: parentName,
            product: matchingProducts,
            selectionType: childItem.SelectionType,
            sortOrder: parentItem?.sortOrder,
          };
        }

        //For products that are not included in the GET parameters, select the radio button "Do not select".
        if (
          !defaultSelectedProducts.includes(childItem.Name) &&
          childItem.SelectionType === STRING_METHOD_SELECTABLEOPTION &&
          !selectedProducts[parentName]
        ) {
          selectedProducts[parentName] = {
            title: parentName,
            product: [],
            selectionType: childItem.SelectionType,
            sortOrder: childItem?.sortOrder,
          };
        }
      });
    });
    return selectedProducts;
  };

  return (
    <React.Fragment>
      <ApplyButtonContext.Provider
        value={{ agreementContext, setAgreementContext }}
      >
        <main>
          <div className="form_container">
            <div className="form_detail full-width">
              <div className="form_wrapper">
                <Placeholder name="modal-section" rendering={props.rendering} />
                <Placeholder
                  name="product-selection-section"
                  rendering={props.rendering}
                />
                {!isStep1Done && (
                  <div className="form_container">
                    <div className="form_detail full-width no-text-ident">
                      <div className="form_wrapper">
                        <HandlingPersonalInfo
                          rendering={props.rendering}
                          setIsLoading={props.setIsLoading}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!isStep1Done && (
                  <div className="btn_container mb-32">
                    <Placeholder
                      name="button-section"
                      rendering={props.rendering}
                    />
                  </div>
                )}

                {isStep1Done && (
                  <Placeholder
                    name="input-product-attribute-section"
                    rendering={props.rendering}
                  />
                )}
                {isNoInputProduct && isStep1Done && !isStep2Done && (
                  <Placeholder
                    name="empty-input-product-attribute-section"
                    rendering={props.rendering}
                  />
                )}

                {isStep1Done && !isStep2Done && (
                  <div className="btn_container mb-32 step-2">
                    <Placeholder
                      name="button-section-step-2"
                      rendering={props.rendering}
                    />
                  </div>
                )}

                {isStep1Done && isStep2Done && (
                  <>
                    <Placeholder
                      name="input-customer-information-section"
                      rendering={props.rendering}
                    />
                    <div className="btn_container mb-32">
                      <Placeholder
                        name="button-section-step-3"
                        rendering={props.rendering}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </main>
      </ApplyButtonContext.Provider>
    </React.Fragment>
  );
};

export default withSitecoreContext()(SimpleOpApplicationLayout);
