import React, { useState, useEffect } from "react";
import { useGetDataViaGraphQL, useCustomQuery } from "../../hooks/useGetData";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import { query as selectContactAddressQuery } from "../../assets/graphql/SelectContactAddressMaster";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import { apiBaseURL } from "../../envSettings";
import { RegisterContactAddress } from "./itemRegisterContactAddress";
import { RegisterInstallationAddress } from "./itemRegisterInstallationAddress";
import { RegisterIndividualCustomerInfo } from "./itemRegisterIndividualCustomerInfo";
import { RegisterCorporateCustomerInfo } from "./itemRegisterCorporateCustomerInfo";
import { SelectPreferrredTransferDate } from "./itemSelectPreferredTransferDate";
import {
  STRING_INDIVIDUAL,
  STRING_CORPORATION,
  STRING_GET,
  STRING_POST,
  STRING_LOADING,
  STRING_APPLY,
  STRING_SIMULATOR,
  STRING_MOVING,
  STRING_REQUEST_WEB,
  STRING_REBUILDING,
} from "../../utils/constantVariables";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import { getUrlInfo } from "../../utils/commonUtils";

const CustomerInfoLayout = (props) => {

  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);
  const pageContext = props.sitecoreContext.route.fields;
  let isNotEncrypt = true;
  const graphQlData = useGetDataViaGraphQL(selectContactAddressQuery);
  const [metadataDefinitions, setMetadataDefinitions] = useState("");
  const [isLoginStatus, setIsLoginStatus] = useState("");
  const [retrieveCustomerInfo, setRetrieveCustomerInfo] = useState([]);

  let orderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    isNotEncrypt
  );

  const planType = getSessionStorageItem(
    keys.SSN_APPLICATION_SELECTPLANTYPE_PLANTYPE,
    isNotEncrypt
  );

  const isTypeCircuit =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_IsTypeCircuit__c;

  const orderChannelCategory = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  )?.AppChannelDivision;

  const retrieveMetadataRequest = {
    objectNames: ["Order", "Account"],
    orderChannelCategory: orderChannelCategory
      ? orderChannelCategory
      : STRING_REQUEST_WEB,
  };

  const retrieveMetadataURI = `${apiBaseURL}order/RetrieveMetadataDefinitions`;
  const {
    data: retrieveMetadataData,
    isLoading: retrieveMetadataLoading,
    error: retrieveMetadataError,
    refetch: retrieveMetadataRefresh,
    remove: retrieveMetadataRemove,
  } = useCustomQuery(
    retrieveMetadataURI,
    true,
    STRING_POST,
    retrieveMetadataRequest
  );

  useEffect(() => {
    if (retrieveMetadataData && retrieveMetadataData["metadataDefinitions"]) {
      if (retrieveMetadataData["metadataDefinitions"].length !== 0) {
        setMetadataDefinitions(retrieveMetadataData["metadataDefinitions"]);
      }
    }
  }, [retrieveMetadataData]);

  const orderItemInfosData = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos?.map(
    (item) => {
      return {
        orderItem: {
          Id: item.orderItem.Id,
          Product2Id: item.orderItem.Product2Id,
          CMAP_ProductSelectionState__c:
            item.orderItem.CMAP_ProductSelectionState__c,
          CMAP_LastTimeChangeCategory__c:
            item.orderItem.CMAP_LastTimeChangeCategory__c,
        },
      };
    }
  );

  const retrieveSystemDateTimeURI = `${apiBaseURL}common/RetrieveSystemDateTime`;

  const {
    data: retrieveSystemDateTimeData,
    isLoading: retrieveSystemDateTimeLoading,
  } = useCustomQuery(retrieveSystemDateTimeURI, true, STRING_GET);

  const urlInfo = getUrlInfo();
  const urlParentItem = urlInfo?.pathnames[1];

  const RetrieveSelectableDesiredDateRangeBody = {
    orderRelatedInfo: {
      orderInfo: {
        order: {
          CMAP_OrderCategory__c:
            orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
              ?.CMAP_OrderCategory__c,
          CMAP_OrderBeforeChange__c:
            orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
              ?.CMAP_OrderBeforeChange__c,
          EffectiveDate:
            urlInfo?.cpadHostName === urlInfo?.hostname
              ? orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
                  ?.EffectiveDate
              : retrieveSystemDateTimeData?.SystemDateTime?.split("T")[0],
        },
        orderAddition: {
          orderCategoryCurrentlySelected:
            orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition
              ?.orderCategoryCurrentlySelected,
          orderCategoryPreviouslySelected:
            orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition
              ?.orderCategoryPreviouslySelected,
        },
        orderItemInfos: orderItemInfosData,
      },
    },
  };

  const retrieveSelectableDesiredDateRangeURI = `${apiBaseURL}order/RetrieveSelectableDesiredDateRange`;
  const {
    data: retrieveSelectableDesiredDateRangeData,
    isLoading: retrieveSelectableDesiredDateRangeLoading,
    error: retrieveSelectableDesiredDateRangeError,
    refetch: retrieveSelectableDesiredDateRangeRefresh,
    remove: retrieveSelectableDesiredDateRangeRemove,
  } = useCustomQuery(
    retrieveSelectableDesiredDateRangeURI,
    false,
    STRING_POST,
    RetrieveSelectableDesiredDateRangeBody
  );

  useEffect(() => {
    if (retrieveSystemDateTimeData) {
      retrieveSelectableDesiredDateRangeRefresh();
    }
  }, [retrieveSystemDateTimeData]);

  const retrieveLoginStatusURI = `${apiBaseURL}common/RetrieveLoginStatus`;

  const {
    data: retrieveLoginStatusData,
    isLoading: retrieveLoginStatusLoading,
  } = useCustomQuery(retrieveLoginStatusURI, true, STRING_GET);

  useEffect(() => {
    if (retrieveLoginStatusData) {
      setIsLoginStatus(retrieveLoginStatusData?.Result);
    }
  }, [retrieveLoginStatusData]);

  const isFirstTimeDisplay =
    getSessionStorageItem(
      keys.SSN_APPLICATION_INPUTCUSTOMERINFO_ISFIRSTTIMEDISPLAY,
      isNotEncrypt
    ) === ""
      ? setSessionStorageItem(
          keys.SSN_APPLICATION_INPUTCUSTOMERINFO_ISFIRSTTIMEDISPLAY,
          true,
          isNotEncrypt
        )
      : getSessionStorageItem(
          keys.SSN_APPLICATION_INPUTCUSTOMERINFO_ISFIRSTTIMEDISPLAY,
          isNotEncrypt
        );

  const accountId =
    orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account?.Id;
  const retrieveCustomerInfoURI = `${apiBaseURL}order/RetrieveCustomerInfo?acountId=${accountId}`;

  const {
    data: retrieveCustomerInfoData,
    isLoading: retrieveCustomerInfoLoading,
    error: retrieveCustomerInfoError,
    refetch: retrieveCustomerInfoRefresh,
    remove: retrieveCustomerInfoRemove,
  } = useCustomQuery(retrieveCustomerInfoURI, false, STRING_GET);

  const isLoading =
    retrieveMetadataLoading ||
    retrieveSystemDateTimeLoading ||
    retrieveSelectableDesiredDateRangeLoading ||
    retrieveLoginStatusLoading ||
    retrieveCustomerInfoLoading ||
    graphQlData === STRING_LOADING;
  props.setIsLoading(isLoading);

  useEffect(() => {
    if (isFirstTimeDisplay) {
      if (
        (urlParentItem === STRING_APPLY ||
          urlParentItem === STRING_SIMULATOR) &&
        isLoginStatus
      ) {
        retrieveCustomerInfoRefresh();
        if (retrieveCustomerInfoData) {
          setSessionStorageItem(
            keys.SSN_APPLICATION_INPUTCUSTOMERINFO_ISFIRSTTIMEDISPLAY,
            false,
            isNotEncrypt
          );
        }
      } else if (
        urlParentItem !== STRING_APPLY &&
        urlParentItem !== STRING_SIMULATOR
      ) {
        setSessionStorageItem(
          keys.SSN_APPLICATION_INPUTCUSTOMERINFO_ISFIRSTTIMEDISPLAY,
          false,
          isNotEncrypt
        );
      }
    }
  }, [retrieveCustomerInfoData, isLoginStatus]);

  useEffect(() => {
    if (retrieveCustomerInfoData) {
      setRetrieveCustomerInfo(retrieveCustomerInfoData);
    }
  }, [retrieveCustomerInfoData]);
  const orderCategory = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_ORDERCATEGORY,
    isNotEncrypt
  );
  const cMAPContractName =
    orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account
      ?.CMAP_ContractName__c;

  const [inputValues, setInputValues] = useState({
    registerIndividualCustomerInfoLastName: "",
    registerIndividualCustomerInfoFirstName: "",
    registerIndividualCustomerInfoLastNameFurigana: "",
    registerIndividualCustomerInfoFirstNameFurigana: "",
    registerIndividualCustomerInfoBirthDate: "",
    registerIndividualCustomerInfoGender: "",
    registerCorporateCustomerInfoPersonInChargeLastName: "",
    registerCorporateCustomerInfoPersonInChargeFirstName: "",
    registerCorporateCustomerInfoPersonInChargeLastNameFurigana: "",
    registerCorporateCustomerInfoPersonInChargeFirstNameFurigana: "",
    registerCorporateCustomerInfoCorporateName: "",
    registerCorporateCustomerInfoCorporateNameFurigana: "",
    registerCorporateCustomerInfoDepartmentName: "",
    registerContactAddressEmailAddress: "",
    registerContactAddressEmailDomain: "",
    registerContactAddressPrefectureMunicipality: "",
    registerContactAddressStreetName: "",
    registerContactAddressBuildingName: "",
    registerContactAddressRoomNumber: "",
    registerContactAddressPostalCode3digit: "",
    registerContactAddressPostalCode4digit: "",
    registerContactAddressPhoneNumber1: "",
    registerContactAddressPhoneNumber2: "",
    registerContactAddressPhoneNumber3: "",
    registerContactAddressMobileNumber1: "",
    registerContactAddressMobileNumber2: "",
    registerContactAddressMobileNumber3: "",
    registerContactAddressPrefecture: "",
    registerContactAddressCity: "",
    registerContactAddressCode: "",
    registerInstallationAddressStreetNumber: "",
    registerInstallationAddressBuildingName: "",
    registerInstallationAddressRoomNumber: "",
    registerInstallationAddressAdditionInformation: "",
    registerInstallationAddressBuildingType: "",
    registerInstallationAddressAddress: "",
    registerInstallationAddressPreferredStartDate: "",
    selectPreferredTransferDateCalendar: "",
  });

  const onChangeEventInput = (event) => {
    let newValue = event.target.value;
    let name = event.target.name;
    setInputValues({ ...inputValues, [name]: newValue });
  };

  const RegisterInstallationAddressView = (
    <RegisterInstallationAddress
      rendering={props.rendering}
      orderRelatedInfo={orderRelatedInfo}
      isNotEncrypt={isNotEncrypt}
      isFirstTimeDisplay={isFirstTimeDisplay}
      planType={planType}
      pageContext={pageContext}
      onChangeEventInput={onChangeEventInput}
      isTypeCircuit={isTypeCircuit}
      setInputValues={setInputValues}
      inputValues={inputValues}
      metadataDefinitions={metadataDefinitions}
      urlParentItem={urlParentItem}
      retrieveSystemDateTimeData={retrieveSystemDateTimeData}
      retrieveSelectableDesiredDateRangeData={
        retrieveSelectableDesiredDateRangeData
      }
    />
  );
  const RegisterIndividualCustomerInfoView = (
    <RegisterIndividualCustomerInfo
      rendering={props.rendering}
      orderRelatedInfo={orderRelatedInfo}
      pageContext={pageContext}
      retrieveCustomerInfo={retrieveCustomerInfo}
      isNotEncrypt={isNotEncrypt}
      isFirstTimeDisplay={isFirstTimeDisplay}
      onChangeEventInput={onChangeEventInput}
      setInputValues={setInputValues}
      inputValues={inputValues}
      orderCategory={orderCategory}
      cMAPContractName={cMAPContractName}
      metadataDefinitions={metadataDefinitions}
      urlParentItem={urlParentItem}
      isLoginStatus={isLoginStatus}
    />
  );
  const RegisterCorporateCustomerInfoView = (
    <RegisterCorporateCustomerInfo
      rendering={props.rendering}
      orderRelatedInfo={orderRelatedInfo}
      pageContext={pageContext}
      retrieveCustomerInfo={retrieveCustomerInfo}
      isNotEncrypt={isNotEncrypt}
      isFirstTimeDisplay={isFirstTimeDisplay}
      onChangeEventInput={onChangeEventInput}
      setInputValues={setInputValues}
      inputValues={inputValues}
      orderCategory={orderCategory}
      cMAPContractName={cMAPContractName}
      urlParentItem={urlParentItem}
      isLoginStatus={isLoginStatus}
    />
  );
  const RegisterContactAddressView = (
    <RegisterContactAddress
      rendering={props.rendering}
      orderRelatedInfo={orderRelatedInfo}
      setIsLoading={props.setIsLoading}
      graphQlData={graphQlData}
      orderCategory={orderCategory}
      planType={planType}
      retrieveCustomerInfo={retrieveCustomerInfo}
      isFirstTimeDisplay={isFirstTimeDisplay}
      isNotEncrypt={isNotEncrypt}
      accountId={accountId}
      onChangeEventInput={onChangeEventInput}
      setInputValues={setInputValues}
      inputValues={inputValues}
      cMAPContractName={cMAPContractName}
      isTypeCircuit={isTypeCircuit}
      metadataDefinitions={metadataDefinitions}
      pageContext={pageContext}
      sitecoreContext={sitecoreContext}
      properties={props}
      urlParentItem={urlParentItem}
      retrieveLoginStatusData={retrieveLoginStatusData}
      isLoginStatus={isLoginStatus}
    />
  );
  const SelectPreferredTransferDateView = (
    <SelectPreferrredTransferDate
      rendering={props.rendering}
      isNotEncrypt={isNotEncrypt}
      setInputValues={setInputValues}
      inputValues={inputValues}
      isFirstTimeDisplay={isFirstTimeDisplay}
      urlParentItem={urlParentItem}
      retrieveSystemDateTimeData={retrieveSystemDateTimeData}
      retrieveSelectableDesiredDateRangeData={
        retrieveSelectableDesiredDateRangeData
      }
    />
  );
  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <Placeholder
                name="warning-modal-section"
                rendering={props.rendering}
              />
              {(urlParentItem === STRING_APPLY ||
                urlParentItem === STRING_SIMULATOR) && (
                <>
                  {RegisterInstallationAddressView}
                  {accountId !== "" &&
                    cMAPContractName === STRING_INDIVIDUAL &&
                    RegisterIndividualCustomerInfoView}
                  {accountId !== "" &&
                    cMAPContractName === STRING_CORPORATION &&
                    RegisterCorporateCustomerInfoView}
                  {accountId === "" && RegisterIndividualCustomerInfoView}
                  {RegisterContactAddressView}
                </>
              )}

              {(urlParentItem === STRING_MOVING ||
                urlParentItem === STRING_REBUILDING) && (
                <>
                  {SelectPreferredTransferDateView}
                  {RegisterInstallationAddressView}
                  {cMAPContractName === STRING_INDIVIDUAL &&
                    RegisterIndividualCustomerInfoView}
                  {cMAPContractName === STRING_CORPORATION &&
                    RegisterCorporateCustomerInfoView}
                  {RegisterContactAddressView}
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(CustomerInfoLayout);
