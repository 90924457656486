import React, { useState } from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  getProperData,
  redirectToLinkUrl,
} from "../../utils/commonUtils";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import { useCustomQuery } from "../../hooks/useGetData";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import { apiBaseURL } from "../../envSettings";

const InputRoomNumberLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  const [selectedRoomNumber, setSelectedRoomNumber] = useState("");
  const orderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );
  const propertyOfferMethod =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_PropertyOfferMethod__c;
  const retrieveApartmentRoomNoListQuery = `${apiBaseURL}/order/RetrieveApartmentRoomNoList?Id=${propertyOfferMethod}`;
  const {
    data: retrieveApartmentRoomNoListData,
    isLoading: retrieveApartmentRoomNoListLoading,
    error: customerInfoError,
    refetch: retrieveApartmentRoomNoListRefetch,
    remove: retrieveApartmentRoomNoListRemove,
  } = useCustomQuery(retrieveApartmentRoomNoListQuery, true);

  props.setIsLoading(retrieveApartmentRoomNoListLoading);

  const mapInputNumberFields = (props, roomListOptions) => {
    let inputRoomNumberSection = getScDataFromPlaceholder(
      "input-room-number-section",
      props
    );
    const [m01Data] = getScDataFromComponent("M-01", inputRoomNumberSection);
    const [c01Data] = getScDataFromComponent("C-01", inputRoomNumberSection);
    const sitecoreFields = props?.sitecoreContext?.route?.fields;
    const apartmentAddress = getSessionStorageItem(
      keys.SSN_APPLICATION_APARTMENTSEARCH_APARTMENTADDRESS,
      true
    );
    const apartmentName = getSessionStorageItem(
      keys.SSN_APPLICATION_APARTMENTSEARCH_APARTMENTNAME,
      true
    );
    const applicationRoomNumber = getSessionStorageItem(
      keys.SSN_APPLICATION_INPUTROOMNUMBER_ROOMNUMBER,
      true
    );
    m01Data.fields.placeholderText =
      sitecoreFields["InputRoomNumber-RoomNumberPlaceholder"]?.value;
    m01Data.fields.formName = "inputRoomNumberList";

    if (roomListOptions && roomListOptions?.length > 0) {
      const fixFormat = roomListOptions.map((item) => {
        return {
          name: item.RoomNumber__c,
          value: item.RoomNumber__c,
          data: item.RoomNumber__c,
        };
      });
      m01Data.fields.pulldownData = fixFormat;

      if (applicationRoomNumber && applicationRoomNumber !== "") {
        const matchedRoomNumber = roomListOptions.filter(
          (item) => item.RoomNumber__c === applicationRoomNumber
        );

        if (matchedRoomNumber && matchedRoomNumber?.length) {
          m01Data.fields.initialValue = {
            value: matchedRoomNumber[0]?.RoomNumber__c,
          };
        }
      }
    }

    m01Data.fields.onChangeEvent = (event) => {
      setSelectedRoomNumber(event?.target?.value);
    };

    c01Data.fields[
      "contentText"
    ] = `${apartmentAddress} <br /> ${apartmentName}`;
  };

  mapInputNumberFields(
    props,
    retrieveApartmentRoomNoListData?.everyHouseProperties
  );

  const buttonSection = getScDataFromPlaceholder("btn_container", props);
  const [backButton] = getScDataFromComponent("B-02", buttonSection);
  backButton.fields.onClickEvent = () => {
    setSessionStorageItem(
      keys.SSN_APPLICATION_INPUTROOMNUMBER_ROOMNUMBER,
      selectedRoomNumber,
      true
    );

    redirectToPreviousPage();
  };
  const [nextButton] = getScDataFromComponent("B-01", buttonSection);
  nextButton.fields.onClickEvent = () => {
    setSessionStorageItem(
      keys.SSN_APPLICATION_INPUTROOMNUMBER_ROOMNUMBER,
      selectedRoomNumber,
      true
    );
    const { linkURL } = getProperData(props.sitecoreContext, nextButton.fields);
    redirectToLinkUrl(linkURL);
  };

  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <div className="form_wrapper-input">
                <Placeholder
                  name="input-room-number-section"
                  rendering={props.rendering}
                />
              </div>
              <div className="btn_container">
                <Placeholder name="btn_container" rendering={props.rendering} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(InputRoomNumberLayout);
