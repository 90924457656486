import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from "../../utils/commonUtils";
import { useCustomQuery } from "../../hooks/useGetData";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { apiBaseURL } from "../../envSettings";

const BR01 = (props) => {
  // get the data
  let pageId = props?.sitecoreContext?.itemId;
  let query = `${apiBaseURL}common/RetrieveBreadcrumbs?inputParam={${pageId}}`;
  const {
    data: breadCrumbData,
    isLoading: breadCrumbLoading,
    error: customerInfoError,
    refetch: breadCrumbRefetch,
    remove: breadCrumbRemove,
  } = useCustomQuery(query);

  // @Controller
  let breadcrumbList = [];
  if (breadCrumbData) {
    let _breadcrumbNameList = breadCrumbData?.BreadcrumbList?.reverse()
      .filter((v) => v.Name !== null)
      .map((v) => {
        let p = v.Path.substring(v.Path.lastIndexOf("/") + 1);
        return { Name: v.Name, Path: p };
      });
    breadcrumbList = [
      ...[{ Name: "MyコミュファTOP", Path: "/" }],
      ..._breadcrumbNameList,
    ];
  }

  // @View
  return (
    <React.Fragment>
      <nav className="breadcrumb BR-01">
        <ol className="breadcrumb_list">
          {breadcrumbList.map((item, i) => (
            <li className="breadcrumb-item" key={i}>
              <div className="navigation-title ">
                <a href={item.Path}>
                  <Text field={fillBlank(item.Name)} />
                </a>
              </div>
              {breadcrumbList.length - 1 !== i && (
                <span className="separator">&gt;</span>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </React.Fragment>
  );
};

export default withSitecoreContext()(BR01);
