import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
} from "../../utils/commonUtils";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import {
  STRING_APPLY,
  STRING_CHANGEPLAN,
  STRING_INDIVIDUAL,
  STRING_MOVING,
  STRING_REBUILDING,
  STRING_SIMULATOR,
} from "../../utils/constantVariables";
import { getSessionStorageItem } from "../../utils/useSessionStorage";
import { useSetSelectedItem } from "../../hooks/useSelectedState";

export function RegisterIndividualCustomerInfo(props) {
  const {
    orderRelatedInfo,
    onChangeEventInput,
    pageContext,
    inputValues,
    setInputValues,
    retrieveCustomerInfo,
    isNotEncrypt,
    isFirstTimeDisplay,
    cMAPContractName,
    metadataDefinitions,
    urlParentItem,
    isLoginStatus,
  } = props;

  const registerIndividualCustomerInfo = getScDataFromPlaceholder(
    "customer-info-section",
    props
  );
  const [firstLastNameField, furiganaNameField] = getScDataFromComponent(
    "I-01",
    registerIndividualCustomerInfo
  );
  const [s01Data] = getScDataFromComponent(
    "S-01",
    registerIndividualCustomerInfo
  );
  const [birthDateField] = getScDataFromComponent(
    "M-01i",
    registerIndividualCustomerInfo
  );

  const ssnLastName = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_LASTNAME,
    isNotEncrypt
  );
  const ssnFirstName = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_FIRSTNAME,
    isNotEncrypt
  );
  const ssnLastNameKana = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_LASTNAMEINKANA,
    isNotEncrypt
  );
  const ssnFirstNameKana = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_FIRSTNAMEINKANA,
    isNotEncrypt
  );
  const ssnGender = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_GENDER,
    isNotEncrypt
  );
  const ssnBirthDate = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_BIRTHYEARMONTHDATE,
    isNotEncrypt
  );

  let individualCustomerInfoLastName = "",
    individualCustomerInfoFirstName = "",
    individualCustomerInfoLastNameFurigana = "",
    individualCustomerInfoFirstNameFurigana = "",
    individualCustomerInfoGender = "",
    individualCustomerInfoDateOfBirth = "",
    birthDateFieldIsDisabled = false;

  const orderRelatedInfoAccount =
    orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account;

  useEffect(() => {
    if (isFirstTimeDisplay) {
      if (
        (urlParentItem === STRING_APPLY ||
          urlParentItem === STRING_SIMULATOR) &&
        isLoginStatus
      ) {
        const account = retrieveCustomerInfo?.accountInfo?.account;
        if (account) {
          individualCustomerInfoLastName = account?.LastName;
          individualCustomerInfoFirstName = account?.FirstName;
          individualCustomerInfoLastNameFurigana = account?.LastNameKana__pc;
          individualCustomerInfoFirstNameFurigana = account?.FirstNameKana__pc;
          individualCustomerInfoGender = account?.CMAP_Gender__c;
          individualCustomerInfoDateOfBirth = account?.PersonBirthdate;
        }
      } else if (
        urlParentItem !== STRING_APPLY &&
        urlParentItem !== STRING_SIMULATOR
      ) {
        individualCustomerInfoLastName = orderRelatedInfoAccount?.LastName;
        individualCustomerInfoFirstName = orderRelatedInfoAccount?.FirstName;
        individualCustomerInfoLastNameFurigana =
          orderRelatedInfoAccount?.LastNameKana__pc;
        individualCustomerInfoFirstNameFurigana =
          orderRelatedInfoAccount?.FirstNameKana__pc;
        individualCustomerInfoGender = orderRelatedInfoAccount?.CMAP_Gender__c;
        individualCustomerInfoDateOfBirth =
          orderRelatedInfoAccount?.PersonBirthdate;
      }
    } else {
      individualCustomerInfoLastName = ssnLastName;
      individualCustomerInfoFirstName = ssnFirstName;
      individualCustomerInfoLastNameFurigana = ssnLastNameKana;
      individualCustomerInfoFirstNameFurigana = ssnFirstNameKana;
      individualCustomerInfoGender = ssnGender;
      individualCustomerInfoDateOfBirth = ssnBirthDate;
    }
  }, [retrieveCustomerInfo, isLoginStatus]);

  useEffect(() => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        registerIndividualCustomerInfoLastName: individualCustomerInfoLastName,
        registerIndividualCustomerInfoFirstName: individualCustomerInfoFirstName,
        registerIndividualCustomerInfoLastNameFurigana: individualCustomerInfoLastNameFurigana,
        registerIndividualCustomerInfoFirstNameFurigana: individualCustomerInfoFirstNameFurigana,
        registerIndividualCustomerInfoBirthDate: individualCustomerInfoDateOfBirth,
        registerIndividualCustomerInfoGender: individualCustomerInfoGender,
      };
    });
  }, [retrieveCustomerInfo]);

  if (
    (urlParentItem === STRING_MOVING ||
      urlParentItem === STRING_REBUILDING ||
      urlParentItem === STRING_CHANGEPLAN) &&
    cMAPContractName === STRING_INDIVIDUAL
  ) {
    firstLastNameField.fields.inputValue = { isDisabled: true };
    furiganaNameField.fields.inputValue = { isDisabled: true };
    s01Data.fields.inputValue = { isDisabled: true };
    birthDateFieldIsDisabled = true;
  }

  firstLastNameField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };

  furiganaNameField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  const firstLastNameData = [
    {
      fields: {
        Value: {
          value:
            pageContext["RegisterIndividualCustomerInfo-LastNamePlaceholder"]
              ?.value,
        },
        inputValue: {
          value: inputValues.registerIndividualCustomerInfoLastName,
          name: "registerIndividualCustomerInfoLastName",
        },
      },
    },
    {
      fields: {
        Value: {
          value:
            pageContext["RegisterIndividualCustomerInfo-FirstNamePlaceholder"]
              ?.value,
        },
        inputValue: {
          value: inputValues.registerIndividualCustomerInfoFirstName,
          name: "registerIndividualCustomerInfoFirstName",
        },
      },
    },
  ];

  firstLastNameField.fields.dataRowList = firstLastNameData;
  const furiganaNameData = [
    {
      fields: {
        Value: {
          value:
            pageContext[
              "RegisterIndividualCustomerInfo-LastNameFuriganaPlaceholder"
            ]?.value,
        },
        inputValue: {
          value: inputValues.registerIndividualCustomerInfoLastNameFurigana,
          name: "registerIndividualCustomerInfoLastNameFurigana",
        },
      },
    },
    {
      fields: {
        Value: {
          value:
            pageContext[
              "RegisterIndividualCustomerInfo-FirstNameFuriganaPlaceholder"
            ]?.value,
        },
        inputValue: {
          value: inputValues.registerIndividualCustomerInfoFirstNameFurigana,
          name: "registerIndividualCustomerInfoFirstNameFurigana",
        },
      },
    },
  ];

  furiganaNameField.fields.dataRowList = furiganaNameData;

  birthDateField.fields.initialValue = {
    value: inputValues.registerIndividualCustomerInfoBirthDate,
  };
  birthDateField.fields.onChangeEvent = (date) => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        registerIndividualCustomerInfoBirthDate: format(date, "yyyy-MM-dd"),
      };
    });
  };

  birthDateField.fields.inputValue = {
    name: "registerIndividualCustomerInfoBirthDate",
    value: inputValues.registerIndividualCustomerInfoBirthDate,
    isDisabled: birthDateFieldIsDisabled,
  };

  const [genderRadioOption, setGenderRadioOption] = useState([]);
  const selectGenderCategory = "GenderType";
  s01Data.fields["category"] = { value: selectGenderCategory };
  s01Data.fields["selections"] = genderRadioOption;
  const [selectedGenderType, setSelectedGenderType] = useSetSelectedItem(
    selectGenderCategory
  );
  useEffect(() => {
    if (metadataDefinitions) {
      const CMAP_Gender__c = metadataDefinitions?.Account?.fieldMetadataDefinitions?.CMAP_Gender__c?.fieldMetadata?.fieldMetadataExtension?.CMAP_Option__c.split(
        ","
      );
      if (CMAP_Gender__c?.length !== 0) {
        const genderTypes = CMAP_Gender__c?.map((value, i) => {
          const item = value.split(":");
          const itemArr = { Code: item[0], Value: item[1] };
          return itemArr;
        });
        setGenderRadioOption(genderTypes);

        if (inputValues.registerIndividualCustomerInfoGender && genderTypes) {
          genderTypes.forEach((item) => {
            if (
              item.Code === inputValues.registerIndividualCustomerInfoGender
            ) {
              setSelectedGenderType({
                [selectGenderCategory]: item.Code,
              });
            }
          });
        }
      }
    }
  }, [metadataDefinitions]);

  useEffect(() => {
    if (selectedGenderType) {
      setInputValues((prevState) => {
        return {
          ...prevState,
          registerIndividualCustomerInfoGender: selectedGenderType?.selected,
        };
      });
    }
  }, [selectedGenderType]);

  return (
    <div className="form_wrapper-input" name="customer-info-section">
      <Placeholder name="customer-info-section" rendering={props.rendering} />
    </div>
  );
}
