import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { di_register } from "../../utils/di";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
  getProperData,
  redirectToLinkUrl
} from "../../utils/commonUtils";
import { getErrorMessage } from "../../utils/errorMessageHandler";
import { validateIsValidDateFormat } from "../../utils/validations";

import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { apiBaseURL } from "../../envSettings";
import { useFormContext } from "react-hook-form";
import { useCustomQuery } from "../../hooks/useGetData";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import { STRING_GET, STRING_POST } from "../../utils/constantVariables";

export default function CommonSelectPreferredDate(props) {

  const { setError, clearErrors } = useFormContext();
  // @Model
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);
  // get the data

  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  let orderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );

  let isUpdateAddRequestDate = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_COMMONSELECTPREFERREDDATE_ISUPDATEADDREQUESTDATE,
    true
  );

  let IsUpdateLastUtilizationDate = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_COMMONSELECTPREFERREDDATE_ISUPDATELASTUTILIZATIONDATE,
    true
  );

  let productChangeAddRequestDate = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_COMMONSELECTPREFERREDDATE_PRODUCTCHANGEADDREQUESTDATE,
    true
  );
  let productChangeLastUtilizationDate = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_COMMONSELECTPREFERREDDATE_PRODUCTCHANGELASTUTILIZATIONDATE,
    true
  );

  const systemDateTimeURI = `${apiBaseURL}common/RetrieveSystemDateTime`;
  const RetrieveSelectableDesiredDateRangeURI = `${apiBaseURL}order/RetrieveSelectableDesiredDateRange`;

  const {
    data: systemDateTimeData,
    isLoading: systemDateTimeIsLoading,
    error: systemDateTimeError,
    refetch: systemDateTimeRefresh,
    remove: systemDateTimeRemove,
  } = useCustomQuery(systemDateTimeURI, true, STRING_GET);

  // payloads
  const orderItemInfosData = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos?.map(
    (item) => {
      return {
        orderItem: {
          Id: item.orderItem.Id,
          Product2Id: item.orderItem.Product2Id,
          CMAP_ProductSelectionState__c:
            item.orderItem.CMAP_ProductSelectionState__c,
          CMAP_LastTimeChangeCategory__c:
            item.orderItem.CMAP_LastTimeChangeCategory__c,
        },
      };
    }
  );

  const effectiveDate = systemDateTimeData?.SystemDateTime?.split("T")[0];

  const RetrieveSelectableDesiredDateRangeBody = {
    orderRelatedInfo: {
      orderInfo: {
        order: {
          CMAP_OrderCategory__c:
            orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
              ?.CMAP_OrderCategory__c,
          CMAP_OrderBeforeChange__c:
            orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
              ?.CMAP_OrderBeforeChange__c,
          EffectiveDate: effectiveDate
        },
        orderAddition: {
          orderCategoryCurrentlySelected:
            orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition
              ?.orderCategoryCurrentlySelected,
          orderCategoryPreviouslySelected:
            orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition
              ?.orderCategoryPreviouslySelected,
        },
        orderItemInfos: orderItemInfosData,
      },
    },
  };

  const {
    data: RetrieveSelectableDesiredDateRangeData,
    isLoading: RetrieveSelectableDesiredDateRangeIsLoading,
    error: RetrieveSelectableDesiredDateRangeError,
    refetch: RetrieveSelectableDesiredDateRangeRefresh,
    remove: RetrieveSelectableDesiredDateRangeRemove,
  } = useCustomQuery(
    RetrieveSelectableDesiredDateRangeURI,
    false,
    STRING_POST,
    RetrieveSelectableDesiredDateRangeBody
  );

  useEffect(() => {
    if (systemDateTimeData) {
      RetrieveSelectableDesiredDateRangeRefresh();
    }    
  }, [systemDateTimeData]);

  const isApiLoading = systemDateTimeIsLoading || RetrieveSelectableDesiredDateRangeIsLoading;
  props.setIsLoading(isApiLoading);

  const RetrieveAddDateWithProductChange =
    RetrieveSelectableDesiredDateRangeData?.selectablePreferDatesRangeInfo
      ?.displayAddDateWithProductChange;
  const RetrieveEndDateWithProductChange =
    RetrieveSelectableDesiredDateRangeData?.selectablePreferDatesRangeInfo
      ?.displayEndDateWithProductChange;

  const [changingAddingProduct, setChangingAddingProduct] = useState(false);
  const [changingEndingProduct, setChangingLastUtilization] = useState(false);
  const [
    changingAddingEndingProduct,
    setchangingAddingEndingProduct,
  ] = useState(false);
  // @Controller
  useEffect(() => {
    if (RetrieveAddDateWithProductChange === true) {
      setChangingAddingProduct(true);
    }

    if (RetrieveEndDateWithProductChange === true) {
      setChangingLastUtilization(true);
    }

    if (
      (RetrieveAddDateWithProductChange === true &&
        RetrieveEndDateWithProductChange === true) ||
      (RetrieveAddDateWithProductChange === false &&
        RetrieveEndDateWithProductChange === true) ||
      (RetrieveAddDateWithProductChange === true &&
        RetrieveEndDateWithProductChange === false)
    ) {
      setchangingAddingEndingProduct(true);
    }
  }, [RetrieveAddDateWithProductChange, RetrieveEndDateWithProductChange]);

  const productChangeAddRequestDateData = productChangeAddRequestDate;

  const productChangeLastUtilizationDateData = productChangeLastUtilizationDate;

  const preferredDateOfProductChangeSection = getScDataFromPlaceholder(
    "common-select-preferred-date-of-product-change-section",
    props
  );

  const [inputAddValues, setInputAddValues] = useState({ productAddDate: "" });

  const [M01iDataPreferredDateOfProductChangeSection] = getScDataFromComponent(
    "M-01i",
    preferredDateOfProductChangeSection
  );

  const [S09DataPreferredDateOfProductChangeSection] = getScDataFromComponent(
    "S-09",
    preferredDateOfProductChangeSection
  );

  // update the context
  M01iDataPreferredDateOfProductChangeSection.fields.onChangeEvent = (date) => {
    setInputAddValues((prevState) => {
      return {
        ...prevState,
        productAddDate: format(date, "yyyy-MM-dd"),
      };
    });
  };

  const [
    checkBoxStatusProductChangeSection,
    setCheckBoxStatusProductChangeSection,
  ] = useState("");

  const [
    minDateProductChangeSection,
    setMinDateProductChangeSection,
  ] = useState();

  const [
    maxDateProductChangeSection,
    maxMinDateProductChangeSection,
  ] = useState();

  // set min and max date
  useEffect(() => {
    if (productChangeAddRequestDateData) {
      setInputAddValues((prevState) => {
        return {
          ...prevState,
          productAddDate: productChangeAddRequestDateData,
        };
      });
    }
  }, []);

  // update the context
  // checkbox first load
  const checkBoxProductChangeSection = () => {
    if (isUpdateAddRequestDate === true) {
      S09DataPreferredDateOfProductChangeSection.fields.checkBoxIsCheck = true;
      const checkBox = true;
      return checkBox;
    } else {
      S09DataPreferredDateOfProductChangeSection.fields.checkBoxIsCheck = false;
      const checkBox = false;
      return checkBox;
    }
  };

  useEffect(() => {
    setCheckBoxStatusProductChangeSection(checkBoxProductChangeSection());
  }, []);

  // checkbox on click event
  S09DataPreferredDateOfProductChangeSection.fields.onChangeEvent = (data) => {
    S09DataPreferredDateOfProductChangeSection.fields.checkBoxIsCheck = data;

    setCheckBoxStatusProductChangeSection(data);
  };

  // min and max date range
  useEffect(() => {
    if (checkBoxStatusProductChangeSection) {
      const minDate =
        RetrieveSelectableDesiredDateRangeData?.selectablePreferDatesRangeInfo
          ?.minPreferDate;

      const maxDate =
        RetrieveSelectableDesiredDateRangeData?.selectablePreferDatesRangeInfo
          ?.maxPreferDate;

      setMinDateProductChangeSection(minDate);
      maxMinDateProductChangeSection(maxDate);
    } else {
      const minDate =
        RetrieveSelectableDesiredDateRangeData?.selectablePreferDatesRangeInfo
          ?.minPreferDateWithProductChange;

      const maxDate =
        RetrieveSelectableDesiredDateRangeData?.selectablePreferDatesRangeInfo
          ?.maxPreferDateWithProductChange;

      setMinDateProductChangeSection(minDate);
      maxMinDateProductChangeSection(maxDate);
    }
  }, [
    RetrieveSelectableDesiredDateRangeData,
    checkBoxStatusProductChangeSection,
  ]);

  M01iDataPreferredDateOfProductChangeSection.fields.minDate = minDateProductChangeSection;
  M01iDataPreferredDateOfProductChangeSection.fields.maxDate = maxDateProductChangeSection;

  M01iDataPreferredDateOfProductChangeSection.fields.initialValue = {
    value: inputAddValues.productAddDate,
  };

  const preferredDateOfLastUtilizationSection = getScDataFromPlaceholder(
    "common-select-preferred-date-of-last-utilization-section",
    props
  );

  const [inputLastValues, setInputLastValues] = useState({
    productLastDate: "",
  });

  const [
    M01iDataPreferredDateOfLastUtilizationSection,
  ] = getScDataFromComponent("M-01i", preferredDateOfLastUtilizationSection);

  const [S09DataPreferredDateOfLastUtilizationSection] = getScDataFromComponent(
    "S-09",
    preferredDateOfLastUtilizationSection
  );

  S09DataPreferredDateOfLastUtilizationSection.fields["checkboxLast"] = {
    value: "checkboxLastUtilizationSection",
  };

  // update the context
  M01iDataPreferredDateOfLastUtilizationSection.fields.onChangeEvent = (
    date
  ) => {
    setInputLastValues((prevLastState) => {
      return {
        ...prevLastState,
        productLastDate: format(date, "yyyy-MM-dd"),
      };
    });
  };

  const [
    checkBoxStatusLastUtilizationSection,
    setCheckBoxStatusLastUtilizationSection,
  ] = useState("");

  const [
    minDateLastUtilizationSection,
    setMinDateLastUtilizationSection,
  ] = useState();

  const [
    maxDateLastUtilizationSection,
    setMaxDateLastUtilizationSection,
  ] = useState();

  // set min and max date
  useEffect(() => {
    if (productChangeLastUtilizationDateData) {
      setInputLastValues((prevLastState) => {
        return {
          ...prevLastState,
          productLastDate: productChangeLastUtilizationDateData,
        };
      });
    }
  }, []);

  // update the context
  // checkbox first load
  const checkBoxLastUtilizationDateData = () => {
    if (IsUpdateLastUtilizationDate === true) {
      S09DataPreferredDateOfLastUtilizationSection.fields.checkBoxIsCheck = true;
      const checkBox = true;
      return checkBox;
    } else {
      S09DataPreferredDateOfLastUtilizationSection.fields.checkBoxIsCheck = false;
      const checkBox = false;
      return checkBox;
    }
  };

  useEffect(() => {
    setCheckBoxStatusLastUtilizationSection(checkBoxLastUtilizationDateData());
  }, []);

  // checkbox on click event
  S09DataPreferredDateOfLastUtilizationSection.fields.onChangeEvent = (
    data
  ) => {
    S09DataPreferredDateOfLastUtilizationSection.fields.checkBoxIsCheck = data;

    setCheckBoxStatusLastUtilizationSection(data);
  };

  // min and max date range
  useEffect(() => {
    if (checkBoxStatusLastUtilizationSection) {
      const minDate =
        RetrieveSelectableDesiredDateRangeData?.selectablePreferDatesRangeInfo
          ?.minEndDate;

      const maxDate =
        RetrieveSelectableDesiredDateRangeData?.selectablePreferDatesRangeInfo
          ?.maxEndDate;

      setMinDateLastUtilizationSection(minDate);
      setMaxDateLastUtilizationSection(maxDate);
    } else {
      const minDate =
        RetrieveSelectableDesiredDateRangeData?.selectablePreferDatesRangeInfo
          ?.minEndDateWithProductChange;

      const maxDate =
        RetrieveSelectableDesiredDateRangeData?.selectablePreferDatesRangeInfo
          ?.maxEndDateWithProductChange;

      setMinDateLastUtilizationSection(minDate);
      setMaxDateLastUtilizationSection(maxDate);
    }
  }, [
    RetrieveSelectableDesiredDateRangeData,
    checkBoxStatusLastUtilizationSection,
  ]);

  M01iDataPreferredDateOfLastUtilizationSection.fields.minDate = minDateLastUtilizationSection;
  M01iDataPreferredDateOfLastUtilizationSection.fields.maxDate = maxDateLastUtilizationSection;

  M01iDataPreferredDateOfLastUtilizationSection.fields.initialValue = {
    value: inputLastValues.productLastDate,
  };

  const changingProductDateButton = getScDataFromPlaceholder(
    "changing-product-date-button",
    props
  );

  const [backButton] = getScDataFromComponent(
    "B-02",
    changingProductDateButton
  );
  // update the context
  backButton.fields.onClickEvent = () => {
    clearErrors();

    setSessionStorageItem(
      keys.SSN_APPLICATION_COMMONSELECTPREFERREDDATE_PRODUCTCHANGEADDREQUESTDATE,
      inputAddValues.productAddDate,
      true
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_COMMONSELECTPREFERREDDATE_ISUPDATEADDREQUESTDATE,
      checkBoxStatusProductChangeSection,
      true
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_COMMONSELECTPREFERREDDATE_PRODUCTCHANGELASTUTILIZATIONDATE,
      inputLastValues.productLastDate,
      true
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_COMMONSELECTPREFERREDDATE_ISUPDATELASTUTILIZATIONDATE,
      checkBoxStatusLastUtilizationSection,
      true
    );

    redirectToPreviousPage();
  };

  const [nextButton] = getScDataFromComponent(
    "B-01",
    changingProductDateButton)

  nextButton.fields.onClickEvent = async () => {
    clearErrors();
    const { linkURL } = getProperData(props.sitecoreContext, nextButton.fields);
    let hasError = false;
    if (
      inputAddValues.productAddDate === "" ||
      inputLastValues.productLastDate === ""
    ) {
      hasError = true;
      setError("preferredProductDate-1", {
        type: "manual",
        message: getErrorMessage("E0002", ["商品変更希望日選択カレンダー"]),
      });
    }
    if (
      !validateIsValidDateFormat(inputAddValues.productAddDate) ||
      !validateIsValidDateFormat(inputLastValues.productLastDate)
    ) {
      hasError = true;
      setError("preferredProductDate-2", {
        type: "manual",
        message: getErrorMessage("E0020", [
          "商品変更希望日選択カレンダー",
          "yyyy年MM月dd日(w)",
        ]),
      });
    } 
    else{
        if (new Date(inputAddValues.productAddDate) > new Date(maxDateProductChangeSection)) {
          hasError = true;
          setError("preferredProductDate-3", {
            type: "manual",
            message: getErrorMessage("E0022", ["商品追加希望日選択カレンダー", maxDateProductChangeSection]),
          });
        }
        if (new Date(inputAddValues.productAddDate) < new Date(minDateProductChangeSection)) {
          hasError = true;
          setError("preferredProductDate-3", {
            type: "manual",
            message: getErrorMessage("E0021", ["商品追加希望日選択カレンダー", minDateProductChangeSection]),
          });
        }

        if (new Date(inputLastValues.productLastDate) > new Date(maxDateLastUtilizationSection)) {
          hasError = true;
          setError("preferredProductDate-4", {
            type: "manual",
            message: getErrorMessage("E0022", ["最終利用日選択カレンダー", maxDateLastUtilizationSection]),
          });
        }
        if (new Date(inputLastValues.productLastDate) < new Date(minDateLastUtilizationSection)) {
          hasError = true;
          setError("preferredProductDate-4", {
            type: "manual",
            message: getErrorMessage("E0021", ["最終利用日選択カレンダー", minDateLastUtilizationSection]),
          });
        }
    }

    


    if (!hasError){
       // update the context
       orderRelatedInfo = {
        ...orderRelatedInfo,
        orderRelatedInfo: {
          ...orderRelatedInfo?.orderRelatedInfo,
          orderInfo: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
            order: {
              ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
              CMAP_ProductChangeAddRequestDate__c:
                inputAddValues.productAddDate,
              CMAP_UpdateAddRequestDate__c: checkBoxStatusProductChangeSection,
              CMAP_ProductChangeLastUtilizationDate__c:
                inputLastValues.productLastDate,
              CMAP_UpdateLastUtilizationDate__c: checkBoxStatusLastUtilizationSection,
            },
          },
        },
      };

      setSessionStorageItem(
        keys.SSN_APPLICATION_COMMONSELECTPREFERREDDATE_PRODUCTCHANGEADDREQUESTDATE,
        inputAddValues.productAddDate,
        true
      );
      setSessionStorageItem(
        keys.SSN_APPLICATION_COMMONSELECTPREFERREDDATE_ISUPDATEADDREQUESTDATE,
        checkBoxStatusProductChangeSection,
        true
      );

      setSessionStorageItem(
        keys.SSN_APPLICATION_COMMONSELECTPREFERREDDATE_PRODUCTCHANGELASTUTILIZATIONDATE,
        inputLastValues.productLastDate,
        true
      );
      setSessionStorageItem(
        keys.SSN_APPLICATION_COMMONSELECTPREFERREDDATE_ISUPDATELASTUTILIZATIONDATE,
        checkBoxStatusLastUtilizationSection,
        true
      );

      setSessionStorageItem(
        keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
        orderRelatedInfo,
        true
      );
      redirectToLinkUrl(linkURL);
    }
  };
  // @View
  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <div className="form_wrapper-input">
                <Placeholder
                  name="common-select-preferred-date-section"
                  rendering={props.rendering}
                />

                {changingAddingEndingProduct === false ? (
                  <>
                    <Placeholder
                      name="common-select-preferred-date-message-section"
                      rendering={props.rendering}
                    />
                  </>
                ) : (
                  <>
                    {changingAddingProduct && (
                      <Placeholder
                        name="common-select-preferred-date-of-product-change-section"
                        rendering={props.rendering}
                      />
                    )}

                    {changingEndingProduct && (
                      <Placeholder
                        name="common-select-preferred-date-of-last-utilization-section"
                        rendering={props.rendering}
                      />
                    )}
                  </>
                )}
                <div className="btn_container">
                  <Placeholder
                    name="changing-product-date-button"
                    rendering={props.rendering}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
