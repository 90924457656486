import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import CommonSelectPreferredDate from "../../services/common/common-select-preferred-date";

const SelectPreferredRelocateDateLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  // @View
  return (
    <React.Fragment>
      <CommonSelectPreferredDate
        rendering={props.rendering}
        sitecoreContext={sitecoreContext}
        setIsLoading={props.setIsLoading}
      />
    </React.Fragment>
  );
};

export default withSitecoreContext()(SelectPreferredRelocateDateLayout);
