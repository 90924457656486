/*
 * 空判定
 *   args:
 *     val: 対象の文字列
 */
export const validateIsRequired = (val) => {
  if (val !== null && val !== undefined && val !== "") {
    if (Array.isArray(val)) {
      return val.length > 0;
    }
    if (typeof val === "object") {
      return Object.keys(val).length > 0;
    }
    return true;
  }
  return false;
};

/*
 * 数値判定
 * args:
 *  val: バリデーション対象
 */
export const validateIsNumber = (val) => {
  if(val !== "" && typeof val !== "undefined"){
    return /^\d+$/.test(val);
  }
  return false
};

/*
 * 固定長判定
 * args:
 *   val: バリデーション対象
 *   length: 確認する固定長の長さ
 */
export const validateIsFixedLength = (val, length = 0) => {
  return typeof val != "object" && val.toString().length === length;
};

export const validateIsMaxLength = (val, length = 0) => {
  if(val !== "" && typeof val !== "undefined"){
    return typeof val != "object" && val.toString().length <= length;
  }
  return true
};

export const validateIsFullWidth = (val) => {
  if (val.match(/^[^\x01-\x7E\uFF61-\uFF9F]+$/)) {
    return true;
  }
  return false;
};

export const validateIsFullWidthKana = (val) => {
  if (val.match(/^[\p{scx=Katakana}]+$/u)) {
    return true;
  }
  return false;
};

export const validateIsHalfWidthKana = (val) => {
  if (val.match(/^[ｦ-ﾟ]+$/)) {
    return true;
  }
  return false;
};

export const checkDate = (val) => {
  let date = new Date(val);
  return isNaN(date.getDate());
};

export const validateIsValidDateFormat = (val) => {
  let date_regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
  if (date_regex.test(val)) {
    return true;
  }
  return false;
};

export const validateCreditCardExpirationDate = (val) => {
  if (val.match(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/)) {
    return true;
  }
  return false;
};

export const validateEmailAddress = (val) => {
  if (
    val.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    return true;
  }
  return false;
};

export const validatePattern = (val, pattern) => {
  if(pattern !== null && pattern !== undefined && pattern !== ""){
    const newPattern = new RegExp(`${pattern}`);
    if (newPattern.test(val)) {
      return true;
    }
    else{
      return false;
    }
  }
  return true;
};


export const validationFuncs = {
  validateIsRequired,
  validateIsNumber,
  validateIsFixedLength,
  validateIsFullWidth,
  validateIsFullWidthKana,
  validateIsHalfWidthKana,
  validateIsMaxLength,
  validateCreditCardExpirationDate,
  validateEmailAddress,
  validatePattern
};
