import React from "react";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const C02 = (props) => {
  const { titleText, explanationText } = props.fields;

  return (
    <React.Fragment>
      <div className="decoration-content benefits C-02">
        <div className="content-title">
          <h5>
            <Text field={fillBlank(titleText)} />
          </h5>
        </div>
        <div className="benefits-image"></div>
        <p>
          <Text field={fillBlank(explanationText)} />
        </p>
        <div className="input-form">
          <Placeholder name="input-content" rendering={props.rendering} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default C02;
