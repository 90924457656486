import React from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

const ErrorPageLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <div className="form_wrapper-input">
                <RichText
                  field={
                    props.sitecoreContext.route.fields["ErrorInfo-Description"]
                  }
                />
                <div className="btn_container">
                  <Placeholder
                    name="error-back-button-section"
                    rendering={props.rendering}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(ErrorPageLayout);
