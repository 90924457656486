import { Text } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const S03 = (props) => {
  const { radioBoxLabel, tooltipText, priceText } = props.fields;

  return (
    <React.Fragment>
      <div className="input-form">
        <div className="input-box S-03">
          <div className="input-container">
            <input type="radio" name="rdQ2S1" />
            <label htmlFor="rdQ2S1">
              <dl>
                <dt>
                  <Text field={fillBlank(radioBoxLabel)} />
                </dt>
                <dd className="tool-tip">
                  <i className="bi bi-info-circle-fill"></i>
                  <p className="information">
                    <Text field={fillBlank(tooltipText)} />
                  </p>
                </dd>
              </dl>
              <span className="right">
                <p>
                  <Text field={fillBlank(priceText)} />
                </p>
                <span>円(税込)</span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default S03;
