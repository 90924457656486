import { atom, atomFamily, selectorFamily, useSetRecoilState, DefaultValue, useRecoilValue, useRecoilCallback, useRecoilState  } from 'recoil';
import { recoilKeys } from '../assets/recoilKeys';
import { selectedItemsAtom } from './useSelectedState';
//import { useMetadataMappingAction } from 'assets/hooks/useMetadataMappingTable';
//import { set } from 'react-hook-form';
import { getSessionStorageItem, setSessionStorageItem, removeSessionStorageItem } from '../utils/useSessionStorage';

//条件付き表示
export const visibleCompnentKeys = 'visibleComponent';

export const visibleComponentNames = () => {
  let savedValue = getSessionStorageItem(visibleCompnentKeys);
  return savedValue;
}

// (metadataの) itemName of Value: CMAP_FieldName__c of Value
// ex. CMAP_Package_c: Package
const metadataMappingsForItemName = atomFamily({
  key: recoilKeys.ATM_METADATA_MAPPINGTABLE,
  default: '',
  //effects: (item) => [setEffectersConditionFunc(item)]
});

// (metadataの) CMAP_FieldName__c of Value: itemName of Value
// ex. CMAP_Package_c: Package
const metadataMappingsForFieldName = atomFamily({
  key: recoilKeys.ATM_METADATA_MAPPINGTABLE_FROM_HUMABILITY,
  default: '',
  //effects: (item) => [setEffectersConditionFunc(item)]
});

// 値変更時、効果が及ぼされる対象のリスト用Atom
// 例；Aが変更されたとき、Bへ影響を及ぼす可能性がある場合
//  A: [B, ] 
const effecters = atomFamily({
  key: recoilKeys.ATM_METADATA_CONDITION_VISIBLE_EFFECTERS,
  default: [],
  //effects: (item) => [setEffectersFunc(item)]
});

// 効果が及ぼされる対象の条件
// 例：Bの値が必須になる条件が、AとCの値がAV、BVであるときの場合
// B: {logic:'AND', conditions: {A:AV, B:BV}]
const effectConditions = atomFamily({
  key: recoilKeys.ATM_METADATA_CONDITION_VISIBLE_EFFECTCONDITON,
  default: { logic: '', conditions: {} },
  //effects: (item) => [setEffectersConditionFunc(item)]
});

const visibleEffect = (key) => ({ setSelf, onSet }) => {
  let savedValue = getSessionStorageItem(visibleCompnentKeys);
  if (!savedValue) {
    savedValue = {};
  }
  if (savedValue && (key in savedValue)) {
    setSelf(savedValue[key]);
  }

  onSet((newValue) => {
    if (newValue instanceof DefaultValue) {
      removeSessionStorageItem(visibleCompnentKeys);
    } else {
      savedValue[key] = newValue;
      setSessionStorageItem(visibleCompnentKeys, JSON.stringify(savedValue));
    }
  });
}

const defaultVisibles = {
  OptionSection: false,
}

//該当項目の表示状態
const visible = atomFamily({
  key: recoilKeys.ATM_METADATA_CONDITION_VISIBLE,
  default: (param) => {
    if (param in defaultVisibles) return defaultVisibles[param];
    return true;
  },
  effects: (key) => [visibleEffect(key)]
});

const conditionSelector = selectorFamily({
  key: recoilKeys.SLT_METADATA_CONDITION_VISIBLE,
  get: (toFieldName) => ({ get }) => {
    //効果を及ぼされる項目名
    let targetFiledName = get(metadataMappingsForItemName(toFieldName));
    //let conditionsDef = get(effectConditions(targetFiledName));
    //let logic = conditionsDef?.logic?.trim().toLowerCase();
    //let conditions = conditionsDef?.conditions;

    let returnVal = get(visible(targetFiledName));
    /*
    let boolList = [];
    for (var fdname in conditions) {
      let effectVal = conditions[fdname];
      let tmpTargetVal = get(selectedItemsAtom(fdname));
      let result = effectVal==tmpTargetVal;
      boolList.push(result);
    }

    if (logic == 'and') {
      returnVal = boolList.every(v=>v);
    } else if (logic == 'or') {
      returnVal = boolList.some(v=>v);
    }
    */
    return returnVal;
  },
  set: (fromFieldName) => ({ get, set }, newVal) => {
    let targetFiledName = get(metadataMappingsForItemName(fromFieldName));
    if (!targetFiledName) {
      targetFiledName = fromFieldName;
    }

    // condition parse logic
    const innserFunc = (fromFieldName)=>{
      let conditionsDef = get(effectConditions(fromFieldName));
      let logic = conditionsDef?.logic?.trim().toLowerCase();
      let conditions = conditionsDef?.conditions;

      let returnVal = get(visible(fromFieldName));
      let boolList = [];
      for (var fdname in conditions) {
        let effectVal = conditions[fdname];
        let fname2 = get(metadataMappingsForFieldName(fdname));
        if (!fname2) {
          fname2 = fdname;
        }
        let tmpTargetValDict = get(selectedItemsAtom(fname2));
        let tmpTargetVal = tmpTargetValDict[fname2]
        let result = effectVal==tmpTargetVal;
        boolList.push(result);
      }

      if (logic == 'and') {
        returnVal = boolList.every(v=>v);
      } else if (logic == 'or') {
        returnVal = boolList.some(v=>v);
      }

      return returnVal;
    };

    // 項目が変更された場合、影響のある項目への処理
    //効果を及ぼす項目名
    let conditionTarget = get(effecters(targetFiledName));
    for (let i in conditionTarget) {
      let target = conditionTarget[i];
      let taregeResult = innserFunc(target);

      // 条件付き表示
      set(visible(target), taregeResult);
    }

    // 初回レンダリング用に、自身への処理
    let isMyVisible = innserFunc(fromFieldName);

    // 条件付き表示
    set(visible(fromFieldName), isMyVisible);

    return;
  },
});

//表示・非表示
export const useComponentVisible = (filedname) => {
  return useRecoilState(visible(filedname));
};
export const useComponentVisivleAction = () => {
  const useGetVisible = (componentName) => useRecoilValue(visible(componentName));

  //const useSetVisible = useRecoilCallback(({ set }) => (key, setVal) => {
    const useSetVisible = useRecoilCallback(({ set }) => (key, setVal) => {
    set(visible(key), setVal);
  });

  return [useGetVisible, useSetVisible];
}


// 条件付き表示のGetter / Setter
export const useMetadataConditionVisible = (fieldname) => {
  const getter = useRecoilValue(visible(fieldname));
  const setter = useSetRecoilState(conditionSelector(fieldname));

  return [getter, setter];
};

// 条件付き表示関係のアクション用関数
export const useMetadataConditionVisibleAction = () => {
  //effector(効果を及ぼす対象の)の追加処理
  const setEffectorVisible = useRecoilCallback(({ set }) => (key, addVal) => {
    set(effecters(key), (prev) => [...prev, addVal]);
  });

  //effectConditionsの追加処理
  const setEffectConditionVisible = useRecoilCallback(
    ({ set }) => (key, addVal) => {
      set(effectConditions(key), addVal);
      set(conditionSelector(key), addVal);
    }
  );

  // set Mappings
  // (metadataの) itemName of Value: CMAP_FieldName__c of Value
  // ex. CMAP_Package_c: Package
  const setMetadataMapperTableForItemName = useRecoilCallback(
    ({ set }) => (key, val) => {
      set(metadataMappingsForItemName(key), val);
    }
  );

  // set Mappings
  // (metadataの) CMAP_FieldName__c of Value: itemName of Value
  // ex. CMAP_Package_c: Package
  const setMetadataMappingsForFieldName = useRecoilCallback(
    ({ set }) => (key, val) => {
      set(metadataMappingsForFieldName(key), val)
    }
  );

  return {
    setEffectorVisible,
    setEffectConditionVisible,
    setMetadataMapperTableForItemName,
    setMetadataMappingsForFieldName
  };
};
