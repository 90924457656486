import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";

const SelectPreferredRelocateDate = (props) => (
  <>
    <div className="form_wrapper-input">
      <Placeholder name="relocate-date-section" rendering={props.rendering} />
    </div>
    <div className="btn_container">
      <Placeholder
        name="relocate-date-button-section"
        rendering={props.rendering}
      />
    </div>
  </>
);

const SelectPreferredDateOfChangingPlan = (props) => (
  <>
    <div className="form_wrapper-input">
      <Placeholder
        name="changing-plan-date-section"
        rendering={props.rendering}
      />
    </div>
    <div className="btn_container">
      <Placeholder
        name="changing-plan-date-button"
        rendering={props.rendering}
      />
    </div>
  </>
);

const SelectPreferredDateOfChangingProduct = (props) => (
  <>
    <div className="form_wrapper-input">
      <Placeholder
        name="changing-product-date-section"
        rendering={props.rendering}
      />
    </div>
    <div className="btn_container">
      <Placeholder
        name="changing-product-date-button"
        rendering={props.rendering}
      />
    </div>
  </>
);

const RegisterCommufaId = (props) => (
  <div className="form_wrapper-input">
    <Placeholder name="commufa-ID-section" rendering={props.rendering} />

    {/* TODO: Separate component */}
    <div className="input-form">
      <div className="list_wrapper note option-result-box">
        <div className="option-result-container">
          <Placeholder
            name="form-content-input-section-1"
            rendering={props.rendering}
          />
        </div>
        <div className="option-result-container">
          <Placeholder
            name="form-content-input-section-2"
            rendering={props.rendering}
          />
        </div>
      </div>
    </div>
  </div>
);

const RegisterInstallationAddress = (props) => (
  <div className="form_wrapper-input">
    <Placeholder
      name="commufa-service-area-section"
      rendering={props.rendering}
    />
  </div>
);

const RegisterIndividualCustomerInfo = (props) => (
  <div className="form_wrapper-input">
    <Placeholder name="customer-info-section" rendering={props.rendering} />
  </div>
);

const RegisterContactAddress = (props) => (
  <>
    <div className="form_wrapper-input">
      <Placeholder name="contact-info-section" rendering={props.rendering} />
    </div>

    <div className="btn_container">
      <Placeholder name="button-section" rendering={props.rendering} />
    </div>
  </>
);

const CustomerInfoCenterLayout = (props) => {
  return (
    <React.Fragment>
      <div className="form_detail full-width">
        <div className="form_wrapper">
          <SelectPreferredDateOfChangingPlan rendering={props.rendering} />
          <SelectPreferredDateOfChangingProduct rendering={props.rendering} />
          <SelectPreferredRelocateDate rendering={props.rendering} />
          <RegisterCommufaId rendering={props.rendering} />
          <RegisterInstallationAddress rendering={props.rendering} />
          <RegisterIndividualCustomerInfo rendering={props.rendering} />
          <RegisterContactAddress rendering={props.rendering} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerInfoCenterLayout;
