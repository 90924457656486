import React, { useContext } from "react";
import { RichText, Text} from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from "../../utils/commonUtils";

const ImportantMattersDescriptionModalLayout = (props) => {
  
  const ImportantMatterDescriptionItem = (item) => {
    const importantMatterDescription =
      item.importantMatterDescriptionInfo?.importantMatterDescription;
    const importantMatterDescriptionContents =
      item.importantMatterDescriptionInfo?.importantMatterDescriptionContents;
    return (
      <>
        <p className='title'>
          <i className="bi bi-info-circle-fill"></i>
          <Text field={fillBlank(importantMatterDescription?.Name)} />
        </p>
        {importantMatterDescriptionContents &&
          importantMatterDescriptionContents.length > 0 &&
          importantMatterDescriptionContents.map((content) =>
            ImportantMatterDescriptionContent(content)
          )}
        <br />
      </>
    );
  };

  const ImportantMatterDescriptionContent = (content) => {
    const CMAP_Title__c = content.CMAP_Title__c;
    const CMAP_ProductImageURL__c = content.CMAP_ProductImageURL__c;
    const CMAP_Content__c = content.CMAP_Content__c;
    const CMAP_AltMessage__c = content.CMAP_AltMessage__c;
    if (CMAP_Title__c !== "" && CMAP_ProductImageURL__c !== "") {
      return (
        <>
          <p className="bold mb-10">{CMAP_Title__c}</p>
          <p>{CMAP_Content__c}</p>
          <img src={CMAP_ProductImageURL__c} alt={CMAP_AltMessage__c} />
        </>
      )
    }
  };

  return (
    <React.Fragment>
      {props.fields?.importantMatterDescriptionList &&
      props.fields?.importantMatterDescriptionList.length > 0 ? (
        props.fields?.importantMatterDescriptionList.map((item, index) =>
          ImportantMatterDescriptionItem(item)
        )
      ) : (
        <>
        <div className="list_wrapper underline L-01 one-column">
          <RichText field={props.fields?.richTextContent} />
        </div>
        <div className="list_wrapper underline L-01 one-column">
          <img src={props.fields?.productImageURL} alt="" />
        </div>
        </>
      )}
    </React.Fragment>
  );
};

export default ImportantMattersDescriptionModalLayout;
