import React from "react";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import {
  getProperData,
  fillBlank,
  getScDataFromPlaceholder,
} from "../../utils/commonUtils";

const C05 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    titleText,
    titleNumber,
    textAreaLabel,
    explanationText,
    dataTargetValue,
    buttonLabel,
    additionalClass,
    isPlaceholderHidden,
    MultilistItems,
  } = properData;
  const properMultilistItems = [];
  for (let i = 0; i < MultilistItems?.length; i++) {
    properMultilistItems[i] = getProperData(
      sitecoreContext,
      MultilistItems[i].fields
    );
  }

  const cancelAndScheduleButtonSection = getScDataFromPlaceholder(
    "cancel-and-schedule-button-section",
    props
  );

  const isCancelAndScheduleButtonSectionHidden =
    cancelAndScheduleButtonSection && cancelAndScheduleButtonSection.length > 0;

  // @View
  return (
    <React.Fragment>
      <div
        className={`decoration-content individual C-05 ${additionalClass?.value}`}
      >
        <div className="content-title">
          <span className="bold mr-10">
            <Text field={fillBlank(titleText)} />
          </span>
          <span>
            <Text field={fillBlank(titleNumber)} />
          </span>
        </div>
        <div className="information">
          <ul>
            {properMultilistItems.map((item, i) => (
              <li key={i}>
                <span>
                  <Text field={fillBlank(item.textAreaLabel)} />
                </span>
                <p>
                  <Text field={fillBlank(item.explanationText)} />
                </p>
              </li>
            ))}
          </ul>

          {/* TODO: Will add logic to hide/show the button depending on the data */}
          {/* TODO: Inside this Placeholder you can add a button B-01, B-02, BM-01 and BM-02 */}
          {/* TODO: To add additionalClass field value to "hide" if you want to hide the button or "arrow-right  btn_txt" to have a button with arrow */}

          {isPlaceholderHidden ? (
            <div className="multiple-button">
              <Placeholder
                name="dynamic-button-section"
                rendering={props.rendering}
              />
              <Placeholder
                name="status-completed-button-section"
                rendering={props.rendering}
              />
            </div>
          ) : (
            <Placeholder
              name="dynamic-button-section"
              rendering={props.rendering}
            />
          )}
          {/* TODO: Will add logic to change the appearance of the button if the application is in progress or completed */}

          {/* TODO: New C-05 variant for contract-cancel page */}

          {/* Hide this section if placeholder does not exist */}
          {isCancelAndScheduleButtonSectionHidden && (
            <div className="bottom-section">
              <Placeholder
                name="cancel-and-schedule-button-section"
                rendering={props.rendering}
              />
              {/* TODO: The class name bottom section will be hidden when the application is completed */}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default C05;
