import { ISO_EMPTY_DATE } from "./constantVariables";

export const dateCondition = (dateStart, dateEnd) => {
  const currentDate = new Date();
  const isoRegex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/;
  if (dateStart.match(isoRegex) && dateEnd.match(isoRegex) !== null)
    return (
      Date.parse(new Date(dateStart)) <= currentDate.getTime() &&
      dateStart != ISO_EMPTY_DATE &&
      (Date.parse(new Date(dateEnd)) >= currentDate.getTime() ||
        dateEnd == ISO_EMPTY_DATE)
    );
  else
    return (
      Date.parse(dateStart) <= currentDate.getTime() &&
      (Date.parse(dateEnd) >= currentDate.getTime() || dateEnd == "")
    );
};
