import React, { useState, useEffect, useRef } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import parse from "html-react-parser";

// get the data

// update the context

const L02 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  // @Model

  const {
    titleText,
    openText,
    closeText,
    productHeaderName,
    priceHeaderName,
    quantityHeaderName,
    currencyText,
    quantityText,
    additionalClass,
    dataRowList,
    hideAccordion = false,
    dropDownIcon,
    showQuantityHeader,
  } = properData;

  const [isActive, setIsActive] = useState(false);
  const [height, setHeight] = useState();
  const ref = useRef(null);
  const hiddenHeader = properData?.hiddenHeader;

  // @Controller

  useEffect(() => {
    if (additionalClass?.value.includes("open")) {
      setIsActive(true);
      let innerHeight = ref.current.clientHeight;
      if (ref?.current?.querySelector("table")?.offsetHeight) {
        innerHeight = ref?.current?.querySelector("table")?.offsetHeight;
      }
      setHeight(innerHeight);
    }

    const handleWindowResize = () => {
      setIsActive(false);
      let accordion = document.querySelectorAll(".accordion");
      [].forEach.call(accordion, function (el) {
        el.classList.remove("open");
      });
      calculateAccordionHeight(0);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const toggleAccordion = () => {
    if (hideAccordion) return;
    let innerHeight = ref.current.clientHeight;
    if (ref?.current?.querySelector("table")?.offsetHeight) {
      innerHeight = ref?.current?.querySelector("table")?.offsetHeight;
    }
    setIsActive(!isActive);
    calculateAccordionHeight(innerHeight);
  };

  const calculateAccordionHeight = (innerHeight) => {
    if (!isActive) {
      setHeight(innerHeight);
    } else {
      setHeight(0);
    }
  };

  // @View
  return (
    <React.Fragment>
      <div
        className={`list_wrapper accordion L-02 ${additionalClass?.value}`}
        onClick={() => toggleAccordion()}
      >
        <ul>
          <li>
            <div
              className={`accordion-title ${isActive ? "active" : ""} ${
                hideAccordion && "hide-accordion"
              }`}
            >
              <h5>
                <Text field={fillBlank(titleText)} />
              </h5>
              {!hideAccordion && (
                <>
                  <span className="a-open">
                    <Text field={fillBlank(openText)} />
                  </span>
                  <span className="a-close">
                    <Text field={fillBlank(closeText)} />
                  </span>
                  {dropDownIcon?.value && (
                    <img src={fillBlank(dropDownIcon)?.value?.src} />
                  )}
                </>
              )}
            </div>
            {!hideAccordion && (
              <div
                className={`accordion-info ${isActive ? "show" : ""}`}
                style={{ height: `${height + "px"}` }}
              >
                <div className="accordion-detail" ref={ref}>
                  <table>
                    {hiddenHeader ? (
                      <thead></thead>
                    ) : (
                      <thead>
                        <tr>
                          <th>
                            <Text field={fillBlank(productHeaderName)} />
                          </th>
                          {(quantityText?.value || showQuantityHeader) && (
                            <th className="quantity">
                              <Text field={fillBlank(quantityHeaderName)} />
                            </th>
                          )}
                          <th>
                            <Text field={fillBlank(priceHeaderName)} />
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {dataRowList?.map((item, i) => (
                        <tr key={i}>
                          <td
                            width={
                              quantityText?.value || showQuantityHeader
                                ? "540px"
                                : ""
                            }
                            class={item?.fields?.Class?.value}
                          >
                            <Text field={fillBlank(item.fields.Key)} />
                          </td>
                          {item.fields?.Quantity && (
                            <td className="quantity">
                              <Text field={item.fields.Quantity} />
                            </td>
                          )}

                          {quantityText?.value && (
                            <td className="quantity">
                              <Text field={quantityText} />
                            </td>
                          )}
                          <td>
                            {item?.fields?.Value?.value && (
                              parse(item.fields.Value.value)
                            )}
                            {currencyText?.value && (
                              <Text field={fillBlank(currencyText)} />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default L02;
