import React from "react";
import { Placeholder, Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import {
  fillBlank,
  getProperData,
  getScDataFromComponent,
  getScDataFromPlaceholder,
} from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";
import {
  SMALL_WINDOW_PARAMS,
  SAME_WINDOW,
  ANOTHER_WINDOW,
} from "../../utils/constantVariables";
// @Model

// get the data

// update the context

// @Controller

// @View

const Q01 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    titleQRText,
    instructionQRText01,
    mobileTitleQRText,
    imageForQrCode,
    titleIDText,
    instructionIDText01,
    imageForForId,
    MobileButtonLink,
    MobileButtonText,
    UrlType,
  } = properData;
  const buttonSection = getScDataFromPlaceholder("button-section", props);
  const [b02Data] = getScDataFromComponent("B-02", buttonSection);
  b02Data.fields.buttonText = MobileButtonText;
  b02Data.fields.onClickEvent = () => {
    if (MobileButtonLink && UrlType !== null) {
      if (UrlType?.fields.Code.value === "1") {
        window.open(MobileButtonLink.value.href, SAME_WINDOW);
      } else if (UrlType?.fields.Code.value === "2") {
        window.open(MobileButtonLink.value.href, ANOTHER_WINDOW);
      } else {
        window.open(
          MobileButtonLink.value.href,
          ANOTHER_WINDOW,
          SMALL_WINDOW_PARAMS
        );
      }
    } else {
      window.open(MobileButtonLink.value.href, SAME_WINDOW);
    }
  };
  return (
    <React.Fragment>
      <div className="register_wrapper Q-01">
        <div className="register-column">
          <div className="column">
            <div className="pc_only">
              <p className="title bold txt_center">
                <Text field={fillBlank(titleQRText)} />
              </p>
              {/*}
              <ul>
                {instructionQRText01?
                  <li>
                    <RichText field={fillBlank(instructionQRText01)} />
                  </li>
                : "" }

                {instructionQRText02?
                  <li>
                    <RichText field={fillBlank(instructionQRText02)} />
                  </li>
                : "" }
              </ul>
              */}
              {instructionQRText01 ? (
                <RichText field={fillBlank(instructionQRText01)} />
              ) : (
                ""
              )}
              <div className="register-container qr">
                <img src={fillBlank(imageForQrCode).value.src} />
              </div>
            </div>
            <div className="sp_only">
              <p className="title bold txt_center">
                <Text field={fillBlank(mobileTitleQRText)} />
              </p>
              <div className="btn_container">
                <Placeholder
                  name="button-section"
                  rendering={props.rendering}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <p className="title bold txt_center">
              <Text field={fillBlank(titleIDText)} />
            </p>
            {/*
            <ul>
              {instructionIDText01?
                <li>
                  <Text field={fillBlank(instructionIDText01)} />
                </li>
              : ""}

              {instructionIDText02?
                <li>
                  <Text field={fillBlank(instructionIDText02)} />
                </li>
              : ""}
            </ul>
              */}
            {instructionIDText01 ? (
              <RichText field={fillBlank(instructionIDText01)} />
            ) : (
              ""
            )}

            <div className="register-container id">
              <img src={fillBlank(imageForForId).value.src} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Q01;
