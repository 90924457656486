import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";

const L01 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { additionalClass, titleText, dataRowList, isHidden } = properData;
  // @View
  return (
    <React.Fragment>
      {!isHidden && (
        <div
          className={`list_wrapper underline L-01 ${additionalClass?.value}`}
        >
          {titleText?.value && titleText?.value != "-" ? (
            <div className="list-title">
              <Text field={fillBlank(titleText)} tag="h5" />
            </div>
          ) : (
            ""
          )}
          <ul>
            {dataRowList?.map((item, i) => (
              <li key={i}>
                <span>
                  <Text field={fillBlank(item.fields.Key)} />
                </span>
                <Text
                  field={fillBlank(item.fields.Value)}
                  tag="p"
                  encode={false}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};

export default L01;
