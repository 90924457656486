import React, { useEffect, useState } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import { getParamValue, redirect } from "../../utils/commonUtils";
import { di_register } from "../../utils/di";
import {
  STRING_ERROR_500_URI,
  STRING_GET,
} from "../../utils/constantVariables";
import { useFormContext } from "react-hook-form";
import { getErrorMessage } from "../../utils/errorMessageHandler";
import { apiBaseURL } from "../../envSettings";
import { useCustomQuery } from "../../hooks/useGetData";
import { getSessionStorageItem } from "../../utils/useSessionStorage";
import { CommonApplicationCompletedLayout } from "../../../src/services/common/common-application-completed";
import { CommonApplicationStepAfter } from "../../services/common/common-application-step-after";

const ForEdionAgency = (props) => {
  // get ss
  const applicationNumber = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_APPLICATIONNUMBER,
    true
  );

  const personalizeInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );

  const { setError, formState } = useFormContext();

  // @Controller
  useEffect(() => {
    /// For "Edion" Agency only
    if (
      personalizeInfo?.AccountName?.toLowerCase()?.includes("edion") &&
      typeof document !== "undefined"
    ) {
      /// GET value of ApplicationNumber(param: TABUKENO) and process result(param: result) from URL query string
      const applicationNumberTabukeno = getParamValue("TABUKENO");
      const processResult = getParamValue("result");

      /// if applicationNumber from sessionStorage is equal to the value of TABUKENO
      /// if the referrer is equal to Information For Edion [EdionDomain]
      /// else error 500 page will be shown
      //// TODO: Data for "InformationForEdionAgency-EdionDomain" will be added in later
      if (
        applicationNumber === applicationNumberTabukeno &&
        document.referrer ===
          props.sitecoreContext.route.fields[
            "InformationForEdionAgency-EdionDomain"
          ].value
      ) {
        /// if there's no process result, show Error Message
        if (!processResult && !formState?.errors?.ProcessResult?.message) {
          setError("ProcessResult", {
            type: "manual",
            message: getErrorMessage("E0029", []),
          });
        }
      } else {
        redirect(STRING_ERROR_500_URI);
      }
    }
  }, [formState]);
};

const ApplicationCompletedLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  // get ss
  const isNewPaymentMethod = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTBILLINGINFO_ISNEWPAYMENTMETHOD,
    true
  );

  const orderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );

  const myCommufaRegistrationChoice = getSessionStorageItem(
    keys.SSN_APPLICATION_CREATEMYCOMMUFAID_REGISTRATIONCHOICE,
    true
  );

  const constructionSchedule =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_IsTypeCircuit__c;

  const paymentMethod =
    orderRelatedInfo?.orderRelatedInfo?.billingTo?.CMAP_PaymentMethod__c;

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const {
    data: retrieveLoginStatusData,
    isLoading: isRetrieveLoginStatusLoading,
  } = useCustomQuery(
    `${apiBaseURL}common/RetrieveLoginStatus`,
    true,
    STRING_GET
  );

  useEffect(() => {
    if (retrieveLoginStatusData) {
      setIsLoggedIn(retrieveLoginStatusData?.Result);
    }
  }, [retrieveLoginStatusData]);

  const isLoading = isRetrieveLoginStatusLoading;

  props.setIsLoading(isLoading);

  if (
    typeof constructionSchedule === "undefined" &&
    typeof paymentMethod === "undefined" &&
    typeof isNewPaymentMethod === "undefined" &&
    typeof myCommufaRegistrationChoice === "undefined"
  ) {
    redirect(STRING_ERROR_500_URI);
  }

  ForEdionAgency(props);

  // @View
  return (
    <main>
      <div className="form_container">
        <div className="form_detail full-width">
          <div className="form_wrapper">
            <CommonApplicationCompletedLayout
              rendering={props.rendering}
              sitecoreContext={sitecoreContext}
            />
            <CommonApplicationStepAfter
              rendering={props.rendering}
              setIsLoading={props.setIsLoading}
              isLoggedIn={isLoggedIn}
              paymentMethod={paymentMethod}
              isNewPaymentMethod={isNewPaymentMethod}
              constructionSchedule={constructionSchedule}
              myCommufaRegistrationChoice={myCommufaRegistrationChoice}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default withSitecoreContext()(ApplicationCompletedLayout);
