import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { useFormContext } from "react-hook-form";
import { Placeholder, RichText } from "@sitecore-jss/sitecore-jss-react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

import { validationFuncs } from "../../utils/validations";
import { getErrorMessage } from "../../utils/errorMessageHandler";

import { recoilKeys as keys } from "../../assets/recoilKeys";

// Hooks
import { useSetSelectedItem } from "../../hooks/useSelectedState";

// Utils
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  formatCurrency,
  redirectToLinkUrl,
  getProperData,
  generateGUID,
} from "../../utils/commonUtils";
import { di_register } from "../../utils/di";
import { parseFieldMetadata } from "../../utils/parseMetadata";
import {
  STRING_GET,
  STRING_OTHER,
  STRING_POST,
  STRING_LOADING,
} from "../../utils/constantVariables";
import {
  setSessionStorageItem,
  getSessionStorageItem,
} from "../../utils/useSessionStorage";

// GraphQL
import { apiBaseURL } from "../../envSettings";
import { useCustomQuery, useGetDataViaGraphQL } from "../../hooks/useGetData";
import { query } from "../../assets/graphql/AgreementContent";

// Calculations
import {
  createCancellationChargeObj,
  createDebtObj,
} from "../../services/calculations/Contract-Cancel/calculateContractCancel";

const createCancellationChargeItems = (title, dataRowList = []) => {
  let childMultiItem = [];
  if (dataRowList instanceof Array && dataRowList.length > 0) {
    childMultiItem = dataRowList?.map(({ item, charge }) => ({
      displayName: item,
      name: item,
      fields: {
        contentLabel: item,
        contentText: formatCurrency(charge),
      },
    }));
  }
  return {
    displayName: title,
    name: title,
    id: generateGUID(),
    fields: {
      textAreaLabel: { value: title },
      MultilistItems: childMultiItem,
    },
  };
};

const PlansToBeCancelled = (props) => {
  return (
    <div className="form_wrapper-input">
      <Placeholder
        name="cancel-target-plan-section"
        rendering={props.rendering}
      />
    </div>
  );
};

const SelectPreferredCancellationDateOfContract = (props) => {
  return (
    <div className="form_wrapper-input">
      <Placeholder
        name="select-cancellation-date-section"
        rendering={props.rendering}
      />
    </div>
  );
};

const RegisterReasonOfContractCancellation = (props) => {
  return (
    <div className="form_wrapper-input">
      <Placeholder
        name="cancellation-reason-registration-section"
        rendering={props.rendering}
      />
    </div>
  );
};

const CancellationCharges = (props) => {
  return (
    <div className="form_wrapper-input">
      <Placeholder
        name="cancellation-fee-details-section"
        rendering={props.rendering}
      />
    </div>
  );
};

const NotesOnCancellationOfContract = (props) => {
  const pageFields = props.sitecoreContext.route.fields;
  return (
    <>
      <div className="form_wrapper-input">
        <Placeholder
          name="cancellation-notes-section"
          rendering={props.rendering}
        />
        <RichText
          field={pageFields["NotesOnCancellationOfContract-Description"]}
          className="with-left-right-margin"
        />
        <Placeholder name="agreement-section" rendering={props.rendering} />
      </div>
      <div className="btn_container">
        <Placeholder name="button-section" rendering={props.rendering} />
      </div>
    </>
  );
};

const ContractCancelLayout = (props) => {
  // TODO: Remove Test Data

  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);
  const {
    "RegisterReasonOfContractCancellation-OtherReasonPlaceholder": otherReasonPlaceholder,
  } = sitecoreContext.route.fields;

  const { setError, clearErrors } = useFormContext();

  const locale = {
    locale: ja,
  };

  const isNotEncrypt = true;
  const orderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    isNotEncrypt
  );
  const lastUtilizationDate = getSessionStorageItem(
    keys.SSN_APPLICATION_CONTRACTCANCEL_LASTUTILIZATIONDATE,
    isNotEncrypt
  );
  const terminationReason = getSessionStorageItem(
    keys.SSN_APPLICATION_CONTRACTCANCEL_TERMINATIONREASON,
    isNotEncrypt
  );
  const terminationReasonOther = getSessionStorageItem(
    keys.SSN_APPLICATION_CONTRACTCANCEL_TERMINATIONREASONOTHER,
    isNotEncrypt
  );
  const personalizeInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    isNotEncrypt
  );

  const [reasonForCancellation, setReasonForCancellation] = useSetSelectedItem(
    "ReasonOfCancellation"
  );
  const [inputValues, setInputValues] = useState({
    prefTerminationDate: "",
    reasonOfCancellationOther: terminationReasonOther,
  });

  const {
    data: retrieveSystemDateTimeData,
    isLoading: isRetrieveSystemDateTimeLoading,
    error: retrieveSystemDateTimeError,
    refetch: retrieveSystemDateTimeRefresh,
  } = useCustomQuery(
    `${apiBaseURL}common/RetrieveSystemDateTime`,
    true,
    STRING_GET
  );

  const {
    data: desiredDateRangeData,
    isLoading: isDesiredDateRange,
    error: dateRangeDataError,
    refetch: desiredDateRangeRefetch,
    remove: desiredDateRangeRemove,
  } = useCustomQuery(
    `${apiBaseURL}order/RetrieveSelectableDesiredDateRange`,
    false,
    STRING_POST,
    {
      orderRelatedInfo: {
        orderInfo: {
          order: {
            CMAP_OrderCategory__c:
              orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
                ?.CMAP_OrderCategory__c,
            CMAP_OrderBeforeChange__c:
              orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
                ?.CMAP_OrderBeforeChange__c,
            EffectiveDate: retrieveSystemDateTimeData?.SystemDateTime?.split(
              "T"
            )[0],
          },
          orderAddition: {
            orderCategoryCurrentlySelected:
              orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition
                ?.orderCategoryCurrentlySelected,
            orderCategoryPreviouslySelected:
              orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition
                ?.orderCategoryPreviouslySelected,
          },
          orderItemInfos: orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos?.map(
            (order) => ({
              orderItem: {
                Id: order?.orderItem?.Id,
                Product2Id: order?.orderItem?.Product2Id,
                CMAP_ProductSelectionState__c:
                  order?.orderItem?.CMAP_ProductSelectionState__c,
                CMAP_LastTimeChangeCategory__c:
                  order?.orderItem?.CMAP_LastTimeChangeCategory__c,
              },
            })
          ),
        },
      },
    }
  );

  useEffect(() => {
    if (retrieveSystemDateTimeData) {
      desiredDateRangeRefetch();
    }
  }, [retrieveSystemDateTimeData]);

  const {
    data: retrieveMetadataDefinitionsData,
    isLoading: isRetrieveMetadataDefinitionsLoading,
    error: retrieveMetadataDefinitionsError,
    refetch: retrieveMetadataDefinitionsRefresh,
  } = useCustomQuery(
    `${apiBaseURL}order/RetrieveMetadataDefinitions`,
    true,
    STRING_POST,
    {
      objectNames: ["Order"],
      orderChannelCategory: personalizeInfo?.AppChannelDivision,
    }
  );

  const terminationReasonMetadata = parseFieldMetadata(
    retrieveMetadataDefinitionsData?.metadataDefinitions.Order
      .fieldMetadataDefinitions.CMAP_TerminationReason__c
  );
  const terminationReasonOtherMetadata = parseFieldMetadata(
    retrieveMetadataDefinitionsData?.metadataDefinitions.Order
      .fieldMetadataDefinitions.CMAP_TerminationReasonOther__c
  );

  const graphQlData = useGetDataViaGraphQL(
    query,
    null,
    "AgreementContentQuery"
  );

  const agreementContentData = graphQlData?.AgreementContent;

  // Mapping Plan To Be Cancelled
  const selectProductData = getScDataFromPlaceholder(
    "cancel-target-plan-section",
    props
  );
  const c05Data = getScDataFromComponent("C-05", selectProductData);
  const multiListItems = c05Data[0]?.fields?.MultilistItems;

  let titleNumber = c05Data[0]?.fields;
  titleNumber["titleNumber"] = getSessionStorageItem(
    keys.SSN_REPORT_CONTRACTLIST_CONTRACTNUMBER,
    isNotEncrypt
  );

  let addressData = multiListItems[0]?.fields;
  addressData["explanationText"] = getSessionStorageItem(
    keys.SSN_REPORT_CONTRACTLIST_ADDRESS,
    isNotEncrypt
  );

  let planData = multiListItems[1]?.fields;
  planData["explanationText"] = getSessionStorageItem(
    keys.SSN_REPORT_CONTRACTLIST_PLANNAME,
    isNotEncrypt
  );

  // Select Preferred Cancellation Date Of Contract
  const cancelDate = getScDataFromPlaceholder(
    "select-cancellation-date-section",
    props
  );
  const [m01iData] = getScDataFromComponent("M-01i", cancelDate);
  m01iData.fields.initialValue = { value: inputValues?.prefTerminationDate };
  m01iData.fields.onChangeEvent = (date) => {
    setInputValues((prev) => ({
      ...prev,
      prefTerminationDate: format(date, "yyyy-MM-dd", locale),
    }));
  };

  const cancelReasonSection = getScDataFromPlaceholder(
    "cancellation-reason-registration-section",
    props
  );
  const [s01Data] = getScDataFromComponent("S-01", cancelReasonSection);
  s01Data.fields["category"] = { value: "ReasonOfCancellation" };
  s01Data.fields["initTextValues"] = terminationReasonMetadata.options?.reduce(
    (a, opt) => {
      a[opt.value] =
        opt.value === STRING_OTHER ? inputValues.reasonOfCancellationOther : "";
      return a;
    },
    {}
  );

  useEffect(() => {
    s01Data.fields["selections"] = terminationReasonMetadata.options?.map(
      (opt) => ({
        Code: opt.value,
        Value: opt.label,
        Checked: reasonForCancellation?.selected === opt.value,
        HasTextArea: opt.value === STRING_OTHER,
        Placeholder: otherReasonPlaceholder.value,
        textarea: {
          maxLength:
            opt.value === STRING_OTHER
              ? terminationReasonOtherMetadata?.numberOfLetters
              : null,
        },
        onTextBoxChange: (value) => {
          setInputValues((prev) => ({
            ...prev,
            reasonOfCancellationOther: value,
          }));
        },
      })
    );
  }, [terminationReasonMetadata]);

  // Agreement on Cancellation of Contract
  const [agreementStatus, setAgreementStatus] = useState(false);
  const agreementSection = getScDataFromPlaceholder("agreement-section", props);
  const [s16Data] = getScDataFromComponent("S-16", agreementSection);
  s16Data.fields["dataRowList"] = agreementContentData?.children.map(
    ({ fields }) => {
      return fields.reduce((a, field) => {
        a.field = field.name;
        a.label = field.value;
        a.checked = false;
        return a;
      }, {});
    }
  );
  s16Data.fields["onChangeEvent"] = (status) => {
    setAgreementStatus(status.every((s) => s));
  };

  // Cancelation
  const [sumOfCancellationCharge, setSumOfCancellationCharge] = useState(0);
  const [cancellationCharge, setCancellationCharge] = useState([]);
  const [debts, setDebts] = useState([]);

  const cancellationFees = getScDataFromPlaceholder(
    "cancellation-fee-details-section",
    props
  );
  const [c07Data] = getScDataFromComponent("C-07", cancellationFees);

  // Button Section
  const btnSection = getScDataFromPlaceholder("button-section", props);
  const [backButton] = getScDataFromComponent("B-02", btnSection);
  const [nextButton] = getScDataFromComponent("B-01", btnSection);

  //back button
  backButton.fields.onClickEvent = () => {
    clearErrors();
    setSessionStorageItem(
      keys.SSN_APPLICATION_CONTRACTCANCEL_LASTUTILIZATIONDATE,
      inputValues?.prefTerminationDate,
      isNotEncrypt
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_CONTRACTCANCEL_TERMINATIONREASON,
      reasonForCancellation.selected,
      isNotEncrypt
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_CONTRACTCANCEL_TERMINATIONREASONOTHER,
      inputValues?.reasonOfCancellationOther,
      isNotEncrypt
    );

    const { linkURL } = getProperData(props.sitecoreContext, backButton.fields);
    redirectToLinkUrl(linkURL);
  };

  //next button
  nextButton.fields.isDisabled = { value: !agreementStatus };
  nextButton.fields.onClickEvent = async () => {
    let isValidate = true;
    clearErrors();

    const doesExistInOptions =
      terminationReasonMetadata.options?.filter(
        (item) => item.value === reasonForCancellation?.selected
      )?.length > 0;
    if (
      reasonForCancellation === "" ||
      reasonForCancellation?.selected === "" ||
      !doesExistInOptions
    ) {
      setError("reasonForCancellation-1", {
        type: "manual",
        message: getErrorMessage("E0002", ["解約理由ラジオボタン"]),
      });
      isValidate = false;
    }
    if (
      reasonForCancellation?.selected === STRING_OTHER &&
      !validationFuncs.validateIsRequired(
        inputValues?.reasonOfCancellationOther
      )
    ) {
      setError("reasonForCancellation-2", {
        type: "manual",
        message: getErrorMessage("E0001", ["解約理由テキストボックス"]),
      });
      isValidate = false;
    }
    if (!validationFuncs.validateIsRequired(inputValues?.prefTerminationDate)) {
      setError("preferredTerminationDate", {
        type: "manual",
        message: getErrorMessage("E0002", ["解約希望日選択カレンダー"]),
      });
      isValidate = false;
    }

    if (!isValidate) return;

    const reasonOptions = s01Data.fields["selections"];
    const reasonLabel = reasonOptions.find(
      (option) => option?.Code === reasonForCancellation.selected
    )?.Value;

    const nextOrderRelatedInfo = {
      ...orderRelatedInfo,
      orderRelatedInfo: {
        ...orderRelatedInfo?.orderRelatedInfo,
        orderInfo: {
          ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
          order: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
            CMAP_OrderChannelDetail__c: "Web",
            CMAP_LastUtilizationDate__c: inputValues?.prefTerminationDate,
            CMAP_TerminationReason__c: reasonForCancellation?.selected,
            CMAP_TerminationReasonOther__c:
              inputValues?.reasonOfCancellationOther,
          },
        },
      },
    };

    setSessionStorageItem(
      keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
      nextOrderRelatedInfo,
      isNotEncrypt
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_CONTRACTCANCEL_LASTUTILIZATIONDATE,
      inputValues?.prefTerminationDate,
      isNotEncrypt
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_CONTRACTCANCEL_TERMINATIONREASON,
      reasonForCancellation.selected,
      isNotEncrypt
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_CONTRACTCANCEL_TERMINATIONREASONOTHER,
      inputValues?.reasonOfCancellationOther,
      isNotEncrypt
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_CONTRACTCANCEL_TERMINATIONREASONLABEL,
      reasonLabel,
      isNotEncrypt
    );

    setSessionStorageItem(
      keys.SSN_REPORT_CONTRACTCANCEL_SUMOFCANCELLATIONCHARGE,
      sumOfCancellationCharge,
      isNotEncrypt
    );
    setSessionStorageItem(
      keys.SSN_REPORT_CONTRACTCANCEL_BREAKDOWNOFCANCELLATIONCHARGEITEMS,
      cancellationCharge,
      isNotEncrypt
    );
    setSessionStorageItem(
      keys.SSN_REPORT_CONTRACTCANCEL_BREAKDOWNOFDEBTITEMS,
      debts,
      isNotEncrypt
    );

    const { linkURL } = getProperData(props.sitecoreContext, nextButton.fields);
    redirectToLinkUrl(linkURL);
  };

  useEffect(() => {
    let totalCancellationCharge = 0;

    // TODO: delete test data
    const cancellationChargeObj = createCancellationChargeObj();

    c07Data.fields.MultilistItems = [];

    if (cancellationChargeObj && cancellationChargeObj?.length > 0) {
      const mappedCancelChargeData = cancellationChargeObj.map(
        (cancelChargeItem) => {
          const productName = cancelChargeItem?.CMAP_ProductName__c;
          const fee = cancelChargeItem?.PriceTaxInc;

          totalCancellationCharge += fee;

          return {
            item: productName,
            charge: fee,
          };
        }
      );

      setCancellationCharge(mappedCancelChargeData);

      c07Data.fields.MultilistItems.push(
        createCancellationChargeItems(
          sitecoreContext?.route?.fields[
            "CancellationCharges-CancellationChargeItemLabel"
          ]?.value,
          mappedCancelChargeData
        )
      );
    }

    // TODO: delete test data
    const debtObj = createDebtObj();

    if (debtObj && debtObj?.length > 0) {
      const mappedDebtData = debtObj.map((debtItem) => {
        const productName = debtItem?.CMAP_ProductName__c;
        const fee = debtItem?.PriceTaxInc;

        totalCancellationCharge += fee;

        return {
          item: productName,
          charge: fee,
        };
      });

      setDebts(mappedDebtData);

      c07Data.fields.MultilistItems.push(
        createCancellationChargeItems(
          sitecoreContext?.route?.fields["CancellationCharges-DebtItemLabel"]
            ?.value,
          mappedDebtData
        )
      );
    }

    setSumOfCancellationCharge(totalCancellationCharge);
    c07Data.fields.priceText = formatCurrency(totalCancellationCharge);
  }, []);

  useEffect(() => {
    // set initial value for reason for cancellation
    setReasonForCancellation({
      ReasonOfCancellation: terminationReason,
    });
  }, []);

  useEffect(() => {
    if (desiredDateRangeData) {
      const {
        selectablePreferDatesRangeInfo: { minEndDate, maxEndDate },
      } = desiredDateRangeData;

      m01iData.fields.minDate = minEndDate;
      m01iData.fields.maxDate = maxEndDate;
      const parsedDate = new Date(lastUtilizationDate);
      if (
        new Date(minEndDate) <= parsedDate &&
        new Date(maxEndDate) >= parsedDate
      ) {
        setInputValues((prev) => ({
          ...prev,
          prefTerminationDate: lastUtilizationDate,
        }));
      }
    }
  }, [desiredDateRangeData]);

  // clear reason other checkbox if selected reason is NOT other
  useEffect(() => {
    if (reasonForCancellation?.selected !== STRING_OTHER) {
      setInputValues((prev) => ({ ...prev, reasonOfCancellationOther: "" }));
    }
  }, [reasonForCancellation]);

  const isLoading =
    isDesiredDateRange ||
    isRetrieveSystemDateTimeLoading ||
    isRetrieveMetadataDefinitionsLoading ||
    graphQlData === STRING_LOADING;

  props.setIsLoading(isLoading);

  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <PlansToBeCancelled rendering={props.rendering} />
              <SelectPreferredCancellationDateOfContract
                rendering={props.rendering}
              />
              <RegisterReasonOfContractCancellation
                rendering={props.rendering}
              />
              <CancellationCharges rendering={props.rendering} />
              <NotesOnCancellationOfContract
                rendering={props.rendering}
                sitecoreContext={sitecoreContext}
              />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(ContractCancelLayout);
