import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import ModalContext from "../../assets/context/ModalContext";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";

// @Model

// get the data

// update the context

// @Controller

// @View

const BM01 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);

  const {
    additionalClass,
    modalButtonLabel,
    modalTarget,
    buttonArrowIcon,
  } = properData;

  const { state, dispatch } = useContext(ModalContext);

  const modalButtonShowHandler = () => {
    dispatch({ type: "SHOW_MODAL", payload: { id: modalTarget?.value } });
  };

  return (
    <React.Fragment>
      <div className="btn_wrapper primary BM-01">
        <button
          className={`btn btn_default ${additionalClass?.value}`}
          type="button"
          onClick={() => modalButtonShowHandler()}
        >
          <Text field={fillBlank(modalButtonLabel)} />
          {buttonArrowIcon?.value && (
            <img src={fillBlank(buttonArrowIcon)?.value?.src} />
          )}
        </button>
      </div>
    </React.Fragment>
  );
};

export default BM01;
