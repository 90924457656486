import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

// @Model

// get the data

// update the context

// @Controller

// @View

const C01 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { titleText, contentText } = properData;
  return (
    <React.Fragment>
      <div className="decoration-content shaded C-01">
        <div className="content-title">
          <h5>
            <Text field={fillBlank(titleText)} />
          </h5>
        </div>
        <ul>
          <li>
            <Text field={fillBlank(contentText)} tag="span" encode={false} />
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default C01;
