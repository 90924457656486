import React, { useState, useEffect } from "react";
import { di_fetch } from "../../utils/di";
import {
  getProperData,
  redirectToLinkUrl,
  getUrlInfo,
} from "../../utils/commonUtils";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import {
  getSessionStorageItem,
} from "../../utils/useSessionStorage";
import { useCustomQuery } from "../../hooks/useGetData";
import { apiBaseURL } from "../../envSettings";
import {
  STRING_POST,
  STRING_SIMULATOR,
  STRING_APPLY,
  SP_BREAKPOINT,
  SAME_WINDOW,
  SMALL_WINDOW,
  STRING_USERAGENT_MOBILE_APP,
} from "../../utils/constantVariables";
import { getURLType } from "../../utils/targetUrlType";


const MyCommufaInfo = (props) => {
  const handleButtonClick = props.handleButtonClick;
  const properData = props.properData;
  const myCommufaId = props.myCommufaId;
  const customerName = props.customerName;

  return (
    <div className="header_pos-right">
      <ul>
        <li>
          <span>
            {customerName}
            <p>
              {(properData &&
                properData["MycommufaHeader-TitleLabel"]?.value) ||
                ""}
            </p>
          </span>
        </li>
        <li>
          <p>
            {properData &&
              properData["MycommufaHeader-mycommufaIDLabel"]?.value}
            <span>{`${myCommufaId?.substring(0, 30)} ${
              myCommufaId?.length > 30 ? "..." : ""
            }`}</span>
          </p>
        </li>
        <li className="pc_only">
          <div className="btn_wrapper B-03">
            <button
              className="btn btn_logout"
              type="button"
              onClick={(e) => handleButtonClick(e)}
            >
              {properData &&
                properData["MycommufaHeader-LogoutButtonText"]?.value}
            </button>
          </div>
        </li>
      </ul>
    </div>
  );
};

const HD02 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);

  // Get ss 
  const myCommufaIdSessionStorage = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_MYCOMMUFAID,
    true
  );
  
  const customerNameSessionStorage = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_CUSTOMERNAME,
    true
  );
  
  const personalizeInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );

  // @Model
  const isContractFlag =
    personalizeInfo?.Contract?.length > 0 ? true : false;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [firstMenuActive, setFirstMenuActive] = useState("");
  const [secondMenuActive, setSecondMenuActive] = useState("");
  const [dimensions, setDimensions] = useState({ width: "" });
  const [globalNavigationData, setGlobalNavigationData] = useState([]);
  const [myCommufaId, setMyCommufaId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [isSimulator, setIsSimulator] = useState(false);
  const [isApply, setIsApply] = useState(false);
  const [isLogoutClick, setIsLogoutClick] = useState(false);
  const [isMobileApp, setIsMobileApp] = useState(false);

  // api urls
  const signOutURI = `${apiBaseURL}loginuser/logout`;
  const globalNavigationURI = `${apiBaseURL}common/RetrieveGlobalNavigation?IsContractFlag=${isContractFlag}`;

  const { error: logOutError, refetch: logOut } = useCustomQuery(
    signOutURI,
    false,
    STRING_POST
  );

  const {
    data: globalNavigation,
    isLoading: globalNavigationIsLoading,
    error: globalNavigationError,
    refetch: globalNavigationRefresh,
    remove: globalNavigationRemove,
  } = useCustomQuery(globalNavigationURI);

  const handleLogout = async () => {
    //call logout API
    await logOut();
    // redirect to button link url
    let urlInfo = properData["MycommufaHeader-LogoutButtonLink"];
    redirectToLinkUrl(urlInfo);
  };

  const handleButtonClick = () => {
    setIsLogoutClick(true);
  };

  let isLogin = true;
  let isMobile = false;

  //Global Navigator

  //2.3
  useEffect(() => {
    setDimensions({
      width: window.innerWidth,
    });
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);

    const urlInfo = getUrlInfo();
    setIsSimulator(
      urlInfo?.pathnames[1]?.toLocaleLowerCase() === STRING_SIMULATOR
    );
  }, []);

  if (dimensions.width <= SP_BREAKPOINT) {
    isMobile = true;
  }

  //Event 2 //Event 5 // Event 6
  const handleClickMenu = async () => {
    if (isMenuOpen) {
      setFirstMenuActive(false);
      setSecondMenuActive(false);
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  //Event 3
  const navigateUrlTypeOnclick = (urlLink, urlType) => {
    let urlTypeVal = getURLType(urlType);
    let linkURL = urlLink.match(/"(.*?)"/)[0].slice(1, -1);
    if (urlTypeVal === SAME_WINDOW) {
      window.location.href = linkURL;
    } else if (urlTypeVal === SMALL_WINDOW) {
      const width = 600,
        height = 600,
        left = (window.innerWidth - width) / 2,
        top = (window.innerHeight - height) / 2;
      const params = `width=${width},height=${height},left=${left},top=${top}`;
      window.open(linkURL, "_blank", params);
    } else {
      window.open(linkURL);
    }
  };

  // update context
  useEffect(() => {
    const urlInfo = getUrlInfo();
    const isApplyPage =
      urlInfo?.pathnames[1]?.toLocaleLowerCase() === STRING_APPLY;

    setIsApply(isApplyPage);
    setMyCommufaId(isApplyPage || isSimulator ? "" : myCommufaIdSessionStorage);
    setCustomerName(
      isApplyPage || isSimulator ? "" : customerNameSessionStorage
    );
    
  if (typeof window !== "undefined") {
    const userAgent = window.navigator.userAgent;
    const userAgentCheck = userAgent?.includes(STRING_USERAGENT_MOBILE_APP) ?? false;
    setIsMobileApp(userAgentCheck);
  }
  }, []);

  // receive globalNavi
  useEffect(() => {
    if (globalNavigation) {
      setGlobalNavigationData(globalNavigation.GlobalNavigation);
    }
  }, [globalNavigation]);


  const Sitemap = (props) => {
    return (
      <ul className="header_pos-bottom-menus">
        {globalNavigationData.length > 0 &&
          globalNavigationData.map((item, i) => {
            return (
              <li
                key={`sitemap_list${i}`}
                className={
                  item.SubMenu != null && firstMenuActive == item
                    ? "has-sub-menus open"
                    : item.SubMenu != null
                    ? "has-sub-menus"
                    : ""
                }
              >
                <span
                  onClick={(e) => {
                    setFirstMenuActive(firstMenuActive == item ? "" : item);
                    setSecondMenuActive("");
                    {
                      item.NavigationLink != "" &&
                        item.NavigationLink &&
                        navigateUrlTypeOnclick(
                          item.NavigationLink,
                          item.NavigationUrlType
                        );
                    }
                  }}
                >
                  {((item.HamburgerMenuIconUrl &&
                    item.HamburgerMenuIconUrl != "") ||
                    (item.NavigationIconUrl &&
                      item.NavigationIconUrl != "")) && (
                    <img
                      src={item.NavigationIconUrl ?? item.HamburgerMenuIconUrl}
                    />
                  )}
                  {item.NavigationTitle ?? item.HamburgerMenuTitle}
                </span>

                {item.SubMenu != null && item.SubMenu?.length > 0 && (
                  <ul className="sub-menus">
                    {item.SubMenu.map((subMenu, i) => {
                      return (
                        <li key={`sitemap_submenu${i}`}>
                          <dl
                            className={`sub-menus-item ${
                              subMenu.CategoryTitle != "" &&
                              secondMenuActive == subMenu
                                ? "open"
                                : subMenu.CategoryTitle == "" && "no-child"
                            }`}
                          >
                            {/* Category */}
                            <dt
                              className={
                                subMenu.CategoryTitle != "" ? "has-link" : ""
                              }
                              onClick={(e) => {
                                setSecondMenuActive(
                                  secondMenuActive == subMenu ? "" : subMenu
                                );
                                {
                                  subMenu.CategoryTitle != "" &&
                                    subMenu.Menu.length === 1 &&
                                    subMenu.Menu.MenuLink != null &&
                                    navigateUrlTypeOnclick(
                                      subMenu.Menu.MenuLink,
                                      subMenu.Menu.MenuUrlType
                                    );
                                }
                              }}
                            >
                              <span>
                                {subMenu.CategoryTitle != ""
                                  ? subMenu.CategoryTitle
                                  : subMenu.Menu[0].MenuTitle}
                              </span>
                            </dt>

                            {/* menu */}
                            {subMenu.Menu?.length > 0 && (
                              <dd>
                                <ul>
                                  {subMenu.Menu?.map((menu, i) => {
                                    return (
                                      <li key={`sitemap_category${i}`}>
                                        <span
                                          className={
                                            menu.MenuLink != "" &&
                                            menu.MenuLink != null
                                              ? "has-link"
                                              : ""
                                          }
                                          onClick={(e) => {
                                            setSecondMenuActive(
                                              secondMenuActive == menu
                                                ? ""
                                                : menu
                                            );
                                            {
                                              menu.MenuLink != "" &&
                                                menu.MenuLink != null &&
                                                navigateUrlTypeOnclick(
                                                  menu.MenuLink,
                                                  menu.MenuUrlType
                                                );
                                            }
                                          }}
                                        >
                                          {menu.MenuTitle}
                                        </span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </dd>
                            )}
                          </dl>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        <li className="sp_only">
          <div className="btn_wrapper B-03">
            <button className="btn btn_logout" type="button">
              {properData &&
                properData["MycommufaHeader-LogoutButtonText"]?.value}
            </button>
          </div>
        </li>
      </ul>
    );
  };
  const salesForceLogoutLink =
    properData["MycommufaHeader-SalesforceLogoutLink"]?.value?.url;

  useEffect(() => {
    if (typeof document !== "undefined" && isLogoutClick) {
      const noScript = document.createElement("script");
      const script = document.createElement("iframe");
      script.src = salesForceLogoutLink;
      script.style = "display: none; visibility: hidden";
      noScript.appendChild(script);
      document.body.appendChild(noScript);
      handleLogout();
    }
  }, [isLogoutClick]);

  // @view
  return (
    <React.Fragment>
      {!isMobileApp && (
      <header className="fixed">
        <div className="header HD-02">
          <div className={`header_pos ${!isLogin && "not-login"}`}>
            <h2 className="header_pos-left">
              {properData && properData["MycommufaHeader-Icon"]?.value?.src && (
                <>
                  <img
                    src={
                      properData &&
                      properData["MycommufaHeader-Icon"]?.value?.src
                    }
                    alt="コミュファ powered by ctc"
                  />
                </>
              )}
            </h2>
            {!isApply && !isSimulator && (
              <React.Fragment>
                <MyCommufaInfo
                  rendering={props.rendering}
                  handleButtonClick={handleButtonClick}
                  properData={properData}
                  customerName={customerName}
                  myCommufaId={myCommufaId}
                />
                {isLogin && (
                  <>
                    <nav
                      className={`header_pos-bottom ${isMenuOpen && "open"}`}
                      style={
                        isMenuOpen ? { display: "block" } : { display: "none" }
                      }
                    >
                      {isMobile && (
                        <MyCommufaInfo
                          rendering={props.rendering}
                          handleButtonClick={handleButtonClick}
                          properData={properData}
                          customerName={customerName}
                          myCommufaId={myCommufaId}
                        />
                      )}
                      <Sitemap />
                    </nav>
                    <div
                      className={`burger-menu ${isMenuOpen && "active"}`}
                      onClick={(e) => handleClickMenu(e)}
                    >
                      <i className="bi bi-list open"></i>
                      <i className="bi bi-x exit"></i>
                      <span className="open-text">メニュー</span>
                      <span className="close-text">Close</span>
                    </div>
                  </>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </header>
      )}

    </React.Fragment>
  );
};

export default HD02;
