import React, { useEffect, useState, useMemo } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";

import { apiBaseURL } from "../../envSettings";
import { useCustomQuery } from "../../hooks/useGetData";

import { recoilKeys as keys } from "../../assets/recoilKeys";
import { getSessionStorageItem } from "../../utils/useSessionStorage";

import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  formatCurrency,
  formatCurrencyChar,
} from "../../utils/commonUtils";

import {
  STRING_POST,
  STRING_DESELECTED,
  STRING_SUSPENDSELECTED,
  STRING_APPLY,
  STRING_SIMULATOR,
} from "../../utils/constantVariables";

// Calculations
import { createAnnualExpenseObj, createCampaignAppliedSimulationObj, createFeeSimulationObj, createInitialCostObj, createMonthlyExpenseObj } from "../calculations/Application-Detail-Confirmation/calculateApplicationDetailConfirmationResult"

// TODO: delete test data


export const CommonEstimateConfirmationDetails = (props) => {
  const {
    orderCategory,
    orderItemInfo,
    sitecoreContext,
    benefitListAgency,
    urlParentName,
  } = props;
  const pageContext = props.sitecoreContext.route.fields;

  di_register("thisPageSitecoreContext", sitecoreContext);

  const [retrieveFeeData, setRetrieveFeeData] = useState(null);
  const [retrieveFeeResponse, setRetrieveFeeResponse] = useState({});

  const {
    ProductSelectionList: productSelectionList
  } = getSessionStorageItem(
    keys.SSN_APPLICATION_SELECTPRODUCT_PRODUCTSELECTIONLIST,
    true
  );

  useEffect(() => {
    if (urlParentName === STRING_APPLY || urlParentName === STRING_SIMULATOR) {
      const benefitListArray = benefitListAgency?.map((benefit) => ({
        CMAP_MainSubProductGroupName__c: "",
        Product2Id: "",
        UnitPrice: "",
        CMAP_UnitPriceTaxIncluded__c: "",
        CMAP_PriceCategory__c: "",
        CMAP_InstallmentPaymentMonthly__c: "",
        CMAP_InstallmentPayMonthlyPriceTaxExc__c: "",
        CMAP_InstallmentPayMonthlyPriceTaxInc__c: "",
        CMAP_NumberOfInstallments__c: "",
        CMAP_InstallmentPayFirstMonthPriceTaxExc__c: "",
        CMAP_InstallmentPayFirstMonthPriceTaxInc__c: "",
        CMAP_SelectionAnnualPayment__c: "",
        CMAP_AnnualPaymentPriceTaxExcluded__c: "",
        CMAP_AnnualPaymentPriceTaxIncluded__c: "",
        CMAP_Unchargeable__c: "",
        CMAP_BenefitProduct__c: "",
        CMAP_GrantUnitPrice__c: "",
        CMAP_GrantPoint__c: "",
        CMAP_OrderItemTypeSelection__c: "",
        CMAP_OrderItemTypeBenefit__c: "",
        CMAP_OrderItemTypeAutomaticSelection__c: "",
        CMAP_ProductSelectionMethod__c: "",
        CMAP_BenefitNameForCpad__c: benefit.BenefitName,
        CMAP_BenefitPriceForCpad__c: benefit.BenefitPrice,
        Quantity: "",
      }));

      const orderItemArray = orderItemInfo?.reduce((a, {
        orderItem: item
      }) => {
        if (
          item.CMAP_ProductSelectionState__c !== STRING_DESELECTED &&
          item.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED
        ) {
          const prodSelection = productSelectionList.find(
            (sel) => sel.Product2Id === item.Product2Id
          );
          a.push({
            orderItem: {
              CMAP_MainSubProductGroupName__c: item.CMAP_MainSubProductGroupName__c,
              Product2Id: item.Product2Id,
              UnitPrice: item.UnitPrice,
              CMAP_UnitPriceTaxIncluded__c: item.CMAP_UnitPriceTaxIncluded__c,
              CMAP_PriceCategory__c: item.CMAP_PriceCategory__c,
              CMAP_InstallmentPaymentMonthly__c: item.CMAP_InstallmentPaymentMonthly__c,
              CMAP_InstallmentPayMonthlyPriceTaxExc__c: item.CMAP_InstallmentPayMonthlyPriceTaxExc__c,
              CMAP_InstallmentPayMonthlyPriceTaxInc__c: item.CMAP_InstallmentPayMonthlyPriceTaxInc__c,
              CMAP_NumberOfInstallments__c: item.CMAP_NumberOfInstallments__c,
              CMAP_InstallmentPayFirstMonthPriceTaxExc__c: item.CMAP_InstallmentPayFirstMonthPriceTaxExc__c,
              CMAP_InstallmentPayFirstMonthPriceTaxInc__c: item.CMAP_InstallmentPayFirstMonthPriceTaxInc__c,
              CMAP_SelectionAnnualPayment__c: item.CMAP_SelectionAnnualPayment__c,
              CMAP_AnnualPaymentPriceTaxExcluded__c: item.CMAP_AnnualPaymentPriceTaxExcluded__c,
              CMAP_AnnualPaymentPriceTaxIncluded__c: item.CMAP_AnnualPaymentPriceTaxIncluded__c,
              CMAP_Unchargeable__c: item.CMAP_Unchargeable__c,
              CMAP_BenefitProduct__c: item.CMAP_BenefitProduct__c,
              CMAP_GrantUnitPrice__c: item.CMAP_GrantUnitPrice__c,
              CMAP_GrantPoint__c: item.CMAP_GrantPoint__c,
              CMAP_OrderItemTypeSelection__c: item.CMAP_OrderItemTypeSelection__c,
              CMAP_OrderItemTypeBenefit__c: item.CMAP_OrderItemTypeBenefit__c,
              CMAP_OrderItemTypeAutomaticSelection__c: item.CMAP_OrderItemTypeAutomaticSelection__c,
              CMAP_ProductSelectionMethod__c: prodSelection ?
                prodSelection.CMAP_ProductSelection__c :
                "",
              CMAP_BenefitNameForCpad__c: "",
              CMAP_BenefitPriceForCpad__c: "",
              Quantity: item.CMAP_Quantity__c,
            },
          });
        }
        return a;
      }, []);

      setRetrieveFeeData({
        orderRelatedInfo: {
          orderInfo: {
            order: {
              CMAP_OrderCategory__c: orderCategory,
            },
            orderItemInfos: [
              ...(orderItemArray?.length > 0 ? orderItemArray : []),
              ...(benefitListArray?.length > 0 ? benefitListArray : []),
            ],
          },
        },
      });
    }
  }, [orderCategory, urlParentName]);

  const retrieveFeeEndpoint = `${apiBaseURL}order/RetrieveFeeSimulation`;
  const {
    data: RetrieveFeeDataResponse,
    isLoading: RetrieveFeeDataLoading,
    error: RetrieveFeeDataError,
    refetch: RetrieveFeeDataRefresh,
    remove: RetrieveFeeDataRemove,
  } = useCustomQuery(retrieveFeeEndpoint, false, STRING_POST, retrieveFeeData);

  useEffect(() => {
    retrieveFeeData &&
    (urlParentName === STRING_APPLY || urlParentName === STRING_SIMULATOR) &&
      RetrieveFeeDataRefresh();
  }, [retrieveFeeData]);

  useEffect(() => {
    RetrieveFeeDataResponse && setRetrieveFeeResponse(RetrieveFeeDataResponse);
  }, [RetrieveFeeDataResponse]);

  const simulationInfo =
    retrieveFeeResponse?.orderRelatedInfo?.orderInfo?.orderItemInfos?.[0]
    ?.simulationInfo;

  const isApiLoading = RetrieveFeeDataLoading;
  props.setIsLoading(isApiLoading);
  
  const selectProductData = getScDataFromPlaceholder(
    "estimate-confirmation-details-section",
    props
  );
  const c06Data = getScDataFromComponent("C-06", selectProductData);

  // TODO: delete test data
  const monthlyExpenseObj = useMemo(() => {
    return createMonthlyExpenseObj();
  }, []);

  // TODO: delete test data
  const feeSimulationObj = useMemo(() => {
    return createFeeSimulationObj(retrieveFeeResponse?.orderRelatedInfo?.orderInfo?.orderItemInfos);
  }, [retrieveFeeResponse]);
  
  // TODO: delete test data
  const campaignAppliedSimulationObj = useMemo(() => {
    return createCampaignAppliedSimulationObj();
  }, []);

  // TODO: delete test data
  const annualExpenseObj = useMemo(() => {
    return createAnnualExpenseObj();
  }, []);

  // TODO: delete test data
  const initialCostObj = useMemo(() => {
    return createInitialCostObj();
  }, []);

  const multilistItems = c06Data[0]?.fields?.MultilistItems;
  multilistItems.forEach((item) => {
    switch (item.name) {
      case "月額料金":
        item.fields.priceText = formatCurrency(monthlyExpenseObj.totalObj.PriceTaxInc);
        item.fields.priceText1 = formatCurrency(monthlyExpenseObj.totalObj.TotalTax);
        item.fields.priceText2 = formatCurrency(monthlyExpenseObj.totalObj.ReducedTax);
        item.fields.priceText3 = formatCurrency(monthlyExpenseObj.totalObj.NormalTax);
        break;
      case "月額料金シミュレーション":
        item.fields.currencyText = pageContext["OrderDetailConfirmation-MonthlyFeeUnitLabel"];
        item.fields.priceText = "";
        item.fields.subscriptionText = "";
        if (urlParentName === STRING_APPLY || urlParentName === STRING_SIMULATOR) {
          item.fields.textLabel1 = simulationInfo?.simulatedUnitPriceLabel1;
          item.fields.textLabel2 = simulationInfo?.simulatedUnitPriceLabel2;
          item.fields.textLabel3 = simulationInfo?.simulatedUnitPriceLabel3;

          item.fields.priceText1 = formatCurrencyChar(feeSimulationObj.simulatedUnitPriceTaxInc1);
          item.fields.priceText2 = formatCurrencyChar(feeSimulationObj.simulatedUnitPriceTaxInc2);
          item.fields.priceText3 = formatCurrencyChar(feeSimulationObj.simulatedUnitPriceTaxInc3);
        } else {
          item.priceText = "";
          item.fields.textLabel1 =
            sitecoreContext?.route?.fields?.[
              "OrderDetailConfirmation-CampaignNotAppliedLabel"
            ]?.value;
          item.fields.textLabel2 =
            sitecoreContext?.route?.fields?.[
              "OrderDetailConfirmation-CampaignAppliedLabel"
            ]?.value;
          item.fields.textLabel3 = "";

          item.fields.priceText1 = formatCurrencyChar(campaignAppliedSimulationObj.notAppliedTotal);
          item.fields.priceText2 = formatCurrencyChar(campaignAppliedSimulationObj.appliedTotal);
          item.fields.priceText3 = "";
        }
        break;
      case "年額料金":
        item.fields.priceText = formatCurrency(annualExpenseObj.totalObj.PriceTaxInc);
        item.fields.priceText1 = formatCurrency(annualExpenseObj.totalObj.TotalTax);
        item.fields.priceText2 = formatCurrency(annualExpenseObj.totalObj.ReducedTax);
        item.fields.priceText3 = formatCurrency(annualExpenseObj.totalObj.NormalTax);
        break;
      case "初期費用":
        item.fields.priceText = formatCurrency(initialCostObj.totalObj.PriceTaxInc);
        item.fields.priceText1 = formatCurrency(initialCostObj.totalObj.TotalTax);
        item.fields.priceText2 = formatCurrency(initialCostObj.totalObj.ReducedTax);
        item.fields.priceText3 = formatCurrency(initialCostObj.totalObj.NormalTax);
        break;
    }
  });

  // @View
  return (
    <div className="form_wrapper-input">
      <Placeholder
        name="estimate-confirmation-details-section"
        rendering={props.rendering}
      />
    </div>
  );
};
