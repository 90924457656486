import React, { useEffect, useState } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";

import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";

import { useCustomQuery } from "../../hooks/useGetData";
import { useGetDataViaGraphQL } from "../../hooks/useGetData";
import { query } from "../../assets/graphql/cPadEstimationDueDateMaster";

import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import {
  setSessionStorageItem,
  getSessionStorageItem,
} from "../../utils/useSessionStorage";

import { format } from "date-fns";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  stringEmpty,
  redirectToLinkUrl,
} from "../../utils/commonUtils";
import { STRING_LOADING, STRING_STORE } from "../../utils/constantVariables";

import { STRING_GET } from "../../utils/constantVariables";

import { apiBaseURL } from "../../envSettings";

import { useFormContext } from "react-hook-form";
import {
  validationFuncs,
  validateIsValidDateFormat,
} from "../../utils/validations";
import { getErrorMessage } from "../../utils/errorMessageHandler";

const CPadAgencyInfoConfirmationPageLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  const { setError, clearErrors } = useFormContext();

  // prepare api call
  const systemDateTimeURI = `${apiBaseURL}common/RetrieveSystemDateTime`;
  let systemDateTime = new Date().toISOString().split("T")[0];

  const {
    data: systemDateTimeData,
    isLoading: systemDateTimeIsLoading,
    error: systemDateTimeError,
    refetch: systemDateTimeRefresh,
    remove: systemDateTimeRemove,
  } = useCustomQuery(systemDateTimeURI, false, STRING_GET);

  // cPadEstimationDueDate master data
  const graphQlData = useGetDataViaGraphQL(query);
  let cPadEstimationDueDate;
  if (graphQlData) {
    cPadEstimationDueDate = graphQlData?.cPadEstimationDueDateMaster?.children
      .map((item) => {
        return item.fields.reduce((obj, current) => {
          obj[current.name] = current.value;
          return obj;
        }, {});
      })
      .find((item) => item?.Code === "1").Value;
  }

  const isLoading = systemDateTimeIsLoading || graphQlData === STRING_LOADING;
  props.setIsLoading(isLoading);

  // get ss
  const personalizeInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );
  const orderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );
  const userAccountName = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ACCOUNTNAME,
    true
  );
  const userSelectedId = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_SELECTEDSTOREID,
    true
  );
  const userStaffName = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STAFFNAME,
    true
  );

  // Initial values
  let storeNameList = [];
  if (personalizeInfo?.StoreList?.length > 0) {
    storeNameList = personalizeInfo?.StoreList?.map((store) => {
      return { name: store.SalesCompanyDisplayText, value: store.StoreID };
    });
  }

  const initialAgencyName = !stringEmpty(userAccountName)
    ? userAccountName
    : personalizeInfo?.AccountName;
  const initialStoreId = !stringEmpty(userSelectedId) ? userSelectedId : "";
  const initialStoreName = storeNameList.find(
    ({ value }) => value === initialStoreId
  )?.name;
  const initialBoothCode =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
      ?.CMAP_StoreBoothCode__c ?? "";
  const initialStaffName = !stringEmpty(userStaffName)
    ? userStaffName
    : personalizeInfo?.StaffName;
  const initialApplicationDate = !!orderRelatedInfo?.orderRelatedInfo?.orderInfo
    ?.order?.EffectiveDate
    ? orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.EffectiveDate
    : systemDateTime;

  // @Model
  const [inputValues, setInputValues] = useState({
    agencyName: initialAgencyName,
    storeId: initialStoreId,
    storeName: initialStoreName,
    boothCode: initialBoothCode,
    staffName: initialStaffName,
    applicationDate: initialApplicationDate,
  });

  // @Controller
  const handleInputChange = (value, name) => {
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  // control binding
  const agencyInfoConfirmationInputsSection = getScDataFromPlaceholder(
    "agency-info-confirmation-inputs-section",
    props
  );
  const [
    agencyNameInput,
    storeBoothCodeInput,
    staffNameInput,
  ] = getScDataFromComponent("I-01", agencyInfoConfirmationInputsSection);
  const [applicationDateTimePicker] = getScDataFromComponent(
    "M-01i",
    agencyInfoConfirmationInputsSection
  );
  const [storeNameDropDown] = getScDataFromComponent(
    "M-01",
    agencyInfoConfirmationInputsSection
  );

  agencyNameInput.fields.inputValue = {
    name: "agencyName",
    value: inputValues.agencyName,
  };
  agencyNameInput.fields.onChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "agencyName");

  storeBoothCodeInput.fields.inputValue = {
    name: "boothCode",
    value: inputValues.boothCode,
  };
  storeBoothCodeInput.fields.onChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "boothCode");

  staffNameInput.fields.inputValue = {
    name: "staffName",
    value: inputValues.staffName,
  };
  staffNameInput.fields.onChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "staffName");

  if (storeNameDropDown) {
    storeNameDropDown.fields.pulldownData = storeNameList;
    storeNameDropDown.fields.formName = "storeName";
    storeNameDropDown.fields.initialValue = {
      value: inputValues.storeId,
    };
    storeNameDropDown.fields.onChangeEvent = (evt) => {
      const selectedId = evt.target.value;
      const selectedName = storeNameDropDown.fields.pulldownData.find(
        ({ value }) => value === selectedId
      )?.name;
      handleInputChange(selectedId, "storeId");
      handleInputChange(selectedName, "storeName");
    };
  }

  applicationDateTimePicker.fields.initialValue = {
    name: "applicationDate",
    value: inputValues.applicationDate,
  };
  applicationDateTimePicker.fields.calendarPlaceholderFormat = "yyyy/MM/dd";
  applicationDateTimePicker.fields.onChangeEvent = (date) =>
    handleInputChange(format(date, "yyyy-MM-dd"), "applicationDate");

  let maxDate = systemDateTimeData?.SystemDateTime
    ? new Date(systemDateTimeData?.SystemDateTime)
    : new Date();
  let overDate = new Date();
  overDate.setDate(maxDate.getDate() + 1);
  overDate = overDate.toISOString().split("T")[0];
  maxDate = maxDate.toISOString().split("T")[0];

  applicationDateTimePicker.fields.maxDate = maxDate;
  applicationDateTimePicker.fields.minDate = "1970-01-01"; // *no min date required but needed in the component

  // map and store PersonalizeInfo in another sessionStorage
  setSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_MICNO,
    personalizeInfo?.MICNo,
    true
  );

  setSessionStorageItem(
    sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_PHONE,
    personalizeInfo?.Phone,
    true
  );

  const newOrderRelatedInfo = {
    ...orderRelatedInfo,
    orderRelatedInfo: {
      ...orderRelatedInfo?.orderRelatedInfo,
      orderInfo: {
        ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
        order: {
          ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
          CMAP_AcquisitionAgency__c: personalizeInfo?.AccountId,
        },
      },
    },
  };

  setSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    newOrderRelatedInfo,
    true
  );

  // store dates
  if (systemDateTimeData && cPadEstimationDueDate) {
    systemDateTime = new Date(systemDateTimeData.SystemDateTime);

    let estimateDueDate = new Date();
    estimateDueDate.setDate(
      systemDateTime.getDate() + parseInt(cPadEstimationDueDate)
    );
    estimateDueDate = estimateDueDate.toISOString().split("T")[0];

    const descriptionDate = systemDateTime.toISOString().split("T")[0];

    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ESTIMATEDUEDATE,
      estimateDueDate,
      true
    );

    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_DESCRIPTIONDATE,
      descriptionDate,
      true
    );
  }

  // hide/show
  storeBoothCodeInput.fields.isHidden = {
    value: true,
  };
  storeNameDropDown.fields.isHidden = {
    value: true,
  };

  if (personalizeInfo?.SalesChannelSelect === STRING_STORE) {
    storeBoothCodeInput.fields.isHidden = {
      value: false,
    };
    storeNameDropDown.fields.isHidden = {
      value: false,
    };
  }

  // on next button click
  const buttonSection = getScDataFromPlaceholder(
    "agency-info-confirmation-button-section",
    props
  );
  const [nextButton] = getScDataFromComponent("B-01", buttonSection);
  nextButton.fields.onClickEvent = () => {
    // validation
    let isValidate = true;
    clearErrors();

    if (!validationFuncs.validateIsRequired(inputValues?.applicationDate)) {
      setError("applicationDate", {
        type: "manual",
        message: getErrorMessage("E0002", ["申込日"]),
      });
      isValidate = false;
    }

    if (!validateIsValidDateFormat(inputValues?.applicationDate)) {
      setError("applicationDate", {
        type: "manual",
        message: getErrorMessage("E0020", ["申込日", "yyyy/MM/dd"]),
      });
      isValidate = false;
    }

    if (inputValues?.applicationDate > maxDate) {
      setError("applicationDate", {
        type: "manual",
        message: getErrorMessage("E0022", ["申込日", overDate]),
      });
      isValidate = false;
    }

    if (!validationFuncs.validateIsMaxLength(inputValues?.boothCode, 255)) {
      setError("boothCodeLength", {
        type: "manual",
        message: getErrorMessage("E0015", ["店舗ブースコード", 255]),
      });
      isValidate = false;
    }

    if (!isValidate) return;

    // store ss
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ACCOUNTNAME,
      inputValues?.agencyName,
      true
    );

    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_SELECTEDSTOREID,
      inputValues?.storeId,
      true
    );

    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STORENAME,
      inputValues?.storeName,
      true
    );

    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STAFFNAME,
      inputValues?.staffName,
      true
    );

    const savedOrderRelatedInfo = {
      ...orderRelatedInfo,
      orderRelatedInfo: {
        ...orderRelatedInfo?.orderRelatedInfo,
        orderInfo: {
          ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
          order: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
            CMAP_AcquisitionShop__c: inputValues?.storeId,
            CMAP_StoreId__c: inputValues?.storeName,
            CMAP_StoreBoothCode__c: inputValues?.boothCode,
            EffectiveDate: inputValues?.applicationDate,
            CMAP_EffectiveTime__c:
              inputValues?.applicationDate ===
              format(systemDateTime, "yyyy-MM-dd")
                ? format(systemDateTime, "HH:mm:ss")
                : "00:00:00",
          },
        },
      },
    };
    setSessionStorageItem(
      sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
      savedOrderRelatedInfo,
      true
    );

    // redirect
    const {
      "AgencyInfo-NextButtonLink": nextButtonLink,
    } = sitecoreContext.route.fields;
    redirectToLinkUrl(nextButtonLink);
  };

  useEffect(() => {
    // call api
    systemDateTimeRefresh();
  }, []);

  // @View
  return (
    <React.Fragment>
      <main>
        <div className="form_container cpad_wrapper2">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <Placeholder
                name="agency-info-confirmation-heading-section"
                rendering={props.rendering}
              />
              <div className="agency-info-section-confirmation">
                <Placeholder
                  name="agency-info-confirmation-inputs-section"
                  rendering={props.rendering}
                />
              </div>
              <div className="bottom-section chart-bottom-section center">
                <Placeholder
                  name="agency-info-confirmation-button-section"
                  rendering={props.rendering}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};
export default withSitecoreContext()(CPadAgencyInfoConfirmationPageLayout);
