import React, { useState, useEffect } from "react";
import { di_fetch } from "../../utils/di";
import { getProperData, getUrlInfo } from "../../utils/commonUtils";
import { STRING_SIMULATOR, STRING_USERAGENT_MOBILE_APP } from "../../utils/constantVariables";

const HD01 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const [isSimulator, setIsSimulator] = useState(false);
  const [isMobileApp, setIsMobileApp] = useState(false);


  // update the context
  useEffect(() => {
    const urlInfo = getUrlInfo();
    setIsSimulator(urlInfo?.pathnames[1]?.toLocaleLowerCase() === STRING_SIMULATOR);
    
  if (typeof window !== "undefined") {
    const userAgent = window.navigator.userAgent;
    const userAgentCheck = userAgent?.includes(STRING_USERAGENT_MOBILE_APP) ?? false;
    setIsMobileApp(userAgentCheck);
  }
  }, []);

  // @View
  return (
    <React.Fragment>
      {!isMobileApp && (
      <header>
        <div className="header new_application HD-01">
          <div className="header_pos">
            <h1 className="header_pos-left">
              {properData && properData["CommufaHeader-Icon"]?.value?.src && (
                <img
                  src={
                    properData && properData["CommufaHeader-Icon"]?.value?.src
                  }
                  alt="コミュファ powered by ctc"
                />
              )}
              {properData && isSimulator && (
                <span>
                  {` ${properData["CommufaHeader-PriceSimulationLabel"]?.value}`}
                </span>
              )}
            </h1>
          </div>
        </div>
      </header>
      )}
    </React.Fragment>
  );
};

export default HD01;
