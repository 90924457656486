import React, { useState } from "react";
import classnames from "classnames";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import CarretIcon from "../../media/Project/commufa/welcome_commufa/img/icons/caret-down-square.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CPad71PageLayout = (props) => {
  const {
    currencySign,
    basicPriceText,
    optionText,
    promotionText,
    totalText,
    lineCurrentlyUseText,
    commufaHikarriText,
    monthlyPriceText,
    totalPriceText,
    differenceText,
    YearOneText,
    YearTwoText,
    YearsTwoText,
    YearThreeOnwardsText,
    YearFiveText,
    monthlyFeeComparisonText,
    twoYearComparisonText,
    fiveYearComparisonText,
    effectiveMonthlyText,
    EstimateSummaryText,
    taxIncludedText,
    basicChargeText,
    annualFeeText,
    initialCostText,
    enterProductNameText,
    privilegeText,
    enterAwardText,
    printBtnText,
    returnBtnText,
    proceedBtnText,
    agencyInformationText,
    agencyNameText,
    salesPersonText,
    telephoneText,
    explanationDateText,
    storeNameText,
    contactAddressText,
  } = props.fields;
  //total price
  const totalPrice = ["7800", "6800", "6300", "6300"];
  // two years computation = total price 1 * 12 + total price 2 * 12  divide by 24 months (2 years)
  const twoYearComputation = (totalPrice[1] * 12 + totalPrice[2] * 12) / 24;
  // five years computation = twoYearComputation +  total price 3 * 36 (3 years) divide by 60 months (5 years)
  const fiveYearComputation = (twoYearComputation + totalPrice[3] * 36) / 60;
  const tableTextDescriptions = [
    basicPriceText?.value,
    optionText?.value,
    promotionText?.value,
    totalText?.value,
  ];

  const testDrawLine = (tgtChart) => {
    return tgtChart?.data?.datasets?.forEach((dataset, datasetIndex) => {
      if (
        datasetIndex === tgtChart._sortedMetasets?.length - 1 ||
        datasetIndex === 0
      ) {
        dataset.data.forEach((dataPoint, index) => {
          if (index <= dataset?.data?.length - 1 && tgtChart) {
            let bar_width = tgtChart.getDatasetMeta(datasetIndex).data[index]
              .width;
            let bar_width_half = bar_width / 2;
            tgtChart.ctx.beginPath();
            tgtChart.ctx.setLineDash([1, 2]);
            tgtChart.ctx.moveTo(
              tgtChart.getDatasetMeta(datasetIndex).data[index]?.x +
                bar_width_half,
              tgtChart.getDatasetMeta(datasetIndex).data[index]?.y
            );
            tgtChart.ctx.lineTo(
              tgtChart.getDatasetMeta(datasetIndex).data[index + 1]?.x -
                bar_width_half,
              tgtChart.getDatasetMeta(datasetIndex).data[index + 1]?.y
            );
            tgtChart.ctx.stroke();
          }
        });
      }
    });
  };

  const Chart1DrawLine = (tgtChart) => {
    return tgtChart?.data?.datasets?.forEach((dataset, datasetIndex) => {
      if (datasetIndex === 0) {
        dataset.data.forEach((dataPoint, index) => {
          if (index === 0) {
            let bar_width = tgtChart.getDatasetMeta(datasetIndex).data[index]
              .width;
            let bar_width_half = bar_width / 2;
            tgtChart.ctx.beginPath();
            tgtChart.ctx.setLineDash([1, 2]);
            tgtChart.ctx.moveTo(
              tgtChart.getDatasetMeta(datasetIndex + 1).data[0]?.x +
                bar_width_half,
              tgtChart.getDatasetMeta(datasetIndex + 1).data[0]?.y
            );
            tgtChart.ctx.lineTo(
              tgtChart.getDatasetMeta(datasetIndex).data[index + 1]?.x -
                bar_width_half,
              tgtChart.getDatasetMeta(datasetIndex).data[index + 1]?.y
            );
            tgtChart.ctx.stroke();
          } else {
            let bar_width = tgtChart.getDatasetMeta(datasetIndex).data[index]
              .width;
            let bar_width_half = bar_width / 2;
            tgtChart.ctx.beginPath();
            tgtChart.ctx.setLineDash([1, 2]);
            tgtChart.ctx.moveTo(
              tgtChart.getDatasetMeta(datasetIndex).data[index]?.x +
                bar_width_half,
              tgtChart.getDatasetMeta(datasetIndex).data[index]?.y
            );
            tgtChart.ctx.lineTo(
              tgtChart.getDatasetMeta(datasetIndex).data[index + 1]?.x -
                bar_width_half,
              tgtChart.getDatasetMeta(datasetIndex).data[index + 1]?.y
            );
            tgtChart.ctx.stroke();
          }
        });
      }
    });
  };

  const dataLabelSumPlugin = (chart, easing) => {
    const ctx = chart.ctx;
    const sums = [];

    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      if (!meta.hidden) {
        meta.data.forEach((element, index) => {
          if (i === 0) {
            sums[index] = 0;
          }
          ctx.fillStyle = "rgba(0,0,0,0)";
          const fontSize = 16;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          const padding = 10;
          const position = element.tooltipPosition();
          sums[index] = sums[index] + dataset.data[index];
          let txw = ctx.measureText(ctx.font);
          let txw_half = 70 / 2;
          let twx_height =
            txw.actualBoundingBoxAscent + txw.actualBoundingBoxDescent;
          if (i === chart.data.datasets.length - 1) {
            fillRoundRect(
              ctx,
              position.x - txw_half,
              position.y - 30,
              70,
              twx_height + 11,
              13
            );
            ctx.fillStyle = "#000";
            ctx.font = "bolder 10.5pt Yu Gothic";
            ctx.fillText(
              totalPrice[index].toLocaleString() + currencySign.value,
              position.x,
              position.y - fontSize / 2 - padding - 1
            );
          }
        });
      }
    });
  };

  const createRoundRectPath = (ctx, x, y, width, height, radius) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
    ctx.fill();
  };

  const fillRoundRect = (ctx, x, y, w, h, r) => {
    createRoundRectPath(ctx, x, y, w, h, r);
    ctx.fill();
  };
  const options = {
    responsive: false,
    maintainAspectRation: false,
    barPercentage: 1,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      testDrawLine: true,
      function: true,
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: (context) => {
          let value = "#fff";

          if (context.datasetIndex === 1) {
            return (value = "#000");
          }

          return value;
        },
        formatter: (value, context) => {
          return value?.toLocaleString() + currencySign.value;
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 25,
        bottom: 0,
      },
      margin: {
        left: "-50px",
      },
    },
  };

  const collapseData1 = () => {
    return (
      <React.Fragment>
        <tr className="collapse-row">
          <td className="vertical-align-txt">ネット</td>
          <td colSpan={10}>
            <div className="grid-section">
              <div className="D-01">
                <p>ネット</p>
                <p>7,300円</p>
              </div>
              <div className="D-01">
                <p>商品名</p>
                <p>7,300円</p>
              </div>
              <div className="D-01">
                <p>テレビ</p>
                <p>7,300円</p>
              </div>
              <div className="D-01">
                <p>商品名</p>
                <p>7,300円</p>
              </div>
              <div className="D-01">
                <p>電話</p>
                <p>7,300円</p>
              </div>

              <div className="D-01">
                <p>商品名</p>
                <p>7,300円</p>
              </div>
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  };
  const data = [
    {
      price1: "7,300",
      price2: "5,800",
      price3: "5,800",
      price4: "5,800",
      price5: "-1,500",
      price6: "-1,500",
      price7: "-1,500",
      price8: "-36,000",
      price9: "-90,000",
      collapsableRow: true,
      expandedData: collapseData1,
    },
    {
      price1: "1,500",
      price2: "1,500",
      price3: "1,500",
      price4: "1,500",
      price5: "0",
      price6: "0",
      price7: "0",
      price8: "0",
      price9: "0",
      collapsableRow: true,
      expandedData: collapseData1,
    },
    {
      price1: "-1,000",
      price2: "-500",
      price3: "-500",
      price4: "-500",
      price5: "+500",
      price6: "+500",
      price7: "+500",
      price8: "+12,000",
      price9: "+30,000",
      collapsableRow: false,
      expandedData: collapseData1,
    },
    {
      price1: "7,800",
      price2: "6,800",
      price3: "6,300",
      price4: "6,300",
      price5: "-1,000",
      price6: "-1,000",
      price7: "-1,000",
      price8: "-24,000",
      price9: "-60,000",
      collapsableRow: false,
      expandedData: collapseData1,
    },
  ];
  const [expandedRows, setExpandedRows] = useState([]);
  const [activeToggle, setActiveToggle] = useState(1);

  const handleRowClick = (index) => {
    const newExpandedRows = [...expandedRows];
    if (expandedRows.includes(index)) {
      const indexToRemove = newExpandedRows.indexOf(index);
      newExpandedRows.splice(indexToRemove, 1);
    } else {
      newExpandedRows.push(index);
    }
    setExpandedRows(newExpandedRows);
  };

  return (
    <React.Fragment>
      <main>
        <div className="form_container cpad_wrapper2">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <div className="cpad-tables">
                <table className="border-style-1">
                  <thead className="custom-thead">
                    <tr>
                      <th className="no-border" rowSpan={3}>
                        &nbsp;
                      </th>
                      <th>{lineCurrentlyUseText.value}</th>
                      <th colSpan={3} width="257px">
                        {commufaHikarriText.value}
                      </th>
                      <th colSpan={5}>{differenceText.value}</th>
                    </tr>
                    <tr>
                      <th rowSpan={2}>{monthlyPriceText.value}</th>
                      <th colSpan={3}>{monthlyPriceText.value}</th>
                      <th colSpan={3}>{monthlyPriceText.value}</th>
                      <th colSpan={2}>{totalPriceText.value}</th>
                    </tr>
                    <tr>
                      <th>{YearOneText.value}</th>
                      <th>{YearTwoText.value}</th>
                      <th width="88px">{YearThreeOnwardsText.value}</th>
                      <th>{YearOneText.value}</th>
                      <th>{YearTwoText.value}</th>
                      <th>{YearThreeOnwardsText.value}</th>
                      <th>{YearsTwoText.value}</th>
                      <th>{YearFiveText.value}</th>
                    </tr>
                  </thead>
                  <tbody className="left-align-text double-border">
                    {data.map((row, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{tableTextDescriptions[index]}</td>
                          {row.collapsableRow ? (
                            <td>
                              <div className="side-td">
                                <div>
                                  {expandedRows.includes(index) ? (
                                    <span onClick={() => handleRowClick(index)}>
                                      <img
                                        src={CarretIcon}
                                        className="icon upward"
                                      />
                                    </span>
                                  ) : (
                                    <span
                                      className="icon"
                                      onClick={() => handleRowClick(index)}
                                    >
                                      <img src={CarretIcon} />
                                    </span>
                                  )}
                                </div>
                                <div className="D-01">
                                  <p>
                                    {row.price1}
                                    <span className="font-w-normal currency-sign">
                                      {currencySign.value}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td>
                              {row.price1}
                              <span className="font-w-normal currency-sign">
                                {currencySign.value}
                              </span>
                            </td>
                          )}

                          <td>
                            {row.price2}
                            <span className="font-w-normal currency-sign">
                              {currencySign.value}
                            </span>
                          </td>
                          <td>
                            {row.price3}
                            <span className="font-w-normal currency-sign">
                              {currencySign.value}
                            </span>
                          </td>
                          <td>
                            {row.price4}
                            <span className="font-w-normal currency-sign">
                              {currencySign.value}
                            </span>
                          </td>
                          <td>
                            {row.price5}
                            <span className="font-w-normal currency-sign">
                              {currencySign.value}
                            </span>
                          </td>
                          <td>
                            {row.price6}
                            <span className="font-w-normal currency-sign">
                              {currencySign.value}
                            </span>
                          </td>
                          <td>
                            {row.price7}
                            <span className="font-w-normal currency-sign">
                              {currencySign.value}
                            </span>
                          </td>
                          <td>
                            {row.price8}
                            <span className="font-w-normal currency-sign">
                              {currencySign.value}
                            </span>
                          </td>
                          <td>
                            {row.price9}
                            <span className="font-w-normal currency-sign">
                              {currencySign.value}
                            </span>
                          </td>
                        </tr>
                        {expandedRows.includes(index) && row.expandedData()}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="chart-section graph-2">
                <div className="right-container">
                  <div className="graph-top-section">
                    <div>
                      <h2>{monthlyFeeComparisonText.value}</h2>
                      <div className="graph-legends">
                        <div className="D-01">
                          <div className="chart-legend">&nbsp;</div>
                          <p>{basicChargeText.value}</p>
                        </div>
                        <div className="D-01">
                          <div className="chart-legend">&nbsp;</div>
                          <p>{optionText.value}</p>
                        </div>
                      </div>
                    </div>

                    <div className="toggle-section">
                      <dl
                        className={classnames("switch-toggle", {
                          on: activeToggle === 0,
                        })}
                      >
                        <dt
                          className={classnames({
                            active: activeToggle === 0,
                          })}
                          onClick={() =>
                            activeToggle === 0
                              ? setActiveToggle(1)
                              : setActiveToggle(0)
                          }
                        >
                          {monthlyPriceText.value}
                        </dt>
                        <dd
                          className={classnames({
                            active: activeToggle === 1,
                          })}
                          onClick={() =>
                            activeToggle === 0
                              ? setActiveToggle(1)
                              : setActiveToggle(0)
                          }
                        >
                          {effectiveMonthlyText.value}
                        </dd>
                      </dl>
                    </div>
                  </div>

                  <div className="graph-container">
                    <div className="bar-graph-item two">
                      <Bar
                        data={{
                          labels: [
                            "現在利用回線",
                            ["コミュファ光", "1年目"],
                            ["コミュファ光", "2年目"],
                            ["コミュファ光", "3年目以降"],
                          ],
                          datasets: [
                            {
                              label: "基本料金",
                              data: [7300, 5300, 5800, 5800],
                              backgroundColor: ["#ED6C00", "#ED6C00"],
                              borderRadius: 4,
                              categoryPercentage: 0.7,
                            },
                            {
                              label: "オプション",
                              data: [1500, 1500, 1500, 1500],
                              backgroundColor: ["#AAD1EF", "#AAD1EF"],
                              borderRadius: 4,
                              categoryPercentage: 0.7,
                            },
                          ],
                        }}
                        width={400}
                        height={240}
                        options={options}
                        plugins={[
                          ChartDataLabels,
                          { afterDraw: testDrawLine },
                          { afterDraw: dataLabelSumPlugin },
                        ]}
                        className={classnames({
                          show: activeToggle === 0,
                          hide: activeToggle === 1,
                        })}
                        style={{ marginLeft: "-23px" }}
                      />
                      <Bar
                        data={{
                          labels: [
                            "現在利用回線",
                            "2年続けた場合",
                            "5年続けた場合",
                          ],
                          datasets: [
                            {
                              label: "基本料金",
                              data: [
                                7300,
                                twoYearComputation,
                                fiveYearComputation,
                              ],
                              backgroundColor: (color) => {
                                if (color.dataIndex === 0) {
                                  return "#ED6C00";
                                } else {
                                  return "#C45800";
                                }
                              },
                              borderRadius: 4,
                              categoryPercentage: 0.6,
                            },
                            {
                              label: "オプション",
                              data: [1500],
                              backgroundColor: ["#AAD1EF"],
                              borderRadius: 4,
                              categoryPercentage: 0.6,
                            },
                          ],
                        }}
                        width={380}
                        height={240}
                        options={options}
                        plugins={[
                          ChartDataLabels,
                          { afterDraw: Chart1DrawLine },
                          { afterDraw: dataLabelSumPlugin },
                        ]}
                        className={classnames({
                          show: activeToggle === 1,
                          hide: activeToggle === 0,
                        })}
                        style={{ marginLeft: "-33px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="right-container">
                  <div className="graph-top-section cus-width">
                    <div>
                      <h2>{twoYearComparisonText.value}</h2>
                      <div className="graph-legends">
                        <div className="D-01">
                          <div className="chart-legend">&nbsp;</div>
                          <p>{basicChargeText.value}</p>
                        </div>
                        <div className="D-01">
                          <div className="chart-legend">&nbsp;</div>
                          <p>{optionText.value}</p>
                        </div>
                      </div>
                    </div>
                    <h2>{fiveYearComparisonText.value}</h2>
                  </div>

                  <div className="graph-container">
                    <div className="bar-graph-item two">
                      <Bar
                        data={{
                          labels: ["現在利用回線", "コミュファ光"],
                          datasets: [
                            {
                              label: "基本料金",
                              data: [7300, 5300],
                              backgroundColor: ["#ED6C00", "#ED6C00"],
                              borderRadius: 4,
                              categoryPercentage: 0.7,
                            },
                            {
                              label: "オプション",
                              data: [1500, 1500],
                              backgroundColor: ["#AAD1EF", "#AAD1EF"],
                              borderRadius: 4,
                              categoryPercentage: 0.7,
                            },
                          ],
                        }}
                        width={210}
                        height={240}
                        options={options}
                        plugins={[
                          ChartDataLabels,
                          { afterDraw: testDrawLine },
                          { afterDraw: dataLabelSumPlugin },
                        ]}
                        style={{ marginLeft: "-23px" }}
                      />
                      <Bar
                        data={{
                          labels: ["現在利用回線", "コミュファ光"],
                          datasets: [
                            {
                              label: "基本料金",
                              data: [7300, 5300],
                              backgroundColor: ["#ED6C00", "#ED6C00"],
                              borderRadius: 4,
                              categoryPercentage: 0.7,
                            },
                            {
                              label: "オプション",
                              data: [1500, 1500],
                              backgroundColor: ["#AAD1EF", "#AAD1EF"],
                              borderRadius: 4,
                              categoryPercentage: 0.7,
                            },
                          ],
                        }}
                        width={210}
                        height={240}
                        options={options}
                        plugins={[
                          ChartDataLabels,
                          { afterDraw: testDrawLine },
                          { afterDraw: dataLabelSumPlugin },
                        ]}
                        style={{ marginLeft: "-23px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form_wrapper-input summary-section">
                <div className="heading_wrapper H-02">
                  <h2>{EstimateSummaryText.value}</h2>
                </div>
                <h3 className="mb-20">
                  {commufaHikarriText.value}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;6,800 円
                  <span className="txt-small font-w-normal">
                    &nbsp;{taxIncludedText.value}
                  </span>
                </h3>
                <ul>
                  <li>
                    <div className="detail">
                      <div className="content-title">
                        <span>{basicChargeText.value}</span>
                      </div>
                      <div className="text-right">
                        <p>5,800円</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="detail">
                      <div className="content-title">
                        <span>{promotionText.value}</span>
                      </div>
                      <div className="text-right">
                        <p>-500円</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="detail">
                      <div className="content-title">
                        <span>{optionText.value}</span>
                      </div>
                      <div className="text-right">
                        <p>1,500円</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <span className="txt-small">{annualFeeText.value}</span>
                    <div className="detail">
                      <div className="content-title">
                        <span>{enterProductNameText.value}</span>
                      </div>
                      <div className="text-right">
                        <p>5,800円</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <span className="txt-small">{initialCostText.value}</span>
                    <div className="detail">
                      <div className="content-title">
                        <span>{enterProductNameText.value}</span>
                      </div>
                      <div className="text-right">
                        <p>5,800円</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <span className="txt-small">{privilegeText.value}</span>
                    <div className="detail">
                      <div className="content-title">
                        <span>{enterAwardText.value}</span>
                      </div>
                      <div className="text-right">
                        <p>-1,000円</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="form_wrapper-input agency-info-section">
                <div className="heading_wrapper H-02">
                  <h2>{agencyInformationText.value}</h2>
                </div>
                <div className="grid-column two side-label-input">
                  <div className="input-box I-01">
                    <div className="input-form">
                      <div className="input-container">
                        <label>{agencyNameText.value}</label>
                        <input
                          type="text"
                          value="ヤマダデンキ"
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-box I-01">
                    <div className="input-form">
                      <div className="input-container">
                        <label>{salesPersonText.value}</label>
                        <input
                          type="text"
                          value="中部　太郎"
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-box I-01">
                    <div className="input-form">
                      <div className="input-container">
                        <label>{telephoneText.value}</label>
                        <input
                          type="text"
                          value="00123456789"
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-box I-01">
                    <div className="input-form">
                      <div className="input-container">
                        <label>{explanationDateText.value}</label>
                        <input
                          type="text"
                          value="2023/05/26"
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-box I-01">
                    <div className="input-form">
                      <div className="input-container">
                        <label>{storeNameText.value}</label>
                        <input
                          type="text"
                          value="名古屋駅前店"
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-box I-01">
                    <div className="input-form">
                      <div className="input-container">
                        <label>{contactAddressText.value}</label>
                        <input
                          type="text"
                          value="090-1234-5678"
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn_wrapper secondary B-02i print-btn">
                  <button className="btn btn_icon" type="button">
                    {printBtnText.value}
                  </button>
                </div>
              </div>
              <div className="bottom-section chart-bottom-section center">
                <div className="btn_wrapper secondary B-02">
                  <button className="btn btn_default" type="button">
                    {returnBtnText.value}
                  </button>
                </div>
                <div className="btn_wrapper primary B-01">
                  <button className="btn btn_default" type="button">
                    {proceedBtnText.value}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default CPad71PageLayout;
