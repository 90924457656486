import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

// @Model

// get the data

// update the context

// @Controller

// @View

const H03 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { titleText, additionalClass, indexNumber, isHidden } = properData;

  return (
    <React.Fragment>
      {!isHidden && (
        <div className={`H-03 heading_wrapper  ${additionalClass?.value}`}>
          <span>
            <Text field={indexNumber} />
          </span>
          <h3>
            <Text field={titleText} />
          </h3>
        </div>
      )}
    </React.Fragment>
  );
};

export default H03;
