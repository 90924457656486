import React, { useState, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { query as queryButtonList } from "../../assets/graphql/CommonMenuButtonListMaster";
import { useGetDataViaGraphQL as UseGetDataViaGraphQL } from "../../hooks/useGetData";
import { dateCondition } from "../../utils/dateLogic";
import parse from "html-react-parser";
import { sitecoreApiHost } from "../../temp/config";

import { fillBlank } from "../../utils/commonUtils";
import { getSessionStorageItem } from "../../utils/useSessionStorage";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { STRING_LOADING } from "../../utils/constantVariables";

const targetUrlType = (target, targetLink) => {
  // TODO: Change to proper code to open small window
  const width = 600,
    height = 600,
    left = (window.innerWidth - width) / 2,
    top = (window.innerHeight - height) / 2;
  const params = `width=${width},height=${height},left=${left},top=${top}`;
  switch (target) {
    case "1":
      return (window.location.href = targetLink);
    case "3":
      return window.open(targetLink, "_blank", params);
    case "2":
      return window.open(targetLink);
  }
};

export function CommonMenuButtonList(props) {
  const sitecoreContext = props?.sitecoreContext;
  const {
    "CommonMenuButtonList-Title": CommonMenuButtonListTitle,
  } = sitecoreContext?.route?.fields;

  const personalizeInfoData = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );
  const [menuCategory, setMenuCategory] = useState([]);

  const buttonMasterListQuery = UseGetDataViaGraphQL(
    queryButtonList,
    null,
    "ButtonMaster-Query"
  );

  useEffect(() => {
    if (
      !personalizeInfoData?.Contract ||
      personalizeInfoData?.Contract?.length === 0
    ) {
      /// 2.1.1.1 If the acquisition result of [変数].契約情報(Contractinfo) is＝０ OR Null
      setMenuCategory(
        buttonMasterListQuery?.CtcMember?.fields?.map(
          (item) => item?.targetItems
        )
      );
    } else {
      /// 2.1.1.1 If the acquisition result of  [変数].契約情報(ContractInfo) is ＞０
      setMenuCategory(
        buttonMasterListQuery?.CtcMemberWithContract?.fields?.map(
          (item) => item?.targetItems
        )
      );
    }
  }, [buttonMasterListQuery]);

  const isLoading = buttonMasterListQuery === STRING_LOADING;
  props.setIsLoading(isLoading);

  return (
    <div className="list_wrapper multiple-column">
      <div className="h_title">
        <h2>
          <Text field={fillBlank(CommonMenuButtonListTitle?.value)} />
        </h2>
      </div>
      <div className="column-list">
        <ul>
          {menuCategory?.map((subItem) =>
            subItem?.map((exactItem, index) => {
              let sortData = exactItem.fields.sort(
                (a, b) => a.definition.sortOrder - b.definition.sortOrder
              );
              if (dateCondition(sortData[4]?.editable, sortData[5]?.editable)) {
                return (
                  <li key={index}>
                    <a
                      onClick={() =>
                        targetUrlType(
                          sortData[3].targetItem.field?.value,
                          parse(sortData[2]?.editable).props?.href
                        )
                      }
                    >
                      <img
                        src={
                          sitecoreApiHost +
                          parse(sortData[0]?.editable).props?.src
                        }
                      />
                      <p>
                        <Text field={fillBlank(sortData[1]?.editable)} />
                        <span className="arrow-right"></span>
                      </p>
                    </a>
                  </li>
                );
              }
              return null;
            })
          )}
        </ul>
        <div className="hidden-line"></div>
      </div>
    </div>
  );
}
