export const query = `query {
  SimpleOpApplicationAttr: item(path: "/sitecore/content/jss/masterjss/Data/SimpleOpApplicationAttr" language: "en") {
    children {
      fields(excludeStandardFields: true) {
        name
        value
        rendered
        ...on LookupField {
          targetItem {
            Title: field(name: "SimpleOpApplicationTitle-Title") {
              value
            }
          }
        }
      }
      children {
        fields(excludeStandardFields: true) {
          name
          value
          ...on LookupField {
          targetItem {
            Value: field(name: "Value") {
              value
            }
            Description: field(name: "Description") {
              value
            }
          }
        }
      }
    }
  }
  }
}`;
