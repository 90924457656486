import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const L03 = (props) => {
  const {
    buttonSearchLabel,
    searchHeaderName,
    searchAddressHeaderName,
    searchResultName,
    searchResultAddressText,
    noSearchResultText,
  } = props.fields;
  return (
    <React.Fragment>
      <div className="search_wrapper L-03" id="SearchWrapper">
        <div className="input-container">
          <div className="search-container">
            <input
              type="text"
              id="wordSearch"
              placeholder="キーワードで絞り込み"
            />
          </div>
          <div className="btn_wrapper primary">
            <button className="btn btn_medium" type="button">
              <Text field={fillBlank(buttonSearchLabel)} />
            </button>
          </div>
        </div>
        <div className="search-result">
          <table>
            <tbody>
              <tr>
                <th>
                  <Text field={fillBlank(searchHeaderName)} />
                </th>
                <th>
                  <Text field={fillBlank(searchAddressHeaderName)} />
                </th>
              </tr>
              <tr className="result-item" data-label="Amenity東陽">
                <td>
                  <Text field={fillBlank(searchResultName)} />
                </td>
                <td>
                  <Text field={fillBlank(searchResultAddressText)} />
                </td>
              </tr>
            </tbody>
          </table>
          <span className="no-result-found hide">
            <Text field={fillBlank(noSearchResultText)} />
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default L03;
