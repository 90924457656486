import React, { useEffect, useState } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { apiBaseURL } from "../../envSettings";
import { di_register } from "../../utils/di";
import { useCustomQuery } from "../../hooks/useGetData";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import {
  setSessionStorageItem,
  removeAllSessionStorageItem,
} from "../../utils/useSessionStorage";
import { redirectToLinkUrl } from "../../utils/targetUrlType";
import { STRING_POST } from "../../utils/constantVariables";
import {
  SAME_WINDOW,
  STRING_CATEGORY_NEW_CONTRACT,
} from "../../utils/constantVariables";
import { getParamValue } from "../../utils/commonUtils";
import { getGaClientIdFromCookie } from "../../utils/useCookies";

const StoreSessionDataLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  const createOrderRelatedInfoURI = `${apiBaseURL}order/CreateOrderRelatedInfo`;
  const requestParam = {
    orderId: "",
    individualContractId: "",
    caseId: "",
    customerId: "",
    leadId: "",
    orderCategorySelected: STRING_CATEGORY_NEW_CONTRACT,
  };

  const {
    data: createOrderRelatedInfoData,
    isLoading: createOrderRelatedInfoIsLoading,
    status: createOrderRelatedInfoStatus,
  } = useCustomQuery(
    createOrderRelatedInfoURI,
    true,
    STRING_POST,
    requestParam
  );

  props.setIsLoading(createOrderRelatedInfoIsLoading);

  const [gaCookieClientId, setGaCookieClientId] = useState("");

  useEffect(() => {
    setGaCookieClientId(getGaClientIdFromCookie());
  }, []);

  useEffect(() => {
    if (createOrderRelatedInfoData) {
      removeAllSessionStorageItem([sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO, sessionKeys.SSN_COMMON_COMMON_MYCOMMUFAID, sessionKeys.SSN_COMMON_COMMON_CUSTOMERNAME]);

      const residenceType = getParamValue("c_residencetype");
      const offerPlan = getParamValue("c_offerplan");
      const offerPackageId = getParamValue("c_offerpackageid");
      const planType = getParamValue("c_plantype");
      const offerPlanProductListCookie = getParamValue(
        "c_offerplanproductids",
        true
      );
      const benefitListCookie = getParamValue("c_benefitproductids", true);

      const offerPlanProductList = {
        OfferPlanProductList: offerPlanProductListCookie,
      };
      const benefitList = {
        BenefitList: benefitListCookie,
      };

      const account =
        createOrderRelatedInfoData?.orderRelatedInfo?.accountInfo?.account;
      const accountAddition =
        createOrderRelatedInfoData?.orderRelatedInfo?.accountInfo
          ?.accountAddition;
      const order =
        createOrderRelatedInfoData?.orderRelatedInfo?.orderInfo?.order;
      const orderAddition =
        createOrderRelatedInfoData?.orderRelatedInfo?.orderInfo?.orderAddition;

      const orderRelatedInfo = {
        orderRelatedInfo: {
          accountInfo: {
            account: {
              Id: account?.Id,
              CMAP_ContractName__c: account?.CMAP_ContractName__c,
              LastName: account?.LastName,
              FirstName: account?.FirstName,
              LastNameKana__pc: account?.LastNameKana__pc,
              FirstNameKana__pc: account?.FirstNameKana__pc,
              CMAP_Gender__c: account?.CMAP_Gender__c,
              PersonBirthdate: account?.PersonBirthdate,
              CMAP_CorporateGroupName__c: account?.CMAP_CorporateGroupName__c,
              CMAP_DepartmentName__c: account?.CMAP_DepartmentName__c,
              CMAP_ContactPersonFamilyName__c:
                account?.CMAP_ContactPersonFamilyName__c,
              CMAP_ContactPersonName__c: account?.CMAP_ContactPersonName__c,
              CMAP_ContactPersonLastNameKana__pc:
                account?.CMAP_ContactPersonLastNameKana__pc,
              CMAP_ContactPersonFirstNameKana__pc:
                account?.CMAP_ContactPersonFirstNameKana__pc,
              CMAP_ContactPostalCode__c: account?.CMAP_ContactPostalCode__c,
              CMAP_ContactPostalCodeLowerFour__c:
                account?.CMAP_ContactPostalCodeLowerFour__c,
              CMAP_ContactState__c: account?.CMAP_ContactState__c,
              CMAP_ContactCity__c: account?.CMAP_ContactCity__c,
              CMAP_ContactHouseNumber__c: account?.CMAP_ContactHouseNumber__c,
              CMAP_MansionName__c: account?.CMAP_MansionName__c,
              CMAP_RoomNo__c: account?.CMAP_RoomNo__c,
              CMAP_AreaCode__c: account?.CMAP_AreaCode__c,
              CMAP_LocalTelephoneNumber__c:
                account?.CMAP_LocalTelephoneNumber__c,
              CMAP_SubscriberNumber__c: account?.CMAP_SubscriberNumber__c,
              CMAP_MobileNumber1__c: account?.CMAP_MobileNumber1__c,
              CMAP_MobileNumber2__c: account?.CMAP_MobileNumber2__c,
              CMAP_MobileNumber3__c: account?.CMAP_MobileNumber3__c,
              CMAP_MyCommufaID__c: account?.CMAP_MyCommufaID__c,
              CMAP_MultilingualReexplainCenter__c:
                account?.CMAP_MultilingualReexplainCenter__c,
            },
            accountAddition: {
              isNewAccount: accountAddition?.isNewAccount,
              leadId: accountAddition?.leadId,
            },
          },
          orderInfo: {
            order: {
              AccountId: order?.AccountId,
              CMAP_ContractName__c: order?.CMAP_ContractName__c,
              CMAP_Case__c: order?.CMAP_Case__c,
              CMAP_Billing__c: order?.CMAP_Billing__c,
              CMAP_BillingPaymentMethodAgent__c:
                order?.CMAP_BillingPaymentMethodAgent__c,
              CMAP_BillingPaymentMethodRegistered__c:
                order?.CMAP_BillingPaymentMethodRegistered__c,
              CMAP_NeedsSendingDocumentOfBilling__c:
                order?.CMAP_NeedsSendingDocumentOfBilling__c,
              CMAP_AcquisitionAgency__c: order?.CMAP_AcquisitionAgency__c,
              CMAP_AcquisitionShop__c: order?.CMAP_AcquisitionShop__c,
              CMAP_IndividualContractComplementary__c:
                order?.CMAP_IndividualContractComplementary__c,
              CMAP_ContractDocDeliveryCategory__c:
                order?.CMAP_ContractDocDeliveryCategory__c,
              CMAP_StandardProductOffer__c: order?.CMAP_StandardProductOffer__c,
              CMAP_MovementStandardProductOffer__c:
                order?.CMAP_MovementStandardProductOffer__c,
              CMAP_ResidenceType__c: residenceType,
              CMAP_OfferPlan__c: offerPlan,
              CMAP_OfferPackageId__c: offerPackageId,
              CMAP_OrderCategory__c: STRING_CATEGORY_NEW_CONTRACT,
              CMAP_ClientId__c: gaCookieClientId,
            },
            orderAddition: {
              orderChannelCategory: orderAddition?.orderChannelCategory,
              planType: planType,
              orderCategoryCurrentlySelected: STRING_CATEGORY_NEW_CONTRACT,
            },
            orderItemInfos: createOrderRelatedInfoData?.orderRelatedInfo?.orderInfo?.orderItemInfos?.map(
              (orderItemInfos) => {
                const orderItem = orderItemInfos.orderItem;
                const orderItemAddition = orderItemInfos.orderItemAddition;
                return {
                  orderItem: {
                    CMAP_OfferPlanProduct__c:
                      orderItem?.CMAP_OfferPlanProduct__c,
                    CMAP_MainSubProductGroupId__c:
                      orderItem?.CMAP_MainSubProductGroupId__c,
                    CMAP_MainSubProductGroupCategory__c:
                      orderItem?.CMAP_MainSubProductGroupCategory__c,
                    CMAP_MainSubProductGroupName__c:
                      orderItem?.CMAP_MainSubProductGroupName__c,
                    CMAP_ProductCategoryId__c:
                      orderItem?.CMAP_ProductCategoryId__c,
                    CMAP_ProductCategoryName__c:
                      orderItem?.CMAP_ProductCategoryName__c,
                    Product2Id: orderItem?.Product2Id,
                    CMAP_ProductName__c: orderItem?.CMAP_ProductName__c,
                    UnitPrice: orderItem?.UnitPrice,
                    CMAP_ComsumptionTaxRate__c:
                      orderItem?.CMAP_ComsumptionTaxRate__c,
                    Quantity: orderItem?.Quantity,
                    CMAP_PriceCategory__c: orderItem?.CMAP_PriceCategory__c,
                    CMAP_InstallmentPaymentMonthly__c:
                      orderItem?.CMAP_InstallmentPaymentMonthly__c,
                    CMAP_InstallmentPayMonthlyPriceTaxExc__c:
                      orderItem?.CMAP_InstallmentPayMonthlyPriceTaxExc__c,
                    CMAP_NumberOfInstallments__c:
                      orderItem?.CMAP_NumberOfInstallments__c,
                    CMAP_InstallmentPayFirstMonthPriceTaxExc__c:
                      orderItem?.CMAP_InstallmentPayFirstMonthPriceTaxExc__c,
                    CMAP_InstallmentPaymentCurrently__c:
                      orderItem?.CMAP_InstallmentPaymentCurrently__c,
                    CMAP_SelectionAnnualPayment__c:
                      orderItem?.CMAP_SelectionAnnualPayment__c,
                    CMAP_AnnualPaymentPriceTaxExcluded__c:
                      orderItem?.CMAP_AnnualPaymentPriceTaxExcluded__c,
                    CMAP_Unchargeable__c: orderItem?.CMAP_Unchargeable__c,
                    CMAP_ProductSelectionState__c:
                      orderItem?.CMAP_ProductSelectionState__c,
                    CMAP_LastTimeChangeCategory__c:
                      orderItem?.CMAP_LastTimeChangeCategory__c,
                    CMAP_HasOrderItemAttribute__c:
                      orderItem?.CMAP_HasOrderItemAttribute__c,
                    CMAP_AvailableDailyPricing__c:
                      orderItem?.CMAP_AvailableDailyPricing__c,
                    CMAP_UnchangeableContract__c:
                      orderItem?.CMAP_UnchangeableContract__c,
                    CMAP_SetProduct__c: orderItem?.CMAP_SetProduct__c,
                    CMAP_HasPriceChanged__c: orderItem?.CMAP_HasPriceChanged__c,
                    CMAP_TaxationCategory__c:
                      orderItem?.CMAP_TaxationCategory__c,
                    CMAP_RemainingDebtLumpSumPayment__c:
                    orderItem?.CMAP_RemainingDebtLumpSumPayment__c,
                    CMAP_ChangeSourceDestinationCategory__c:
                      orderItem?.CMAP_ChangeSourceDestinationCategory__c,
                    CMAP_InheritsMovementAssetAttribute__c:
                      orderItem?.CMAP_InheritsMovementAssetAttribute__c,
                    CMAP_OrderItemTypeSelection__c:
                      orderItem?.CMAP_OrderItemTypeSelection__c,
                    CMAP_OrderItemTypeBenefit__c:
                      orderItem?.CMAP_OrderItemTypeBenefit__c,
                    CMAP_OrderItemTypeAutomaticSelection__c:
                      orderItem?.CMAP_OrderItemTypeAutomaticSelection__c,
                    CMAP_BeforeOffer__c: orderItem?.CMAP_BeforeOffer__c,
                    CMAP_MainSubProGroupBenefitCPSortOrder__c:
                      orderItem?.CMAP_MainSubProGroupBenefitCPSortOrder__c,
                    CMAP_ProductCategoryBenefitSortOrder__c:
                      orderItem?.CMAP_ProductCategoryBenefitSortOrder__c,
                    CMAP_ProductSortOrder__c:
                      orderItem?.CMAP_ProductSortOrder__c,
                    CMAP_StandardProduct__c: orderItem?.CMAP_StandardProduct__c,
                    CMAP_PredecessorOfferStartRequest__c:
                      orderItem?.CMAP_PredecessorOfferStartRequest__c,
                    CMAP_ExemptionPeriod__c: orderItem?.CMAP_ExemptionPeriod__c,
                  },
                  orderItemAddition: {
                    precaution: orderItemAddition?.precaution,
                    tooltip: orderItemAddition?.tooltip,
                    cancellationPrecaution:
                      orderItemAddition?.cancellationPrecaution,
                    terminationPrecaution:
                      orderItemAddition?.terminationPrecaution,
                    otherCompanyScreenTransitionLinkDescription:
                      orderItemAddition?.otherCompanyScreenTransitionLinkDescription,
                    otherCompanyScreenTransitionLinkURL:
                      orderItemAddition?.otherCompanyScreenTransitionLinkURL,
                    quantityCategory: orderItemAddition?.quantityCategory,
                    totalPrice: orderItemAddition?.totalPrice,
                  },
                };
              }
            ),
          },
        },
      };

      setSessionStorageItem(
        sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
        orderRelatedInfo,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_APPLICATION_STORESESSIONDATA_OFFERPLANPRODUCTLIST,
        offerPlanProductList,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_APPLICATION_STORESESSIONDATA_BENEFITLIST,
        benefitList,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_APPLICATION_SELECTPLANTYPE_PLANTYPE,
        planType,
        true
      );

      const linkURL =
        sitecoreContext.route.fields["SessionDataStorage-NextPageLink"]?.value;
      redirectToLinkUrl(linkURL, SAME_WINDOW);
    }
  }, [createOrderRelatedInfoData, createOrderRelatedInfoStatus]);

  return <></>;
};

export default withSitecoreContext()(StoreSessionDataLayout);
