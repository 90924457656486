import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import {
  useGetDataViaGraphQL,
  useCustomQuery,
  useDynamicParallelQuery,
} from "../../hooks/useGetData";
import { query as orderItemAttrCheckboxMasterQuery } from "../../assets/graphql/OrderItemAttrCheckboxMaster";
import { apiBaseURL } from "../../envSettings";
import {
  getProperData,
  getScDataFromComponent,
  getScDataFromPlaceholder,
  redirectToLinkUrl,
  deepcopy,
  generateGUID,
  formatCurrencyChar,
} from "../../utils/commonUtils";
import { di_fetch, di_register } from "../../utils/di";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import ModalContext from "../../assets/context/ModalContext";
import { apiResultCode } from "../../assets/consts";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import {
  STRING_CORPORATION,
  STRING_GET,
  STRING_INDIVIDUAL,
  STRING_POST,
  STRING_LOADING,
} from "../../utils/constantVariables";
import {
	parseOptionString,
	matchMetaOptions
} from "../../utils/parseMetadata";

// Calculations
import {
  createCancellationChargeObj,
  createDebtObj,
} from "../../services/calculations/Contract-Cancel/calculateContractCancel";

const addDataRowList = (key, value) => {
  return {
    fields: {
      Key: {
        value: key,
      },
      Value: {
        value: value,
      },
    },
  };
};

const createCancellationChargeItems = (title, dataRowList = []) => {
  let childMultiItem = [];
  if (dataRowList instanceof Array && dataRowList.length > 0) {
    childMultiItem = dataRowList?.map(({ item, charge }) => ({
      displayName: item,
      name: item,
      fields: {
        contentLabel: item,
        contentText: formatCurrencyChar(charge),
      },
    }));
  }

  return {
    displayName: title,
    name: title,
    id: generateGUID(),
    fields: {
      textAreaLabel: { value: title },
      MultilistItems: childMultiItem,
    },
  };
};

const CancellationPlanAndProductConfirmation = (props) => {
  const orderRelatedInfo = props.orderRelatedInfo;

  const section = getScDataFromPlaceholder(
    "cancellation-details-section",
    props
  );
  const c10List = getScDataFromComponent("C-10", section);
  c10List[0].fields.detailsText = getSessionStorageItem(
    keys.SSN_REPORT_CONTRACTLIST_CONTRACTNUMBER,
    true
  ).toString();
  c10List[1].fields.detailsText = getSessionStorageItem(
    keys.SSN_REPORT_CONTRACTLIST_ADDRESS,
    true
  );
  c10List[2].fields.hideDetailsText = true;

  const [l02Data] = getScDataFromComponent("L-02", section);
  l02Data.fields.hiddenHeader = true;
  l02Data.fields.titleText = getSessionStorageItem(
    keys.SSN_REPORT_CONTRACTLIST_PLANNAME,
    true
  );

  useEffect(() => {
    if (
      orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos &&
      orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos?.length > 0
    ) {
      orderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos.forEach(
        (item) => {
          if (item.orderItem.CMAP_ProductName__c !== undefined) {
            l02Data.fields.dataRowList.push(
              addDataRowList(
                item.orderItem.CMAP_ProductName__c,
                formatCurrencyChar(
                  item.orderItem.CMAP_UnitPriceTaxIncluded__c *
                    item.orderItem.Quantity
                )
              )
            );
          }
        }
      );
    }

    l02Data.fields.hideAccordion = l02Data.fields.dataRowList?.length === 0;
  }, []);

  return (
    <div className="form_wrapper-input">
      <Placeholder
        name="cancellation-details-section"
        rendering={props.rendering}
      />
    </div>
  );
};

const ConfirmationOfApplicationAttributes = (props) => {
  const { pageContext, orderRelatedInfo, orderItemAttrCheckboxList} = props;
  const [productIds, setProductIds] = useState([]);
  const [
    confirmationApplicationVisible,
    setConfirmationApplicationVisible,
  ] = useState(true);

  const orderChannelCategory = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  )?.AppChannelDivision;

  const {
    isLoading: isRetrieveOrderItemAttrDefinitionsLoading,
    results: RetrieveOrderItemAttrDefinitionsData,
  } = useDynamicParallelQuery(
    productIds,
    `${apiBaseURL}order/RetrieveOrderItemAttrDefinitions`,
    STRING_POST
  );

  let applicationAttrSection = getScDataFromPlaceholder(
    "application-attributes-plan-canceled-section",
    props
  );
  const [applicationAttrTitle] = getScDataFromComponent(
    "H-02",
    applicationAttrSection
  );
  const [applicationAttr] = getScDataFromComponent(
    "L-01",
    applicationAttrSection
  );
  applicationAttr.fields.isHidden = true;

  const parsedOrderItemAttr = useMemo(() => {
    // TODO: Review and update if the API Response changes
    let componentsList = [];
    if (isRetrieveOrderItemAttrDefinitionsLoading || !orderItemAttrCheckboxList) return componentsList;

    const orderItemInfos =
      orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos;
    if (orderItemInfos && orderItemInfos.length > 0) {
      applicationAttrTitle.fields.isHidden = orderItemInfos.every(
        ({ orderItemAttributes, orderItem }) =>
          orderItemAttributes.length === 0 ||
          Object.keys(orderItem).length === 0
      );

      orderItemInfos.forEach((itemInfo, index) => {
        if (itemInfo.orderItem.CMAP_HasOrderItemAttribute__c === true) {
          // instantiate dynamic component
          let applicationAttrCopy = deepcopy(applicationAttr);
          applicationAttrCopy.fields.dataRowList = [];
          applicationAttrCopy.fields.isHidden =
            itemInfo?.orderItemAttributes.length === 0;
          applicationAttrCopy.uid =
            "application_attribute_copy_" +
            index +
            "_" +
            applicationAttrCopy.uid;

          const productIndex = productIds.findIndex(
            (e) => e.productId === itemInfo.orderItem.Product2Id
          );
          const metadata =
            RetrieveOrderItemAttrDefinitionsData[productIndex]?.data?.data
              ?.orderItemAttributeDefinitionInfo?.metadataDefinitions;

          if (metadata) {
            Object.values(metadata).forEach((defInfo) => {
              // populate L01 dataRowList
              itemInfo.orderItemAttributes.forEach((itemAttr) => {
                Object.values(defInfo.fieldMetadataDefinitions ?? []).forEach(
                  (fieldInfo) => {
                    const fieldMetadataExtension =
                      fieldInfo?.fieldMetadata?.fieldMetadataExtension;
                    const fieldMetadataAddition =
                      fieldInfo?.fieldMetadata?.fieldMetadataAddition;

                    if (
                      fieldMetadataAddition.orderItemAttributeDefinitionId ===
                      itemAttr.CMAP_OrderItemAttributeDefenition__c
                    ) {
                      // determine the value displayed
                      let itemValue;

                      switch (fieldMetadataExtension?.CMAP_Type__c?.toLocaleLowerCase()) {
                        case 'long':
                          itemValue = itemAttr?.CMAP_ValueLongTextArea__c.replace(/(?:\r\n|\r|\n)/g, '<br>');
                          break;
                        case 'date':
                          itemValue = moment(itemAttr?.CMAP_Value__c).format("YYYY年MM月DD日");
                          break;
                        case 'datetime':
                          itemValue = itemValue = moment(itemAttr?.CMAP_Value__c).format("YYYY年MM月DD日 hh:mm:ss");
                          break;
                        case 'checkbox':
                          const matchCheckboxValue = orderItemAttrCheckboxList.find((orderItemAttrCheckbox) => !!orderItemAttrCheckbox.code === (itemAttr?.CMAP_Value__c.toLowerCase() === "true"))?.value;
                          itemValue = matchCheckboxValue;
                          break;
                        case 'combobox':
                          const matchOptionLabel = matchMetaOptions(parseOptionString(fieldMetadataExtension?.CMAP_Option__c), itemAttr?.CMAP_Value__c);
                          itemValue = matchOptionLabel;
                          break;
                        default:
                          itemValue = itemAttr?.CMAP_Value__c.replace(/(?:\r\n|\r|\n)/g, '<br>');
                      }

                      const row = {
                        ...addDataRowList(
                          fieldMetadataExtension?.CMAP_FieldLabel__c,
                          itemValue
                        ),
                        CMAP_FieldName__c: fieldMetadataExtension?.CMAP_FieldName__c,
                      };
                      applicationAttrCopy.fields.dataRowList.push(row);
                    }
                  }
                );
              });

              // order the fields
              const fieldOrders = defInfo.fieldOrders ?? [];
              const sortOrder = {};
              for (let i = 0; i < fieldOrders.length; i++) {
                sortOrder[fieldOrders[i]] = i;
              }
              applicationAttrCopy.fields.dataRowList.sort((p1, p2) => sortOrder[p1?.CMAP_FieldName__c] - sortOrder[p2?.CMAP_FieldName__c]);
            });

            // if CMAP_HasOrderItemAttribute__c is true but
            // orderItemAttributes is empty then hide the list
            if (!applicationAttrCopy.fields.isHidden) {
              //set the subtitle
              applicationAttrCopy.fields.titleText.value =
                itemInfo.orderItem.CMAP_ProductName__c +
                pageContext[
                  "ConfirmationOfApplicationAttributes-SubTitleSuffix"
                ].value;
              componentsList.push(applicationAttrCopy);
            }
          }
        }
      });
    }

    return componentsList;
  }, [
    orderRelatedInfo,
    RetrieveOrderItemAttrDefinitionsData,
    isRetrieveOrderItemAttrDefinitionsLoading,
    orderItemAttrCheckboxList
  ]);

  //display dynamic component
  for (let i = 0; i < parsedOrderItemAttr.length; i++) {
    if (i === 0) applicationAttrSection.splice(1);
    applicationAttrSection[i + 1] = parsedOrderItemAttr[i];
  }

  useEffect(() => {
    if (
      orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos &&
      orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos?.length > 0
    ) {
      setConfirmationApplicationVisible(true);
      let productIds = [];
      //create a list of Ids where orderItem.CMAP_HasOrderItemAttribute__c = true
      orderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos.forEach(
        (item) => {
          if (item?.orderItem?.CMAP_HasOrderItemAttribute__c === true) {
            productIds.push({
              productId: item.orderItem.Product2Id,
              orderChannelCategory: orderChannelCategory,
            });
          }
        }
      );
      setProductIds(productIds || []);
    } else {
      setConfirmationApplicationVisible(false);
    }
  }, [orderRelatedInfo]);

  return (
    <>
      {confirmationApplicationVisible && (
        <div className="form_wrapper-input mb-32">
          <Placeholder
            name="application-attributes-plan-canceled-section"
            rendering={props.rendering}
          />
        </div>
      )}
    </>
  );
};

const ConfirmationOfTerminationRequestDate = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const pageContext = sitecoreContext?.route?.fields;
  const orderRelatedInfo = props.orderRelatedInfo;

  const reasonLabel = getSessionStorageItem(
    keys.SSN_APPLICATION_CONTRACTCANCEL_TERMINATIONREASONLABEL,
    true
  );

  const section = getScDataFromPlaceholder(
    "confirmation-of-termination-request-date-section",
    props
  );
  const [l01Data] = getScDataFromComponent("L-01", section);
  l01Data.fields.dataRowList = [];
  l01Data.fields.dataRowList.push(
    addDataRowList(
      pageContext["ConfirmationOfTerminationRequestDate-LastUtilizationDate"]
        .value,
      moment(
        orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
          ?.CMAP_LastUtilizationDate__c,
        "YYYY-MM-DD"
      ).format("YYYY年MM月DD日")
    )
  );
  l01Data.fields.dataRowList.push(
    addDataRowList(
      pageContext["ConfirmationOfTerminationRequestDate-ReasonOfTermination"]
        .value,
      `${reasonLabel}<br>${orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_TerminationReasonOther__c}`
    )
  );

  return (
    <div className="form_wrapper-input">
      <Placeholder
        name="confirmation-of-termination-request-date-section"
        rendering={props.rendering}
      />
    </div>
  );
};

const CancellationCharge = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const pageContext = sitecoreContext?.route?.fields;

  const section = getScDataFromPlaceholder(
    "cancellation-fee-details-section",
    props
  );
  const [c07Data] = getScDataFromComponent("C-07", section);

  let totalCancellationCharge = 0;

  // TODO: delete test data
  const cancellationChargeObj = useMemo(() => {
    return createCancellationChargeObj();
  }, []);

  c07Data.fields.MultilistItems = [];

  if (cancellationChargeObj && cancellationChargeObj?.length > 0) {
    const mappedCancelChargeData = cancellationChargeObj?.map(
      (cancelChargeItem) => {
        const productName = cancelChargeItem?.CMAP_ProductName__c;
        const fee = cancelChargeItem?.PriceTaxInc;

        totalCancellationCharge += fee;

        return {
          item: productName,
          charge: fee,
        };
      }
    );

    c07Data.fields.MultilistItems.push(
      createCancellationChargeItems(
        pageContext["CancellationCharge-CancellationChargeLabel"]?.value,
        mappedCancelChargeData
      )
    );
  }

  // TODO: delete test data
  const debtObj = useMemo(() => {
    return createDebtObj();
  }, []);

  if (debtObj && debtObj?.length > 0) {
    const mappedDebtData = debtObj?.map((debtItem) => {
      const productName = debtItem?.CMAP_ProductName__c;
      const fee = debtItem?.PriceTaxInc;

      totalCancellationCharge += fee;

      return {
        item: productName,
        charge: fee,
      };
    });

    c07Data.fields.MultilistItems.push(
      createCancellationChargeItems(
        pageContext["CancellationCharge-DebtItemLabel"]?.value,
        mappedDebtData
      )
    );
  }

  c07Data.fields.priceText = formatCurrencyChar(totalCancellationCharge); // total

  return (
    <div className="form_wrapper-input">
      <Placeholder
        name="cancellation-fee-details-section"
        rendering={props.rendering}
      />
    </div>
  );
};

const CancellationConfirmationPageButton = (props) => {
  return (
    <div className="btn_container">
      <Placeholder name="btn_container" rendering={props.rendering} />
    </div>
  );
};

const ContractCancelConfirmationLayout = (props) => {
  // TODO: Remove test data

  const sitecoreContext = props?.sitecoreContext;
  const pageContext = sitecoreContext?.route?.fields;
  di_register("thisPageSitecoreContext", sitecoreContext);

  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  // get master data
  const orderItemAttrCheckboxMaster = useGetDataViaGraphQL(
    orderItemAttrCheckboxMasterQuery,
    null,
    "orderItemAttrCheckboxMaster"
  );

  const [orderItemAttrCheckboxList, setOrderItemAttrCheckboxList] = useState(null);

  const { dispatch, state } = useContext(ModalContext);
  const { setError, clearErrors } = useFormContext();
  const orderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );

  const [checkOrderRequest, setCheckOrderRequest] = useState(null);
  const [registerOrderRequest, setRegisterOrderRequest] = useState(null);

  const {
    data: retrieveSystemDateTimeData,
    isLoading: isRetrievesystemDateTimeLoading,
  } = useCustomQuery(
    `${apiBaseURL}common/RetrieveSystemDateTime`,
    true,
    STRING_GET
  );

  const createCheckRegisterOrderApiRequest = (orderRelatedInfo) => {
    if (!orderRelatedInfo) return {};

    const billingTo = orderRelatedInfo?.orderRelatedInfo?.billingTo;
    const { account, accountAddition } =
      orderRelatedInfo?.orderRelatedInfo?.accountInfo ?? {};
    const CMAPContractName = account?.CMAP_ContractName__c;
    const {
      order,
      orderAddition,
      orderImportantMatterDescriptions,
      orderItemInfos,
    } = orderRelatedInfo?.orderRelatedInfo?.orderInfo ?? {};

    const result = {
      orderRelatedInfo: {
        accountInfo: {
          account: {
            Id: account?.Id,
            CMAP_ContractName__c: account?.CMAP_ContractName__c,
            LastName: account?.LastName,
            ...(CMAPContractName === STRING_INDIVIDUAL && {
              FirstName: account?.FirstName,
              LastNameKana__pc: account?.LastNameKana__pc,
              FirstNameKana__pc: account?.FirstNameKana__pc,
              CMAP_Gender__c: account?.CMAP_Gender__c,
              PersonBirthdate: account?.PersonBirthdate,
            }),
            ...(CMAPContractName === STRING_CORPORATION && {
              CMAP_CorporateGroupName__c: account?.CMAP_CorporateGroupName__c,
              CMAP_DepartmentName__c: account?.CMAP_DepartmentName__c,
              CMAP_ContactPersonFamilyName__c:
                account?.CMAP_ContactPersonFamilyName__c,
              CMAP_ContactPersonName__c: account?.CMAP_ContactPersonName__c,
              CMAP_ContactPersonLastNameKana__pc:
                account?.CMAP_ContactPersonLastNameKana__pc,
              CMAP_ContactPersonFirstNameKana__pc:
                account?.CMAP_ContactPersonFirstNameKana__pc,
            }),
            CMAP_ContactPostalCode__c: account?.CMAP_ContactPostalCode__c,
            CMAP_ContactPostalCodeLowerFour__c:
              account?.CMAP_ContactPostalCodeLowerFour__c,
            CMAP_ContactState__c: account?.CMAP_ContactState__c,
            CMAP_ContactCity__c: account?.CMAP_ContactCity__c,
            CMAP_ContactHouseNumber__c: account?.CMAP_ContactHouseNumber__c,
            CMAP_MansionName__c: account?.CMAP_MansionName__c,
            CMAP_RoomNo__c: account?.CMAP_RoomNo__c,
            CMAP_AreaCode__c: account?.CMAP_AreaCode__c,
            CMAP_LocalTelephoneNumber__c: account?.CMAP_LocalTelephoneNumber__c,
            CMAP_SubscriberNumber__c: account?.CMAP_SubscriberNumber__c,
            CMAP_MobileNumber1__c: account?.CMAP_MobileNumber1__c,
            CMAP_MobileNumber2__c: account?.CMAP_MobileNumber2__c,
            CMAP_MobileNumber3__c: account?.CMAP_MobileNumber3__c,
            CMAP_MyCommufaID__c: account?.CMAP_MyCommufaID__c,
            CMAP_MultilingualReexplainCenter__c:
              account?.CMAP_MultilingualReexplainCenter__c,
          },
          accountAddition: {
            isNewAccount: accountAddition?.isNewAccount,
            leadId: accountAddition?.leadId,
          },
        },
        orderInfo: {
          order: {
            CMAP_OrderCategory__c: order?.CMAP_OrderCategory__c,
            CMAP_OrderBeforeChange__c: order?.CMAP_OrderBeforeChange__c,
            EffectiveDate: order?.EffectiveDate,
            CMAP_OrderChannelDetail__c: order?.CMAP_OrderChannelDetail__c,
            AccountId: order?.AccountId,
            CMAP_ResidenceType__c: order?.CMAP_ResidenceType__c,
            CMAP_BuildingCategory__c: order?.CMAP_BuildingCategory__c,
            CMAP_OfferPlan__c: order?.CMAP_OfferPlan__c,
            CMAP_OfferPlanName__c: order?.CMAP_OfferPlanName__c,
            CMAP_OfferPackageId__c: order?.CMAP_OfferPackageId__c,
            CMAP_IsTypeCircuit__c: order?.CMAP_IsTypeCircuit__c,
            CMAP_IsTypeCircuitTypeJ__c: order?.CMAP_IsTypeCircuitTypeJ__c,
            CMAP_IsTypeNotCircuit__c: order?.CMAP_IsTypeNotCircuit__c,
            CMAP_IsTypeGoodsServiceSales__c:
              order?.CMAP_IsTypeGoodsServiceSales__c,
            CMAP_IsTypeCircuitWireless__c: order?.CMAP_IsTypeCircuitWireless__c,
            CMAP_InstallationLocationOfferArea__c:
              order?.CMAP_InstallationLocationOfferArea__c,
            CMAP_InstallLocLayerDeliveryDuration__c:
              order?.CMAP_InstallLocLayerDeliveryDuration__c,
            CMAP_StandardOpeningDays__c: order?.CMAP_StandardOpeningDays__c,
            CMAP_StandardOpeningMonths__c: order?.CMAP_StandardOpeningMonths__c,
            CMAP_ApartmentPropertyName__c: order?.CMAP_ApartmentPropertyName__c,
            CMAP_PropertyAddress__c: order?.CMAP_PropertyAddress__c,
            CMAP_PropertyAddressCode11__c: order?.CMAP_PropertyAddressCode11__c,
            CMAP_PropertyCity__c: order?.CMAP_PropertyCity__c,
            CMAP_PropertyOfferMethod__c: order?.CMAP_PropertyOfferMethod__c,
            CMAP_Case__c: order?.CMAP_Case__c,
            CMAP_Billing__c: order?.CMAP_Billing__c,
            CMAP_BillingPaymentMethodAgent__c:
              order?.CMAP_BillingPaymentMethodAgent__c,
            CMAP_BillingPaymentMethodRegistered__c:
              order?.CMAP_BillingPaymentMethodRegistered__c,
            CMAP_NeedsSendingDocumentOfBilling__c:
              order?.CMAP_NeedsSendingDocumentOfBilling__c,
            CMAP_AcquisitionAgency__c: order?.CMAP_AcquisitionAgency__c,
            CMAP_AcquisitionShop__c: order?.CMAP_AcquisitionShop__c,
            CMAP_IndividualContractPlan__c:
              order?.CMAP_IndividualContractPlan__c,
            CMAP_CancelDate__c: order?.CMAP_CancelDate__c,
            CMAP_CancelReason__c: order?.CMAP_CancelReason__c,
            CMAP_CancelReasonOther__c: order?.CMAP_CancelReasonOther__c,
            CMAP_StartRequestDate__c: order?.CMAP_StartRequestDate__c,
            CMAP_TerminationReason__c: order?.CMAP_TerminationReason__c,
            CMAP_TerminationReasonOther__c:
              order?.CMAP_TerminationReasonOther__c,
            CMAP_SuspendRequestDate__c: order?.CMAP_SuspendRequestDate__c,
            CMAP_SuspendResumeProspectDate__c:
              order?.CMAP_SuspendResumeProspectDate__c,
            CMAP_ResumeRequestDate__c: order?.CMAP_ResumeRequestDate__c,
            CMAP_UpdateAddRequestDate__c: order?.CMAP_UpdateAddRequestDate__c,
            CMAP_ProductChangeAddRequestDate__c:
              order?.CMAP_ProductChangeAddRequestDate__c,
            CMAP_UpdateLastUtilizationDate__c:
              order?.CMAP_UpdateLastUtilizationDate__c,
            CMAP_ProductChangeLastUtilizationDate__c:
              order?.CMAP_ProductChangeLastUtilizationDate__c,
            CMAP_LastUtilizationDate__c: order?.CMAP_LastUtilizationDate__c,
            CMAP_PlanChangeRequestDate__c: order?.CMAP_PlanChangeRequestDate__c,
            CMAP_ChangeSourceLastUtilizationDate__c:
              order?.CMAP_ChangeSourceLastUtilizationDate__c,
            ShippingStreet: order?.ShippingStreet,
            ShippingCity: order?.ShippingCity,
            ShippingState: order?.ShippingState,
            ShippingPostalCode: order?.ShippingPostalCode,
            ShippingLatitude: order?.ShippingLatitude,
            ShippingLongitude: order?.ShippingLongitude,
            CMAP_InstallationLocationAddressCode11__c:
              order?.CMAP_InstallationLocationAddressCode11__c,
            CMAP_InstallationLocationAddressCode__c:
              order?.CMAP_InstallationLocationAddressCode__c,
            CMAP_InstallationLocationCity__c:
              order?.CMAP_InstallationLocationCity__c,
            CMAP_InstallationLocationHouseNumber__c:
              order?.CMAP_InstallationLocationHouseNumber__c,
            CMAP_InstallationLocationRoomNumber__c:
              order?.CMAP_InstallationLocationRoomNumber__c,
            CMAP_EffectiveTime__c: order?.CMAP_EffectiveTime__c,
            CMAP_ClientId__c: order?.CMAP_ClientId__c,
            CMAP_DeliveryServiceAddress__PostalCode__s:
              order?.CMAP_DeliveryServiceAddress__PostalCode__s,
            CMAP_DeliveryServiceAddress__StateCode__s:
              order?.CMAP_DeliveryServiceAddress__StateCode__s,
            CMAP_DeliveryServiceAddress__City__s:
              order?.CMAP_DeliveryServiceAddress__City__s,
            CMAP_DeliveryServiceAddress__Street__s:
              order?.CMAP_DeliveryServiceAddress__Street__s,
            CMAP_DeliveryServiceAddressBuildingName__c:
              order?.CMAP_DeliveryServiceAddressBuildingName__c,
            CMAP_DeliveryServiceAddressRoomNumber__c:
              order?.CMAP_DeliveryServiceAddressRoomNumber__c,
            CMAP_CampaignBenefitAddition__c:
              order?.CMAP_CampaignBenefitAddition__c,
            CMAP_ChangeSourceOfferPlan__c: order?.CMAP_ChangeSourceOfferPlan__c,
            CMAP_ChangeSourceOpportunity__c:
              order?.CMAP_ChangeSourceOpportunity__c,
            CMAP_ChangeSourceIndividualContractPur__c:
              order?.CMAP_ChangeSourceIndividualContractPur__c,
            CMAP_ChangeSourceOfferPlanName__c:
              order?.CMAP_ChangeSourceOfferPlanName__c,
            CMAP_ChangeSourceTypeCircuit__c:
              order?.CMAP_ChangeSourceTypeCircuit__c,
            CMAP_ChangeSourceTypeCircuitWireless__c:
              order?.CMAP_ChangeSourceTypeCircuitWireless__c,
            CMAP_ChangeSourceTypeCircuitTypeJ__c:
              order?.CMAP_ChangeSourceTypeCircuitTypeJ__c,
            CMAP_ChangeSourceTypeNotCircuit__c:
              order?.CMAP_ChangeSourceTypeNotCircuit__c,
            CMAP_ChangeSourceCase__c: order?.CMAP_ChangeSourceCase__c,
            CMAP_ChangeSourceResidenceType__c:
              order?.CMAP_ChangeSourceResidenceType__c,
            CMAP_ChangeSourceBuildingCategory__c:
              order?.CMAP_ChangeSourceBuildingCategory__c,
            CMAP_ChangeSourceInstallationLocAddr__c:
              order?.CMAP_ChangeSourceInstallationLocAddr__c,
            CMAP_ChangeSourceDeliverySrvAddrForDisp__c:
              order?.CMAP_ChangeSourceDeliverySrvAddrForDisp__c,
            CMAP_CancelOrderChannelDetail__c:
              order?.CMAP_CancelOrderChannelDetail__c,
            CMAP_Rebuilding__c: order?.CMAP_Rebuilding__c,
            CMAP_IndividualContractComplementary__c:
              order?.CMAP_IndividualContractComplementary__c,
            CMAP_PersonalInformationConsentDate__c:
              order?.CMAP_PersonalInformationConsentDate__c,
            CMAP_ContractDocDeliveryCategory__c:
              order?.CMAP_ContractDocDeliveryCategory__c,
            CMAP_StandardProductOffer__c: order?.CMAP_StandardProductOffer__c,
            CMAP_MovementStandardProductOffer__c:
              order?.CMAP_MovementStandardProductOffer__c,
            CMAP_ConsentFamilyover65__c: order?.CMAP_ConsentFamilyover65__c,
            CMAP_TelePreferredCommunicationDay__c:
              order?.CMAP_TelePreferredCommunicationDay__c,
            CMAP_TelePreferredCommunicationTime__c:
              order?.CMAP_TelePreferredCommunicationTime__c,
            CMAP_RequestNotToSend__c: order?.CMAP_RequestNotToSend__c,
            CMAP_SupportedLanguage__c: order?.CMAP_SupportedLanguage__c,
            CMAP_PreferredCommunicationMethod__c:
              order?.CMAP_PreferredCommunicationMethod__c,
            CMAP_HearingImpairmentFlag__c: order?.CMAP_HearingImpairmentFlag__c,
            CMAP_TranslatorName__c: order?.CMAP_TranslatorName__c,
            CMAP_TranslatorKana__c: order?.CMAP_TranslatorKana__c,
            CMAP_HopeForConsulting1__c: order?.CMAP_HopeForConsulting1__c,
            CMAP_HopeForConsulting1Other__c:
              order?.CMAP_HopeForConsulting1Other__c,
            CMAP_HopeForConsulting2__c: order?.CMAP_HopeForConsulting2__c,
            CMAP_HopeForConsulting2Other__c:
              order?.CMAP_HopeForConsulting2Other__c,
            CMAP_RadioSurvey__c: order?.CMAP_RadioSurvey__c,
            CMAP_SeniorCitizen__c: order?.CMAP_SeniorCitizen__c,
            CMAP_RequestInvestigationTVanotherDay__c:
              order?.CMAP_RequestInvestigationTVanotherDay__c,
            CMAP_ManagementCompanyConsent__c:
              order?.CMAP_ManagementCompanyConsent__c,
            CMAP_StoreId__c: order?.CMAP_StoreId__c,
            CMAP_OtherCustomerRequest__c: order?.CMAP_OtherCustomerRequest__c,
            CMAP_StoreBoothCode__c: order?.CMAP_StoreBoothCode__c,
          },
          orderAddition: {
            orderCategoryCurrentlySelected:
              orderAddition?.orderCategoryCurrentlySelected,
            orderCategoryPreviouslySelected:
              orderAddition?.orderCategoryPreviouslySelected,
            orderChannelCategory: orderAddition?.orderChannelCategory,
            previousLastModifiedDate: orderAddition?.previousLastModifiedDate,
            electronicSignature: orderAddition?.electronicSignature,
          },
          orderImportantMatterDescriptions: orderImportantMatterDescriptions?.map(
            (desc) => ({
              CMAP_ImportantMatterDescription__c:
                desc?.CMAP_ImportantMatterDescription__c,
              CMAP_ProductGroup__c: desc?.CMAP_ProductGroup__c,
              CMAP_SetParentProduct__c: desc?.CMAP_SetParentProduct__c,
              CMAP_ConsentDateTime__c: desc?.CMAP_ConsentDateTime__c,
              CMAP_ImportantMatterDescriptionConsent__c:
                desc?.CMAP_ImportantMatterDescriptionConsent__c,
            })
          ),
          orderItemInfos: orderItemInfos?.map((item) => ({
            orderItem: {
              CMAP_OfferPlanProduct__c:
                item?.orderItem?.CMAP_OfferPlanProduct__c,
              Product2Id: item?.orderItem?.Product2Id,
              UnitPrice: item?.orderItem?.UnitPrice,
              CMAP_ComsumptionTaxRate__c:
                item?.orderItem?.CMAP_ComsumptionTaxRate__c,
              Quantity: item?.orderItem?.Quantity,
              CMAP_PriceCategory__c: item?.orderItem?.CMAP_PriceCategory__c,
              CMAP_InstallmentPaymentMonthly__c:
                item?.orderItem?.CMAP_InstallmentPaymentMonthly__c,
              CMAP_InstallmentPayMonthlyPriceTaxExc__c:
                item?.orderItem?.CMAP_InstallmentPayMonthlyPriceTaxExc__c,
              CMAP_NumberOfInstallments__c:
                item?.orderItem?.CMAP_NumberOfInstallments__c,
              CMAP_InstallmentPayFirstMonthPriceTaxExc__c:
                item?.orderItem?.CMAP_InstallmentPayFirstMonthPriceTaxExc__c,
              CMAP_SelectionAnnualPayment__c:
                item?.orderItem?.CMAP_SelectionAnnualPayment__c,
              CMAP_AnnualPaymentPriceTaxExcluded__c:
                item?.orderItem?.CMAP_AnnualPaymentPriceTaxExcluded__c,
              CMAP_Unchargeable__c: item?.orderItem?.CMAP_Unchargeable__c,
              CMAP_Asset__c: item?.orderItem?.CMAP_Asset__c,
              CMAP_ProductSelectionState__c:
                item?.orderItem?.CMAP_ProductSelectionState__c,
              CMAP_LastTimeChangeCategory__c:
                item?.orderItem?.CMAP_LastTimeChangeCategory__c,
              CMAP_HasOrderItemAttribute__c:
                item?.orderItem?.CMAP_HasOrderItemAttribute__c,
              CMAP_AvailableDailyPricing__c:
                item?.orderItem?.CMAP_AvailableDailyPricing__c,
              CMAP_SetProduct__c: item?.orderItem?.CMAP_SetProduct__c,
              CMAP_BenefitProduct__c: item?.orderItem?.CMAP_BenefitProduct__c,
              CMAP_HasPriceChanged__c: item?.orderItem?.CMAP_HasPriceChanged__c,
              CMAP_OrderAutomaticSelectionProduct__c:
                item?.orderItem?.CMAP_OrderAutomaticSelectionProduct__c,
              CMAP_TaxationCategory__c:
                item?.orderItem?.CMAP_TaxationCategory__c,
              CMAP_installmentFlag__c: item?.orderItem?.CMAP_installmentFlag__c,
              CMAP_ChangeSourceDestinationCategory__c:
                item?.orderItem?.CMAP_ChangeSourceDestinationCategory__c,
              CMAP_InheritsMovementAssetAttribute__c:
                item?.orderItem?.CMAP_InheritsMovementAssetAttribute__c,
              CMAP_ChangeSourceOrderItem__c:
                item?.orderItem?.CMAP_ChangeSourceOrderItem__c,
              CMAP_OrderItemBeforeChange__c:
                item?.orderItem?.CMAP_OrderItemBeforeChange__c,
              CMAP_QuantityValueChange__c:
                item?.orderItem?.CMAP_QuantityValueChange__c,
              CMAP_PredecessorOfferStartRequest__c:
                item?.orderItem?.CMAP_PredecessorOfferStartRequest__c,
              CMAP_ExemptionPeriod__c: item?.orderItem?.CMAP_ExemptionPeriod__c,
            },
            orderItemAddition: {
              previousLastModifiedDate:
                item?.orderItemAddition?.previousLastModifiedDate,
            },
            orderItemAttributes: item?.orderItemAttributes?.map((attr) => ({
              CMAP_OrderItemAttributeDefenition__c:
                attr?.CMAP_OrderItemAttributeDefenition__c,
              CMAP_Value__c: attr?.CMAP_Value__c,
              CMAP_ValueLongTextArea__c: attr?.CMAP_ValueLongTextArea__c,
              CMAP_ValueChanged__c: attr?.CMAP_ValueChanged__c,
              CMAP_AssetAttribute__c: attr?.CMAP_AssetAttribute__c,
            })),
          })),
        },
        billingTo: {
          CMAP_PaymentMethod__c: billingTo?.CMAP_PaymentMethod__c,
          CMAP_CreditCardNumberFirstFourDigits__c:
            billingTo?.CMAP_CreditCardNumberFirstFourDigits__c,
          CMAP_CreditCardNumberLastFourDigits__c:
            billingTo?.CMAP_CreditCardNumberLastFourDigits__c,
          CMAP_YamaguchiInfoId__c: billingTo?.CMAP_YamaguchiInfoId__c,
          CMAP_CreditCardName__c: billingTo?.CMAP_CreditCardName__c,
          CMAP_CreditCardExpirationDate__c:
            billingTo?.CMAP_CreditCardExpirationDate__c,
          CMAP_CreditCardBankRelationship__c:
            billingTo?.CMAP_CreditCardBankRelationship__c,
          CMAP_CardCompany__c: billingTo?.CMAP_CardCompany__c,
          CMAP_IndividualCorporationKbn__c:
            billingTo?.CMAP_IndividualCorporationKbn__c,
          CMAP_PaymentRegistrationMethod__c:
            billingTo?.CMAP_PaymentRegistrationMethod__c,
          CMAP_AccountTransferBillingNumber__c:
            billingTo?.CMAP_AccountTransferBillingNumber__c,
        },
      },
    };

    return result;
  };

  const {
    data: checkOrderData,
    isLoading: isCheckOrderLoading,
    refetch: checkOrderDataRefresh,
  } = useCustomQuery(
    `${apiBaseURL}order/CheckOrder`,
    false,
    STRING_POST,
    checkOrderRequest
  );

  const {
    data: registerOrderData,
    isLoading: isRegisterOrderLoading,
    refetch: registerOrderRefetch,
  } = useCustomQuery(
    `${apiBaseURL}order/RegisterOrder`,
    false,
    STRING_POST,
    registerOrderRequest
  );

  const isApiLoading =
    isCheckOrderLoading ||
    isRegisterOrderLoading ||
    isRetrievesystemDateTimeLoading ||
    orderItemAttrCheckboxMaster === STRING_LOADING;
  props.setIsLoading(isApiLoading);

  // receive orderItemAttrCheckboxMaster data
  useEffect(() => {
    const orderItemAttrCheckboxData = orderItemAttrCheckboxMaster?.orderItemAttrCheckboxMaster?.children?.map(
      (item) => {
        return {
          value: item.fields.find((x) => x.name === "Value")?.value,
          code: parseInt(item.fields.find((x) => x.name === "Code")?.value),
        };
      }
    );
    if (orderItemAttrCheckboxData?.length) {
      setOrderItemAttrCheckboxList(orderItemAttrCheckboxData);
    }
  }, [orderItemAttrCheckboxMaster]);
  
  // receive systemDateTime data
  useEffect(() => {
    if (retrieveSystemDateTimeData) {
      const systemDateTime = new Date(
        retrieveSystemDateTimeData?.SystemDateTime
      );

      const systemDateTimeYMD = moment(systemDateTime).format("YYYY-MM-DD");
      const systemDateTimeHMS = moment(systemDateTime).format("HH:mm:ss");

      const updatedOrderRelatedInfo = {
        ...orderRelatedInfo,
        orderRelatedInfo: {
          ...orderRelatedInfo?.orderRelatedInfo,
          orderInfo: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
            order: {
              ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
              EffectiveDate: systemDateTimeYMD,
              CMAP_EffectiveTime__c: systemDateTimeHMS,
            },
          },
        },
      };

      setSessionStorageItem(
        keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
        updatedOrderRelatedInfo,
        true
      );

      const orderInfoApiRequest = createCheckRegisterOrderApiRequest(
        updatedOrderRelatedInfo
      );

      setCheckOrderRequest({
        ...orderInfoApiRequest,
      });

      setRegisterOrderRequest({
        orderCheckProcessAlertIgnored: true,
        ...orderInfoApiRequest,
      });
    }
  }, [retrieveSystemDateTimeData]);

  // receive checkOrderRequest
  useEffect(() => {
    if (checkOrderRequest) checkOrderDataRefresh();
  }, [checkOrderRequest]);

  const btnSection = getScDataFromPlaceholder("btn_container", props);

  const [backButton] = getScDataFromComponent("B-02", btnSection);
  backButton.fields.onClickEvent = () => {
    redirectToPreviousPage();
  };

  const [nextButton] = getScDataFromComponent("B-01", btnSection);
  nextButton.fields.onClickEvent = async () => {
    clearErrors();

    registerOrderRefetch();
  };

  useEffect(() => {
    if (checkOrderData) {
      const processResult = checkOrderData.processResult;

      if (processResult.returnCode === apiResultCode.error) {
        processResult.resultInfos.forEach((err, index) => {
          if (err.resultCategory === apiResultCode.error) {
            setError(err.resultCode + index, {
              type: "manual",
              message: err.resultMessage,
            });
          }
        });
        nextButton.fields.isDisabled = { value: true };
      }

      if (processResult.returnCode === apiResultCode.alert) {
        const cancelDetailSection = getScDataFromPlaceholder(
          "warning-modal-section",
          props
        );
        const [popup] = getScDataFromComponent("P0-01", cancelDetailSection);
        popup.fields.isCustom = true;

        const modalSection = getScDataFromPlaceholder("modal-section", popup);
        const [warningModal] = getScDataFromComponent(
          "Warning-Message-Modal-Layout",
          modalSection
        );
        warningModal.fields = {
          descriptionText: pageContext["WarningMessageModal-Description"],
          closeText: pageContext["WarningMessageModal-CloseButtonText"],
          confirmText: pageContext["WarningMessageModal-ApplyButtonText"],
          warningMessageList: processResult.resultInfos.reduce((acc, err) => {
            if (err.resultCategory === apiResultCode.alert) {
              acc.push({
                code: err.resultCode,
                message: err.resultMessage,
              });
            }
            return acc;
          }, []),
          onCancelClick: () => {
            nextButton.fields.isDisabled = { value: true };
          },
        };

        dispatch({
          type: "SHOW_MODAL",
          payload: { id: popup?.fields?.modalTarget?.value },
        });
      }
    }
  }, [checkOrderData]);

  useEffect(() => {
    if (registerOrderData) {
      const processResult = registerOrderData.processResult;

      if (processResult?.returnCode === apiResultCode.error) {
        processResult.resultInfos.forEach((err, index) => {
          if (err.resultCategory === apiResultCode.error) {
            setError(err.resultCode + index, {
              type: "manual",
              message: err.resultMessage,
            });
          }
        });
      }

      if (processResult.returnCode === apiResultCode.success) {
        //store CMAP_OrderReceiptNumber__c in session storage
        const receiptNumber =
          registerOrderData?.orderRelatedInfo?.orderInfo?.order
            ?.CMAP_OrderReceiptNumber__c;
        setSessionStorageItem(
          keys.SSN_COMMON_COMMON_APPLICATIONNUMBER,
          receiptNumber,
          true
        );

        const { linkURL } = getProperData(
          props.sitecoreContext,
          nextButton.fields
        );
        redirectToLinkUrl(linkURL);
      }
    }
  }, [registerOrderData]);

  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <Placeholder
                name="warning-modal-section"
                rendering={props.rendering}
              />
              <CancellationPlanAndProductConfirmation
                rendering={props.rendering}
                orderRelatedInfo={orderRelatedInfo}
              />
              <ConfirmationOfApplicationAttributes
                rendering={props.rendering}
                orderRelatedInfo={orderRelatedInfo}
                orderItemAttrCheckboxList={orderItemAttrCheckboxList}
                pageContext={pageContext}
              />

              <ConfirmationOfTerminationRequestDate
                rendering={props.rendering}
                orderRelatedInfo={orderRelatedInfo}
              />
              <CancellationCharge rendering={props.rendering} />
              <CancellationConfirmationPageButton rendering={props.rendering} />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(ContractCancelConfirmationLayout);
