import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { format, parse } from "date-fns";
import {
  fillBlank,
  preventDoubleClick,
  formatCurrency,
  formatDate,
} from "../../utils/commonUtils";
import { useCustomQuery } from "../../hooks/useGetData";
import { apiBaseURL } from "../../envSettings";
import { getSessionStorageItem } from "../../utils/useSessionStorage";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import {
  DATE_FORMAT_YYYYMM,
  DATE_FORMAT_YYYY年M月,
  STRING_GET,
} from "../../utils/constantVariables";

export function CommonUsageFee(props) {
  const sitecoreContext = props?.sitecoreContext;
  const {
    BillingDetailButtonText,
    BillingDetailButtonLink,
    PastBillingListButtonText,
    PastBillingListButtonLink,
    "CommonUsageFee-TitleLabel": CommonUsageFeeTitleLabel,
    "CommonUsageFee-BilledAmountThisMonthTargetStLabel": CommonUsageFeeBilledAmountThisMonthTargetStLabel,
    "CommonUsageFee-BilledAmountThisMonthTargetEdLabel": CommonUsageFeeBilledAmountThisMonthTargetEdLabel,
    "CommonUsageFee-BilledAmountThisMonthSumLabel": CommonUsageFeeBilledAmountThisMonthSumLabel,
    "CommonUsageFee-BilledAmountThisMonthSumAfterLabel": CommonUsageFeeBilledAmountThisMonthSumAfterLabel,
    "CommonUsageFee-DiscountLabel": CommonUsageFeeDiscountLabel,
    "CommonUsageFee-DiscountYenLabel": CommonUsageFeeDiscountYenLabel,
  } = sitecoreContext?.route?.fields;

  let personalizeInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );

  const customerBillingAmountURI = `${apiBaseURL}contract/RetrieveCustomerBillingAmount?accountId=${personalizeInfo.AccountId}`;

  const {
    data: customerBillingAmountData,
    isLoading: customerBillingAmountIsLoading,
    error: customerBillingAmountError,
    refetch: customerBillingAmountRefresh,
    remove: customerBillingAmountRemove,
  } = useCustomQuery(customerBillingAmountURI, true, STRING_GET);

  props.setIsLoading(customerBillingAmountIsLoading)

  const billingAmountResult = customerBillingAmountData?.result;
  const isBillingAmountEmpty =
    billingAmountResult?.billingAmount === "" ||
    billingAmountResult?.billingAmount === null;
  const isDisablePastBillingListButton =
    billingAmountResult?.pastBillsExistFlg === false ? "disabled" : "";

  const updatedBillingDataLink = BillingDetailButtonLink?.value?.href
    ?.replace("{AccountId}", personalizeInfo.AccountId)
    ?.replace(
      "{BillingInfoType}",
      billingAmountResult?.billingPageURLParameter
    );

  const updatedPassBillingListLink = PastBillingListButtonLink?.value?.href?.replace(
    "{AccountId}",
    personalizeInfo.AccountId
  );

  //functions
  const handleButtonClick = preventDoubleClick(async (url) =>
    window.open(url, "_blank")
  );

  const getFormattedDate = (date) => {
    return fillBlank(
      formatDate(date, DATE_FORMAT_YYYY年M月, DATE_FORMAT_YYYYMM)
    ).value;
  };

  return (
    <div className="list_wrapper two-column">
      <div className="h_title">
        <h2>{fillBlank(CommonUsageFeeTitleLabel).value}</h2>
      </div>
      <div className="column-list">
        <ul>
          <li>
            <span className="bold pc_only">
              {getFormattedDate(billingAmountResult?.billingYM)}
              {
                fillBlank(CommonUsageFeeBilledAmountThisMonthTargetStLabel)
                  .value
              }
            </span>
            ({getFormattedDate(billingAmountResult?.useYM)}
            {fillBlank(CommonUsageFeeBilledAmountThisMonthTargetEdLabel).value})
          </li>
          <li>
            {fillBlank(CommonUsageFeeBilledAmountThisMonthSumLabel).value}
            <span className="bold text-primary text-big">
              {formatCurrency(
                fillBlank(billingAmountResult?.billingAmount).value
              )}
            </span>
            {fillBlank(CommonUsageFeeBilledAmountThisMonthSumAfterLabel).value}
          </li>
          <li>
            <span className="text-small">
              <span className="mr-10">
                {fillBlank(CommonUsageFeeDiscountLabel).value}
              </span>
              <Text
                tag="span"
                className="bold"
                field={fillBlank(
                  formatCurrency(
                    isBillingAmountEmpty
                      ? "-"
                      : billingAmountResult?.discountAmount
                  )
                )}
              />
              {fillBlank(CommonUsageFeeDiscountYenLabel).value}
            </span>
          </li>
        </ul>
        <ul className="column-list_buttons">
          <div className="btn_wrapper primary big">
            <button
              className={`btn btn_default ${
                isBillingAmountEmpty ? "disabled" : ""
              }`}
              type="button"
              onClick={() => handleButtonClick(updatedBillingDataLink)}
              disabled={isBillingAmountEmpty}
            >
              <Text field={fillBlank(BillingDetailButtonText)} />
            </button>
          </div>
          <div className="btn_wrapper primary big">
            <button
              className={`btn btn_white ${isDisablePastBillingListButton}`}
              type="button"
              onClick={() => handleButtonClick(updatedPassBillingListLink)}
              disabled={isDisablePastBillingListButton === "disabled"}
            >
              <Text field={fillBlank(PastBillingListButtonText)} />
            </button>
          </div>
        </ul>
      </div>
    </div>
  );
}
