import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank, preventDoubleClick } from "../../utils/commonUtils";

// @Model

// get the data

// update the context

// @Controller

// @View

const P04 = (props) => {
  const {
    accordionTitleText,
    isAccordionActive = false,
    onClickEvent,
  } = props.fields;

  const handleOnClickEvent = preventDoubleClick(
    async (event, myClickEvent = undefined) => {
      if (myClickEvent) {
        myClickEvent();
        return;
      }
    }
  );

  return (
    <React.Fragment>
      <div
        className={`accordion-title ${isAccordionActive ? "active" : ""} P-04`}
        onClick={(e) => handleOnClickEvent(e, onClickEvent)}
      >
        <h5>
          <Text field={fillBlank(accordionTitleText)} />
        </h5>
      </div>
    </React.Fragment>
  );
};

export default P04;
