import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import {
  preventDoubleClick,
  openButtonLink,
  getProperData,
  fillBlank,
} from "../../utils/commonUtils";

const ApplicationInformation = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    ApplicationInformationIcon,
    ApplicationInformationDescription,
    ApplicationInformationButtonText,
    ApplicationInformationLinkUrl,
    ApplicationInformationLinkUrlForSP,
    ApplicationInformationListItemList,
    btnWrapperAdditionalClass,
    queryString,
  } = properData;

  // @Controller
  const handleButtonClick = preventDoubleClick(
    async (evt, myClickEvent = undefined, linkURL) => {
      if (myClickEvent) {
        myClickEvent();
        return false;
      }

      if (props?.onClick) props.onClick();

      let urlInfo = linkURL?.value;
      let linkType = urlInfo?.linktype;
      let url = urlInfo?.href;
      if (linkType == "javascript") {
        eval(url);
        return true;
      }

      openButtonLink(url, urlInfo?.target);
    }
  );

  const openButtonLink = (url, target) => {
    if (target === "" || typeof target == "undefined") {
      window.location.href =
        queryString !== undefined ? `${url}?${queryString}` : url;
    } else if (target === "_blank") {
      const urlPath = queryString !== undefined ? `${url}?${queryString}` : url;
      window.open(urlPath);
    }
  };

  return (
    <React.Fragment>
      {ApplicationInformationListItemList?.map((item, index) => {
        return (
          <div
            className="list-button-container ApplicationInformation"
            key={`list_${index}`}
          >
            <div className="list_wrapper with-icon">
              <div className="icon-list">
                <img
                  className="icon"
                  src={
                    fillBlank(item.ApplicationInformationIcon.value.src)?.value
                  }
                />
                <span>
                  <RichText
                    field={fillBlank(item.ApplicationInformationDescription)}
                  />
                </span>
              </div>
            </div>
            <div className={`btn_wrapper ${btnWrapperAdditionalClass?.value}`}>
              <button
                className="btn btn_default"
                type="button"
                onClick={(e) =>
                  handleButtonClick(
                    e,
                    props?.fields?.onClickEvent,
                    item.ApplicationInformationLinkUrl
                  )
                }
              >
                <Text
                  field={fillBlank(item.ApplicationInformationButtonText)}
                />
              </button>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default ApplicationInformation;
