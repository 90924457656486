export const query = `
    query RetrieveSettingItem ($site: String) {
      item(path: $site) {
          fields(excludeStandardFields: true) {
              name,
              value,
              editable,
              rendered
          }
      }
    }    
`;
