import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const Li01 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { linkText, additionalClass } = properData;

  // @View
  return (
    <React.Fragment>
      <div className={`link_wrapper icon  Li-01 ${additionalClass?.value}`}>
        <a href="#">
          <span>
            <Text field={fillBlank(linkText)} />
          </span>
        </a>
      </div>
    </React.Fragment>
  );
};

export default Li01;
