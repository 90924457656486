import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from "../../utils/commonUtils";
import ModalContext from "../../assets/context/ModalContext";

const WarningMessageModalLayout = ({ fields = {} }) => {
  const {
    closeText,
    confirmText,
    descriptionText,
    modalTarget,
    warningMessageList = [],
  } = fields;
  const { dispatch } = useContext(ModalContext);

  const closeModal = () => {
    dispatch({
      type: "HIDE_MODAL",
      payload: { id: modalTarget },
    });
  };

  const handleCancelButtonClick = () => {
    closeModal();
    if (fields?.onCancelClick) {
      fields.onCancelClick({ continueProcess: false });
    }
  };

  const handleConfirmButtonClick = () => {
    closeModal();
    if (fields?.onConfirmClick) {
      fields.onConfirmClick({ continueProcess: true });
    }
  };

  return (
    <>
      <div className="modal-body">
        <div className="modal-body_content">
          {descriptionText && (
            <Text field={fillBlank(descriptionText)} tag="p" encode={false} />
          )}
          <div className="mt-16">
            {warningMessageList.map(({ code, message }) => (
              <div key={code} className="warning-message">
                <Text field={fillBlank(code)} tag="p" encode={false} />
                <Text field={fillBlank(message)} tag="p" encode={false} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        {closeText?.value && (
          <div className="btn_wrapper secondary B-02">
            <button
              className="btn btn_default"
              type="button"
              data-dismiss="modal"
              onClick={handleCancelButtonClick}
            >
              <Text field={fillBlank(closeText)} />
            </button>
          </div>
        )}
        {confirmText?.value && (
          <div className="btn_wrapper primary B-01">
            <button
              className="btn btn_default"
              type="button"
              onClick={handleConfirmButtonClick}
            >
              <Text field={fillBlank(confirmText)} />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default WarningMessageModalLayout;
