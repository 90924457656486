import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const C10 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { titleText, detailsText, additionalClass } = properData;

  return (
    <React.Fragment>
      {!properData?.isHidden && (
        <div
          className={`decoration-content individual no-border C-10 ${additionalClass?.value}`}
        >
          <div className="information">
            <ul>
              <li>
                <span>
                  <Text field={fillBlank(titleText)} />
                </span>
                {!properData?.hideDetailsText && (
                  <p>
                    <Text field={fillBlank(detailsText)} />
                  </p>
                )}
              </li>
            </ul>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default C10;
