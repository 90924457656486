import React, { useState, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import {
  getProperData,
  fillBlank,
  preventDoubleClick,
  decryptAuthCode,
  generateAuthCode,
} from "../../utils/commonUtils";
import { useFormDataStore, useCustomerInfoStore } from "../../hooks/useSimpleOpState";
import { apiBaseURL } from "../../envSettings";
import { useCustomQuery } from "../../hooks/useGetData";
import {
  STRING_POST,
} from "../../utils/constantVariables"
import { setSessionStorageItem } from "../../utils/useSessionStorage";
import { useFormContext } from "react-hook-form";
import { getErrorMessage } from "../../utils/errorMessageHandler";
import { validationFuncs } from "../../utils/validations";

// @Model

// get the data

// update the context

// @Controller

// @View

const I06 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    inputTitleText,
    confirmationTitleText,
    requiredText,
    inputFormClass,
    addressPlaceholder,
    domainPlaceholder,
    isHidden,
    descriptionText,
    name,
    type,
    sendAuthCodeText,
    inputAuthCodeLabel,
    authCodePlaceholder,
    verificationText,
    completeVerificationText,
    mailItem,
    sendingMailFailureMsg,
    authGuideMsg,
    authCompleteGuideMsg,
  } = properData;
  let { inputValue } = properData;
  const [isHiddenField, setIsHiddenField] = useState("");
  const [isMailSeningEventCalled, setIsMailSeningEventCalled] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);
  const [authCode, setAuthCode] = useState("");
  const [authCodeParam, setAuthCodeParam] = useState();
  const [isVerified, setIsVerified] = useState(false);
  const {
    setFormDataEmail,
    formData,
  } = useFormDataStore((state) => state);
  const {
    setCustomerInfoEmail,
    customerInfo,
  } = useCustomerInfoStore((state) => state);
  const { setError, clearErrors } = useFormContext();

  const sendAuthenticationCodeEmailURI = `${apiBaseURL}simpleOp/SendAuthenticationCodeEmail`;
  const {
    data: sendAuthenticationCodeEmailData,
    isLoading: sendAuthenticationCodeEmailIsLoading,
    refetch: sendAuthenticationCodeEmailRefetch,
    remove: sendAuthenticationCodeEmailRemove,
  } = useCustomQuery(
    sendAuthenticationCodeEmailURI,
    false,
    STRING_POST,
    authCodeParam,
  );

  const handleButtonClick = preventDoubleClick(
    async (evt, myClickEvent = undefined) => {
      if (myClickEvent) {
        myClickEvent();
        return false;
      }
    }
  );

  const moveToErrorMessage = (hasError) => {
    if (hasError) {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  const retrieveDatasource = () => {
    let data = {};
    if (type === "productAttributes") {
      data = formData[name];
    }
    else if (type === "customerInfo") {
      data = customerInfo[name];
    }
    return data;
  }

  const validateEmailAddress = () => {
    let isValid = true;
    let address;
    let domain;
    let addressConfirm;
    let domainConfirm;
    if (Object.keys(formData).length > 0 && type === "productAttributes" && formData[name]) {
      address = formData[name]?.emailAddress ?? "";
      domain = formData[name]?.emailDomain ?? "";
      addressConfirm = formData[name]?.emailAddressConfirmation ?? "";
      domainConfirm = formData[name]?.emailDomainConfirmation ?? "";
    }
    else if (Object.keys(customerInfo).length > 0 && type === "customerInfo" && customerInfo[name]) {
      address = customerInfo[name]?.emailAddress ?? "";
      domain = customerInfo[name]?.emailDomain ?? "";
      addressConfirm = customerInfo[name]?.emailAddressConfirmation ?? "";
      domainConfirm = customerInfo[name]?.emailDomainConfirmation ?? "";
    }
    const emailAddress = `${address}@${domain}`;
    const confirmEmailAddress = `${addressConfirm}@${domainConfirm}`;

    clearErrors();
    if (!validationFuncs.validateIsRequired(address)) {
      setError(`${name}Address-Required`, {
        type: "manual",
        message: getErrorMessage("E0001", ["メールアドレス"]),
      });
      isValid = false;
    }
    if (!validationFuncs.validateIsRequired(domain)) {
      setError(`${name}Domain-Required`, {
        type: "manual",
        message: getErrorMessage("E0001", ["メールアドレスドメイン"]),
      });
      isValid = false;
    }
    if (!validationFuncs.validateIsRequired(addressConfirm)) {
      setError(`${name}AddressConfirm-Required`, {
        type: "manual",
        message: getErrorMessage("E0001", ["メールアドレス（確認用）"]),
      });
      isValid = false;
    }
    if (!validationFuncs.validateIsRequired(domainConfirm)) {
      setError(`${name}DomainConfirm-Required`, {
        type: "manual",
        message: getErrorMessage("E0001", ["メールアドレスドメイン（確認用）"]),
      });
      isValid = false;
    }
    if (
      !validationFuncs.validateEmailAddress(emailAddress) ||
      !validationFuncs.validateEmailAddress(confirmEmailAddress)
    ) {
      setError(`${name}Address-Format`, {
        type: "manual",
        message: getErrorMessage("E0024", []),
      });
      isValid = false;
    }
    if (emailAddress !== confirmEmailAddress) {
      setError(`${name}Address-Match`, {
        type: "manual",
        message: getErrorMessage("E0031", []),
      });
      isValid = false;
    }
    return isValid;
  }

  const validateAuthCode = (data) => {
    let isValid = true;
    const decryptedAuthCode = decryptAuthCode(authCode);

    clearErrors();
    if (!validationFuncs.validateIsRequired(data?.authCode)) {
      setError(`${name}AuthCode-Required`, {
        type: "manual",
        message: getErrorMessage("E0001", ["確認コード"]),
      });
      isValid = false;
    }
    if (!validationFuncs.validateIsMaxLength(data?.authCode, 4)) {
      setError(`${name}AuthCode-Format`, {
        type: "manual",
        message: getErrorMessage("E0015", ["確認コード", 4]),
      });
      isValid = false;
    }
    if (decryptedAuthCode !== data?.authCode) {
      setError(`${name}AuthCode-UnMatch`, {
        type: "manual",
        message: getErrorMessage("E0028", []),
      });
      isValid = false;
    }
    setIsVerified(isValid);
    const currentTarget = {
      target: {
        name: "isVerified",
        value: isValid
      }
    }
    if (type === "productAttributes") {
      setFormDataEmail(currentTarget, name);
    } else {
      setCustomerInfoEmail(currentTarget, name);
    }
    return isValid;
  }

  const setProductAttributeValue = () => {
    if (Object.keys(formData).length > 0 && type === "productAttributes" && formData[name]) {
      checkEmailConfirmationIsMatched(
        formData[name]?.emailAddress,
        formData[name]?.emailDomain,
        formData[name]?.emailAddressConfirmation,
        formData[name]?.emailDomainConfirmation,
        formData[name]?.authCode,
        formData[name]?.isVerified,
      );
    }
  }

  const setCustomerInfoValue = () => {
    if (Object.keys(customerInfo).length > 0 && type === "customerInfo" && customerInfo[name]) {
      checkEmailConfirmationIsMatched(
        customerInfo[name]?.emailAddress,
        customerInfo[name]?.emailDomain,
        customerInfo[name]?.emailAddressConfirmation,
        customerInfo[name]?.emailDomainConfirmation,
        customerInfo[name]?.authCode,
        customerInfo[name]?.isVerified,
      );
    }
  }

  const clickSendMailButton = (e) => {
    let data = retrieveDatasource();
    let isValidate = validateEmailAddress();
    if (isValidate) {
      let address = data?.emailAddress;
      let domain = data?.emailDomain;

      const newAuthCode = generateAuthCode();
      const decryptedAuthCode = decryptAuthCode(newAuthCode);
      // TEST VALUE
      setSessionStorageItem("SimpleOpAuth", decryptedAuthCode, true);
      setAuthCode(newAuthCode);
      setAuthCodeParam(
        {
          EmailAddress: `${address}@${domain}`,
          AuthCode: newAuthCode,
          ItemId: mailItem,
        }
      );
      setIsMailSeningEventCalled(true);
    }
    moveToErrorMessage(!isValidate);
  }

  const clickVerifyAuthCodeButton = (e) => {
    let data = retrieveDatasource();
    moveToErrorMessage(!validateAuthCode(data));
  }

  const checkEmailConfirmationIsMatched = (
    inputAddress,
    inputDomain,
    inputAddressConfirm,
    inputDomainConfirm,
    inputAuthCode,
    inputIsVerified
  ) => {
    inputValue = [
      { name: "emailAddress", value: inputAddress },
      { name: "emailDomain", value: inputDomain },
      { name: "emailAddressConfirmation", value: inputAddressConfirm },
      { name: "emailDomainConfirmation", value: inputDomainConfirm },
      { name: "authCode", value: inputAuthCode },
      { name: "isVerified", value: inputIsVerified },
    ];
  };

  setProductAttributeValue();
  setCustomerInfoValue();

  useEffect(() => {
    setProductAttributeValue();
    if (Object.keys(formData).length > 0 && type === "productAttributes" && formData[name]) {
      if (formData[name]?.isVerified) {
        setIsMailSent(true);
        setIsVerified(true);
      }
      if (formData[name]?.emailAddress !== formData[name]?.emailAddressConfirmation
        || formData[name]?.emailDomain !== formData[name]?.emailDomainConfirmation
      ) {
        setIsMailSent(false);
        setIsVerified(false);
        delete formData[name].authCode;
        delete formData[name].isVerified;
      }
    }
  
    setCustomerInfoValue();
    if (Object.keys(customerInfo).length > 0 && type === "customerInfo" && customerInfo[name]) {
      if (customerInfo[name]?.isVerified) {
        setIsMailSent(true);
        setIsVerified(true);
      }
      if (customerInfo[name]?.emailAddress !== customerInfo[name]?.emailAddressConfirmation
        || customerInfo[name]?.emailDomain !== customerInfo[name]?.emailDomainConfirmation
      ) {
        setIsMailSent(false);
        setIsVerified(false);
        delete customerInfo[name].authCode;
        delete customerInfo[name].isVerified;
      }
    }
  }, [formData, customerInfo, isMailSent, isVerified])

  useEffect(() => {
    if (sendAuthenticationCodeEmailData) {
      if (sendAuthenticationCodeEmailData?.StatusCode == 200) {
        setIsMailSent(true);
      }
      else {
        clearErrors();
        setError(`SendingMail-Failed`, {
          type: "manual",
          message: sendingMailFailureMsg.value,
        });
        moveToErrorMessage(true);
      }
    }
  }, [sendAuthenticationCodeEmailData]);

  useEffect(() => {
    if (isMailSeningEventCalled) {
      setIsMailSeningEventCalled(false);
      sendAuthenticationCodeEmailRemove();
      sendAuthenticationCodeEmailRefetch();
    }
  }, [isMailSeningEventCalled]);

  useEffect(() => {
    setIsHiddenField(isHidden);
  }, [isHidden]);

  return (
    <React.Fragment>
      {!isHiddenField?.value && (
        <>
          <div className="input-box I-06 simple-op-email">
            <div className={`input-form ${inputFormClass?.value}`}>
              <div className="input-container email mb-20">
                  <label htmlFor="I06-1">
                    <Text field={fillBlank(inputTitleText)} />
                    {requiredText?.value && (
                      <span className="required">
                        <Text field={fillBlank(requiredText)} />
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    id="I06-1"
                    name={inputValue && inputValue[0]?.name}
                    placeholder={`${addressPlaceholder?.value}`}
                    value={inputValue && inputValue[0]?.value}
                    onChange={props?.fields?.onChangeEvent}
                  />
                  <span>@</span>
                  <input
                    type="text"
                    className="medium"
                    name={inputValue && inputValue[1]?.name}
                    placeholder={`${domainPlaceholder?.value}`}
                    value={inputValue && inputValue[1]?.value}
                    onChange={props?.fields?.onChangeEvent}
                  />
                  {descriptionText && descriptionText?.value !== "" && (
                    <span className="ml-0 mt-16">
                      <Text field={fillBlank(descriptionText)} />
                    </span>
                  )}
                </div>
                <div className="input-container email mb-20">
                <label htmlFor="I06-1">
                  <Text field={fillBlank(confirmationTitleText)} />
                  {requiredText?.value && (
                    <span className="required">
                      <Text field={fillBlank(requiredText)} />
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  id="I06-1"
                  name={inputValue && inputValue[2]?.name}
                  placeholder={`${addressPlaceholder?.value}`}
                  value={inputValue && inputValue[2]?.value}
                  onChange={props?.fields?.onChangeEvent}
                />
                <span>@</span>
                <input
                  type="text"
                  className="medium"
                  name={inputValue && inputValue[3]?.name}
                  placeholder={`${domainPlaceholder?.value}`}
                  value={inputValue && inputValue[3]?.value}
                  onChange={props?.fields?.onChangeEvent}
                />
                {descriptionText && descriptionText?.value !== "" && (
                  <span className="ml-0 mt-16">
                    <Text field={fillBlank(descriptionText)} />
                  </span>
                )}
              </div>
              <div className={`btn_wrapper primary mb-20`}>
                <button
                  className={`btn btn_txt btn_default ${isMailSent ? "disabled" : ""}`}
                  type="button"
                  onClick={(e) => handleButtonClick(e, clickSendMailButton)}
                  disabled={isMailSent}
                >
                  <Text field={fillBlank(sendAuthCodeText)} />
                </button>
              </div>
              {isMailSent && (
                <>
                  <div className={`description-content no-left-right-margin mb-20`}>
                    <p>
                      <span>
                        <Text field={fillBlank(authGuideMsg)} />
                      </span>
                    </p>
                  </div>
                  {!isVerified && (
                    <div className="input-container no-left-right-margin mb-20">
                      <label>
                        <Text field={fillBlank(inputAuthCodeLabel)} />
                        {requiredText?.value && (
                          <span className="required">
                            <Text field={fillBlank(requiredText)} />
                          </span>
                        )}
                      </label>
                      <input
                        name={inputValue && inputValue[4]?.name}
                        type="text"
                        placeholder={`${authCodePlaceholder?.value}`}
                        onChange={props?.fields?.onChangeEvent}
                        disabled={!isMailSent || isVerified}
                      />
                    </div>
                  )}

                  <div className={`btn_wrapper primary mb-20`}>
                    <button
                      className={`btn btn_txt btn_default ${!isMailSent || isVerified ? "disabled" : ""}`}
                      type="button"
                      onClick={(e) => handleButtonClick(e, clickVerifyAuthCodeButton)}
                      disabled={!isMailSent || isVerified}
                    >
                      <Text field={fillBlank(isVerified ? completeVerificationText : verificationText)} />
                    </button>
                  </div>
                  {isVerified && (
                  <div className={`description-content no-left-right-margin no-bottom-margin`}>
                    <p>
                      <span>
                        <Text field={fillBlank(authCompleteGuideMsg)} />
                      </span>
                    </p>
                  </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default I06;
