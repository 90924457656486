import React, { useEffect, useState } from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { di_register } from "../../utils/di";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  getProperData,
  redirectToLinkUrl,
} from "../../utils/commonUtils";

import { useCustomQuery } from "../../hooks/useGetData";
import { useSetSelectedItem } from "../../hooks/useSelectedState";
import { validationFuncs } from "../../utils/validations";
import { useFormContext } from "react-hook-form";
import { apiBaseURL } from "../../envSettings";
import { getErrorMessage } from "../../utils/errorMessageHandler";
import { STRING_POST, STRING_OTHER } from "../../utils/constantVariables";
import { parseFieldMetadata } from "../../utils/parseMetadata";

// TODO: Remove Test Data

const ApplicationCancel = (props) => {
  return (
    <>
      <div className="form_wrapper-input">
        <Placeholder
          name="application-cancel-section"
          rendering={props.rendering}
        />
      </div>
      <div className="btn_container">
        <Placeholder name="button-section" rendering={props.rendering} />
      </div>
    </>
  );
};

const ApplicationCancelPageLayout = (props) => {
  // @Model
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);
  const { setError, clearErrors } = useFormContext();

  // get the data
  let orderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );

  const personalizeInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );

  const applicationNumber = getSessionStorageItem(
    sessionKeys.SSN_REPORT_APPLICATIONLIST_ORDERRECEIPTNUMBER,
    true
  );

  const applicationDateData = getSessionStorageItem(
    sessionKeys.SSN_REPORT_APPLICATIONLIST_EFFECTIVEDATE,
    true
  );

  const planNameData = getSessionStorageItem(
    sessionKeys.SSN_REPORT_APPLICATIONLIST_PLANNAME,
    true
  );

  const reportApplicationListOrderStatus = getSessionStorageItem(
    sessionKeys.SSN_REPORT_APPLICATIONLIST_ORDERSTATUS,
    true
  );

  const reportApplicationListAddress = getSessionStorageItem(
    sessionKeys.SSN_REPORT_APPLICATIONLIST_ADDRESS,
    true
  );

  const order = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order;
  const sessionCancelReason =
    order?.cMAPCancelReason__c || order?.CMAP_CancelReason__c;
  const sessionCancelReasonOther =
    order?.cMAPCancelReasonOther__c || order?.CMAP_CancelReasonOther__c;

  const { data: metadata, isLoading: isMetatadataLoading } = useCustomQuery(
    `${apiBaseURL}order/RetrieveMetadataDefinitions`,
    true,
    STRING_POST,
    {
      objectNames: ["Order"],
      orderChannelCategory: personalizeInfo.AppChannelDivision,
    }
  );

  props.setIsLoading(isMetatadataLoading);

  const [otherCancelReason, setOtherCancelReason] = useState("");
  const [disableOtherReasonInput, setDisableOtherReasonInput] = useState(false);

  const selectContract = getScDataFromPlaceholder(
    "application-cancel-section",
    props
  );
  const otherReasonInput = getScDataFromComponent("I-04", selectContract);
  const numberOfLetters = parseFieldMetadata(
    metadata?.metadataDefinitions?.Order?.fieldMetadataDefinitions
      ?.CMAP_CancelReasonOther__c
  )?.numberOfLetters;
  otherReasonInput[0].fields.maxLength = numberOfLetters;
  otherReasonInput[0].fields.disableField = disableOtherReasonInput;
  otherReasonInput[0].fields.initialValue = otherCancelReason;
  otherReasonInput[0].fields.onBlurEvent = (e) => {
    let currentInputValue = e.target.value;
    setOtherCancelReason(currentInputValue);
  };

  const [c05Data] = getScDataFromComponent("C-05", selectContract);
  const [s01Data] = getScDataFromComponent("S-01", selectContract);

  const [reasonOptions, setReasonOptions] = useState([]);
  const selectReasonOfCancellationCategory = "selectedReasonOfCancellation";

  s01Data.fields["category"] = { value: selectReasonOfCancellationCategory };
  s01Data.fields["selections"] = reasonOptions;
  
  const [
    selectedReasonOfCancellation,
    setSelectedReasonOfCancellation,
  ] = useSetSelectedItem(selectReasonOfCancellationCategory);

  const handleNextBtn = async (
    selectedReasonOfCancellation,
    otherCancelReason
  ) => {
    clearErrors(["otherCancelReason", "selectedReasonOfCancellation"]);
    let isValidate = true;
    if (!validationFuncs.validateIsRequired(selectedReasonOfCancellation)) {
      setError("selectedReasonOfCancellation", {
        type: "manual",
        message: getErrorMessage("E0002", ["キャンセル理由ラジオボタン"]),
      });
      isValidate = false;
    }
    if (
      selectedReasonOfCancellation === STRING_OTHER &&
      !validationFuncs.validateIsRequired(otherCancelReason)
    ) {
      setError("otherCancelReason", {
        type: "manual",
        message: getErrorMessage("E0001", ["キャンセル理由テキストエリア"]),
      });
      isValidate = false;
    }
    if (isValidate) {
      orderRelatedInfo = {
        ...orderRelatedInfo,
        orderRelatedInfo: {
          ...orderRelatedInfo?.orderRelatedInfo,
          orderInfo: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
            order: {
              ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
              CMAP_CancelReason__c: selectedReasonOfCancellation,
              CMAP_CancelReasonOther__c: otherCancelReason,
            },
          },
        },
      };
      setSessionStorageItem(
        sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
        orderRelatedInfo,
        true
      );

      const reasonLabel = reasonOptions.find(
        (option) => option?.Code === selectedReasonOfCancellation
      )?.Value;
      setSessionStorageItem(
        sessionKeys.SSN_REPORT_APPLICATIONCANCEL_REASONOFCANCELLATIONLABEL,
        reasonLabel,
        true
      );

      const { linkURL } = getProperData(
        props.sitecoreContext,
        nextButton[0]?.fields
      );
      redirectToLinkUrl(linkURL);
    }
  };

  const selectButtons = getScDataFromPlaceholder("button-section", props);
  const nextButton = getScDataFromComponent("B-01", selectButtons);
  const backButton = getScDataFromComponent("B-02", selectButtons);

  nextButton[0].fields.onClickEvent = (e) => {
    handleNextBtn(selectedReasonOfCancellation?.selected, otherCancelReason);
  };
  backButton[0].fields.onClickEvent = (e) => {
    const pageContext = props.sitecoreContext.route.fields;
    redirectToLinkUrl(pageContext["ApplicationCancel-BackButtonLink"]);
  };

  // Store the following values in sessionStorage
  useEffect(() => {
    orderRelatedInfo = {
      ...orderRelatedInfo,
      orderRelatedInfo: {
        ...orderRelatedInfo?.orderRelatedInfo,
        orderInfo: {
          ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
          order: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
            CMAP_OrderChannelDetail__c: "Web",
            CMAP_CancelOrderChannelDetail__c: "Web",
            CMAP_OrderCategory__c: "Cancel",
          },
          orderAddition: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition,
            orderCategoryCurrentlySelected: "Cancel",
          },
        },
      },
    };

    setSessionStorageItem(
      sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
      orderRelatedInfo,
      true
    );
  }, []);

  // Receive RetrieveMetadataDefinitions data
  useEffect(() => {
    const multilistItems = c05Data?.fields?.MultilistItems;
  
    c05Data.fields["titleNumber"] = applicationNumber;
  
    // 申込日
    let applicationDate = multilistItems[0].fields;
    applicationDate["explanationText"] = applicationDateData;
  
    // 申込状況
    let applicationStatus = multilistItems[1].fields;
    applicationStatus["explanationText"] = reportApplicationListOrderStatus;
  
    // ご利用住所ラベル
    let address = multilistItems[2].fields;
    address["explanationText"] = reportApplicationListAddress;
  
    // プランラベル
    let planName = multilistItems[3].fields;
    planName["explanationText"] = planNameData;
  
  
    // set radios options
    const reasonOptionsMain = parseFieldMetadata(
      metadata?.metadataDefinitions?.Order?.fieldMetadataDefinitions
        ?.CMAP_CancelReason__c
    );
  
    const reasonOptionsData = reasonOptionsMain?.options?.map((child) => ({
      Code: child.value,
      Value: child.label,
    }));
    setReasonOptions(reasonOptionsData);


    // set value from SS
    // If the input value of each item is stored in the session, restore the input state.
    if (sessionCancelReasonOther && sessionCancelReason === STRING_OTHER) { // otherReason + otherReason value
      setOtherCancelReason(sessionCancelReasonOther);
      setSelectedReasonOfCancellation({ selectedReasonOfCancellation: sessionCancelReason });
    } else if (sessionCancelReason === STRING_OTHER) { // otherReason
      setSelectedReasonOfCancellation({ selectedReasonOfCancellation: STRING_OTHER }); 
    } else if (sessionCancelReason) { // other than otherReason
      setSelectedReasonOfCancellation({ selectedReasonOfCancellation: sessionCancelReason });
    } else { // no value
      setOtherCancelReason("");
    }
  }, [metadata]);

  useEffect(() => {
    // disable reason for other cases if selected reason for cancellation is other than その他(Other)
    if (
      selectedReasonOfCancellation?.selected !== STRING_OTHER ||
      selectedReasonOfCancellation?.selected === ""
    ) {
      setDisableOtherReasonInput(true);
      setOtherCancelReason("");
    } else {
      setDisableOtherReasonInput(false);
    }
  }, [selectedReasonOfCancellation]);

  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <ApplicationCancel rendering={props.rendering} />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(ApplicationCancelPageLayout);
