import React, { useEffect, useContext, useState } from "react";
import { Placeholder, RichText } from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import {
    getPlaceholderFromComponent,
    getScDataFromPlaceholder,
    getScDataFromComponent,
    redirectToLinkUrl,
    UrlParams,
  } from "../../utils/commonUtils";
import { apiResultCode } from "../../assets/consts";
import {
    STRING_POST,
    STRING_GET,
    STRING_REQUEST_WEB
} from "../../utils/constantVariables";
import { getSessionStorageItem, setSessionStorageItem } from "../../utils/useSessionStorage";
import { apiBaseURL } from "../../envSettings";
import { useFormContext } from "react-hook-form";
import {
    useCustomQuery,
} from "../../hooks/useGetData";
import ModalContext from "../../assets/context/ModalContext";

export function ImportantMatterConfirmation(props) { 
	const {
    sitecoreContext,
    orderRelatedInfo,
    isCpad,
    orderChannelCategory,
    retrieveSystemDateTime,
    retrieveSystemDateTimeRefresh
  } = props;

	const pageContext = sitecoreContext.route.fields;
  const { systemDateTime, effectiveDate, effectiveTime } = retrieveSystemDateTime;

	const personalizeInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
	);
	const { setError, clearErrors } = useFormContext();
	const { dispatch } = useContext(ModalContext); 
	const { state } = useContext(ModalContext);
	const { imgPath } = state;
	const [edionRequestData, setEdionRequestData] = useState(null);
	const [registerOrderRequest, setRegisterOrderRequest] = useState(null);
	const [isImportantInformationSectionHidden, setIsImportantInformationSectionHidden] = useState(false);

	const checkOrderEndpoint = `${apiBaseURL}order/CheckOrder`;
	const registerOrderEndpoint = `${apiBaseURL}order/RegisterOrder`;
	const importantMatterDescription = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderImportantMatterDescriptions;

  let importantMatterDescriptionIds;
	if (importantMatterDescription && importantMatterDescription?.length > 0) {
		const ids = importantMatterDescription.map(
      ({ CMAP_ImportantMatterDescription__c }, i) =>
      CMAP_ImportantMatterDescription__c
		);
		importantMatterDescriptionIds = [...new Set(ids)];
	}

	let retrieveImportantMattersDescriptionEndpoint;
	if ( importantMatterDescriptionIds && importantMatterDescriptionIds.length > 0) {
		const params = UrlParams(
			importantMatterDescriptionIds,
			"importantMatterDescriptionIds"
		);
		retrieveImportantMattersDescriptionEndpoint = `${apiBaseURL}order/RetrieveImportantMatterDescriptionInfoList?${params}`;
	}

	const {
		data: RetrieveImportantMattersDescriptionDataResponse,
		isLoading: RetrieveImportantMattersDescriptionDataLoading,
		error: RetrieveImportantMattersDescriptionDataError,
		refetch: RetrieveImportantMattersDescriptionDataRefresh,
		remove: RetrieveImportantMattersDescriptionDataRemove,
	} = useCustomQuery(
		retrieveImportantMattersDescriptionEndpoint,
		true,
		STRING_GET
	);

  const createCheckOrderApiRequest = (orderRelatedInfo) => {
    if (!orderRelatedInfo) return {};

    const billingTo = orderRelatedInfo?.orderRelatedInfo?.billingTo;
    const { account, accountAddition } =
      orderRelatedInfo?.orderRelatedInfo?.accountInfo ?? {};
    const {
      order,
      orderAddition,
      orderImportantMatterDescriptions,
      orderItemInfos,
    } = orderRelatedInfo?.orderRelatedInfo?.orderInfo ?? {};

		const orderAccountId = order?.AccountId || account?.Id;

    const result = {
      orderRelatedInfo: {
        accountInfo: {
          account: {
            Id: account?.Id,
            CMAP_ContractName__c: account?.CMAP_ContractName__c,
            LastName: account?.LastName,
						FirstName: account?.FirstName,
						LastNameKana__pc: account?.LastNameKana__pc,
						FirstNameKana__pc: account?.FirstNameKana__pc,
						CMAP_Gender__c: account?.CMAP_Gender__c,
						PersonBirthdate: account?.PersonBirthdate,
						CMAP_CorporateGroupName__c: account?.CMAP_CorporateGroupName__c,
						CMAP_DepartmentName__c: account?.CMAP_DepartmentName__c,
						CMAP_ContactPersonFamilyName__c:
							account?.CMAP_ContactPersonFamilyName__c,
						CMAP_ContactPersonName__c: account?.CMAP_ContactPersonName__c,
						CMAP_ContactPersonLastNameKana__pc:
							account?.CMAP_ContactPersonLastNameKana__pc,
						CMAP_ContactPersonFirstNameKana__pc:
							account?.CMAP_ContactPersonFirstNameKana__pc,
            CMAP_ContactPostalCode__c: account?.CMAP_ContactPostalCode__c,
            CMAP_ContactPostalCodeLowerFour__c:
              account?.CMAP_ContactPostalCodeLowerFour__c,
            CMAP_ContactState__c: account?.CMAP_ContactState__c,
            CMAP_ContactCity__c: account?.CMAP_ContactCity__c,
            CMAP_ContactHouseNumber__c: account?.CMAP_ContactHouseNumber__c,
            CMAP_MansionName__c: account?.CMAP_MansionName__c,
            CMAP_RoomNo__c: account?.CMAP_RoomNo__c,
            CMAP_AreaCode__c: account?.CMAP_AreaCode__c,
            CMAP_LocalTelephoneNumber__c: account?.CMAP_LocalTelephoneNumber__c,
            CMAP_SubscriberNumber__c: account?.CMAP_SubscriberNumber__c,
            CMAP_MobileNumber1__c: account?.CMAP_MobileNumber1__c,
            CMAP_MobileNumber2__c: account?.CMAP_MobileNumber2__c,
            CMAP_MobileNumber3__c: account?.CMAP_MobileNumber3__c,
            CMAP_MyCommufaID__c: account?.CMAP_MyCommufaID__c,
            CMAP_MultilingualReexplainCenter__c:
              account?.CMAP_MultilingualReexplainCenter__c,
          },
          accountAddition: {
            isNewAccount: accountAddition?.isNewAccount,
            leadId: accountAddition?.leadId,
          },
        },
        orderInfo: {
          order: {
            CMAP_OrderCategory__c: order?.CMAP_OrderCategory__c,
            CMAP_OrderBeforeChange__c: order?.CMAP_OrderBeforeChange__c,
            EffectiveDate: effectiveDate,
            CMAP_OrderChannelDetail__c: STRING_REQUEST_WEB,
            AccountId: orderAccountId,
            CMAP_ResidenceType__c: order?.CMAP_ResidenceType__c,
            CMAP_BuildingCategory__c: order?.CMAP_BuildingCategory__c,
            CMAP_OfferPlan__c: order?.CMAP_OfferPlan__c,
            CMAP_OfferPlanName__c: order?.CMAP_OfferPlanName__c,
            CMAP_OfferPackageId__c: order?.CMAP_OfferPackageId__c,
            CMAP_IsTypeCircuit__c: order?.CMAP_IsTypeCircuit__c,
            CMAP_IsTypeCircuitTypeJ__c: order?.CMAP_IsTypeCircuitTypeJ__c,
            CMAP_IsTypeNotCircuit__c: order?.CMAP_IsTypeNotCircuit__c,
            CMAP_IsTypeGoodsServiceSales__c:
              order?.CMAP_IsTypeGoodsServiceSales__c,
            CMAP_IsTypeCircuitWireless__c: order?.CMAP_IsTypeCircuitWireless__c,
            CMAP_InstallationLocationOfferArea__c:
              order?.CMAP_InstallationLocationOfferArea__c,
            CMAP_InstallLocLayerDeliveryDuration__c:
              order?.CMAP_InstallLocLayerDeliveryDuration__c,
            CMAP_StandardOpeningDays__c: order?.CMAP_StandardOpeningDays__c,
            CMAP_StandardOpeningMonths__c: order?.CMAP_StandardOpeningMonths__c,
            CMAP_ApartmentPropertyName__c: order?.CMAP_ApartmentPropertyName__c,
            CMAP_PropertyAddress__c: order?.CMAP_PropertyAddress__c,
            CMAP_PropertyAddressCode11__c: order?.CMAP_PropertyAddressCode11__c,
            CMAP_PropertyCity__c: order?.CMAP_PropertyCity__c,
            CMAP_PropertyOfferMethod__c: order?.CMAP_PropertyOfferMethod__c,
            CMAP_Case__c: order?.CMAP_Case__c,
            CMAP_Billing__c: order?.CMAP_Billing__c,
            CMAP_BillingPaymentMethodAgent__c:
              order?.CMAP_BillingPaymentMethodAgent__c,
            CMAP_BillingPaymentMethodRegistered__c:
              order?.CMAP_BillingPaymentMethodRegistered__c,
            CMAP_NeedsSendingDocumentOfBilling__c:
              order?.CMAP_NeedsSendingDocumentOfBilling__c,
            CMAP_AcquisitionAgency__c: order?.CMAP_AcquisitionAgency__c,
            CMAP_AcquisitionShop__c: order?.CMAP_AcquisitionShop__c,
            CMAP_IndividualContractPlan__c:
              order?.CMAP_IndividualContractPlan__c,
            CMAP_CancelDate__c: order?.CMAP_CancelDate__c,
            CMAP_CancelReason__c: order?.CMAP_CancelReason__c,
            CMAP_CancelReasonOther__c: order?.CMAP_CancelReasonOther__c,
            CMAP_StartRequestDate__c: order?.CMAP_StartRequestDate__c,
            CMAP_TerminationReason__c: order?.CMAP_TerminationReason__c,
            CMAP_TerminationReasonOther__c:
              order?.CMAP_TerminationReasonOther__c,
            CMAP_SuspendRequestDate__c: order?.CMAP_SuspendRequestDate__c,
            CMAP_SuspendResumeProspectDate__c:
              order?.CMAP_SuspendResumeProspectDate__c,
            CMAP_ResumeRequestDate__c: order?.CMAP_ResumeRequestDate__c,
            CMAP_UpdateAddRequestDate__c: order?.CMAP_UpdateAddRequestDate__c,
            CMAP_ProductChangeAddRequestDate__c:
              order?.CMAP_ProductChangeAddRequestDate__c,
            CMAP_UpdateLastUtilizationDate__c:
              order?.CMAP_UpdateLastUtilizationDate__c,
            CMAP_ProductChangeLastUtilizationDate__c:
              order?.CMAP_ProductChangeLastUtilizationDate__c,
            CMAP_LastUtilizationDate__c: order?.CMAP_LastUtilizationDate__c,
            CMAP_PlanChangeRequestDate__c: order?.CMAP_PlanChangeRequestDate__c,
            CMAP_ChangeSourceLastUtilizationDate__c:
              order?.CMAP_ChangeSourceLastUtilizationDate__c,
            ShippingStreet: order?.ShippingStreet,
            ShippingCity: order?.ShippingCity,
            ShippingState: order?.ShippingState,
            ShippingPostalCode: order?.ShippingPostalCode,
            ShippingLatitude: order?.ShippingLatitude,
            ShippingLongitude: order?.ShippingLongitude,
            CMAP_InstallationLocationAddressCode11__c:
              order?.CMAP_InstallationLocationAddressCode11__c,
            CMAP_InstallationLocationAddressCode__c:
              order?.CMAP_InstallationLocationAddressCode__c,
            CMAP_InstallationLocationCity__c:
              order?.CMAP_InstallationLocationCity__c,
            CMAP_InstallationLocationHouseNumber__c:
              order?.CMAP_InstallationLocationHouseNumber__c,
            CMAP_InstallationLocationRoomNumber__c:
              order?.CMAP_InstallationLocationRoomNumber__c,
            CMAP_EffectiveTime__c: effectiveTime,
            CMAP_ClientId__c: order?.CMAP_ClientId__c,
            CMAP_DeliveryServiceAddress__PostalCode__s:
              order?.CMAP_DeliveryServiceAddress__PostalCode__s,
            CMAP_DeliveryServiceAddress__StateCode__s:
              order?.CMAP_DeliveryServiceAddress__StateCode__s,
            CMAP_DeliveryServiceAddress__City__s:
              order?.CMAP_DeliveryServiceAddress__City__s,
            CMAP_DeliveryServiceAddress__Street__s:
              order?.CMAP_DeliveryServiceAddress__Street__s,
            CMAP_DeliveryServiceAddressBuildingName__c:
              order?.CMAP_DeliveryServiceAddressBuildingName__c,
            CMAP_DeliveryServiceAddressRoomNumber__c:
              order?.CMAP_DeliveryServiceAddressRoomNumber__c,
            CMAP_CampaignBenefitAddition__c:
              order?.CMAP_CampaignBenefitAddition__c,
            CMAP_ChangeSourceOfferPlan__c: order?.CMAP_ChangeSourceOfferPlan__c,
            CMAP_ChangeSourceOpportunity__c:
              order?.CMAP_ChangeSourceOpportunity__c,
            CMAP_ChangeSourceIndividualContractPur__c:
              order?.CMAP_ChangeSourceIndividualContractPur__c,
            CMAP_ChangeSourceOfferPlanName__c:
              order?.CMAP_ChangeSourceOfferPlanName__c,
            CMAP_ChangeSourceTypeCircuit__c:
              order?.CMAP_ChangeSourceTypeCircuit__c,
            CMAP_ChangeSourceTypeCircuitWireless__c:
              order?.CMAP_ChangeSourceTypeCircuitWireless__c,
            CMAP_ChangeSourceTypeCircuitTypeJ__c:
              order?.CMAP_ChangeSourceTypeCircuitTypeJ__c,
            CMAP_ChangeSourceTypeNotCircuit__c:
              order?.CMAP_ChangeSourceTypeNotCircuit__c,
            CMAP_ChangeSourceCase__c: order?.CMAP_ChangeSourceCase__c,
            CMAP_ChangeSourceResidenceType__c:
              order?.CMAP_ChangeSourceResidenceType__c,
            CMAP_ChangeSourceBuildingCategory__c:
              order?.CMAP_ChangeSourceBuildingCategory__c,
            CMAP_ChangeSourceInstallationLocAddr__c:
              order?.CMAP_ChangeSourceInstallationLocAddr__c,
            CMAP_ChangeSourceDeliverySrvAddrForDisp__c:
              order?.CMAP_ChangeSourceDeliverySrvAddrForDisp__c,
            CMAP_CancelOrderChannelDetail__c:
              order?.CMAP_CancelOrderChannelDetail__c,
            CMAP_Rebuilding__c: order?.CMAP_Rebuilding__c,
            CMAP_IndividualContractComplementary__c:
              order?.CMAP_IndividualContractComplementary__c,
            CMAP_PersonalInformationConsentDate__c:
              order?.CMAP_PersonalInformationConsentDate__c,
            CMAP_ContractDocDeliveryCategory__c:
              order?.CMAP_ContractDocDeliveryCategory__c,
            CMAP_StandardProductOffer__c: order?.CMAP_StandardProductOffer__c,
            CMAP_MovementStandardProductOffer__c:
              order?.CMAP_MovementStandardProductOffer__c,
            CMAP_ConsentFamilyover65__c: order?.CMAP_ConsentFamilyover65__c,
            CMAP_TelePreferredCommunicationDay__c:
              order?.CMAP_TelePreferredCommunicationDay__c,
            CMAP_TelePreferredCommunicationTime__c:
              order?.CMAP_TelePreferredCommunicationTime__c,
            CMAP_RequestNotToSend__c: order?.CMAP_RequestNotToSend__c,
            CMAP_SupportedLanguage__c: order?.CMAP_SupportedLanguage__c,
            CMAP_PreferredCommunicationMethod__c:
              order?.CMAP_PreferredCommunicationMethod__c,
            CMAP_HearingImpairmentFlag__c: order?.CMAP_HearingImpairmentFlag__c,
            CMAP_TranslatorName__c: order?.CMAP_TranslatorName__c,
            CMAP_TranslatorKana__c: order?.CMAP_TranslatorKana__c,
            CMAP_HopeForConsulting1__c: order?.CMAP_HopeForConsulting1__c,
            CMAP_HopeForConsulting1Other__c:
              order?.CMAP_HopeForConsulting1Other__c,
            CMAP_HopeForConsulting2__c: order?.CMAP_HopeForConsulting2__c,
            CMAP_HopeForConsulting2Other__c:
              order?.CMAP_HopeForConsulting2Other__c,
            CMAP_RadioSurvey__c: order?.CMAP_RadioSurvey__c,
            CMAP_SeniorCitizen__c: order?.CMAP_SeniorCitizen__c,
            CMAP_RequestInvestigationTVanotherDay__c:
              order?.CMAP_RequestInvestigationTVanotherDay__c,
            CMAP_ManagementCompanyConsent__c:
              order?.CMAP_ManagementCompanyConsent__c,
            CMAP_StoreId__c: order?.CMAP_StoreId__c,
            CMAP_OtherCustomerRequest__c: order?.CMAP_OtherCustomerRequest__c,
            CMAP_StoreBoothCode__c: order?.CMAP_StoreBoothCode__c,
          },
          orderAddition: {
            orderCategoryCurrentlySelected:
              orderAddition?.orderCategoryCurrentlySelected,
            orderCategoryPreviouslySelected:
              orderAddition?.orderCategoryPreviouslySelected,
            orderChannelCategory: orderChannelCategory,
            previousLastModifiedDate: orderAddition?.previousLastModifiedDate,
            electronicSignature: "",
          },
          orderImportantMatterDescriptions: orderImportantMatterDescriptions?.map(
            (desc) => ({
              CMAP_ImportantMatterDescription__c:
                desc?.CMAP_ImportantMatterDescription__c,
              CMAP_ProductGroup__c: desc?.CMAP_ProductGroup__c,
              CMAP_SetParentProduct__c: desc?.CMAP_SetParentProduct__c,
              CMAP_ConsentDateTime__c: "",
              CMAP_ImportantMatterDescriptionConsent__c:
                desc?.CMAP_ImportantMatterDescriptionConsent__c,
            })
          ),
          orderItemInfos: orderItemInfos?.map((item) => ({
            orderItem: {
              CMAP_OfferPlanProduct__c:
                item?.orderItem?.CMAP_OfferPlanProduct__c,
              Product2Id: item?.orderItem?.Product2Id,
              UnitPrice: item?.orderItem?.UnitPrice,
              CMAP_ComsumptionTaxRate__c:
                item?.orderItem?.CMAP_ComsumptionTaxRate__c,
              Quantity: item?.orderItem?.Quantity,
              CMAP_PriceCategory__c: item?.orderItem?.CMAP_PriceCategory__c,
              CMAP_InstallmentPaymentMonthly__c:
                item?.orderItem?.CMAP_InstallmentPaymentMonthly__c,
              CMAP_InstallmentPayMonthlyPriceTaxExc__c:
                item?.orderItem?.CMAP_InstallmentPayMonthlyPriceTaxExc__c,
              CMAP_NumberOfInstallments__c:
                item?.orderItem?.CMAP_NumberOfInstallments__c,
              CMAP_InstallmentPayFirstMonthPriceTaxExc__c:
                item?.orderItem?.CMAP_InstallmentPayFirstMonthPriceTaxExc__c,
              CMAP_SelectionAnnualPayment__c:
                item?.orderItem?.CMAP_SelectionAnnualPayment__c,
              CMAP_AnnualPaymentPriceTaxExcluded__c:
                item?.orderItem?.CMAP_AnnualPaymentPriceTaxExcluded__c,
              CMAP_Unchargeable__c: item?.orderItem?.CMAP_Unchargeable__c,
              CMAP_Asset__c: item?.orderItem?.CMAP_Asset__c,
              CMAP_ProductSelectionState__c:
                item?.orderItem?.CMAP_ProductSelectionState__c,
              CMAP_LastTimeChangeCategory__c:
                item?.orderItem?.CMAP_LastTimeChangeCategory__c,
              CMAP_HasOrderItemAttribute__c:
                item?.orderItem?.CMAP_HasOrderItemAttribute__c,
              CMAP_AvailableDailyPricing__c:
                item?.orderItem?.CMAP_AvailableDailyPricing__c,
              CMAP_SetProduct__c: item?.orderItem?.CMAP_SetProduct__c,
              CMAP_BenefitProduct__c: item?.orderItem?.CMAP_BenefitProduct__c,
              CMAP_HasPriceChanged__c: item?.orderItem?.CMAP_HasPriceChanged__c,
              CMAP_OrderAutomaticSelectionProduct__c:
                item?.orderItem?.CMAP_OrderAutomaticSelectionProduct__c,
              CMAP_TaxationCategory__c:
                item?.orderItem?.CMAP_TaxationCategory__c,
              CMAP_installmentFlag__c: item?.orderItem?.CMAP_installmentFlag__c,
              CMAP_ChangeSourceDestinationCategory__c:
                item?.orderItem?.CMAP_ChangeSourceDestinationCategory__c,
              CMAP_InheritsMovementAssetAttribute__c:
                item?.orderItem?.CMAP_InheritsMovementAssetAttribute__c,
              CMAP_ChangeSourceOrderItem__c:
                item?.orderItem?.CMAP_ChangeSourceOrderItem__c,
              CMAP_OrderItemBeforeChange__c:
                item?.orderItem?.CMAP_OrderItemBeforeChange__c,
              CMAP_QuantityValueChange__c:
                item?.orderItem?.CMAP_QuantityValueChange__c,
              CMAP_PredecessorOfferStartRequest__c:
                item?.orderItem?.CMAP_PredecessorOfferStartRequest__c,
              CMAP_ExemptionPeriod__c: item?.orderItem?.CMAP_ExemptionPeriod__c,
            },
            orderItemAddition: {
              previousLastModifiedDate:
                item?.orderItemAddition?.previousLastModifiedDate,
            },
            orderItemAttributes: item?.orderItemAttributes?.map((attr) => ({
              CMAP_OrderItemAttributeDefenition__c:
                attr?.CMAP_OrderItemAttributeDefenition__c,
              CMAP_Value__c: attr?.CMAP_Value__c,
              CMAP_ValueLongTextArea__c: attr?.CMAP_ValueLongTextArea__c,
              CMAP_ValueChanged__c: attr?.CMAP_ValueChanged__c,
              CMAP_AssetAttribute__c: attr?.CMAP_AssetAttribute__c,
            })),
          })),
        },
        billingTo: {
          CMAP_PaymentMethod__c: billingTo?.CMAP_PaymentMethod__c,
          CMAP_CreditCardNumberFirstFourDigits__c:
            billingTo?.CMAP_CreditCardNumberFirstFourDigits__c,
          CMAP_CreditCardNumberLastFourDigits__c:
            billingTo?.CMAP_CreditCardNumberLastFourDigits__c,
          CMAP_YamaguchiInfoId__c: billingTo?.CMAP_YamaguchiInfoId__c,
          CMAP_CreditCardName__c: billingTo?.CMAP_CreditCardName__c,
          CMAP_CreditCardExpirationDate__c:
            billingTo?.CMAP_CreditCardExpirationDate__c,
          CMAP_CreditCardBankRelationship__c:
            billingTo?.CMAP_CreditCardBankRelationship__c,
          CMAP_CardCompany__c: billingTo?.CMAP_CardCompany__c,
          CMAP_IndividualCorporationKbn__c:
            billingTo?.CMAP_IndividualCorporationKbn__c,
          CMAP_PaymentRegistrationMethod__c:
            billingTo?.CMAP_PaymentRegistrationMethod__c,
          CMAP_AccountTransferBillingNumber__c:
            billingTo?.CMAP_AccountTransferBillingNumber__c,
        },
      },
    };

    return result;
  };

	const checkOrderDataRequest = createCheckOrderApiRequest(orderRelatedInfo);

	const {
		data: checkOrderDataResponse,
		isLoading: checkOrderDataLoading,
		error: checkOrderDataError,
		refetch: checkOrderDataRefresh,
		remove: checkOrderDataRemove,
	} = useCustomQuery(checkOrderEndpoint, false, STRING_POST, checkOrderDataRequest);


	//TODO: Uncomment lines below once Edion functionality has been fixed
	// const edionUri = pageContext["ImportantMatterConfirmation-ApplyButtonLinkForEdion"]?.value?.url;
	// const {
	//     data: EdionResponse,
	//     isLoading: EdionIsLoading,
	//     error: EdionError,
	//     refetch: EdionRefresh,
	//     remove: EdionRemove,
	// } = useCustomQuery(
	//     edionUri,
	//     false,
	//     STRING_POST,
	//     edionRequestData,
	//     {},
	//     3,
	//     false
	// );
	// useEffect(() => {
	//     if (edionRequestData !== null) {
	//         EdionRefresh();
	//     }
	// }, [edionRequestData]);

	// useEffect(() => {
	//     EdionResponse && redirectToLinkUrl(buttonLink);
	// }, [EdionResponse]);
    
	const {
		data: registerOrderDataResponse,
		isLoading: registerOrderDataLoading,
		error: registerOrderDataError,
		refetch: registerOrderDataRefresh,
		remove: registerOrderDataRemove,
	} = useCustomQuery(
		registerOrderEndpoint,
		false,
		STRING_POST,
		registerOrderRequest
	);

  // receive the first systemDateTime call
	useEffect(() => {
		if (retrieveSystemDateTime.isFirstCall) {
			checkOrderDataRefresh();
		}
	}, [retrieveSystemDateTime])

	useEffect(() => {
		if (checkOrderDataResponse !== undefined || checkOrderDataResponse) {
			const processResult = checkOrderDataResponse.processResult;
			if (processResult?.returnCode === apiResultCode.error) {
				setIsImportantInformationSectionHidden(true);

				processResult.resultInfos.forEach((err, index) => {
					if (err.resultCategory === apiResultCode.error) {
						setError(err.resultCode + index, {
							type: "manual",
							message: err.resultMessage,
						});
					}
				});
			}

			if (processResult?.returnCode === apiResultCode.alert) {
				const cancelDetailSection = getScDataFromPlaceholder(
					"warning-modal-section",
					props
				);
				const [popup] = getScDataFromComponent("P0-01", cancelDetailSection);
				popup.fields.isCustom = true;

				const modalSection = getScDataFromPlaceholder("modal-section", popup);
				const [warningModal] = getScDataFromComponent(
					"Warning-Message-Modal-Layout",
					modalSection
				);
				warningModal.fields = {
					descriptionText: pageContext["WarningMessageModal-Description"],
					closeText: pageContext["WarningMessageModal-CloseButtonText"],
					confirmText: pageContext["WarningMessageModal-ApplyButtonText"],
					warningMessageList: processResult.resultInfos.reduce((acc, err) => {
						if (err.resultCategory === apiResultCode.alert) {
							acc.push({
								code: err.resultCode,
								message: err.resultMessage,
							});
						}
						return acc;
					}, []),
					onConfirmClick: () => {
						checkOrderDataRefresh();
					},
					onCancelClick: () => {
            setIsImportantInformationSectionHidden(true);
            return false;
          },
				};
				dispatch({
					type: "SHOW_MODAL",
					payload: { id: popup?.fields?.modalTarget?.value },
				});
			}
		}
	}, [checkOrderDataResponse]);

	useEffect(() => {
		const importantInformationSection = getScDataFromPlaceholder("important-information-section",props);
		let c03Data = getScDataFromComponent("C-03", importantInformationSection)[0];
		c03Data.fields.buttonList = [{
			Name:  pageContext["ImportantMatterConfirmation-ImportantMatterTitle"]?.value,
		}];

		const importantMatterDescriptionInfos = RetrieveImportantMattersDescriptionDataResponse?.importantMatterDescriptionInfos;
		const [m01Data] = getScDataFromComponent("M0-01",importantInformationSection);
		if (importantMatterDescriptionInfos && importantMatterDescriptionInfos.length > 0) {
			const modalSection = getScDataFromPlaceholder("modal-section", m01Data);
			const modalLayout = getScDataFromComponent("Important-Matters-Description-Modal-Layout",modalSection);
			modalLayout[0].fields = {
				importantMatterDescriptionList: importantMatterDescriptionInfos
			};
		}
	}, [RetrieveImportantMattersDescriptionDataResponse])

	useEffect(() => {
		if (isCpad) {
			const pageContext = props.sitecoreContext.route.fields;
			const signatureSection = getScDataFromPlaceholder("signature-section",props);
			const signatureInputSection = getScDataFromPlaceholder("button-fifth-section", props);
			let signatureD01Data = getScDataFromComponent("D-01", signatureSection)[0];
			let signatureH02Data = getScDataFromComponent("H-02",signatureSection)[0];
			let signatureInputM01Data = getScDataFromComponent("M0-01",signatureInputSection)[0];
			let signatureInputBM02Data = getScDataFromComponent("BM-02",signatureInputSection)[0];
			let signatureModal = getPlaceholderFromComponent("modal-section", signatureInputM01Data);
			let ps01Data = getScDataFromComponent("PS-01", signatureModal)[0];
			let h04Data = getScDataFromComponent("H-04", signatureModal)[0];
			signatureH02Data.fields.titleText = pageContext["SignatureInput-Title"]?.value;
			signatureD01Data.fields.descriptionText = pageContext["SignatureInput-Description"]?.value;
			ps01Data.fields.buttonText = pageContext["SignatureInput-CompleteButtonText"]?.value;
			h04Data.fields.titleText = pageContext["SignatureInput-SubTitle"]?.value;
			signatureInputBM02Data.fields.modalButtonLabel = pageContext["SignatureInput-InputButtonText"]?.value;
		}
	}, [isCpad])

	useEffect(() => {
		if (registerOrderDataError) {
			clearErrors();
			setError("registerOrderDataError", {
				type: "manual",
				message: "API error",
			});
		}

		if (registerOrderDataResponse) {
			const processResult = registerOrderDataResponse.processResult;
			if (processResult?.returnCode === apiResultCode.error) {
					processResult.resultInfos.forEach((err, index) => {
					if (err.resultCategory === apiResultCode.error) {
						setError(err.resultCode + index, {
						type: "manual",
						message: err.resultMessage,
						});
					}
				});
			}

			if (processResult?.returnCode === apiResultCode.success) {
        const CMAP_OrderReceiptNumber__c = registerOrderDataResponse?.orderRelatedInfo?.orderInfo?.order?.CMAP_OrderReceiptNumber__c;
				//TODO: Uncomment line below once Edion functionality has been fixed
				// const applicationNumber = getSessionStorageItem(
				//     keys.SSN_COMMON_COMMON_APPLICATIONNUMBER,
				//     true
				// );

				// if (personalizeInfo?.AccountName === "エディオン") {
				//     setEdionRequestData({
				//         TABUKENO: applicationNumber,
				//         retURL: buttonLink?.value?.href,
				//     });
				// } else {
        // setSessionStorageItem(
        //   keys.SSN_COMMON_COMMON_APPLICATIONNUMBER,
        //   CMAP_OrderReceiptNumber__c,
        //   true
        // )
				//     redirectToLinkUrl(buttonLink);
				// }
        setSessionStorageItem(
          keys.SSN_COMMON_COMMON_APPLICATIONNUMBER,
          CMAP_OrderReceiptNumber__c,
          true
        )
				redirectToLinkUrl(buttonLink);
			}
		}
	}, [registerOrderDataResponse, registerOrderDataError]);

	const importantInformationSection = getScDataFromPlaceholder("button-fourth-section",props);
	const b01Data = getScDataFromComponent("B-01", importantInformationSection)[0];
	b01Data.fields.onClickEvent = (e) => {
		onClickApplyButton();
	};

	const buttonLink = pageContext["ImportantMatterConfirmation-ApplyButtonLink"];
	b01Data.fields.linkURL = buttonLink;
	const onClickApplyButton = () => {
    retrieveSystemDateTimeRefresh();
	};

  // receive the second systemDateTime call
	useEffect(() => {
		if (retrieveSystemDateTime.isFirstCall === false) {
      clearErrors();
      const checkOrderDataRequest = createCheckOrderApiRequest(orderRelatedInfo);
      const { orderImportantMatterDescriptions } = checkOrderDataRequest?.orderRelatedInfo?.orderInfo ?? [];
  
      const registerOrderRequestData  = {
        ...checkOrderDataRequest,
        orderCheckProcessAlertIgnored: true,
        orderRelatedInfo: {
          ...checkOrderDataRequest?.orderRelatedInfo,
          orderInfo: {
            ...checkOrderDataRequest?.orderRelatedInfo?.orderInfo,
            order: {
              ...checkOrderDataRequest?.orderRelatedInfo?.orderInfo?.order,
            },
            orderAddition: {
              ...checkOrderDataRequest?.orderRelatedInfo?.orderInfo?.orderAddition,
              electronicSignature: imgPath
            },
            orderImportantMatterDescriptions: orderImportantMatterDescriptions.map((desc) => ({
              ...desc,
              CMAP_ConsentDateTime__c: systemDateTime,
            })),
          },
        },
      };
      
			setRegisterOrderRequest(registerOrderRequestData);
		}
	}, [retrieveSystemDateTime])

  // receive orderRequest
	useEffect(() => {
		if (registerOrderRequest){
			registerOrderDataRefresh();
		}
	}, [registerOrderRequest]);

  const isApiLoading = RetrieveImportantMattersDescriptionDataLoading || checkOrderDataLoading || registerOrderDataLoading;
  props.setIsLoading(isApiLoading);

	return (
		<>
			<div className="form_wrapper-input" name="important-information-section signature-section">
				{!isImportantInformationSectionHidden &&
					<Placeholder
						name="important-information-section"
						rendering={props.rendering}
					/>
				}
				<RichText field={
					sitecoreContext.route.fields["ImportantMatterConfirmation-MattersRelatedToCancellation"]
				}/>
				<RichText field={
					sitecoreContext.route.fields["ImportantMatterConfirmation-InquiryContactInfo"]
				}/>

				{isCpad && 
					<Placeholder
						name="signature-section"
						rendering={props.rendering}
					/>
				}

				{isCpad && imgPath !== "" && (
					<div className="signature-canvas-img">
						<img alt="signature" src={imgPath} />
					</div>
				)}
			</div>
			<div className="form_wrapper signature-canvas" name="button-fifth-section button-fourth-section">
				{isCpad && 
					<div className="btn_container">
						<Placeholder
							name="button-fifth-section"
							rendering={props.rendering}
						/>
					</div>
				}
					
				<div className="btn_container">
					<Placeholder
						name="button-fourth-section"
						rendering={props.rendering}
					/>
				</div>
			</div>
		</>
	)
}