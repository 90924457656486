//Note:  This is a temporary file for C-Pad-Create-MyCommufa-ID and might be deleted in the future
import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
} from "../../utils/commonUtils";

const CPadRegisterCommufaId = (props) => {
  const planTypeSelectionSection = getScDataFromPlaceholder(
    "commufa-ID-section",
    props
  );
  const [S02Data] = getScDataFromComponent("S-02", planTypeSelectionSection);
  const selectRegisterCommufaIdCategory = "selectedRegisterCommufaId";

  // TODO: get the data from api or master
  const selectRegisterCommufaIdOptions = [
    {
      Code: "A",
      Value: "その場でメールの確認ができる",
      Description: "MyコミュファID登録を⾏う（その場でメールの確認ができる）",
    },
    {
      Code: "その場でメールの確認ができない",
      Value: "回線を含まないプラン",
      Description: "MyコミュファID登録を⾏う（その場でメールの確認ができない）",
    },
    {
      Code: "C",
      Value: "MyコミュファID登録を⾏わない",
      Description: "MyコミュファID登録を⾏わない",
    },
  ];

  S02Data.fields["category"] = { value: selectRegisterCommufaIdCategory };
  S02Data.fields["selections"] = selectRegisterCommufaIdOptions;

  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <Placeholder
                name="warning-modal-section"
                rendering={props.rendering}
              />
              <div className="form_wrapper-input" name="commufa-ID-section">
                <Placeholder
                  name="commufa-ID-section"
                  rendering={props.rendering}
                />
                {/* TODO: Separate component */}
                <div className="input-form">
                  <div className="list_wrapper note option-result-box">
                    <div
                      className="option-result-container"
                      name="form-content-input-section-1"
                    >
                      <Placeholder
                        name="form-content-input-section-1"
                        rendering={props.rendering}
                      />
                    </div>
                    <div
                      className="option-result-container"
                      name="form-content-input-section-2"
                    >
                      <Placeholder
                        name="form-content-input-section-2"
                        rendering={props.rendering}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="form_wrapper-input"
                name="form-content-input-section-3"
              >
                <Placeholder
                  name="form-content-input-section-3"
                  rendering={props.rendering}
                />
              </div>
              <div className="btn_container" name="button-section">
                <Placeholder
                  name="button-section"
                  rendering={props.rendering}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default CPadRegisterCommufaId;
