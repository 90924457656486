import { recoilKeys as keys } from "../../../assets/recoilKeys";
import {
  TAX_RATE_REDUCED,
  TAX_RATE_NORMAL
} from "../../../utils/constantVariables";
import {
  getSessionStorageItem,
} from "../../../utils/useSessionStorage";
import linq from 'linq'
import {
  filterSelectedMonthlyExpenseProduct,
  filterSelectedAnnualExpenseProduct,
  filterSelectedInitialCostProduct,
  filterSelectedBenefitProduct,
  calculateTotalPrice,
  calculateAnnualPriceTaxExc,
  calculateAnnualPriceTaxInc,
  calculateInitialCostTaxExc,
  calculateInitialCostTaxInc,
  createSubtotalObj,
  createBenefitSubObj
} from "../Common/CommonProcess"

// 月額料金
export const createMonthlyExpenseObj = (afterFeeSimulationResponse) => {
  // SessionStorageから変更後のOrderRelatedInfoとHearingInfoを読み込む。
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更後のOrderRelatedInfoから対象の月額商品を抽出する。
  const selectedMonthlyProduct = filterSelectedMonthlyExpenseProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 表示用オブジェクトの元データを作成する。
  const baseObj = createMonthlyExpenseBaseObj(selectedMonthlyProduct, afterFeeSimulationResponse);

  // 表示用オブジェクト_商品小計を作成する。
  const subtotalObj = createMonthlyExpenseSubtotalObj(baseObj);

  // 表示用オブジェクト_合計を作成する。
  const totalObj = createMonthlyExpenseTotalObj(baseObj);

  return { subtotalObj, totalObj };
}

const createMonthlyExpenseBaseObj = (base, afterFeeSimulationResponse) => {
  const afterOrderItemInfos = linq.from(afterFeeSimulationResponse)
    .select(x => {
      return {
        Product2Id: x?.orderItem?.Product2Id, // 商品
        simulatedUnitPriceTaxExc1: x?.simulationInfo?.simulatedUnitPriceTaxExc1, // シミュレーション金額1(税抜)
        simulatedUnitPriceTaxExc2: x?.simulationInfo?.simulatedUnitPriceTaxExc2, // シミュレーション金額2(税抜)
        simulatedUnitPriceTaxExc3: x?.simulationInfo?.simulatedUnitPriceTaxExc3, // シミュレーション金額3(税抜)
        simulatedUnitPriceTaxInc1: x?.simulationInfo?.simulatedUnitPriceTaxInc1, // シミュレーション金額1(税込)
        simulatedUnitPriceTaxInc2: x?.simulationInfo?.simulatedUnitPriceTaxInc2, // シミュレーション金額2(税込)
        simulatedUnitPriceTaxInc3: x?.simulationInfo?.simulatedUnitPriceTaxInc3 // シミュレーション金額3(税込)
      };
    }
    )
    .toArray();

  const result = linq.from(base)
    .select(x => x?.orderItem)
    .groupJoin(afterOrderItemInfos, // LeftOuterJoinする右側の配列
      x => x?.Product2Id, // 左側テーブルの結合キー：商品
      y => y?.Product2Id, // 右側テーブルの結合キー：商品
      (x, y) => ({
        ...x, // 左側テーブルの全項目
        y
      }))
    .selectMany(x => x?.y?.defaultIfEmpty(), // LeftOuterJoinの続き
      (x, y) => {
        delete x?.y;
        return {
          ...x, // 左側テーブルの全項目
          simulatedUnitPriceTaxExc1: x?.CMAP_Unchargeable__c ? 0 : y?.simulatedUnitPriceTaxExc1, // シミュレーション金額1(税抜)
          simulatedUnitPriceTaxExc2: x?.CMAP_Unchargeable__c ? 0 : y?.simulatedUnitPriceTaxExc2, // シミュレーション金額2(税抜)
          simulatedUnitPriceTaxExc3: x?.CMAP_Unchargeable__c ? 0 : y?.simulatedUnitPriceTaxExc3, // シミュレーション金額3(税抜)
          simulatedUnitPriceTaxInc1: x?.CMAP_Unchargeable__c ? 0 : y?.simulatedUnitPriceTaxInc1, // シミュレーション金額1(税込)
          simulatedUnitPriceTaxInc2: x?.CMAP_Unchargeable__c ? 0 : y?.simulatedUnitPriceTaxInc2, // シミュレーション金額2(税込)
          simulatedUnitPriceTaxInc3: x?.CMAP_Unchargeable__c ? 0 : y?.simulatedUnitPriceTaxInc3 // シミュレーション金額3(税込)
        };
      }
    )
    .toArray();

  return result;
}

const createMonthlyExpenseSubtotalObj = (base) => {
  const result = linq.from(base)
    .select(x => {
      return {
        CMAP_MainSubProductGroupId__c: x?.CMAP_MainSubProductGroupId__c, // メイン/サブ商品グループ
        CMAP_MainSubProductGroupName__c: x?.CMAP_MainSubProductGroupName__c, // メイン/サブ商品グループ名
        CMAP_ProductCategoryId__c: x?.CMAP_ProductCategoryId__c, // 商品カテゴリ
        CMAP_ProductCategoryName__c: x?.CMAP_ProductCategoryName__c, // 商品カテゴリ名
        Product2Id: x?.Product2Id, // 商品
        CMAP_ProductName__c: x?.CMAP_ProductName__c, // 商品名
        CMAP_OrderItemTypeSelection__c: x?.CMAP_OrderItemTypeSelection__c, // 申込商品種別：選択商品
        CMAP_OrderItemTypeBenefit__c: x?.CMAP_OrderItemTypeBenefit__c, // 申込商品種別：特典商品
        CMAP_OrderItemTypeAutomaticSelection__c: x?.CMAP_OrderItemTypeAutomaticSelection__c, // 申込商品種別：自動選択商品
        CMAP_MainSubProGroupBenefitCPSortOrder__c: x?.CMAP_MainSubProGroupBenefitCPSortOrder__c, // メイン/サブ商品グループ・特典キャンペーン並び順
        CMAP_ProductCategoryBenefitSortOrder__c: x?.CMAP_ProductCategoryBenefitSortOrder__c, // 商品カテゴリ・特典並び順
        CMAP_ProductSortOrder__c: x?.CMAP_ProductSortOrder__c, // 商品並び順
        simulatedUnitPriceTaxExc1: x?.simulatedUnitPriceTaxExc1, // シミュレーション金額1(税抜)
        simulatedUnitPriceTaxExc2: x?.simulatedUnitPriceTaxExc2, // シミュレーション金額2(税抜)
        simulatedUnitPriceTaxExc3: x?.simulatedUnitPriceTaxExc3, // シミュレーション金額3(税抜)
        simulatedUnitPriceTaxInc1: x?.simulatedUnitPriceTaxInc1, // シミュレーション金額1(税込)
        simulatedUnitPriceTaxInc2: x?.simulatedUnitPriceTaxInc2, // シミュレーション金額2(税込)
        simulatedUnitPriceTaxInc3: x?.simulatedUnitPriceTaxInc3 // シミュレーション金額3(税込)
      };
    })
    .orderBy(x => x?.CMAP_MainSubProGroupBenefitCPSortOrder__c) // 第1ソート：メイン商品/サブ商品グループ・特典キャンペーン並び順
    .thenBy(x => x?.CMAP_ProductCategoryBenefitSortOrder__c) // 第2ソート：商品カテゴリ・特典並び順
    .thenBy(x => x?.CMAP_ProductSortOrder__c) // 第3ソート：商品並び順
    .toArray();

  return result;
}

const createMonthlyExpenseTotalObj = (base) => {
  const tmp = linq.from(base)
    .groupBy(
      x => x.CMAP_ComsumptionTaxRate__c, // グループ化するキー：消費税率
      null,
      (key, group) => ({
        CMAP_ComsumptionTaxRate__c: key, // 消費税率
        simulatedUnitPriceTaxExc1: group?.sum(x => x?.simulatedUnitPriceTaxExc1), // シミュレーション金額1(税抜)
        simulatedUnitPriceTaxExc2: group?.sum(x => x?.simulatedUnitPriceTaxExc2), // シミュレーション金額2(税抜)
        simulatedUnitPriceTaxExc3: group?.sum(x => x?.simulatedUnitPriceTaxExc3) // シミュレーション金額3(税抜)
      }))
    .toArray();

  // 表示用オブジェクトを作成する。
  const result = {
    simulatedUnitPriceTaxExc1: 0,
    simulatedUnitPriceTaxExc2: 0,
    simulatedUnitPriceTaxExc3: 0,
    simulatedUnitPriceTax1: 0,
    simulatedUnitPriceTax2: 0,
    simulatedUnitPriceTax3: 0,
    simulatedUnitPriceTaxInc1: 0,
    simulatedUnitPriceTaxInc2: 0,
    simulatedUnitPriceTaxInc3: 0
  };
  tmp.forEach(x => {
    result["simulatedUnitPriceTaxExc1"] += x?.simulatedUnitPriceTaxExc1 || 0;
    result["simulatedUnitPriceTaxExc2"] += x?.simulatedUnitPriceTaxExc2 || 0;
    result["simulatedUnitPriceTaxExc3"] += x?.simulatedUnitPriceTaxExc3 || 0;
    result["simulatedUnitPriceTax1"] += Math.trunc(x?.simulatedUnitPriceTaxExc1 * x?.CMAP_ComsumptionTaxRate__c / 100) || 0;
    result["simulatedUnitPriceTax2"] += Math.trunc(x?.simulatedUnitPriceTaxExc2 * x?.CMAP_ComsumptionTaxRate__c / 100) || 0;
    result["simulatedUnitPriceTax3"] += Math.trunc(x?.simulatedUnitPriceTaxExc3 * x?.CMAP_ComsumptionTaxRate__c / 100) || 0;
  });
  result["simulatedUnitPriceTaxInc1"] = result.simulatedUnitPriceTaxExc1 + result.simulatedUnitPriceTax1;
  result["simulatedUnitPriceTaxInc2"] = result.simulatedUnitPriceTaxExc2 + result.simulatedUnitPriceTax2;
  result["simulatedUnitPriceTaxInc3"] = result.simulatedUnitPriceTaxExc3 + result.simulatedUnitPriceTax3;

  return result;
}

// 年額料金
export const createAnnualExpenseObj = () => {
  // SessionStorageから変更後のOrderRelatedInfoとHearingInfoを読み込む。
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更後のOrderRelatedInfoから対象の年額商品を抽出する。
  const selectedAnnualProduct = filterSelectedAnnualExpenseProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 表示用オブジェクト_商品小計を作成する。
  const subtotalObj = createSubtotalObj(selectedAnnualProduct, calculateAnnualPriceTaxExc, calculateAnnualPriceTaxInc);

  // 表示用オブジェクト_合計値を作成する。
  const totalObj = calculateTotalPrice(selectedAnnualProduct, calculateAnnualPriceTaxExc);

  return { subtotalObj, totalObj };
}

// 初期費用
export const createInitialCostObj = () => {
  // SessionStorageから変更後のOrderRelatedInfoとHearingInfoを読み込む。
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更後のOrderRelatedInfoから対象の初期費用商品を抽出する。
  const selectedInitialCostProduct = filterSelectedInitialCostProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 表示用オブジェクト_商品小計を作成する。
  const subtotalObj = createSubtotalObj(selectedInitialCostProduct, calculateInitialCostTaxExc, calculateInitialCostTaxInc);

  // 表示用オブジェクト_合計を作成する。
  const totalObj = calculateTotalPrice(selectedInitialCostProduct, calculateInitialCostTaxExc);

  return { subtotalObj, totalObj };
}

// プラン特典
export const createBenefitObj = () => {
  // SessionStorageから変更後のOrderRelatedInfoとHearingInfoを読み込む。
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更後のOrderRelatedInfoから対象の初期費用商品を抽出する。
  const selectedBenefitProduct = filterSelectedBenefitProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 表示用オブジェクトを作成する。
  const benefitObj = createBenefitSubObj(selectedBenefitProduct);

  return benefitObj;
}

// オプション
export const createOptionObj = (afterFeeSimulationResponse) => {
  // SessionStorageから変更後のOrderRelatedInfoとHearingInfoを読み込む。
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更後の料金シミュレーションのレスポンスから対象のオプション商品を抽出する。
  const selectedOptionProduct = filterOptionProduct(afterFeeSimulationResponse);

  // 表示用オブジェクトを作成する。
  const result = createOptionDisplayObj(selectedOptionProduct, after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  return result;
}

const filterOptionProduct = (orderItemInfos) => {
  const result = linq.from(orderItemInfos)
    .where(x => x?.simulationInfo?.simulatedOptionUnitPrice0 !== null)
    .select(x => {
      return {
        Product2Id: x?.orderItem?.Product2Id, // 商品
        simulatedOptionUnitPrice0: x?.simulationInfo?.simulatedOptionUnitPrice0, // オプション金額開始月
        simulatedOptionUnitPrice1: x?.simulationInfo?.simulatedOptionUnitPrice1, // オプション金額1か月後
        simulatedOptionUnitPrice2: x?.simulationInfo?.simulatedOptionUnitPrice2, // オプション金額2か月後
        simulatedOptionUnitPrice3: x?.simulationInfo?.simulatedOptionUnitPrice3, // オプション金額3か月後
        simulatedOptionUnitPrice4: x?.simulationInfo?.simulatedOptionUnitPrice4, // オプション金額4か月後
        simulatedOptionUnitPrice5: x?.simulationInfo?.simulatedOptionUnitPrice5 // オプション金額5か月後
      };
    })
    .toArray();

  return result;
}

const createOptionDisplayObj = (selectedBenefitProduct, after) => {
  const tmp = linq.from(after)
    .select(x => x.orderItem)
    .toArray();

  const result = linq.from(selectedBenefitProduct)
    .groupJoin(tmp, // LeftOuterJoinする右側の配列
      x => x?.Product2Id, // 左側テーブルの結合キー：商品
      y => y?.Product2Id, // 右側テーブルの結合キー：商品
      (x, y) => ({
        ...x, // 左側テーブルの全項目
        y
      }))
    .selectMany(x => x?.y?.defaultIfEmpty(), // LeftOuterJoinの続き
      (x, y) => {
        delete x?.y;
        return {
          ...x,
          CMAP_ProductName__c: y?.CMAP_ProductName__c, // 商品名
          CMAP_MainSubProGroupBenefitCPSortOrder__c: y?.CMAP_MainSubProGroupBenefitCPSortOrder__c, // メイン商品/サブ商品グループ・特典キャンペーン並び順
          CMAP_ProductCategoryBenefitSortOrder__c: y?.CMAP_ProductCategoryBenefitSortOrder__c, // 商品カテゴリ・特典並び順
          CMAP_ProductSortOrder__c: y?.CMAP_ProductSortOrder__c, // 商品並び順
        }
      }
    )
    .orderBy(x => x?.CMAP_MainSubProGroupBenefitCPSortOrder__c) // 第1ソート：メイン商品/サブ商品グループ・特典キャンペーン並び順
    .thenBy(x => x?.CMAP_ProductCategoryBenefitSortOrder__c) // 第2ソート：商品カテゴリ・特典並び順
    .thenBy(x => x?.CMAP_ProductSortOrder__c) // 第3ソート：商品並び順
    .toArray();

  return result;
}