import React, { useState, useEffect } from "react";
import {
  Placeholder,
  RichText as SitecoreRichText,
} from "@sitecore-jss/sitecore-jss-react";
import { useCustomQuery } from "../../hooks/useGetData";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import { di_fetch } from "../../utils/di";
import { apiBaseURL } from "../../envSettings";
import { STRING_GET } from "../../utils/constantVariables";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  redirectToLinkUrl,
} from "../../utils/commonUtils";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";

export const HandlingPersonalInfo = (props) => {
  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  const orderRelatedInfoSessionStorage = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );

  //Retrieves system date and time
  const systemDateTimeURI = `${apiBaseURL}common/RetrieveSystemDateTime`;

  const {
    data: systemDateTimeData,
    isLoading: systemDateTimeIsLoading,
    error: systemDateTimeError,
    refetch: systemDateTimeRefresh,
    remove: systemDateTimeRemove,
  } = useCustomQuery(systemDateTimeURI, false, STRING_GET);

  // Retrieve login state
  const retrieveLoginStateURI = `${apiBaseURL}common/RetrieveLoginStatus`;
  const { data: retrieveLoginStatusData, isLoading: retrieveLoginStatusLoading } = useCustomQuery(
    retrieveLoginStateURI,
    true,
    STRING_GET
  );

  const isApiLoading = systemDateTimeIsLoading || retrieveLoginStatusLoading;
  props.setIsLoading(isApiLoading);

  // model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const pageFields = sitecoreContext?.route.fields;
  const [isAgreement, setIsAgreement] = useState(false);

  const personalInfoSection3 = getScDataFromPlaceholder(
    "personal-info-section-3",
    props
  );
  const buttonSection = getScDataFromPlaceholder("personal-info-button-section", props);

  const [agreementButton] = getScDataFromComponent(
    "B-04",
    personalInfoSection3
  );

  if (buttonSection.length > 0) {
    const [backButton] = getScDataFromComponent("B-02", buttonSection);
    const [nextButton] = getScDataFromComponent("B-01", buttonSection);

    backButton.fields.onChangeEvent = () => {
      setSessionStorageItem(
        keys.SSN_APPLICATION_HANDLINGPERSONALINFO_AGREEMENTFLG,
        "false",
        true
      );
      redirectToPreviousPage();
    }
    nextButton.fields.isDisabled = { value: !isAgreement };
    nextButton.fields.onClickEvent = () => {
      // call systemDateTimeData api
      systemDateTimeRefresh();
    };
  };

  agreementButton.fields.initialValue = { value: isAgreement };
  agreementButton.fields.onChangeEvent = () => {
    setIsAgreement(!isAgreement);
  };




  // receive systemDateTimeData api
  useEffect(() => {
    if (systemDateTimeData) {
      // go to next page

      setSessionStorageItem(
        keys.SSN_APPLICATION_HANDLINGPERSONALINFO_AGREEMENTFLG,
        isAgreement,
        true
      );

      const orderRelatedInfo = {
        ...orderRelatedInfoSessionStorage,
        orderRelatedInfo: {
          ...orderRelatedInfoSessionStorage?.orderRelatedInfo,
          orderInfo: {
            ...orderRelatedInfoSessionStorage?.orderRelatedInfo?.orderInfo,
            order: {
              ...orderRelatedInfoSessionStorage?.orderRelatedInfo?.orderInfo?.order,
              CMAP_PersonalInformationConsentDate__c: systemDateTimeData?.SystemDateTime,
            },
          },
        },
      };

      setSessionStorageItem(
        keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
        orderRelatedInfo,
        true
      );

      const isLogged = retrieveLoginStatusData?.Result ?? false;

      const memberURL = pageFields["HandlingPersonalInfo-NextButtonLinkForMember"];
      const nonMemberURL = pageFields["HandlingPersonalInfo-NextButtonLinkForNonMember"];
      const linkURL = isLogged ? memberURL : nonMemberURL;
      redirectToLinkUrl(linkURL);
    }
  }, [systemDateTimeData]);


  return (
    <>
      <div className="form_wrapper-input">
        <Placeholder
          name="personal-info-section-1"
          rendering={props.rendering}
        />
        <Placeholder name="modal-window-1" rendering={props.rendering} />
        <SitecoreRichText
          field={pageFields?.["HandlingPersonalInfo-ContractNotice"]}
        />
        <Placeholder
          name="personal-info-section-2"
          rendering={props.rendering}
        />
        <Placeholder name="modal-window-2" rendering={props.rendering} />
        <Placeholder
          name="personal-info-section-3"
          rendering={props.rendering}
        />
      </div>
      <div className="btn_container">
        <Placeholder name="personal-info-button-section" rendering={props.rendering} />
      </div>
    </>
  );
};
