import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

// get the data

// update the context

// @Controller

const L08 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { image, iconLogo, contactTitleText, additionalClass } = properData;

  // @View
  return (
    <React.Fragment>
      <div className={`list_wrapper with-icon L-08 ${additionalClass?.value}`}>
        <div className="icon-list">
          {typeof image === "object" && iconLogo ? (
            <span className="icon">
              <i className={`bi bi-${iconLogo?.value}`}></i>
            </span>
          ) : (
            <span
              className="icon"
              dangerouslySetInnerHTML={{ __html: image }}
            />
          )}
          {/*<i className={`bi bi-${iconLogo?.value}`}></i>*/}
          {/*<Image media={{ editable :  image}} />*/}
          {/*<span  />
          </span>*/}
          <span>
            <RichText field={fillBlank(contactTitleText)} />
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default L08;
