import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const PT01 = (props) => {
  const { headingText } = props.fields;
  let htxt = headingText;
  if (!htxt?.value) {
    let _htxt = props?.sitecoreContext?.route?.fields?.PageTitle;
    if (_htxt){
      htxt = _htxt;
    }
  }
  return (
    <React.Fragment>
      <div className="title_wrapper PT-01">
        <h2>
          <Text field={fillBlank(htxt)} />
        </h2>
      </div>
    </React.Fragment>
  );
};

export default withSitecoreContext()(PT01);
