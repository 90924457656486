
import { validationFuncs } from "../../utils/validations";
import { getErrorMessage } from "../../utils/errorMessageHandler";
import {
  STRING_APPLY,
  STRING_SIMULATOR,
  STRING_MOVING,
  STRING_INDIVIDUAL,
  STRING_CORPORATION,
  STRING_CIRCUIT,
  STRING_RESIDENCE_TYPE_APARTMENT
} from "../../utils/constantVariables";

const CMAP_NumberOfLetters__c = (name) => {
    return name?.fieldMetadata?.fieldMetadataExtension?.CMAP_NumberOfLetters__c;
}
const CMAP_ValidationPattern__c = (name) => {
  return name?.fieldMetadata?.fieldMetadataExtension?.CMAP_ValidationPattern__c;
}
const CMAP_ValidationErrorMessage__c = (name) => {
  return name?.fieldMetadata?.fieldMetadataExtension?.CMAP_ValidationErrorMessage__c;
}

export const saveCustomerInfoData = async (
    inputValues,
    metadataDefinitions,
    urlParentItem,
    planType,
    cMAPContractName,
    accountId,
    orderRelatedInfo,
    setError,
    clearErrors,
    setIsValidate,
    setIsButtonNextClick
  ) => {
    clearErrors();
    setIsValidate(true);

    const fieldMetadataDefinitions = metadataDefinitions?.Account?.fieldMetadataDefinitions;
    const maxLengthStreetNumber = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_ContactHouseNumber__c);
    const maxLengthBuildingName = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_MansionName__c);
    const maxLengthRoomNumber = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_RoomNo__c);
    const maxLengthLastName = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.LastName);
    const maxLengthFirstName = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.FirstName);
    const maxLengthLastNameFurigana = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.LastNameKana__pc);
    const maxLengthFirstNameFurigana = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.FirstNameKana__pc);
    const maxLengthCorporateName = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.LastName);
    const maxLengthCorporateNameFurigana = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_CorporateGroupName__c);
    const maxLengthDepartmentName = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_DepartmentName__c);
    const maxLengthPersonalInChargeLastName = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_ContactPersonFamilyName__c);
    const maxLengthPersonalInChargeFirstName = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_ContactPersonName__c);
    const maxLengthPersonalInChargeLastNameFurigana = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_ContactPersonLastNameKana__pc);
    const maxLengthPersonalInChargeFirstNameFurigana = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_ContactPersonFirstNameKana__pc);
    const maxLengthPhoneNumber1 = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_AreaCode__c);
    const maxLengthPhoneNumber2 = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_LocalTelephoneNumber__c);
    const maxLengthPhoneNumber3 = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_SubscriberNumber__c);
    const maxLengthMobileNumber1 = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_MobileNumber1__c);
    const maxLengthMobileNumber2 = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_MobileNumber2__c);
    const maxLengthMobileNumber3 = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_MobileNumber3__c);
    const patternLastNameFurigana = CMAP_ValidationPattern__c(fieldMetadataDefinitions?.LastNameKana__pc);
    const patternFirstNameFurigana = CMAP_ValidationPattern__c(fieldMetadataDefinitions?.FirstNameKana__pc);
    const patternCorporateNameFurigana = CMAP_ValidationPattern__c(fieldMetadataDefinitions?.CMAP_CorporateGroupName__c);
    const patternPersonalInChargeLastNameFurigana = CMAP_ValidationPattern__c(fieldMetadataDefinitions?.CMAP_ContactPersonLastNameKana__pc);
    const patternPersonalInChargeFirstNameFurigana = CMAP_ValidationPattern__c(fieldMetadataDefinitions?.CMAP_ContactPersonFirstNameKana__pc);
    const patternPhoneNumber1 = CMAP_ValidationPattern__c(fieldMetadataDefinitions?.CMAP_AreaCode__c);
    const patternPhoneNumber2 = CMAP_ValidationPattern__c(fieldMetadataDefinitions?.CMAP_LocalTelephoneNumber__c);
    const patternPhoneNumber3 = CMAP_ValidationPattern__c(fieldMetadataDefinitions?.CMAP_SubscriberNumber__c);
    const patternMobileNumber1 = CMAP_ValidationPattern__c(fieldMetadataDefinitions?.CMAP_MobileNumber1__c);
    const patternMobileNumber2 = CMAP_ValidationPattern__c(fieldMetadataDefinitions?.CMAP_MobileNumber2__c);
    const patternMobileNumber3 = CMAP_ValidationPattern__c(fieldMetadataDefinitions?.CMAP_MobileNumber3__c);
    const errorMessageLastNameFurigana = CMAP_ValidationErrorMessage__c(fieldMetadataDefinitions?.LastNameKana__pc);
    const errorMessageFirstNameFurigana = CMAP_ValidationErrorMessage__c(fieldMetadataDefinitions?.FirstNameKana__pc);
    const errorMessageCorporateNameFurigana = CMAP_ValidationErrorMessage__c(fieldMetadataDefinitions?.CMAP_CorporateGroupName__c);
    const errorMessagePersonalInChargeLastNameFurigana = CMAP_ValidationErrorMessage__c(fieldMetadataDefinitions?.CMAP_ContactPersonLastNameKana__pc);
    const errorMessagePersonalInChargeFirstNameFurigana = CMAP_ValidationErrorMessage__c(fieldMetadataDefinitions?.CMAP_ContactPersonFirstNameKana__pc);
    const errorMessagePhoneNumber1 = CMAP_ValidationErrorMessage__c(fieldMetadataDefinitions?.CMAP_AreaCode__c);
    const errorMessagePhoneNumber2 = CMAP_ValidationErrorMessage__c(fieldMetadataDefinitions?.CMAP_LocalTelephoneNumber__c);
    const errorMessagePhoneNumber3 = CMAP_ValidationErrorMessage__c(fieldMetadataDefinitions?.CMAP_SubscriberNumber__c);
    const errorMessageMobileNumber1 = CMAP_ValidationErrorMessage__c(fieldMetadataDefinitions?.CMAP_MobileNumber1__c);
    const errorMessageMobileNumber2 = CMAP_ValidationErrorMessage__c(fieldMetadataDefinitions?.CMAP_MobileNumber2__c);
    const errorMessageMobileNumber3 = CMAP_ValidationErrorMessage__c(fieldMetadataDefinitions?.CMAP_MobileNumber3__c);

    // PreferredTransferDateCalendar -> only on: moving
    if (urlParentItem === STRING_MOVING) {
      if (!validationFuncs.validateIsRequired(inputValues.selectPreferredTransferDateCalendar)) {
        setError("PreferredTransferDateCalendar", {
          type: "manual",
          message: getErrorMessage("E0002", ["移転希望日選択カレンダー"]),
        });
        setIsValidate(false);
      }
    }

    // Commufa service section
    if (!validationFuncs.validateIsRequired(inputValues.registerInstallationAddressBuildingType)) {
      setError("BuildingTypeRadioButton", {
        type: "manual",
        message: getErrorMessage("E0002", ["建物区分ラジオボタン"]),
      });
      setIsValidate(false);
    }

    if (!validationFuncs.validateIsMaxLength(inputValues.registerInstallationAddressBuildingName, maxLengthBuildingName)) {
      setError("BuildingName", {
        type: "manual",
        message: getErrorMessage("E0015", ["建物名", maxLengthBuildingName]),
      });
      setIsValidate(false);
    }

    if (orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_ResidenceType__c === STRING_RESIDENCE_TYPE_APARTMENT) {
      if (!validationFuncs.validateIsMaxLength(inputValues.registerInstallationAddressRoomNumber, maxLengthRoomNumber)) {
        setError("RoomNumber", {
          type: "manual",
          message: getErrorMessage("E0015", ["部屋番号", maxLengthRoomNumber]),
        });
        setIsValidate(false);
      }
    }

    if (!validationFuncs.validateIsRequired(inputValues.registerInstallationAddressStreetNumber)) {
      setError("StreetNumber", {
        type: "manual",
        message: getErrorMessage("E0001", ["町名・番地"]),
      });
      setIsValidate(false);
    }
    if (!validationFuncs.validateIsMaxLength(inputValues.registerInstallationAddressStreetNumber, maxLengthStreetNumber)) {
      setError("StreetNumber", {
        type: "manual",
        message: getErrorMessage("E0015", ["町名・番地", maxLengthStreetNumber]),
      });
      setIsValidate(false);
    }

    // PreferredStartDateCalendar -> only on: (apply or simulator) + not circuit
    if ((urlParentItem === STRING_APPLY || urlParentItem === STRING_SIMULATOR) && planType !== STRING_CIRCUIT) {
      if (!validationFuncs.validateIsRequired(inputValues.registerInstallationAddressPreferredStartDate)) {
        setError("PreferredStartDateCalendar", {
          type: "manual",
          message: getErrorMessage("E0002", ["開始希望日選択カレンダー"]),
        });
        setIsValidate(false);
      }
    }

    // individual section -> only on: individual
    if (cMAPContractName === STRING_INDIVIDUAL) {
      if (!validationFuncs.validateIsRequired(inputValues.registerIndividualCustomerInfoLastName)) {
        setError("LastName", {
          type: "manual",
          message: getErrorMessage("E0001", ["姓"]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validateIsMaxLength(inputValues.registerIndividualCustomerInfoLastName, maxLengthLastName)) {
        setError("LastName", {
          type: "manual",
          message: getErrorMessage("E0015", ["姓", maxLengthLastName]),
        });
        setIsValidate(false);
      }

      if (!validationFuncs.validateIsRequired(inputValues.registerIndividualCustomerInfoFirstName)) {
        setError("FirstName", {
          type: "manual",
          message: getErrorMessage("E0001", ["名"]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validateIsMaxLength(inputValues.registerIndividualCustomerInfoFirstName, maxLengthFirstName)) {
        setError("FirstName", {
          type: "manual",
          message: getErrorMessage("E0015", ["名", maxLengthFirstName]),
        });
        setIsValidate(false);
      }

      if (!validationFuncs.validateIsRequired(inputValues.registerIndividualCustomerInfoLastNameFurigana)) {
        setError("LastNameFurigana", {
          type: "manual",
          message: getErrorMessage("E0001", ["姓フリガナ"]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validateIsMaxLength(inputValues.registerIndividualCustomerInfoLastNameFurigana, maxLengthLastNameFurigana)) {
        setError("LastNameFurigana", {
          type: "manual",
          message: getErrorMessage("E0015", ["姓フリガナ", maxLengthLastNameFurigana]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validatePattern(inputValues.registerIndividualCustomerInfoLastNameFurigana, patternLastNameFurigana)) {
        setError("LastNameFurigana", {
          type: "manual",
          message: errorMessageLastNameFurigana,
        });
        setIsValidate(false);
      }

      if (!validationFuncs.validateIsRequired(inputValues.registerIndividualCustomerInfoFirstNameFurigana)) {
        setError("FirstNameFurigana", {
          type: "manual",
          message: getErrorMessage("E0001", ["名フリガナ"]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validateIsMaxLength(inputValues.registerIndividualCustomerInfoFirstNameFurigana, maxLengthFirstNameFurigana)) {
        setError("FirstNameFurigana", {
          type: "manual",
          message: getErrorMessage("E0015", ["名フリガナ", maxLengthFirstNameFurigana]),
        });
        setIsValidate(false);
      } 
      if (!validationFuncs.validatePattern(inputValues.registerIndividualCustomerInfoFirstNameFurigana, patternFirstNameFurigana)) {
        setError("FirstNameFurigana", {
          type: "manual",
          message: errorMessageFirstNameFurigana,
        });
        setIsValidate(false);
      }

      if (!validationFuncs.validateIsRequired(inputValues.registerIndividualCustomerInfoGender)) {
        setError("GenderRadioButton", {
          type: "manual",
          message: getErrorMessage("E0002", ["性別ラジオボタン"]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validateIsRequired(inputValues.registerIndividualCustomerInfoBirthDate)) {
        setError("DateOfBirth", {
          type: "manual",
          message: getErrorMessage("E0002", ["生年月日"]),
        });
        setIsValidate(false);
      }
    }

    // corporation section -> only on: corporation
    if (cMAPContractName === STRING_CORPORATION) {
      if (!validationFuncs.validateIsRequired(inputValues.registerCorporateCustomerInfoCorporateName)) {
        setError("CorporateName", {
          type: "manual",
          message: getErrorMessage("E0001", ["企業団体名"]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validateIsMaxLength(inputValues.registerCorporateCustomerInfoCorporateName, maxLengthCorporateName)) {
        setError("CorporateName", {
          type: "manual",
          message: getErrorMessage("E0015", ["企業団体名", maxLengthCorporateName]),
        });
        setIsValidate(false);
      }

      if (!validationFuncs.validateIsRequired(inputValues.registerCorporateCustomerInfoCorporateNameFurigana)) {
        setError("CorporateNameFurigana", {
          type: "manual",
          message: getErrorMessage("E0001", ["企業団体名フリガナ"]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validateIsMaxLength(inputValues.registerCorporateCustomerInfoCorporateNameFurigana, maxLengthCorporateNameFurigana)) {
        setError("CorporateNameFurigana", {
          type: "manual",
          message: getErrorMessage("E0015", ["企業団体名フリガナ", maxLengthCorporateNameFurigana]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validatePattern(inputValues.registerCorporateCustomerInfoCorporateNameFurigana, patternCorporateNameFurigana)) {
        setError("CorporateNameFurigana", {
          type: "manual",
          message: errorMessageCorporateNameFurigana,
        });
        setIsValidate(false);
      }
      
      if (!validationFuncs.validateIsRequired(inputValues.registerCorporateCustomerInfoDepartmentName)) {
        setError("DepartmentName", {
          type: "manual",
          message: getErrorMessage("E0001", ["部署名"]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validateIsMaxLength(inputValues.registerCorporateCustomerInfoDepartmentName, maxLengthDepartmentName)) {
        setError("DepartmentName", {
          type: "manual",
          message: getErrorMessage("E0015", ["部署名", maxLengthDepartmentName]),
        });
        setIsValidate(false);
      }
      
      if (!validationFuncs.validateIsRequired(inputValues.registerCorporateCustomerInfoPersonInChargeLastName)) {
        setError("PersonInChargeLastName", {
          type: "manual",
          message: getErrorMessage("E0001", ["担当者姓"]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validateIsMaxLength(inputValues.registerCorporateCustomerInfoPersonInChargeLastName, maxLengthPersonalInChargeLastName)) {
        setError("PersonInChargeLastName", {
          type: "manual",
          message: getErrorMessage("E0015", ["担当者姓", maxLengthPersonalInChargeLastName]),
        });
        setIsValidate(false);
      }

      if (!validationFuncs.validateIsRequired(inputValues.registerCorporateCustomerInfoPersonInChargeFirstName)) {
        setError("PersonInChargeFirstName", {
          type: "manual",
          message: getErrorMessage("E0001", ["担当者名"]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validateIsMaxLength(inputValues.registerCorporateCustomerInfoPersonInChargeFirstName, maxLengthPersonalInChargeFirstName)) {
        setError("PersonInChargeFirstName", {
          type: "manual",
          message: getErrorMessage("E0015", ["担当者名", maxLengthPersonalInChargeFirstName]),
        });
        setIsValidate(false);
      }
      
      if (!validationFuncs.validateIsRequired(inputValues.registerCorporateCustomerInfoPersonInChargeFirstNameFurigana)) {
        setError("PersonInChargeFirstNameFurigana", {
          type: "manual",
          message: getErrorMessage("E0001", ["担当者名フリガナ"]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validateIsMaxLength(inputValues.registerCorporateCustomerInfoPersonInChargeFirstNameFurigana, maxLengthPersonalInChargeFirstNameFurigana)) {
        setError("PersonInChargeFirstNameFurigana", {
          type: "manual",
          message: getErrorMessage("E0015", ["担当者名フリガナ", maxLengthPersonalInChargeFirstNameFurigana]),
        });
        setIsValidate(false);
      } 
      if (!validationFuncs.validatePattern(inputValues.registerCorporateCustomerInfoPersonInChargeFirstNameFurigana, patternPersonalInChargeFirstNameFurigana)) {
        setError("PersonInChargeFirstNameFurigana", {
          type: "manual",
          message: errorMessagePersonalInChargeFirstNameFurigana,
        });
        setIsValidate(false);
      }
      
      if (!validationFuncs.validateIsRequired(inputValues.registerCorporateCustomerInfoPersonInChargeLastNameFurigana)) {
        setError("PersonInChargeLastNameFurigana", {
          type: "manual",
          message: getErrorMessage("E0001", ["担当者姓フリガナ"]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validateIsMaxLength(inputValues.registerCorporateCustomerInfoPersonInChargeLastNameFurigana, maxLengthPersonalInChargeLastNameFurigana)) {
        setError("PersonInChargeLastNameFurigana", {
          type: "manual",
          message: getErrorMessage("E0015", ["担当者姓フリガナ", maxLengthPersonalInChargeLastNameFurigana]),
        });
        setIsValidate(false);
      }
      if (!validationFuncs.validatePattern(inputValues.registerCorporateCustomerInfoPersonInChargeLastNameFurigana, patternPersonalInChargeLastNameFurigana)) {
        setError("PersonInChargeLastNameFurigana", {
          type: "manual",
          message: errorMessagePersonalInChargeLastNameFurigana,
        });
        setIsValidate(false);
      }
    }

    // SameAddressRadioButton -> only on: not moving
    if (urlParentItem !== STRING_MOVING) {
      if (!validationFuncs.validateIsRequired(inputValues.registerContactAddressCode)) {
        setError("SameAddressRadioButton", {
          type: "manual",
          message: getErrorMessage("E0002", ["ご利用場所と同じラジオボタン"]),
        });
        setIsValidate(false);
      }
    }

    // Postal code -> only on: other address (or moving)
    if (parseInt(inputValues.registerContactAddressCode) === 2 || urlParentItem === STRING_MOVING) {
      const postalCodeIsValid = validatePostalCode(setIsValidate, setError, inputValues, fieldMetadataDefinitions, cMAPContractName, accountId);

      if (!postalCodeIsValid) {
        setIsValidate(false);
      }
    }
    
    // RegisterContact (address) -> only on: other address
    if (parseInt(inputValues.registerContactAddressCode) === 2) {
      if (!validationFuncs.validateIsMaxLength(inputValues.registerContactAddressBuildingName, maxLengthBuildingName)) {
        setError("BuildingName", {
          type: "manual",
          message: getErrorMessage("E0015", ["建物名（連絡先）", maxLengthBuildingName]),
        });
        setIsValidate(false);
      }

      if (!validationFuncs.validateIsRequired(inputValues.registerContactAddressStreetName)) {
        setError("StreetName", {
          type: "manual",
          message: getErrorMessage("E0001", ["町名・番地（連絡先）"]),
        });
        setIsValidate(false);
      }

      if (!validationFuncs.validateIsMaxLength(inputValues.registerContactAddressStreetName, maxLengthStreetNumber)) {
        setError("StreetName", {
          type: "manual",
          message: getErrorMessage("E0015", ["町名・番地（連絡先）", maxLengthStreetNumber]),
        });
        setIsValidate(false);
      }

      if (!validationFuncs.validateIsMaxLength(inputValues.registerContactAddressRoomNumber, maxLengthRoomNumber)) {
        setError("RoomNumber", {
          type: "manual",
          message: getErrorMessage("E0015", ["部屋番号（連絡先）", maxLengthRoomNumber]),
        });
        setIsValidate(false);
      }
    }
    
    // RegisterContact
    if (!validationFuncs.validateIsMaxLength(inputValues.registerContactAddressPhoneNumber1, maxLengthPhoneNumber1)) {
      setError("PhoneNumber1", {
        type: "manual",
        message: getErrorMessage("E0015", ["電話番号1", maxLengthPhoneNumber1]),
      });
      setIsValidate(false);
    } 
    if (!validationFuncs.validatePattern(inputValues.registerContactAddressPhoneNumber1, patternPhoneNumber1)) {
      setError("PhoneNumber1", {
        type: "manual",
        message: errorMessagePhoneNumber1,
      });
      setIsValidate(false);
    } 

    if (!validationFuncs.validateIsMaxLength(inputValues.registerContactAddressPhoneNumber2, maxLengthPhoneNumber2)) {
      setError("PhoneNumber2", {
        type: "manual",
        message: getErrorMessage("E0015", ["電話番号2", maxLengthPhoneNumber2]),
      });
      setIsValidate(false);
    } 
    if (!validationFuncs.validatePattern(inputValues.registerContactAddressPhoneNumber2, patternPhoneNumber2)) {
      setError("PhoneNumber2", {
        type: "manual",
        message: errorMessagePhoneNumber2,
      });
      setIsValidate(false);
    } 

    if (!validationFuncs.validateIsMaxLength(inputValues.registerContactAddressPhoneNumber3, maxLengthPhoneNumber3)) {
      setError("PhoneNumber3", {
        type: "manual",
        message: getErrorMessage("E0015", ["電話番号3", maxLengthPhoneNumber3]),
      });
      setIsValidate(false);
    } 
    if (!validationFuncs.validatePattern(inputValues.registerContactAddressPhoneNumber3, patternPhoneNumber3)) {
      setError("PhoneNumber3", {
        type: "manual",
        message: errorMessagePhoneNumber3,
      });
      setIsValidate(false);
    } 

    if (!validationFuncs.validateIsMaxLength(inputValues.registerContactAddressMobileNumber1, maxLengthMobileNumber1)) {
      setError("MobileNumber1", {
        type: "manual",
        message: getErrorMessage("E0015", ["携帯番号1", maxLengthMobileNumber1]),
      });
      setIsValidate(false);
    }
    if (!validationFuncs.validatePattern(inputValues.registerContactAddressMobileNumber1, patternMobileNumber1)) {
      setError("MobileNumber1", {
        type: "manual",
        message: errorMessageMobileNumber1,
      });
      setIsValidate(false);
    } 

    if (!validationFuncs.validateIsMaxLength(inputValues.registerContactAddressMobileNumber2, maxLengthMobileNumber2)) {
      setError("MobileNumber2", {
        type: "manual",
        message: getErrorMessage("E0015", ["携帯番号2", maxLengthMobileNumber2]),
      });
      setIsValidate(false);
    } 
    if (!validationFuncs.validatePattern(inputValues.registerContactAddressMobileNumber2, patternMobileNumber2)) {
      setError("MobileNumber2", {
        type: "manual",
        message: errorMessageMobileNumber2,
      });
      setIsValidate(false);
    } 

    if (!validationFuncs.validateIsMaxLength(inputValues.registerContactAddressMobileNumber3, maxLengthMobileNumber3)) {
      setError("MobileNumber3", {
        type: "manual",
        message: getErrorMessage("E0015", ["携帯番号3", maxLengthMobileNumber3]),
      });
      setIsValidate(false);
    } 
    if (!validationFuncs.validatePattern(inputValues.registerContactAddressMobileNumber3, patternMobileNumber3)) {
      setError("MobileNumber3", {
        type: "manual",
        message: errorMessageMobileNumber3,
      });
      setIsValidate(false);
    } 

    setIsButtonNextClick(true)
};


export const validatePostalCode = (setIsValidate, setError, inputValues, fieldMetadataDefinitions, cMAPContractName, accountID, isLoginStatus) =>{
  const maxLengthContactPostalCode3Digit = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_ContactPostalCode__c);
  const maxLengthContactPostalCode4Digit = CMAP_NumberOfLetters__c(fieldMetadataDefinitions?.CMAP_ContactPostalCodeLowerFour__c);
  const patternPostalCode3Digit = CMAP_ValidationPattern__c(fieldMetadataDefinitions?.CMAP_ContactPostalCode__c);
  const patternPostalCode4Digit = CMAP_ValidationPattern__c(fieldMetadataDefinitions?.CMAP_ContactPostalCodeLowerFour__c);
  const errorMessagePostalCode3Digit = CMAP_ValidationErrorMessage__c(fieldMetadataDefinitions?.CMAP_ContactPostalCode__c);
  const errorMessagePostalCode4Digit = CMAP_ValidationErrorMessage__c(fieldMetadataDefinitions?.CMAP_ContactPostalCodeLowerFour__c);

  let isValid = true;
  if (!isLoginStatus && parseInt(inputValues.registerContactAddressCode) === 1) {
    if (!validationFuncs.validateIsRequired(inputValues.registerContactAddressPostalCode3digit)) {
      setError("postalCode3digit", {
        type: "manual",
        message: getErrorMessage("E0001", ["郵便番号3桁"]),
      });
      isValid = false;
      setIsValidate(false);
    }
    if (cMAPContractName === STRING_INDIVIDUAL || !accountID) {
      if (
        !validationFuncs.validateIsMaxLength(inputValues.registerContactAddressPostalCode3digit, maxLengthContactPostalCode3Digit)
      ) {
        setError("postalCode3digit", {
          type: "manual",
          message: getErrorMessage("E0015", ["郵便番号3桁", maxLengthContactPostalCode3Digit]),
        });
        isValid = false;
        setIsValidate(false);
      }
      if (
        !validationFuncs.validatePattern(inputValues.registerContactAddressPostalCode3digit, patternPostalCode3Digit)
      ) {
        setError("postalCode3digit", {
          type: "manual",
          message: errorMessagePostalCode3Digit,
        });
        isValid = false;
        setIsValidate(false);
      }
    }
  
    if (!validationFuncs.validateIsRequired(inputValues.registerContactAddressPostalCode4digit)) {
      setError("postalCode4digit", {
        type: "manual",
        message: getErrorMessage("E0001", ["郵便番号4桁"]),
      });
      isValid = false;
      setIsValidate(false);
    }
    if (cMAPContractName === STRING_INDIVIDUAL || !accountID) {
      if (
        !validationFuncs.validateIsMaxLength(inputValues.registerContactAddressPostalCode4digit, maxLengthContactPostalCode4Digit)
      ) {
        setError("postalCode4digit", {
          type: "manual",
          message: getErrorMessage("E0015", ["郵便番号4桁", maxLengthContactPostalCode4Digit]),
        });
        isValid = false;
        setIsValidate(false);
      }
      if (
        !validationFuncs.validatePattern(inputValues.registerContactAddressPostalCode4digit, patternPostalCode4Digit)
      ) {
        setError("postalCode4digit", {
          type: "manual",
          message: errorMessagePostalCode4Digit,
        });
        isValid = false;
        setIsValidate(false);
      }
    }
  }
  return isValid;
}