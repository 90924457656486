import React from 'react';

var dependencies = {};

// DI proc
export function di_register(key, dependency) {
  key = key.trim();
  dependencies[key] = dependency;
}

// get data via DI
export function di_fetch(key) {
  if (key in dependencies) return dependencies[key];
  //throw new Error(`${ key } is not registered as dependency.`);
  return '';
}

export function wire(Component, deps) {
  return class Injector extends React.Component {
    constructor(props) {
      super(props);

      let _dict = {};
      for (let i in deps) {
        let k = deps[i].trim();
        let v = di_fetch(k);
        _dict[k] = v;
      }
      let _add_dict = { mycontext: _dict };
      this._resolvedDependencies = _add_dict;
    }
    render() {
      return (
        <Component
          {...this.state}
          {...this.props}
          {...this._resolvedDependencies}
        />
      );
    }
  };
}
