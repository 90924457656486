import { Text } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank, nullChecker } from "../../utils/commonUtils";

// @Model

// get the data

// update the context

// @Controller

// @View

const L05 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { additionalClass, titleText } = properData;

  const dataRowList = nullChecker(properData?.dataRowList, []);
  return (
    <React.Fragment>
      <div
        className={`list_wrapper note primary L-05 ${additionalClass?.value}`}
      >
        {titleText.value != "–" && (
          <>
            <h4>
              <Text field={fillBlank(titleText)} />
            </h4>
            <br />
          </>
        )}
        <ul>
          {dataRowList &&
            dataRowList.map((item, i) => (
              <Text
                field={fillBlank(item.fields.Value)}
                tag="li"
                encode={false}
                key={i}
              />
            ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default L05;
