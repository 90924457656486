import { recoilKeys as keys } from "../../../assets/recoilKeys";
import {
  STRING_STANDARD,
  STRING_DISCOUNT,
  STRING_OPTION,
  STRING_OTHER_PROVIDER,
  STRING_SUBTOTAL,
  STRING_TOTAL
} from "../../../utils/constantVariables";
import {
  getSessionStorageItem,
} from "../../../utils/useSessionStorage";
import linq from 'linq'
import {
  filterSelectedMonthlyExpenseProduct,
  filterSelectedAnnualExpenseProduct,
  filterSelectedInitialCostProduct,
  filterSelectedBenefitProduct,
  createSubtotalObj,
  createBenefitSubObj,
  calculateAnnualPriceTaxExc,
  calculateAnnualPriceTaxInc,
  calculateInitialCostTaxExc,
  calculateInitialCostTaxInc
} from "../Common/CommonProcess"

const STRING_MONTHLY_EXPENSE_PROPS = {
  CATEGORY: 'Category',
  CURRENT: 'Current',
  COMMUFA1: 'Commufa1',
  COMMUFA2: 'Commufa2',
  COMMUFA3: 'Commufa3',
  COMMUFA1_DIFF: 'Commufa1Diff',
  COMMUFA2_DIFF: 'Commufa2Diff',
  COMMUFA3_DIFF: 'Commufa3Diff',
  CUMULATIVE_AMOUNT1: 'CumulativeAmount1',
  CUMULATIVE_AMOUNT2: 'CumulativeAmount2',
  CUMULATIVE_AMOUNT1_DIFF: 'CumulativeAmount1Diff',
  CUMULATIVE_AMOUNT2_DIFF: 'CumulativeAmount2Diff',
  CURRENT_CUMULATIVE_AMOUNT1: 'CurrentCumulativeAmount1',
  CURRENT_CUMULATIVE_AMOUNT2: 'CurrentCumulativeAmount2'
}

// 月額料金
export const createMonthlyExpenseObj = (afterFeeSimulationResponse) => {
  // SessionStorageから変更後のOrderRelatedInfoとHearingInfoを読み込む。
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);
  const hearingInfo = getSessionStorageItem(keys.SSN_APPLICATION_CPADHEARING_HEARINGINFO, true);

  // 変更後のOrderRelatedInfoから対象の月額商品を抽出する。
  const selectedMonthlyProduct = filterSelectedMonthlyExpenseProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 料金シミュレーション情報オブジェクトを作成する。
  const baseObj = createFeeSimulationInfoObj(selectedMonthlyProduct, afterFeeSimulationResponse);

  // 月額料金情報オブジェクトを作成する。
  const result = createMonthlyExpenseInfoObj(baseObj, hearingInfo);

  return result;
}

const createFeeSimulationInfoObj = (base, afterFeeSimulationResponse) => {
  const afterOrderItemInfos = linq.from(afterFeeSimulationResponse)
    .select(x => {
      return {
        Product2Id: x?.orderItem?.Product2Id, // 商品
        simulationOrderItemCategory: x?.simulationInfo?.simulationOrderItemCategory, // 料金シミュレーション区分（基本/オプション/割引）
        simulatedUnitPriceTaxExc1: x?.simulationInfo?.simulatedUnitPriceTaxExc1, // シミュレーション金額1(税抜)
        simulatedUnitPriceTaxExc2: x?.simulationInfo?.simulatedUnitPriceTaxExc2, // シミュレーション金額2(税抜)
        simulatedUnitPriceTaxExc3: x?.simulationInfo?.simulatedUnitPriceTaxExc3, // シミュレーション金額3(税抜)
        cumulativeAmount1: x?.simulationInfo?.cumulativeAmount1, // 2年累計
        cumulativeAmount2: x?.simulationInfo?.cumulativeAmount2 // 5年累計
      };
    }
    )
    .toArray();

  const result = linq.from(base)
    .select(x => x?.orderItem)
    .groupJoin(afterOrderItemInfos, // LeftOuterJoinする右側の配列
      x => x?.Product2Id, // 左側テーブルの結合キー：商品
      y => y?.Product2Id, // 右側テーブルの結合キー：商品
      (x, y) => ({
        ...x, // 左側テーブルの全項目
        y
      }))
    .selectMany(x => x?.y?.defaultIfEmpty(), // LeftOuterJoinの続き
      (x, y) => {
        delete x?.y;
        return {
          simulationOrderItemCategory: y?.simulationOrderItemCategory, // 料金シミュレーション区分（基本/オプション/割引）
          CMAP_ComsumptionTaxRate__c: x?.CMAP_ComsumptionTaxRate__c, // 消費税率
          simulatedUnitPriceTaxExc1: x?.CMAP_Unchargeable__c === false ? y?.simulatedUnitPriceTaxExc1 : 0, // シミュレーション金額1(税抜)
          simulatedUnitPriceTaxExc2: x?.CMAP_Unchargeable__c === false ? y?.simulatedUnitPriceTaxExc2 : 0, // シミュレーション金額2(税抜)
          simulatedUnitPriceTaxExc3: x?.CMAP_Unchargeable__c === false ? y?.simulatedUnitPriceTaxExc3 : 0, // シミュレーション金額3(税抜)
          cumulativeAmount1: x?.CMAP_Unchargeable__c === false ? y?.cumulativeAmount1 : 0, // 2年累計
          cumulativeAmount2: x?.CMAP_Unchargeable__c === false ? y?.cumulativeAmount2 : 0 // 5年累計
        }
      }
    )
    .groupBy(
      x => {
        return {
          simulationOrderItemCategory: x?.simulationOrderItemCategory, // 料金シミュレーション区分（基本/オプション/割引）
          CMAP_ComsumptionTaxRate__c: x?.CMAP_ComsumptionTaxRate__c // 消費税率
        }
      },
      null,
      (key, group) => {
        return {
          simulationOrderItemCategory: key?.simulationOrderItemCategory, // 料金シミュレーション区分（基本/オプション/割引）
          CMAP_ComsumptionTaxRate__c: key?.CMAP_ComsumptionTaxRate__c, // 消費税率
          simulatedUnitPriceTaxInc1: Math.trunc(group?.sum(x => x?.simulatedUnitPriceTaxExc1) * (100 + key?.CMAP_ComsumptionTaxRate__c) / 100), // シミュレーション金額1(税込)
          simulatedUnitPriceTaxInc2: Math.trunc(group?.sum(x => x?.simulatedUnitPriceTaxExc2) * (100 + key?.CMAP_ComsumptionTaxRate__c) / 100), // シミュレーション金額2(税込)
          simulatedUnitPriceTaxInc3: Math.trunc(group?.sum(x => x?.simulatedUnitPriceTaxExc3) * (100 + key?.CMAP_ComsumptionTaxRate__c) / 100), // シミュレーション金額3(税込)
          cumulativeAmount1: group?.sum(x => x?.cumulativeAmount1), // 2年累計
          cumulativeAmount2: group?.sum(x => x?.cumulativeAmount2) // 5年累計
        }
      }
    )
    .groupBy(
      x => x?.simulationOrderItemCategory, // 料金シミュレーション区分（基本/オプション/割引）
      null,
      (key, group) => {
        return {
          [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: key,// 料金シミュレーション区分（基本/オプション/割引）
          [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1]: group?.sum(x => x?.simulatedUnitPriceTaxInc1), // シミュレーション金額1(税込)
          [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2]: group?.sum(x => x?.simulatedUnitPriceTaxInc2), // シミュレーション金額2(税込)
          [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3]: group?.sum(x => x?.simulatedUnitPriceTaxInc3), // シミュレーション金額3(税込)
          [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: group?.sum(x => x?.cumulativeAmount1), // 2年累計
          [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: group?.sum(x => x?.cumulativeAmount2) // 5年累計
        }
      }
    )
    .toArray();

  return result;
}

const createMonthlyExpenseInfoObj = (base, hearingInfo) => {

  // 基本、オプション、割引のオブジェクトを取得
  const baseStandard = base.find(x => x?.[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_STANDARD);
  const baseOption = base.find(x => x?.[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_OPTION);
  const baseDiscount = base.find(x => x?.[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_DISCOUNT);

  // HearingInfoの各金額を取得
  const hearingInfoStandard = hearingInfo?.BasicPrice?.TotalPrice || 0;
  const hearingInfoOption = hearingInfo?.Option?.OptionList?.reduce((prev, current) => prev + current?.OptionPrice, 0)
    + hearingInfo?.Option?.OtherOptionList?.reduce((prev, current) => prev + current?.OptionPrice, 0) || 0;
  const hearingInfoDiscount = hearingInfo?.Discount || 0;
  const hearingInfoOtherProvider = hearingInfo?.OtherProvider?.OtherProviderPrice || 0;

  // 基本、オプション、割引を算出
  const result = [
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_STANDARD,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: hearingInfoStandard,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1]: baseStandard?.Commufa1 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2]: baseStandard?.Commufa2 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3]: baseStandard?.Commufa3 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1_DIFF]: (baseStandard?.Commufa1 || 0) - hearingInfoStandard,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2_DIFF]: (baseStandard?.Commufa2 || 0) - hearingInfoStandard,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3_DIFF]: (baseStandard?.Commufa3 || 0) - hearingInfoStandard,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: baseStandard?.CumulativeAmount1 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: baseStandard?.CumulativeAmount2 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1_DIFF]: (baseStandard?.CumulativeAmount1 || 0) - hearingInfoStandard * 24,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2_DIFF]: (baseStandard?.CumulativeAmount2 || 0) - hearingInfoStandard * 60
    },
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_OPTION,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: hearingInfoOption,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1]: baseOption?.Commufa1 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2]: baseOption?.Commufa2 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3]: baseOption?.Commufa3 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1_DIFF]: (baseOption?.Commufa1 || 0) - hearingInfoOption,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2_DIFF]: (baseOption?.Commufa2 || 0) - hearingInfoOption,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3_DIFF]: (baseOption?.Commufa3 || 0) - hearingInfoOption,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: baseOption?.CumulativeAmount1 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: baseOption?.CumulativeAmount2 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1_DIFF]: (baseOption?.CumulativeAmount1 || 0) - hearingInfoOption * 24,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2_DIFF]: (baseOption?.CumulativeAmount2 || 0) - hearingInfoOption * 60
    },
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_DISCOUNT,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: hearingInfoDiscount,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1]: baseDiscount?.Commufa1 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2]: baseDiscount?.Commufa2 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3]: baseDiscount?.Commufa3 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1_DIFF]: (baseDiscount?.Commufa1 || 0) - hearingInfoDiscount,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2_DIFF]: (baseDiscount?.Commufa2 || 0) - hearingInfoDiscount,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3_DIFF]: (baseDiscount?.Commufa3 || 0) - hearingInfoDiscount,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: baseDiscount?.CumulativeAmount1 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: baseDiscount?.CumulativeAmount2 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1_DIFF]: (baseDiscount?.CumulativeAmount1 || 0) - hearingInfoDiscount * 24,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2_DIFF]: (baseDiscount?.CumulativeAmount2 || 0) - hearingInfoDiscount * 60
    }
  ];

  // 基本、オプション、割引の小計を算出
  const subtotal = createMonthlyExpenseTotalObj(result, STRING_SUBTOTAL);

  // 他社プロバイダーを算出
  result.push(
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_OTHER_PROVIDER,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: null,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1]: hearingInfoOtherProvider,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2]: hearingInfoOtherProvider,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3]: hearingInfoOtherProvider,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1_DIFF]: hearingInfoOtherProvider,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2_DIFF]: hearingInfoOtherProvider,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3_DIFF]: hearingInfoOtherProvider,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: hearingInfoOtherProvider * 24,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: hearingInfoOtherProvider * 60,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1_DIFF]: hearingInfoOtherProvider * 24,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2_DIFF]: hearingInfoOtherProvider * 60
    }
  );

  // 基本、オプション、割引、他社プロバイダの合計を算出
  const total = createMonthlyExpenseTotalObj(result, STRING_TOTAL);

  // 結果に小計と合計を追加
  result.push(subtotal);
  result.push(total);

  return result;
}

const createMonthlyExpenseTotalObj = (base, category) => {
  // 結果保持用のオブジェクトを作成
  const result = {
    [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: category,
    [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: 0,
    [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1]: 0,
    [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2]: 0,
    [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3]: 0,
    [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1_DIFF]: 0,
    [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2_DIFF]: 0,
    [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3_DIFF]: 0,
    [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: 0,
    [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: 0,
    [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1_DIFF]: 0,
    [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2_DIFF]: 0
  }

  // 各列の合計を算出
  linq.from(base).forEach(x => {
    result[STRING_MONTHLY_EXPENSE_PROPS.CURRENT] += x.Current;
    result[STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1] += x.Commufa1;
    result[STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2] += x.Commufa2;
    result[STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3] += x.Commufa3;
    result[STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1_DIFF] += x.Commufa1Diff;
    result[STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2_DIFF] += x.Commufa2Diff;
    result[STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3_DIFF] += x.Commufa3Diff;
    result[STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1] += x.CumulativeAmount1;
    result[STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2] += x.CumulativeAmount2;
    result[STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1_DIFF] += x.CumulativeAmount1Diff;
    result[STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2_DIFF] += x.CumulativeAmount2Diff;
  });

  return result;
}

// 月額料金グラフ
export const createMonthlyExpenseChartObj = (monthlyExpenseObj) => {
  // 各列を取得
  const standard = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_STANDARD);
  const option = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_OPTION);
  const discount = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_DISCOUNT);
  const otherProvider = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_OTHER_PROVIDER);
  const subtotal = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_SUBTOTAL);
  const total = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_TOTAL);

  // 結果を算出
  const result = [
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_STANDARD,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: (standard?.Current + discount?.Current) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1]: (standard?.Commufa1 + discount?.Commufa1) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2]: (standard?.Commufa2 + discount?.Commufa2) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3]: (standard?.Commufa3 + discount?.Commufa3) || 0
    },
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_OPTION,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: option?.Current || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1]: option?.Commufa1 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2]: option?.Commufa2 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3]: option?.Commufa3 || 0
    },
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_OTHER_PROVIDER,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: null, // 他社プロバイダの現在利用回線はnullで返す。
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1]: otherProvider?.Commufa1 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2]: otherProvider?.Commufa2 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3]: otherProvider?.Commufa3 || 0
    },
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_SUBTOTAL,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: subtotal?.Current || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1]: subtotal?.Commufa1 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2]: subtotal?.Commufa2 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3]: subtotal?.Commufa3 || 0
    },
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_TOTAL,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: total?.Current || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA1]: total?.Commufa1 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA2]: total?.Commufa2 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.COMMUFA3]: total?.Commufa3 || 0
    }
  ];

  return result;
}

// 実質月額グラフ
export const createActualMonthlyExpenseChartObj = (monthlyExpenseObj) => {
  // 各列を取得
  const standard = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_STANDARD);
  const option = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_OPTION);
  const discount = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_DISCOUNT);
  const otherProvider = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_OTHER_PROVIDER);
  const subtotal = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_SUBTOTAL);
  const total = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_TOTAL);

  // 結果を算出
  const result = [
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_STANDARD,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: (standard?.Current + discount?.Current) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: ((standard?.CumulativeAmount1 + discount?.CumulativeAmount1) / 24) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: ((standard?.CumulativeAmount2 + discount?.CumulativeAmount2) / 60) || 0
    },
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_OPTION,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: option?.Current || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: (option?.CumulativeAmount1 / 24) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: (option?.CumulativeAmount2 / 60) || 0
    },
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_OTHER_PROVIDER,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: null, // 他社プロバイダの現在利用回線はnullで返す。
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: (otherProvider?.CumulativeAmount1 / 24) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: (otherProvider?.CumulativeAmount2 / 60) || 0
    },
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_SUBTOTAL,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: subtotal?.Current || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: (subtotal?.CumulativeAmount1 / 24) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: (subtotal?.CumulativeAmount2 / 60) || 0
    },
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_TOTAL,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT]: total?.Current || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: (total?.CumulativeAmount1 / 24) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: (total?.CumulativeAmount2 / 60) || 0
    }
  ];

  return result;
}

// 累計比較グラフ
export const createCumulativeTotalChartObj = (monthlyExpenseObj) => {
  // 各列を取得
  const standard = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_STANDARD);
  const option = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_OPTION);
  const discount = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_DISCOUNT);
  const otherProvider = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_OTHER_PROVIDER);
  const subtotal = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_SUBTOTAL);
  const total = monthlyExpenseObj?.find(x => x[STRING_MONTHLY_EXPENSE_PROPS.CATEGORY] === STRING_TOTAL);

  // 結果を算出
  const result = [
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_STANDARD,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT_CUMULATIVE_AMOUNT1]: ((standard?.Current + discount?.Current) * 24) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT_CUMULATIVE_AMOUNT2]: ((standard?.Current + discount?.Current) * 60) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: (standard?.CumulativeAmount1 + discount?.CumulativeAmount1) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: (standard?.CumulativeAmount2 + discount?.CumulativeAmount2) || 0
    },
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_OPTION,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT_CUMULATIVE_AMOUNT1]: (option?.Current * 24) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT_CUMULATIVE_AMOUNT2]: (option?.Current * 60) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: option?.CumulativeAmount1 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: option?.CumulativeAmount2 || 0
    },
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_OTHER_PROVIDER,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT_CUMULATIVE_AMOUNT1]: null, // 他社プロバイダの現在利用回線2年累計はnullで返す。
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT_CUMULATIVE_AMOUNT2]: null, // 他社プロバイダの現在利用回線5年累計はnullで返す。
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: otherProvider?.CumulativeAmount1 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: otherProvider?.CumulativeAmount2 || 0
    },
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_SUBTOTAL,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT_CUMULATIVE_AMOUNT1]: (subtotal?.Current * 24) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT_CUMULATIVE_AMOUNT2]: (subtotal?.Current * 60) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: subtotal?.CumulativeAmount1 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: subtotal?.CumulativeAmount2 || 0
    },
    {
      [STRING_MONTHLY_EXPENSE_PROPS.CATEGORY]: STRING_TOTAL,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT_CUMULATIVE_AMOUNT1]: (total?.Current * 24) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CURRENT_CUMULATIVE_AMOUNT2]: (total?.Current * 60) || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT1]: total?.CumulativeAmount1 || 0,
      [STRING_MONTHLY_EXPENSE_PROPS.CUMULATIVE_AMOUNT2]: total?.CumulativeAmount2 || 0
    }
  ];

  return result;
}

// 年額料金
export const createAnnualExpenseObj = () => {
  // SessionStorageから変更後のOrderRelatedInfoを読み込む。
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更後のOrderRelatedInfoから対象の年額商品を抽出する。
  const selectedAnnualProduct = filterSelectedAnnualExpenseProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 表示用オブジェクト_商品小計を作成する。
  const subtotalObj = createSubtotalObj(selectedAnnualProduct, calculateAnnualPriceTaxExc, calculateAnnualPriceTaxInc);

  return subtotalObj;
}

// 初期費用
export const createInitialCostObj = () => {
  // SessionStorageから変更後のOrderRelatedInfoを読み込む。
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更後のOrderRelatedInfoから対象の初期費用商品を抽出する。
  const selectedInitialCostProduct = filterSelectedInitialCostProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 表示用オブジェクト_商品小計を作成する。
  const subtotalObj = createSubtotalObj(selectedInitialCostProduct, calculateInitialCostTaxExc, calculateInitialCostTaxInc);

  return subtotalObj;
}

// プラン特典
export const createBenefitObj = () => {
  // SessionStorageから変更後のOrderRelatedInfoを読み込む。
  const after = getSessionStorageItem(keys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

  // 変更後のOrderRelatedInfoから対象の初期費用商品を抽出する。
  const selectedBenefitProduct = filterSelectedBenefitProduct(after?.orderRelatedInfo?.orderInfo?.orderItemInfos);

  // 表示用オブジェクト_商品小計を作成する。
  const benefitObj = createBenefitSubObj(selectedBenefitProduct, calculateInitialCostTaxExc, calculateInitialCostTaxInc);

  return benefitObj;
}