import React, { useEffect, useState } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { benefitCampaignData } from "./benefitCampaignTestData";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  deepcopy,
} from "../../utils/commonUtils";
import {
  STRING_INPUT_CONTENT,
  STRING_NOT_APPLIED,
  STRING_PLAN_BENEFITS_SECTION,
  STRING_CATEGORY_NEW_CONTRACT,
  STRING_BENEFIT_INFO_COPY,
  STRING_SELECTED,
  STRING_ADDED,
  STRING_CURRENT_SECTION_SELECT_BENEFIT
} from "../../utils/constantVariables";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";

//get data

const orderRelatedInfo = getSessionStorageItem(
  sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
  true
);

const noApplyBenefitSession = getSessionStorageItem(
  sessionKeys.SSN_APPLICATION_SELECTPRODUCT_NOAPPLYBENEFIT,
  true
);

const beforeChangeOrderRelatedInfo = getSessionStorageItem(
  sessionKeys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO,
  true
);

export function SelectPlanBenefitsPackage(props) {
  
  const [planBenefitSelected, setPlanBenefitSelected] = useState([]);
  const [planProductIsLicked, setPlanProductIsLicked] = useState(true);
  const [
    benefitCampaignInfoCurrentIndex,
    setBenefitCampaignInfoCurrentIndex,
  ] = useState("");
  const [benefitProductItems, setBenefitProductItems] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [noApplyBenefit, setNoApplyBenefit] = useState([]);
  const [updatedOrderRelatedInfo, setUpdatedOrderRelatedInfo] = useState([]);

  const checkIfCompleteSelected = (campaignId) => {
    setSelectedCampaign((prevState) => {
      if(!prevState.includes(campaignId)){
        return [...prevState, campaignId]
      }else{
        return prevState;
      }
    });
  }

  useEffect(() => {
    if(benefitCampaignData.benefitCampaignInfos.length === selectedCampaign.length){
      props.setBM05Data("");
    }
  },[selectedCampaign]);

  useEffect(() => {
    
    let planBenefitSection = getScDataFromPlaceholder(
      STRING_PLAN_BENEFITS_SECTION,
      props
    );

    const [benefit] = getScDataFromComponent("C-02", planBenefitSection);
    let countSelected = 0;
    if (benefitCampaignData && benefit) {
      benefitCampaignData.benefitCampaignInfos.forEach(
        (benefitCampaignInfo, benefitCampaignInfoIndex) => {
          const applyBenefit = orderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos.find((x) => x.orderItem?.CMAP_BenefitCampaignId__c === benefitCampaignInfo.benefitCampaign.Id);
          const noApplyBenefit = noApplyBenefitSession.length > 0 ? noApplyBenefitSession.find((x) => x === benefitCampaignInfo.benefitCampaign.Id) : false;
          if(applyBenefit || noApplyBenefit){
            countSelected += 1;
          }
          const benefitCopy = deepcopy(benefit);
          benefitCopy.uid =
          STRING_BENEFIT_INFO_COPY + benefitCampaignInfoIndex + "_" + benefitCopy.uid;
          benefitCopy.fields.titleText =
            benefitCampaignInfo?.benefitCampaign?.CMAP_BenefitCampaignName__c;
          benefitCopy.fields.explanationText =
            benefitCampaignInfo?.benefitCampaign?.CMAP_BenefitCampaignAdditionalRule__c;
          setPlanBenefitSelected((planBenefitSelected) => [
            ...planBenefitSelected,
            {
              campaignId: benefitCampaignInfo?.benefitCampaign?.Id,
              benefitProductListSelected: [],
            },
          ]);
          let benefitCopyInputContent = getScDataFromPlaceholder(
            STRING_INPUT_CONTENT,
            benefitCopy
          );
          const [benefitCopyS01] = getScDataFromComponent(
            "S-01",
            benefitCopyInputContent
          );
          benefitCopyS01.fields.titleText = STRING_NOT_APPLIED;
          benefitCopyS01.fields.radioBoxLabel = STRING_NOT_APPLIED;
          benefitCopyS01.fields.inputValue = STRING_NOT_APPLIED;
          benefitCopyS01.fields.groupings =
            benefitCampaignInfo?.benefitCampaign?.Id;
          benefitCopyS01.fields.optionId =
            benefitCampaignInfo?.benefitCampaign?.Id;
          benefitCopyS01.fields.onChangeEvent = (e) => {
            setBenefitCampaignInfoCurrentIndex(benefitCampaignInfoIndex);
            setPlanProductIsLicked((prevState) => !prevState);
            setBenefitProductItems([STRING_NOT_APPLIED]);
            checkIfCompleteSelected(benefitCampaignInfo?.benefitCampaign?.Id);
          };
          
          if(noApplyBenefitSession){
            const checkIfNoApplybenefitCopyS01 = noApplyBenefitSession.length > 0 ? noApplyBenefitSession?.find(x => x === benefitCampaignInfo?.benefitCampaign.Id) : false;
            if(checkIfNoApplybenefitCopyS01){
              benefitCopyS01.fields.checked = true;
            }
          }
          benefitCampaignInfo.benefitGroupNumberInfos.forEach(
            (benefitGroupNumberInfo) => {
              benefitGroupNumberInfo.benefitInfos.forEach(
                (benefitInfo, benefitInfoIndex) => {
                  const benefitInfoCopy = deepcopy(benefit);
                  benefitInfoCopy.uid =
                    STRING_BENEFIT_INFO_COPY +
                    benefitInfoIndex +
                    "_" +
                    benefitInfoCopy.uid;
                  benefitInfoCopy.fields.titleText =
                    benefitInfo?.benefit?.CMAP_BenefitName__c;
                  benefitInfoCopy.fields.explanationText =
                    benefitInfo?.benefitInfo?.CMAP_BenefitContent__c;
                  const benefitInfoCopyInputContent = getScDataFromPlaceholder(
                    STRING_INPUT_CONTENT,
                    benefitInfoCopy
                  );
                  const [benefitInfoCopyS01] = getScDataFromComponent(
                    "S-01",
                    benefitInfoCopyInputContent
                  );
                  benefitInfoCopyS01.fields.titleText =
                    benefitInfo?.benefit?.CMAP_BenefitName__c;
                  benefitInfoCopyS01.fields.radioBoxLabel =
                    benefitInfo?.benefit?.CMAP_BenefitName__c;
                  benefitInfoCopyS01.fields.inputValue =
                    benefitInfo?.benefit?.CMAP_BenefitName__c;
                  benefitInfoCopyS01.fields.optionId = benefitInfo?.benefit?.Id;
                  benefitInfoCopyS01.fields.groupings =
                    benefitCampaignInfo?.benefitCampaign?.Id;
                    if(noApplyBenefitSession){
                      const checkIfNoApplybenefitInfoCopyS01 = noApplyBenefitSession.length > 0 ? noApplyBenefitSession?.find(x => x === benefitCampaignInfo?.benefitCampaign.Id) : false;
                      if(!checkIfNoApplybenefitInfoCopyS01){
                        const checkbenefitInfoCopyS01 = orderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos
                      .find((x) => x.orderItem?.CMAP_BenefitId__c === benefitInfo?.benefit?.Id && x.orderItem?.CMAP_BenefitCampaignId__c === benefitCampaignInfo?.benefitCampaign.Id);
                        if(checkbenefitInfoCopyS01){
                          benefitInfoCopyS01.fields.checked = true;
                        }
                      }
                    }
                  benefitInfoCopyS01.fields.onChangeEvent = (e) => {
                    setBenefitCampaignInfoCurrentIndex(
                      benefitCampaignInfoIndex
                    );
                    setPlanProductIsLicked((prevState) => !prevState);
                    const benefitProductItems = [];
                    checkIfCompleteSelected(benefitCampaignInfo?.benefitCampaign?.Id);
                    benefitInfo.benefitProductList.forEach((benefitProduct) => {
                      const filterBeforeChangeOrderRelatedInfo = beforeChangeOrderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos.find((x) => x.orderItem.CMAP_BenefitId__c === benefitProduct?.product.Id);
                      let productSelectionState = "";
                      let lastTimeChangeCategory = "";
                      if(filterBeforeChangeOrderRelatedInfo){
                        productSelectionState = filterBeforeChangeOrderRelatedInfo.orderItem.CMAP_ProductSelectionState__c;
                        lastTimeChangeCategory = filterBeforeChangeOrderRelatedInfo.orderItem.CMAP_LastTimeChangeCategory__c;
                      }else{
                        productSelectionState = STRING_SELECTED;
                        lastTimeChangeCategory = STRING_ADDED;
                      }
                      benefitProductItems.push({
                        orderItem: {
                          CMAP_OfferPlanProduct__c: null,
                          CMAP_MainSubProductGroupId__c: null, 
                          CMAP_MainSubProductGroupName__c: null, 
                          CMAP_ProductCategoryId__c: null, 
                          CMAP_ProductCategoryName__c: null, 
                          Product2Id: benefitProduct?.product.Id,
                          CMAP_ProductName__c: benefitProduct?.product.Name,
                          UnitPrice: benefitProduct?.pricebookEntry.UnitPrice,
                          CMAP_UnitPriceTaxIncluded__c:
                            benefitProduct?.pricebookEntry
                              .CMAP_UnitPriceTaxIncluded__c,
                          CMAP_ComsumptionTaxRate__c:
                            benefitProduct?.pricebookEntry
                              .CMAP_ConsumptionTaxRate__c,
                          Quantity: 1, 
                          CMAP_PriceCategory__c:
                            benefitProduct?.pricebookEntry
                              .CMAP_PriceCategory__c,
                          CMAP_InstallmentPaymentMonthly__c: null, 
                          CMAP_InstallmentPayMonthlyPriceTaxExc__c: null, 
                          CMAP_InstallmentPayMonthlyPriceTaxInc__c: null, 
                          CMAP_NumberOfInstallments__c: null, 
                          CMAP_InstallmentPayFirstMonthPriceTaxExc__c: null, 
                          CMAP_InstallmentPayFirstMonthPriceTaxInc__c: null, 
                          CMAP_SelectionAnnualPayment__c: null, 
                          CMAP_AnnualPaymentPriceTaxExcluded__c: null, 
                          CMAP_AnnualPaymentPriceTaxIncluded__c: null, 
                          CMAP_Unchargeable__c:
                            benefitProduct?.product.CMAP_IsUnchargeable__c,
                          CMAP_Asset__c: null, 
                          CMAP_ProductSelectionState__c: orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition
                          ?.orderCategoryCurrentlySelected ===
                        STRING_CATEGORY_NEW_CONTRACT
                          ? STRING_SELECTED
                          : productSelectionState,
                          CMAP_LastTimeChangeCategory__c: orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition
                          ?.orderCategoryCurrentlySelected ===
                        STRING_CATEGORY_NEW_CONTRACT
                          ? null
                          : lastTimeChangeCategory, 
                          CMAP_HasOrderItemAttribute__c: false, 
                          CMAP_AvailableDailyPricing__c: false, 
                          CMAP_SetProduct__c: null, 
                          CMAP_BenefitProduct__c:
                            benefitProduct?.benefitProduct.Id,
                          CMAP_BenefitName__c: benefit.CMAP_BenefitName__c,
                          CMAP_GrantUnitPrice__c:
                            benefitProduct?.product.CMAP_GrantUnitPrice__c,
                          CMAP_GrantPoint__c:
                            benefitProduct?.product.CMAP_GrantPoint__c,
                          CMAP_HasPriceChanged__c: false, 
                          CMAP_OrderAutomaticSelectionProduct__c: null, 
                          CMAP_TaxationCategory__c:
                            benefitProduct?.product
                              .CMAP_TaxationCategory__c,
                          CMAP_installmentFlag__c: null, 
                          CMAP_ChangeSourceDestinationCategory__c: null, 
                          CMAP_InheritsMovementAssetAttribute__c: false, 
                          CMAP_ChangeSourceOrderItem__c: null, 
                          CMAP_OrderItemBeforeChange__c: null, 
                          CMAP_OrderItemTypeSelection__c: false, 
                          CMAP_OrderItemTypeBenefit__c: true, 
                          CMAP_OrderItemTypeAutomaticSelection__c: false, 
                          CMAP_MainSubProGroupBenefitCPSortOrder__c:
                            benefitProduct?.benefitProduct
                              .CMAP_BenefitCampaignSortOrder__c,
                          CMAP_ProductCategoryBenefitSortOrder__c:
                            benefitProduct?.benefitProduct
                              .CMAP_BenefitSortOrder__c,
                          CMAP_ProductSortOrder__c:
                            benefitProduct?.product.CMAP_SortOrder__c,
                          CMAP_QuantityValueChange__c: false, 
                          CMAP_PredecessorOfferStartRequest__c: null,
                          CMAP_BenefitId__c: benefitInfo?.benefit?.Id, 
                          CMAP_BenefitCampaignId__c: benefitCampaignInfo?.benefitCampaign?.Id,
                          CMAP_BenefitCampaignName__c: benefitCampaignInfo?.benefitCampaign?.CMAP_BenefitCampaignName__c,
                        },
                        orderItemAddition: {
                          previousLastModifiedDate: null,
                        },
                        orderItemAttributes: null,
                      });
                    });
                    setBenefitProductItems(benefitProductItems);
                  };
                  benefitCopyInputContent.push(benefitInfoCopy);
                }
              );
            }
          );
          const defaultOption = benefitCopyInputContent[0];
          benefitCopyInputContent.splice(0, 1);
          benefitCopyInputContent.splice(
            benefitCopyInputContent.length,
            0,
            defaultOption
          );
          getScDataFromPlaceholder(STRING_PLAN_BENEFITS_SECTION, benefitCopy);
          planBenefitSection.push(benefitCopy);
        }
      );
      planBenefitSection.splice(1, 1);
    }

    if(countSelected === benefitCampaignData.benefitCampaignInfos.length){
      props.setBM05Data("");
      setNoApplyBenefit(noApplyBenefitSession);
    }
  }, []);

  useEffect(() => {
    planBenefitSelected[benefitCampaignInfoCurrentIndex] = {
      ...planBenefitSelected[benefitCampaignInfoCurrentIndex],
      benefitProductListSelected: benefitProductItems,
    };

    const updatedOrderRelatedInfo = { ...orderRelatedInfo };
    const noApplyBenefit = [];
    planBenefitSelected.forEach((selectedBenefit) => {
      if(!selectedBenefit?.benefitProductListSelected.includes(STRING_NOT_APPLIED)){
        const filterBenefitProduct = updatedOrderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos
            .filter(
              (x) =>
                x.orderItem?.CMAP_BenefitCampaignId__c !== selectedBenefit.campaignId
            );
            updatedOrderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos = filterBenefitProduct;
        selectedBenefit.benefitProductListSelected.forEach((selectedBenefitProduct) => 
          updatedOrderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos.push(
            selectedBenefitProduct
          ));
      }else{
        if(selectedBenefit?.benefitProductListSelected.includes(STRING_NOT_APPLIED)){
          noApplyBenefit.push(selectedBenefit.campaignId);
        }
      }
    }); 

    setUpdatedOrderRelatedInfo(updatedOrderRelatedInfo);
    setNoApplyBenefit(noApplyBenefit);

  }, [planProductIsLicked]);

 

  const formButton3 = getScDataFromPlaceholder("form-button-3", props);
  const [b02Data] = getScDataFromComponent("B-02", formButton3);
  b02Data.fields.onClick = () => {
    saveSession();
  };

  if (props.currentSection !== STRING_CURRENT_SECTION_SELECT_BENEFIT) {
    const formHeading3 = getScDataFromPlaceholder("form-heading-3", props);
    const [h02Data] = getScDataFromComponent("H-02", formHeading3);
    h02Data.fields.enteredClass = props.enteredClass;
  }

  const saveSession = () => {
    noApplyBenefit.forEach(benefit => {
      const filterNoApplyBenefit = updatedOrderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos.filter((x) => x.orderItem?.CMAP_BenefitCampaignId__c !==  benefit);
      updatedOrderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos = filterNoApplyBenefit;
  });

    setUpdatedOrderRelatedInfo(orderRelatedInfo);
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_SELECTPRODUCT_NOAPPLYBENEFIT,
      noApplyBenefit,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
      updatedOrderRelatedInfo,
      true
    );
  }
  
  return (
    <React.Fragment>
      {/* SECTION 3 */}
      <div className="form_wrapper">
        <Placeholder name="form-heading-3" rendering={props.rendering} />
        {!props?.isPlanBenefitCompleted  && (
        <>
        <div className="form_wrapper-input">
          <Placeholder
            name="plan-benefits-section"
            rendering={props.rendering}
          />
        </div>

        <div className="btn_container">
          <Placeholder name="form-button-3" rendering={props.rendering} />
        </div>
        </>
        )}
      </div>
    </React.Fragment>
  );
}
