import React, { useRef, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const FT01 = (props) => {
  // @Model
  const footerRef = useRef(null);
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);

  // @Controller
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const {
      innerWidth: windowWidth,
      innerHeight: windowHeight,
      scrollY: scrollTop,
    } = window;
    const { scrollHeight: documentHeight } = document.documentElement;

    let footerPadding = 0;
    const footerEl = footerRef?.current;
    const b05El = document.querySelector(".btn_wrapper.fix");
    if (scrollTop + windowHeight >= documentHeight && windowWidth <= 640) {
      const spDisplayEl = document.querySelector(".sp-display");
      footerPadding = spDisplayEl ? spDisplayEl?.offsetHeight : 0;
    }
    footerPadding = b05El ? footerPadding + b05El?.offsetHeight : footerPadding;
    footerEl.style.paddingBottom = footerPadding + "px";
  };

  // @View
  return (
    <React.Fragment>
      <footer ref={footerRef}>
        <div className="footer FT-01">
          <div className="footer_pos">
            <div className="footer_pos-left">
              {properData && properData["commufaFooter-Icon"]?.value?.src && (
                <img
                  src={
                    properData && properData["commufaFooter-Icon"]?.value?.src
                  }
                  alt="footer_img"
                />
              )}
            </div>
            <div className="footer_pos-right">
              <p>
                {properData &&
                  properData["commufaFooter-Copyright"]?.value}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default FT01;
