import React, { useEffect, useState } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  redirect,
} from "../../utils/commonUtils";
import { ApartmentSearchContext } from "../../assets/context/ApartmentSearchContext";
import { useCustomQuery } from "../../hooks/useGetData";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import { apiBaseURL } from "../../envSettings";
import {
  STRING_APPINFO_APARTMENTSEARCH,
  STRING_GET,
} from "../../utils/constantVariables";
import { CommonServiceUnavailableArea } from "../../services/common/common-service-unavailable-area";

const AreaSearchResultList = (props) => {
  return (
    <>
      <div className="form_wrapper-input">
        <Placeholder
          name="apartment-list-heading-section"
          rendering={props.rendering}
        />
        <div className="description-container">
          <Placeholder
            name="apartment-list-title-section"
            rendering={props.rendering}
          />
        </div>
        <div className="description-container">
          <Placeholder
            name="apartment-list-description-section"
            rendering={props.rendering}
          />
        </div>
        <div className="pagination_wrapper">
          <div className="page-result">
            <Placeholder
              name="apartment-list-pagination-result-section"
              rendering={props.rendering}
            />
          </div>
        </div>
        <Placeholder
          name="apartment-list-bottom-section"
          rendering={props.rendering}
        />
      </div>
      <div className="btn_container">
        <Placeholder name="button-section" rendering={props.rendering} />
      </div>
    </>
  );
};

const ServiceUnavailableArea = (props) => {
  const sitecoreContext = props.sitecoreContext;

  return (
    <>
      <div className="form_wrapper-input">
        <Placeholder
          name="apartment-list-heading-section"
          rendering={props.rendering}
        />
        <CommonServiceUnavailableArea
          screen={STRING_APPINFO_APARTMENTSEARCH}
          rendering={props.rendering}
          sitecoreContext={sitecoreContext}
          setIsLoading={props.setIsLoading}
        />
      </div>
      <div className="btn_container">
        <Placeholder name="button-section" rendering={props.rendering} />
      </div>
    </>
  );
};

const redirectAfterApartmentClicked = (
  props,
  apartmentRoomData,
  isProvidable
) => {
  const IsALCMProperty =
    apartmentRoomData?.everyHouseProperties?.length === 0 ? false : true;
  setSessionStorageItem(
    sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_ISALCMPROPERTY,
    IsALCMProperty,
    true
  );
  if (isProvidable && IsALCMProperty) {
    // LinkForALCMProperty
    redirect(
      props?.sitecoreContext?.route?.fields["ApartmentList-LinkForALCMProperty"]
        ?.value
    );
  } else if (isProvidable && !IsALCMProperty) {
    // LinkForNotALCMProperty
    redirect(
      props?.sitecoreContext?.route?.fields[
        "ApartmentList-LinkForNotALCMProperty"
      ]?.value
    );
  } else {
    // LinkForProvidableArea
    redirect(
      props?.sitecoreContext?.route?.fields[
        "ApartmentList-LinkForProvidableArea"
      ]?.value
    );
  }
};

const ApartmentSearchPageLayout = (props) => {
  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  const addressCode = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_SELECTPLANTYPE_ADDRESSCODE,
    true
  );
  const orderChannelCategory = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  )?.AppChannelDivision || "Web";

  const [apartmentSearchContext, setApartmentSearchContext] = useState(true);
  const [searchResultCount, setSearchResultCount] = useState("0");
  const [totalItemCount, setTotalItemCount] = useState("0");
  const [apartmentRoomId, setApartmentRoomId] = useState("");
  const [
    isApartmentOfferMethodListEmpty,
    setIsApartmentOfferMethodListEmpty,
  ] = useState(false);

  // get the data
  const retrieveApartmentOfferMethodListURI = `${apiBaseURL}order/RetrieveApartmentOfferMethodList?addressCode=${addressCode}&orderChannelCategory=${orderChannelCategory}`;
  const retrieveApartmentRoomNoListURI = `${apiBaseURL}order/RetrieveApartmentRoomNoList?Id=${apartmentRoomId}`;

  const {
    data: retrieveApartmentOfferMethodListData,
    isLoading: retrieveApartmentOfferMethodListIsLoading,
    error: retrieveApartmentOfferMethodListError,
    refetch: retrieveApartmentOfferMethodListRefresh,
    remove: retrieveApartmentOfferMethodListRemove,
  } = useCustomQuery(retrieveApartmentOfferMethodListURI, true, STRING_GET);

  const {
    data: retrieveApartmentRoomNoListData,
    isLoading: retrieveApartmentRoomNoListIsLoading,
    error: retrieveApartmentRoomNoListError,
    refetch: retrieveApartmentRoomNoListRefresh,
    remove: retrieveApartmentRoomNoListRemove,
  } = useCustomQuery(retrieveApartmentRoomNoListURI, false, STRING_GET);

  const isApiLoading = retrieveApartmentOfferMethodListIsLoading || retrieveApartmentRoomNoListIsLoading

  props.setIsLoading(isApiLoading)

  // 検索地域の表示変更
  let address = getSessionStorageItem(
    sessionKeys.SSN_SELECT_APPLICATION_SELECTPLANTYPE_INSTALLATIONLOCATIONADDRESS,
    true
  );
  let titleSectionPlaceholder = getScDataFromPlaceholder(
    "apartment-list-title-section",
    props
  );
  let titleSectionSearchAddress = getScDataFromComponent(
    "D-02",
    titleSectionPlaceholder
  );
  titleSectionSearchAddress[0].fields.descriptionText.value = address;

  //検索結果の件数変更
  let searchResultCntPlaceholder = getScDataFromPlaceholder(
    "apartment-list-pagination-result-section",
    props
  );
  let searchResultCntElm = getScDataFromComponent(
    "D-02",
    searchResultCntPlaceholder
  );

  let headerSectionPlaceholder = getScDataFromPlaceholder(
    "apartment-list-heading-section",
    props
  );

  const [resultTitleMessage] = getScDataFromComponent(
    "D-01",
    headerSectionPlaceholder
  );
  // show AreaSearchResultTitle-Message When the search result is 0 after pressing the "絞込" button in 地域選択一覧 (AreaSearchResultList) otherwise, hide.
  resultTitleMessage.fields.isHidden = {
    value: !isApartmentOfferMethodListEmpty,
  };

  searchResultCntElm[0].fields.descriptionText.value = searchResultCount
    ? searchResultCount
    : 0;

  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  const orderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );

  let isProvidable = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_ISPROVIDABLE,
    true
  );

  const installationLocationAddress = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_SELECTPLANTYPE_INSTALLATIONLOCATIONADDRESS,
    true
  );

  const selectRow = getScDataFromPlaceholder(
    "apartment-list-bottom-section",
    props
  );
  let pl01Data = getScDataFromComponent("PL-01", selectRow);

  pl01Data[0].fields.pagePrevious =
    sitecoreContext.route.fields["ApartmentList-Pagination-Previous"].value;
  pl01Data[0].fields.pageNext =
    sitecoreContext.route.fields["ApartmentList-Pagination-Next"].value;

  pl01Data[0].fields.dataRowList = retrieveApartmentOfferMethodListData?.propertyOfferMethodInfo?.propertyOfferMethods.map(
    (item) => {
      return {
        ProvisionID: item.Id,
        PropertyName: item.CMAP_PropertyName__c,
        Address: item.CMAP_Address__c,
        Providable: item.CMAP_IsOfferPossible__c,
        OfferState: item.CMAP_OfferStatusForScreen__c,
        OfferMethodName: item.CMAP_OfferFormulaName__c,
        Remark: item.CMAP_Comment__c,
        ApartmentHome: item.CMAP_IsApartmentHome__c,
        ApartmentStreetBunch: item.CMAP_StreetBunch__c,
      };
    }
  );

  pl01Data[0].fields.onClickEvent = (e, roomId) => {
    setApartmentRoomId(roomId);

    const newOrderRelatedInfo = {
      ...orderRelatedInfo,
      orderRelatedInfo: {
        ...orderRelatedInfo?.orderRelatedInfo,
        orderInfo: {
          ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
          order: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
            CMAP_PropertyOfferMethod__c: e.provisionid,
          },
        },
      },
    };

    setSessionStorageItem(
      sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
      newOrderRelatedInfo,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_APARTMENTNAME,
      e.propertyname,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_APARTMENTADDRESS,
      e.address,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_ISPROVIDABLE,
      e.providable,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_PROVISIONSTATUS,
      e.offerstate,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_PROVISIONMETHOD,
      e.offermethodname,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_NOTE,
      e.remark,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_ISAPARTMENTHOME,
      e.apartmenthome,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_APARTMENTSEARCH_APARTMENTSTREETBUNCH,
      e.apartmentstreetbunch,
      true
    );
  };

  const apartmentListTitleSection = getScDataFromPlaceholder(
    "apartment-list-title-section",
    props
  );
  let d02 = getScDataFromComponent("D-02", apartmentListTitleSection);
  d02[0].fields.descriptionText.value = installationLocationAddress;

  const buttonSection = getScDataFromPlaceholder("button-section", props);
  const [backButton] = getScDataFromComponent("B-02", buttonSection);
  backButton.fields.onClickEvent = () => {
    redirectToPreviousPage();
  };

  useEffect(() => {
    if (
      retrieveApartmentOfferMethodListData?.propertyOfferMethodInfo
        ?.propertyOfferMethods
    ) {
      setTotalItemCount(
        retrieveApartmentOfferMethodListData?.propertyOfferMethodInfo?.totalItemNumber?.toLocaleString()
      );
      setSearchResultCount(
        retrieveApartmentOfferMethodListData?.propertyOfferMethodInfo?.propertyOfferMethods?.length?.toLocaleString()
      );
    }
  }, [retrieveApartmentOfferMethodListData]);

  // @Controller
  useEffect(() => {
    if (apartmentRoomId && apartmentRoomId != "") {
      retrieveApartmentRoomNoListRefresh();
    }
  }, [apartmentRoomId]);

  useEffect(() => {
    if (retrieveApartmentRoomNoListData) {
      redirectAfterApartmentClicked(
        props,
        retrieveApartmentRoomNoListData,
        isProvidable
      );
    }
  }, [retrieveApartmentRoomNoListData]);
  useEffect(() => {
    if (
      !retrieveApartmentOfferMethodListData ||
      retrieveApartmentOfferMethodListData.propertyOfferMethodInfo
        ?.propertyOfferMethods?.length === 0 ||
      retrieveApartmentOfferMethodListData?.propertyOfferMethodInfo
        ?.totalItemNumber === 0
    ) {
      setIsApartmentOfferMethodListEmpty(true);
    } else {
      setIsApartmentOfferMethodListEmpty(false);
    }
  }, [retrieveApartmentOfferMethodListData]);

  // @View
  return (
    <React.Fragment>
      <ApartmentSearchContext.Provider
        value={{
          apartmentSearchContext,
          setApartmentSearchContext,
          setSearchResultCount,
          totalItemCount,
        }}
      >
        <main>
          <div className="form_container">
            <div className="form_detail full-width">
              <div className="form_wrapper">
                {isApartmentOfferMethodListEmpty &&
                !retrieveApartmentOfferMethodListIsLoading ? (
                  <ServiceUnavailableArea
                    rendering={props.rendering}
                    sitecoreContext={sitecoreContext}
                    setIsLoading={props.setIsLoading}
                  />
                ) : (
                  <AreaSearchResultList rendering={props.rendering} />
                )}
              </div>
            </div>
          </div>
        </main>
      </ApartmentSearchContext.Provider>
    </React.Fragment>
  );
};

export default withSitecoreContext()(ApartmentSearchPageLayout);
