import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import CommonNotificationInformation from "./common-notification-information";
import { ApplicationInformationList } from "./common-application-information-list";
import {
  STRING_APPINFO_APARTMENTSEARCH,
  STRING_APPINFO_PLANTYPE,
} from "../../utils/constantVariables";

export const CommonServiceUnavailableArea = (props) => {
  const {
    screen: screen,
    isAreaNotificationInformationListHidden: isAreaNotificationInformationListHidden,
    isAreaApplicationInformationListHidden = {
      isAreaApplicationInformationListHidden,
    },
  } = props;

  return (
    <>
      <div className="additional-information">
        <Placeholder
          name="service-outside-area-upper-section"
          rendering={props.rendering}
        />
        <div className="btn_container">
          <Placeholder
            name="area-notification-request-button-section"
            rendering={props.rendering}
          />
        </div>
        <Placeholder
          name="request-from-line-app-text-section"
          rendering={props.rendering}
        />
        {screen === STRING_APPINFO_PLANTYPE ? (
          !isAreaNotificationInformationListHidden ? (
            <CommonNotificationInformation rendering={props.rendering} />
          ) : (
            ""
          )
        ) : (
          <CommonNotificationInformation rendering={props.rendering} />
        )}
        {screen === STRING_APPINFO_PLANTYPE ? (
          !isAreaApplicationInformationListHidden ? (
            <ApplicationInformationList
              rendering={props.rendering}
              screen={screen}
              setIsLoading={props.setIsLoading}
            />
          ) : (
            ""
          )
        ) : (
          <ApplicationInformationList
            rendering={props.rendering}
            screen={screen}
            setIsLoading={props.setIsLoading}
          />
        )}
      </div>
      {screen !== STRING_APPINFO_PLANTYPE &&
      screen !== STRING_APPINFO_APARTMENTSEARCH ? (
        <div className="btn_container">
          <Placeholder
            name="back-to-area-check-button-section"
            rendering={props.rendering}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};
