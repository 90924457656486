import React, { useState, useEffect, useMemo } from "react";
import {
  Placeholder,
  Text,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import _ from "lodash";
import { format } from "date-fns";
import { di_register } from "../../utils/di";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import {
  setSessionStorageItem,
  getSessionStorageItem,
} from "../../utils/useSessionStorage";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
  fillBlank,
  formatCurrencyChar,
  redirect,
  showPrintDialog,
} from "../../utils/commonUtils";
import {
  STRING_CATEGORY_PLAN_CHANGE,
  STRING_CATEGORY_PRODUCT_CHANGE,
  STRING_STORE,
  STRING_USERAGENT_CPAD_APP,
  STRING_DESELECTED,
  STRING_SUSPENDSELECTED,
} from "../../utils/constantVariables";

import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";

import {
  createAnnualExpenseObj,
  createBenefitObj,
  createInitialCostObj,
  createMonthlyExpenseObj,
  createRemainingDebtObj,
} from "../../services/calculations/C-Pad-Estimation-Result-Plan-Product-Change/calculateC-PadEstimationResultPlanProductChangeResult";
import { groupByGroupAndCat } from "../../services/calculations/Common/CommonProcess";

// TODO: delete test data

const mapThreePrices = (item) => {
  return {
    beforeCol: formatCurrencyChar(item.BeforeMonthlyPriceTaxInc),
    offeringCPCol: formatCurrencyChar(item.AfterMonthlyPriceOfferingCPTaxInc),
    finishedCPCol: formatCurrencyChar(item.AfterMonthlyPriceFinishedCPTaxInc),
    isTotal: false,
  };
};

const mapTwoPrices = (item) => {
  return {
    beforeCol: formatCurrencyChar(item.BeforePriceTaxInc),
    afterCol: formatCurrencyChar(item.AfterPriceTaxInc),
    isTotal: false,
  };
};

const buildGroupCatProductPricesRowData = (
  groupedChangeDetailsObj,
  mapPrices
) => {
  const rowData = [];
  for (const groupKey of Object.keys(groupedChangeDetailsObj)) {
    const group = groupedChangeDetailsObj[groupKey];
    let groupLength = 0;
    for (const catKey of Object.keys(group)) {
      const cat = group[catKey];
      groupLength = groupLength + cat.length;
    }
    let isNewGroup = true;

    for (const catKey of Object.keys(group)) {
      const cat = group[catKey];
      const catLength = Object.keys(cat).length;
      let isNewCat = true;

      cat.forEach((item) => {
        const breakdown = [];
        if (isNewGroup) {
          breakdown.push({
            rowSpan: groupLength,
            value: item.CMAP_MainSubProductGroupName__c,
          });
          isNewGroup = false;
        }

        if (isNewCat) {
          breakdown.push({
            rowSpan: catLength,
            value: item.CMAP_ProductCategoryName__c,
          });
          isNewCat = false;
        }

        breakdown.push({
          value: item.CMAP_ProductName__c,
        });

        rowData.push({
          ...mapPrices(item),
          breakdown,
        });
      });
    }
  }

  return rowData;
};

const buildGroupProductPricesRowData = (detailsObj, groupName, mapPrices) => {
  const rowData = [];

  const groupLength = detailsObj.length;
  let isNewGroup = true;

  detailsObj.forEach((item) => {
    const breakdown = [];
    if (isNewGroup) {
      breakdown.push({
        rowSpan: groupLength,
        colSpan: 2,
        value: groupName,
      });
      isNewGroup = false;
    }

    breakdown.push({
      value: item.CMAP_ProductName__c,
    });

    rowData.push({
      ...mapPrices(item),
      breakdown,
    });
  });

  return rowData;
};

const TextCellFormatter = ({ row, value }) => {
  let className = "txt_left";
  if (row.isTotal) {
    className = "txt_right";
  }

  return (
    <Text
      field={fillBlank(value)}
      encode={false}
      tag="p"
      className={`${className} full-width`}
    />
  );
};

const PriceCellFormatter = ({ value }) => {
  return (
    <Text
      field={fillBlank(value)}
      encode={false}
      tag="p"
      className="txt_right full-width"
    />
  );
};

const orderRelatedInfo = getSessionStorageItem(
  keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
  true
);

const personalizeInfo = getSessionStorageItem(
  keys.SSN_COMMON_COMMON_PERSONALIZEINFO,
  true
);

const { BenefitListForAgency: benefitListForAgency } = getSessionStorageItem(
  keys.SSN_APPLICATION_SELECTPRODUCT_BENEFITLISTFORAGENCY,
  true
);

const accountName = getSessionStorageItem(
  keys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ACCOUNTNAME,
  true
);
const storeName = getSessionStorageItem(
  keys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STORENAME,
  true
);
const descriptionDate = getSessionStorageItem(
  keys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_DESCRIPTIONDATE,
  true
);
const micNo = getSessionStorageItem(
  keys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_MICNO,
  true
);
const estimateDueDate = getSessionStorageItem(
  keys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ESTIMATEDUEDATE,
  keys
);
const staffName = getSessionStorageItem(
  keys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STAFFNAME,
  true
);
const phone = getSessionStorageItem(
  keys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_PHONE,
  true
);

const PlanEstimationSection = (props) => {
  // @Model
  const orderCategory =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_OrderCategory__c;
  const changeSourceOfferPlanName =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
      ?.CMAP_ChangeSourceOfferPlanName__c;
  const offerPlanName =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_OfferPlanName__c;

  const planSectionHolder = getScDataFromPlaceholder("plan-section", props);

  const [
    c10PlanDetailData,
    c10BeforeDetailData,
    c10AfterDetailData,
  ] = getScDataFromComponent("C-10", planSectionHolder);

  // @Controller
  useEffect(() => {
    c10PlanDetailData.fields.detailsText = offerPlanName;
    c10BeforeDetailData.fields.isHidden =
      orderCategory === STRING_CATEGORY_PRODUCT_CHANGE ? true : false;
    c10AfterDetailData.fields.isHidden =
      orderCategory === STRING_CATEGORY_PRODUCT_CHANGE ? true : false;

    c10BeforeDetailData.fields.detailsText = changeSourceOfferPlanName;
    c10AfterDetailData.fields.detailsText = offerPlanName;
    c10PlanDetailData.fields.hideDetailsText =
      orderCategory === STRING_CATEGORY_PLAN_CHANGE ? true : false;

    c10PlanDetailData.fields.hideDetailsText
      ? (c10AfterDetailData.fields.additionalClass.value = "mb-24")
      : (c10PlanDetailData.fields.additionalClass.value = "mb-24");
  }, [orderCategory]);

  // @View
  return (
    <>
      <Placeholder name="plan-section" rendering={props.rendering} />
    </>
  );
};

const MonthlyFeeEstimationSection = (props) => {
  const {
    "PlanProductChangeEstimation-ItemsCol": itemsCol,
    "PlanProductChangeEstimation-BeforeCol": beforeCol,
    "PlanProductChangeEstimation-AfterCol": afterCol,
    "PlanProductChangeEstimation-OfferingCPCol": offeringCPCol,
    "PlanProductChangeEstimation-FinishedCPCol": finishedCPCol,
    "PlanProductChangeEstimation-AutomaticSelectionProduct": automaticSelectionProduct,
    "PlanProductChangeEstimation-BenefitProduct": benefitProduct,
    "PlanProductChangeEstimation-TotalPriceWithTax": totalPriceWithTax,
    "PlanProductChangeEstimation-Tax": tax,
  } = props?.sitecoreContext?.route?.fields;

  const monthlyFeeSectionHolder = getScDataFromPlaceholder(
    "monthly-fee-section",
    props
  );

  const [tb01MonthlyFeeTableData] = getScDataFromComponent(
    "TB-01",
    monthlyFeeSectionHolder
  );

  const monthlyColumData = [
    {
      key: "breakdown",
      header: itemsCol,
      colSpan: 3,
      rowSpan: 2,
      width: "60%",
      Cell: TextCellFormatter,
    },
    {
      key: "beforeCol",
      header: beforeCol,
      rowSpan: 2,
      Cell: PriceCellFormatter,
    },
    {
      header: afterCol,
      colSpan: 2,
      children: [
        {
          key: "offeringCPCol",
          header: offeringCPCol,
          Cell: PriceCellFormatter,
        },
        {
          key: "finishedCPCol",
          header: finishedCPCol,
          Cell: PriceCellFormatter,
        },
      ],
    },
  ];

  // TODO: delete test data

  const monthlyFeeRowData = useMemo(() => {
    const monthlyExpenseObj = createMonthlyExpenseObj();
    let rowData = [];

    if (monthlyExpenseObj.subtotalObj.length !== 0) {
      // 選択商品
      const selectedItems = monthlyExpenseObj.subtotalObj.filter(
        (x) => x.CMAP_OrderItemTypeSelection__c
      );
      const groupedSelectedItems = groupByGroupAndCat(selectedItems);
      const selectedItemsRowData = buildGroupCatProductPricesRowData(
        groupedSelectedItems,
        mapThreePrices
      );
      rowData = rowData.concat(selectedItemsRowData);

      // 自動選択商品
      const automaticSelectedItems = monthlyExpenseObj.subtotalObj.filter(
        (x) => x.CMAP_OrderItemTypeAutomaticSelection__c
      );
      const automaticSelectedItemsRowData = buildGroupProductPricesRowData(
        automaticSelectedItems,
        automaticSelectionProduct,
        mapThreePrices
      );
      rowData = rowData.concat(automaticSelectedItemsRowData);

      // 特典商品
      const benefitSelectedItems = monthlyExpenseObj.subtotalObj.filter(
        (x) => x.CMAP_OrderItemTypeBenefit__c
      );
      const benefitSelectedItemsRowData = buildGroupProductPricesRowData(
        benefitSelectedItems,
        benefitProduct,
        mapThreePrices
      );
      rowData = rowData.concat(benefitSelectedItemsRowData);

      // total
      const monthlyFeeTotalRowData = [
        {
          breakdown: {
            colSpan: 3,
            value: `${totalPriceWithTax?.value} <br> ${tax?.value}`,
          },
          beforeCol: `${formatCurrencyChar(
            monthlyExpenseObj.totalObj.BeforeMonthlyPriceTaxInc
          )} <br /> ${formatCurrencyChar(
            monthlyExpenseObj.totalObj.BeforeMonthlyPriceTax
          )}`,
          offeringCPCol: `${formatCurrencyChar(
            monthlyExpenseObj.totalObj.AfterMonthlyPriceOfferingCPTaxInc
          )} <br /> ${formatCurrencyChar(
            monthlyExpenseObj.totalObj.AfterMonthlyPriceOfferingCPTax
          )}`,
          finishedCPCol: `${formatCurrencyChar(
            monthlyExpenseObj.totalObj.AfterMonthlyPriceFinishedCPTaxInc
          )} <br /> ${formatCurrencyChar(
            monthlyExpenseObj.totalObj.AfterMonthlyPriceFinishedCPTax
          )}`,
          isTotal: true,
        },
      ];
      rowData = rowData.concat(monthlyFeeTotalRowData);
    }

    return rowData;
  }, []);

  tb01MonthlyFeeTableData.fields.columnData = monthlyColumData;
  tb01MonthlyFeeTableData.fields.rowData = monthlyFeeRowData;

  const [isMonthlyFeeHidden, setIsMonthlyFeeHidden] = useState(false);
  useEffect(() => {
    setIsMonthlyFeeHidden(monthlyFeeRowData.length === 0);
  }, []);

  // @View
  return (
    <>
      {!isMonthlyFeeHidden && (
        <Placeholder name="monthly-fee-section" rendering={props.rendering} />
      )}
    </>
  );
};

const AnnualFeeEstimationSection = (props) => {
  const {
    "PlanProductChangeEstimation-ItemsCol": itemsCol,
    "PlanProductChangeEstimation-BeforeCol": beforeCol,
    "PlanProductChangeEstimation-AfterCol": afterCol,
    "PlanProductChangeEstimation-AutomaticSelectionProduct": automaticSelectionProduct,
    "PlanProductChangeEstimation-BenefitProduct": benefitProduct,
    "PlanProductChangeEstimation-TotalPriceWithTax": totalPriceWithTax,
    "PlanProductChangeEstimation-Tax": tax,
  } = props?.sitecoreContext?.route?.fields;

  const annualFeeSectionHolder = getScDataFromPlaceholder(
    "annual-fee-section",
    props
  );

  const [tb01AnnualFeeTableData] = getScDataFromComponent(
    "TB-01",
    annualFeeSectionHolder
  );

  const annualFeeColumData = [
    {
      key: "breakdown",
      header: itemsCol,
      rowSpan: 1,
      colSpan: 3,
      Cell: TextCellFormatter,
    },
    {
      key: "beforeCol",
      header: beforeCol,
      rowSpan: 1,
      Cell: PriceCellFormatter,
    },
    {
      key: "afterCol",
      header: afterCol,
      rowSpan: 1,
      Cell: PriceCellFormatter,
    },
  ];

  // TODO: delete test data

  const annualExpenseRowData = useMemo(() => {
    const annualExpenseObj = createAnnualExpenseObj();
    let rowData = [];

    if (annualExpenseObj.subtotalObj.length !== 0) {
      // 選択商品
      const selectedItems = annualExpenseObj.subtotalObj.filter(
        (x) => x.CMAP_OrderItemTypeSelection__c
      );
      const groupedSelectedItems = groupByGroupAndCat(selectedItems);
      const selectedItemsRowData = buildGroupCatProductPricesRowData(
        groupedSelectedItems,
        mapTwoPrices
      );
      rowData = rowData.concat(selectedItemsRowData);

      // 自動選択商品
      const automaticSelectedItems = annualExpenseObj.subtotalObj.filter(
        (x) => x.CMAP_OrderItemTypeAutomaticSelection__c
      );
      const automaticSelectedItemsRowData = buildGroupProductPricesRowData(
        automaticSelectedItems,
        automaticSelectionProduct,
        mapTwoPrices
      );
      rowData = rowData.concat(automaticSelectedItemsRowData);

      // 特典商品
      const benefitSelectedItems = annualExpenseObj.subtotalObj.filter(
        (x) => x.CMAP_OrderItemTypeBenefit__c
      );
      const benefitSelectedItemsRowData = buildGroupProductPricesRowData(
        benefitSelectedItems,
        benefitProduct,
        mapTwoPrices
      );
      rowData = rowData.concat(benefitSelectedItemsRowData);

      // total
      const annualExpenseTotalRowData = [
        {
          breakdown: {
            colSpan: 3,
            value: `${totalPriceWithTax?.value} <br> ${tax?.value}`,
          },
          beforeCol: `${formatCurrencyChar(
            annualExpenseObj.totalObj.BeforePriceTaxInc
          )} <br /> ${formatCurrencyChar(
            annualExpenseObj.totalObj.BeforePriceTax
          )}`,
          afterCol: `${formatCurrencyChar(
            annualExpenseObj.totalObj.AfterPriceTaxInc
          )} <br /> ${formatCurrencyChar(
            annualExpenseObj.totalObj.AfterPriceTax
          )}`,
          isTotal: true,
        },
      ];
      rowData = rowData.concat(annualExpenseTotalRowData);
    }

    return rowData;
  }, []);

  tb01AnnualFeeTableData.fields.columnData = annualFeeColumData;
  tb01AnnualFeeTableData.fields.rowData = annualExpenseRowData;

  const [isAnnualFeeHidden, setIsAnnualFeeHidden] = useState(false);
  useEffect(() => {
    setIsAnnualFeeHidden(annualExpenseRowData.length === 0);
  }, []);

  // @View
  return (
    <>
      {!isAnnualFeeHidden && (
        <Placeholder name="annual-fee-section" rendering={props.rendering} />
      )}
    </>
  );
};

const InitialCostEstimationSection = (props) => {
  const {
    "PlanProductChangeEstimation-ItemsCol": itemsCol,
    "PlanProductChangeEstimation-BeforeCol": beforeCol,
    "PlanProductChangeEstimation-AfterCol": afterCol,
    "PlanProductChangeEstimation-AutomaticSelectionProduct": automaticSelectionProduct,
    "PlanProductChangeEstimation-BenefitProduct": benefitProduct,
    "PlanProductChangeEstimation-TotalPriceWithTax": totalPriceWithTax,
    "PlanProductChangeEstimation-Tax": tax,
  } = props?.sitecoreContext?.route?.fields;

  const initialCostSectionHolder = getScDataFromPlaceholder(
    "initial-cost-section",
    props
  );

  const [tb01InitialCostTableData] = getScDataFromComponent(
    "TB-01",
    initialCostSectionHolder
  );

  const initialCostColumData = [
    {
      key: "breakdown",
      header: itemsCol,
      rowSpan: 1,
      colSpan: 3,
      Cell: TextCellFormatter,
    },
    {
      key: "beforeCol",
      header: beforeCol,
      rowSpan: 1,
      Cell: PriceCellFormatter,
    },
    {
      key: "afterCol",
      header: afterCol,
      rowSpan: 1,
      Cell: PriceCellFormatter,
    },
  ];

  // TODO: delete test data

  const initialCostRowData = useMemo(() => {
    const initialCostObj = createInitialCostObj();
    let rowData = [];

    if (initialCostObj.subtotalObj.length !== 0) {
      // 選択商品
      const selectedItems = initialCostObj.subtotalObj.filter(
        (x) => x.CMAP_OrderItemTypeSelection__c
      );
      const groupedSelectedItems = groupByGroupAndCat(selectedItems);
      const selectedItemsRowData = buildGroupCatProductPricesRowData(
        groupedSelectedItems,
        mapTwoPrices
      );
      rowData = rowData.concat(selectedItemsRowData);

      // 自動選択商品
      const automaticSelectedItems = initialCostObj.subtotalObj.filter(
        (x) => x.CMAP_OrderItemTypeAutomaticSelection__c
      );
      const automaticSelectedItemsRowData = buildGroupProductPricesRowData(
        automaticSelectedItems,
        automaticSelectionProduct,
        mapTwoPrices
      );
      rowData = rowData.concat(automaticSelectedItemsRowData);

      // 特典商品
      const benefitSelectedItems = initialCostObj.subtotalObj.filter(
        (x) => x.CMAP_OrderItemTypeBenefit__c
      );
      const benefitSelectedItemsRowData = buildGroupProductPricesRowData(
        benefitSelectedItems,
        benefitProduct,
        mapTwoPrices
      );
      rowData = rowData.concat(benefitSelectedItemsRowData);

      // total
      const initialCostTotalRowData = [
        {
          breakdown: {
            colSpan: 3,
            value: `${totalPriceWithTax?.value} <br> ${tax?.value}`,
          },
          beforeCol: `${formatCurrencyChar(
            initialCostObj.totalObj.BeforePriceTaxInc
          )} <br /> ${formatCurrencyChar(
            initialCostObj.totalObj.BeforePriceTax
          )}`,
          afterCol: `${formatCurrencyChar(
            initialCostObj.totalObj.AfterPriceTaxInc
          )} <br /> ${formatCurrencyChar(
            initialCostObj.totalObj.AfterPriceTax
          )}`,
          isTotal: true,
        },
      ];
      rowData = rowData.concat(initialCostTotalRowData);
    }

    return rowData;
  }, []);

  tb01InitialCostTableData.fields.columnData = initialCostColumData;
  tb01InitialCostTableData.fields.rowData = initialCostRowData;

  const [isInitialCostHidden, setIsInitialCostHidden] = useState(false);
  useEffect(() => {
    setIsInitialCostHidden(initialCostRowData.length === 0);
  }, []);

  // @View
  return (
    <>
      {!isInitialCostHidden && (
        <Placeholder name="initial-cost-section" rendering={props.rendering} />
      )}
    </>
  );
};

const RemainingDebtEstimationSection = (props) => {
  const {
    "PlanProductChangeEstimation-ItemsCol": itemsCol,
    "PlanProductChangeEstimation-BeforeCol": beforeCol,
    "PlanProductChangeEstimation-AfterCol": afterCol,
    "PlanProductChangeEstimation-AutomaticSelectionProduct": automaticSelectionProduct,
    "PlanProductChangeEstimation-BenefitProduct": benefitProduct,
    "PlanProductChangeEstimation-TotalPriceWithTax": totalPriceWithTax,
    "PlanProductChangeEstimation-Tax": tax,
  } = props?.sitecoreContext?.route?.fields;

  const debtSectionHolder = getScDataFromPlaceholder(
    "remaining-debt-section",
    props
  );

  const [tb01RemainingDebtTableData] = getScDataFromComponent(
    "TB-01",
    debtSectionHolder
  );

  const remainingDebtColumData = [
    {
      key: "breakdown",
      header: itemsCol,
      rowSpan: 1,
      colSpan: 3,
      Cell: TextCellFormatter,
    },
    {
      key: "beforeCol",
      header: beforeCol,
      rowSpan: 1,
      Cell: PriceCellFormatter,
    },
    {
      key: "afterCol",
      header: afterCol,
      rowSpan: 1,
      Cell: PriceCellFormatter,
    },
  ];

  // TODO: delete test data

  const remainingDebtRowData = useMemo(() => {
    const remainingDebtObj = createRemainingDebtObj();
    let rowData = [];

    if (remainingDebtObj.subtotalObj.length !== 0) {
      // 選択商品
      const selectedItems = remainingDebtObj.subtotalObj.filter(
        (x) => x.CMAP_OrderItemTypeSelection__c
      );
      const groupedSelectedItems = groupByGroupAndCat(selectedItems);
      const selectedItemsRowData = buildGroupCatProductPricesRowData(
        groupedSelectedItems,
        mapTwoPrices
      );
      rowData = rowData.concat(selectedItemsRowData);

      // 自動選択商品
      const automaticSelectedItems = remainingDebtObj.subtotalObj.filter(
        (x) => x.CMAP_OrderItemTypeAutomaticSelection__c
      );
      const automaticSelectedItemsRowData = buildGroupProductPricesRowData(
        automaticSelectedItems,
        automaticSelectionProduct,
        mapTwoPrices
      );
      rowData = rowData.concat(automaticSelectedItemsRowData);

      // 特典商品
      const benefitSelectedItems = remainingDebtObj.subtotalObj.filter(
        (x) => x.CMAP_OrderItemTypeBenefit__c
      );
      const benefitSelectedItemsRowData = buildGroupProductPricesRowData(
        benefitSelectedItems,
        benefitProduct,
        mapTwoPrices
      );
      rowData = rowData.concat(benefitSelectedItemsRowData);

      // total
      const remainingDebtTotalRowData = [
        {
          breakdown: {
            colSpan: 3,
            value: `${totalPriceWithTax?.value} <br> ${tax?.value}`,
          },
          beforeCol: `${formatCurrencyChar(
            remainingDebtObj.totalObj.BeforePriceTaxInc
          )} <br /> ${formatCurrencyChar(
            remainingDebtObj.totalObj.BeforePriceTax
          )}`,
          afterCol: `${formatCurrencyChar(
            remainingDebtObj.totalObj.AfterPriceTaxInc
          )} <br /> ${formatCurrencyChar(
            remainingDebtObj.totalObj.AfterPriceTax
          )}`,
          isTotal: true,
        },
      ];
      rowData = rowData.concat(remainingDebtTotalRowData);
    }

    return rowData;
  }, []);

  tb01RemainingDebtTableData.fields.columnData = remainingDebtColumData;
  tb01RemainingDebtTableData.fields.rowData = remainingDebtRowData;

  const [isRemainingDebtHidden, setIsRemainingDebtHidden] = useState(false);
  useEffect(() => {
    setIsRemainingDebtHidden(remainingDebtRowData.length === 0);
  }, []);

  // @View
  return (
    <>
      {!isRemainingDebtHidden && (
        <Placeholder name="remaining-debt-section" rendering={props.rendering} />
      )}
    </>
  );
};

const CampaignBenefitsEstimationSection = (props) => {
  const {
    "PlanProductChangeEstimation-ItemsCol": itemsCol,
  } = props?.sitecoreContext?.route?.fields;

  const campaignBenefitsSectionHolder = getScDataFromPlaceholder(
    "campaign-benefits-section",
    props
  );

  const [tb01CampaignBenefitsTableData] = getScDataFromComponent(
    "TB-01",
    campaignBenefitsSectionHolder
  );

  const benefitColumData = [
    {
      key: "breakdown",
      header: itemsCol,
      rowSpan: 1,
      Cell: TextCellFormatter,
    },
  ];

  const orderItemInfo = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos;
  const hideCampaignBenefit =
    !orderItemInfo?.some(
      ({ orderItem: item }) =>
        item.CMAP_OrderItemTypeBenefit__c &&
        item.CMAP_ProductSelectionState__c !== STRING_DESELECTED &&
        item.CMAP_ProductSelectionState__c !== STRING_SUSPENDSELECTED
    ) && benefitListForAgency?.length === 0;

  // TODO: delete test data

  const benefitRowData = useMemo(() => {
    // 代理店特典内訳リスト
    const benefitListForAgencyBreakdownList =
      benefitListForAgency?.map((item) => ({
        breakdown: `${item.BenefitName} ${
          item.BenefitPrice ? formatCurrencyChar(item.BenefitPrice) : ""
        }`,
      })) ?? [];

    // プラン特典内訳リスト
    const benefitObj = createBenefitObj();
    const planBenefitListBreakdownList =
      benefitObj.map((item) => ({
        breakdown: item.CMAP_BenefitName__c,
      })) ?? [];

    return [
      ...benefitListForAgencyBreakdownList,
      ...planBenefitListBreakdownList,
    ];
  }, []);

  tb01CampaignBenefitsTableData.fields.columnData = benefitColumData;
  tb01CampaignBenefitsTableData.fields.rowData = benefitRowData;

  const [isBenefitHidden, setIsBenefitHidden] = useState(false);
  useEffect(() => {
    setIsBenefitHidden(hideCampaignBenefit);
  }, []);

  // @View
  return (
    <>
      {!isBenefitHidden && (
        <Placeholder
          name="campaign-benefits-section"
          rendering={props.rendering}
        />
      )}
    </>
  );
};

const AgencyInfoSection = (props) => {
  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  const {
    "PlanProductChangeEstimationResultTransitionButtonArea-NextButtonLink": nextButtonLink,
  } = props?.sitecoreContext?.route?.fields;

  // @Model
  const [inputValues, setInputValues] = useState({
    agencyName: accountName,
    storeName: storeName,
    descDate: descriptionDate,
    micNumber: micNo,
    dueDate: estimateDueDate,
    staff: staffName,
    phoneNo: phone,
  });

  // @Controller
  const handleInputChange = (value, name) => {
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  const agencyInfoInputsHolder = getScDataFromPlaceholder(
    "agency-info-inputs-section",
    props
  );

  const [
    i01AgencyNameData,
    i01StoreNameData,
    i01MicNoData,
    i01StaffNameData,
    i01PhoneData,
  ] = getScDataFromComponent("I-01", agencyInfoInputsHolder);

  i01AgencyNameData.fields.inputValue = {
    name: "agencyName",
    value: inputValues.agencyName,
  };
  i01AgencyNameData.fields.onChangeEvent = (e) =>
    handleInputChange(e.target.value, "agencyName");

  i01StoreNameData.fields.isHidden = {
    value: personalizeInfo.SalesChannelSelect !== STRING_STORE,
  };
  i01StoreNameData.fields.inputValue = {
    name: "storeName",
    value: inputValues.storeName,
  };
  i01StoreNameData.fields.onChangeEvent = (e) =>
    handleInputChange(e.target.value, "storeName");

  const [m01iDescDateData, m01iEstDueDateData] = getScDataFromComponent(
    "M-01i",
    agencyInfoInputsHolder
  );

  m01iDescDateData.fields.initialValue = {
    name: "descDate",
    value: inputValues.descDate,
  };
  m01iDescDateData.fields.calendarPlaceholderFormat = "yyyy/MM/dd";
  m01iDescDateData.fields.onChangeEvent = (date) =>
    handleInputChange(format(date, "yyyy-MM-dd"), "descDate");

  i01MicNoData.fields.inputValue = {
    name: "micNumber",
    value: inputValues.micNumber,
  };
  i01MicNoData.fields.onChangeEvent = (e) =>
    handleInputChange(e.target.value, "micNumber");

  m01iEstDueDateData.fields.initialValue = {
    name: "dueDate",
    value: inputValues.dueDate,
  };
  m01iEstDueDateData.fields.calendarPlaceholderFormat = "yyyy/MM/dd";
  m01iEstDueDateData.fields.onChangeEvent = (date) =>
    handleInputChange(format(date, "yyyy-MM-dd"), "dueDate");

  i01StaffNameData.fields.inputValue = {
    name: "staff",
    value: inputValues.staff,
  };
  i01StaffNameData.fields.onChangeEvent = (e) =>
    handleInputChange(e.target.value, "staff");

  i01PhoneData.fields.inputValue = {
    name: "phoneNo",
    value: inputValues.phoneNo,
  };
  i01PhoneData.fields.onChangeEvent = (e) =>
    handleInputChange(e.target.value, "phoneNo");

  const agencyInfoButtonsHolder = getScDataFromPlaceholder(
    "agency-info-buttons-section",
    props
  );

  const [b02iPrintBtnData] = getScDataFromComponent(
    "B-02i",
    agencyInfoButtonsHolder
  );

  // print button
  b02iPrintBtnData.fields.onClickEvent = () => {
    // TODO: Do the test on this part when we have the ctc devices.
    showPrintDialog();
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const userAgent = window.navigator.userAgent;
      b02iPrintBtnData.fields.isHidden = !userAgent?.includes(
        STRING_USERAGENT_CPAD_APP
      );
    }
  }, []);

  // back and next button
  const setInputSessionStorage = () => {
    setSessionStorageItem(
      keys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ACCOUNTNAME,
      inputValues.agencyName,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STORENAME,
      inputValues.storeName,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_DESCRIPTIONDATE,
      inputValues.descDate,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_MICNO,
      inputValues.micNumber,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_ESTIMATEDUEDATE,
      inputValues.dueDate,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_STAFFNAME,
      inputValues.staff,
      true
    );
    setSessionStorageItem(
      keys.SSN_APPLICATION_CPADAGENCYINFOCONFIRMATION_PHONE,
      inputValues.phoneNo,
      true
    );
  };

  const backButtonHolder = getScDataFromPlaceholder("button-section", props);
  const nextButtonHolder = getScDataFromPlaceholder("button-section", props);

  const [backButton] = getScDataFromComponent("B-02", backButtonHolder);

  // @Controller
  backButton.fields.onClickEvent = () => {
    setInputSessionStorage();
    redirectToPreviousPage();
  };

  const [nextButton] = getScDataFromComponent("B-01", nextButtonHolder);

  //@ Controller
  nextButton.fields.onClickEvent = () => {
    setInputSessionStorage();
    redirect(nextButtonLink.value.href);
  };

  // @View
  return (
    <>
      <div className="form_wrapper-input agency-info-section mx-16 mt-40 normal">
        <Placeholder name="agency-info-section" rendering={props.rendering} />
        <div className="grid-column two side-label-input">
          <Placeholder
            name="agency-info-inputs-section"
            rendering={props.rendering}
          />
        </div>
        <Placeholder
          name="agency-info-buttons-section"
          rendering={props.rendering}
        />
      </div>
    </>
  );
};

const ButtonsSection = (props) => {
  // @View
  return (
    <>
      <div className="bottom-section chart-bottom-section center">
        <Placeholder name="button-section" rendering={props.rendering} />
      </div>
    </>
  );
};

const CPadEstimationResultPlanProductChangeLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  // @View
  return (
    <React.Fragment>
      <main>
        <div className="form_container cpad_wrapper2">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <PlanEstimationSection
                rendering={props.rendering}
                sitecoreContext={sitecoreContext}
              />
              <MonthlyFeeEstimationSection
                rendering={props.rendering}
                sitecoreContext={sitecoreContext}
              />
              <AnnualFeeEstimationSection
                rendering={props.rendering}
                sitecoreContext={sitecoreContext}
              />
              <InitialCostEstimationSection
                rendering={props.rendering}
                sitecoreContext={sitecoreContext}
              />
              <RemainingDebtEstimationSection
                rendering={props.rendering}
                sitecoreContext={sitecoreContext}
              />
              <CampaignBenefitsEstimationSection
                rendering={props.rendering}
                sitecoreContext={sitecoreContext}
              />
              <AgencyInfoSection
                rendering={props.rendering}
                sitecoreContext={sitecoreContext}
              />
              <ButtonsSection
                rendering={props.rendering}
                sitecoreContext={sitecoreContext}
              />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(
  CPadEstimationResultPlanProductChangeLayout
);
