import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const S10 = (props) => {
  const { titleText, descriptionText } = props.fields;

  return (
    <React.Fragment>
      <div className="input-form">
        <div className="input-box single-type S-10">
          <div className="input-container">
            <input type="checkbox" name="cbOptionS10" />
            <label htmlFor="cbOptionS10">
              <Text field={fillBlank(titleText)} />
              <span>
                <Text field={fillBlank(descriptionText)} />
              </span>
            </label>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default S10;
