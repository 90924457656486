import { recoilKeys as keys } from "../../assets/recoilKeys";
import {
  STRING_MOVING,
  STRING_APPLY,
  STRING_SIMULATOR,
  STRING_RESIDENCE_TYPE_APARTMENT,
} from "../../utils/constantVariables";
import { redirectToLinkUrl } from "../../utils/commonUtils";

export const setSessionStorageData = (
  inputValues,
  button,
  linkURL,
  isNotEncrypt,
  orderRelatedInfo,
  urlParentItem,
  isTypeCircuit,
  addressAfterMunicipality,
  setSessionStorageItem,
  redirectToPreviousPage
) => {
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_BUILDINGDIVISION,
    inputValues.registerInstallationAddressBuildingType,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_STREETNUMBER,
    inputValues.registerInstallationAddressStreetNumber,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_BUILDINGNAME,
    inputValues.registerInstallationAddressBuildingName,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_ROOMNUMBER,
    inputValues.registerInstallationAddressRoomNumber,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_INDIVIDUALCONTRACTADDITIONALINFORMATION,
    inputValues.registerInstallationAddressAdditionInformation,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_LASTNAME,
    inputValues.registerIndividualCustomerInfoLastName,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_FIRSTNAME,
    inputValues.registerIndividualCustomerInfoFirstName,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_LASTNAMEINKANA,
    inputValues.registerIndividualCustomerInfoLastNameFurigana,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_FIRSTNAMEINKANA,
    inputValues.registerIndividualCustomerInfoFirstNameFurigana,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_GENDER,
    inputValues.registerIndividualCustomerInfoGender,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_BIRTHYEARMONTHDATE,
    inputValues.registerIndividualCustomerInfoBirthDate,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CORPORATIONNAME,
    inputValues.registerCorporateCustomerInfoCorporateName,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CORPORATIONNAMEINKANA,
    inputValues.registerCorporateCustomerInfoCorporateNameFurigana,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_DEPARTMENTNAME,
    inputValues.registerCorporateCustomerInfoDepartmentName,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_NAMEOFPERSONINCHARGELASTNAME,
    inputValues.registerCorporateCustomerInfoPersonInChargeLastName,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_NAMEOFPERSONINCHARGEFIRSTNAME,
    inputValues.registerCorporateCustomerInfoPersonInChargeFirstName,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_NAMEOFPERSONINCHARGELASTNAMEFURIGANA,
    inputValues.registerCorporateCustomerInfoPersonInChargeLastNameFurigana,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_NAMEOFPERSONINCHARGEFIRSTNAMEFURIGANA,
    inputValues.registerCorporateCustomerInfoPersonInChargeFirstNameFurigana,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSCODE,
    inputValues.registerContactAddressCode,
    true
  );

  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSPOSTALCODE,
    `${inputValues.registerContactAddressPostalCode3digit}${inputValues.registerContactAddressPostalCode4digit}`,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSPREFECTURE,
    inputValues.registerContactAddressPrefecture,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSCITY,
    inputValues.registerContactAddressCity,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSDISTRICT,
    inputValues.registerContactAddressStreetName,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSAPARTMENTNAME,
    inputValues.registerContactAddressBuildingName,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSAPARTMENTROOMNUMBER,
    inputValues.registerContactAddressRoomNumber,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSAREACODE,
    inputValues.registerContactAddressPhoneNumber1,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSLOCALTELEPHONENUMBER,
    inputValues.registerContactAddressPhoneNumber2,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSSUBSCRIBERNAME,
    inputValues.registerContactAddressPhoneNumber3,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSMOBILENUMBER1,
    inputValues.registerContactAddressMobileNumber1,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSMOBILENUMBER2,
    inputValues.registerContactAddressMobileNumber2,
    true
  );
  setSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_CONTACTADDRESSMOBILENUMBER3,
    inputValues.registerContactAddressMobileNumber3,
    true
  );

  if (urlParentItem === STRING_MOVING) {
    setSessionStorageItem(
      keys.SSN_APPLICATION_INPUTCUSTOMERINFO_PREFERREDTRANSFERDATE,
      inputValues.selectPreferredTransferDateCalendar,
      true
    );
  }

  const order = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order;

  if (
    (urlParentItem === STRING_APPLY || urlParentItem === STRING_SIMULATOR) &&
    !isTypeCircuit
  ) {
    setSessionStorageItem(
      keys.SSN_APPLICATION_INPUTCUSTOMERINFO_PREFERREDSTARTDATE,
      inputValues.registerInstallationAddressPreferredStartDate,
      true
    );
  }

  if (button.componentName === "B-02") {
    redirectToPreviousPage();
  }

  if (button.componentName === "B-01") {
    if (urlParentItem === STRING_MOVING) {
      orderRelatedInfo = {
        ...orderRelatedInfo,
        orderRelatedInfo: {
          ...orderRelatedInfo?.orderRelatedInfo,
          orderInfo: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
            order: {
              ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
              CMAP_ChangeSourceLastUtilizationDate__c:
                inputValues.selectPreferredTransferDateCalendar,
            },
          },
        },
      };
    }

    if (
      (urlParentItem === STRING_APPLY || urlParentItem === STRING_SIMULATOR) &&
      !isTypeCircuit
    ) {
      orderRelatedInfo = {
        ...orderRelatedInfo,
        orderRelatedInfo: {
          ...orderRelatedInfo?.orderRelatedInfo,
          orderInfo: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
            order: {
              ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
              CMAP_StartRequestDate__c:
                inputValues.registerInstallationAddressPreferredStartDate,
            },
          },
        },
      };
    }

    if (isTypeCircuit) {
      orderRelatedInfo = {
        ...orderRelatedInfo,
        orderRelatedInfo: {
          ...orderRelatedInfo?.orderRelatedInfo,
          orderInfo: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
            order: {
              ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
              CMAP_InstallationLocationHouseNumber__c:
                inputValues.registerInstallationAddressStreetNumber,
            },
          },
        },
      };

      if (order?.CMAP_ResidenceType__c === STRING_RESIDENCE_TYPE_APARTMENT) {
        orderRelatedInfo = {
          ...orderRelatedInfo,
          orderRelatedInfo: {
            ...orderRelatedInfo?.orderRelatedInfo,
            orderInfo: {
              ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
              order: {
                ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
                CMAP_InstallationLocationRoomNumber__c:
                  inputValues.registerInstallationAddressRoomNumber,
              },
            },
          },
        };
      }
    }

    if (
      order?.CMAP_IsTypeCircuitWireless__c ||
      order?.CMAP_IsTypeNotCircuit__c ||
      order?.CMAP_IsTypeGoodsServiceSales__c
    ) {
      orderRelatedInfo = {
        ...orderRelatedInfo,
        orderRelatedInfo: {
          ...orderRelatedInfo?.orderRelatedInfo,
          orderInfo: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
            order: {
              ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
              CMAP_DeliveryServiceAddress__Street__s:
                inputValues.registerInstallationAddressStreetNumber,
              CMAP_DeliveryServiceAddressBuildingName__c:
                inputValues.registerInstallationAddressBuildingName,
              CMAP_DeliveryServiceAddressRoomNumber__c:
                inputValues.registerInstallationAddressRoomNumber,
            },
          },
        },
      };
    }

    orderRelatedInfo = {
      ...orderRelatedInfo,
      orderRelatedInfo: {
        ...orderRelatedInfo?.orderRelatedInfo,
        orderInfo: {
          ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
          order: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
            CMAP_BuildingCategory__c:
              inputValues.registerInstallationAddressBuildingType,
            CMAP_IndividualContractComplementary__c:
              inputValues.registerInstallationAddressAdditionInformation,
            ShippingStreet: addressAfterMunicipality,
            CMAP_ApartmentPropertyName__c:
              inputValues.registerInstallationAddressBuildingName,
          },
        },
      },
    };

    setSessionStorageItem(
      keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
      orderRelatedInfo,
      isNotEncrypt
    );
    redirectToLinkUrl(linkURL);
  }
};
