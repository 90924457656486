import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
} from "../../utils/commonUtils";
import { dateCondition } from "../../utils/dateLogic";
import { fillBlank } from "../../utils/commonUtils";

export function CommonCarousel(props) {
  const {
    sitecoreContext: sitecoreContext,
    sectionName: sectionName,
    multipleSlide: multipleSlide,
  } = props;

  const {
    "CommonCarouselItem-DelayTime": CommonCarouselItemDelayTime,
  } = sitecoreContext?.route?.fields;

  const carouselSection = getScDataFromPlaceholder(sectionName, props);
  const [ic01Data] = getScDataFromComponent("IC-01", carouselSection);
  const CommonCarouselListItemList = ic01Data.fields.CarouselListItemList;
  const filteredImage = CommonCarouselListItemList.filter((sort) =>
    sort.fields["CarouselItem-PublicationStartDate"]?.value &&
    sort.fields["CarouselItem-PublicationEndDate"]?.value !== undefined
      ? dateCondition(
          sort.fields["CarouselItem-PublicationStartDate"]?.value,
          sort.fields["CarouselItem-PublicationEndDate"]?.value
        )
      : sort
  );

  ic01Data.fields.carouselImages = filteredImage?.map((child) => ({
    mainImage: child?.fields["CarouselItem-Image"],
    linkUrl: child?.fields["CarouselItem-LinkURL"],
    publicationStartDate: child?.fields["CarouselItem-PublicationEndDate"],
    publicationEndDate: child?.fields["CarouselItem-PublicationStartDate"],
  }));

  const carouselSpeed =
    CommonCarouselItemDelayTime?.value == null ||
    CommonCarouselItemDelayTime?.value == ""
      ? 3000
      : parseInt(CommonCarouselItemDelayTime?.value);

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <button
        type="button"
        data-role="none"
        className={className}
        style={{ ...style }}
        onClick={onClick}
      >
        <img src={fillBlank(ic01Data.fields.prevNextIcon)?.value?.src} />
      </button>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <button
        type="button"
        data-role="none"
        className={className}
        style={{ ...style }}
        onClick={onClick}
      >
        <img src={fillBlank(ic01Data.fields.prevNextIcon)?.value?.src} />
      </button>
    );
  };

  if (multipleSlide) {
    ic01Data.fields.newSettings = {
      centerMode: true,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: carouselSpeed,
      variableWidth: true,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            centerMode: false,
            variableWidth: false,
          },
        },
      ],
    };
  } else {
    ic01Data.fields.newSettings = {
      centerMode: false,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: carouselSpeed,
      variableWidth: false,
      arrows: false,
    };
  }

  return (
    <div className="hero-banner">
      <Placeholder name={sectionName} rendering={props.rendering} />
    </div>
  );
}
