import React from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { di_fetch, di_register } from "../../utils/di";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import { useSetItems } from "../../hooks/useSelectedState";

import {
  deepcopy,
  getScDataFromComponent,
  getScDataFromPlaceholder,
  formatCurrency,
  generateGUID,
} from "../../utils/commonUtils";
import { getSessionStorageItem } from "../../utils/useSessionStorage";

import { CommonEstimateConfirmationDetails } from "../../services/common/common-estimate-confirmation-details";
import { CommonProductDetailConfirmation } from "../../services/common/common-product-details-confirmation";
import { HandlingPersonalInfo } from "../../services/common/common-handling-personal-info";

import { STRING_REQUEST_WEB } from "../../utils/constantVariables";

const addDataRowList = (key, value) => {
  return {
    fields: {
      Key: {
        value: key,
      },
      Value: {
        value: value,
      },
    },
  };
};

const ProductDetailConfirmation = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const pageFields = sitecoreContext?.route?.fields;
  const [getSessionStorage] = useSetItems();

  const createTextComponent = (componentList, component, fieldName, value) => {
    let copiedComponent = deepcopy(component);
    copiedComponent.uid = generateGUID();
    copiedComponent.fields[fieldName] = value;
    componentList[componentList.length] = copiedComponent;
  };

  const createMonthlyProdList = (
    componentList,
    prodList,
    component,
    pageFields
  ) => {
    for (let i = 0; i < prodList.length; i++) {
      let prodDetail = deepcopy(component);
      let monthlyPlanDetail = prodDetail?.fields?.dataRowList;
      prodDetail.uid = generateGUID();
      prodDetail.fields.dataRowList.splice(0);
      prodDetail.fields.titleText = prodList[i].MonthlyProductGroupName;
      for (let j = 0; prodList[i].MonthlyProduct.length > j; j++) {
        // 各グループの詳細作成
        monthlyPlanDetail[monthlyPlanDetail.length] = addDataRowList(
          prodList[i].MonthlyProduct[j].MonthlyProductName,
          formatCurrency(
            prodList[i].MonthlyProduct[j].MonthlyProductPrice
          ).concat("円")
        );
        if (prodList[i].MonthlyProduct.length === j + 1) {
          monthlyPlanDetail[monthlyPlanDetail.length] = addDataRowList(
            pageFields["ProductDetailConfirmation-SubtotalLabel"]?.value,
            formatCurrency(prodList[i].MonthlyProductSubtotal).concat("円")
          );
        }
      }
      componentList[componentList.length] = prodDetail;
    }
  };
  const createAnnualProdList = (
    componentList,
    prodList,
    component,
    pageFields
  ) => {
    for (let i = 0; prodList.length > i; i++) {
      let annualPlan = deepcopy(component);
      let annualPlanDetail = annualPlan?.fields?.dataRowList;
      annualPlan.uid = generateGUID();
      annualPlan.fields.dataRowList.splice(0);
      annualPlan.fields.titleText = prodList[i].AnnualProductGroupName;
      for (let j = 0; prodList[i].AnnualProduct.length > j; j++) {
        annualPlanDetail[annualPlanDetail.length] = addDataRowList(
          prodList[i].AnnualProduct[j].AnnualProductName,
          formatCurrency(
            prodList[i].AnnualProduct[j].AnnualProductPrice
          ).concat("円")
        );
        if (prodList[i].AnnualProduct.length === j + 1) {
          annualPlanDetail[annualPlanDetail.length] = addDataRowList(
            pageFields["ProductDetailConfirmation-SubtotalLabel"]?.value,
            formatCurrency(prodList[i].AnnualProductSubtotal).concat("円")
          );
        }
      }
      componentList[componentList.length] = annualPlan;
    }
  };
  const createInitialCostList = (
    componentList,
    costList,
    component,
    pageFields
  ) => {
    for (let i = 0; costList.length > i; i++) {
      let initialCost = deepcopy(component);
      let initialCostDetail = initialCost?.fields?.dataRowList;
      initialCost.uid = generateGUID();
      initialCost.fields.dataRowList.splice(0);
      initialCost.fields.titleText =
        pageFields["ProductDetailConfirmation-InitialCostLabel"]?.value;
      for (let j = 0; costList[i].InitialCost.length > j; j++) {
        initialCostDetail[initialCostDetail.length] = addDataRowList(
          costList[i].InitialCost[j].InitialCostName,
          formatCurrency(costList[i].InitialCost[j].InitialCost).concat("円")
        );
        if (costList[i].InitialCost.length === j + 1) {
          initialCostDetail[initialCostDetail.length] = addDataRowList(
            pageFields["ProductDetailConfirmation-SubtotalLabel"]?.value,
            formatCurrency(costList[i].InitialCostSubtotal).concat("円")
          );
        }
      }
      componentList[componentList.length] = initialCost;
    }
  };
  const createPlanBenefitList = (
    componentList,
    benefitList,
    component,
    pageFields
  ) => {
    let planBenefit = deepcopy(component);
    let planBenefitDetail = planBenefit?.fields?.dataRowList;
    planBenefit.uid = generateGUID();
    planBenefit.fields.dataRowList.splice(0);
    planBenefit.fields.titleText =
      pageFields["ProductDetailConfirmation-PlanBenefitLabel"]?.value;
    planBenefit.fields.hiddenHeader = true;
    for (let i = 0; benefitList.length > i; i++) {
      planBenefitDetail[planBenefitDetail.length] = addDataRowList(
        benefitList[i].PlanBenefitName.toString(),
        ""
      );
    }
    componentList[componentList.length] = planBenefit;
  };
  const createPlanAttributeList = (componentList, attributeList, component) => {
    for (let i = 0; attributeList.length > i; i++) {
      let planAttribute = deepcopy(component);
      let planAttributeDetail = planAttribute?.fields?.dataRowList;
      planAttribute.uid = generateGUID();
      planAttribute.fields.dataRowList.splice(0);
      planAttribute.fields.titleText = attributeList[i].PlanAttributeTitleName;
      planAttribute.fields.hiddenHeader = true;
      for (let j = 0; attributeList[i].PlanAttribute.length > j; j++) {
        planAttributeDetail[planAttributeDetail.length] = addDataRowList(
          attributeList[i].PlanAttribute[j].PlanAttributeRegistrationItem,
          attributeList[i].PlanAttribute[j].PlanAttributeRegistrationInfo
        );
      }
      componentList[componentList.length] = planAttribute;
    }
  };

  let componentList = [];
  let monthlyProdList = getSessionStorage([
    keys.SSN_APPLICATION_SELECTPRODUCT_MONTHLYPRODUCTLIST,
    true,
  ]);
  let annualProdList = getSessionStorage([
    keys.SSN_APPLICATION_SELECTPRODUCT_ANNUALPRODUCTLIST,
    true,
  ]);
  let initialCostList = getSessionStorage([
    keys.SSN_APPLICATION_SELECTPRODUCT_INITIALCOSTLIST,
    true,
  ]);
  let planBenefitList = getSessionStorage([
    keys.SSN_APPLICATION_SELECTPRODUCT_PLANBENEFITLIST,
    true,
  ]);
  let planAttributeList = getSessionStorage([
    keys.SSN_APPLICATION_SELECTPRODUCT_PLANATTRIBUTELIST,
    true,
  ]);

  let prodListSection = getScDataFromPlaceholder("product-list-section", props);

  let accordionContent = getScDataFromPlaceholder("accordion-content", props);
  let h01List = getScDataFromComponent("H-03", accordionContent);
  let l02List = getScDataFromComponent("L-02", accordionContent);
  let l01List = getScDataFromComponent("L-01", accordionContent);
  let c10List = getScDataFromComponent("C-10", accordionContent);

  c10List[0].fields.detailsText = getSessionStorage([
    keys.SSN_APPLICATION_SELECTPRODUCT_PLANNAME,
    true,
  ]);

  createTextComponent(
    componentList,
    h01List[0],
    "titleText",
    pageFields["ProductDetailConfirmation-MonthlyFeeLabel"]
  );

  componentList.push(c10List[0]);
  createMonthlyProdList(componentList, monthlyProdList, l02List[0], pageFields);

  createTextComponent(
    componentList,
    h01List[0],
    "titleText",
    pageFields["ProductDetailConfirmation-AnnualFeeLabel"]
  );

  createAnnualProdList(componentList, annualProdList, l02List[0], pageFields);

  createTextComponent(
    componentList,
    h01List[0],
    "titleText",
    pageFields["ProductDetailConfirmation-InitialCostLabel"]
  );
  createInitialCostList(componentList, initialCostList, l02List[0], pageFields);

  createTextComponent(
    componentList,
    h01List[0],
    "titleText",
    pageFields["ProductDetailConfirmation-PlanBenefitLabel"]
  );
  createPlanBenefitList(componentList, planBenefitList, l02List[0], pageFields);

  createTextComponent(
    componentList,
    h01List[0],
    "titleText",
    pageFields["ProductDetailConfirmation-PlanAttributeLabel"]
  );
  createPlanAttributeList(
    componentList,
    planAttributeList,
    l01List[0],
    pageFields
  );

  prodListSection.splice(1);
  for (let i = 0; i < componentList.length; i++) {
    prodListSection[prodListSection.length] = componentList[i];
  }

  return (
    <div className="form_wrapper-input">
      <Placeholder name="product-list-section" rendering={props.rendering} />
    </div>
  );
};

const ContractFromSimulationResultLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  const orderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );
  const beforeChangeOrderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO,
    true
  );
  const personalizeInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );
  const { BenefitListForAgency: benefitListAgency } = getSessionStorageItem(
    keys.SSN_APPLICATION_SELECTPRODUCT_BENEFITLISTFORAGENCY,
    true
  );
  const orderChannelCategory = personalizeInfo?.AppChannelDivision ?? STRING_REQUEST_WEB;

  const order = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order;
  const orderCategory = order?.CMAP_OrderCategory__c;
  const orderItemInfo = orderRelatedInfo?.orderRelatedInfo?.orderItemInfos;

  return (
    <React.Fragment>
      <main>
        <Placeholder name="title-wrapper" rendering={props.rendering} />

        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <Placeholder name="heading-wrapper" rendering={props.rendering} />
              <CommonEstimateConfirmationDetails
                rendering={props.rendering}
                setIsLoading={props.setIsLoading}
                sitecoreContext={sitecoreContext}
                orderCategory={orderCategory}
                orderItemInfo={orderItemInfo}
                benefitListAgency={benefitListAgency}
              />
              <ProductDetailConfirmation rendering={props.rendering} />
              {/* TODO: use the following common component instead of ProductDetailConfirmation (need to adjust the components and placeholder in sitecore)*/}
              {/* <CommonProductDetailConfirmation
                  rendering={props.rendering}
                  setIsLoading={props.setIsLoading}
                  sitecoreContext={sitecoreContext}
                  orderCategory={orderCategory}
                  orderChannelCategory={orderChannelCategory}
                  benefitListAgency={benefitListAgency}
                  orderRelatedInfo={orderRelatedInfo}
                  beforeChangeOrderRelatedInfo={beforeChangeOrderRelatedInfo}
              /> */}
              <HandlingPersonalInfo
                rendering={props.rendering}
                setIsLoading={props.setIsLoading}
              />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(ContractFromSimulationResultLayout);
