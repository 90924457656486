import React, { useEffect, useState } from "react";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  deepcopy,
} from "../../utils/commonUtils";
import {
  useCustomQuery,
  useDynamicParallelQuery,
} from "../../hooks/useGetData";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import { apiBaseURL } from "../../envSettings";
import { getURLType, redirectToLinkUrl } from "../../utils/targetUrlType";
import {
  buttonConditionCode,
  ORDER_CONDITION,
  ORDER_CONDITION_TEXT,
  STRING_CATEGORY_PLAN_CHANGE,
  STRING_CATEGORY_PRODUCT_CHANGE,
  STRING_CATEGORY_CANCEL,
  API_RESPONSE_STATUS,
  SAME_WINDOW
} from "../../utils/constantVariables";

const buttonCategoryBehavior = (category, name, button ) => {
  const orderCategoryItem = category?.filter((category) => category.orderCategory === name);
  if (orderCategoryItem && orderCategoryItem.length > 0){
    button.fields.buttonText = {
      value: orderCategoryItem[0].orderCategoryLabel
    }
  }
  else{
    button.fields.isHidden = true;
  }
}

const addDataRowList = (key, value) => {
  return {
    fields: {
      explanationText: value,
      textAreaLabel: key,
    },
  };
};

const createApplicationList = (
  componentList,
  applicationListData,
  orderCategoryData,
  c05Component,
  pageContext,
  uid
) => {
  for (let i = 0; i < applicationListData?.length; i++) {
    let applicationDetail = deepcopy(c05Component);
    let multilistItems = applicationDetail?.fields?.MultilistItems;
    applicationDetail.uid = c05Component.uid.concat(uid).concat(i.toString());
    const orderListData = applicationListData[i]?.orderInfo?.order;
    let orderCondition = "";
    let mainAddress = orderListData?.CMAP_DeliveryServiceAddressForDisplay__c

    if (orderListData?.CMAP_IsTypeCircuit__c){
      mainAddress = orderListData?.CMAP_InstallationLocationAddress__c
    }

    if (
      orderListData?.CMAP_IsCompleted__c === true
    ) {
      if (
        orderListData?.CMAP_OrderCondition__c ===
        ORDER_CONDITION.VALID
      ) {
        orderCondition = ORDER_CONDITION_TEXT.COMPLETION;
      } else if (
        orderListData?.CMAP_OrderCondition__c ===
        ORDER_CONDITION.INVALID
      ) {
        orderCondition = ORDER_CONDITION_TEXT.INVALID;
      } else orderCondition = ORDER_CONDITION_TEXT.CANCEL
    } else {
      if (
        orderListData?.CMAP_OrderCondition__c ===
        ORDER_CONDITION.VALID
      ) {
        orderCondition = ORDER_CONDITION_TEXT.APPLYING;
      } else orderCondition = ORDER_CONDITION_TEXT.PROCESSING;
    }
    applicationDetail.fields.titleNumber =
      orderListData?.CMAP_OrderReceiptNumber__c;
    multilistItems[0] = addDataRowList(
      pageContext["ApplicationInfo-OrderDateLabel"]?.value,
      orderListData?.EffectiveDate
    );
    multilistItems[1] = addDataRowList(
      pageContext["ApplicationInfo-OrderStatusLabel"]?.value,
      orderCondition
    );
    multilistItems[2] = addDataRowList(
      pageContext["ApplicationInfo-MainAddressLabel"]?.value,
      mainAddress
    );
    multilistItems[3] = addDataRowList(
      pageContext["ApplicationInfo-PlanNameLabel"]?.value,
      orderListData?.CMAP_OfferPlanName__c
    );
    
    const cancelAndScheduleSection = getScDataFromPlaceholder(
      "cancel-and-schedule-button-section",
      applicationDetail
    );
    const [b02Data1, b02Data2, b02Data3, b02Data4] = getScDataFromComponent("B-02", cancelAndScheduleSection);
    
    if (orderListData?.CMAP_IsCompleted__c){
      b02Data1.fields.isHidden = true;
      b02Data2.fields.isHidden = true;
      b02Data3.fields.isHidden = true;
    }
    else{
      buttonCategoryBehavior(orderCategoryData[i], STRING_CATEGORY_PLAN_CHANGE, b02Data1);
      buttonCategoryBehavior(orderCategoryData[i], STRING_CATEGORY_PRODUCT_CHANGE, b02Data2);
      buttonCategoryBehavior(orderCategoryData[i], STRING_CATEGORY_CANCEL, b02Data3);
    }

    let [d01Data] = getScDataFromComponent("D-01", cancelAndScheduleSection);
    const needForConstruction = orderListData?.CMAP_NeedForConstruction__c;

    b02Data4.fields["isHidden"] = !needForConstruction;
    d01Data.fields.isHidden = { value: !needForConstruction };

    const constructionButton = () => {
      if (
        orderListData?.CMAP_NeedsConstructionScheduleAdjustment__c ===
          buttonConditionCode.necessary && orderListData?.CMAP_NeedForConstruction__c === true
      ) {
        b02Data4.fields.btnWrapperAdditionalClass.value = "";
      } else if (
        orderListData?.CMAP_NeedsConstructionScheduleAdjustment__c ===
          buttonConditionCode.notNecessary &&
        orderListData?.CMAP_NeedForConstruction__c === true
      )
        b02Data4.fields.btnWrapperAdditionalClass.value = "disabled";
    };

    constructionButton();
   
    applicationDetail.fields.isPlaceholderHidden = orderListData?.CMAP_IsCompleted__c && 
    orderListData?.CMAP_OrderCondition__c === ORDER_CONDITION.VALID;
   
    let dynamicButtonSection = getScDataFromPlaceholder(
      "dynamic-button-section",
      applicationDetail
    );

    dynamicButtonSection[0].fields.onClickEvent = () => {
      redirectToLinkUrl(
        pageContext["ApplicationInfo-ShowPlanDetailButtonLink"]?.value,
        getURLType(pageContext["ApplicationInfo-ShowPlanDetailButtonUrlType"])
      );
    };
    
    b02Data1.fields.onClickEvent = () => {
      setSessionStorageItem(
        sessionKeys.SSN_REPORT_APPLICATIONLIST_APPLICATIONID,
        orderListData?.Id,
        true
      );
      redirectToLinkUrl(
        pageContext["ApplicationInfo-ChangePlanButtonLink"]?.value,
        SAME_WINDOW
      );
    };

    b02Data2.fields.onClickEvent = () => {
      setSessionStorageItem(
        sessionKeys.SSN_REPORT_APPLICATIONLIST_APPLICATIONID,
        orderListData?.Id,
        true
      );
      redirectToLinkUrl(
        pageContext["ApplicationInfo-ChangeProductButtonLink"]?.value,
        SAME_WINDOW
      );
    };

    b02Data3.fields.onClickEvent = () => {
      setSessionStorageItem(
        sessionKeys.SSN_REPORT_APPLICATIONLIST_APPLICATIONID,
        orderListData?.Id,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_REPORT_APPLICATIONLIST_ORDERRECEIPTNUMBER,
        orderListData?.CMAP_OrderReceiptNumber__c,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_REPORT_APPLICATIONLIST_EFFECTIVEDATE,
        orderListData?.EffectiveDate,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_REPORT_APPLICATIONLIST_ORDERSTATUS,
        orderCondition,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_REPORT_APPLICATIONLIST_ADDRESS,
        mainAddress,
        true
      );
      setSessionStorageItem(
        sessionKeys.SSN_REPORT_APPLICATIONLIST_PLANNAME,
        orderListData?.CMAP_OfferPlanName__c,
        true
      );

      redirectToLinkUrl(
        pageContext["ApplicationInfo-OrderCancelButtonLink"]?.value,
        SAME_WINDOW
      );
    };
    
    const constructionOrderListPageURL = {
      href: `${pageContext["ApplicationInfo-ConstructionScheduleButtonLink"]?.value?.href}?c_customer=${orderListData?.AccountId}`,
    };

    b02Data4.fields.onClickEvent = () => {
      redirectToLinkUrl(
        constructionOrderListPageURL,
        getURLType(
          pageContext["ApplicationInfo-ConstructionScheduleButtonUrlType"]
        )
      );
    };
    
    let statusCompletedButtonSection = getScDataFromPlaceholder(
      "status-completed-button-section",
      applicationDetail
    );

    statusCompletedButtonSection[0].fields.onClickEvent = () => {
      redirectToLinkUrl(
        pageContext["ApplicationInfo-ContractListButtonLink"]?.value,
        getURLType(pageContext["ApplicationInfo-ContractListButtonUrlType"])
      );
    };

    componentList[componentList.length] = applicationDetail;
  }
};

const mappingForApplicationList = (
  props,
  isLoading,
  orderCategoryData,
  applicationListData,
  orderIds
) => {
  const pageContext = props.sitecoreContext.route.fields;
  const applicationInformationSection = getScDataFromPlaceholder(
    "application-information-section",
    props
  );

  let componentList = [];
  let c05Data = getScDataFromComponent("C-05", applicationInformationSection);

  let cancelAndSchedulePlaceholder = getScDataFromPlaceholder(
    "cancel-and-schedule-button-section",
    c05Data[0]
  );
  let b02Component = getScDataFromComponent(
    "B-02",
    cancelAndSchedulePlaceholder
  );
  let [d01Component] = getScDataFromComponent(
    "D-01",
    cancelAndSchedulePlaceholder
  );
  b02Component[3].fields["isHidden"] = true;
  d01Component.fields.isHidden = { value: true };
  if (
    applicationListData &&
    applicationListData?.length > 0 &&
    orderIds &&
    orderIds?.length > 0 &&
    !isLoading
  ) {
    let copiedc05 = deepcopy(getScDataFromComponent("C-05", c05Data)[0]);

    copiedc05.fields.MultilistItems.splice(0);

    createApplicationList(
      componentList,
      applicationListData,
      orderCategoryData,
      copiedc05,
      pageContext,
      "c05001"
    );
    applicationInformationSection.splice(0);
    for (let i = 0; i < componentList.length; i++) {
      applicationInformationSection[applicationInformationSection.length] =
        componentList[i];
    }
  } else {
    applicationInformationSection.splice(1);
  }
};

const mapMultipleApplicationListItem = (data) => {
  return (
    data?.map((application) => application?.data?.data?.orderCategoryInfos) ||
    []
  );
};

const ApplicationListLayout = (props) => {
  // TODO: remove test data

  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  const customerID = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_CUSTOMERID,
    true
  );

  const orderInfoListURI = `${apiBaseURL}order/RetrieveOrderInfoList?Id=${customerID}&maxNumber=999&order=Desc`;

  const {
    data: orderInfoListData,
    isLoading: orderInfoListIsLoading,
    error: orderInfoListError,
    refetch: orderInfoListRefresh,
    remove: orderInfoListRemove,
  } = useCustomQuery(orderInfoListURI, true);

  const [orderIds, setOrderIds] = useState([]);
  const orderCategoryListURI = `${apiBaseURL}order/RetrieveOrderCategoryList?changeType=OrderChange&orderChannelCategory=Web&orderId=`;
  const individualContractId = "&individualContractId="
  const {
    isLoading: isOrderCategoryLoading,
    results: orderCategoryData,
  } = useDynamicParallelQuery(orderIds, orderCategoryListURI, "", individualContractId);

  const isApiLoading = orderInfoListIsLoading || isOrderCategoryLoading;
  props.setIsLoading(isApiLoading)

  if (orderCategoryData && orderCategoryData.length > 0 && orderCategoryData[0]?.status ===  API_RESPONSE_STATUS.SUCCESS){
    mappingForApplicationList(
      props,
      isOrderCategoryLoading,
      mapMultipleApplicationListItem(orderCategoryData),
      orderInfoListData?.orderInfos,
      orderIds
    );
  }

  useEffect(() => {
    if (
      orderInfoListData?.orderInfos &&
      orderInfoListData?.orderInfos?.length > 0
    ) {
      let orderIds = [];
      orderInfoListData.orderInfos.forEach((item) => {
        orderIds.push(item.orderInfo["order"]?.Id);
      });
      setOrderIds(orderIds || []);
    }
  }, [orderInfoListData]);

  // @View
  return (
    <React.Fragment>
      <main>
        <Placeholder
          name="common-breadcrumbs-and-title-section"
          rendering={props.rendering}
        />
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <div className="form_wrapper-input">
                <Placeholder
                  name="application-information-section"
                  rendering={props.rendering}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(ApplicationListLayout);
