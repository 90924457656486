import React, { useState } from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank } from '../../utils/commonUtils';

// @Model

// get the data

// update the context

// @Controller

// @View

const L04 = (props) => {
  const {
    columnName,
    titleHeaderName,
    dateHeaderName,
    contentHeaderName,
    pointHeaderName,
    destinationLinkHeaderName,
    titleText,
    dateText,
    contentText,
    pointValue,
    destinationLinkText,
  } = props.fields;
  return (
    <React.Fragment>
      <div className="list_wrapper tabset L-04">
        <div className="tab-menu">
          <input
            type="radio"
            name="tabset01"
            id="tab1"
            aria-controls="tabPanel1"
            checked
          />
          <label htmlFor="tab1">
            <Text field={fillBlank(columnName)} />
          </label>
        </div>
        <div className="tab-panels">
          <div id="tabPanel1" className="tab-panel active">
            <table>
              <thead>
                <tr>
                  <th className="title">
                    <Text field={fillBlank(titleHeaderName)} />
                  </th>
                  <th className="date">
                    <Text field={fillBlank(dateHeaderName)} />
                  </th>
                  <th>
                    <Text field={fillBlank(contentHeaderName)} />
                  </th>
                  <th className="price">
                    <Text field={fillBlank(pointHeaderName)} />
                  </th>
                  <th>
                    <Text field={fillBlank(destinationLinkHeaderName)} />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-xsmall text-primary bold">
                    <Text field={fillBlank(titleText)} />
                  </td>
                  <td>
                    <Text field={fillBlank(dateText)} />
                  </td>
                  <td className="bold">
                    <Text field={fillBlank(contentText)} />
                  </td>
                  <td className="text-normal  right">
                    <span className="bold text-primary">
                      <Text field={fillBlank(pointValue)} />
                    </span>
                    pt
                  </td>
                  <td className="text-small">
                    <Link field={destinationLinkText} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default L04;
