import React, { useContext, useMemo } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import ModalContext from "../../assets/context/ModalContext";
import { getProperData, redirectToLinkUrl } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const P002 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { titleText, descriptionText, modalTarget, buttonLabel, buttonURL } = properData;
  const { state, dispatch } = useContext(ModalContext);

  // @Controller
  const {
    showModal,
    displayModal,
    paddingModal,
    roleValue,
    colorGrayClass,
  } = useMemo(() => {
    const { showModal, id } = state;
    return showModal && modalTarget?.value === id
      ? state
      : {
          showModal: "",
          displayModal: "",
          paddingModal: "",
          roleValue: "",
          colorGrayClass: "",
        };
  }, [state]);

  const handleLoginButtonClick = () => {
    redirectToLinkUrl(buttonURL);
  };

  // @View
  return (
    <React.Fragment>
      <div
        tabIndex="-1"
        aria-modal="true"
        data-backdrop="static"
        id={modalTarget?.value}
        role={roleValue}
        className={`modal popup fade P0-02 ${showModal}`}
        style={{ display: displayModal, paddingRight: paddingModal }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {titleText && (
              <div className="modal-header">
                <h5 className="modal-title">
                  <Text field={titleText} />
                </h5>
              </div>
            )}

            {/* Default modal content */}
              <>
                <div className="modal-body">
                  <div className="modal-body_content">
                    {descriptionText && (
                      <Text
                        field={descriptionText}
                        tag="p"
                        encode={false}
                      />
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                <div className="btn_wrapper primary B-01">
                  <button
                    className="btn btn_default"
                    type="button"
                    onClick={handleLoginButtonClick}
                  >
                    <Text field={buttonLabel} />
                  </button>
                </div>
                </div>
              </>
          </div>
        </div>
      </div>
      <div className={colorGrayClass}></div>
    </React.Fragment>
  );
};

export default P002;
