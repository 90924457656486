import React, { useEffect, useState } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
  isWithinDateRange,
} from "../../utils/commonUtils";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import { getSessionStorageItem } from "../../utils/useSessionStorage";
import {
  STRING_RESIDENCE_TYPE_HOME,
  STRING_RESIDENCE_TYPE_APARTMENT,
  STRING_APPLY,
  STRING_SIMULATOR,
  STRING_CIRCUIT,
  STRING_MOVING,
  STRING_REBUILDING,
} from "../../utils/constantVariables";
import { format } from "date-fns";
import { useSetSelectedItem } from "../../hooks/useSelectedState";
import { useCustomQuery } from "../../hooks/useGetData";

export function RegisterInstallationAddress(props) {
  const {
    onChangeEventInput,
    inputValues,
    setInputValues,
    pageContext,
    isNotEncrypt,
    isFirstTimeDisplay,
    orderRelatedInfo,
    planType,
    isTypeCircuit,
    metadataDefinitions,
    urlParentItem,
    retrieveSystemDateTimeData,
    retrieveSelectableDesiredDateRangeData,
  } = props;

  const registrationInstallationAddress = getScDataFromPlaceholder(
    "commufa-service-area-section",
    props
  );
  const [H04Data] = getScDataFromComponent(
    "H-04",
    registrationInstallationAddress
  );
  const [S01Data] = getScDataFromComponent(
    "S-01",
    registrationInstallationAddress
  );

  const [M01iData] = getScDataFromComponent(
    "M-01i",
    registrationInstallationAddress
  );

  M01iData.fields.onChangeEvent = (date) => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        registerInstallationAddressPreferredStartDate: format(
          date,
          "yyyy-MM-dd"
        ),
      };
    });
  };
  let preferredStartDateInitialValue = "";
  const ssnPreferredStartDate = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_PREFERREDSTARTDATE,
    isNotEncrypt
  );
  useEffect(() => {
    if (isFirstTimeDisplay) {
      if (
        (urlParentItem === STRING_APPLY ||
          urlParentItem === STRING_SIMULATOR) &&
        !isTypeCircuit
      ) {
        if (retrieveSystemDateTimeData) {
          if (retrieveSelectableDesiredDateRangeData) {
            const minDate =
              retrieveSelectableDesiredDateRangeData
                .selectablePreferDatesRangeInfo?.minPreferDate;
            const maxDate =
              retrieveSelectableDesiredDateRangeData
                .selectablePreferDatesRangeInfo?.maxPreferDate;
            //missing else
            M01iData.fields.minDate = minDate;
            M01iData.fields.maxDate = maxDate;
            if (isWithinDateRange(ssnPreferredStartDate, minDate, maxDate)) {
              preferredStartDateInitialValue = ssnPreferredStartDate;
            }
          }
        }
      }
    }

    if (
      urlParentItem === STRING_APPLY ||
      urlParentItem === STRING_SIMULATOR
    ) {
      if (planType === STRING_CIRCUIT) {
        M01iData.fields.isHidden = true;
      }
    }

    if (
      urlParentItem === STRING_MOVING ||
      urlParentItem === STRING_REBUILDING
    ) {
      M01iData.fields.isHidden = true;
    }

    setInputValues((prevState) => {
      return {
        ...prevState,
        registerInstallationAddressPreferredStartDate: preferredStartDateInitialValue,
      };
    });
  }, [retrieveSystemDateTimeData, retrieveSelectableDesiredDateRangeData]);

  M01iData.fields.initialValue = {
    value: inputValues.registerInstallationAddressPreferredStartDate,
  };

  const addressField = getScDataFromComponent(
    "D-01",
    registrationInstallationAddress
  );
  const [
    streetNumberField,
    buildingNameField,
    roomNumberField,
    additionalInformationField,
  ] = getScDataFromComponent("I-01", registrationInstallationAddress);

  streetNumberField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  buildingNameField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  roomNumberField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };
  additionalInformationField.fields.onChangeEvent = (e) => {
    onChangeEventInput(e);
  };

  let buildingDivision = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_BUILDINGDIVISION,
    isNotEncrypt
  );
  let address = getSessionStorageItem(
    keys.SSN_APPLICATION_SELECTPLANTYPE_INSTALLATIONLOCATIONADDRESS,
    isNotEncrypt
  );
  let apartmentStreetBunch = getSessionStorageItem(
    keys.SSN_APPLICATION_APARTMENTSEARCH_APARTMENTSTREETBUNCH,
    isNotEncrypt
  );
  let streetNumber = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_STREETNUMBER,
    isNotEncrypt
  );
  let apartmentName = getSessionStorageItem(
    keys.SSN_APPLICATION_APARTMENTSEARCH_APARTMENTNAME,
    isNotEncrypt
  );
  let buildingName = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_BUILDINGNAME,
    isNotEncrypt
  );
  let additionInformation = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_INDIVIDUALCONTRACTADDITIONALINFORMATION,
    isNotEncrypt
  );
  let inputRoomNumber_roomNumber = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTROOMNUMBER_ROOMNUMBER,
    isNotEncrypt
  );
  let inputCustomerInfo_roomNumber = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_ROOMNUMBER,
    isNotEncrypt
  );
  const prefecture = getSessionStorageItem(
    keys.SSN_APPLICATION_RECONFIRMADDRESS_PREFECTURE,
    isNotEncrypt
  );
  const city = getSessionStorageItem(
    keys.SSN_APPLICATION_RECONFIRMADDRESS_CITY,
    isNotEncrypt
  );
  const district = getSessionStorageItem(
    keys.SSN_APPLICATION_RECONFIRMADDRESS_DISTRICT,
    isNotEncrypt
  );
  const street1 = getSessionStorageItem(
    keys.SSN_APPLICATION_RECONFIRMADDRESS_STREET1,
    isNotEncrypt
  );
  const IsALCMProperty = getSessionStorageItem(
    keys.SSN_APPLICATION_APARTMENTSEARCH_ISALCMPROPERTY,
    isNotEncrypt
  );
  const deliveryServiceAddressStreetName = getSessionStorageItem(
    keys.SSN_APPLICATION_SELECTPLANTYPE_DELIVERYSERVICEADDRESSSTREETNAME,
    isNotEncrypt
  );

  const order = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order;

  const residenceType = order?.CMAP_ResidenceType__c;

  let installationLocationAddress = "",
    individualContractAdditionInfo = "",
    installationStreetNumber = "",
    installationBuildingName = "",
    installationRoomNumber = "",
    installationAddressBuildingTypeRadioButton = "",
    isStreetNumberDisabled = false,
    isBuildingNameDisabled = false,
    isRoomNumberDisabled = false;

  if (urlParentItem === STRING_REBUILDING) {
    installationLocationAddress = order?.CMAP_InstallationLocationAddress__c;
    installationStreetNumber = order?.CMAP_InstallationLocationHouseNumber__c;
    installationBuildingName = order?.CMAP_ApartmentPropertyName__c;
    installationRoomNumber = inputCustomerInfo_roomNumber;
    individualContractAdditionInfo = additionInformation;
  } else {
    if (isTypeCircuit) {
      if (residenceType === STRING_RESIDENCE_TYPE_APARTMENT) {
        H04Data.fields.titleText =
          pageContext[
            "RegisterInstallationAddress-InstallationAddressLabel"
          ]?.value;

        installationLocationAddress = address;
        individualContractAdditionInfo = additionInformation;
        installationStreetNumber = apartmentStreetBunch;
        installationBuildingName = apartmentName;

        if (IsALCMProperty) {
          installationRoomNumber = inputRoomNumber_roomNumber;

          isStreetNumberDisabled = true;
          isBuildingNameDisabled = true;
          isRoomNumberDisabled = true;
        } else {
          installationRoomNumber = inputCustomerInfo_roomNumber;

          isStreetNumberDisabled = true;
          isBuildingNameDisabled = true;
        }
      } else if (residenceType === STRING_RESIDENCE_TYPE_HOME) {
        installationLocationAddress = `${prefecture} ${city} ${district} ${street1}`;
        installationStreetNumber = streetNumber;
        installationBuildingName = buildingName;
        individualContractAdditionInfo = additionInformation;

        isRoomNumberDisabled = true;
      }
    } else {
      H04Data.fields.titleText =
        pageContext[
          "RegisterInstallationAddress-DeliveryServiceAddressLabel"
        ]?.value;

      installationLocationAddress = address;
      installationStreetNumber = streetNumber;
      installationBuildingName = buildingName;
      installationRoomNumber = inputCustomerInfo_roomNumber;
      individualContractAdditionInfo = additionInformation;
    }
  }

  useEffect(() => {
    if (isFirstTimeDisplay) {
      if (
        urlParentItem !== STRING_APPLY &&
        urlParentItem !== STRING_SIMULATOR
      ) {
        installationAddressBuildingTypeRadioButton =
          order?.CMAP_BuildingCategory__c;
      }
    } else {
      installationAddressBuildingTypeRadioButton = buildingDivision;
    }
  }, []);

  useEffect(() => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        registerInstallationAddressAddress: installationLocationAddress,
        registerInstallationAddressStreetNumber: installationStreetNumber,
        registerInstallationAddressBuildingName: installationBuildingName,
        registerInstallationAddressRoomNumber: installationRoomNumber,
        registerInstallationAddressAdditionInformation: individualContractAdditionInfo,
        registerInstallationAddressBuildingType: installationAddressBuildingTypeRadioButton,
      };
    });
  }, []);

  addressField[1].fields.descriptionText =
    inputValues.registerInstallationAddressAddress;

  streetNumberField.fields.inputValue = {
    value: inputValues.registerInstallationAddressStreetNumber,
    name: "registerInstallationAddressStreetNumber",
    isDisabled: isStreetNumberDisabled,
  };

  buildingNameField.fields.inputValue = {
    value: inputValues.registerInstallationAddressBuildingName,
    name: "registerInstallationAddressBuildingName",
    isDisabled: isBuildingNameDisabled,
  };

  roomNumberField.fields.inputValue = {
    value: inputValues.registerInstallationAddressRoomNumber,
    name: "registerInstallationAddressRoomNumber",
    isDisabled: isRoomNumberDisabled,
  };

  additionalInformationField.fields.inputValue = {
    value: inputValues.registerInstallationAddressAdditionInformation,
    name: "registerInstallationAddressAdditionInformation",
  };

  const [buildingTypeOptions, setBuildingTypeOptions] = useState([]);
  const selectBuildingTypeCategory = "BuildingType";
  S01Data.fields["category"] = { value: selectBuildingTypeCategory };
  S01Data.fields["selections"] = buildingTypeOptions;
  const [selectedBuildingType, setSelectedBuildingType] = useSetSelectedItem(
    selectBuildingTypeCategory
  );

  useEffect(() => {
    if (metadataDefinitions) {
      const CMAP_BuildingCategory__c =
        metadataDefinitions?.Order?.fieldMetadataDefinitions
          ?.CMAP_BuildingCategory__c?.fieldMetadata?.fieldMetadataExtension;

      const buildingCategory_OptionCondition = JSON.parse(
        CMAP_BuildingCategory__c?.CMAP_OptionCondition__c
      );

      if (buildingCategory_OptionCondition) {
        if (isTypeCircuit) {
          S01Data.fields.isHidden = false;
          const buildingType = buildingCategory_OptionCondition
            ?.find((type) => type.condition[0].value === residenceType)
            .listitem?.map((item) => {
              const itemArr = {
                Code: item.value,
                Value: item.label,
                Checked: false,
              };
              return itemArr;
            });
          setBuildingTypeOptions(buildingType);

          if (
            inputValues.registerInstallationAddressBuildingType &&
            buildingType
          ) {
            buildingType.forEach((item) => {
              if (
                item.Code ===
                inputValues.registerInstallationAddressBuildingType
              ) {
                setSelectedBuildingType({
                  [selectBuildingTypeCategory]: item.Code,
                });
              }
            });
          }
        } else {
          S01Data.fields.isHidden = true;
        }
      }
    }
  }, [metadataDefinitions]);

  useEffect(() => {
    if (selectedBuildingType) {
      setInputValues((prevState) => {
        return {
          ...prevState,
          registerInstallationAddressBuildingType:
            selectedBuildingType?.selected,
        };
      });
    }
  }, [selectedBuildingType]);

  return (
    <div className="form_wrapper-input" name="commufa-service-area-section">
      <Placeholder
        name="commufa-service-area-section"
        rendering={props.rendering}
      />
    </div>
  );
}
