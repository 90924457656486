import React, { useEffect, useState } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import {
  deepcopy,
  getPlaceholderFromComponent,
  getScDataFromPlaceholder,
  getScDataFromComponent,
  formatCurrencyChar,
} from "../../utils/commonUtils";

import { apiBaseURL } from "../../envSettings";
import {
  useGetDataViaGraphQL,
  useDynamicParallelQuery
} from "../../hooks/useGetData";
import { query as orderItemAttrCheckboxMasterQuery } from "../../assets/graphql/OrderItemAttrCheckboxMaster";
import {
  STRING_LOADING,
  STRING_POST,
  STRING_APPLY,
  STRING_SIMULATOR,
} from "../../utils/constantVariables";

import moment from "moment";
import {
	parseOptionString,
	matchMetaOptions
} from "../../utils/parseMetadata";

// Calculations
import { createAnnualExpenseObj, createBenefitObj, createInitialCostObj, createMonthlyExpenseObj } from "../calculations/Application-Detail-Confirmation/calculateApplicationDetailConfirmationResult"
import { groupByGroup } from "../calculations/Common/CommonProcess";

// TODO: delete test data

const addDataRowList = (key, value, quantity) => {
  return {
    fields: {
      Key: {
        value: key,
      },
      Value: {
        value: value,
      },
      Quantity: {
        value: quantity?.toString(),
      },
    },
  };
};

const buildProductMultipleGroupRows = (groupedItemsObj, hasTotal) => {
  const rowData = []

  for (const groupKey of Object.keys(groupedItemsObj)) {
    const group = groupedItemsObj[groupKey];

    const productItems = group.map((item) => {
      return {
        CMAP_ProductName__c: item.CMAP_ProductName__c,
        Quantity: item.Quantity,
        PriceTaxInc: item.PriceTaxInc,
      }
    });
  
    rowData.push({
      groupeName: group[0]?.CMAP_MainSubProductGroupName__c,
      productItems,
      hasTotal,
    });
  }

  return rowData;
}

const buildProductOneGroupRow = (itemsObj, groupText, hasTotal) => {
  const rowData = []

  if (itemsObj.length > 0) {
    const productItems = itemsObj.map((item) => {
      return {
        CMAP_ProductName__c: item.CMAP_ProductName__c,
        Quantity: item.Quantity,
        PriceTaxInc: item.PriceTaxInc,
      }
    });
  
    rowData.push({
      groupeName: groupText,
      productItems,
      hasTotal,
    });
  }

  return rowData;
}

const buildBenefitMultipleGroupRows = (groupedItemsObj) => {
  const rowData = []

  for (const groupKey of Object.keys(groupedItemsObj)) {
    const group = groupedItemsObj[groupKey];

    const benefitItems = group.map((item) => {
      return {
        CMAP_BenefitName__c: item.CMAP_BenefitName__c
      }
    });
  
    rowData.push({
      groupeName: group[0]?.CMAP_BenefitCampaignName__c,
      benefitItems,
    });
  }

  return rowData;
}

const createTextComponent = (
  componentList,
  component,
  key,
  fieldName,
  value
) => {
  let copiedComponent = deepcopy(component);
  copiedComponent.uid = component.uid.concat(key);
  copiedComponent.fields[fieldName] = value;
  componentList[componentList.length] = copiedComponent;
};

const createProdList = (
  componentList,
  rows,
  l02Component,
  pageContext,
  uid
) => {
  rows.forEach((row, i) => {
    let copiedL02 = deepcopy(l02Component);
    copiedL02.fields.showQuantityHeader = true;
    let dataRowList = copiedL02?.fields?.dataRowList;
    copiedL02.uid = l02Component.uid.concat(uid).concat(i.toString());
    copiedL02.fields.titleText = row.groupeName;

    let total = 0;
    row.productItems.forEach((productItem, j) => {
      total = total + productItem.PriceTaxInc;

      dataRowList[dataRowList.length] = addDataRowList(
        productItem.CMAP_ProductName__c,
        formatCurrencyChar(productItem.PriceTaxInc),
        productItem.Quantity
      );

      if (row.productItems.length === j + 1 && row.hasTotal) {
        dataRowList[dataRowList.length] = addDataRowList(
          pageContext["ProductDetailConfirmation-SubtotalLabel"]?.value,
          formatCurrencyChar(total)
        );
      }
    });
    componentList[componentList.length] = copiedL02;
  });
};

const createPlanBenefitList = (
  componentList,
  rows,
  l02Component,
  uid
) => {
  rows.forEach((row, i) => {
    let copiedL02 = deepcopy(l02Component);
    copiedL02.fields.hiddenHeader = true;
    let dataRowList = copiedL02?.fields?.dataRowList;
    copiedL02.uid = l02Component.uid.concat(uid).concat(i.toString());
    copiedL02.fields.titleText = row.groupeName;

    row.benefitItems.forEach((benefitItem) => {
      dataRowList[dataRowList.length] = addDataRowList(
        benefitItem.CMAP_BenefitName__c
      );
    });
    componentList[componentList.length] = copiedL02;
  });
};

const createAgencyBenefitList = (
  componentList,
  agencyBenefitList,
  l02Component,
  pageContext,
  uid
) => {
  let copiedL02 = deepcopy(l02Component);
  let agencyBenefitDetail = copiedL02?.fields?.dataRowList;
  copiedL02.uid = copiedL02?.uid.toString().concat(uid);
  copiedL02.fields.titleText = pageContext["ProductDetailConfirmation-AgencyBenefit"]?.value;
  copiedL02.fields.hiddenHeader = true;
  if (agencyBenefitList) {
    for (let i = 0; agencyBenefitList?.length > i; i++) {
      const benefitPrice = formatCurrencyChar(agencyBenefitList[i].BenefitPrice);
      agencyBenefitDetail[agencyBenefitDetail.length] = addDataRowList(
        `${agencyBenefitList[i].BenefitName.toString()} ${benefitPrice}`
      );
    }
  }
  componentList[componentList.length] = copiedL02;
};

const mappingForAfterProdList = (props, copiedComponentList, afterProdList, monthlyExpenseObj, annualExpenseObj, initialCostObj, benefitObj) => {
  // BEFORE
  const {
    orderRelatedInfo,
    benefitListAgency: agencyBenefitList,
    sitecoreContext,
  } = props;

  const pageContext = sitecoreContext.route.fields;

  const {
    copiedH03,
    copiedC10,
    copiedL01,
    copiedL02,
  } = copiedComponentList;

  // Build MonthlyAfter rows
  let monthlyAfterSelectionProductSubTotalRows = [];

  const groupedMonthlyAfterSelectionProductSubtotalObj = groupByGroup(monthlyExpenseObj.afterSelectionProductSubtotalObj);
  const monthlyAfterSelectionProductRows = buildProductMultipleGroupRows(groupedMonthlyAfterSelectionProductSubtotalObj, true);
  monthlyAfterSelectionProductSubTotalRows = monthlyAfterSelectionProductSubTotalRows.concat(monthlyAfterSelectionProductRows);

  const monthlyAfterAutomaticSelectionProductRow = buildProductOneGroupRow(monthlyExpenseObj.afterAutomaticSelectionProductSubtotalObj, pageContext["ProductDetailConfirmation-AutoApplicationProduct"].value, false);
  monthlyAfterSelectionProductSubTotalRows = monthlyAfterSelectionProductSubTotalRows.concat(monthlyAfterAutomaticSelectionProductRow);

  const monthlyAfterBenefitProductRow = buildProductOneGroupRow(monthlyExpenseObj.afterBenefitProductSubtotalObj, pageContext["ProductDetailConfirmation-BenefitProduct"].value, false);
  monthlyAfterSelectionProductSubTotalRows = monthlyAfterSelectionProductSubTotalRows.concat(monthlyAfterBenefitProductRow);


  // Build AnnualAfter rows
  let annualAfterSelectionProductSubTotalRows = [];

  const groupedAnnualAfterSelectionProductSubtotalObj = groupByGroup(annualExpenseObj.afterSelectionProductSubtotalObj);
  const annualAfterSelectionProductRows = buildProductMultipleGroupRows(groupedAnnualAfterSelectionProductSubtotalObj, true);
  annualAfterSelectionProductSubTotalRows = annualAfterSelectionProductSubTotalRows.concat(annualAfterSelectionProductRows);

  const annualAfterAutomaticSelectionProductRow = buildProductOneGroupRow(annualExpenseObj.afterAutomaticSelectionProductSubtotalObj, pageContext["ProductDetailConfirmation-AutoApplicationProduct"].value, false);
  annualAfterSelectionProductSubTotalRows = annualAfterSelectionProductSubTotalRows.concat(annualAfterAutomaticSelectionProductRow);

  const annualAfterBenefitProductRow = buildProductOneGroupRow(annualExpenseObj.afterBenefitProductSubtotalObj, pageContext["ProductDetailConfirmation-BenefitProduct"].value, false);
  annualAfterSelectionProductSubTotalRows = annualAfterSelectionProductSubTotalRows.concat(annualAfterBenefitProductRow);


  // Build InitialAfter rows
  let initialAfterSelectionProductSubTotalRows = [];

  const groupedInitialAfterSelectionProductSubtotalObj = groupByGroup(initialCostObj.afterSelectionProductSubtotalObj);
  const initialSelectionProductRows = buildProductMultipleGroupRows(groupedInitialAfterSelectionProductSubtotalObj, true);
  initialAfterSelectionProductSubTotalRows = initialAfterSelectionProductSubTotalRows.concat(initialSelectionProductRows);

  const initialAfterAutomaticSelectionProductRow = buildProductOneGroupRow(initialCostObj.afterAutomaticSelectionProductSubtotalObj, pageContext["ProductDetailConfirmation-AutoApplicationProduct"].value, false);
  initialAfterSelectionProductSubTotalRows = initialAfterSelectionProductSubTotalRows.concat(initialAfterAutomaticSelectionProductRow);

  const initialAfterBenefitProductRow = buildProductOneGroupRow(initialCostObj.afterBenefitProductSubtotalObj, pageContext["ProductDetailConfirmation-BenefitProduct"].value, false);
  initialAfterSelectionProductSubTotalRows = initialAfterSelectionProductSubTotalRows.concat(initialAfterBenefitProductRow);


  // Build BenefitAfter rows
  const groupedBenefitAfterObj = groupByGroup(benefitObj.afterBenefitObj, "CMAP_BenefitCampaignName__c");
  const benefitAfterRows = buildBenefitMultipleGroupRows(groupedBenefitAfterObj);


  if (copiedL01?.fields?.dataRowList) {
    copiedL01.fields.dataRowList.splice(0);
    copiedL02.fields.dataRowList.splice(0);
  }

  // Monthly
  createTextComponent(
    afterProdList,
    copiedH03,
    "t2h1",
    "titleText",
    pageContext["ProductDetailConfirmation-MonthlyFeeLabel"],
    props
  );
  createTextComponent(
    afterProdList,
    copiedC10,
    "t2l1",
    "detailsText",
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_OfferPlanName__c,
    props
  );
  createProdList(
    afterProdList,
    monthlyAfterSelectionProductSubTotalRows,
    copiedL02,
    pageContext,
    "t2dl1",
  );

  // Annual
  if (annualAfterSelectionProductSubTotalRows.length > 0) {
    createTextComponent(
      afterProdList,
      copiedH03,
      "t2h2",
      "titleText",
      pageContext["ProductDetailConfirmation-AnnualFeeLabel"],
      props
    );
    createProdList(
      afterProdList,
      annualAfterSelectionProductSubTotalRows,
      copiedL02,
      pageContext,
      "t2dl2",
    );
  }

  // Initial
  if (initialAfterSelectionProductSubTotalRows.length > 0) {
    createTextComponent(
      afterProdList,
      copiedH03,
      "t2h3",
      "titleText",
      pageContext["ProductDetailConfirmation-InitialCostLabel"],
      props
    );
    createProdList(
      afterProdList,
      initialAfterSelectionProductSubTotalRows,
      copiedL02,
      pageContext,
      "t2dl3",
    );
  }

  // PlanBenefit
  if (benefitObj.afterBenefitObj.length > 0 || agencyBenefitList?.length > 0) {
    createTextComponent(
      afterProdList,
      copiedH03,
      "t2h4",
      "titleText",
      pageContext["ProductDetailConfirmation-PlanBenefitLabel"],
      props
    );

    if (benefitObj.afterBenefitObj.length > 0) {
      createPlanBenefitList(
        afterProdList,
        benefitAfterRows,
        copiedL02,
        "t2dl4",
      );
    }
    if (agencyBenefitList?.length > 0) {
      createAgencyBenefitList(
        afterProdList,
        agencyBenefitList,
        copiedL02,
        pageContext,
        "t1dl5",
      );
    }
  }
}

const mappingForBeforeProdList = (props, copiedComponentList, beforeProdList, monthlyExpenseObj, annualExpenseObj, initialCostObj, benefitObj) => {
  const {
    beforeChangeOrderRelatedInfo,
    benefitListAgency: agencyBenefitList,
    sitecoreContext,
  } = props;

  const pageContext = sitecoreContext.route.fields;

  const {
    copiedH03,
    copiedC10,
    copiedL02,
  } = copiedComponentList;

  // Build MonthlyBefore rows
  let monthlyBeforeSelectionProductSubTotalRows = [];

  const groupedMonthlyBeforeSelectionProductSubtotalObj = groupByGroup(monthlyExpenseObj.beforeSelectionProductSubtotalObj);
  const monthlyBeforeSelectionProductRows = buildProductMultipleGroupRows(groupedMonthlyBeforeSelectionProductSubtotalObj, true);
  monthlyBeforeSelectionProductSubTotalRows = monthlyBeforeSelectionProductSubTotalRows.concat(monthlyBeforeSelectionProductRows);

  const monthlyBeforeAutomaticSelectionProductRow = buildProductOneGroupRow(monthlyExpenseObj.beforeAutomaticSelectionProductSubtotalObj, pageContext["ProductDetailConfirmation-AutoApplicationProduct"].value, false);
  monthlyBeforeSelectionProductSubTotalRows = monthlyBeforeSelectionProductSubTotalRows.concat(monthlyBeforeAutomaticSelectionProductRow);

  const monthlyBeforeBenefitProductRow = buildProductOneGroupRow(monthlyExpenseObj.beforeBenefitProductSubtotalObj, pageContext["ProductDetailConfirmation-BenefitProduct"].value, false);
  monthlyBeforeSelectionProductSubTotalRows = monthlyBeforeSelectionProductSubTotalRows.concat(monthlyBeforeBenefitProductRow);


  // Build AnnualBefore rows
  let annualBeforeSelectionProductSubTotalRows = [];

  const groupedAnnualBeforeSelectionProductSubtotalObj = groupByGroup(annualExpenseObj.beforeSelectionProductSubtotalObj);
  const annualBeforeSelectionProductRows = buildProductMultipleGroupRows(groupedAnnualBeforeSelectionProductSubtotalObj, true);
  annualBeforeSelectionProductSubTotalRows = annualBeforeSelectionProductSubTotalRows.concat(annualBeforeSelectionProductRows);

  const annualBeforeAutomaticSelectionProductRow = buildProductOneGroupRow(annualExpenseObj.beforeAutomaticSelectionProductSubtotalObj, pageContext["ProductDetailConfirmation-AutoApplicationProduct"].value, false);
  annualBeforeSelectionProductSubTotalRows = annualBeforeSelectionProductSubTotalRows.concat(annualBeforeAutomaticSelectionProductRow);

  const annualBeforeBenefitProductRow = buildProductOneGroupRow(annualExpenseObj.beforeBenefitProductSubtotalObj, pageContext["ProductDetailConfirmation-BenefitProduct"].value, false);
  annualBeforeSelectionProductSubTotalRows = annualBeforeSelectionProductSubTotalRows.concat(annualBeforeBenefitProductRow);


  // Build InitialBefore rows
  let initialBeforeSelectionProductSubTotalRows = [];

  const groupedInitialBeforeSelectionProductSubtotalObj = groupByGroup(initialCostObj.beforeSelectionProductSubtotalObj);
  const initialBeforeSelectionProductRows = buildProductMultipleGroupRows(groupedInitialBeforeSelectionProductSubtotalObj, true);
  initialBeforeSelectionProductSubTotalRows = initialBeforeSelectionProductSubTotalRows.concat(initialBeforeSelectionProductRows);

  const initialBeforeAutomaticSelectionProductRow = buildProductOneGroupRow(initialCostObj.beforeAutomaticSelectionProductSubtotalObj, pageContext["ProductDetailConfirmation-AutoApplicationProduct"].value, false);
  initialBeforeSelectionProductSubTotalRows = initialBeforeSelectionProductSubTotalRows.concat(initialBeforeAutomaticSelectionProductRow);

  const initialBeforeBenefitProductRow = buildProductOneGroupRow(initialCostObj.beforeBenefitProductSubtotalObj, pageContext["ProductDetailConfirmation-BenefitProduct"].value, false);
  initialBeforeSelectionProductSubTotalRows = initialBeforeSelectionProductSubTotalRows.concat(initialBeforeBenefitProductRow);


  // Build BenefitAfter rows
  const groupedBenefitBeforeObj = groupByGroup(benefitObj.beforeBenefitObj, "CMAP_BenefitCampaignName__c");
  const benefitBeforeRows = buildBenefitMultipleGroupRows(groupedBenefitBeforeObj);


  // Monthly
  createTextComponent(
    beforeProdList,
    copiedH03,
    "t1h1",
    "titleText",
    pageContext["ProductDetailConfirmation-MonthlyFeeLabel"],
    props
  );
  createTextComponent(
    beforeProdList,
    copiedC10,
    "t1l1",
    "detailsText",
    beforeChangeOrderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.CMAP_OfferPlanName__c,
    props
  );
  createProdList(
    beforeProdList,
    monthlyBeforeSelectionProductSubTotalRows,
    copiedL02,
    pageContext,
    "t1l2"
  );

  // Annual
  if (annualBeforeSelectionProductSubTotalRows.length > 0) {
    createTextComponent(
      beforeProdList,
      copiedH03,
      "t1h2",
      "titleText",
      pageContext["ProductDetailConfirmation-AnnualFeeLabel"],
      props
    );
    createProdList(
      beforeProdList,
      annualBeforeSelectionProductSubTotalRows,
      copiedL02,
      pageContext,
      "t1dl2"
    );
  }

  // Initial
  if (initialBeforeSelectionProductSubTotalRows.length > 0) {
    createTextComponent(
      beforeProdList,
      copiedH03,
      "t1h3",
      "titleText",
      pageContext["ProductDetailConfirmation-InitialCostLabel"],
      props
    );
    createProdList(
      beforeProdList,
      initialBeforeSelectionProductSubTotalRows,
      copiedL02,
      pageContext,
      "t1dl3"
    );
  }

  // PlanBenefit
  if (benefitObj.beforeBenefitObj.length > 0 || agencyBenefitList?.length > 0) {
    createTextComponent(
      beforeProdList,
      copiedH03,
      "t1h4",
      "titleText",
      pageContext["ProductDetailConfirmation-PlanBenefitLabel"],
      props
    );

    if (benefitObj.beforeBenefitObj.length > 0) {
      createPlanBenefitList(
        beforeProdList,
        benefitBeforeRows,
        copiedL02,
        "t1dl4"
      );
    }
    if (agencyBenefitList?.length > 0) {
      createAgencyBenefitList(
        beforeProdList,
        agencyBenefitList,
        copiedL02,
        pageContext,
        "t1dl5",
      );
    }
  }
}

const createPlanAttributeList = (planAttributeList, l01Component, copiedH03, relatedInfo, uid, componentList, attributeLabel, productIds, orderItemAttrCheckboxList) => {
  if (planAttributeList && planAttributeList.length > 0) {
    const orderItemInfos = relatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos;
    if (orderItemInfos && orderItemInfos?.length > 0) {
      let planAttributeLabel = deepcopy(copiedH03);
      planAttributeLabel.fields.titleText.value = `${attributeLabel}`;

      componentList[componentList.length] = planAttributeLabel;

      orderItemInfos.forEach((itemInfo, index) => {
        if (itemInfo?.orderItem?.CMAP_HasOrderItemAttribute__c) {
          let planAttribute = deepcopy(l01Component);
          planAttribute.fields.titleText.value = `${itemInfo?.orderItem?.CMAP_ProductName__c}登録情報`;
          planAttribute.uid = planAttribute?.uid
            .toString()
            .concat(uid)
            .concat(index.toString());
          planAttribute.fields.hiddenHeader = true;

          const productIndex = productIds.findIndex(
            (e) => e.productId === itemInfo.orderItem.Product2Id
          );
          const metadata = planAttributeList[productIndex];

          if (metadata) {
            Object.values(metadata).forEach((defInfo) => {
              // populate L01 dataRowList
              itemInfo.orderItemAttributes.forEach((itemAttr) => {
                Object.values(defInfo.fieldMetadataDefinitions  ?? []).forEach(
                  (fieldInfo) => {
                    const fieldMetadataExtension =
                      fieldInfo?.fieldMetadata?.fieldMetadataExtension;
                    const fieldMetadataAddition =
                      fieldInfo?.fieldMetadata?.fieldMetadataAddition;
                      
                    if (
                      fieldMetadataAddition.orderItemAttributeDefinitionId ===
                      itemAttr.CMAP_OrderItemAttributeDefenition__c
                    ) {
                      // determine the value displayed
                      let itemValue;

                      switch (fieldMetadataExtension?.CMAP_Type__c?.toLocaleLowerCase()) {
                        case 'long':
                          itemValue = itemAttr?.CMAP_ValueLongTextArea__c.replace(/(?:\r\n|\r|\n)/g, '<br>');
                          break;
                        case 'date':
                          itemValue = moment(itemAttr?.CMAP_Value__c).format("YYYY年MM月DD日");
                          break;
                        case 'datetime':
                          itemValue = itemValue = moment(itemAttr?.CMAP_Value__c).format("YYYY年MM月DD日 hh:mm:ss");
                          break;
                        case 'checkbox':
                          const matchCheckboxValue = orderItemAttrCheckboxList.find((orderItemAttrCheckbox) => !!orderItemAttrCheckbox.code === (itemAttr?.CMAP_Value__c.toLowerCase() === "true"))?.value;
                          itemValue = matchCheckboxValue;
                          break;
                        case 'combobox':
                          const matchOptionLabel = matchMetaOptions(parseOptionString(fieldMetadataExtension?.CMAP_Option__c), itemAttr?.CMAP_Value__c);
                          itemValue = matchOptionLabel;
                          break;
                        default:
                          itemValue = itemAttr?.CMAP_Value__c.replace(/(?:\r\n|\r|\n)/g, '<br>');
                      }

                      const row = {
                        ...addDataRowList(
                          fieldMetadataExtension?.CMAP_FieldLabel__c,
                          itemValue
                        ),
                        CMAP_FieldName__c: fieldMetadataExtension?.CMAP_FieldName__c,
                      };
                      planAttribute.fields.dataRowList.push(row);
                    }
                  }
                );
              });

              // order the fields
              const fieldOrders = defInfo.fieldOrders ?? [];
              const sortOrder = {};
              for (let i = 0; i < fieldOrders.length; i++) {
                sortOrder[fieldOrders[i]] = i;
              }
              planAttribute.fields.dataRowList.sort((p1, p2) => sortOrder[p1?.CMAP_FieldName__c] - sortOrder[p2?.CMAP_FieldName__c]);
            });

            componentList[componentList.length] = planAttribute;
          }
        }
      })
    }
  }
}

const mappingForProdListItemAttrDefinitionAfter = (props, copiedComponentList, afterItemAttrDefinitionProdList, orderItemAttrDefinition, productIds, orderItemAttrCheckboxList) => {
  const {
    sitecoreContext
  } = props;

  const pageContext = sitecoreContext.route.fields;

  const {
    copiedH03,
    copiedL01,
  } = copiedComponentList;

  createPlanAttributeList(
    orderItemAttrDefinition,
    copiedL01,
    copiedH03,
    props?.orderRelatedInfo,
    "t2l2",
    afterItemAttrDefinitionProdList,
    pageContext["ProductDetailConfirmation-PlanAttributeLabel"]?.value,
    productIds,
    orderItemAttrCheckboxList
  );
}

const mappingForProdListItemAttrDefinitionBefore = (props, copiedComponentList, beforeItemAttrDefinitionProdList, orderItemAttrDefinitionBeforeChange, productIdsBeforeChange, orderItemAttrCheckboxList) => {
  const {
    sitecoreContext
  } = props;

  const pageContext = sitecoreContext.route.fields;

  const {
    copiedH03,
    copiedL01,
  } = copiedComponentList;

  createPlanAttributeList(
    orderItemAttrDefinitionBeforeChange,
    copiedL01,
    copiedH03,
    props?.beforeChangeOrderRelatedInfo,
    "t1l2",
    beforeItemAttrDefinitionProdList,
    pageContext["ProductDetailConfirmation-PlanAttributeLabel"]?.value,
    productIdsBeforeChange,
    orderItemAttrCheckboxList
  );
}

export const CommonProductDetailConfirmation = (props) => {
  const {
    orderRelatedInfo,
    orderChannelCategory,
    beforeChangeOrderRelatedInfo,
    urlParentName,
  } = props;

  const [productIdsBeforeChange, setProductIdsBeforeChange] = useState([]);
  const [productIds, setProductIds] = useState([]);

  const [orderItems, setOrderItems] = useState({});
  const [orderItemsBeforeChange, setOrderItemsBeforeChange] = useState({});
  const [orderItemAttrDefinition, setOrderItemAttrDefinition] = useState([]);
  const [orderItemAttrDefinitionBeforeChange, setOrderItemAttrDefinitionBeforeChange] = useState([]);
  
  const prodListSection = getScDataFromPlaceholder(
    "plan-and-product-list-section",
    props
  );
  const [productDetailConfirmationCompareTab] = getScDataFromComponent("P-06", prodListSection);
  const tab1PlaceHolder = getPlaceholderFromComponent("tab-1-content", productDetailConfirmationCompareTab);
  const tab2PlaceHolder = getPlaceholderFromComponent("tab-2-content", productDetailConfirmationCompareTab);
  const templates = getPlaceholderFromComponent("tab-3-temp-content", productDetailConfirmationCompareTab);

  const [isTab1Hidden, setIsTab1Hidden] = useState(false);
  const [isTab2Hidden, setIsTab2Hidden] = useState(false);
  productDetailConfirmationCompareTab.fields.isTab1Hidden = isTab1Hidden;
  productDetailConfirmationCompareTab.fields.isTab2Hidden = isTab2Hidden;

  const copiedH03 = deepcopy(getScDataFromComponent("H-03", templates)[0]);
  const copiedC10 = deepcopy(getScDataFromComponent("C-10", templates)[0]);
  const copiedL02 = deepcopy(getScDataFromComponent("L-02", templates)[0]);
  const copiedL01 = deepcopy(getScDataFromComponent("L-01", templates)[0]);
  
  const copiedComponentList = {
    copiedH03,
    copiedC10,
    copiedL02,
    copiedL01,
  };

  const beforeProdList = [];
  const afterProdList = [];
  const beforeItemAttrDefinitionProdList = [];
  const afterItemAttrDefinitionProdList = [];

  // get master data
  const orderItemAttrCheckboxMaster = useGetDataViaGraphQL(
    orderItemAttrCheckboxMasterQuery,
    null,
    "orderItemAttrCheckboxMaster"
  );

  const [orderItemAttrCheckboxList, setOrderItemAttrCheckboxList] = useState(null);

  const retrieveOrderItemAttrDefinitionsEndpoint = `${apiBaseURL}order/RetrieveOrderItemAttrDefinitions`;
  const {
    isLoading: OrderItemAttrLoading,
    results: OrderItemAttrDataResponse,
  } = useDynamicParallelQuery(
    productIds,
    retrieveOrderItemAttrDefinitionsEndpoint,
    STRING_POST
  );

  const {
    isLoading: OrderItemAttrLoadingBeforeChange,
    results: OrderItemAttrDataResponseBeforeChange,
  } = useDynamicParallelQuery(
    productIdsBeforeChange,
    retrieveOrderItemAttrDefinitionsEndpoint,
    STRING_POST
  );

  const isApiLoading = 
    OrderItemAttrLoading ||
    OrderItemAttrLoadingBeforeChange ||
    orderItemAttrCheckboxMaster === STRING_LOADING;
  props.setIsLoading(isApiLoading);

  // Map products
  useEffect(() => {
    // Get calculation objects
    // TODO: delete test data
    const monthlyExpenseObj = createMonthlyExpenseObj();

    // TODO: delete test data
    const annualExpenseObj = createAnnualExpenseObj();
    
    // TODO: delete test data
    const initialCostObj = createInitialCostObj();

    // TODO: delete test data
    const benefitObj = createBenefitObj();

    if (urlParentName === STRING_APPLY || urlParentName === STRING_SIMULATOR) {
      // hide tabs
      productDetailConfirmationCompareTab.fields.isTabsHidden = true;
      productDetailConfirmationCompareTab.fields.showOnlyTab2 = true;

      // populate component list
      mappingForAfterProdList(props, copiedComponentList, afterProdList, monthlyExpenseObj, annualExpenseObj, initialCostObj, benefitObj);

      // add component list under the tab
      tab2PlaceHolder.push(...afterProdList);
    } else {
      // set second tab active
      productDetailConfirmationCompareTab.fields.isSecondTabActiveByDefault = true;

      // populate component lists
      mappingForAfterProdList(props, copiedComponentList, afterProdList, monthlyExpenseObj, annualExpenseObj, initialCostObj, benefitObj);
      mappingForBeforeProdList(props, copiedComponentList, beforeProdList, monthlyExpenseObj, annualExpenseObj, initialCostObj, benefitObj);

      // add component lists under the tabs
      tab2PlaceHolder.push(...afterProdList);
      tab1PlaceHolder.push(...beforeProdList);
    }
  }, []);

  // receive orderItemAttrCheckboxMaster data
  useEffect(() => {
    const orderItemAttrCheckboxData = orderItemAttrCheckboxMaster?.orderItemAttrCheckboxMaster?.children?.map(
      (item) => {
        return {
          value: item.fields.find((x) => x.name === "Value")?.value,
          code: parseInt(item.fields.find((x) => x.name === "Code")?.value),
        };
      }
    );
    if (orderItemAttrCheckboxData?.length) {
      setOrderItemAttrCheckboxList(orderItemAttrCheckboxData);
    }
  }, [orderItemAttrCheckboxMaster]);

  useEffect(() => {
    if (
      orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos &&
      orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos?.length > 0
    ) {
      let productIdsArr = [];
      let body = [];
      orderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos.forEach((item) => {
        if (item?.orderItem?.CMAP_HasOrderItemAttribute__c === true) {
          body = {
            productId: item.orderItem.Product2Id,
            orderChannelCategory: orderChannelCategory
          };
          productIdsArr.push(body);
        }
      });
      setProductIds(productIdsArr || []);
    }
  }, []);

  useEffect(() => {
    if (urlParentName !== STRING_APPLY && urlParentName !== STRING_SIMULATOR) {
      if (
        beforeChangeOrderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos &&
        beforeChangeOrderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos?.length > 0
      ) {
        let productIdsArr = [];
        let body = [];
        beforeChangeOrderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos.forEach((item) => {
          if (item?.orderItem?.CMAP_HasOrderItemAttribute__c === true) {
            body = {
              productId: item.orderItem.Product2Id,
              orderChannelCategory: orderChannelCategory
            };
            productIdsArr.push(body);
          }
        });
        setProductIdsBeforeChange(productIdsArr || []);
      }
    }
  }, []);

  useEffect(() => {
    if (OrderItemAttrLoading === false && OrderItemAttrDataResponse.length > 0)
      if (OrderItemAttrDataResponse[0].isSuccess) {
        setOrderItems(OrderItemAttrDataResponse);
      }
  }, [OrderItemAttrLoading]);

  useEffect(() => {
    if (OrderItemAttrLoadingBeforeChange === false && OrderItemAttrDataResponseBeforeChange.length > 0)
      if (OrderItemAttrDataResponseBeforeChange[0].isSuccess) {
        setOrderItemsBeforeChange(OrderItemAttrDataResponseBeforeChange);
      }
  }, [OrderItemAttrLoadingBeforeChange]);

  useEffect(() => {
    if (orderItems && orderItems.length > 0) {
      setIsTab2Hidden(true);

      const item = orderItems?.map((application) => application?.data?.data?.orderItemAttributeDefinitionInfo?.metadataDefinitions);
      setOrderItemAttrDefinition(item);
    }
  }, [orderItems]);

  useEffect(() => {
    if (orderItemsBeforeChange && orderItemsBeforeChange.length > 0) {
      setIsTab1Hidden(true);

      const item = orderItemsBeforeChange?.map((application) => application?.data?.data?.orderItemAttributeDefinitionInfo?.metadataDefinitions);
      setOrderItemAttrDefinitionBeforeChange(item);
    }
  }, [orderItemsBeforeChange]);

  useEffect(() => {
    if (orderItemAttrDefinition.length > 0 && orderItemAttrCheckboxList) {
      // populate component list
      mappingForProdListItemAttrDefinitionAfter(props, copiedComponentList, afterItemAttrDefinitionProdList, orderItemAttrDefinition, productIds, orderItemAttrCheckboxList);

      // add component list under the tab
      tab2PlaceHolder.push(...afterItemAttrDefinitionProdList);

      // force reload
      setIsTab2Hidden(false);
    }
  }, [orderItemAttrDefinition, orderItemAttrCheckboxList]);

  useEffect(() => {
    if (orderItemAttrDefinitionBeforeChange.length && orderItemAttrCheckboxList) {
      // populate component list
      mappingForProdListItemAttrDefinitionBefore(props, copiedComponentList, beforeItemAttrDefinitionProdList, orderItemAttrDefinitionBeforeChange, productIdsBeforeChange, orderItemAttrCheckboxList);

      // add component list under the tab
      tab1PlaceHolder.push(...beforeItemAttrDefinitionProdList);

      // force reload
      setIsTab1Hidden(false);
    }
  }, [orderItemAttrDefinitionBeforeChange, orderItemAttrCheckboxList]);

  return (
    <>
      <div className="form_wrapper-input" name="plan-and-product-list-section">
        <Placeholder
          name="plan-and-product-list-section"
          rendering={props.rendering}
        />
      </div>
      <div className="btn_container" name="button-first-section">
        <Placeholder
          name="button-first-section"
          rendering={props.rendering}
        />
      </div>
    </>
  )
}