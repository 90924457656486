import React, { useEffect, useState } from "react";
import moment from "moment";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import { getScDataFromComponent, getScDataFromPlaceholder, redirectToLinkUrl } from "../../utils/commonUtils";
import { getSessionStorageItem, setSessionStorageItem } from "../../utils/useSessionStorage";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { SelectPlanAttribute } from "./selectPlanAttribute";
import { SelectPlanBenefitsPackage } from "./selectPlanBenefitsPackage";
import { SelectPlan } from "./selectPlan";
import { SelectProduct } from "./selectProduct";
import {
  STRING_CATEGORY_PLAN_CHANGE,
  STRING_CATEGORY_NEW_CONTRACT,
  STRING_CATEGORY_MOVEMENT,
  STRING_CURRENT_SECTION_SELECT_PRODUCT,
  STRING_CURRENT_SECTION_SELECT_BENEFIT,
  STRING_CURRENT_SECTION_SELECT_PLAN_ATTRIBUTES,
  STRING_CATEGORY_PRODUCT_CHANGE,
  STRING_CATEGORY_TRANSFER,
  STRING_GET,
  STRING_RESIDENCE_TYPE_HOME,
  STRING_POST,
  STRING_CURRENT_SECTION_SELECT_PLAN,
} from "../../utils/constantVariables";
import { PRODUCTSELECT_AUTOSELECTPRODUCT, PRODUCTSELECT_NEXT } from "../Select-Plan-Product-Layout";

import { useCustomQuery } from "../../hooks/useGetData";
import { apiBaseURL as API_BASE_URL } from "../../envSettings";

const orderRelatedInfo = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);

const mappedPlanChangeModal = (props, setTriggeredChangePlanWorkFlow, setTriggeredMovementWorkFlow) => {
  const sitecoreContext = props?.sitecoreContext;
  const pageContext = sitecoreContext?.route.fields;
  const section = getScDataFromPlaceholder("form-heading-1", props);
  const opticalLineSection = getScDataFromPlaceholder("optical-line-section", props);

  const popup = getScDataFromComponent("P0-01", section);
  popup[0].fields.isCustom = true;

  const planChangeModal = getScDataFromComponent(
    "Plan-Change-Modal-Layout",
    getScDataFromPlaceholder("modal-section", popup[0])
  );

  planChangeModal[0].fields = {
    descriptionText: pageContext["PlanChangeModal-DescriptionLabel"],
    closeText: pageContext["PlanChangeModal-BackButtonText"],
    confirmText: pageContext["PlanChangeModal-PlanChangeButtonText"],
    modalTarget: popup[0].fields.modalTarget,
    onConfirmClick: () => {
      const currentOrderCategory =
        orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition?.orderCategoryCurrentlySelected;

      opticalLineSection.splice(0);

      if (
        currentOrderCategory === STRING_CATEGORY_PRODUCT_CHANGE ||
        currentOrderCategory === STRING_CATEGORY_TRANSFER
      ) {
        setSessionStorageItem(
          sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
          {
            ...orderRelatedInfo,
            orderRelatedInfo: {
              ...orderRelatedInfo.orderRelatedInfo,
              orderInfo: {
                ...orderRelatedInfo.orderRelatedInfo.orderInfo,
                orderAddition: {
                  ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition,
                  orderCategoryCurrentlySelected: STRING_CATEGORY_PLAN_CHANGE,
                },
              },
            },
          },
          true
        );
        redirectToLinkUrl(pageContext["SelectedPlanSection-RedirectToSelectPlanType"]);
      } else if (currentOrderCategory === STRING_CATEGORY_PLAN_CHANGE) {
        setTriggeredChangePlanWorkFlow(true);
      } else if (currentOrderCategory === STRING_CATEGORY_MOVEMENT) {
        setTriggeredMovementWorkFlow(true);
      }

      props.setBM05Data("");
      props.setIsPlanSelectionCompleted(false);
      props.setCurrentSection(STRING_CURRENT_SECTION_SELECT_PLAN);

      props.setIsProductSelectionCompleted(false);
      props.setSelectProductSectionState(null);
      // props.setBM05Data("disabled");

      return true;
    },
    onCancelClick: () => {
      return false;
    },
  };
};

const SelectPlanProductLeftLayout = (props) => {

  const {
    rendering,
    currentSection,
    isPlanSelectionCompleted,
    isProductSelectionCompleted,
    isPlanBenefitCompleted,
    isPlanAttributeCompleted,
    setBM05Data,
    setCurrentSection,
    setIsPlanSelectionCompleted,
    setIsProductSelectionCompleted,
    setIsPlanBenefitCompleted,
    setIsPlanAttributeCompleted,
    selectProductSectionState,
    setSelectProductSectionState,
    setIsPackageChange,
  } = props;

  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  const personalizeInfo = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO, true);
  const selectedPlanType = getSessionStorageItem(sessionKeys.SSN_APPLICATION_SELECTPLANTYPE_PLANTYPE, true);
  const { orderRelatedInfo } = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);
  const orderCategoryCurrentlySelected = orderRelatedInfo?.orderInfo?.orderAddition?.orderCategoryCurrentlySelected;
  const [opticalLineSectionInitialData, setOpticalLineSectionInitialData] = useState();

  const [enteredClass, setEnteredClass] = useState("");
  const [triggeredChangePlanWorkFlow, setTriggeredChangePlanWorkFlow] = useState(false);
  const [triggeredMovementWorkFlow, setTriggeredMovementWorkFlow] = useState(false);

  const { data: retrieveSystemTimeDateData, isLoading: isRetrieveSystemTimeDateLoading } = useCustomQuery(
    `${API_BASE_URL}common/RetrieveSystemDateTime`,
    true,
    STRING_GET
  );

  const [retrieveOfferPlanListBaseURI, setRetrieveOfferPlanListBaseURI] = useState(null);
  const {
    data: retrieveOfferPlanListData,
    isLoading: isRetrieveOfferPlanListLoading,
    refetch: retrieveOfferPlanListRefetch,
  } = useCustomQuery(retrieveOfferPlanListBaseURI, false, STRING_GET);

  const [retrieveAutoSelectProductRequest, setRetrieveAutoSelectProductRequest] = useState(null);
  const {
    data: retrieveAutoSelectProductList,
    isLoading: isRetrieveAutoSelectProductLoading,
    refetch: retrieveAutoSelectProductRefetch,
  } = useCustomQuery(`${API_BASE_URL}order/AutoSelectProducts`, false, STRING_POST, retrieveAutoSelectProductRequest);

  props.setIsLoading(
    isRetrieveOfferPlanListLoading || isRetrieveSystemTimeDateLoading || isRetrieveAutoSelectProductLoading
  );

  useEffect(() => {
    if (!retrieveOfferPlanListBaseURI) return;
    retrieveOfferPlanListRefetch();
  }, [retrieveOfferPlanListBaseURI]);

  useEffect(() => {
    if (selectProductSectionState === PRODUCTSELECT_AUTOSELECTPRODUCT) {
      const orderRelatedInfo = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);
      setRetrieveAutoSelectProductRequest(orderRelatedInfo);
    } else if (selectProductSectionState === PRODUCTSELECT_NEXT) {
      const orderRelatedInfo = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);
      const updatedOrderItemInfos = orderRelatedInfo?.orderRelatedInfo?.orderInfo.orderItemInfos;

      retrieveAutoSelectProductList.orderItemInfos.forEach((item) => {
        const index = updatedOrderItemInfos.findIndex(
          ({ orderItem }) => orderItem.Product2Id === item.orderItem.Product2Id
        );

        if (index === -1) updatedOrderItemInfos.push(item);
        else
          updatedOrderItemInfos[index] = {
            ...updatedOrderItemInfos[index],
            ...item,
          };
      });

      orderRelatedInfo.orderRelatedInfo.orderInfo.orderItemInfos = updatedOrderItemInfos;
      setSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO, orderRelatedInfo, true);
    }
  }, [selectProductSectionState]);

  useEffect(() => {
    if (retrieveAutoSelectProductRequest) {
      retrieveAutoSelectProductRefetch();
    }
  }, [retrieveAutoSelectProductRequest]);

  useEffect(() => {
    if (!retrieveSystemTimeDateData) return;
    const accountInfo = orderRelatedInfo?.accountInfo;
    const orderInfo = orderRelatedInfo?.orderInfo;

    const parsedDate = moment(retrieveSystemTimeDateData?.SystemDateTime, "YYYY-MM-DDTHH:mm:ss.SSSZ");

    const queryParams = new URLSearchParams();
    if (personalizeInfo.AccountName) {
      queryParams.set("orderChannelCategory", personalizeInfo.AppChannelDivision);
      queryParams.set("agencyId", orderInfo?.order?.CMAP_AcquisitionAgency__c);
      queryParams.set("shopId", orderInfo?.order?.CMAP_AcquisitionShop__c);
    } else {
      queryParams.set("orderChannelCategory", "Web");
    }

    queryParams.set("contractName", accountInfo?.account?.CMAP_ContractName__c);
    queryParams.set("planType", selectedPlanType);
    queryParams.set("residenceType", orderInfo?.order?.CMAP_ResidenceType__c);
    queryParams.set(
      "offerAreaId",
      orderInfo?.order?.CMAP_ResidenceType__c === STRING_RESIDENCE_TYPE_HOME
        ? orderInfo?.order?.CMAP_InstallationLocationOfferArea__c
        : null
    );
    queryParams.set(
      "propertyOfferMethodId",
      orderInfo?.order?.CMAP_ResidenceType__c === STRING_RESIDENCE_TYPE_HOME
        ? null
        : orderInfo?.order?.CMAP_PropertyOfferMethod__c
    );
    queryParams.set("effectiveDate", parsedDate.format("YYYY-MM-DD"));
    queryParams.set("effectiveTime", parsedDate.format("HH:mm:ss"));
    queryParams.set("orderCategoryCurrentlySelected", orderCategoryCurrentlySelected);
    queryParams.set("prefectureCode", orderInfo?.order?.ShippingState);

    if (orderCategoryCurrentlySelected === STRING_CATEGORY_NEW_CONTRACT) {
      queryParams.set("excludesUnchangebleContractProduct", false);
    } else {
      queryParams.set("excludesUnchangebleContractProduct", !orderInfo?.order?.CMAP_BeforeOffer__c);
      queryParams.set("orderCategoryPreviouslySelected", orderInfo?.orderAddition?.orderCategoryPreviouslySelected);
    }

    setRetrieveOfferPlanListBaseURI(`${API_BASE_URL}order/RetrieveOfferPlanList?` + queryParams.toString());
  }, [retrieveSystemTimeDateData]);

  useEffect(() => {
    mappedPlanChangeModal(props, setTriggeredChangePlanWorkFlow, setTriggeredMovementWorkFlow);
  }, []);

  // @View
  return (
    <React.Fragment>
      <div className="form_detail">
        {/* SECTION 1 */}
        <SelectPlan
          rendering={rendering}
          setBM05Data={setBM05Data}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          isPlanSelectionCompleted={isPlanSelectionCompleted}
          setIsPlanSelectionCompleted={setIsPlanSelectionCompleted}
          triggeredMovementWorkFlow={triggeredMovementWorkFlow}
          setTriggeredMovementWorkFlow={setTriggeredMovementWorkFlow}
          triggeredChangePlanWorkFlow={triggeredChangePlanWorkFlow}
          setTriggeredChangePlanWorkFlow={setTriggeredChangePlanWorkFlow}
          retrieveOfferPlanListData={retrieveOfferPlanListData}
          setIsPackageChange={setIsPackageChange}
        />

        {/* SECTION 2 */}
        {(currentSection === STRING_CURRENT_SECTION_SELECT_PRODUCT ||
          currentSection === STRING_CURRENT_SECTION_SELECT_BENEFIT ||
          currentSection === STRING_CURRENT_SECTION_SELECT_PLAN_ATTRIBUTES) && (
          <SelectProduct
            rendering={rendering}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            setBM05Data={setBM05Data}
            isProductSelectionCompleted={isProductSelectionCompleted}
            setIsProductSelectionCompleted={setIsProductSelectionCompleted}
            opticalLineSectionInitialData={opticalLineSectionInitialData}
            setOpticalLineSectionInitialData={setOpticalLineSectionInitialData}
            autoSelectProductList={retrieveAutoSelectProductList}
            selectProductSectionState={selectProductSectionState}
            setSelectProductSectionState={setSelectProductSectionState}
            setIsLoading={props.setIsLoading}
          />
        )}

        {/* SECTION 3 */}
        {(currentSection === STRING_CURRENT_SECTION_SELECT_BENEFIT ||
          currentSection === STRING_CURRENT_SECTION_SELECT_PLAN_ATTRIBUTES) && (
          <SelectPlanBenefitsPackage
            rendering={props.rendering}
            bm05Data={props.setBM05Data}
            currentSection={props.currentSection}
            enteredClass={enteredClass}
            isPlanBenefitCompleted={props.isPlanBenefitCompleted}
            setEnteredClass={setEnteredClass}
          />
        )}

        {/* SECTION 4 */}
        {currentSection === STRING_CURRENT_SECTION_SELECT_PLAN_ATTRIBUTES && (
          <SelectPlanAttribute
            rendering={props.rendering}
            setBM05Data={setBM05Data}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            isPlanAttributeCompleted={isPlanAttributeCompleted}
            setIsPlanAttributeCompleted={setIsPlanAttributeCompleted}
            setIsLoading={props.setIsLoading}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default withSitecoreContext()(SelectPlanProductLeftLayout);
