import React, { useEffect, useState } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank, isContainingHTMLTags } from "../../utils/commonUtils";
import parse from "html-react-parser";

const D01 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { descriptionText, additionalClass, isHidden } = properData;
  const isRichText = isContainingHTMLTags(descriptionText?.value);

  const [isHiddenField, setIsHiddenField] = useState(isHidden ?? false);

  useEffect(() => {
    setIsHiddenField(isHidden);
  }, [isHidden]);
  // @View
  return (
    <React.Fragment>
      {!isHiddenField?.value ? (
        <div className={`description-content ${additionalClass?.value} D-01`}>
          {isRichText ? (
            parse(descriptionText?.value)
          ) : (
            <Text field={fillBlank(descriptionText)} tag="p" encode={false} />
          )}
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default D01;
