import React, { useEffect, useState } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { getScDataFromComponent, getScDataFromPlaceholder, deepcopy } from "../../utils/commonUtils";
import {
  STRING_PRODUCT_COPY,
  STRING_LUMPSUMPAYMENT,
  STRING_LUMPSUMINSTALLMENTPAYMENT,
  STRING_MONTHLYPAYMENT,
  STRING_AGENTCLAIM,
  STRING_MONTHLYANNUALPAYMENT,
  STRING_ANNUALPAYMENT,
  STRING_PRICE_TYPE_LUMPSUM,
  STRING_PRICE_TYPE_INSTALLMENT,
  STRING_PRICE_TYPE_ANNUAL,
  STRING_YEN_TAX_INCLUDED,
  STRING_YEN_TAX_INCLUDED_MONTH,
  STRING_YEN_TAX_INCLUDED_YEAR,
  STRING_PAYMENT_INSTALLMENT,
  STRING_PAYMENT_ANNUAL,
  STRING_METHOD_STANDARDOFFER,
  STRING_METHOD_SELECTABLESTANDARDOFFER,
  STRING_METHOD_OPTION,
  STRING_METHOD_SELECTABLEOPTION,
  STRING_METHOD_UNSELECTABLE,
  STRING_CHECKBOX,
  STRING_RADIO,
  STRING_FLEXIBLE_QUANTITY,
  STRING_HIDE,
  STRING_QUANTITY_LABEL,
  STRING_QUANTITY_INPUT,
  STRING_NOT_SELECTED,
  STRING_INSTALLMENTPAYMENT,
  STRING_WITH_IMAGE,
  STRING_WITH_TOP_BOTTOM_MARGIN,
  STRING_BULLET,
  STRING_DEPENDENCY_SINGLE,
  STRING_DEPENDENCY_GROUP,
  STRING_SELECTED,
  STRING_CATEGORY_NEW_CONTRACT,
  STRING_FIXED_QUANTITY,
  STRING_ADDED,
  STRING_DESELECTED,
  STRING_CURRENT_SECTION_SELECT_PRODUCT,
  STRING_CATEGORY_MOVEMENT,
  STRING_GET,
  STRING_POST,
} from "../../utils/constantVariables";
import { parseFieldMetadata } from "../../utils/parseMetadata";
import { getSessionStorageItem, setSessionStorageItem } from "../../utils/useSessionStorage";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { useCustomQuery } from "../../hooks/useGetData";
import { apiBaseURL as API_BASE_URL } from "../../envSettings";
import { PRODUCTSELECT_AUTOSELECTPRODUCT } from "../Select-Plan-Product-Layout";

export function SelectProduct(props) {
  const { autoSelectProductList } = props;

  const beforeChangeOrderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO,
    true
  );

  const [_, setOpticalData] = useState();
  const [isChildSelected, setIsChildSelected] = useState();
  const [isChildInstallationChanged, setIsChildInstallationChanged] = useState(false);

  const [productListState, setProductListState] = useState({});
  const [currentProductChange, setCurrentProductChange] = useState(null);

  const [retrievePlanProductListURI, setRetrievePlanProductListURI] = useState(null);
  const {
    data: planProductList,
    isLoading: isRetrievePlanProductListLoading,
    refetch: retrievePlanProductListRefetch,
  } = useCustomQuery(retrievePlanProductListURI, false, STRING_GET);

  const { data: metadata, isLoading: isRetrieveMetadataDefinitionsLoading } = useCustomQuery(
    `${API_BASE_URL}order/RetrieveMetadataDefinitions`,
    true,
    STRING_POST,
    {
      objectNames: ["OrderItem"],
      orderChannelCategory: "Web",
    }
  );

  props.setIsLoading(isRetrievePlanProductListLoading || isRetrieveMetadataDefinitionsLoading);

  useEffect(() => {
    if (!retrievePlanProductListURI) return;
    retrievePlanProductListRefetch();
  }, [retrievePlanProductListURI]);

  useEffect(() => {
    const personalizeInfo = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO, true);
    const selectedPlanInfo = getSessionStorageItem(sessionKeys.SSN_APPLICATION_SELECTPRODUCT_SELECTEDPLANINFO, true);
    const orderRelatedInfo = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);
    const orderCategoryCurrentlySelected = orderRelatedInfo?.orderInfo?.orderAddition?.orderCategoryCurrentlySelected;

    const accountInfo = orderRelatedInfo?.accountInfo;
    const orderInfo = orderRelatedInfo?.orderInfo;

    const queryParams = new URLSearchParams();
    queryParams.set(
      "offerPlanId",
      orderInfo?.order?.CMAP_OfferPlan__c ? orderInfo?.order?.CMAP_OfferPlan__c : selectedPlanInfo?.OfferPlan
    );
    queryParams.set("contractName", accountInfo?.account?.CMAP_ContractName__c);
    queryParams.set("residenceType", orderInfo?.order?.CMAP_ResidenceType__c);
    queryParams.set("offerAreaId", orderInfo?.order?.CMAP_InstallationLocationOfferArea__c);
    queryParams.set("propertyOfferMethodId", orderInfo?.order?.CMAP_PropertyOfferMethod__c);
    queryParams.set("orderCategoryCurrentlySelected", orderInfo?.orderAddition?.orderCategoryCurrentlySelected);
    queryParams.set("orderChannelCategory", personalizeInfo?.AppChannelDivision);
    queryParams.set("agencyId", orderInfo?.order?.CMAP_AcquisitionAgency__c);
    queryParams.set("shopId", orderInfo?.order?.CMAP_AcquisitionShop__c);
    queryParams.set("prefectureCode", orderInfo?.order?.ShippingState);

    if (orderCategoryCurrentlySelected === STRING_CATEGORY_NEW_CONTRACT) {
      queryParams.set("excludesUnchangebleContractProduct", false);
    } else {
      queryParams.set("excludesUnchangebleContractProduct", !orderInfo?.order?.CMAP_BeforeOffer__c);
      queryParams.set("orderCategoryPreviouslySelected", orderInfo?.orderAddition?.orderCategoryPreviouslySelected);
    }

    setRetrievePlanProductListURI(`${API_BASE_URL}order/RetrieveOfferPlanProductList?` + queryParams.toString());
  }, []);

  const [h02Data] = getScDataFromComponent("H-02", getScDataFromPlaceholder("form-heading-2", props));
  h02Data.fields.enteredClass = props.currentSection !== STRING_CURRENT_SECTION_SELECT_PRODUCT ? "entered" : "";
  h02Data.fields.onEditButtonClick = () => {
    props.setIsProductSelectionCompleted(false);
    props.setSelectProductSectionState(PRODUCTSELECT_AUTOSELECTPRODUCT);
    props.setCurrentSection(STRING_CURRENT_SECTION_SELECT_PRODUCT);
  };

  const addRemoveOrderImportantMatterDescriptionArray = (
    orderImportantMatterDescriptions,
    product,
    productDetails,
    prodList
  ) => {
    const index = orderImportantMatterDescriptions.findIndex(
      (e) =>
        e.CMAP_ImportantMatterDescription__c === productDetails.CMAP_ImportantMatterDescription__c &&
        e.CMAP_ProductGroup__c === productDetails.Id
    );

    let hasChildSelected = false;
    let hasGroupCategorySelected = false;
    for (let cat in prodList) {
      let { productGroupId, productCategoryId, type, checked, productSet } = prodList[cat];
      if (productGroupId !== product.productGroupId) continue;
      if (productCategoryId !== product.productCategoryId) continue;

      if ((type === STRING_RADIO || type === STRING_CHECKBOX) && checked) {
        hasGroupCategorySelected = true;
      }

      if (!checked && productSet.length > 0) {
        productSet.forEach((set) => {
          let {
            productGroupId: setProductGroupId,
            productCategoryId: setProductCategoryId,
            type: setType,
            checked: setChecked,
          } = set;
          if (setProductGroupId !== set.productGroupId) return;
          if (setProductCategoryId !== set.productCategoryId) return;

          if ((setType === STRING_RADIO || setType === STRING_CHECKBOX) && setChecked) {
            hasGroupCategorySelected = true;
            hasChildSelected = true;
          }
        });
      }
    }

    if (!hasGroupCategorySelected && index >= 0) {
      orderImportantMatterDescriptions.splice(index, 1);
      return orderImportantMatterDescriptions;
    }

    if (product.checked || (!product.checked && hasChildSelected)) {
      const description = {
        CMAP_ImportantMatterDescription__c: productDetails.CMAP_ImportantMatterDescription__c,
        CMAP_ProductGroup__c: productDetails.Id,
        CMAP_SetParentProduct__c: productDetails.setParentProduct,
        CMAP_ConsentDateTime__c: null,
        CMAP_ImportantMatterDescriptionConsent__c: null,
      };

      if (index === -1) {
        orderImportantMatterDescriptions.push(description);
      } else {
        orderImportantMatterDescriptions[index] = description;
      }
    }

    return orderImportantMatterDescriptions;
  };

  const createOrderItem = (productInfo, productState, parentProductState = undefined, beforeChange = undefined) => {
    const orderRelatedInfo = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);
    const isProductSet = Boolean(parentProductState);

    const orderItem = {
      CMAP_OfferPlanProduct__c: isProductSet ? null : productInfo?.offerPlanProduct?.Id,
      CMAP_MainSubProductGroupId__c: productInfo?.productGroup?.Id,
      CMAP_MainSubProductGroupName__c: productInfo?.productGroup?.Name,
      CMAP_ProductCategoryId__c: productInfo?.productCategory?.Id,
      CMAP_ProductCategoryName__c: productInfo?.productCategory?.Name,
      Product2Id: productInfo?.product?.Id,
      CMAP_ProductName__c: productInfo?.product?.Name,
      UnitPrice: productInfo?.pricebookEntry?.UnitPrice,
      CMAP_UnitPriceTaxIncluded__c: productInfo?.pricebookEntry?.CMAP_UnitPriceTaxIncluded__c,
      CMAP_ComsumptionTaxRate__c: productInfo?.pricebookEntry?.CMAP_ConsumptionTaxRate__c,
      Quantity: 1,
      CMAP_PriceCategory__c: productInfo?.pricebookEntry?.CMAP_PriceCategory__c,
      CMAP_InstallmentPaymentMonthly__c: false,
      CMAP_InstallmentPayMonthlyPriceTaxExc__c: productInfo?.pricebookEntry?.CMAP_InstalmentPricePerMonthTaxExcluded__c,
      CMAP_InstallmentPayMonthlyPriceTaxInc__c: productInfo?.pricebookEntry?.CMAP_InstalmentPricePerMonthTaxIncluded__c,
      CMAP_NumberOfInstallments__c: productInfo?.pricebookEntry?.CMAP_NumberOfInstallments__c,
      CMAP_InstallmentPayFirstMonthPriceTaxExc__c:
        productInfo?.pricebookEntry?.CMAP_InstalmentFirstMonthPriceTaxExclude__c,
      CMAP_InstallmentPayFirstMonthPriceTaxInc__c:
        productInfo?.pricebookEntry?.CMAP_InstalmentFirstMonthPriceTaxInclude__c,
      CMAP_SelectionAnnualPayment__c: false,
      CMAP_AnnualPaymentPriceTaxExcluded__c: productInfo?.pricebookEntry?.CMAP_AnnualPaymentPriceTaxExcluded__c,
      CMAP_AnnualPaymentPriceTaxIncluded__c: productInfo?.pricebookEntry?.CMAP_AnnualPaymentPriceTaxIncluded__c,
      CMAP_Unchargeable__c: productInfo?.pricebookEntry?.CMAP_IsUnchargeable__c,
      CMAP_Asset__c: null,
      CMAP_ProductSelectionState__c: STRING_SELECTED,
      CMAP_LastTimeChangeCategory__c: beforeChange ? null : STRING_ADDED,
      CMAP_HasOrderItemAttribute__c: productInfo?.product?.CMAP_HasOrderItemAttributeDefinition__c,
      CMAP_AvailableDailyPricing__c: productInfo?.pricebookEntry?.CMAP_AvailableDailyPricing__c,
      CMAP_SetProduct__c: productInfo?.productAddition?.setProductId,
      CMAP_BenefitProduct__c: null,
      CMAP_BenefitId__c: null,
      CMAP_BenefitName__c: null,
      CMAP_BenefitCampaignId__c: null,
      CMAP_BenefitCampaignName__c: null,
      CMAP_BenefitGroupNumber__c: null,
      CMAP_GrantUnitPrice__c: null,
      CMAP_GrantPoint__c: null,
      CMAP_HasPriceChanged__c: false,
      CMAP_OrderAutomaticSelectionProduct__c: null,
      CMAP_TaxationCategory__c: productInfo?.product?.CMAP_TaxationCategory__c,
      CMAP_installmentFlag__c: null,
      CMAP_ChangeSourceDestinationCategory__c: null,
      CMAP_InheritsMovementAssetAttribute__c: false,
      CMAP_ChangeSourceOrderItem__c: null,
      CMAP_OrderItemBeforeChange__c: null,
      CMAP_OrderItemTypeSelection__c: true,
      CMAP_OrderItemTypeBenefit__c: false,
      CMAP_OrderItemTypeAutomaticSelection__c: false,
      CMAP_MainSubProGroupBenefitCPSortOrder__c: productInfo?.product?.CMAP_MainSubProductGroupSortOrder__c,
      CMAP_ProductCategoryBenefitSortOrder__c: productInfo?.product?.CMAP_ProductCategorySortOrder__c,
      CMAP_ProductSortOrder__c: productInfo?.product?.CMAP_SortOrder__c,
      CMAP_StandardProduct__c: null,
      CMAP_QuantityValueChange__c: null,
      CMAP_InheritableMovementAssetAttribute__c: productState?.isMovement,
    };

    switch (productInfo.pricebookEntry.CMAP_PriceCategory__c) {
      case STRING_LUMPSUMINSTALLMENTPAYMENT:
        orderItem.CMAP_InstallmentPaymentMonthly__c = productState.isInstallment;
        break;
      case STRING_MONTHLYANNUALPAYMENT:
        orderItem.CMAP_SelectionAnnualPayment__c = productState.isInstallment;
        break;
      case STRING_ANNUALPAYMENT:
        orderItem.CMAP_SelectionAnnualPayment__c = true;
    }

    switch (productInfo.product.CMAP_QuantityType__c) {
      case STRING_FLEXIBLE_QUANTITY:
        orderItem.Quantity = productState.quantity;
        break;
      case STRING_FIXED_QUANTITY:
        orderItem.Quantity = productInfo.product?.CMAP_DefaultQuantity__c
          ? productInfo.product?.CMAP_DefaultQuantity__c
          : 1;
        break;
    }

    if (productState?.price?.changed) {
      orderItem.UnitPrice =
        (productState.price.value / (100 + productInfo?.pricebookEntry?.CMAP_ConsumptionTaxRate__c)) * 100;
    }

    const orderItemInfos = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos;
    if (orderRelatedInfo && orderItemInfos.length > 0) {
      const itemInfo = orderItemInfos.find(({ orderItem }) => orderItem.Product2Id === productInfo?.product?.Id);

      if (itemInfo) {
        if (parentProductState.checked || !productState.checked) {
          orderItem.CMAP_ProductSelectionState__c = STRING_DESELECTED;
          orderItem.CMAP_LastTimeChangeCategory__c = STRING_DESELECTED;
        } else {
          orderItem.CMAP_ProductSelectionState__c = STRING_SELECTED;
        }
      }
    }

    return orderItem;
  };

  const addRemoveOrderItemInfo = (orderItemInfos, productInfo, productState, parentProductState = undefined) => {
    const isProductSet = Boolean(parentProductState);
    const index = orderItemInfos.findIndex(({ orderItem }) => orderItem.Product2Id === productInfo.product.Id);

    const beforeChangeOrderItemInfos = beforeChangeOrderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos;
    const beforeChangeOrderItem = beforeChangeOrderItemInfos?.find(
      (item) => item.orderItem.Product2Id === productInfo.product.Id
    );

    if (!beforeChangeOrderItem && productState.type === STRING_CHECKBOX) {
      if (!productState.checked && !isProductSet) {
        if (index !== -1) orderItemInfos.splice(index, 1);
        return orderItemInfos;
      }

      if (isProductSet && (parentProductState.checked || (!parentProductState.checked && !productState.checked))) {
        if (index !== -1) orderItemInfos.splice(index, 1);
        return orderItemInfos;
      }
    }

    if (productState.type === STRING_RADIO && !productState.checked) return orderItemInfos;
    if (productState.type === STRING_RADIO && productState.previousValue) {
      const prevProductIndex = orderItemInfos.findIndex(
        ({ orderItem }) => orderItem.Product2Id === productState.previousValue
      );

      if (prevProductIndex !== -1) {
        const prevBeforeChange = beforeChangeOrderItemInfos?.find(
          ({ orderItem }) => orderItem.Product2Id === productState.previousValue
        );

        if (prevBeforeChange) {
          orderItemInfos[prevProductIndex].orderItem.CMAP_ProductSelectionState__c = STRING_DESELECTED;
          orderItemInfos[prevProductIndex].orderItem.CMAP_LastTimeChangeCategory__c = STRING_DESELECTED;
        } else {
          orderItemInfos.splice(index, 1);
        }
      }
    }

    const orderItem = createOrderItem(productInfo, productState, parentProductState, beforeChangeOrderItem);
    if (index === -1) {
      orderItemInfos.push({
        orderItem,
        orderItemAddition: {
          previousLastModifiedDate: null,
        },
      });
    } else {
      const orderInfo = orderItemInfos[index];
      orderItemInfos[index] = {
        ...orderInfo,
        orderItem: {
          ...orderInfo?.orderItem,
          ...orderItem,
        },
        orderItemAddition: {
          ...orderInfo.orderItemAddition,
          previousLastModifiedDate: null,
        },
      };
    }
  };

  const updateOrderRelatedInfo = (updatedProdList = undefined) => {
    let orderRelatedInfo = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);
    let { orderImportantMatterDescriptions = [], orderItemInfos = [] } = orderRelatedInfo?.orderRelatedInfo?.orderInfo;

    for (let cat in updatedProdList) {
      const product = updatedProdList[cat];

      planProductList.mainSubProductGroupInfos.forEach(({ productCategoryInfos, productGroup }) => {
        productCategoryInfos.forEach(({ productCategory, productInfos }) => {
          const productInfo = productInfos.find(
            ({ singleProductInfo }) => product.productID === singleProductInfo.product.Id
          );

          if (productInfo) {
            const { singleProductInfo, setUnitProductInfos } = productInfo;
            if (productGroup.CMAP_ImportantMatterDescription__c) {
              addRemoveOrderImportantMatterDescriptionArray(
                orderImportantMatterDescriptions,
                product,
                {
                  ...productGroup,
                  productId: singleProductInfo.product.Id,
                  setParentProduct: singleProductInfo.productAddition.setParentProduct,
                },
                updatedProdList
              );
            }

            if (productCategory.CMAP_ImportantMatterDescription__c) {
              addRemoveOrderImportantMatterDescriptionArray(
                orderImportantMatterDescriptions,
                product,
                {
                  ...productCategory,
                  Id: productGroup.Id,
                  productId: singleProductInfo.product.Id,
                  setParentProduct: singleProductInfo.productAddition.setParentProduct,
                },
                updatedProdList
              );
            }

            addRemoveOrderItemInfo(orderItemInfos, singleProductInfo, product, false);
            if (product.productSet.length > 0) {
              product.productSet.forEach((set) => {
                const setProductInfo = setUnitProductInfos.find(({ product: p }) => set.productID === p.Id);
                if (setProductInfo) {
                  addRemoveOrderItemInfo(orderItemInfos, setProductInfo, set, product);
                }
              });
            }
          }
        });
      });
    }

    setSessionStorageItem(
      sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
      {
        ...orderRelatedInfo,
        orderRelatedInfo: {
          ...orderRelatedInfo.orderRelatedInfo,
          orderInfo: {
            ...orderRelatedInfo.orderRelatedInfo.orderInfo,
            orderImportantMatterDescriptions,
            orderItemInfos,
          },
        },
      },
      true
    );
  };

  const getProductSelectionDependent = (productID) => {
    let prodSelDependent = {};
    planProductList.mainSubProductGroupInfos.forEach(({ productCategoryInfos }) => {
      productCategoryInfos.forEach(({ productInfos }) => {
        productInfos.forEach(({ singleProductInfo }) => {
          if (!singleProductInfo.offerPlanProductSelectionMethods) return;
          const dependentProdId = singleProductInfo.product.Id;
          singleProductInfo.offerPlanProductSelectionMethods.forEach((method) => {
            if (method.CMAP_ProductSelectionDependent__c === productID) {
              prodSelDependent = {
                ...prodSelDependent,
                [dependentProdId]: {
                  currentMethod: method,
                  currentMethods: singleProductInfo.offerPlanProductSelectionMethods,
                  defaultMethod: singleProductInfo.offerPlanProductSelectionMethod,
                  offerPlanProduct: singleProductInfo.offerPlanProduct,
                },
              };
            }
          });
        });
      });
    });

    return prodSelDependent;
  };

  const updateChildMethod = (component, event, prodSelDependent, prodState) => {
    const fields = component.fields;
    return fields.selections.map((sel) => {
      const data = prodSelDependent[sel.id];
      if (data) {
        sel.isDisabled = false;
        sel.isSelected = false;
        const { currentMethods, defaultMethod, offerPlanProduct } = data;

        if (event.type === STRING_CHECKBOX) {
          sel.isDisabledBasedOnDependencyForeignProductId = event.checked
            ? false
            : !!offerPlanProduct?.CMAP_DependenceProductId__c;
        }
        if (event.type === STRING_RADIO) {
          sel.isDisabledBasedOnDependencyForeignProductId =
            event.value === sel.id ? false : !!offerPlanProduct?.CMAP_DependenceProductId__c;
        }

        const priorityMethod = currentMethods.reduce((acc, curr) => {
          if (acc) return acc;

          for (let cat in prodState) {
            const item = prodState[cat];
            if (item.productID !== curr.CMAP_ProductSelectionDependent__c) continue;

            if (
              (item.type === STRING_CHECKBOX && item.checked) ||
              (item.type === STRING_RADIO && item.value === curr.CMAP_ProductSelectionDependent__c)
            ) {
              acc = curr;
            }
          }

          return acc;
        }, null);

        const type = priorityMethod ? priorityMethod.CMAP_ProductSelection__c : defaultMethod.CMAP_ProductSelection__c;

        switch (type) {
          case STRING_METHOD_STANDARDOFFER:
            sel.isDisabled = true;
            sel.isSelected = true;
            sel.productSelection = STRING_CHECKBOX;
            break;
          case STRING_METHOD_SELECTABLESTANDARDOFFER:
            sel.productSelection = STRING_RADIO;
            break;
          case STRING_METHOD_OPTION:
            sel.productSelection = STRING_CHECKBOX;
            break;
          case STRING_METHOD_SELECTABLEOPTION:
            sel.productSelection = STRING_RADIO;
            break;
          case STRING_METHOD_UNSELECTABLE:
            sel.isDisabled = true;
            sel.productSelection = STRING_CHECKBOX;
            break;
        }
      }

      if (sel.isDisabledBasedOnDependencyForeignProductId) {
        sel.isSelected = false;
      }

      if (sel.productSelection === STRING_CHECKBOX) {
        const category = fields.category.value;
        prodState[category] = {
          ...prodState[category],
          type: STRING_CHECKBOX,
          value: "",
          checked: sel.isSelected,
          productSet: sel.productSet.reduce((a, set) => {
            if (set.productSelection !== STRING_BULLET) {
              a.push({
                ...createSelectedProductState(set, set.id, category),
                parentId: sel.id,
                value: set.id,
                checked: sel.isSelected ? sel.isSelected : set.isSelected,
              });
            }
            return a;
          }, []),
        };
      }

      return sel;
    });
  };

  const addNoneOfTheAboveOption = (groupingValue) => {
    return {
      id: groupingValue + "_" + STRING_NOT_SELECTED,
      productName: STRING_NOT_SELECTED,
      productSelection: STRING_RADIO,
      grouping: groupingValue,
      showErrorMessage: false,
      productSet: [],
      s17child: {
        isHidden: true,
      },
    };
  };

  const createSelectedProductState = (product, productID, category) => {
    return {
      productID: productID,
      type: product.productSelection,
      value: product.isSelected ? productID : null,
      previousValue: product.productSelection === STRING_RADIO && product.isSelected ? productID : null,
      checked: product.isSelected,
      productGroupId: product.productGroupId,
      productCategoryId: product.productCategoryId,
      category: category,
      isInstallment: product.s17child.isSelected,
      quantity: product.quantityLabel.value,
      isMovement: product.beforeChange.checkboxValue,
      price: {
        initial: product.productPriceLabel.value,
        value: product.productPriceLabel.value,
        changed: false,
      },
    };
  };

  const createProductInfoCommon = (productInfos, currentGroup, productGroupId, productCategoryId, parentId) => {
    const dataFormatter = {
      productGroupId,
      productCategoryId,
      productName: productInfos?.singleProductInfo?.product?.Name,
      tooltipText: productInfos?.singleProductInfo?.product?.CMAP_Tooltip__c,
      externalLink: productInfos?.singleProductInfo?.product?.CMAP_OtherCompanyDescription__c,
      externalLinkUrl: productInfos?.singleProductInfo?.product?.CMAP_OtherCompanyURL__c,
      precautionText: productInfos?.singleProductInfo?.product?.CMAP_Precaution__c,
      productImage: productInfos?.singleProductInfo?.product?.CMAP_ProductImageURL__c,
      productImageAlt: productInfos?.singleProductInfo?.offerPlanProduct?.CMAP_AltMessage__c,
      quantityLabel: {
        value: "",
        unit: "",
        min: "",
        max: "",
        numerical_input: STRING_HIDE,
      },
      isSelected: false,
      isDisabled: productInfos?.singleProductInfo?.productAddition?.readOnly,
      grouping: currentGroup,
      id: productInfos?.singleProductInfo?.product?.Id,
      priceCategory: productInfos?.singleProductInfo?.pricebookEntry?.CMAP_PriceCategory__c,
      priceCategoryDefault: productInfos?.singleProductInfo?.pricebookEntry?.CMAP_PriceCategoryDefault__c,
      s17child: {
        isHidden: true,
        isDisabled: true,
        isSelected: productInfos?.singleProductInfo?.pricebookEntry?.CMAP_PriceCategory__c === STRING_ANNUALPAYMENT,
        paymentLabel: "",
        paymentPriceType: "",
        paymentPriceLabel: "",
        paymentPriceUnitLabel: "",
      },
      beforeChange: {
        show: false,
        cancelText: productInfos?.singleProductInfo?.product?.CMAP_AttentionAtCancellation__c,
        terminationText: productInfos?.singleProductInfo?.product?.CMAP_AttentionAtTermination__c,
        checkboxShow: false,
        checkboxValue: false,
      },
    };

    if (!parentId) {
      dataFormatter.isSelected = productInfos?.singleProductInfo?.offerPlanProduct?.CMAP_DependenceProductId__c
        ? false
        : productInfos?.singleProductInfo?.offerPlanProductSelectionMethod?.CMAP_DefaultSelection__c;
    }

    if (
      productInfos?.singleProductInfo?.product?.CMAP_ProductImageURL__c !== null ||
      productInfos?.singleProductInfo?.product?.CMAP_ProductImageURL__c !== ""
    ) {
      dataFormatter.additionalClass = STRING_WITH_IMAGE;
    }

    if (productInfos?.singleProductInfo?.productAddition?.readOnly) {
      if (productInfos?.singleProductInfo?.product?.CMAP_QuantityType__c === STRING_FLEXIBLE_QUANTITY) {
        dataFormatter.quantityLabel = {
          value: productInfos?.singleProductInfo?.product?.CMAP_DefaultQuantity__c,
          unit: productInfos?.singleProductInfo?.product?.CMAP_ProductUnitOfQuantity__c,
          numerical_input: STRING_QUANTITY_LABEL,
        };
      }
    } else {
      if (productInfos?.singleProductInfo?.product?.CMAP_QuantityType__c === STRING_FLEXIBLE_QUANTITY) {
        if (!productInfos?.singleProductInfo?.product?.CMAP_UnselectableSingleItem__c) {
          dataFormatter.quantityLabel = {
            value: productInfos?.singleProductInfo?.product?.CMAP_DefaultQuantity__c,
            unit: productInfos?.singleProductInfo?.product?.CMAP_ProductUnitOfQuantity__c,
            numerical_input: STRING_QUANTITY_INPUT,
          };
        }
      }
    }

    switch (productInfos?.singleProductInfo?.pricebookEntry?.CMAP_PriceCategory__c) {
      case STRING_LUMPSUMPAYMENT:
        dataFormatter.productPriceType = STRING_PRICE_TYPE_LUMPSUM;
        dataFormatter.productPriceLabel = {
          value: productInfos?.singleProductInfo?.pricebookEntry?.CMAP_UnitPriceTaxIncluded__c,
          changeable: productInfos?.singleProductInfo?.product?.CMAP_ChangeablePrice__c,
        };
        dataFormatter.productPriceUnitLabel = STRING_YEN_TAX_INCLUDED;
        break;

      case STRING_LUMPSUMINSTALLMENTPAYMENT:
        dataFormatter.productPriceType = "";
        dataFormatter.productPriceLabel = {
          value: productInfos?.singleProductInfo?.pricebookEntry?.CMAP_UnitPriceTaxIncluded__c,
          changeable: productInfos?.singleProductInfo?.product?.CMAP_ChangeablePrice__c,
        };

        dataFormatter.productPriceUnitLabel = STRING_YEN_TAX_INCLUDED;

        dataFormatter.s17child.isHidden = false;
        dataFormatter.s17child.paymentLabel = STRING_PAYMENT_INSTALLMENT;
        dataFormatter.s17child.paymentPriceType = STRING_PRICE_TYPE_INSTALLMENT;
        dataFormatter.s17child.paymentPriceLabel =
          productInfos?.singleProductInfo?.pricebookEntry?.CMAP_InstalmentPricePerMonthTaxIncluded__c;
        dataFormatter.s17child.paymentPriceUnitLabel = STRING_YEN_TAX_INCLUDED_MONTH;

        if (dataFormatter.isSelected) {
          dataFormatter.s17child.isDisabled = false;
          if (
            productInfos?.singleProductInfo?.pricebookEntry?.CMAP_PriceCategoryDefault__c === STRING_INSTALLMENTPAYMENT
          ) {
            dataFormatter.s17child.isSelected = true;
          } else if (
            productInfos?.singleProductInfo?.pricebookEntry?.CMAP_PriceCategoryDefault__c === STRING_LUMPSUMPAYMENT
          ) {
            dataFormatter.s17child.isSelected = false;
          }
        } else {
          dataFormatter.s17child.isDisabled = true;
          dataFormatter.s17child.isSelected = false;
        }
        break;

      case STRING_MONTHLYPAYMENT:
        dataFormatter.productPriceType = "";
        dataFormatter.productPriceLabel = {
          value: productInfos?.singleProductInfo?.pricebookEntry?.CMAP_UnitPriceTaxIncluded__c,
          changeable: productInfos?.singleProductInfo?.product?.CMAP_ChangeablePrice__c,
        };
        dataFormatter.productPriceUnitLabel = STRING_YEN_TAX_INCLUDED_MONTH;
        break;

      case STRING_AGENTCLAIM:
        dataFormatter.productPriceType = "";
        dataFormatter.productPriceLabel = "";
        dataFormatter.productPriceUnitLabel = "";
        break;

      case STRING_MONTHLYANNUALPAYMENT:
        dataFormatter.productPriceType = "";

        dataFormatter.productPriceLabel = {
          value: productInfos?.singleProductInfo?.pricebookEntry?.CMAP_UnitPriceTaxIncluded__c,
          changeable: productInfos?.singleProductInfo?.product?.CMAP_ChangeablePrice__c,
        };
        dataFormatter.productPriceUnitLabel = STRING_YEN_TAX_INCLUDED_MONTH;

        dataFormatter.s17child.isHidden = false;
        dataFormatter.s17child.paymentLabel = STRING_PAYMENT_ANNUAL;
        dataFormatter.s17child.paymentPriceType = STRING_PRICE_TYPE_ANNUAL;
        dataFormatter.s17child.paymentPriceLabel =
          productInfos?.singleProductInfo?.pricebookEntry?.CMAP_AnnualPaymentPriceTaxIncluded__c;
        dataFormatter.s17child.paymentPriceUnitLabel = STRING_YEN_TAX_INCLUDED_YEAR;

        if (dataFormatter.isSelected) {
          dataFormatter.s17child.isDisabled = false;
          if (productInfos?.singleProductInfo?.pricebookEntry?.CMAP_PriceCategoryDefault__c === STRING_ANNUALPAYMENT) {
            dataFormatter.s17child.isSelected = true;
          } else if (
            productInfos?.singleProductInfo?.pricebookEntry?.CMAP_PriceCategoryDefault__c === STRING_MONTHLYPAYMENT
          ) {
            dataFormatter.s17child.isSelected = false;
          }
        } else {
          dataFormatter.s17child.isDisabled = true;
          dataFormatter.s17child.isSelected = false;
        }
        break;

      case STRING_ANNUALPAYMENT:
        dataFormatter.productPriceType = STRING_PRICE_TYPE_ANNUAL;
        dataFormatter.productPriceLabel = {
          value: productInfos?.singleProductInfo?.pricebookEntry?.CMAP_AnnualPaymentPriceTaxIncluded__c,
          changeable: productInfos?.singleProductInfo?.product?.CMAP_ChangeablePrice__c,
        };
        dataFormatter.productPriceUnitLabel = STRING_YEN_TAX_INCLUDED_YEAR;
        break;

      default:
        // TODO : no default in the document
        break;
    }

    const orderRelatedInfo = getSessionStorageItem(sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO, true);
    const orderItemInfos = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos;
    if (orderRelatedInfo && orderItemInfos.length > 0) {
      const itemInfo = orderItemInfos.find(
        ({ orderItem }) => orderItem.Product2Id === productInfos?.singleProductInfo?.product?.Id
      );

      dataFormatter.isSelected = false;
      if (itemInfo) {
        dataFormatter.isSelected = itemInfo.orderItem.CMAP_ProductSelectionState__c === STRING_SELECTED;
        dataFormatter.quantityLabel.value = itemInfo.orderItem.Quantity;
        if (dataFormatter.productPriceLabel) dataFormatter.productPriceLabel.value = itemInfo.orderItem.UnitPrice;
        dataFormatter.s17child.isDisabled = !dataFormatter.isSelected;
        dataFormatter.s17child.isSelected =
          itemInfo.orderItem.CMAP_InstallmentPaymentMonthly__c || itemInfo.orderItem.CMAP_SelectionAnnualPayment__c;
      }

      if (parentId) {
        const parentItemInfo = orderItemInfos.find(({ orderItem }) => orderItem.Product2Id === parentId);
        if (parentItemInfo && parentItemInfo.orderItem.CMAP_ProductSelectionState__c === STRING_SELECTED) {
          dataFormatter.isSelected = true;
        }
      }
    }

    const orderCategoryCurrentlySelected =
      orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition?.orderCategoryCurrentlySelected;
    const beforeChangeOrderItemInfos = beforeChangeOrderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos;
    if (beforeChangeOrderItemInfos) {
      const beforeChangeItemInfo = beforeChangeOrderItemInfos.find(
        ({ orderItem }) => orderItem.Product2Id === productInfos?.singleProductInfo?.product?.Id
      );

      if (beforeChangeItemInfo) {
        dataFormatter.beforeChange.show = true;
        dataFormatter.beforeChange.checkboxValue =
          productInfos?.singleProductInfo?.product?.CMAP_InheritableMovementAssetAttribute__c;
        dataFormatter.beforeChange.checkboxShow =
          productInfos?.singleProductInfo?.product?.CMAP_HasOrderItemAttributeDefinition__c &&
          orderCategoryCurrentlySelected === STRING_CATEGORY_MOVEMENT;
      }
    }

    return dataFormatter;
  };

  const handleOnChangeParentEvent = (event, product, category) => {
    setCurrentProductChange({
      data: {
        type: event.target.type,
        value: event.target.value,
        checked: event.target.checked,
        productID: product.id,
      },
      product,
      category,
    });
  };

  const handleOnChangeProductSet = (event, product, category, parentId) => {
    const parent = productListState[category];
    if (parent.productID === parentId) {
      const index = parent.productSet.findIndex((p) => p.productID === product.id);
      if (index === -1) return;

      parent.productSet[index] = {
        ...parent.productSet[index],
        parentId: parentId,
        type: event.target.type,
        value: event.target.value,
        checked: event.target.checked,
      };

      setCurrentProductChange({
        data: parent,
        product,
        category,
      });
    }
  };

  const handleOnChangeInstallment = (event, product, category, parentId) => {
    const prod = productListState[category];
    if (prod.productID === parentId) {
      setCurrentProductChange({
        data: {
          ...productListState[category],
          productSet: productListState[category].productSet.map((set) => {
            if (set.id === product.productID) {
              set.isInstallment = event.target.checked;
            }
            return set;
          }),
        },
        product,
        category,
      });
    }

    if (prod.productID === product.id) {
      setCurrentProductChange({
        data: {
          ...productListState[category],
          isInstallment: event.target.checked,
        },
        product,
        category,
      });
    }
  };

  const handleOnChangeQuantity = (value, product, category, parentId) => {
    const prod = productListState[category];
    if (prod.productID === parentId) {
      setCurrentProductChange({
        data: {
          ...productListState[category],
          productSet: productListState[category].productSet.map((set) => {
            if (set.id === product.productID) {
              set.quantity = value;
            }
            return set;
          }),
        },
        product,
        category,
      });
    }

    if (prod.productID === product.id) {
      setCurrentProductChange({
        data: {
          ...productListState[category],
          quantity: value,
        },
        product,
        category,
      });
    }
  };

  const handleOnChangePrice = (value, product, category, parentId) => {
    const price = productListState[category].price;
    const isChanged = price.initial === parseInt(value) ? false : true;
    if (productListState[category].productID === parentId) {
      setCurrentProductChange({
        data: {
          ...productListState[category],
          productSet: productListState[category].productSet.map((set) => {
            if (set.id === product.productID) {
              set.price.value = value;
              set.price.changed = isChanged;
            }
            return set;
          }),
        },
        product,
        category,
      });
    }

    if (productListState[category].productID === product.id) {
      setCurrentProductChange({
        data: {
          ...productListState[category],
          price: {
            ...price,
            value,
            changed: isChanged,
          },
        },
        product,
        category,
      });
    }
  };

  const handleOnChangeMovement = (checked, product, category, parentId) => {
    const prod = productListState[category];
    if (prod.productID === parentId) {
      setCurrentProductChange({
        data: {
          ...productListState[category],
          productSet: productListState[category].productSet.map((set) => {
            if (set.id === product.productID) {
              set.isMovement = checked;
            }
            return set;
          }),
        },
        product,
        category,
      });
    }

    if (prod.productID === product.id) {
      setCurrentProductChange({
        data: {
          ...productListState[category],
          isMovement: checked,
        },
        product,
        category,
      });
    }
  };

  useEffect(() => {
    if (!metadata || !planProductList) return;

    const opticalLineSection = getScDataFromPlaceholder("optical-line-section", props);
    if (props.opticalLineSectionInitialData === undefined) {
      props.setOpticalLineSectionInitialData(opticalLineSection.slice(0, 5));
    } else {
      props.opticalLineSectionInitialData.forEach((fields) => {
        opticalLineSection.push(fields);
      });
    }

    const parsedMetadata = parseFieldMetadata(
      metadata?.metadataDefinitions?.OrderItem?.fieldMetadataDefinitions?.Quantity
    );

    const [h02Data] = getScDataFromComponent("H-02", opticalLineSection);
    const [d01Data] = getScDataFromComponent("D-01", opticalLineSection);
    const [h03Data] = getScDataFromComponent("H-03", opticalLineSection);
    const [s17Data] = getScDataFromComponent("S-17", opticalLineSection);
    s17Data.fields.selections = [];
    s17Data.fields.minimumSelectionValue = 0;
    s17Data.fields.maximumSelectionValue = 0;

    if (planProductList?.mainSubProductGroupInfos.length > 0) {
      planProductList.mainSubProductGroupInfos.forEach((mainSubProductGroupInfos, index) => {
        const h02DataCopy = deepcopy(h02Data);
        h02DataCopy.uid = STRING_PRODUCT_COPY + mainSubProductGroupInfos.productGroup.Id + "_" + h02DataCopy.uid;
        h02DataCopy.fields.titleText = mainSubProductGroupInfos.productGroup.Name;
        opticalLineSection.push(h02DataCopy);

        const d01DataCopy = deepcopy(d01Data);
        d01DataCopy.uid = STRING_PRODUCT_COPY + mainSubProductGroupInfos.productGroup.Id + "_" + d01DataCopy.uid;
        d01DataCopy.fields.descriptionText = mainSubProductGroupInfos.productGroup.CMAP_SitecoreDescription__c;
        opticalLineSection.push(d01DataCopy);

        mainSubProductGroupInfos.productCategoryInfos.forEach((productCategoryInfos) => {
          const h03DataCopy = deepcopy(h03Data);
          h03DataCopy.uid = STRING_PRODUCT_COPY + productCategoryInfos.productCategory.Id + "_" + h03DataCopy.uid;
          h03DataCopy.fields.titleText = {
            value: productCategoryInfos.productCategory.Name,
          };
          h03DataCopy.fields.additionalClass = {
            value: STRING_WITH_TOP_BOTTOM_MARGIN,
          };
          opticalLineSection.push(h03DataCopy);

          let addSelectableOption = "";
          let previousGroup = "";
          let isSameGroup = false;
          let tmpS17DataCopy = [];

          let selectionData = {};
          let productSetData = {};
          let counter = 0;

          productCategoryInfos.productInfos.forEach((productInfos, productInfosIndex) => {
            let currentGroup =
              productInfos?.singleProductInfo?.offerPlanProductSelectionMethod?.CMAP_ProductSelectionGroupNumber__c;
            if (!currentGroup) {
              currentGroup = productInfos?.singleProductInfo?.product?.Id;
            }

            let s17DataCopy = null;
            if (previousGroup !== currentGroup) {
              previousGroup = currentGroup;

              if (counter !== 0) {
                if (addSelectableOption !== "" && isSameGroup) {
                  let tmpDataNoneOfTheAbove = addNoneOfTheAboveOption(addSelectableOption);
                  tmpS17DataCopy.fields.selections.push(tmpDataNoneOfTheAbove);
                  addSelectableOption = "";
                }
                opticalLineSection.push(tmpS17DataCopy);
              }

              let productId = productInfos?.singleProductInfo?.product?.Id;
              s17DataCopy = deepcopy(s17Data);
              s17DataCopy.uid = STRING_PRODUCT_COPY + "_" + productId + "_" + s17DataCopy.uid;
              s17DataCopy.fields.id = s17DataCopy.uid;
              s17DataCopy.fields.onChangeParentEvent = (event, product, category) => {
                handleOnChangeParentEvent(event, product, category);
              };
              s17DataCopy.fields.category = { value: currentGroup };
              s17DataCopy.fields.minimumSelectionValue = parsedMetadata?.minValue
                ? Number(parsedMetadata?.minValue)
                : parsedMetadata?.minValue;
              s17DataCopy.fields.maximumSelectionValue = parsedMetadata?.maxValue
                ? Number(parsedMetadata?.maxValue)
                : parsedMetadata?.maxValue;

              tmpS17DataCopy = s17DataCopy;
              isSameGroup = false;
            } else {
              s17DataCopy = tmpS17DataCopy;
              isSameGroup = true;
            }

            selectionData = createProductInfoCommon(
              productInfos,
              currentGroup,
              mainSubProductGroupInfos.productGroup.Id,
              productCategoryInfos.productCategory.Id
            );
            selectionData.dependencySingleProductId =
              productInfos?.singleProductInfo?.offerPlanProduct?.CMAP_DependenceSingleProductId__c;
            selectionData.dependencyGroupProductId =
              productInfos?.singleProductInfo?.offerPlanProduct?.CMAP_DependenceGroupProductId__c;
            selectionData.dependencyForeignProductId =
              productInfos?.singleProductInfo?.offerPlanProduct?.CMAP_DependenceProductId__c;
            selectionData.isDisabledBasedOnDependencyForeignProductId = productInfos?.singleProductInfo
              ?.offerPlanProduct?.CMAP_DependenceProductId__c
              ? true
              : false;
            selectionData.dependencyType =
              productInfos?.singleProductInfo?.offerPlanProduct?.CMAP_DependenceProductTypeForApplication__c;

            switch (productInfos?.singleProductInfo?.offerPlanProductSelectionMethod?.CMAP_ProductSelection__c) {
              case STRING_METHOD_STANDARDOFFER:
                selectionData.productSelection = STRING_CHECKBOX;
                break;

              case STRING_METHOD_SELECTABLESTANDARDOFFER:
                selectionData.productSelection = STRING_RADIO;
                break;

              case STRING_METHOD_OPTION:
                selectionData.productSelection = STRING_CHECKBOX;
                break;

              case STRING_METHOD_SELECTABLEOPTION:
                selectionData.productSelection = STRING_RADIO;
                if (
                  !addSelectableOption ===
                  productInfos?.singleProductInfo?.offerPlanProductSelectionMethod?.CMAP_ProductSelectionGroupNumber__c
                ) {
                  addSelectableOption =
                    productInfos?.singleProductInfo?.offerPlanProductSelectionMethod
                      ?.CMAP_ProductSelectionGroupNumber__c;
                }
                break;

              case STRING_METHOD_UNSELECTABLE:
                selectionData.productSelection = STRING_CHECKBOX;
                break;
            }

            selectionData.showErrorMessage = false;

            // CREATE PRODUCT SET
            selectionData.productSet = [];
            if (productInfos?.setUnitProductInfos?.length > 0) {
              let sumOfProductSetSelected = 0;
              productInfos.setUnitProductInfos.forEach((productSetInfos) => {
                // ADD PRODUCT SET
                if (!productInfos?.offerPlanProductSelectionMethod?.CMAP_DefaultSelection__c) {
                  if (productSetInfos?.offerPlanProductSelectionMethod?.CMAP_DefaultSelection__c) {
                    sumOfProductSetSelected += productSetInfos?.pricebookEntry?.CMAP_UnitPriceTaxIncluded__c;
                  }
                }

                productSetData = createProductInfoCommon(
                  { singleProductInfo: productSetInfos },
                  currentGroup,
                  mainSubProductGroupInfos.productGroup.Id,
                  productCategoryInfos.productCategory.Id,
                  selectionData.id
                );
                productSetData.productSelection = STRING_BULLET;

                if (
                  productSetInfos?.product?.CMAP_UnselectableSingleItem__c === false &&
                  productSetInfos?.productAddition?.readOnly === false
                ) {
                  productSetData.productSelection = STRING_CHECKBOX;
                }

                selectionData.productSet.push(productSetData);
              });

              if (sumOfProductSetSelected > selectionData.productPriceLabel.value) {
                selectionData.showErrorMessage = true;
              }
            }

            s17DataCopy.fields.selections.push(selectionData);

            if (productCategoryInfos["productInfos"].length === productInfosIndex + 1) {
              if (addSelectableOption !== "" && isSameGroup) {
                let tmpDataNoneOfTheAbove = addNoneOfTheAboveOption(addSelectableOption);
                s17DataCopy.fields.selections.push(tmpDataNoneOfTheAbove);
              }

              opticalLineSection.push(s17DataCopy);
            }

            tmpS17DataCopy = s17DataCopy;
            counter++;
          });
        });
      });
    }

    let selectedStatus = {};
    let productCompList = getScDataFromComponent("S-17", opticalLineSection);
    productCompList.forEach((component) => {
      component.fields.selections.forEach((select) => {
        const productID = select.id;

        selectedStatus[component.fields.category.value] = {
          ...createSelectedProductState(select, productID, component.fields.category.value),
          productSet: select.productSet.map((set) => ({
            ...createSelectedProductState(set, set.id, component.fields.category.value),
            parentId: productID,
          })),
        };
      });
      return component;
    });

    let updateProductCompList = opticalLineSection;
    for (let sel in selectedStatus) {
      const prod = selectedStatus[sel];
      const productSelectionDependent = getProductSelectionDependent(prod.productID);
      const hasProdSelDependency = Object.keys(productSelectionDependent).length > 0;
      updateProductCompList = opticalLineSection.map((component) => {
        if (component.componentName !== "S-17") return component;
        const selections = component.fields.selections;
        if (hasProdSelDependency && selections?.length > 0) {
          component.fields.selections = updateChildMethod(component, prod, productSelectionDependent, selectedStatus);
        }
        return component;
      });
    }

    const [l12Data] = getScDataFromComponent("L-12", opticalLineSection);
    l12Data.fields.isHidden = true;
    l12Data.fields.data = [];
    opticalLineSection.push(l12Data);

    updateOrderRelatedInfo(selectedStatus);
    setProductListState(selectedStatus);
    setOpticalData(updateProductCompList);

    opticalLineSection.splice(0, 5);
  }, [metadata, planProductList]);

  useEffect(() => {
    if (!currentProductChange) return;

    const { data: event, product, category } = currentProductChange;
    const { id: productID, dependencySingleProductId, dependencyGroupProductId } = product;

    const currentState = productListState[category];
    const updatedProductListState = {
      ...productListState,
      [category]: {
        ...currentState,
        ...event,
        previousValue: currentState.value,
        productSet: currentState.productSet.map((set) => ({
          ...set,
          checked: event.checked ? event.checked : set.checked,
        })),
      },
    };

    const productSelectionDependent = getProductSelectionDependent(productID);
    const hasProdSelDependency = Object.keys(productSelectionDependent).length > 0;

    // find all product with foreign key
    const opticalLineSection = getScDataFromPlaceholder("optical-line-section", props);
    const updatedOpticalLineSection = opticalLineSection.map((component) => {
      if (dependencySingleProductId) {
        let s17SelectionSingle = component?.fields?.selections?.find(
          (selection) => selection.dependencyForeignProductId === dependencySingleProductId
        );
        if (s17SelectionSingle?.dependencyType === STRING_DEPENDENCY_SINGLE) {
          if (event.checked) {
            s17SelectionSingle.isDisabledBasedOnDependencyForeignProductId = false;
          } else {
            s17SelectionSingle.isDisabledBasedOnDependencyForeignProductId = true;
            s17SelectionSingle.s17child.isDisabled = true;
            s17SelectionSingle.s17child.isSelected = false;
            setIsChildInstallationChanged(false);
            setIsChildSelected(false);
          }
          s17SelectionSingle.isSelected = false;
        }
      }

      if (dependencyGroupProductId) {
        let s17SelectionGroup = component?.fields?.selections?.find(
          (selection) => selection.dependencyForeignProductId === dependencyGroupProductId
        );
        if (s17SelectionGroup?.dependencyType === STRING_DEPENDENCY_GROUP) {
          if (event.checked) {
            s17SelectionGroup.isDisabledBasedOnDependencyForeignProductId = false;
          } else {
            s17SelectionGroup.isDisabledBasedOnDependencyForeignProductId = true;
            s17SelectionGroup.s17child.isDisabled = true;
            s17SelectionGroup.s17child.isSelected = false;
            setIsChildInstallationChanged(false);
            setIsChildSelected(false);
          }
          s17SelectionGroup.isSelected = false;
        }
      }

      if (hasProdSelDependency && component.fields.selections?.length > 0) {
        component.fields.selections = updateChildMethod(
          component,
          event,
          productSelectionDependent,
          updatedProductListState
        );
      }

      return component;
    });

    if (dependencySingleProductId || dependencyGroupProductId || hasProdSelDependency) {
      setOpticalData(updatedOpticalLineSection);
    }

    updateOrderRelatedInfo(updatedProductListState);
    setProductListState(updatedProductListState);
    setCurrentProductChange(null);

    props.setSelectProductSectionState(null);
  }, [currentProductChange, productListState]);

  useEffect(() => {
    if (props.currentSection !== STRING_CURRENT_SECTION_SELECT_PRODUCT) return;
    let hasSelected = false;
    if (productListState) {
      for (let cat in productListState) {
        const product = productListState[cat];
        if (!hasSelected && product.checked) {
          hasSelected = true;
        }

        product.productSet.forEach((set) => {
          if (!hasSelected && set.checked) {
            hasSelected = true;
          }
        });
      }
    }

    props.setBM05Data(hasSelected ? "" : "disabled");
  }, [productListState]);

  useEffect(() => {
    if (!autoSelectProductList) return;

    const opticalLineSection = getScDataFromPlaceholder("optical-line-section", props);
    const [l12Data] = getScDataFromComponent("L-12", opticalLineSection);

    if (!l12Data) return;

    l12Data.fields.isHidden = props.selectProductSectionState === null;
    l12Data.fields.data = autoSelectProductList?.orderItemInfos.map(({ orderItem: item }) => {
      let status = "";
      if (
        item.CMAP_ProductSelectionState__c === STRING_SELECTED &&
        item.CMAP_LastTimeChangeCategory__c === STRING_ADDED
      ) {
        status = "bold";
      } else if (item.CMAP_ProductSelectionState__c === STRING_DESELECTED) {
        status = "line-through";
      }

      return {
        text: item.CMAP_ProductName__c,
        price: item.CMAP_UnitPriceTaxIncluded__c,
        class: status,
      };
    });

    setOpticalData(opticalLineSection);
  }, [autoSelectProductList, props.selectProductSectionState]);

  return (
    <React.Fragment>
      {/* SECTION 2 */}
      <div className="form_wrapper">
        <Placeholder name="form-heading-2" rendering={props.rendering} />

        {!props.isProductSelectionCompleted && (
          <section className="form_wrapper-input">
            <div className="accordion">
              <div className="accordion-detail">
                <Placeholder
                  name="optical-line-section"
                  rendering={props.rendering}
                  isChildSelected={isChildSelected}
                  setIsChildSelected={setIsChildSelected}
                  isChildInstallationChanged={isChildInstallationChanged}
                  setIsChildInstallationChanged={setIsChildInstallationChanged}
                  handleOnChangeProductSet={handleOnChangeProductSet}
                  handleOnChangeInstallment={handleOnChangeInstallment}
                  handleOnChangeQuantity={handleOnChangeQuantity}
                  handleOnChangePrice={handleOnChangePrice}
                  handleOnChangeMovement={handleOnChangeMovement}
                />
              </div>
            </div>
          </section>
        )}

        {props.currentSection === STRING_CURRENT_SECTION_SELECT_PRODUCT && (
          <div className="btn_container">
            <Placeholder name="form-button-2" rendering={props.rendering} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
