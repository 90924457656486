import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";

// @Model

// get the data

// update the context

// @Controller

// @View

const D02 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    descriptionText,
    additionalClass,
    descriptionSubText,
    isHidden,
  } = properData;

  return (
    <React.Fragment>
      {!isHidden && (
        <div className={`description-content ${additionalClass?.value} D-02`}>
          <Text field={fillBlank(descriptionText)} tag="span" encode={false} />
          {descriptionSubText?.value}
        </div>
      )}
    </React.Fragment>
  );
};

export default D02;
