import React, { useEffect, useState } from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { format, parse } from "date-fns";
import ja from "date-fns/locale/ja";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
  computeAge,
  redirectToLinkUrl,
} from "../../utils/commonUtils";
import {
  STRING_RESIDENCE_TYPE_HOME,
  STRING_HIKARI_TV_SERVICE,
  STRING_REQUEST_WEB,
  STRING_POST,
} from "../../utils/constantVariables";
import { di_register } from "../../utils/di";
import { getErrorMessage } from "../../utils/errorMessageHandler";
import { parseFieldMetadata } from "../../utils/parseMetadata";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import { validationFuncs } from "../../utils/validations";

import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { useFormContext } from "react-hook-form";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";

import { useCustomQuery } from "../../hooks/useGetData";
import { apiBaseURL } from "../../envSettings";

const AdditionalInfoForAgencyPageLayout = (props) => {
  // TODO: Remove test data

  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);
  const { setError, clearErrors } = useFormContext();

  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  const locale = {
    locale: ja,
  };

  const mapMetadataToSelect = (data) => {
    if (!data || data?.length === 0) return [];
    return data?.map((opt) => ({
      value: opt.value,
      name: opt.label,
    }));
  };

  // get the data
  const orderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    false
  );
  const personalizeInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );
  const orderData = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order;
  let prefContactMethod = orderData?.CMAP_PreferredCommunicationMethod__c;
  if (!orderData?.CMAP_PreferredCommunicationMethod__c) {
    prefContactMethod = "メール";
  }

  // @Model
  const [inputValues, setInputValues] = useState({
    prefDay: orderData?.CMAP_TelePreferredCommunicationDay__c,
    prefTime: orderData?.CMAP_TelePreferredCommunicationTime__c,
    requestNotToSend: orderData?.CMAP_RequestNotToSend__c,
    supportedLang: orderData?.CMAP_SupportedLanguage__c,
    prefCommunicationMethod: prefContactMethod,
    hopeForConsulting1: orderData?.CMAP_HopeForConsulting1__c,
    hopeForConsulting1Other: orderData?.CMAP_HopeForConsulting1Other__c,
    hopeForConsulting2: orderData?.CMAP_HopeForConsulting2__c,
    hopeForConsulting2Other: orderData?.CMAP_HopeForConsulting2Other__c,
    hearingImpairmentFlg: orderData?.CMAP_HearingImpairmentFlag__c,
    translatorName: orderData?.CMAP_TranslatorName__c,
    translatorNameKana: orderData?.CMAP_TranslatorKana__c,
    seniorCitizenFlg: orderData?.CMAP_SeniorCitizen__c,
    tvAnotherDaySurvey: orderData?.CMAP_RequestInvestigationTVanotherDay__c,
    mgmtCompanyApproval: orderData?.CMAP_ManagementCompanyConsent__c,
    supplementaryCampaignBenefits: orderData?.CMAP_CampaignBenefitAddition__c,
    otherRequest: orderData?.CMAP_OtherCustomerRequest__c,
  });

  const retrieveMetadataDefinitionsEndpoint = `${apiBaseURL}order/RetrieveMetadataDefinitions`;
  const orderChannelCategory =
    personalizeInfo?.AppChannelDivision || STRING_REQUEST_WEB;
  const retrieveMetadataRequest = {
    objectNames: ["Order"],
    orderChannelCategory: orderChannelCategory,
  };
  const {
    data: retrieveMetadataResponse,
    isLoading: retrieveMetadataLoading,
    error: retrieveMetadataError,
    refetch: retrieveMetadataRefresh,
    remove: retrieveMetadataRemove,
  } = useCustomQuery(
    retrieveMetadataDefinitionsEndpoint,
    true,
    STRING_POST,
    retrieveMetadataRequest
  );

  const [retrieveMetadataData, setRetrieveMetadataData] = useState();

  useEffect(() => {
    retrieveMetadataResponse &&
      setRetrieveMetadataData(retrieveMetadataResponse);
  }, [retrieveMetadataResponse]);

  props.setIsLoading(retrieveMetadataLoading);

  const [prefDayMetadata, setPrefDayMetadata] = useState({});
  const [prefTimeMetadata, setPrefTimeMetadata] = useState({});
  const [requestNotToSendMetadata, setRequestNotToSendMetadata] = useState({});
  const [supportedLangMetadata, setSupportedLangMetadata] = useState({});
  const [
    communicationMethodMetadata,
    setCommunicationMethodMetadata,
  ] = useState({});
  const [hopeForConsulting1Metadata, setHopeForConsulting1Metadata] = useState(
    {}
  );
  const [hopeForConsulting2Metadata, setHopeForConsulting2Metadata] = useState(
    {}
  );

  useEffect(() => {
    const retrieveMetadataDataOrder =
      retrieveMetadataData?.metadataDefinitions?.Order
        ?.fieldMetadataDefinitions;

    if (retrieveMetadataData !== "undefined" && retrieveMetadataDataOrder) {
      setPrefDayMetadata(
        parseFieldMetadata(
          retrieveMetadataDataOrder.CMAP_TelePreferredCommunicationDay__c
        )
      );

      setPrefTimeMetadata(
        parseFieldMetadata(
          retrieveMetadataDataOrder.CMAP_TelePreferredCommunicationTime__c
        )
      );

      setRequestNotToSendMetadata(
        parseFieldMetadata(retrieveMetadataDataOrder.CMAP_RequestNotToSend__c)
      );

      setSupportedLangMetadata(
        parseFieldMetadata(retrieveMetadataDataOrder.CMAP_SupportedLanguage__c)
      );

      setCommunicationMethodMetadata(
        parseFieldMetadata(
          retrieveMetadataDataOrder.CMAP_PreferredCommunicationMethod__c
        )
      );

      setHopeForConsulting1Metadata(
        parseFieldMetadata(retrieveMetadataDataOrder.CMAP_HopeForConsulting1__c)
      );

      setHopeForConsulting2Metadata(
        parseFieldMetadata(retrieveMetadataDataOrder.CMAP_HopeForConsulting2__c)
      );
    }
  }, [retrieveMetadataData]);

  // @Controller
  const handleInputChange = (value, name) => {
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  const customerInfoSection = getScDataFromPlaceholder(
    "customer-info-and-request-section",
    props
  );
  const otherInfoSection = getScDataFromPlaceholder(
    "other-additional-info",
    props
  );

  const [
    preferredDaySelect,
    preferredTimeSelect,
    requestNotToSendSelect,
    langSelect,
    communicationMethodSelect,
    hopeConsulting1Select,
    hopeConsulting2Select,
  ] = getScDataFromComponent("M-01", customerInfoSection);

  preferredDaySelect.fields.formName = "CMAP_TelePreferredCommunicationDay__c";
  preferredDaySelect.fields.pulldownData = mapMetadataToSelect(
    prefDayMetadata?.options
  );
  preferredDaySelect.fields.initialValue = {
    value: inputValues.prefDay,
  };
  preferredDaySelect.fields.onChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "prefDay");

  preferredTimeSelect.fields.formName =
    "CMAP_TelePreferredCommunicationTime__c";
  preferredTimeSelect.fields.pulldownData = mapMetadataToSelect(
    prefTimeMetadata?.options
  );
  preferredTimeSelect.fields.initialValue = {
    value: inputValues.prefTime,
  };
  preferredTimeSelect.fields.onChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "prefTime");

  requestNotToSendSelect.fields.formName = "CMAP_RequestNotToSend__c";
  requestNotToSendSelect.fields.pulldownData = mapMetadataToSelect(
    requestNotToSendMetadata?.options
  );
  requestNotToSendSelect.fields.initialValue = {
    value: inputValues.requestNotToSend,
  };
  requestNotToSendSelect.fields.onChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "requestNotToSend");

  langSelect.fields.formName = "CMAP_SupportedLanguage__c";
  langSelect.fields.pulldownData = mapMetadataToSelect(
    supportedLangMetadata?.options
  );
  langSelect.fields.initialValue = {
    value: inputValues.supportedLang,
  };
  langSelect.fields.onChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "supportedLang");

  communicationMethodSelect.fields.formName =
    "CMAP_PreferredCommunicationMethod__c";
  communicationMethodSelect.fields.pulldownData = mapMetadataToSelect(
    communicationMethodMetadata?.options
  );
  communicationMethodSelect.fields.initialValue = {
    value: inputValues.prefCommunicationMethod,
  };
  communicationMethodSelect.fields.onChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "prefCommunicationMethod");

  hopeConsulting1Select.fields.formName = "CMAP_HopeForConsulting1__c";
  hopeConsulting1Select.fields.pulldownData = mapMetadataToSelect(
    hopeForConsulting1Metadata?.options
  );
  hopeConsulting1Select.fields.initialValue = {
    value: inputValues.hopeForConsulting1,
    input: inputValues.hopeForConsulting1Other,
  };
  hopeConsulting1Select.fields.onChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "hopeForConsulting1");
  hopeConsulting1Select.fields.onInputChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "hopeForConsulting1Other");

  hopeConsulting2Select.fields.formName = "CMAP_HopeForConsulting2__c";
  hopeConsulting2Select.fields.pulldownData = mapMetadataToSelect(
    hopeForConsulting2Metadata?.options
  );
  hopeConsulting2Select.fields.initialValue = {
    value: inputValues.hopeForConsulting2,
    input: inputValues.hopeForConsulting2Other,
  };
  hopeConsulting2Select.fields.onChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "hopeForConsulting2");
  hopeConsulting2Select.fields.onInputChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "hopeForConsulting2Other");

  const [birthDateText] = getScDataFromComponent("D-01", customerInfoSection);
  const birthDate =
    orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account?.PersonBirthdate;
  let age = 0;
  if (birthDate) {
    const parseBirthDate = parse(birthDate, "yyyy-MM-dd", new Date());
    const formattedBirthDate = format(parseBirthDate, "yyyy年MM月dd日", locale);
    age = computeAge(birthDate);
    birthDateText.fields.descriptionText = `${formattedBirthDate} 年齢${age}歳`;
  }

  const [
    seniorCitizenCheckbox,
    hearingImpairmentCheckbox,
  ] = getScDataFromComponent("S-09", customerInfoSection);
  seniorCitizenCheckbox.fields.isHidden = age < 65;
  seniorCitizenCheckbox.fields.checkBoxIsCheck = inputValues?.seniorCitizenFlg;
  seniorCitizenCheckbox.fields.formName = "CMAP_SeniorCitizen__c";
  seniorCitizenCheckbox.fields.onChangeEvent = (value) => {
    handleInputChange(value, "seniorCitizenFlg");
  };

  const [
    interpreterNameInput,
    interpreterNameKanaInput,
  ] = getScDataFromComponent("I-01", customerInfoSection);

  interpreterNameInput.fields.isHidden = {
    value: !inputValues?.hearingImpairmentFlg,
  };
  interpreterNameInput.fields.inputValue = {
    name: "CMAP_TranslatorName__c",
    value: inputValues.translatorName,
  };
  interpreterNameInput.fields.onChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "translatorName");

  interpreterNameKanaInput.fields.isHidden = {
    value: !inputValues?.hearingImpairmentFlg,
  };
  interpreterNameKanaInput.fields.inputValue = {
    name: "CMAP_TranslatorKana__c",
    value: inputValues.translatorNameKana,
  };
  interpreterNameKanaInput.fields.onChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "translatorNameKana");

  hearingImpairmentCheckbox.fields.checkBoxIsCheck =
    inputValues?.hearingImpairmentFlg;
  hearingImpairmentCheckbox.fields.formName = "CMAP_HearingImpairmentFlag__c";
  hearingImpairmentCheckbox.fields.onChangeEvent = (value) => {
    const isHidden = { value: !value };
    interpreterNameInput.fields.isHidden = isHidden;
    interpreterNameKanaInput.fields.isHidden = isHidden;
    handleInputChange(value, "hearingImpairmentFlg");
  };

  const [
    tvAnotherDaySurveyCheckbox,
    mgmtCompanyApprovalCheckbox,
  ] = getScDataFromComponent("S-09", otherInfoSection);

  const hasTVItem = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos.some(
    (order) => order.orderItem.CMAP_ProductName__c === STRING_HIKARI_TV_SERVICE
  );
  tvAnotherDaySurveyCheckbox.fields.isHidden = !hasTVItem;
  tvAnotherDaySurveyCheckbox.fields.formName =
    "CMAP_RequestInvestigationTVanotherDay__c";
  tvAnotherDaySurveyCheckbox.fields.checkBoxIsCheck =
    inputValues?.tvAnotherDaySurvey;
  tvAnotherDaySurveyCheckbox.fields.onChangeEvent = (value) =>
    handleInputChange(value, "tvAnotherDaySurvey");

  mgmtCompanyApprovalCheckbox.fields.isHidden =
    orderData?.CMAP_ResidenceType__c !== STRING_RESIDENCE_TYPE_HOME;
  mgmtCompanyApprovalCheckbox.fields.checkBoxIsCheck =
    inputValues?.mgmtCompanyApproval;
  mgmtCompanyApprovalCheckbox.fields.formName =
    "CMAP_ManagementCompanyConsent__c";
  mgmtCompanyApprovalCheckbox.fields.onChangeEvent = (value) =>
    handleInputChange(value, "mgmtCompanyApproval");

  const [supplementaryInfoCampaignBenefitsInput] = getScDataFromComponent(
    "I-01",
    otherInfoSection
  );
  supplementaryInfoCampaignBenefitsInput.fields.inputValue = {
    name: "CMAP_OtherCustomerRequest__c",
    value: inputValues.supplementaryCampaignBenefits,
  };
  supplementaryInfoCampaignBenefitsInput.fields.onChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "supplementaryCampaignBenefits");

  const [otherRequestInput] = getScDataFromComponent("I-04", otherInfoSection);
  otherRequestInput.fields.initialValue = inputValues.otherRequest;
  otherRequestInput.fields.onChangeEvent = (evt) =>
    handleInputChange(evt.target.value, "otherRequest");

  const setDataToSessionStorage = () => {
    const updatedOrderRelatedInfo = {
      ...orderRelatedInfo,
      orderRelatedInfo: {
        ...orderRelatedInfo?.orderRelatedInfo,
        orderInfo: {
          ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
          order: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
            CMAP_SeniorCitizen__c: inputValues?.seniorCitizenFlg,
            CMAP_TelePreferredCommunicationDay__c: inputValues?.prefDay,
            CMAP_TelePreferredCommunicationTime__c: inputValues?.prefTime,
            CMAP_RequestNotToSend__c: inputValues?.requestNotToSend,
            CMAP_SupportedLanguage__c: inputValues?.supportedLang,
            CMAP_PreferredCommunicationMethod__c:
              inputValues?.prefCommunicationMethod,
            CMAP_HearingImpairmentFlag__c: inputValues?.hearingImpairmentFlg,
            CMAP_TranslatorName__c: inputValues?.translatorName,
            CMAP_TranslatorKana__c: inputValues?.translatorNameKana,
            CMAP_HopeForConsulting1__c: inputValues?.hopeForConsulting1,
            CMAP_HopeForConsulting1Other__c:
              inputValues?.hopeForConsulting1Other,
            CMAP_HopeForConsulting2__c: inputValues?.hopeForConsulting2,
            CMAP_HopeForConsulting2Other__c:
              inputValues?.hopeForConsulting2Other,
            CMAP_RequestInvestigationTVanotherDay__c:
              inputValues?.tvAnotherDaySurvey,
            CMAP_ManagementCompanyConsent__c: inputValues?.mgmtCompanyApproval,
            CMAP_CampaignBenefitAddition__c:
              inputValues?.supplementaryCampaignBenefits,
            CMAP_OtherCustomerRequest__c: inputValues?.otherRequest,
          },
        },
      },
    };

    setSessionStorageItem(
      sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
      updatedOrderRelatedInfo,
      true
    );
  };

  const buttonSection = getScDataFromPlaceholder("button-section", props);
  const [backButton] = getScDataFromComponent("B-02", buttonSection);
  backButton.fields.onClickEvent = () => {
    clearErrors();
    setDataToSessionStorage();

    redirectToPreviousPage();
  };

  const [nextButton] = getScDataFromComponent("B-01", buttonSection);
  nextButton.fields.onClickEvent = () => {
    let isValidate = true;
    clearErrors();

    if (!validationFuncs.validateIsRequired(inputValues?.prefDay)) {
      setError("PreferredContactDayOfTheWeekDropDown", {
        type: "manual",
        message: getErrorMessage("E0002", ["連絡希望曜日ドロップダウン"]),
      });
      isValidate = false;
    }

    if (!validationFuncs.validateIsRequired(inputValues?.prefTime)) {
      setError("PreferredContactTimeDropDown", {
        type: "manual",
        message: getErrorMessage("E0002", ["連絡希望時間帯ドロップダウン"]),
      });
      isValidate = false;
    }

    if (age > 65 && !inputValues?.seniorCitizenFlg) {
      setError("SeniorCitizenCheckBox", {
        type: "manual",
        message: getErrorMessage("E0002", ["高齢者チェックボックス"]),
      });
      isValidate = false;
    }

    if (!validationFuncs.validateIsRequired(inputValues?.supportedLang)) {
      setError("SupportedLanguageDropDown", {
        type: "manual",
        message: getErrorMessage("E0002", ["対応言語ドロップダウン"]),
      });
      isValidate = false;
    }

    if (
      !validationFuncs.validateIsRequired(inputValues?.prefCommunicationMethod)
    ) {
      setError("PreferredContactMethodDropDown", {
        type: "manual",
        message: getErrorMessage("E0002", ["連絡希望手段ドロップダウン"]),
      });
      isValidate = false;
    }

    if (inputValues.hearingImpairmentFlg) {
      if (
        !validationFuncs.validateIsMaxLength(inputValues?.translatorName, 100)
      ) {
        setError("InterpreterName", {
          type: "manual",
          message: getErrorMessage("E0015", [
            "通訳できる方の氏名（漢字）",
            100,
          ]),
        });
        isValidate = false;
      }

      if (
        !validationFuncs.validateIsFullWidth(inputValues?.translatorNameKana)
      ) {
        setError("InterpreterNameKana1", {
          type: "manual",
          message: getErrorMessage("E0009", ["通訳できる方の氏名（かな）"]),
        });
        isValidate = false;
      }
      if (
        !validationFuncs.validateIsMaxLength(
          inputValues?.translatorNameKana,
          100
        )
      ) {
        setError("InterpreterNameKana2", {
          type: "manual",
          message: getErrorMessage("E0015", [
            "通訳できる方の氏名（かな）",
            100,
          ]),
        });
        isValidate = false;
      }
    }

    if (
      !validationFuncs.validateIsMaxLength(
        inputValues?.hopeForConsulting1Other,
        255
      )
    ) {
      setError("OtherRequests1", {
        type: "manual",
        message: getErrorMessage("E0015", ["その他要望1", 255]),
      });
      isValidate = false;
    }

    if (
      !validationFuncs.validateIsMaxLength(
        inputValues?.hopeForConsulting2Other,
        255
      )
    ) {
      setError("OtherRequests2", {
        type: "manual",
        message: getErrorMessage("E0015", ["その他要望2", 255]),
      });
      isValidate = false;
    }

    if (
      orderData?.CMAP_ResidenceType__c === STRING_RESIDENCE_TYPE_HOME &&
      !inputValues.mgmtCompanyApproval
    ) {
      setError("ManagementCompanyApprovalCheckBox", {
        type: "manual",
        message: getErrorMessage("E0002", ["管理会社承諾チェックボックス"]),
      });
      isValidate = false;
    }

    if (
      !validationFuncs.validateIsMaxLength(
        inputValues?.supplementaryCampaignBenefits,
        255
      )
    ) {
      setError("SupplementaryInfoOnCampaignBenefits", {
        type: "manual",
        message: getErrorMessage("E0015", ["キャンペーン特典補足情報", 255]),
      });
      isValidate = false;
    }

    if (
      !validationFuncs.validateIsMaxLength(inputValues?.otherRequest, 32768)
    ) {
      setError("OtherRequests", {
        type: "manual",
        message: getErrorMessage("E0015", ["その他お客様要望", 32768]),
      });
      isValidate = false;
    }

    if (!isValidate) return;

    setDataToSessionStorage();

    const {
      "OtherAdditionalInfo-NextButtonLink": nextButtonLink,
    } = sitecoreContext.route.fields;
    redirectToLinkUrl(nextButtonLink);
  };

  // @View
  return (
    <React.Fragment>
      <main>
        <div className="form_container cpad_wrapper2">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <div className="form_wrapper-input">
                <Placeholder
                  name="customer-info-and-request-section"
                  rendering={props.rendering}
                />
                <Placeholder
                  name="other-additional-info"
                  rendering={props.rendering}
                />
                <div className="btn_container">
                  <Placeholder
                    name="button-section"
                    rendering={props.rendering}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(AdditionalInfoForAgencyPageLayout);
