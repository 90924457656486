import { SAME_WINDOW, ANOTHER_WINDOW, SMALL_WINDOW } from "./constantVariables";

export const getURLType = (urlType) => {
  switch (urlType?.fields?.Code?.value) {
    case 1:
    case "1":
      return SAME_WINDOW;
    case 3:
    case "3":
      return SMALL_WINDOW;
    case 2:
    case "2":
      return ANOTHER_WINDOW;
    default:
      return SAME_WINDOW;
  }
};

export const redirectToLinkUrl = (linkURL, urlType) => {
  const url = linkURL?.href;
  if (urlType === SAME_WINDOW) {
    window.location.href = url;
  } else if (urlType === SMALL_WINDOW) {
    // TODO: Change to proper code to open small window
    const width = 600,
      height = 600,
      left = (window.innerWidth - width) / 2,
      top = (window.innerHeight - height) / 2;
    const params = `width=${width},height=${height},left=${left},top=${top}`;
    window.open(url, ANOTHER_WINDOW, params);
  } else {
    window.open(url);
  }
};
