import React, { useState, useEffect } from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import {
  getProperData,
  getScDataFromComponent,
  getScDataFromPlaceholder,
  redirectToLinkUrl,
  isWithinDateRange,
  getUrlInfo,
} from "../../utils/commonUtils";
import { getErrorMessage } from "../../utils/errorMessageHandler";
import {
  validateIsValidDateFormat,
  validationFuncs,
} from "../../utils/validations";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import { format } from "date-fns";
import { useFormContext } from "react-hook-form";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import { apiBaseURL, cpadPageURL } from "../../envSettings";
import { useCustomQuery } from "../../hooks/useGetData";
import {
  STRING_GET,
  STRING_POST,
  STRING_RESIDENCE_TYPE_HOME,
  STRING_RESIDENCE_TYPE_APARTMENT,
} from "../../utils/constantVariables";
import { useSetSelectedItem } from "../../hooks/useSelectedState";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import { parseFieldMetadata } from "../../utils/parseMetadata";

const SelectPreferredDateChangingPlanLayout = (props) => {
  // TODO: temporary use Test Data for no API

  const sitecoreContext = props?.sitecoreContext;
  di_register("thisPageSitecoreContext", sitecoreContext);

  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  const { setError, clearErrors } = useFormContext();

  const S01_COMPONENT_CATEGORY = "RegisterInstallationAddress";
  const [selectedBuildingType, selectedBuildingTypeSetter] = useSetSelectedItem(
    S01_COMPONENT_CATEGORY
  );
  const [inputValues, setInputValues] = useState({ desiredDate: "" });
  const [
    showRegisterInstallationAddress,
    setShowRegisterInstallationAddress,
  ] = useState(false);
  const [optionsState, setOptionsState] = useState([]);
  const [addressLabelState, setAddressLabelState] = useState();
  const [streetInputValue, setStreetInputValue] = useState({
    value: "",
    isDisabled: false,
  });
  const [buildingNameInputValue, setBuildingNameInputValue] = useState({
    value: "",
    isDisabled: false,
  });
  const [roomNumberInputValue, setRoomNumberInputValue] = useState({
    value: "",
    isDisabled: false,
  });
  const [streetCharLimit, setStreetCharLimit] = useState(0);
  const [buildingCharLimit, setBuildingCharLimit] = useState(0);
  const [roomCharLimit, setRoomCharLimit] = useState(0);

  const [siteURL, setSiteURL] = useState("");

  const [apiStatus, setApiStatus] = useState({
    isRegisterCustomerApiFinished: false,
    isRetrieveAddressApiFinished: false,
    registerCustomerApiData: null,
    retrieveAddressApiData: null,
  });

  const isCpad = siteURL === cpadPageURL;

  const preferredPlanChangeDate = getSessionStorageItem(
    keys.SSN_APPLICATION_SELECTPREFERREDDATEOFCHANGINGPLAN_PREFERREDPLANCHANGEDATE,
    true
  );

  let orderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );
  const beforeChangeOrderRelatedInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO,
    true
  );
  const personalizeInfo = getSessionStorageItem(
    keys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  );
  const isALCMProperty = getSessionStorageItem(
    keys.SSN_APPLICATION_APARTMENTSEARCH_ISALCMPROPERTY,
    true
  );
  const installationLocationAddress = getSessionStorageItem(
    keys.SSN_APPLICATION_SELECTPLANTYPE_INSTALLATIONLOCATIONADDRESS,
    true
  );
  const apartmentStreetBunch = getSessionStorageItem(
    keys.SSN_APPLICATION_APARTMENTSEARCH_APARTMENTSTREETBUNCH,
    true
  );
  const inputCustomerStreetNumber = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_STREETNUMBER,
    true
  );
  const apartmentName = getSessionStorageItem(
    keys.SSN_APPLICATION_APARTMENTSEARCH_APARTMENTNAME,
    true
  );
  const inputCustomerBuildingName = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_BUILDINGNAME,
    true
  );
  const inputRoomNumber = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTROOMNUMBER_ROOMNUMBER,
    true
  );
  const inputCustomerRoomNumber = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTROOMNUMBER_ROOMNUMBER,
    true
  );
  const prefecture = getSessionStorageItem(
    keys.SSN_APPLICATION_RECONFIRMADDRESS_PREFECTURE,
    true
  );
  const reconfirmCity = getSessionStorageItem(
    keys.SSN_APPLICATION_RECONFIRMADDRESS_CITY,
    true
  );
  const reconfirmDistrict = getSessionStorageItem(
    keys.SSN_APPLICATION_RECONFIRMADDRESS_DISTRICT,
    true
  );
  const reconfirmStreet1 = getSessionStorageItem(
    keys.SSN_APPLICATION_RECONFIRMADDRESS_STREET1,
    true
  );

  const ssnAddressCodes =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
      ?.CMAP_InstallationLocationAddressCode11__c;

  let addressCodes = "";
  if (typeof ssnAddressCodes === "string") {
    ssnAddressCodes.split(",").map((item, index) => {
      addressCodes += `${index > 0 ? "&" : ""}addressCodes=${item.trim()}`;
    });
  }

  const residenceType =
    orderRelatedInfo.orderRelatedInfo?.orderInfo?.order?.CMAP_ResidenceType__c;

  // api urls
  const systemDateTimeURI = `${apiBaseURL}common/RetrieveSystemDateTime`;
  const retrieveSelectableDesiredDateRangeURI = `${apiBaseURL}order/RetrieveSelectableDesiredDateRange`;
  const retrieveMetadataDefinitionsURI = `${apiBaseURL}order/RetrieveMetadataDefinitions`;
  const registerCustomerInfoURI = `${apiBaseURL}order/RegisterCustomerInfo`;
  const retrieveAddressInfoURI = `${apiBaseURL}order/RetrieveAddressInfo?${addressCodes}`;

  const {
    data: systemDateTimeData,
    isLoading: systemDateTimeIsLoading,
  } = useCustomQuery(systemDateTimeURI, true, STRING_GET);

  // payloads
  const orderRelatedInfoAccount =
    orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account;
  const orderRelatedInfoAccountAddition =
    orderRelatedInfo?.orderRelatedInfo?.accountInfo?.accountAddition;
  const orderRelatedInfoOrder =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order;

  const isTypeCircuit = orderRelatedInfoOrder?.CMAP_IsTypeCircuit__c;
  const beforeIsTypeCircuit =
    beforeChangeOrderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
      ?.CMAP_IsTypeCircuit__c;

  const orderItemInfos = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos?.map(
    (item) => ({
      Id: item.orderItem?.Id,
      Product2Id: item.orderItem?.Product2Id,
      CMAP_ProductSelectionState__c:
        item.orderItem?.CMAP_ProductSelectionState__c,
      CMAP_LastTimeChangeCategory__c:
        item.orderItem?.CMAP_LastTimeChangeCategory__c,
    })
  );

  const systemDateTime = systemDateTimeData?.SystemDateTime?.split("T")[0];
  const orderInfoEffectiveDate =
    orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order?.EffectiveDate;
  const effectiveDate = isCpad ? orderInfoEffectiveDate : systemDateTime;

  const orderRelatedInfoPayload = {
    orderRelatedInfo: {
      orderInfo: {
        order: {
          CMAP_OrderCategory__c:
            orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
              ?.CMAP_OrderCategory__c,
          CMAP_OrderBeforeChange__c:
            orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
              ?.CMAP_OrderBeforeChange__c,
          EffectiveDate: effectiveDate,
        },
        orderAddition: {
          orderCategoryCurrentlySelected:
            orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition
              ?.orderCategoryCurrentlySelected,
          orderCategoryPreviouslySelected:
            orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition
              ?.orderCategoryPreviouslySelected,
        },
        orderItemInfos: orderItemInfos,
      },
    },
  };

  const retrieveMetadataDefinitionsPayload = {
    objectNames: ["Order", "Account"],
    orderChannelCategory: personalizeInfo.ChannelKubun,
  };

  const registerCustomerInfoPayload = {
    duplicateAlertSkipped: true,
    orderChannelCategory: personalizeInfo.AppChannelDivision,
    registered: false,
    accountInfo: {
      account: {
        Id: orderRelatedInfoAccount?.Id,
        CMAP_ContractName__c: orderRelatedInfoAccount?.CMAP_ContractName__c,
        LastName: orderRelatedInfoAccount?.LastName,
        FirstName: orderRelatedInfoAccount?.FirstName,
        LastNameKana__pc: orderRelatedInfoAccount?.LastNameKana__pc,
        FirstNameKana__pc: orderRelatedInfoAccount?.FirstNameKana__pc,
        CMAP_Gender__c: orderRelatedInfoAccount?.CMAP_Gender__c,
        PersonBirthdate: orderRelatedInfoAccount?.PersonBirthdate,
        CMAP_CorporateGroupName__c:
          orderRelatedInfoAccount?.CMAP_CorporateGroupName__c,
        CMAP_DepartmentName__c: orderRelatedInfoAccount?.CMAP_DepartmentName__c,
        CMAP_ContactPersonFamilyName__c:
          orderRelatedInfoAccount?.CMAP_ContactPersonFamilyName__c,
        CMAP_ContactPersonName__c:
          orderRelatedInfoAccount?.CMAP_ContactPersonName__c,
        CMAP_ContactPersonLastNameKana__pc:
          orderRelatedInfoAccount?.CMAP_ContactPersonLastNameKana__pc,
        CMAP_ContactPersonFirstNameKana__pc:
          orderRelatedInfoAccount?.CMAP_ContactPersonFirstNameKana__pc,
        CMAP_ContactPostalCode__c:
          orderRelatedInfoAccount?.CMAP_ContactPostalCode__c,
        CMAP_ContactPostalCodeLowerFour__c:
          orderRelatedInfoAccount?.CMAP_ContactPostalCodeLowerFour__c,
        CMAP_ContactState__c: orderRelatedInfoAccount?.CMAP_ContactState__c,
        CMAP_ContactCity__c: orderRelatedInfoAccount?.CMAP_ContactCity__c,
        CMAP_ContactHouseNumber__c:
          orderRelatedInfoAccount?.CMAP_ContactHouseNumber__c,
        CMAP_MansionName__c: orderRelatedInfoAccount?.CMAP_MansionName__c,
        CMAP_RoomNo__c: orderRelatedInfoAccount?.CMAP_RoomNo__c,
      },
      accountAddition: {
        isNewAccount: orderRelatedInfoAccountAddition?.isNewAccount,
        leadId: orderRelatedInfoAccountAddition?.leadId,
      },
    },
  };

  const {
    data: selectableDesiredDateRangeData,
    isLoading: selectableDesiredDateRangeIsLoading,
    refetch: selectableDesiredDateRangeRefresh,
  } = useCustomQuery(
    retrieveSelectableDesiredDateRangeURI,
    false,
    STRING_POST,
    orderRelatedInfoPayload
  );

  const {
    data: metadataDefinitionsData,
    isLoading: metadataDefinitionsIsLoading,
    refetch: metadataDefinitionsRefresh,
  } = useCustomQuery(
    retrieveMetadataDefinitionsURI,
    false,
    STRING_POST,
    retrieveMetadataDefinitionsPayload
  );

  const {
    data: registerCustomerInfoData,
    isLoading: registerCustomerInfoIsLoading,
    refetch: registerCustomerInfoRefresh,
  } = useCustomQuery(
    registerCustomerInfoURI,
    false,
    STRING_POST,
    registerCustomerInfoPayload
  );

  const {
    data: retrieveAddressInfoData,
    isLoading: retrieveAddressInfoIsLoading,
    refetch: retrieveAddressInfoRefresh,
  } = useCustomQuery(retrieveAddressInfoURI, false, STRING_GET);

  const isApiLoading =
    systemDateTimeIsLoading ||
    selectableDesiredDateRangeIsLoading ||
    metadataDefinitionsIsLoading ||
    registerCustomerInfoIsLoading ||
    retrieveAddressInfoIsLoading;
  props.setIsLoading(isApiLoading);

  const preferredDateSection = getScDataFromPlaceholder(
    "changing-plan-date-section",
    props
  );
  const buttonSection = getScDataFromPlaceholder(
    "changing-plan-date-button",
    props
  );
  const registerInstallationAddress = getScDataFromPlaceholder(
    "register-installation-address",
    props
  );
  const registerInstallationAddressTwoColumnInput = getScDataFromPlaceholder(
    "register-installation-address-two-column-input",
    props
  );

  const [backButton] = getScDataFromComponent("B-02", buttonSection);
  const [nextButton] = getScDataFromComponent("B-01", buttonSection);
  const [M01iData] = getScDataFromComponent("M-01i", preferredDateSection);
  const [radioButton] = getScDataFromComponent(
    "S-01",
    registerInstallationAddress
  );
  const addressLabel = getScDataFromComponent(
    "D-01",
    registerInstallationAddress
  )[1];
  const [streetInput] = getScDataFromComponent(
    "I-01",
    registerInstallationAddress
  );
  const buildingInput = getScDataFromComponent(
    "I-01",
    registerInstallationAddressTwoColumnInput
  )[0];
  const roomNumberInput = getScDataFromComponent(
    "I-01",
    registerInstallationAddressTwoColumnInput
  )[1];

  M01iData.fields.initialValue = { value: inputValues.desiredDate };
  M01iData.fields.onChangeEvent = (date) => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        desiredDate: format(date, "yyyy-MM-dd"),
      };
    });
  };
  radioButton.fields["category"] = {
    value: S01_COMPONENT_CATEGORY,
  };
  radioButton.fields["selections"] = optionsState;
  addressLabel.fields["descriptionText"] = { value: addressLabelState };
  streetInput.fields["inputValue"] = streetInputValue;

  streetInput.fields.onChangeEvent = (e) => {
    const value = e.target.value;
    setStreetInputValue((prev) => ({ ...prev, value: value }));
  };
  buildingInput.fields["inputValue"] = buildingNameInputValue;
  buildingInput.fields.onChangeEvent = (e) => {
    const value = e.target.value;
    setBuildingNameInputValue((prev) => ({ ...prev, value: value }));
  };
  roomNumberInput.fields["inputValue"] = roomNumberInputValue;
  roomNumberInput.fields.onChangeEvent = (e) => {
    const value = e.target.value;
    setRoomNumberInputValue((prev) => ({ ...prev, value: value }));
  };

  useEffect(() => {
    // Urls infos
    const urlInfo = getUrlInfo();
    setSiteURL(urlInfo?.origin);
  }, []);

  useEffect(() => {
    selectedBuildingTypeSetter({ [S01_COMPONENT_CATEGORY]: "" });
    if (isTypeCircuit && !beforeIsTypeCircuit) {
      setShowRegisterInstallationAddress(true);
      metadataDefinitionsRefresh();
      return;
    }

    setShowRegisterInstallationAddress(false);
  }, []);

  useEffect(() => {
    if (systemDateTimeData) {
      selectableDesiredDateRangeRefresh();
    }
  }, [systemDateTimeData]);

  useEffect(() => {
    if (selectableDesiredDateRangeData) {
      const minDate =
        selectableDesiredDateRangeData.selectablePreferDatesRangeInfo
          ?.minPreferDate;
      const maxDate =
        selectableDesiredDateRangeData.selectablePreferDatesRangeInfo
          ?.maxPreferDate;

      M01iData.fields.minDate = minDate;
      M01iData.fields.maxDate = maxDate;

      if (isWithinDateRange(preferredPlanChangeDate, minDate, maxDate)) {
        setInputValues((prevState) => {
          return {
            ...prevState,
            desiredDate: preferredPlanChangeDate,
          };
        });
      }
    }
  }, [selectableDesiredDateRangeData]);

  useEffect(() => {
    if (metadataDefinitionsData) {
      const streetCharLimit =
        metadataDefinitionsData.metadataDefinitions?.Account
          ?.fieldMetadataDefinitions?.CMAP_ContactHouseNumber__c?.fieldMetadata
          ?.fieldMetadataExtension?.CMAP_NumberOfLetters__c;
      const buildingCharLimit =
        metadataDefinitionsData.metadataDefinitions?.Account
          ?.fieldMetadataDefinitions?.CMAP_MansionName__c?.fieldMetadata
          ?.fieldMetadataExtension?.CMAP_NumberOfLetters__c;
      const roomCharLimit =
        metadataDefinitionsData.metadataDefinitions?.Account
          ?.fieldMetadataDefinitions?.CMAP_RoomNo__c?.fieldMetadata
          ?.fieldMetadataExtension?.CMAP_NumberOfLetters__c;
      const CMAP_BuildingCategory__c =
        metadataDefinitionsData.metadataDefinitions?.Order
          ?.fieldMetadataDefinitions?.CMAP_BuildingCategory__c?.fieldMetadata
          ?.fieldMetadataExtension;

      const optionCondition = JSON.parse(
        CMAP_BuildingCategory__c?.CMAP_OptionCondition__c
      );

      const buildingType = optionCondition
        ?.find((type) => type.condition[0].value === residenceType)
        .listitem?.map((item) => {
          const optionArr = {
            Code: item.value,
            Value: item.label,
          };
          return optionArr;
        });

      setStreetCharLimit(streetCharLimit);
      setBuildingCharLimit(buildingCharLimit);
      setRoomCharLimit(roomCharLimit);
      setOptionsState(buildingType);

      if (residenceType === STRING_RESIDENCE_TYPE_APARTMENT && isALCMProperty) {
        setAddressLabelState(installationLocationAddress);
        setStreetInputValue({
          value: apartmentStreetBunch,
          isDisabled: true,
        });
        setBuildingNameInputValue({
          value: apartmentName,
          isDisabled: true,
        });
        setRoomNumberInputValue({
          value: inputRoomNumber,
          isDisabled: true,
        });
      } else if (
        residenceType === STRING_RESIDENCE_TYPE_APARTMENT &&
        isALCMProperty !== true
      ) {
        setAddressLabelState(installationLocationAddress);
        setStreetInputValue({
          value: apartmentStreetBunch,
          isDisabled: true,
        });
        setBuildingNameInputValue({
          value: apartmentName,
          isDisabled: true,
        });
        setRoomNumberInputValue({
          value: inputCustomerRoomNumber,
          isDisabled: false,
        });
      } else if (residenceType === STRING_RESIDENCE_TYPE_HOME) {
        setAddressLabelState(
          `${prefecture} ${reconfirmCity} ${reconfirmDistrict} ${reconfirmStreet1}`
        );
        setStreetInputValue({
          value: inputCustomerStreetNumber,
          isDisabled: false,
        });
        setBuildingNameInputValue({
          value: inputCustomerBuildingName,
          isDisabled: false,
        });
        setRoomNumberInputValue({
          value: null,
          isDisabled: true,
        });
      } else {
        setShowRegisterInstallationAddress(false);
      }
    }
  }, [metadataDefinitionsData]);

  // Event #2
  backButton.fields.onClickEvent = () => {
    clearErrors();

    setSessionStorageItem(
      keys.SSN_APPLICATION_SELECTPREFERREDDATEOFCHANGINGPLAN_PREFERREDPLANCHANGEDATE,
      inputValues.desiredDate,
      true
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_INPUTCUSTOMERINFO_BUILDINGDIVISION,
      selectedBuildingType?.selected,
      true
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_INPUTCUSTOMERINFO_STREETNUMBER,
      streetInputValue.value,
      true
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_INPUTCUSTOMERINFO_BUILDINGNAME,
      buildingNameInputValue.value,
      true
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_INPUTCUSTOMERINFO_ROOMNUMBER,
      roomNumberInputValue.value,
      true
    );

    redirectToPreviousPage();
  };

  // Event #3
  nextButton.fields.onClickEvent = async () => {
    clearErrors();
    let hasError = false;
    if (
      !validationFuncs.validateIsMaxLength(
        streetInputValue.value,
        streetCharLimit
      )
    ) {
      setError("StreetNumber", {
        type: "manual",
        message: getErrorMessage("E0015", ["町名・番地", streetCharLimit]),
      });
      hasError = true;
    }
    if (
      !validationFuncs.validateIsMaxLength(
        buildingNameInputValue.value,
        buildingCharLimit
      )
    ) {
      setError("BuildingName", {
        type: "manual",
        message: getErrorMessage("E0015", ["建物名", buildingCharLimit]),
      });
      hasError = true;
    }
    if (
      !validationFuncs.validateIsMaxLength(
        roomNumberInputValue.value,
        roomCharLimit
      ) &&
      residenceType === STRING_RESIDENCE_TYPE_APARTMENT
    ) {
      setError("RoomNumber", {
        type: "manual",
        message: getErrorMessage("E0015", ["部屋番号", roomCharLimit]),
      });
      hasError = true;
    }

    if (!validationFuncs.validateIsRequired(inputValues.desiredDate)) {
      setError("preferredDateChangingPlan-1", {
        type: "manual",
        message: getErrorMessage("E0002", ["プラン変更希望日選択カレンダー"]),
      });
      hasError = true;
    }

    if (!validateIsValidDateFormat(inputValues.desiredDate)) {
      setError("preferredDateChangingPlan-2", {
        type: "manual",
        message: getErrorMessage("E0020", [
          "プラン変更希望日選択カレンダー",
          "yyyy年MM月dd日(w)",
        ]),
      });
      hasError = true;
    }
    if (
      !validationFuncs.validateIsRequired(selectedBuildingType?.selected) &&
      isTypeCircuit &&
      !beforeIsTypeCircuit
    ) {
      setError("preferredDateChangingPlan-3", {
        type: "manual",
        message: getErrorMessage("E0002", ["建物区分ラジオボタン"]),
      });
      hasError = true;
    }
    if (
      !validationFuncs.validateIsRequired(streetInputValue.value) &&
      isTypeCircuit &&
      !beforeIsTypeCircuit
    ) {
      setError("preferredDateChangingPlan-4", {
        type: "manual",
        message: getErrorMessage("E0001", ["町名・番地"]),
      });
      hasError = true;
    }

    if (hasError) return;

    setSessionStorageItem(
      keys.SSN_APPLICATION_SELECTPREFERREDDATEOFCHANGINGPLAN_PREFERREDPLANCHANGEDATE,
      inputValues.desiredDate,
      true
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_INPUTCUSTOMERINFO_BUILDINGDIVISION,
      selectedBuildingType?.selected,
      true
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_INPUTCUSTOMERINFO_STREETNUMBER,
      streetInputValue.value,
      true
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_INPUTCUSTOMERINFO_BUILDINGNAME,
      buildingNameInputValue.value,
      true
    );

    setSessionStorageItem(
      keys.SSN_APPLICATION_INPUTCUSTOMERINFO_ROOMNUMBER,
      roomNumberInputValue.value,
      true
    );

    if (isTypeCircuit && !beforeIsTypeCircuit) {
      registerCustomerInfoRefresh();
      if (residenceType === STRING_RESIDENCE_TYPE_APARTMENT) {
        retrieveAddressInfoRefresh();
      } else {
        // set retrieve address api status to finish
        setApiStatus((prevState) => {
          return {
            ...prevState,
            isRetrieveAddressApiFinished: true,
          };
        });
      }
    } else {
      // set apis status to finished
      setApiStatus((prevState) => {
        return {
          ...prevState,
          isRegisterCustomerApiFinished: true,
          isRetrieveAddressApiFinished: true,
        };
      });
    }
  };

  useEffect(() => {
    if (
      registerCustomerInfoData &&
      registerCustomerInfoData?.accountRegistrationUpdateResultInfo
    ) {
      setApiStatus((prevState) => {
        return {
          ...prevState,
          isRegisterCustomerApiFinished: true,
          registerCustomerApiData: registerCustomerInfoData,
        };
      });
    }
  }, [registerCustomerInfoData]);

  useEffect(() => {
    if (retrieveAddressInfoData && retrieveAddressInfoData?.streets) {
      const address = retrieveAddressInfoData?.streets[0];

      setApiStatus((prevState) => {
        return {
          ...prevState,
          isRetrieveAddressApiFinished: true,
          retrieveAddressApiData: address,
        };
      });
    }
  }, [retrieveAddressInfoData]);

  useEffect(() => {
    if (
      apiStatus.isRegisterCustomerApiFinished === true &&
      apiStatus.isRetrieveAddressApiFinished === true
    ) {
      orderRelatedInfo = {
        ...orderRelatedInfo,
        orderRelatedInfo: {
          ...orderRelatedInfo?.orderRelatedInfo,
          orderInfo: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
            order: {
              ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
              CMAP_PlanChangeRequestDate__c: inputValues?.desiredDate,
            },
          },
        },
      };

      if (apiStatus?.retrieveAddressApiData) {
        const address = apiStatus?.retrieveAddressApiData;
        const municipalAddress = `${address?.CMAP_StreetOoazaName__c} ${address?.CMAP_StreetAzaName__c} ${streetInputValue?.value}`;

        orderRelatedInfo = {
          ...orderRelatedInfo,
          orderRelatedInfo: {
            ...orderRelatedInfo?.orderRelatedInfo,
            orderInfo: {
              ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
              order: {
                ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
                CMAP_BuildingCategory__c: selectedBuildingType?.selected,
                ShippingStreet: municipalAddress,
                CMAP_ApartmentPropertyName__c: buildingNameInputValue?.value,
                CMAP_InstallationLocationRoomNumber__c:
                  roomNumberInputValue?.value,
              },
            },
          },
        };
      }

      if (apiStatus?.registerCustomerApiData) {
        const account =
          apiStatus?.registerCustomerApiData
            ?.accountRegistrationUpdateResultInfo?.accountInfo?.account;
        const accountAddition =
          apiStatus?.registerCustomerApiData
            ?.accountRegistrationUpdateResultInfo?.accountInfo?.accountAddition;

        orderRelatedInfo = {
          ...orderRelatedInfo,
          orderRelatedInfo: {
            ...orderRelatedInfo?.orderRelatedInfo,
            accountInfo: {
              ...orderRelatedInfo?.orderRelatedInfo?.accountInfo,
              account: {
                ...orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account,
                Id: account?.Id,
                CMAP_ContractName__c: account?.CMAP_ContractName__c,
                LastName: account?.LastName,
                FirstName: account?.FirstName,
                LastNameKana__pc: account?.LastNameKana__pc,
                FirstNameKana__pc: account?.FirstNameKana__pc,
                CMAP_Gender__c: account?.CMAP_Gender__c,
                PersonBirthdate: account?.PersonBirthdate,
                CMAP_CorporateGroupName__c: account?.CMAP_CorporateGroupName__c,
                CMAP_DepartmentName__c: account?.CMAP_DepartmentName__c,
                CMAP_ContactPersonFamilyName__c:
                  account?.CMAP_ContactPersonFamilyName__c,
                CMAP_ContactPersonName__c: account?.CMAP_ContactPersonName__c,
                CMAP_ContactPersonLastNameKana__pc:
                  account?.CMAP_ContactPersonLastNameKana__pc,
                CMAP_ContactPersonFirstNameKana__pc:
                  account?.CMAP_ContactPersonFirstNameKana__pc,
                CMAP_ContactPostalCode__c: account?.CMAP_ContactPostalCode__c,
                CMAP_ContactPostalCodeLowerFour__c:
                  account?.CMAP_ContactPostalCodeLowerFour__c,
                CMAP_ContactState__c: account?.CMAP_ContactState__c,
                CMAP_ContactCity__c: account?.CMAP_ContactCity__c,
                CMAP_ContactHouseNumber__c: account?.CMAP_ContactHouseNumber__c,
                CMAP_MansionName__c: account?.CMAP_MansionName__c,
                CMAP_RoomNo__c: account?.CMAP_RoomNo__c,
                CMAP_AreaCode__c: account?.CMAP_AreaCode__c,
                CMAP_LocalTelephoneNumber__c:
                  account?.CMAP_LocalTelephoneNumber__c,
                CMAP_SubscriberNumber__c: account?.CMAP_SubscriberNumber__c,
                CMAP_MobileNumber1__c: account?.CMAP_MobileNumber1__c,
                CMAP_MobileNumber2__c: account?.CMAP_MobileNumber2__c,
                CMAP_MobileNumber3__c: account?.CMAP_MobileNumber3__c,
                CMAP_MyCommufaID__c: account?.CMAP_MyCommufaID__c,
                StartURLValue__c: account?.StartURLValue__c,
                CMAP_MultilingualReexplainCenter__c:
                  account?.CMAP_MultilingualReexplainCenter__c,
              },
              accountAddition: {
                ...orderRelatedInfo?.orderRelatedInfo?.accountInfo
                  ?.accountAddition,
                isNewAccount: accountAddition?.isNewAccount,
                leadId: accountAddition?.leadId,
              },
            },
          },
        };
      }

      setSessionStorageItem(
        keys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
        orderRelatedInfo,
        true
      );

      const { linkURL } = getProperData(
        props.sitecoreContext,
        nextButton.fields
      );

      redirectToLinkUrl(linkURL);
    }
  }, [apiStatus]);

  return (
    <React.Fragment>
      <Placeholder
        name="customer-info-one-column"
        rendering={props.rendering}
      ></Placeholder>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <div className="form_wrapper-input">
                <Placeholder
                  name="changing-plan-date-section"
                  rendering={props.rendering}
                />
                {showRegisterInstallationAddress && (
                  <div className="form_container cpad_wrapper2">
                    <div className="register-installation-address-section">
                      <div className="form_wrapper-input">
                        <Placeholder
                          name="register-installation-address"
                          rendering={props.rendering}
                        />
                      </div>
                      <div className="grid-column-two">
                        <Placeholder
                          name="register-installation-address-two-column-input"
                          rendering={props.rendering}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="btn_container">
                <Placeholder
                  name="changing-plan-date-button"
                  rendering={props.rendering}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(SelectPreferredDateChangingPlanLayout);
