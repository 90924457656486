import React, { useState, useEffect } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { di_register } from "../../utils/di";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import { apiBaseURL } from "../../envSettings";
import { useCustomQuery } from "../../hooks/useGetData";
import { STRING_GET } from "../../utils/constantVariables";
import { redirectToLinkUrl } from "../../utils/targetUrlType";
import { getCookieValue } from "../../utils/useCookies"

const HomeSearchResultJudgementLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  const pageContext = sitecoreContext?.route?.fields;
  di_register("thisPageSitecoreContext", sitecoreContext);

  const orderChannelCategory = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
    true
  )?.AppChannelDivision || "Web";

  const [retrieveOfferMethodListParams, setRetrieveOfferMethodListParams] = useState({});
  const retrieveOfferMethodListURI = `${apiBaseURL}order/RetrieveOfferMethodList?${retrieveOfferMethodListParams}`;

  const {
    data: retrieveOfferMethodListData,
    isLoading: retrieveOfferMethodListIsLoading,
    error: retrieveOfferMethodListError,
    refetch: retrieveOfferMethodListRefresh,
    remove: retrieveOfferMethodListRemove,
  } = useCustomQuery(retrieveOfferMethodListURI, false, STRING_GET);

  props.setIsLoading(retrieveOfferMethodListIsLoading);

  const availableNextButtonLink =
    pageContext["HomeSearchResultEvaluation-AvailableNextButtonLink"]?.value;
  const unAvailableNextButtonLink =
    pageContext["HomeSearchResultEvaluation-UnAvailableNextButtonLink"]?.value;

  // get cookie
  useEffect(() => {
    // read cookie value
    const cookieValue = getCookieValue(sessionKeys.COOKIE_PASCO_RESULT) || null;
    const pascoData = JSON.parse(cookieValue) || {};
    
    const o_citycode = pascoData?.o_citycode ?? "";
    const o_lon_j = pascoData?.o_lon_j ?? "";
    const o_lat_j = pascoData?.o_lat_j ?? "";
    const o_lon_w = pascoData?.o_lon_w ?? "";
    const o_lat_w = pascoData?.o_lat_w ?? "";
    const o_blockno = pascoData?.o_blockno ?? "";
    const o_shared = pascoData?.o_shared ?? "";
    const o_tengteikyo = pascoData?.o_tengteikyo ?? "";
    const o_msgcode = pascoData?.o_msgcode ?? "";
    const o_tvprovide = pascoData?.o_tvprovide ?? "";
    const o_broadcastcode = pascoData?.o_broadcastcode ?? "";
    const o_adrcd = pascoData?.o_adrcd ?? "";

    const cityCodeFirst2 = o_citycode?.slice(0, 2) ?? "";
    const cityCodeLast3 = o_citycode?.slice(-3) ?? "";

    //TODO: include the ff fields (blockNumberTypeC & layerCodeTypeC) once the values has been decided
    const paramsString =
      `prefectureCode=${cityCodeFirst2}` +
      `&cityCode=${cityCodeLast3}` +
      `&priorityOfferMethod=${o_shared}` +
      `&groupUnitCenterTypeK=${o_shared === "1" ? o_blockno : ""}` +
      `&layerCodeTypeK=${o_shared === "1" ? o_msgcode : ""}` +
      `&groupUnitCenterTypeS=${o_shared === "2" ? o_blockno : ""}` +
      `&layerCodeTypeS=${o_shared === "2" ? o_msgcode : ""}` +
      `&orderChannelCategory=${orderChannelCategory}` +
      `&residenceType=Home`;

    // save SS
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_ADDRESSCODE,
      o_citycode,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_LONGITUDE_J,
      o_lon_j,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_LATITUDE_J,
      o_lat_j,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_LONGITUDE_W,
      o_lon_w,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_LATITUDE_W,
      o_lat_w,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_BLOCKNO,
      o_blockno,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_SHARED,
      o_shared,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_TENGTEIKYO,
      o_tengteikyo,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_MSGCODE,
      o_msgcode,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_TVPROVIDE,
      o_tvprovide,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_BROADCASTCODE,
      o_broadcastcode,
      true
    );
    setSessionStorageItem(
      sessionKeys.SSN_APPLICATION_HOMESEARCHRESULTEVALUATION_ADRCD,
      o_adrcd,
      true
    );

    setRetrieveOfferMethodListParams(paramsString);
  }, []);

  // refresh api
  useEffect(() => {
    if (retrieveOfferMethodListParams.length > 0) {
      retrieveOfferMethodListRefresh();
    }
  }, [retrieveOfferMethodListParams]);

  // receive api data
  useEffect(() => {
    if (
      retrieveOfferMethodListData &&
      retrieveOfferMethodListData?.offerMethodInfos
    ) {
      if (retrieveOfferMethodListData?.offerMethodInfos?.length > 0) {
        redirectToLinkUrl(availableNextButtonLink, "_self");
      } else {
        redirectToLinkUrl(unAvailableNextButtonLink, "_self");
      }
    }
  }, [retrieveOfferMethodListData]);

  return (
    <>
    </>
  );
};

export default withSitecoreContext()(HomeSearchResultJudgementLayout);