import React, { useState, useEffect } from "react";
import {
    Placeholder,
    withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { di_register } from "../../utils/di";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import parse from "html-react-parser";
import {
    getSessionStorageItem,
    setSessionStorageItem,
    removeSessionStorageItem,
} from "../../utils/useSessionStorage";
import {
    deepcopy,
    getScDataFromComponent,
    getScDataFromPlaceholder,
    formatCurrency,
    generateGUID,
    redirectToLinkUrl,
    isInvalidLoginStatus,
} from "../../utils/commonUtils";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";
import {
    STRING_CURRENCY_CHAR,
    STRING_LOADING,
    STRING_GET,
    STRING_POST,
    STRING_ERROR_500_URI,
    STRING_ERROR_403_URI,
    STRING_SIMPLE_OP_CONTENT_LABEL,
    STRING_SIMPLE_OP_QUANTITY_LABEL,
    STRING_SIMPLE_OP_PRICE_LABEL,
    STRING_SIMPLE_OP_CONSUMPTION_TAX_LABEL,
    STRING_SIMPLE_OP_SUMMARY_LABEL,
    STRING_SIMPLE_OP_UNEXT_COMMON_KEY,
    STRING_SIMPLE_OP_UNEXT_APPLY_ATTR,
    STRING_SIMPLE_OP_KURASHI_COMMON_KEY,
    STRING_SIMPLE_OP_KURASHI_APPLY_ATTR,
    STRING_SIMPLE_OP_DAZN_COMMON_KEY,
    STRING_SIMPLE_OP_DAZN_APPLY_ATTR,
    STRING_SIMPLE_OP_DAZN_APPLY_MONTHLY_VAL,
    STRING_SIMPLE_OP_DAZN_APPLY_ANNUAL_VAL,
    STRING_SIMPLE_OP_SH_DEVICE_KEY,
    STRING_SIMPLE_OP_SH_PAYMENT_ATTR,
    STRING_SIMPLE_OP_SH_APPLY_SIXTY_VAL,
    STRING_SIMPLE_OP_SH_APPLY_LUMPSUM_VAL,
    STRING_SIMPLE_OP_SESSION_KEY,
    STRING_SIMPLE_OP_APPLICATION,
    STRING_LUMPSUMPAYMENT,
    STRING_MONTHLYPAYMENT,
    STRING_ANNUALPAYMENT,
    STRING_SIXTY_INSTALLPAYMENT,
} from "../../utils/constantVariables";
import { query as querySimpleOpApplicationImportantMatters } from "../../assets/graphql/SimpleOpApplicationImportantMatters";
import { query as querySimpleOpApplicationCustomerInfo } from "../../assets/graphql/SimpleOpApplicationCustomerInfo";
import { query as querySimpleApplicationDropDown } from "../../assets/graphql/SimpleApplicationDropDown";
import { apiBaseURL, siteKey } from "../../envSettings";
import { useGetDataViaGraphQL as UseGetDataViaGraphQL } from "../../hooks/useGetData";
import { useCustomQuery } from "../../hooks/useGetData";
// TODO: Recapcha Issueにより一時削除
// import ReCAPTCHA from "react-google-recaptcha";

const addDataRowList = (key, value, quantity, style) => {
    return {
        fields: {
            Key: {
                value: key,
            },
            Value: {
                value: value,
            },
            Quantity: {
                value: quantity,
            },
            Class: {
                value: style
            },
        },
    };
};

const calculateTaxAndProdSum = (products) => {
    let totalTaxes = 0;
    let productTotal = 0;

    if (products.length > 0) {
        products.forEach((product) => {
            const price = parseInt(
                !product?.isSelectedAlternativePayment ?
                    product?.price : product?.alternativePrice
            );
            const quantity = parseInt(
                product.quantity ? parseInt(product.quantity) : 1
            );
            const priceWithoutTax = !product?.isSelectedAlternativePayment ?
                product?.priceWithoutTax : product?.alternativePriceWithoutTax;

            productTotal += price * quantity;
            totalTaxes += (price - priceWithoutTax) * quantity;
        });
    }

    return {
        totalTaxes,
        productTotal: productTotal,
    };
};

const createProductList = (componentList, prodList, component, flexiblePriceLabel) => {
    for (let i = 0; i < prodList.length; i++) {
        let prodDetail = deepcopy(component);
        let monthlyPlanDetail = prodDetail?.fields?.dataRowList;
        prodDetail.uid = generateGUID();
        if (prodDetail && prodDetail.fields) {
            prodDetail.fields.dataRowList.splice(0);
            prodDetail.fields.titleText = prodList[i].title;
            prodDetail.fields.productHeaderName = { value: STRING_SIMPLE_OP_CONTENT_LABEL };
            prodDetail.fields.quantityHeaderName = { value: STRING_SIMPLE_OP_QUANTITY_LABEL };
            prodDetail.fields.priceHeaderName = { value: STRING_SIMPLE_OP_PRICE_LABEL };
            prodDetail.fields.showQuantityHeader = true;
            prodDetail.fields.additionalClass = { value: "price-breakdown mb-20 open" };
            const { totalTaxes, productTotal } = calculateTaxAndProdSum(
                prodList[i].product
            );
            for (let j = 0; prodList[i].product.length > j; j++) {
                const product = prodList[i].product[j];
                monthlyPlanDetail[monthlyPlanDetail.length] = addDataRowList(
                    product?.title,
                    calculatePrice(
                        !product?.isSelectedAlternativePayment ?
                            product?.priceWithoutTax : product?.alternativePriceWithoutTax,
                        product.quantity,
                        flexiblePriceLabel),
                    product?.quantity || "1",
                    product?.titleClass,
                );
            }
            if (totalTaxes) {
                monthlyPlanDetail[monthlyPlanDetail.length] = addDataRowList(
                    STRING_SIMPLE_OP_CONSUMPTION_TAX_LABEL,
                    formatCurrency(totalTaxes).toString().concat(STRING_CURRENCY_CHAR),
                    ""
                );
            }

            monthlyPlanDetail[monthlyPlanDetail.length] = addDataRowList(
                STRING_SIMPLE_OP_SUMMARY_LABEL,
                isNaN(productTotal) ?
                    flexiblePriceLabel :
                    formatCurrency(productTotal).toString().concat(STRING_CURRENCY_CHAR),
                ""
            );
            componentList[componentList.length] = prodDetail;
        }
    }
};

const calculatePrice = (inputPrice, inputQuantity, flexiblePriceLabel) => {
    const price = parseInt(inputPrice);
    const quantity = parseInt(inputQuantity ? inputQuantity : 1);
    const formatPrice = formatCurrency(price * quantity);
    if (formatPrice === "-") {
        return flexiblePriceLabel;
    }
    return formatCurrency(price * quantity)
        .toString()
        .concat(STRING_CURRENCY_CHAR);
};

const createProductAttrList = (componentList, prodList, component, dropdownMaster) => {
    for (let i = 0; i < prodList.length; i++) {
        let prodDetail = deepcopy(component);
        let monthlyPlanDetail = prodDetail?.fields?.dataRowList;
        if (prodDetail && prodDetail.fields) {
            prodDetail.uid = generateGUID();
            prodDetail.fields.dataRowList.splice(0);
            prodDetail.fields.titleText = { value: prodList[i].title };
            for (let j = 0; prodList[i].attributes.length > j; j++) {
                const description = dropdownMaster.find(item =>
                    item.Value === prodList[i].attributes[j].value
                )?.Description;
                monthlyPlanDetail[monthlyPlanDetail.length] = addDataRowList(
                    prodList[i].attributes[j].title,
                    description ? description : prodList[i].attributes[j].value
                );
            }
            componentList[componentList.length] = prodDetail;
        }
    }
};

const sortProducts = (productData) => {
    return productData.map(item => {
        item.product.sort((a, b) => a.sortOrder - b.sortOrder);
        return item;
    });
};

const formatData = (data, replaceText) => {
    const formattedData = {};
    if (data?.children) {
        data.children[0].fields.forEach((field) => {
            formattedData[field.name?.replace(`${replaceText}-`, "")] = field.value;
        });

        if (data.children[0]?.children) {
            formattedData.fields = data.children[0].children.map((child) => {
                const childFields = {};
                child.fields.forEach((field) => {
                    if (field?.targetItem) {
                        childFields[
                            field.name?.replace(
                                `SimpleOpApplicationImportantMattersChild-`,
                                ""
                            )
                        ] = field?.targetItem?.field.value;
                    } else {
                        childFields[
                            field.name?.replace(
                                `SimpleOpApplicationImportantMattersChild-`,
                                ""
                            )
                        ] = field.value;
                    }
                });

                return childFields;
            });
        }

        return formattedData;
    }

    return formattedData;
};

const transformedData = (data, stringReplace) =>
    data?.reduce((acc, curr) => {
        if (curr?.targetItem) {
            acc[curr.name?.replace(`${stringReplace}-`, "")] =
                curr.targetItem?.Value?.value;
            acc[`${curr.name?.replace(`${stringReplace}-`, "")}Label`] =
                curr.targetItem?.Description?.value;
        } else if (curr.name === `${stringReplace}-Image` || curr.name === `${stringReplace}-ImageForSp`) {
            const temp = document.createElement('div');
            temp.innerHTML = curr.rendered;
            var imgSrc = temp.firstChild?.getAttribute("src");
            acc[curr.name?.replace(`${stringReplace}-`, "")] = imgSrc;
        } else {
            acc[curr.name?.replace(`${stringReplace}-`, "")] = curr.value;
        }
        return acc;
    }, {});

const updateAdditionalProdAttr = (selectedProducts) => {
    const { SimpleOpApplication } = selectedProducts;
    const { monthlyPayment, annualPayment, lumpSumPayment, sixtyInstallmentPayment, productAttributes } = SimpleOpApplication;

    const summarizedSelected = [
        ...monthlyPayment,
        ...annualPayment,
        ...lumpSumPayment,
        ...sixtyInstallmentPayment,
    ];

    const findItemWithCommonKey = (key) => summarizedSelected.find(item =>
        item?.product.some(product => product?.key.includes(key))
    );

    const registerAdditionalAttribute = (attrItem, key, value) => {
        if (attrItem) {
            let prodAttribute = productAttributes.find(attr => attr?.title === attrItem?.title);
            if (!prodAttribute) {
                prodAttribute = { title: attrItem.title, attributes: [] };
                productAttributes.push(prodAttribute);
            }
            prodAttribute.attributes.push({ name: key, title: "", value: value });
        }
    }

    const unextItem = findItemWithCommonKey(STRING_SIMPLE_OP_UNEXT_COMMON_KEY);
    registerAdditionalAttribute(
        unextItem,
        STRING_SIMPLE_OP_UNEXT_APPLY_ATTR,
        STRING_SIMPLE_OP_APPLICATION
    );

    const kurashiItem = findItemWithCommonKey(STRING_SIMPLE_OP_KURASHI_COMMON_KEY);
    registerAdditionalAttribute(
        kurashiItem,
        STRING_SIMPLE_OP_KURASHI_APPLY_ATTR,
        STRING_SIMPLE_OP_APPLICATION
    );


    const daznMap = {};
    daznMap[STRING_MONTHLYPAYMENT] = STRING_SIMPLE_OP_DAZN_APPLY_MONTHLY_VAL;
    daznMap[STRING_ANNUALPAYMENT] = STRING_SIMPLE_OP_DAZN_APPLY_ANNUAL_VAL;

    const daznItem = findItemWithCommonKey(STRING_SIMPLE_OP_DAZN_COMMON_KEY);
    if (daznItem?.product?.length) {
        const daznAttrKey = !daznItem?.product[0]?.isSelectedAlternativePayment
            ? daznItem?.product[0]?.paymentType : daznItem?.product[0]?.alternativePaymentType;
        const daznAttrValue = daznMap[daznAttrKey] ?? "";
        registerAdditionalAttribute(daznItem, STRING_SIMPLE_OP_DAZN_APPLY_ATTR, daznAttrValue);
    }

    const smartHomeMap = {};
    smartHomeMap[STRING_SIXTY_INSTALLPAYMENT] = STRING_SIMPLE_OP_SH_APPLY_SIXTY_VAL;
    smartHomeMap[STRING_LUMPSUMPAYMENT] = STRING_SIMPLE_OP_SH_APPLY_LUMPSUM_VAL;

    const smartHomeItem = summarizedSelected.find(item =>
        item?.product.some(product => product?.key === STRING_SIMPLE_OP_SH_DEVICE_KEY)
    );
    const smartHomeAttrKey = !smartHomeItem?.product[0]?.isSelectedAlternativePayment
        ? smartHomeItem?.product[0]?.paymentType : smartHomeItem?.product[0]?.alternativePaymentType;
    const smartHomeAttrValue = smartHomeMap[smartHomeAttrKey] ?? "";
    registerAdditionalAttribute(smartHomeItem, STRING_SIMPLE_OP_SH_PAYMENT_ATTR, smartHomeAttrValue);
};

const formatMaster = (fields) => {
    const result = {};
    fields.forEach(field => {
        result[field.name] = field.value;
    });
    return result;
};

const formatDropdownField = (children) => {
    let allItems = [];
    children.forEach(child => {
        if (child.fields) {
            allItems.push(formatMaster(child.fields));
        }
        if (child.children) {
            allItems = allItems.concat(formatDropdownField(child.children));
        }
    });
    return allItems;
};

const formatDropdownList = (data) => {
    if (data && data.children) {
        return formatDropdownField(data.children);
    }
    return [];
};

const SimpleOpApplicationConfirmationLayout = (props) => {
    const sitecoreContext = props?.sitecoreContext;
    di_register("thisPageSitecoreContext", sitecoreContext);
    const pageFields = sitecoreContext?.route?.fields;
    const [selectedItemIndex, setSelectedItemIndex] = useState();
    const [isAgreedToImportantMatters, setIsAgreedToImportantMatters] = useState(
        false
    );
    // TODO: Recapcha Issueにより一時削除
    // const [recaptcha, setRecaptcha] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [dropdownMaster, setDropdownMaster] = useState(null);
    const [isRegistrationCalled, setIsRegistrationCalled] = useState(false);
    const [isInitalLoadFinished, setIsInitalLoadFinished] = useState(false);

    const importantMattersQuery = UseGetDataViaGraphQL(
        querySimpleOpApplicationImportantMatters,
        null,
        "SimpleOpApplicationImportantMatters"
    );
    const SimpleOpApplicationSessionData = getSessionStorageItem(
        sessionKeys.SSN_APPLICATION_SIMPLEOP_ORDERINFO,
        true
    );

    const SimpleOpApplicationCustomerInfoQuery = UseGetDataViaGraphQL(
        querySimpleOpApplicationCustomerInfo,
        null,
        "SimpleOpApplicationCustomerInfo"
    );

    const SimpleApplicationDropDownItemSearchQuery = UseGetDataViaGraphQL(
        querySimpleApplicationDropDown,
        null,
        "SimpleApplicationDropdownItems"
    );

    const [simpleOpApplicationOrderInfo, setSimpleOpApplicationOrderInfo] = useState();
    const simpleOpApplicationRequestURI = `${apiBaseURL}simpleOp/RegisterApplication`;
    const {
        data: simpleOpApplicationData,
        isLoading: simpleOpApplicationIsLoading,
        error: simpleOpApplicationError,
        refetch: simpleOpApplicationRefresh,
        remove: simpleOpApplicationRemove,
    } = useCustomQuery(
        simpleOpApplicationRequestURI,
        false,
        STRING_POST,
        simpleOpApplicationOrderInfo
    );

    // Retrieve login state
    const retrieveLoginStateURI = `${apiBaseURL}common/RetrieveLoginStatus`;
    const {
        data: retrieveLoginStatusData,
        isLoading: retrieveLoginStatusLoading,
    } = useCustomQuery(retrieveLoginStateURI, true, STRING_GET);

    const registerSimpleOpSessionURI = `${apiBaseURL}simpleop/RegisterSimpleOpSession`;
    const {
        data: registerSimpleOpSessionData,
        isLoading: registerSimpleOpSessionIsLoading,
        error: registerSimpleOpSessionError,
        refetch: registerSimpleOpSessionRefresh,
        remove: registerSimpleOpSessionRemove,
    } = useCustomQuery(
        registerSimpleOpSessionURI,
        false,
        STRING_POST,
        { "key": STRING_SIMPLE_OP_SESSION_KEY, "value": "true" },
    );

    const retrieveServerSessionInfoURI = `${apiBaseURL}common/RetrieveServerSessionInfo`;
    const {
        data: serverSessionInfoData,
        isLoading: serverSessionInfoIsLoading,
        error: serverSessionInfoError,
        refetch: serverSessionInfoRefresh,
        remove: serverSessionInfoRemove,
    } = useCustomQuery(
        retrieveServerSessionInfoURI,
        false,
        STRING_GET
    );

    const isLoading =
        importantMattersQuery === STRING_LOADING ||
        SimpleOpApplicationCustomerInfoQuery === STRING_LOADING ||
        SimpleApplicationDropDownItemSearchQuery === STRING_LOADING ||
        retrieveLoginStatusLoading === STRING_LOADING ||
        simpleOpApplicationIsLoading === STRING_LOADING ||
        registerSimpleOpSessionIsLoading === STRING_LOADING ||
        serverSessionInfoIsLoading === STRING_LOADING;

    props.setIsLoading(isLoading);
    const precautionText = pageFields["ApplicationConfirmationSection-Precaution"]?.value;
    let monthlyComponentList = [];
    let AnnualComponentList = [];
    let LumpSumComponentList = [];
    let SixtyInstallmentComponentList = [];
    let ProductAttrComponentList = [];
    let monthlyProdList = sortProducts(
        SimpleOpApplicationSessionData?.SimpleOpApplication?.monthlyPayment || []
    );
    let annualProdList = sortProducts(
        SimpleOpApplicationSessionData?.SimpleOpApplication?.annualPayment || []
    );
    let lumpSumProdList = sortProducts(
        SimpleOpApplicationSessionData?.SimpleOpApplication?.lumpSumPayment || []
    );
    let sixtyInstallmentProdList = sortProducts(
        SimpleOpApplicationSessionData?.SimpleOpApplication?.sixtyInstallmentPayment || []
    );
    let prodAttributeList =
        SimpleOpApplicationSessionData?.SimpleOpApplication?.productAttributes ||
        [];
    let monthlySection = getScDataFromPlaceholder("form-accordion-1", props);
    let annualSection = getScDataFromPlaceholder("form-accordion-2", props);
    let lumpSumSection = getScDataFromPlaceholder("form-accordion-3", props);
    let sixtyInstallmentSection = getScDataFromPlaceholder("form-accordion-4", props);
    let l02List = getScDataFromComponent("L-02", monthlySection);
    let l02List01 = getScDataFromComponent("L-02", annualSection);
    let l02List02 = getScDataFromComponent("L-02", lumpSumSection);
    let l02List03 = getScDataFromComponent("L-02", sixtyInstallmentSection);
    let productAttrSection = getScDataFromPlaceholder(
        "product-attributes-section",
        props
    );

    let l01List = getScDataFromComponent("L-01", productAttrSection);
    let customeInfoSection = getScDataFromPlaceholder(
        "customer-info-section",
        props
    );
    const [l01List02] = getScDataFromComponent("L-01", customeInfoSection);

    if (typeof window !== "undefined" && dropdownMaster && !isInitalLoadFinished) {
        //Monthly
        createProductList(
            monthlyComponentList,
            monthlyProdList,
            l02List[0],
            pageFields["ApplicationConfirmationSection-FlexiblePriceLabel"].value
        );
        monthlySection.splice(1);
        for (let i = 0; i < monthlyComponentList.length; i++) {
            monthlySection[monthlySection.length] = monthlyComponentList[i];
        }
        if (monthlyProdList.length > 0) {
            setIsInitalLoadFinished(true);
        }

        //Annual
        createProductList(
            AnnualComponentList,
            annualProdList,
            l02List01[0],
            pageFields["ApplicationConfirmationSection-FlexiblePriceLabel"].value
        );
        annualSection.splice(1);
        for (let i = 0; i < AnnualComponentList.length; i++) {
            annualSection[annualSection.length] = AnnualComponentList[i];
        }
        if (annualProdList.length > 0) {
            setIsInitalLoadFinished(true);
        }

        //LumpSum
        createProductList(
            LumpSumComponentList,
            lumpSumProdList,
            l02List02[0],
            pageFields["ApplicationConfirmationSection-FlexiblePriceLabel"].value
        );
        lumpSumSection.splice(1);
        for (let i = 0; i < LumpSumComponentList.length; i++) {
            lumpSumSection[lumpSumSection.length] = LumpSumComponentList[i];
        }
        if (lumpSumProdList.length > 0) {
            setIsInitalLoadFinished(true);
        }

        // 60 Installment
        createProductList(
            SixtyInstallmentComponentList,
            sixtyInstallmentProdList,
            l02List03[0],
            pageFields["ApplicationConfirmationSection-FlexiblePriceLabel"].value
        );
        sixtyInstallmentSection.splice(1);
        for (let i = 0; i < SixtyInstallmentComponentList.length; i++) {
            sixtyInstallmentSection[sixtyInstallmentSection.length] = SixtyInstallmentComponentList[i];
        }
        if (sixtyInstallmentProdList.length > 0) {
            setIsInitalLoadFinished(true);
        }

        //Product Attr
        createProductAttrList(
            ProductAttrComponentList,
            prodAttributeList,
            l01List[0],
            dropdownMaster,
        );
        productAttrSection.splice(1);
        for (let i = 0; i < ProductAttrComponentList.length; i++) {
            productAttrSection[productAttrSection.length] =
                ProductAttrComponentList[i];
        }
    }

    const CustomerInfoData = SimpleOpApplicationCustomerInfoQuery?.SimpleOpApplicationCustomerInfo?.children?.map(({ fields, sortOrder }) => {
        return {
            ...transformedData(fields, "SimpleOpApplicationCustomerInfo"),
            sortOrder: sortOrder?.value,
        };
    });

    //Customer Info
    const customerInfoSessionData =
        SimpleOpApplicationSessionData?.SimpleOpApplication?.customerInfo;

    let customerInfoList = [];
    if (CustomerInfoData !== undefined) {
        CustomerInfoData.forEach(customerInfo => {

            if (
                isInvalidLoginStatus(
                    customerInfo?.IsLoggedIn,
                    customerInfo?.IsNotLoggedIn,
                    isLogged
                )
            ) {
                return;
            }

            customerInfoList.push({
                fields: {
                    Key: customerInfo?.Title,
                    Value: customerInfoSessionData && customerInfoSessionData[customerInfo?.Name]
                }
            });
        });
    }

    // receive checkOrderRequest
    useEffect(() => {
        if (simpleOpApplicationData?.StatusCode === 200) {
            registerSimpleOpSessionRemove();
            registerSimpleOpSessionRefresh();
        }
        else if (simpleOpApplicationData?.StatusCode === 403) {
            window.location.href = STRING_ERROR_403_URI;
        }
        else if (simpleOpApplicationData?.StatusCode) {
            window.location.href = STRING_ERROR_500_URI;
        }
    }, [simpleOpApplicationData]);

    useEffect(() => {
        setIsLogged(retrieveLoginStatusData?.Result ?? false);
        if (retrieveLoginStatusData?.Result) {
            serverSessionInfoRemove();
            serverSessionInfoRefresh();
        }
    }, [retrieveLoginStatusData]);

    useEffect(() => {
        if (registerSimpleOpSessionData?.StatusCode) {
            switch (registerSimpleOpSessionData?.StatusCode) {
                case 200:
                    const nextPageUrl = isLogged ?
                        pageFields["CustomerInfoSection-NextButtonLinkForMember"] :
                        pageFields["CustomerInfoSection-NextButtonLinkForNonMember"];

                    removeSessionStorageItem(sessionKeys.SSN_APPLICATION_SIMPLEOP_ORDERINFO);
                    redirectToLinkUrl(nextPageUrl);
                    break;
                case 500:
                    window.location.href = STRING_ERROR_500_URI;
                    break;
            }
        }
    }, [registerSimpleOpSessionData]);

    useEffect(() => {
        if (simpleOpApplicationOrderInfo && !isRegistrationCalled) {
            setIsRegistrationCalled(true);
            simpleOpApplicationRemove();
            simpleOpApplicationRefresh();
        }
    }, [simpleOpApplicationOrderInfo]);

    useEffect(() => {
        if (serverSessionInfoData) {
            setSessionStorageItem(
                sessionKeys.SSN_COMMON_COMMON_PERSONALIZEINFO,
                serverSessionInfoData,
                true
            );
        }
    }, [serverSessionInfoData]);

    useEffect(() => {
        const dropDownResult = SimpleApplicationDropDownItemSearchQuery?.SimpleApplicationDropdownItems;
        if (dropDownResult) {
            const dropdown = formatDropdownList(dropDownResult);
            setDropdownMaster(dropdown);
        }
    }, [SimpleApplicationDropDownItemSearchQuery]);

    l01List02.fields.dataRowList = customerInfoList;
    l01List02.fields.titleText = { value: "お客さま情報" };

    //button section
    const buttonSection = getScDataFromPlaceholder("button-section", props);
    const [backButton] = getScDataFromComponent("B-02", buttonSection);
    const [nextButton] = getScDataFromComponent("B-01", buttonSection);
    const redirectToPreviousPage = useRedirectToPreviousPage();
    backButton.fields.onClickEvent = () => {
        redirectToPreviousPage();
    };
    nextButton.fields.additionalId = { value: "confirm" };
    nextButton.fields.isDisabled = {
        // TODO: Recapcha Issueにより一時削除
        // value: isAgreedToImportantMatters && recaptcha ? false : true,
        value: isAgreedToImportantMatters ? false : true,
    };
    nextButton.fields.onClickEvent = () => {
        updateAdditionalProdAttr(SimpleOpApplicationSessionData);
        setSimpleOpApplicationOrderInfo(SimpleOpApplicationSessionData);
    };


    //Important Matters
    const ImportantMattersData = formatData(
        importantMattersQuery?.SimpleOpApplicationImportantMatters,
        "SimpleOpApplicationImportantMatters"
    );
    const importantInformationSection = getScDataFromPlaceholder(
        "important-matters-section",
        props
    );
    const [c03Data] = getScDataFromComponent("C-03", importantInformationSection);
    const [m01Data] = getScDataFromComponent(
        "M0-01",
        importantInformationSection
    );
    const buttonSectionP04 = getScDataFromPlaceholder(
        "button-section-p04",
        c03Data
    );
    const [b04Data] = getScDataFromComponent("B-04", buttonSectionP04);
    b04Data.fields.requiredMessageText = {
        value: ImportantMattersData?.RequiredLabel || "",
    };
    b04Data.fields.consentText = {
        value: ImportantMattersData?.AgreeButtonText || "",
    };
    b04Data.fields.onChangeEvent = () => {
        setIsAgreedToImportantMatters(!isAgreedToImportantMatters);
    };
    c03Data.fields.titleText = {
        value: ImportantMattersData?.Title || "",
    };
    c03Data.fields.explanationText = {
        value: ImportantMattersData?.Description || "",
    };
    c03Data.fields.onItemClick = (index) => {
        setSelectedItemIndex(index);
    };
    const allProducts = [
        ...monthlyProdList,
        ...annualProdList,
        ...lumpSumProdList,
        ...sixtyInstallmentProdList,
    ]?.map((item) => item.title);
    if (ImportantMattersData?.fields && allProducts?.length > 0) {
        const matchedProducts = ImportantMattersData?.fields?.filter((fields) =>
            allProducts.includes(fields.Parent)
        );
        c03Data.fields.buttonList = matchedProducts?.map((fields) => ({
            Name: fields.Title,
            Id: fields.Parent,
        }));
        m01Data.fields.richTextContent = {
            value: selectedItemIndex?.toString()
                ? matchedProducts[selectedItemIndex].Content
                : "",
        };
        m01Data.fields.infoModalText = {
            value: selectedItemIndex?.toString()
                ? matchedProducts[selectedItemIndex].ContentTitle
                : "",
        };
    }
    return (
        <>
            <main>
                <div className="form_container">
                    <div className="form_detail full-width">
                        <div className="form_wrapper mt-40">
                            <Placeholder
                                name="product-list-section"
                                rendering={props.rendering}
                            />

                            {precautionText && precautionText.length > 0 && (
                                <div className="precaution-box confirmation">
                                    {parse(precautionText)}
                                </div>
                            )}

                            {monthlyProdList && monthlyProdList?.length > 0 && (
                                <Placeholder
                                    name="form-accordion-1"
                                    rendering={props.rendering}
                                />
                            )}

                            {annualProdList && annualProdList?.length > 0 && (
                                <Placeholder
                                    name="form-accordion-2"
                                    rendering={props.rendering}
                                />
                            )}

                            {lumpSumProdList && lumpSumProdList?.length > 0 && (
                                <Placeholder
                                    name="form-accordion-3"
                                    rendering={props.rendering}
                                />
                            )}

                            {sixtyInstallmentProdList && sixtyInstallmentProdList?.length > 0 && (
                                <Placeholder
                                    name="form-accordion-4"
                                    rendering={props.rendering}
                                />
                            )}

                            {prodAttributeList && prodAttributeList?.length > 0 && (
                                <Placeholder
                                    name="product-attributes-section"
                                    rendering={props.rendering}
                                />
                            )}

                            {customerInfoList && customerInfoList?.length > 0 && (
                                <Placeholder
                                    name="customer-info-section"
                                    rendering={props.rendering}
                                />
                            )}
                            <Placeholder
                                name="important-matters-section"
                                rendering={props.rendering}
                            />
                            {/* 
                            // TODO: Recapcha Issueにより一時削除
                            <ReCAPTCHA
                                className="recaptcha"
                                sitekey={siteKey}
                                onChange={(val) => setRecaptcha(val)}
                            /> 
                            */}
                            <div className="btn_container mb-32">
                                <Placeholder
                                    name="button-section"
                                    rendering={props.rendering}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default withSitecoreContext()(SimpleOpApplicationConfirmationLayout);
