import React, { useState, useRef, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import { getURLType, redirectToLinkUrl } from "../../utils/targetUrlType";

const IC01 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    newSettings,
    carouselImages,
    prevNextIcon,
  } = properData;

  return (
    <React.Fragment>
      <Slider {...newSettings}>
        {carouselImages?.map((item, index) => {
          return (
            <div key={"carouselImages_" + index}>
              <a
                onClick={() =>
                  redirectToLinkUrl(
                    item.linkUrl?.value,
                    getURLType(item.urlType)
                  )
                }
              >
                <img src={fillBlank(item.mainImage.value?.src)?.value} />
              </a>
            </div>
          );
        })}
      </Slider>
    </React.Fragment>
  );
};

export default IC01;
