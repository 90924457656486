import React, { useEffect, useContext, useState } from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { di_register } from "../../utils/di";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/useSessionStorage";
import {
  getScDataFromComponent,
  getScDataFromPlaceholder,
  getProperData,
  redirectToLinkUrl,
} from "../../utils/commonUtils";
import { apiBaseURL } from "../../envSettings";
import { useCustomQuery } from "../../hooks/useGetData";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import ModalContext from "../../assets/context/ModalContext";
import { apiResultCode } from "../../assets/consts";
import { STRING_POST, STRING_GET } from "../../utils/constantVariables";
import { useRedirectToPreviousPage } from "../../hooks/useRedirectToPreviousPage";

const ApplicationCancelConfirmation = (props) => {
  return (
    <>
      <div className="heading_wrapper">
        <Placeholder
          name="heading-wrapper-section"
          rendering={props.rendering}
        />
      </div>
      <div className="form_wrapper-input">
        <Placeholder
          name="application-content-section"
          rendering={props.rendering}
        />
      </div>
      <div className="btn_container">
        <Placeholder name="button-section" rendering={props.rendering} />
      </div>
    </>
  );
};

const mapApplicationDetails = (props, orderRelatedInfo) => {
  const pageContext = props?.sitecoreContext?.route?.fields;
  const selectContent = getScDataFromPlaceholder(
    "application-content-section",
    props
  );
  const [l01Data] = getScDataFromComponent("L-01", selectContent);
  const [c05Data] = getScDataFromComponent("C-05", selectContent);
  const multilistItems = c05Data?.fields?.MultilistItems;

  // get ss
  const reportApplicationListAddress = getSessionStorageItem(
    sessionKeys.SSN_REPORT_APPLICATIONLIST_ADDRESS,
    true
  );
  const reportApplicationListEffectiveDate = getSessionStorageItem(
    sessionKeys.SSN_REPORT_APPLICATIONLIST_EFFECTIVEDATE,
    true
  );
  const reportApplicationListOrderStatus = getSessionStorageItem(
    sessionKeys.SSN_REPORT_APPLICATIONLIST_ORDERSTATUS,
    true
  );
  const reportApplicationListPlanName = getSessionStorageItem(
    sessionKeys.SSN_REPORT_APPLICATIONLIST_PLANNAME,
    true
  );
  const reportApplicationListOrderNumber = getSessionStorageItem(
    sessionKeys.SSN_REPORT_APPLICATIONLIST_ORDERRECEIPTNUMBER,
    true
  );
  const reportApplicationCancelReasonLabel = getSessionStorageItem(
    sessionKeys.SSN_REPORT_APPLICATIONCANCEL_REASONOFCANCELLATIONLABEL,
    true
  );

  const arrayData = [
    {
      fields: {
        Key:
          pageContext["ApplicationCancelConfirmation-ReasonOfCancellation"]
            ?.value,
        Value: reportApplicationCancelReasonLabel,
      },
    },
    {
      fields: {
        Key:
          pageContext["ApplicationCancelConfirmation-OtherCancellation"]?.value,
        Value:
          orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
            ?.CMAP_CancelReasonOther__c,
      },
    },
  ];
  l01Data.fields["dataRowList"] = arrayData;
  c05Data.fields["titleNumber"] = reportApplicationListOrderNumber;
  // 申込日
  let applicationDate = multilistItems[0].fields;
  applicationDate["explanationText"] = moment(
    reportApplicationListEffectiveDate,
    "YYYY-MM-DD"
  ).format("YYYY年M月D日");

  // 申込状況
  let applicationStatus = multilistItems[1].fields;
  applicationStatus["explanationText"] = reportApplicationListOrderStatus;

  // ご利用住所ラベル
  let address = multilistItems[2].fields;
  address["explanationText"] = reportApplicationListAddress;

  // プランラベル
  let planName = multilistItems[3].fields;
  planName["explanationText"] = reportApplicationListPlanName;
};

const ApplicationCancelConfirmationLayout = (props) => {
  const sitecoreContext = props?.sitecoreContext;
  const pageContext = sitecoreContext?.route?.fields;
  di_register("thisPageSitecoreContext", sitecoreContext);

  const { dispatch } = useContext(ModalContext);
  const { setError, clearErrors } = useFormContext();
  const [isDisableNextButton, setIsDisableNextButton] = useState(false);

  const [checkOrderRequest, setCheckOrderRequest] = useState(null);
  const [registerOrderRequest, setRegisterOrderRequest] = useState(null);

  // get the data
  const orderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );
  mapApplicationDetails(props, orderRelatedInfo);

  // hooks
  const redirectToPreviousPage = useRedirectToPreviousPage();

  // api urls
  const checkOrderURI = `${apiBaseURL}order/CheckOrder`;
  const registerOrderURI = `${apiBaseURL}order/RegisterOrder`;
  const systemDateTimeURI = `${apiBaseURL}common/RetrieveSystemDateTime`;

  // payload
  const createCheckRegisterOrderApiRequest = (orderRelatedInfo) => {
    const accountInfoAccount =
      orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account;
    const accountInfoAccountAddition =
      orderRelatedInfo?.orderRelatedInfo?.accountInfo?.accountAddition;
    const order = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order;
    const orderAddition =
      orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition;
    const orderImportantMatterDescriptions =
      orderRelatedInfo?.orderRelatedInfo?.orderInfo
        ?.orderImportantMatterDescriptions;
    const orderItemInfos =
      orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos;
    const billing = orderRelatedInfo?.orderRelatedInfo?.billingTo;

    // Deserialize Common.Common.OrderRelatedInfo obtained from sessionStorage
    const deserializedOrderRelatedInfo = {
      orderRelatedInfo: {
        accountInfo: {
          account: {
            Id: accountInfoAccount?.Id,
            CMAP_ContractName__c: accountInfoAccount?.CMAP_ContractName__c,
            LastName: accountInfoAccount?.LastName,
            FirstName: accountInfoAccount?.FirstName,
            LastNameKana__pc: accountInfoAccount?.LastNameKana__pc,
            FirstNameKana__pc: accountInfoAccount?.FirstNameKana__pc,
            CMAP_Gender__c: accountInfoAccount?.CMAP_Gender__c,
            PersonBirthdate: accountInfoAccount?.PersonBirthdate,
            CMAP_CorporateGroupName__c:
              accountInfoAccount?.CMAP_CorporateGroupName__c,
            CMAP_DepartmentName__c: accountInfoAccount?.CMAP_DepartmentName__c,
            CMAP_ContactPersonFamilyName__c:
              accountInfoAccount?.CMAP_ContactPersonFamilyName__c,
            CMAP_ContactPersonName__c:
              accountInfoAccount?.CMAP_ContactPersonName__c,
            CMAP_ContactPersonLastNameKana__pc:
              accountInfoAccount?.CMAP_ContactPersonLastNameKana__pc,
            CMAP_ContactPersonFirstNameKana__pc:
              accountInfoAccount?.CMAP_ContactPersonFirstNameKana__pc,
            CMAP_ContactPostalCode__c:
              accountInfoAccount?.CMAP_ContactPostalCode__c,
            CMAP_ContactPostalCodeLowerFour__c:
              accountInfoAccount?.CMAP_ContactPostalCodeLowerFour__c,
            CMAP_ContactState__c: accountInfoAccount?.CMAP_ContactState__c,
            CMAP_ContactCity__c: accountInfoAccount?.CMAP_ContactCity__c,
            CMAP_ContactHouseNumber__c:
              accountInfoAccount?.CMAP_ContactHouseNumber__c,
            CMAP_MansionName__c: accountInfoAccount?.CMAP_MansionName__c,
            CMAP_RoomNo__c: accountInfoAccount?.CMAP_RoomNo__c,
            CMAP_AreaCode__c: accountInfoAccount?.CMAP_AreaCode__c,
            CMAP_LocalTelephoneNumber__c:
              accountInfoAccount?.CMAP_LocalTelephoneNumber__c,
            CMAP_SubscriberNumber__c:
              accountInfoAccount?.CMAP_SubscriberNumber__c,
            CMAP_MobileNumber1__c: accountInfoAccount?.CMAP_MobileNumber1__c,
            CMAP_MobileNumber2__c: accountInfoAccount?.CMAP_MobileNumber2__c,
            CMAP_MobileNumber3__c: accountInfoAccount?.CMAP_MobileNumber3__c,
            CMAP_MyCommufaID__c: accountInfoAccount?.CMAP_MyCommufaID__c,
            CMAP_MultilingualReexplainCenter__c:
              accountInfoAccount?.CMAP_MultilingualReexplainCenter__c,
          },
          accountAddition: {
            isNewAccount: accountInfoAccountAddition?.isNewAccount,
            leadId: accountInfoAccountAddition?.leadId,
          },
        },
        orderInfo: {
          order: {
            CMAP_OrderCategory__c: order?.CMAP_OrderCategory__c,
            CMAP_OrderBeforeChange__c: order?.CMAP_OrderBeforeChange__c,
            EffectiveDate: order?.EffectiveDate,
            CMAP_OrderChannelDetail__c: order?.CMAP_OrderChannelDetail__c,
            AccountId: order?.AccountId,
            CMAP_ResidenceType__c: order?.CMAP_ResidenceType__c,
            CMAP_BuildingCategory__c: order?.CMAP_BuildingCategory__c,
            CMAP_OfferPlan__c: order?.CMAP_OfferPlan__c,
            CMAP_OfferPlanName__c: order?.CMAP_OfferPlanName__c,
            CMAP_OfferPackageId__c: order?.CMAP_OfferPackageId__c,
            CMAP_IsTypeCircuit__c: order?.CMAP_IsTypeCircuit__c,
            CMAP_IsTypeCircuitWireless__c: order?.CMAP_IsTypeCircuitWireless__c,
            CMAP_IsTypeCircuitTypeJ__c: order?.CMAP_IsTypeCircuitTypeJ__c,
            CMAP_IsTypeNotCircuit__c: order?.CMAP_IsTypeNotCircuit__c,
            CMAP_IsTypeGoodsServiceSales__c:
              order?.CMAP_IsTypeGoodsServiceSales__c,
            CMAP_InstallationLocationOfferArea__c:
              order?.CMAP_InstallationLocationOfferArea__c,
            CMAP_InstallLocLayerDeliveryDuration__c:
              order?.CMAP_InstallLocLayerDeliveryDuration__c,
            CMAP_StandardOpeningDays__c: order?.CMAP_StandardOpeningDays__c,
            CMAP_StandardOpeningMonths__c: order?.CMAP_StandardOpeningMonths__c,
            CMAP_ApartmentPropertyName__c: order?.CMAP_ApartmentPropertyName__c,
            CMAP_PropertyAddress__c: order?.CMAP_PropertyAddress__c,
            CMAP_PropertyAddressCode11__c: order?.CMAP_PropertyAddressCode11__c,
            CMAP_PropertyCity__c: order?.CMAP_PropertyCity__c,
            CMAP_PropertyOfferMethod__c: order?.CMAP_PropertyOfferMethod__c,
            CMAP_Case__c: order?.CMAP_Case__c,
            CMAP_Billing__c: order?.CMAP_Billing__c,
            CMAP_BillingPaymentMethodAgent__c:
              order?.CMAP_BillingPaymentMethodAgent__c,
            CMAP_BillingPaymentMethodRegistered__c:
              order?.CMAP_BillingPaymentMethodRegistered__c,
            CMAP_NeedsSendingDocumentOfBilling__c:
              order?.CMAP_NeedsSendingDocumentOfBilling__c,
            CMAP_AcquisitionAgency__c: order?.CMAP_AcquisitionAgency__c,
            CMAP_AcquisitionShop__c: order?.CMAP_AcquisitionShop__c,
            CMAP_IndividualContractPlan__c:
              order?.CMAP_IndividualContractPlan__c,
            CMAP_CancelDate__c: order?.CMAP_CancelDate__c,
            CMAP_CancelReason__c: order?.CMAP_CancelReason__c,
            CMAP_CancelReasonOther__c: order?.CMAP_CancelReasonOther__c,
            CMAP_StartRequestDate__c: order?.CMAP_StartRequestDate__c,
            CMAP_TerminationReason__c: order?.CMAP_TerminationReason__c,
            CMAP_TerminationReasonOther__c:
              order?.CMAP_TerminationReasonOther__c,
            CMAP_SuspendRequestDate__c: order?.CMAP_SuspendRequestDate__c,
            CMAP_SuspendResumeProspectDate__c:
              order?.CMAP_SuspendResumeProspectDate__c,
            CMAP_ResumeRequestDate__c: order?.CMAP_ResumeRequestDate__c,
            CMAP_UpdateAddRequestDate__c: order?.CMAP_UpdateAddRequestDate__c,
            CMAP_ProductChangeAddRequestDate__c:
              order?.CMAP_ProductChangeAddRequestDate__c,
            CMAP_UpdateLastUtilizationDate__c:
              order?.CMAP_UpdateLastUtilizationDate__c,
            CMAP_ProductChangeLastUtilizationDate__c:
              order?.CMAP_ProductChangeLastUtilizationDate__c,
            CMAP_LastUtilizationDate__c: order?.CMAP_LastUtilizationDate__c,
            CMAP_PlanChangeRequestDate__c: order?.CMAP_PlanChangeRequestDate__c,
            CMAP_ChangeSourceLastUtilizationDate__c:
              order?.CMAP_ChangeSourceLastUtilizationDate__c,
            ShippingStreet: order?.ShippingStreet,
            ShippingCity: order?.ShippingCity,
            ShippingState: order?.ShippingState,
            ShippingPostalCode: order?.ShippingPostalCode,
            ShippingLatitude: order?.ShippingLatitude,
            ShippingLongitude: order?.ShippingLongitude,
            CMAP_InstallationLocationAddressCode11__c:
              order?.CMAP_InstallationLocationAddressCode11__c,
            CMAP_InstallationLocationAddressCode__c:
              order?.CMAP_InstallationLocationAddressCode__c,
            CMAP_InstallationLocationCity__c:
              order?.CMAP_InstallationLocationCity__c,
            CMAP_InstallationLocationHouseNumber__c:
              order?.CMAP_InstallationLocationHouseNumber__c,
            CMAP_InstallationLocationRoomNumber__c:
              order?.CMAP_InstallationLocationRoomNumber__c,
            CMAP_EffectiveTime__c: order?.CMAP_EffectiveTime__c,
            CMAP_ClientId__c: order?.CMAP_ClientId__c,
            CMAP_DeliveryServiceAddress__PostalCode__s:
              order?.CMAP_DeliveryServiceAddress__PostalCode__s,
            CMAP_DeliveryServiceAddress__StateCode__s:
              order?.CMAP_DeliveryServiceAddress__StateCode__s,
            CMAP_DeliveryServiceAddress__City__s:
              order?.CMAP_DeliveryServiceAddress__City__s,
            CMAP_DeliveryServiceAddress__Street__s:
              order?.CMAP_DeliveryServiceAddress__Street__s,
            CMAP_DeliveryServiceAddressBuildingName__c:
              order?.CMAP_DeliveryServiceAddressBuildingName__c,
            CMAP_DeliveryServiceAddressRoomNumber__c:
              order?.CMAP_DeliveryServiceAddressRoomNumber__c,
            CMAP_CampaignBenefitAddition__c:
              order?.CMAP_CampaignBenefitAddition__c,
            CMAP_ChangeSourceOfferPlan__c: order?.CMAP_ChangeSourceOfferPlan__c,
            CMAP_ChangeSourceOpportunity__c:
              order?.CMAP_ChangeSourceOpportunity__c,
            CMAP_ChangeSourceIndividualContractPur__c:
              order?.CMAP_ChangeSourceIndividualContractPur__c,
            CMAP_ChangeSourceOfferPlanName__c:
              order?.CMAP_ChangeSourceOfferPlanName__c,
            CMAP_ChangeSourceTypeCircuit__c:
              order?.CMAP_ChangeSourceTypeCircuit__c,
            CMAP_ChangeSourceTypeCircuitWireless__c:
              order?.CMAP_ChangeSourceTypeCircuitWireless__c,
            CMAP_ChangeSourceTypeCircuitTypeJ__c:
              order?.CMAP_ChangeSourceTypeCircuitTypeJ__c,
            CMAP_ChangeSourceTypeNotCircuit__c:
              order?.CMAP_ChangeSourceTypeNotCircuit__c,
            CMAP_ChangeSourceCase__c: order?.CMAP_ChangeSourceCase__c,
            CMAP_ChangeSourceResidenceType__c:
              order?.CMAP_ChangeSourceResidenceType__c,
            CMAP_ChangeSourceBuildingCategory__c:
              order?.CMAP_ChangeSourceBuildingCategory__c,
            CMAP_ChangeSourceInstallationLocAddr__c:
              order?.CMAP_ChangeSourceInstallationLocAddr__c,
            CMAP_ChangeSourceDeliverySrvAddrForDisp__c:
              order?.CMAP_ChangeSourceDeliverySrvAddrForDisp__c,
            CMAP_CancelOrderChannelDetail__c:
              order?.CMAP_CancelOrderChannelDetail__c,
            CMAP_Rebuilding__c: order?.CMAP_Rebuilding__c,
            CMAP_IndividualContractComplementary__c:
              order?.CMAP_IndividualContractComplementary__c,
            CMAP_PersonalInformationConsentDate__c:
              order?.CMAP_PersonalInformationConsentDate__c,
            CMAP_ContractDocDeliveryCategory__c:
              order?.CMAP_ContractDocDeliveryCategory__c,
            CMAP_StandardProductOffer__c: order?.CMAP_StandardProductOffer__c,
            CMAP_MovementStandardProductOffer__c:
              order?.CMAP_MovementStandardProductOffer__c,
            CMAP_ConsentFamilyover65__c: order?.CMAP_ConsentFamilyover65__c,
            CMAP_TelePreferredCommunicationDay__c:
              order?.CMAP_TelePreferredCommunicationDay__c,
            CMAP_TelePreferredCommunicationTime__c:
              order?.CMAP_TelePreferredCommunicationTime__c,
            CMAP_RequestNotToSend__c: order?.CMAP_RequestNotToSend__c,
            CMAP_SupportedLanguage__c: order?.CMAP_SupportedLanguage__c,
            CMAP_PreferredCommunicationMethod__c:
              order?.CMAP_PreferredCommunicationMethod__c,
            CMAP_HearingImpairmentFlag__c: order?.CMAP_HearingImpairmentFlag__c,
            CMAP_TranslatorName__c: order?.CMAP_TranslatorName__c,
            CMAP_TranslatorKana__c: order?.CMAP_TranslatorKana__c,
            CMAP_HopeForConsulting1__c: order?.CMAP_HopeForConsulting1__c,
            CMAP_HopeForConsulting1Other__c:
              order?.CMAP_HopeForConsulting1Other__c,
            CMAP_HopeForConsulting2__c: order?.CMAP_HopeForConsulting2__c,
            CMAP_HopeForConsulting2Other__c:
              order?.CMAP_HopeForConsulting2Other__c,
            CMAP_RadioSurvey__c: order?.CMAP_RadioSurvey__c,
            CMAP_SeniorCitizen__c: order?.CMAP_SeniorCitizen__c,
            CMAP_RequestInvestigationTVanotherDay__c:
              order?.CMAP_RequestInvestigationTVanotherDay__c,
            CMAP_ManagementCompanyConsent__c:
              order?.CMAP_ManagementCompanyConsent__c,
            CMAP_StoreId__c: order?.CMAP_StoreId__c,
            CMAP_OtherCustomerRequest__c: order?.CMAP_OtherCustomerRequest__c,
            CMAP_StoreBoothCode__c: order?.CMAP_StoreBoothCode__c,
          },
          orderAddition: {
            orderCategoryCurrentlySelected:
              orderAddition?.orderCategoryCurrentlySelected,
            orderCategoryPreviouslySelected:
              orderAddition?.orderCategoryPreviouslySelected,
            orderChannelCategory: orderAddition?.orderChannelCategory,
            previousLastModifiedDate: orderAddition?.previousLastModifiedDate,
            electronicSignature: orderAddition?.electronicSignature,
          },
          orderImportantMatterDescriptions: orderImportantMatterDescriptions,
          orderItemInfos: orderItemInfos,
        },
        billingTo: {
          CMAP_PaymentMethod__c: billing?.CMAP_PaymentMethod__c,
          CMAP_CreditCardNumberFirstFourDigits__c:
            billing?.CMAP_CreditCardNumberFirstFourDigits__c,
          CMAP_CreditCardNumberLastFourDigits__c:
            billing?.CMAP_CreditCardNumberLastFourDigits__c,
          CMAP_YamaguchiInfoId__c: billing?.CMAP_YamaguchiInfoId__c,
          CMAP_CreditCardName__c: billing?.CMAP_CreditCardName__c,
          CMAP_CreditCardExpirationDate__c:
            billing?.CMAP_CreditCardExpirationDate__c,
          CMAP_CreditCardBankRelationship__c:
            billing?.CMAP_CreditCardBankRelationship__c,
          CMAP_CardCompany__c: billing?.CMAP_CardCompany__c,
          CMAP_IndividualCorporationKbn__c:
            billing?.CMAP_IndividualCorporationKbn__c,
          CMAP_PaymentRegistrationMethod__c:
            billing?.CMAP_PaymentRegistrationMethod__c,
          CMAP_AccountTransferBillingNumber__c:
            billing?.CMAP_AccountTransferBillingNumber__c,
        },
      },
    };

    return deserializedOrderRelatedInfo;
  };

  const {
    data: checkOrderData,
    isLoading: checkOrderIsLoading,
    error: checkOrderError,
    refetch: checkOrderRefresh,
    remove: checkOrderRemove,
  } = useCustomQuery(checkOrderURI, false, STRING_POST, checkOrderRequest);

  const {
    data: registerOrderData,
    isLoading: registerOrderIsLoading,
    error: registerOrderError,
    refetch: registerOrderRefresh,
    remove: registerOrderRemove,
  } = useCustomQuery(
    registerOrderURI,
    false,
    STRING_POST,
    registerOrderRequest
  );

  const {
    data: systemDateTimeData,
    isLoading: systemDateTimeIsLoading,
    error: systemDateTimeError,
    refetch: systemDateTimeRefresh,
    remove: systemDateTimeRemove,
  } = useCustomQuery(systemDateTimeURI, true, STRING_GET);

  const isApiLoading =
    checkOrderIsLoading || registerOrderIsLoading || systemDateTimeIsLoading;
  props.setIsLoading(isApiLoading);

  const selectButtons = getScDataFromPlaceholder("button-section", props);
  const nextButton = getScDataFromComponent("B-01", selectButtons);
  const backButton = getScDataFromComponent("B-02", selectButtons);

  // @Controller
  const handleNextBtn = () => {
    registerOrderRemove();
    registerOrderRefresh();
  };

  const handleBackBtn = () => {
    redirectToPreviousPage();
  };
  nextButton[0].fields.isDisabled = { value: isDisableNextButton };
  nextButton[0].fields.onClickEvent = (e) => {
    handleNextBtn();
  };
  backButton[0].fields.onClickEvent = (e) => {
    handleBackBtn();
  };

  // receive systemDateTime data
  useEffect(() => {
    if (systemDateTimeData) {
      const systemDateTime = new Date(systemDateTimeData?.SystemDateTime);

      const systemDateTimeYMD = moment(systemDateTime).format("YYYY-MM-DD");
      const systemDateTimeHMS = moment(systemDateTime).format("HH:mm:ss");

      const updatedOrderRelatedInfo = {
        ...orderRelatedInfo,
        orderRelatedInfo: {
          ...orderRelatedInfo?.orderRelatedInfo,
          orderInfo: {
            ...orderRelatedInfo?.orderRelatedInfo?.orderInfo,
            order: {
              ...orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order,
              EffectiveDate: systemDateTimeYMD,
              CMAP_EffectiveTime__c: systemDateTimeHMS,
              CMAP_CancelDate__c: systemDateTimeYMD,
            },
          },
        },
      };

      setSessionStorageItem(
        sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
        updatedOrderRelatedInfo,
        true
      );

      const orderInfoApiRequest = createCheckRegisterOrderApiRequest(
        updatedOrderRelatedInfo
      );

      setCheckOrderRequest({
        ...orderInfoApiRequest,
      });

      setRegisterOrderRequest({
        orderCheckProcessAlertIgnored: true,
        ...orderInfoApiRequest,
      });
    }
  }, [systemDateTimeData]);

  // receive checkOrderRequest
  useEffect(() => {
    if (checkOrderRequest) checkOrderRefresh();
  }, [checkOrderRequest]);

  //If Receive checkOrderData api
  useEffect(() => {
    clearErrors();
    if (checkOrderData) {
      const processResult = checkOrderData.processResult;
      if (processResult?.returnCode === apiResultCode.error) {
        processResult.resultInfos.forEach((err, index) => {
          if (err.resultCategory === apiResultCode.error) {
            setError(err.resultCode + index, {
              type: "manual",
              message: err.resultMessage,
            });
          }
        });
        setIsDisableNextButton(true);
      }

      if (processResult?.returnCode === apiResultCode.alert) {
        const cancelDetailSection = getScDataFromPlaceholder(
          "warning-modal-section",
          props
        );
        const [popup] = getScDataFromComponent("P0-01", cancelDetailSection);
        popup.fields.isCustom = true;

        const modalSection = getScDataFromPlaceholder("modal-section", popup);
        const [warningModal] = getScDataFromComponent(
          "Warning-Message-Modal-Layout",
          modalSection
        );
        warningModal.fields = {
          descriptionText: pageContext["WarningMessageModal-Description"],
          closeText: pageContext["WarningMessageModal-CloseButtonText"],
          confirmText: pageContext["WarningMessageModal-ApplyButtonText"],
          warningMessageList: processResult.resultInfos.reduce((acc, err) => {
            if (err.resultCategory === apiResultCode.alert) {
              acc.push({
                code: err.resultCode,
                message: err.resultMessage,
              });
            }
            return acc;
          }, []),
          onConfirmClick: () => {
            setIsDisableNextButton(false);
            return false;
          },
          onCancelClick: () => {
            setIsDisableNextButton(true);
            return false;
          },
        };

        dispatch({
          type: "SHOW_MODAL",
          payload: { id: popup?.fields?.modalTarget?.value },
        });
      }
    }
  }, [checkOrderData]);

  //If POST method (RegisterOrder) is success, Redirect to the NextButtonLink
  useEffect(() => {
    clearErrors();
    const processResult = registerOrderData?.processResult;
    if (processResult?.returnCode === apiResultCode.error) {
      processResult.resultInfos.forEach((err, index) => {
        if (err.resultCategory === apiResultCode.error) {
          setError(err.resultCode + index, {
            type: "manual",
            message: err.resultMessage,
          });
        }
      });
    }

    if (processResult?.returnCode === apiResultCode.success) {
      //store CMAP_OrderReceiptNumber__c in session storage
      const receiptNumber =
        registerOrderData?.orderRelatedInfo?.orderInfo?.order
          ?.CMAP_OrderReceiptNumber__c;
      setSessionStorageItem(
        sessionKeys.SSN_COMMON_COMMON_APPLICATIONNUMBER,
        receiptNumber,
        true
      );

      const { linkURL } = getProperData(
        props.sitecoreContext,
        nextButton[0]?.fields
      );
      redirectToLinkUrl(linkURL);
    }
  }, [registerOrderData]);

  // @View
  return (
    <React.Fragment>
      <main>
        <div className="form_container">
          <div className="form_detail full-width">
            <div className="form_wrapper">
              <Placeholder
                name="warning-modal-section"
                rendering={props.rendering}
              />
              <ApplicationCancelConfirmation rendering={props.rendering} />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withSitecoreContext()(ApplicationCancelConfirmationLayout);
