import { useCallback, useState } from "react";
import { recoilKeys } from "../assets/recoilKeys";

const crypto = require("crypto");
// const ENCRIPT_PASS = 'ctcProjectPh5';
// const SALT = '12345678';
//const KEY = crypto.scryptSync(ENCRIPT_PASS, SALT, 32);
//const KEY = crypto.pbkdf2Sync(ENCRIPT_PASS, SALT, 10000, 32);
const KEY = Buffer.from([
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
  0xff,
]);
const ALGORITHM = "aes-256-cbc";
const INPUT_ENC = "utf8";
const OUTPUT_ENC = "hex";

export const myEncryptFnc = (val) => {
  const IV = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(ALGORITHM, KEY, IV);

  const cipheredData = cipher.update(Buffer.from(val));

  return Buffer.concat([IV, cipheredData, cipher.final()]).toString(OUTPUT_ENC);
};

export const myDecryptFnc = (val) => {
  const buff = Buffer.from(val, OUTPUT_ENC);
  const iv = buff.slice(0, 16);
  const encData = buff.slice(16);
  const decipher = crypto.createDecipheriv(ALGORITHM, KEY, iv);
  const decData = decipher.update(encData);
  let encD = Buffer.concat([decData, decipher.final()]);
  return encD.toString(INPUT_ENC);
};

const useSessionStorage = (key, initialValue, isNotEncrypt) => {
  let val = initialValue;
  isNotEncrypt = isNotEncrypt === undefined ? false : isNotEncrypt;
  const [value, setValue] = useState(() => {
    if (typeof window !== undefined) {
      const keyValue = window.sessionStorage.getItem(key);

      if (keyValue) {
        val = isNotEncrypt ? keyValue : myDecryptFnc(keyValue);
        try {
          val = JSON.parse(val);
        } catch (e) {
          val = keyValue;
        }
      }
    }

    return val;
  });

  const myDecryptSetter = useCallback(
    (newValue, isNotEncrypt) => {
      isNotEncrypt = isNotEncrypt === undefined ? false : isNotEncrypt;
      setValue((oldValue) => {
        let _val = newValue;
        if (typeof window !== "undefined" && newValue) {
          if (typeof newValue == "object") {
            _val = JSON.stringify(newValue);
          }
          _val = isNotEncrypt ? _val : myEncryptFnc(_val);
          window.sessionStorage.setItem(key, _val);
        }

        return newValue;
      });
    },
    [key]
  );

  return [value, myDecryptSetter];
};

export const getSessionStorageItem = (key, isNotEncrypt) => {
  if (key === undefined) {
    return "";
  }
  isNotEncrypt = isNotEncrypt === undefined ? false : isNotEncrypt;
  let val = "";
  if (typeof window !== "undefined") {
    const keyValue = window.sessionStorage.getItem(key);
    if (keyValue) {
      try {
        val = isNotEncrypt ? keyValue : myDecryptFnc(keyValue);
        val = JSON.parse(val);
      } catch (e) {
        val = keyValue;
      }
    }
  }

  return val;
};

export const setSessionStorageItem = (key, newVal, isNotEncrypt) => {
  isNotEncrypt = isNotEncrypt === undefined ? false : isNotEncrypt;
  let _val = newVal;
  if (typeof window !== "undefined") {
    if (typeof newVal === "object") {
      _val = JSON.stringify(newVal);
    } else if (typeof newVal === "boolean") {
      _val = newVal.toString();
    }

    if (_val) {
      _val = isNotEncrypt ? _val : myEncryptFnc(_val);
      window.sessionStorage.setItem(key, _val);
    }
  }

  return newVal;
};

export const removeSessionStorageItem = (key) => {
  if (typeof window !== "undefined") {
    window.sessionStorage.removeItem(key);
  }
};

export const removeAllSessionStorageItem = (excludeKeys = []) => {
  JSON.parse(JSON.stringify(recoilKeys), (key, value) => {
    if (key.substring(0, 3) === "SSN" && !excludeKeys?.includes(value)) {
      removeSessionStorageItem(value);
    }
  });
};

export default useSessionStorage;
