import React, { useState } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fillBlank, getProperData } from "../../utils/commonUtils";
import { di_fetch } from "../../utils/di";

const S16 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    titleText,
    descriptionText,
    dataRowList = [],
    additionalClass,
  } = properData;

  const [checkboxStatus, setCheckboxStatus] = useState([]);
  if (checkboxStatus.length !== dataRowList.length) {
    setCheckboxStatus(dataRowList?.map((item) => item.checked));
  }

  const handleCheckboxChange = (index, evt) => {
    const updatedStatus = [...checkboxStatus];
    updatedStatus[index] = !checkboxStatus[index];
    setCheckboxStatus(updatedStatus);

    if (properData.onChangeEvent) properData.onChangeEvent(updatedStatus);
  };

  return (
    <React.Fragment>
      <div
        className={`input-form checkbox-list-wrapper S-16  ${additionalClass?.value}`}
      >
        <div className="input-box">
          <div className="list-title">
            <Text field={fillBlank(titleText)} tag="span" encode={false} />
          </div>
          <div className="list-content">
            <div className="description-content">
              <Text field={fillBlank(descriptionText)} tag="p" encode={false} />
            </div>

            <div className="checkbox-list">
              {dataRowList?.map((item, i) => {
                const id = "cbOption_" + i;
                return (
                  <div className="input-container" key={id}>
                    <input
                      type="checkbox"
                      name={id}
                      checked={checkboxStatus[i]}
                      defaultChecked={item.checked}
                      onChange={() => handleCheckboxChange(i)}
                    />
                    <label htmlFor={id}>
                      <Text field={fillBlank(item.label)} />
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default S16;
