import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";

// @Model

// get the data

// update the context

// @Controller

// @View

const H04 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const { titleText, additionalClass } = properData;

  return (
    <React.Fragment>
      <div className={`H-04 heading_wrapper  ${additionalClass?.value}`}>
        <h4>
          <Text field={fillBlank(titleText)} />
        </h4>
      </div>
    </React.Fragment>
  );
};

export default H04;
