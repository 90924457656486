import React, { useEffect } from "react";
import { format } from "date-fns";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
  isWithinDateRange,
} from "../../utils/commonUtils";
import { recoilKeys as keys } from "../../assets/recoilKeys";
import { getSessionStorageItem } from "../../utils/useSessionStorage";
import {
  STRING_MOVING,
  STRING_REBUILDING,
} from "../../utils/constantVariables";

export function SelectPreferrredTransferDate(props) {
  const {
    isNotEncrypt,
    inputValues,
    setInputValues,
    isFirstTimeDisplay,
    urlParentItem,
    retrieveSystemDateTimeData,
    retrieveSelectableDesiredDateRangeData,
  } = props;

  const transferPreferredDateSection = getScDataFromPlaceholder(
    "transfer-preferred-date-section",
    props
  );
  const [M01iData] = getScDataFromComponent(
    "M-01i",
    transferPreferredDateSection
  );

  let preferredTransferDateInitialValue = "";

  const ssnPreferredTransfereDate = getSessionStorageItem(
    keys.SSN_APPLICATION_INPUTCUSTOMERINFO_PREFERREDTRANSFERDATE,
    isNotEncrypt
  );
  useEffect(() => {
    if (isFirstTimeDisplay) {
      if (
        urlParentItem === STRING_MOVING ||
        urlParentItem === STRING_REBUILDING
      ) {
        if (retrieveSystemDateTimeData) {
          if (retrieveSelectableDesiredDateRangeData) {
            const minDate =
              retrieveSelectableDesiredDateRangeData
                .selectablePreferDatesRangeInfo?.minEndDate;
            const maxDate =
              retrieveSelectableDesiredDateRangeData
                .selectablePreferDatesRangeInfo?.maxEndDate;

            M01iData.fields.minDate = minDate;
            M01iData.fields.maxDate = maxDate;

            if (
              isWithinDateRange(ssnPreferredTransfereDate, minDate, maxDate)
            ) {
              preferredTransferDateInitialValue = ssnPreferredTransfereDate;
            }
          }
        }
      }
    }

    setInputValues((prevState) => {
      return {
        ...prevState,
        selectPreferredTransferDateCalendar: preferredTransferDateInitialValue,
      };
    });
  }, [retrieveSystemDateTimeData, retrieveSelectableDesiredDateRangeData]);

  M01iData.fields.initialValue = {
    value: inputValues.selectPreferredTransferDateCalendar,
  };

  M01iData.fields.onChangeEvent = (date) => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        selectPreferredTransferDateCalendar: format(date, "yyyy-MM-dd"),
      };
    });
  };
  M01iData.fields.inputValue = {
    name: "selectPreferredTransferDateCalendar",
    value: inputValues.selectPreferredTransferDateCalendar,
  };
  return (
    <div className="form_wrapper-input" name="transfer-preferred-date-section">
      <Placeholder
        name="transfer-preferred-date-section"
        rendering={props.rendering}
      />
    </div>
  );
}
