import React, { useContext } from "react";
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { recoilKeys as sessionKeys } from "../../assets/recoilKeys";
import { di_register } from "../../utils/di";
import { apiBaseURL } from "../../envSettings";
import { useCustomQuery } from "../../hooks/useGetData";
import { STRING_POST } from "../../utils/constantVariables";
import { getSessionStorageItem } from "../../utils/useSessionStorage";
import ModalContext from "../../assets/context/ModalContext";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
} from "../../utils/commonUtils";
import { DotLoader } from "react-spinners";

const CancellationBreakdownTable = (props) => {
  const sitecoreFields = props.pageContext;
  return (
    <div className="cpad-tables normal vertical-sp-view mb-20">
      <table className="table-style-1">
        <tbody>
          <tr>
            <td>
              {sitecoreFields["ViewCancelProducts-OriginalPlanLable"].value}
            </td>
            <td>{props.originalPlan}</td>
          </tr>
          <tr>
            <td>
              {sitecoreFields["ViewCancelProducts-ProposedPlanLable"].value}
            </td>
            <td>{props.proposedPlan}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const ProductDescriptionTable = (props) => {
  const sitecoreFields = props.pageContext;
  const tableData = props.tableData;
  const cancelProductLabel =
    sitecoreFields["ViewCancelProducts-CancelProductLable"].value;
  const noCancelProductsText =
    sitecoreFields["ViewCancelProducts-NoCancelProductsText"].value;
  const ProductName = sitecoreFields["ViewCancelProducts-ProductName"].value;
  const ProductStatus =
    sitecoreFields["ViewCancelProducts-ProductStatus"].value;
  const InitationDate =
    sitecoreFields["ViewCancelProducts-InitationDate"].value;
  const Price = sitecoreFields["ViewCancelProducts-Price"].value;
  const Quantity = sitecoreFields["ViewCancelProducts-Quantity"].value;
  const UnitPrice = sitecoreFields["ViewCancelProducts-UnitPrice"].value;
  const tableHeaders = [
    ProductName,
    ProductStatus,
    InitationDate,
    Price,
    Quantity,
    UnitPrice,
  ];

  return (
    <>
      <div className="description-content D-02 mb-10">
        <span>{cancelProductLabel}</span>
      </div>

      {tableData?.length > 0 ? (
        <>
          {tableData?.map((data, i) => (
            <>
              {data?.notApplicableOrderItemInfos?.length > 0 ? (
                <div className="responsive-table">
                  <table
                    className="border-style-1"
                    cellSpacing="0"
                    cellPadding="0"
                  >
                    <thead>
                      <tr className="txt_center">
                        {tableHeaders.map((item, i) => (
                          <th key={i}>{item}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.notApplicableOrderItemInfos[0].orderItemInfos.map(
                        (item, key) => (
                          <>
                            <tr key={i}>
                              <td
                                key={ProductName + key}
                                className="txt_left"
                                data-before={ProductName}
                              >
                                <span>
                                  {item?.orderItem?.CMAP_OfferPlanProduct__c}
                                </span>
                              </td>
                              <td
                                key={ProductStatus + key}
                                className="txt_left"
                                data-before={ProductStatus}
                              >
                                <span>
                                  {
                                    item?.orderItem
                                      ?.CMAP_ProductSelectionState__c
                                  }
                                </span>
                              </td>
                              <td
                                key={InitationDate + key}
                                className="txt_left"
                                data-before={InitationDate}
                              >
                                <span>
                                  {item?.orderItem?.CMAP_OfferStartDate__c}
                                </span>
                              </td>
                              <td
                                key={Price + key}
                                className="txt_right"
                                data-before={Price}
                              >
                                <span>
                                  ¥{item?.orderItemAddition?.totalPrice}
                                </span>
                              </td>
                              <td
                                key={Quantity + key}
                                className="txt_left"
                                data-before={Quantity}
                              >
                                <span>{item?.orderItem?.Quantity}</span>
                              </td>
                              <td
                                key={UnitPrice + key}
                                className="txt_right"
                                data-before={UnitPrice}
                              >
                                <span>¥{item?.orderItem?.UnitPrice}</span>
                              </td>
                            </tr>
                          </>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="description-content D-01">
                  <p>{noCancelProductsText}</p>
                </div>
              )}
            </>
          ))}
        </>
      ) : (
        <div className="description-content D-01">
          <p>{noCancelProductsText}</p>
        </div>
      )}
    </>
  );
};

const ProductsSubjectForCancellationModalLayout = (props) => {
  // TODO: Integrate Products Subject For Cancellation Modal to 1-6
  const sitecoreContext = props?.sitecoreContext;
  const pageContext = sitecoreContext?.route?.fields;
  di_register("thisPageSitecoreContext", sitecoreContext);
  const { dispatch } = useContext(ModalContext);

  const beforeChangeOrderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_BEFORECHANGEORDERRELATEDINFO,
    true
  );
  const originalPlan =
    beforeChangeOrderRelatedInfo?.orderRelatedInfo?.orderInfo?.order
      ?.CMAP_OfferPlan__c;
  const beforeChangeOrderItemInfos =
    beforeChangeOrderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderItemInfos;

  const selectedPlanInfo = getSessionStorageItem(
    sessionKeys.SSN_APPLICATION_SELECTPRODUCT_SELECTEDPLANINFO,
    true
  );
  // TODO: Replace value to Selected [3.PlanName] on 1-6_プラン・商品選択(PlanProductSelection) once transferred to 1-6.
  const proposedPlan = selectedPlanInfo?.OfferPlanName;
  // TODO: Replace value to Selected [2.PlanID] on 1-6_プラン・商品選択(PlanProductSelection） once transferred to 1-6.
  const planID = selectedPlanInfo?.OfferPlan;

  const orderRelatedInfo = getSessionStorageItem(
    sessionKeys.SSN_COMMON_COMMON_ORDERRELATEDINFO,
    true
  );

  const orderInfoOrder = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order;
  const orderInfoOrderAddition = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.orderAddition;

  const payload = {
    orderRelatedInfo: {
      accountInfo: {
        account: {
          CMAP_ContractName__c:
          orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account?.CMAP_ContractName__c,
        },
      },
      orderInfo: {
        order: {
          CMAP_ContractName__c: orderInfoOrder?.CMAP_ContractName__c,
          CMAP_ResidenceType__c: orderInfoOrder?.CMAP_ResidenceType__c,
          CMAP_OfferPlan__c: planID,
          CMAP_InstallationLocationOfferArea__c:
            orderInfoOrder?.CMAP_InstallationLocationOfferArea__c,
          CMAP_AcquisitionAgency__c: orderInfoOrder?.CMAP_AcquisitionAgency__c,
          CMAP_AcquisitionShop__c: orderInfoOrder?.CMAP_AcquisitionShop__c,
          CMAP_InstallationLocationPrefectureCode__c:
            orderInfoOrder?.CMAP_InstallationLocationPrefectureCode__c,
          CMAP_StandardProductOffer__c:
            orderInfoOrder?.CMAP_StandardProductOffer__c,
          CMAP_MovementStandardProductOffer__c:
            orderInfoOrder?.CMAP_MovementStandardProductOffer__c,
        },
        orderAddition: {
          orderCategoryCurrentlySelected:
            orderInfoOrderAddition?.orderCategoryCurrentlySelected,
          orderCategoryPreviouslySelected:
            orderInfoOrderAddition?.orderCategoryPreviouslySelected,
          orderChannelCategory: orderInfoOrderAddition?.orderChannelCategory,
        },
        orderItemInfos: beforeChangeOrderItemInfos,
      },
    },
  };
  // get the data
  const planComparisonURI = `${apiBaseURL}order/PlanComparison`;
  const {
    data: planComparisonData,
    isLoading: planComparisonIsLoading,
    error: planComparisonError,
    refetch: planComparisonRefresh,
    remove: planComparisonRemove,
  } = useCustomQuery(planComparisonURI, true, STRING_POST, payload);

  const tableData = planComparisonData?.planComparisoninfo;
  const buttonSection = getScDataFromPlaceholder("button-section", props);
  const [ToReselectPlansButton] = getScDataFromComponent("B-02", buttonSection);
  const [ToSelectProductsButton] = getScDataFromComponent(
    "B-01",
    buttonSection
  );

  // @Controller
  const modalCloseButtonHandler = () => {
    dispatch({
      type: "HIDE_MODAL",
      payload: { id: "subject-to-cancel-modal" },
    });
  };

  ToReselectPlansButton.fields.onClickEvent = () => {
    modalCloseButtonHandler();
  };

  ToSelectProductsButton.fields.onClickEvent = () => {
    modalCloseButtonHandler();
  };
  // @View
  return (
    <>
    {planComparisonIsLoading && (
        <div className="screen-loading">
          <DotLoader
            loading={planComparisonIsLoading}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="spinner"
            color="#FFF"
          />
        </div>
      )}
      <div className="form_container cpad_wrapper2">
        <div className="form_detail full-width">
          <div className="form_wrapper">
            <div className="heading_wrapper">
              <Placeholder
                name="product-subject-for-cancellation-header"
                rendering={props.rendering}
              />
              <Placeholder
                name="product-subject-for-cancellation-description"
                rendering={props.rendering}
              />
            </div>
            <CancellationBreakdownTable
              rendering={props.rendering}
              pageContext={pageContext}
              originalPlan={originalPlan}
              proposedPlan={proposedPlan}
            />
            <ProductDescriptionTable
              rendering={props.rendering}
              pageContext={pageContext}
              tableData={tableData}
            />
            <div className="btn_container mt-40 modal_btn_container">
              <Placeholder name="button-section" rendering={props.rendering} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withSitecoreContext()(ProductsSubjectForCancellationModalLayout);
