import React, { useEffect } from "react";
import { format, parse as parseDate } from "date-fns";

import {
  STRING_CURRENCY_CHAR,
  DATE_FORMAT_YYYY年M月,
  DATE_FORMAT_YYYYMM,
  STRING_CARD_VISA,
  STRING_CARD_MASTER,
  STRING_CARD_AMEX,
  STRING_CARD_DINERS,
  STRING_CARD_JCB,
} from "./constantVariables";
import { cpadPageURL, masterPageURL, mycommufaPageURL } from "../envSettings";

const crypto = require("crypto");

export const renderErrorMsg = (isError, errorMessage) => {
  if (!isError) {
    return "";
  }
  return (
    <div className="warning-message">
      <p>{errorMessage}</p>
    </div>
  );
};

/**
 * Replaces empty, null, undefined string to "-" or the defaultValue param
 * @param {{ value: string }} text
 * @param {string} defaultValue
 * @returns {{ value: string | number }}
 */
export const fillBlank = (text, defaultValue) => {
  if (typeof text === "number") return { value: text };
  if (!text) {
    text = defaultValue ? defaultValue : "–";
    const obj = {};
    obj.value = text;
    return obj;
  } else {
    if (typeof text?.value === "number") return { value: text };
    if (typeof text === "string") text = { value: text };
    if (!text.value) {
      text.value = defaultValue ? defaultValue : "–";
      return text;
    }
  }
  return text;
};

export const preventDoubleClick = (func) => {
  let clicked = false;

  return (...args) => {
    if (!clicked) {
      clicked = true;
      func(...args);

      setTimeout(() => {
        clicked = false;
      }, 500);
    }
  };
};

export const getScDataFromPlaceholder = (placeholderName, props) => {
  let data = props?.rendering?.placeholders;
  if (!data) {
    data = props?.placeholders;
  }

  if (typeof data !== "object") {
    return [];
  }
  if (placeholderName in data) {
    return data[placeholderName];
  }

  return [];
};

export const getPlaceholderFromComponent = (placeholderName, component) => {
  const placeholder = component?.placeholders;
  if (typeof placeholder !== "object") {
    return [];
  }
  if (placeholderName in placeholder) {
    return placeholder[placeholderName];
  }
  return [];
};

export const getScDataFromComponent = (componentName, props) => {
  if (!props || typeof props !== "object") {
    return props;
  }
  return props.filter((v) => v.componentName == componentName);
};

const exectedList = {};
export const replaceScDataFromComponent = (
  componentName,
  props,
  afterData,
  key
) => {
  if (!props || typeof props !== "object") {
    return props;
  }

  if (key in exectedList) {
    return props;
  }

  // remove original data
  let start_i = 0;
  let remove_index = [];
  props.forEach(function (v, i) {
    if (v.componentName == componentName) {
      //delete props[i];
      props.splice(i, 1);
      start_i = i;
    }
  });

  //add new data
  props.forEach(function (v, i) {
    if (i == start_i - 1) {
      props.push(...afterData);
    }
  });

  //props = [...props.keys()].map(i => ++i);

  exectedList[key] = true;
};

export const stringEmpty = (str) => {
  if (str === undefined || str === null) {
    return true;
  }

  if (typeof str !== "string") {
    return false;
  }

  if (str != "" && str.length > 0) {
    return false;
  }

  return true;
};

export const objectEmpty = (obj) => {
  if (obj === undefined) {
    return true;
  }
  if (typeof obj !== "object") {
    return false;
  }

  if (obj.length > 0) {
    return false;
  }

  return true;
};

export const notEmpty = (obj) => {
  return !stringEmpty(obj) && !objectEmpty(obj);
};

export const deepcopy = (origin_dict) => {
  let newObj = Object.assign({}, origin_dict);
  newObj = JSON.stringify(newObj);
  newObj = JSON.parse(newObj);

  return newObj;
};

export function getProperData(ctxt, compFields) {
  if (!ctxt) {
    return compFields;
  }

  if (!compFields) {
    return {};
  }

  let compFieldNames = Object.keys(compFields);
  let pageFields = ctxt?.route?.fields;
  let resultObj = {};
  for (var i in compFieldNames) {
    var fieldName = compFieldNames[i];
    if (fieldName.startsWith("page_")) {
      if (compFields[fieldName]) {
        let pageFieldName = compFields[fieldName].name;
        let properFieldName = compFieldNames[i].substring(5);
        resultObj[properFieldName] = pageFields[pageFieldName];
      }
    } else {
      if (!resultObj[fieldName]) {
        resultObj[fieldName] = compFields[fieldName];
      }
    }
  }
  return resultObj;
}

export function setDicts(dict, keyStrings, val) {
  if (!keyStrings || keyStrings.length == 0) {
    return dict;
  }

  if (dict.length === 0) {
    dict = {};
  }

  let copyDict = deepcopy(dict);

  let splitKeys = keyStrings.split(".");
  let maxLength = splitKeys.length - 1;
  splitKeys.forEach(function (v, i) {
    if (!copyDict[v]) {
      let addV = {};
      if (i == maxLength) {
        addV = val;
      }
      copyDict[v] = addV;
    }
  });

  return copyDict;
}

export const openButtonLink = (url, target) => {
  if (target === "") {
    window.location.href = url;
  } else if (target === "_blank") {
    window.open(url);
  }
};

/**
 * Application Info（お申込案内リスト）のデータ書き換えロジック
 * @param {*} injectdata
 * @param {*} targetListData
 * @param {*} targetListButtonData
 */
export const dataInjectionForApplicationInfo = (
  injectdata,
  targetListData,
  targetListButtonData
) => {
  let img = injectdata?.fields.filter((e) => e.name == "Image")[0]?.editable,
    description = injectdata?.fields.filter((e) => e.name == "Description")[0]
      ?.value,
    buttonText = injectdata?.fields.filter((e) => e.name == "ButtonText")[0]
      ?.value,
    linkUrl = injectdata?.fields.filter((e) => e.name == "LinkUrl")[0]?.value,
    urlType = injectdata?.fields.filter((e) => e.name == "UrlType")[0]?.value,
    iconString = injectdata?.fields.filter((e) => e.name == "icon-string")[0]
      ?.value;

  targetListData.fields.image = img;
  targetListData.fields.iconLogo = iconString ? iconString : "";
  targetListData.fields.contactTitleText = description;

  targetListButtonData.fields.buttonText = buttonText;
  targetListButtonData.fields.linkURL = linkUrl;
  //targetListButtonData.fields.buttonText = buttonText;
  /*targetListButtonData.fields.onClickEvent = (e) => {

    }*/
};

export const nullChecker = (data, defaultValue) => {
  if (defaultValue === undefined || defaultValue === null)
    defaultValue = { value: "" };
  if (data) {
    if (data?.value) return data;
    if (data instanceof Object && Object.keys(data).length > 0) return data;
    if (data instanceof Array && data.length > 0) return data;
  }
  return defaultValue;
};

export const redirect = (urlObj) => {
  if (typeof urlObj === "string" && typeof window !== "undefined") {
    window.location.href = urlObj;
    return true;
  }

  if (urlObj?.href && typeof window !== "undefined") {
    window.location.href = urlObj.href;
    return true;
  }
};

export const redirectToLinkUrl = (linkURL) => {
  const urlInfo = linkURL?.value;
  const linkType = urlInfo?.linktype;
  const target = urlInfo?.target;
  const url = urlInfo?.href;
  if (linkType == "javascript") {
    eval(url);
    return true;
  }

  if (target === "" || typeof target == "undefined") {
    window.location.href = url;
  } else if (target === "_blank") {
    window.open(url);
  }
};

// 金額表示フォーマット作成
export const formatCurrency = (input, sign = null) => {
  let num = Number(input?.toString());
  if (isNaN(num)) return "-";

  let result = "";
  if (sign) result = sign.toString();
  result = result + num.toLocaleString("ja-JP");

  return result;
};

export const formatCurrencyChar = (input, sign = null) => {
  const formattedCurrency = formatCurrency(input, sign);

  if (formattedCurrency === "-") return "-";

  return formattedCurrency + STRING_CURRENCY_CHAR;
};

export const generateRandomDigit = () => {
  let random = Math.floor(1000 + Math.random() * 9000);
  return random.toString();
};

export const isWithinDateRange = (date, minDate, maxDate) => {
  if (date && minDate && maxDate) {
    date = new Date(date);
    minDate = new Date(minDate);
    maxDate = new Date(maxDate);

    return date >= minDate && date <= maxDate;
  }

  return false;
};

export const UrlParams = (obj, item) => {
  let params = new URLSearchParams();
  obj.forEach((value) => params.append(item, value));
  return params;
};

export const computeAge = (date) => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const sortKeyValuePair = (keyValuePair, sortByAsc = true) => {
  const sortedkeyValuePair = keyValuePair?.sort((a, b) => {
    const keyA = a.key;
    const keyB = b.key;

    if (sortByAsc == true) {
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
    } else if (sortByAsc == false) {
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
    }
    return 0;
  });

  return sortedkeyValuePair;
};

export const generateGUID = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

const KEY = Buffer.alloc(32, 0xff);
const ALGORITHM = "aes-256-cbc";
const INPUT_ENC = "utf-8";
const OUTPUT_ENC = "hex";

export const generateAuthCode = (value) => {
  if (!value) {
    value = Math.floor(1000 + Math.random() * 9000).toString();
  }

  const IV = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(ALGORITHM, KEY, IV);
  const encryptedData = cipher.update(Buffer.from(value, INPUT_ENC));
  return Buffer.concat([IV, encryptedData, cipher.final()]).toString(
    OUTPUT_ENC
  );
};

export const decryptAuthCode = (hexString) => {
  const encodedValue = Buffer.from(hexString, OUTPUT_ENC);

  const IV = encodedValue.slice(0, 16);
  const encryptedData = encodedValue.slice(16);
  const decipher = crypto.createDecipheriv(ALGORITHM, KEY, IV);
  return (
    decipher.update(encryptedData, "binary", INPUT_ENC) +
    decipher.final(INPUT_ENC)
  );
};

export const showPrintDialog = () => {
  if (window?.webkit?.messageHandlers?.showPrintDialog !== undefined) {
    // for iOS
    window.webkit.messageHandlers.showPrintDialog.postMessage("");
  } else if (window?.android?.showPrintDialog !== undefined) {
    // for Android
    window.android.showPrintDialog();
  }
};

/**
 * Formats the date string passed from "dateFrom" format to "dateTo" format
 * @param {string} date
 * @param {string} dateTo
 * @param {string} dateFrom
 * @returns {string}
 */
export const formatDate = (
  date,
  dateTo = DATE_FORMAT_YYYY年M月,
  dateFrom = DATE_FORMAT_YYYYMM
) => {
  if (!date) return "-";
  const parsed = parseDate(date, dateFrom, new Date());
  return format(parsed, dateTo);
};

export const validateUTF8Char = (val, len, wi) => {
  const valLength = val.toString().length;
  if (valLength < len) {
    const valDif = len - valLength;
    let charFullWidth = "";
    let utf8Char = "";
    if (wi === "full-width") {
      utf8Char = "\u3000";
    }
    if (wi === "half-width") {
      utf8Char = "\u0020";
    }
    for (var i = 0; i < valDif; i++) {
      charFullWidth += utf8Char;
    }
    return `${val}${charFullWidth}`;
  }
  return val;
};

export const fullWidthSpace = (val, len) => {
  return validateUTF8Char(val, len, "full-width");
};

export const halfWidthSpace = (val, len) => {
  return validateUTF8Char(val, len, "half-width");
};

export const yyMMFormat = (val) => {
  var eDate = val.split("/");
  return `${eDate[1]}/${eDate[0]}`;
};

const cardEnum = {
  VISA: "4",
  Master: ["2", "5"],
  JCB: "35",
  AMEX: ["034", "037"],
  Diners: ["0030", "0036", "0038", "0039"],
};

export const creditCardBrand = (val) => {
  const cardNumber = val.toString();
  const firstFourDigits = cardNumber.substring(0, 4);
  const firstDigit = cardNumber.substring(0, 1);
  const firstTwoDigits = cardNumber.substring(0, 2);
  const firstThreeDigits = cardNumber.substring(0, 3);
  if (firstDigit === cardEnum.VISA) {
    return STRING_CARD_VISA;
  } else if (cardEnum.Master.indexOf(firstDigit) > -1) {
    return STRING_CARD_MASTER;
  } else if (firstTwoDigits === cardEnum.JCB) {
    return STRING_CARD_JCB;
  } else if (cardEnum.AMEX.indexOf(firstThreeDigits) > -1) {
    return STRING_CARD_AMEX;
  } else if (cardEnum.Diners.indexOf(firstFourDigits) > -1) {
    return STRING_CARD_DINERS;
  } else {
    return "";
  }
};

export const getParamValue = (param, isParamArray = false) => {
  if (typeof window !== "undefined") {
    const urlQueryString = new URLSearchParams(window.location.search);
    const paramValue = !isParamArray
      ? urlQueryString.get(param)
      : urlQueryString.getAll(param);
    return paramValue;
  }
};

export const getUrlInfo = () => {
  if (typeof window !== "undefined") {
    const pathnames = window.location.pathname?.split("/");
    const hostname = window.location.hostname?.split(".")[0];
    const origin = window.location.origin;
    const href = window.location.href;
    const cpadHostName = cpadPageURL?.split("//")[1]?.split(".")[0];
    const masterHostName = masterPageURL?.split("//")[1]?.split(".")[0];
    const mycommufaHostName = mycommufaPageURL?.split("//")[1]?.split(".")[0];

    return {
      pathnames,
      hostname,
      origin,
      href,
      cpadHostName,
      masterHostName,
      mycommufaHostName,
    };
  }
};

export const addDataRowList = (key, value) => {
  return {
    fields: {
      Key: {
        value: key,
      },
      Value: {
        value: value,
      },
    },
  };
};

export const stringToJSON = (data) => {
  let parsedData;
  try {
    parsedData = JSON.parse(data);
  } catch (e) {
    parsedData = data;
  }
  return parsedData;
};

export const generateNumberArray = (maxNumber) => {
  return Array.from({ length: maxNumber }, (_, index) => index + 1);
};

export const hasObjectField = (objectData, fieldName) => {
  let hasPhone = false;
  Object.keys(objectData).forEach((service) => {
    if (typeof objectData[service] === "object") {
      Object.keys(objectData[service]).forEach((field) => {
        if (field.startsWith(fieldName)) {
          hasPhone = true;
        }
      });
    }
  });

  return hasPhone;
};

export const isInvalidLoginStatus = (IsLoggedIn, IsNotLoggedIn, isLogged) => {
    if (isLogged) {
        return IsLoggedIn !== "1";
    }
    else {
        return IsNotLoggedIn !== "1";
    }
};

export const isContainingHTMLTags = (str) => {
  const regex = /<\/?[a-z][\s\S]*>/i;
  return regex.test(str);
};