
import React, { useEffect } from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import {
  getScDataFromPlaceholder,
  getScDataFromComponent,
  addDataRowList
} from "../../utils/commonUtils";
import moment from "moment";
import {
	parseOptionString,
	matchMetaOptions
} from "../../utils/parseMetadata";

export function AdditionalInfoForAgencyConfirmation(props) {
	const {sitecoreContext, isCpad, orderRelatedInfo, retrieveMetadataData} = props;
	const pageContext = sitecoreContext.route.fields;
	const order = orderRelatedInfo?.orderRelatedInfo?.orderInfo?.order;
	const account = orderRelatedInfo?.orderRelatedInfo?.accountInfo?.account;

	const orderTelePreferredCommunicationDay = order?.CMAP_TelePreferredCommunicationDay__c;
	const orderTelePreferredCommunicationTime = order?.CMAP_TelePreferredCommunicationTime__c;
	const orderRequestNotToSend = order?.CMAP_RequestNotToSend__c;
	const orderSupportedLanguage = order?.CMAP_SupportedLanguage__c;
	const orderPreferredCommunicationMethod = order?.CMAP_PreferredCommunicationMethod__c;
	const orderHopeForConsulting1 = order?.CMAP_HopeForConsulting1__c;
	const orderHopeForConsulting2 = order?.CMAP_HopeForConsulting2__c;

	const seniorCitizen = order?.CMAP_SeniorCitizen__c;
	const personBirthDate = account?.PersonBirthdate;
	const hearingImpairmentFlag = order?.CMAP_HearingImpairmentFlag__c;
	const translatorName = order?.CMAP_TranslatorName__c;
	const translatorKana = order?.CMAP_TranslatorKana__c;
	const requestInvestigationTVanotherDay = order?.CMAP_RequestInvestigationTVanotherDay__c;
	const managementCompanyConsent = order?.CMAP_ManagementCompanyConsent__c;
	const effectiveDate = order?.EffectiveDate;
	const campaignBenefitAddition = order?.CMAP_CampaignBenefitAddition__c;
	const otherCustomerRequest = order?.CMAP_OtherCustomerRequest__c;
	const hopeForConsulting1Other = order?.CMAP_HopeForConsulting1Other__c;
	const hopeForConsulting2Other = order?.CMAP_HopeForConsulting2Other__c

	const additionalAgencyInfoSection = getScDataFromPlaceholder("additional-agency-info-section",props);
	const additionalAgencyButtonSection = getScDataFromPlaceholder("additional-agency-button-section",props);
	const [additionalAgencyL01Data1, additionalAgencyL01Data2] = getScDataFromComponent("L-01", additionalAgencyInfoSection);
	const [additionalAgencyH02Data] = getScDataFromComponent("H-02", additionalAgencyInfoSection);
	const b01Data = getScDataFromComponent("B-01i", additionalAgencyButtonSection)[0];
	
	useEffect(() => {
		const retrieveMetadataDataOrder = retrieveMetadataData?.metadataDefinitions?.Order?.fieldMetadataDefinitions;
		if (retrieveMetadataData && retrieveMetadataDataOrder) {
			const telePreferredCommunicationDayOptions = retrieveMetadataDataOrder?.CMAP_TelePreferredCommunicationDay__c?.fieldMetadata?.fieldMetadataExtension?.CMAP_Option__c;
			const telePreferredCommunicationTimeOptions = retrieveMetadataDataOrder?.CMAP_TelePreferredCommunicationTime__c?.fieldMetadata?.fieldMetadataExtension?.CMAP_Option__c;
			const requestNotToSendOptions = retrieveMetadataDataOrder?.CMAP_RequestNotToSend__c?.fieldMetadata?.fieldMetadataExtension?.CMAP_Option__c;
			const supportedLanguageOptions = retrieveMetadataDataOrder?.CMAP_SupportedLanguage__c?.fieldMetadata?.fieldMetadataExtension?.CMAP_Option__c;
			const preferredCommunicationMethodOptions = retrieveMetadataDataOrder?.CMAP_PreferredCommunicationMethod__c?.fieldMetadata?.fieldMetadataExtension?.CMAP_Option__c;
			const hopeForConsulting1Options = retrieveMetadataDataOrder?.CMAP_HopeForConsulting1__c?.fieldMetadata?.fieldMetadataExtension?.CMAP_Option__c;
			const hopeForConsulting2Options = retrieveMetadataDataOrder?.CMAP_HopeForConsulting2__c?.fieldMetadata?.fieldMetadataExtension?.CMAP_Option__c;

			const telePreferredCommunicationDay = matchMetaOptions(parseOptionString(telePreferredCommunicationDayOptions), orderTelePreferredCommunicationDay, "-");
			const telePreferredCommunicationTime = matchMetaOptions(parseOptionString(telePreferredCommunicationTimeOptions), orderTelePreferredCommunicationTime, "-");
			const requestNotToSend = matchMetaOptions(parseOptionString(requestNotToSendOptions), orderRequestNotToSend, "-");
			const supportedLanguage = matchMetaOptions(parseOptionString(supportedLanguageOptions), orderSupportedLanguage, "-");
			const preferredCommunicationMethod = matchMetaOptions(parseOptionString(preferredCommunicationMethodOptions), orderPreferredCommunicationMethod, "-");
			const hopeForConsulting1 = matchMetaOptions(parseOptionString(hopeForConsulting1Options), orderHopeForConsulting1, "-");
			const hopeForConsulting2 = matchMetaOptions(parseOptionString(hopeForConsulting2Options), orderHopeForConsulting2, "-");

			if (isCpad) {
				additionalAgencyL01Data1.fields.titleText = pageContext["AdditionalInfoForAgencyConfirmation-CustomerInfoSubTitle"];
				additionalAgencyL01Data2.fields.titleText = pageContext["AdditionalInfoForAgencyConfirmation-OtherSubTitle"];
				const birthDate = personBirthDate?.split("-");
				const birthday = new Date(birthDate);
				const currentDate = new Date();
				const splitDate = currentDate.toISOString().split("T")[0].split("-");
				let dataRowList1 = [], dataRowList2 = [];
				additionalAgencyH02Data.fields.titleText = pageContext["AdditionalInfoForAgencyConfirmation-Title"];
						
				if (birthDate) {
					let currentAge = splitDate[0] - birthDate[0];
					const modifier = splitDate[1] - birthDate[1];
					if (
							modifier < 0 ||
							(modifier === 0 && currentDate.getDate() < birthday.getDate())
					) {
						currentAge = currentAge - 1;
					}
					
					const dateOfBirthInfoLabel = pageContext["AdditionalInfoForAgencyConfirmation-DateOfBirthInfo"]?.value;
					let dateOfBirthInfoValue = "",
						hearingImpairmentValue = "",
						tvAnotherDaySurveyValue = "",
						managementCompanyApprovalValue = "";
					const preferredContactDayOfTheWeekLabel = pageContext["AdditionalInfoForAgencyConfirmation-PreferredContactDayOfTheWeek"]?.value;
					const preferredContactDayOfTheWeekValue = telePreferredCommunicationDay;
					const preferredContactTimeLabel = pageContext["AdditionalInfoForAgencyConfirmation-PreferredContactTime"]?.value;
					const preferredContactTimeValue = `${telePreferredCommunicationTime}`;
					const requestNotToSendLabel = pageContext["AdditionalInfoForAgencyConfirmation-RequestNotToSend"]?.value;
					const requestNotToSendValue = `${requestNotToSend}`;
					const supportedLanguageLabel = pageContext["AdditionalInfoForAgencyConfirmation-SupportedLanguage"]?.value;
					const supportedLanguageValue = `${supportedLanguage}`;
					const preferredContactMeansLabel = pageContext["AdditionalInfoForAgencyConfirmation-PreferredContactMeans"]?.value;
					const preferredContactMeansValue = `${preferredCommunicationMethod}`;
					const hearingImpairmentLabel =  pageContext["AdditionalInfoForAgencyConfirmation-HearingImpairment"]?.value;
					const interpreterNameLabel = pageContext["AdditionalInfoForAgencyConfirmation-InterpreterName"]?.value;
					const interpreterNameValue = `${translatorName}`;
					const interpreterNameKanaLabel = pageContext["AdditionalInfoForAgencyConfirmation-InterpreterNameKana"]?.value;
					const interpreterNameKanaValue = `${translatorKana}`;
					const consultingServiceRequestLabel = pageContext["AdditionalInfoForAgencyConfirmation-ConsultingServiceRequest"]?.value;
					const tvAnotherDaySurveyLabel = pageContext["AdditionalInfoForAgencyConfirmation-TvAnotherDaySurvey"]?.value;
					const managementCompanyApprovalLabel = pageContext["AdditionalInfoForAgencyConfirmation-ManagementCompanyApproval"]?.value;
					const applicationDateLabel = pageContext["AdditionalInfoForAgencyConfirmation-ApplicationDate"]?.value;
					const applicationDateValue = moment(effectiveDate, "YYYY-MM-DD").format("YYYY年MM月DD日");
					const supplementaryInfOnCampaignBenefitsLabel = pageContext["AdditionalInfoForAgencyConfirmation-SupplementaryInfoOnCampaignBenefits"]?.value;
					const supplementaryInfOnCampaignBenefitsValue = `${campaignBenefitAddition}`;
					const otherRequestLabel = pageContext["AdditionalInfoForAgencyConfirmation-OtherRequests"]?.value;
					const otherRequestValue = `${otherCustomerRequest}`;
					const consultingServiceRequestValue = `${hopeForConsulting1}${hopeForConsulting1Other}<br>${hopeForConsulting2}${hopeForConsulting2Other}`;

					if (seniorCitizen) {
						dateOfBirthInfoValue = `${birthDate[0]}年${birthDate[1]}月${birthDate[2]}日&nbsp&nbsp&nbsp&nbsp年齢${currentAge}歳<br>${pageContext["AdditionalInfoForAgencyConfirmation-SeniorCitizen"]?.value}`;
					} else {
						dateOfBirthInfoValue = `${birthDate[0]}年${birthDate[1]}月${birthDate[2]}日&nbsp&nbsp&nbsp&nbsp年齢${currentAge}歳`;
					}

					if (hearingImpairmentFlag) {
						hearingImpairmentValue = pageContext["AdditionalInfoForAgencyConfirmation-WithHearingImpairment"]?.value;
					}
					else {
						hearingImpairmentValue = pageContext["AdditionalInfoForAgencyConfirmation-WithoutHearingImpairment"]?.value;
					}

					if (requestInvestigationTVanotherDay) {
						tvAnotherDaySurveyValue = pageContext["AdditionalInfoForAgencyConfirmation-RequestInvestigationTVanotherDay"]?.value;
					}
					else {
						tvAnotherDaySurveyValue = pageContext["AdditionalInfoForAgencyConfirmation-NoRequestInvestigationTVanotherDay"]?.value;
					}

					if (managementCompanyConsent) {
						managementCompanyApprovalValue = pageContext["AdditionalInfoForAgencyConfirmation-ManagementCompanyConsent"]?.value;
					}
					else {
						managementCompanyApprovalValue = pageContext["AdditionalInfoForAgencyConfirmation-NoManagementCompanyConsent"]?.value;
					}

					dataRowList1.push(
						addDataRowList(dateOfBirthInfoLabel, dateOfBirthInfoValue),
						addDataRowList(preferredContactDayOfTheWeekLabel, preferredContactDayOfTheWeekValue),
						addDataRowList(preferredContactTimeLabel, preferredContactTimeValue),
						addDataRowList(requestNotToSendLabel, requestNotToSendValue),
						addDataRowList(supportedLanguageLabel, supportedLanguageValue),
						addDataRowList(preferredContactMeansLabel, preferredContactMeansValue),
						addDataRowList(hearingImpairmentLabel, hearingImpairmentValue),
						addDataRowList(interpreterNameLabel, interpreterNameValue),
						addDataRowList(interpreterNameKanaLabel, interpreterNameKanaValue),
						addDataRowList(consultingServiceRequestLabel, consultingServiceRequestValue),
					);
					additionalAgencyL01Data1.fields.dataRowList = dataRowList1;
					dataRowList2.push(
						addDataRowList(tvAnotherDaySurveyLabel, tvAnotherDaySurveyValue),
						addDataRowList(managementCompanyApprovalLabel, managementCompanyApprovalValue),
						addDataRowList(applicationDateLabel, applicationDateValue),
						addDataRowList(supplementaryInfOnCampaignBenefitsLabel, supplementaryInfOnCampaignBenefitsValue),
						addDataRowList(otherRequestLabel, otherRequestValue)
					);
					additionalAgencyL01Data2.fields.dataRowList = dataRowList2;
				}
			
				const buttonLink = pageContext["AdditionalInfoForAgencyConfirmation-EditButtonLink"];
				b01Data.fields.linkURL = buttonLink;
			}
		}
	}, [retrieveMetadataData, isCpad])

    return (
			<>
				{isCpad && 
					<>
						<div className="form_wrapper-input" name="additional-agency-info-section">
							<Placeholder
								name="additional-agency-info-section"
								rendering={props.rendering}
							/>
						</div>
						
						<div className="btn_container" name="additional-agency-button-section">
							<Placeholder
								name="additional-agency-button-section"
								rendering={props.rendering}
							/>
						</div>
					</>
				}
			</>
    )
}