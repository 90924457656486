import React, { useEffect, useState } from "react";
import { di_fetch } from "../../utils/di";
import {
    fillBlank,
    getProperData,
    generateNumberArray,
} from "../../utils/commonUtils";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import parse from "html-react-parser";
import {
    STRING_METHOD_SELECTABLEOPTION,
    STRING_OPTION,
    QUANTITY_DROPDOWN_VALUE,
    STRING_MONTHLY_CURRENCY_TEXT,
    STRING_ANNUAL_CURRENCY_TEXT,
    STRING_LUMPSUM_CURRENCY_TEXT,
    STRING_NOT_SELECTED,
    STRING_ALTERNATIVE_PAYMENT,
    STRING_SMART_HOME,
    STRING_LUMPSUMPAYMENT,
    STRING_MONTHLYPAYMENT,
    STRING_ANNUALPAYMENT,
    STRING_SIXTY_INSTALLPAYMENT,
} from "../../utils/constantVariables";

const S18 = (props) => {
    const sitecoreContext = di_fetch("thisPageSitecoreContext");
    const properData = getProperData(sitecoreContext, props.fields);
    const { 
        data, 
        onChangeEvent, 
        productName, 
        defaultValues, 
        emptyChildText, 
        precautionText 
    } = properData;
    const [selectionData, setSelectionData] = useState();
    const [isAccordionActive, setIsAccordionActive] = useState(false);
    const checkSelectionType = (array, type) => {
        return array?.find((item) => item.SelectionType === type);
    };

    const appendField = (arrayData) => {
        return arrayData.map((obj) => {
            const defaultChecked =
                defaultValues &&
                defaultValues?.product.filter((product) => product.name === obj.Name);
            return {
                ...obj,
                selected: defaultChecked?.length > 0 ? true : false,
                Quantity:
                    defaultChecked?.length > 0 ? defaultChecked[0]?.quantity : "1",
                isSelectedAlternativePayment:
                    defaultChecked?.length > 0
                        ? defaultChecked[0]?.isSelectedAlternativePayment
                        : false,
            };
        });
    };

    const mappingProductInfos = (productInfo) => {
        return productInfo.map(parent => ({
            name: parent?.Name,
            title: parent?.Title,
            titleClass: parent?.TitleClass,
            paymentType: parent?.PaymentType,
            price: parent?.Price,
            priceWithoutTax: parent?.PriceWithoutTax,
            quantity: parent?.Quantity,
            sortOrder: parent?.sortOrder,
            key: parent?.Key,
            value: parent?.Value,
            isSelectedAlternativePayment: parent?.isSelectedAlternativePayment,
            alternativePrice: parent?.AlternativePrice,
            alternativePriceWithoutTax: parent?.AlternativePriceWithoutTax,
            alternativePaymentType: parent?.AlternativePaymentType
        }));
    };

    useEffect(() => {
        //append selected field to each items
        if (data) {
            const appendSelectedField = data?.map((item) => {
                const defaultChecked =
                    defaultValues &&
                    defaultValues?.product?.filter(
                        (product) => product.name === item.Name
                    );
                const hasAlternativePayment =
                    defaultChecked?.length > 0 ? defaultChecked[0]?.isSelectedAlternativePayment : false;
                if (item?.subChild) {
                    return {
                        ...item,
                        selected: defaultChecked?.length > 0 ? true : false,
                        subChild: appendField(item?.subChild),
                        ...(hasAlternativePayment && {
                            isSelectedAlternativePayment:
                            hasAlternativePayment || false,
                        }),
                        Quantity:  defaultChecked?.product?.length > 0 ? defaultChecked[0]?.product.quantity : "1",
                    };
                } else {
                    return {
                        ...item,
                        selected: defaultChecked?.length > 0 ? true : false,
                        isSelectedAlternativePayment:
                            defaultChecked?.length > 0 ? defaultChecked[0]?.isSelectedAlternativePayment : false,
                        Quantity: defaultChecked?.length > 0 ? defaultChecked[0]?.quantity : "1",
                    };
                }
            });
            //if selectionType === STRING_METHOD_SELECTABLEOPTION append a NOT SELECTED option
            setSelectionData(
                checkSelectionType(data, STRING_METHOD_SELECTABLEOPTION)
                    ? [
                        ...appendSelectedField,
                        {
                            Title: STRING_NOT_SELECTED,
                            Name: "not_selected",
                            selected:
                                defaultValues && defaultValues?.product?.length === 0
                                    ? true
                                    : false,
                            SelectionType: STRING_METHOD_SELECTABLEOPTION,
                        },
                    ]
                    : appendSelectedField
            );
            //set Accordion to Active (Open) if there is a default values
            setIsAccordionActive(
                (defaultValues && defaultValues?.product?.length > 0)
                || (emptyChildText && emptyChildText.length > 0)
                ? true : false
            );
        }
    }, [data]);
    
    const handleChange = (parentKey, selectionType, updatedState, e) => {
        if (selectionType === STRING_METHOD_SELECTABLEOPTION) {
            const updatedOptions = updatedState.map((option) => {
                if (option.Name === parentKey) {
                    onChangeEvent(
                        productName,
                        option.Name !== "not_selected"
                            ? [
                                {
                                    name: option?.Name,
                                    title: option?.Title,
                                    titleClass: option?.TitleClass,
                                    paymentType: option?.PaymentType,
                                    price: option?.Price,
                                    priceWithoutTax: option?.PriceWithoutTax,
                                    quantity: "1",
                                    sortOrder: option?.sortOrder,
                                    key: option?.Key,
                                    value: option?.Value,
                                    alternativePrice: option?.AlternativePrice,
                                    alternativePriceWithoutTax: option?.AlternativePriceWithoutTax,
                                    alternativePaymentType: option?.AlternativePaymentType,
                                },
                            ]
                            : [],
                        option.SelectionType
                    );
                    return { ...option, selected: true };
                } else {
                    return { ...option, selected: false };
                }
            });

            setSelectionData(updatedOptions);
        } else if (selectionType === STRING_OPTION) {
            const currentSelection = mappingProductInfos(
                selectionData.filter(product => product?.selected)
            );
            
            const updatedOptions = selectionData.map((parent) => {
                if (parent.Name === parentKey) {
                    onChangeEvent(
                        productName,
                        !parent.selected
                            ? [...currentSelection,
                                {
                                    name: parent?.Name,
                                    title: parent?.Title,
                                    titleClass: parent?.TitleClass,
                                    paymentType: parent?.PaymentType,
                                    price: parent?.Price,
                                    priceWithoutTax: parent?.PriceWithoutTax,
                                    quantity: parent?.Quantity,
                                    sortOrder: parent?.sortOrder,
                                    key: parent?.Key,
                                    value: parent?.Value,
                                    alternativePrice: parent?.AlternativePrice,
                                    alternativePriceWithoutTax: parent?.AlternativePriceWithoutTax,
                                    alternativePaymentType: parent?.AlternativePaymentType,
                                },
                            ] : currentSelection.filter(
                                item => item.name !== parentKey
                            ),
                        parent.SelectionType
                    );
                    if (parent?.subChild) {
                        const updatedChild = parent.subChild.map((child) => {
                            return {
                                ...child,
                                selected: false,
                                Quantity: "1",
                                isSelectedAlternativePayment: false,
                            };
                        });
                        return {
                            ...parent,
                            selected: !parent.selected,
                            subChild: updatedChild,
                            ...(parent?.isSelectedAlternativePayment && {
                                isSelectedAlternativePayment: !parent?.isSelectedAlternativePayment,
                            }),
                        };
                    }

                    return {
                        ...parent,
                        selected: !parent.selected,
                        ...(parent?.isSelectedAlternativePayment && {
                            isSelectedAlternativePayment: !parent?.isSelectedAlternativePayment,
                        }),
                    };
                }
                return parent;
            });

            setSelectionData(updatedOptions);
        }
    };

    const handleChildChange = (
        event,
        parentKey,
        parentDetails,
        paymentType,
        selectionType,
        quantity,
        itemKey,
        type
    ) => {
        const newSelectionData = selectionData.filter(
            (data) => data.Name === parentKey
        );

        newSelectionData.map((data) => {
            if (quantity && itemKey === data.Name) {
                data.Quantity = quantity || "1";
            } else if (Object.hasOwn(data, 'subChild') && data.subChild !== undefined) {
                data.subChild.map((itemSet) => {
                    //handling alternative payment of product child (SMART HOME) with unified alternative payment method
                    if (
                        type === STRING_ALTERNATIVE_PAYMENT &&
                        parentKey === STRING_SMART_HOME &&
                        itemSet.selected
                    ) {
                        itemSet.isSelectedAlternativePayment = !itemSet.isSelectedAlternativePayment;
                    } else if (
                        parentKey === STRING_SMART_HOME &&
                        !itemSet.selected &&
                        event?.target?.value === itemSet.Name
                    ) {
                        //handling onchange selection of product child (SMART HOME)
                        itemSet.selected = !itemSet.selected;
                        //check if there's an existing child that isSelectedAlternativePayment
                        const isOtherChildHasSelectedAlternativePayment = data.subChild.some(
                            (child) => child.isSelectedAlternativePayment
                        );
                        if (isOtherChildHasSelectedAlternativePayment) {
                            itemSet.isSelectedAlternativePayment = true;
                        }
                    } else if (
                        (type === STRING_ALTERNATIVE_PAYMENT ? event : event.target.value) ===
                        itemSet.Name
                    ) {
                        if (type === STRING_ALTERNATIVE_PAYMENT) {
                            itemSet.isSelectedAlternativePayment = !itemSet.isSelectedAlternativePayment;
                        } else {
                            itemSet.selected = !itemSet.selected;

                            if (!itemSet.selected) {
                                itemSet.Quantity = "1";
                                itemSet.isSelectedAlternativePayment = false;
                            }
                        }
                    } else if (quantity && itemKey === itemSet.Name) {
                        itemSet.Quantity = quantity || "1";
                    }
                });
            }
        });
        const updatedSelectionData = selectionData.map((obj) =>
            obj.Name === parentKey
                ? { ...obj, subChild: newSelectionData[0]?.subChild }
                : obj
        );
        const selectedChild = newSelectionData[0]?.subChild?.filter(
            (child) => child.selected
        );
        const selectedProducts = mappingProductInfos(selectedChild);
        onChangeEvent(
            productName,
            selectedChild && selectedChild?.length > 0
                ? [parentDetails, ...selectedProducts]
                : [parentDetails],
            selectionType
        );
        setSelectionData(updatedSelectionData);
    };

    const handleAlternativePaymentChange = (name) => {
        const updatedItems = [...selectionData];
        const selectedItemIndex = updatedItems.findIndex(
            (item) => item.Name === name
        );
        if (selectedItemIndex !== -1) {
            const isSelectedAlternativePayment =
                updatedItems[selectedItemIndex].isSelectedAlternativePayment;
            updatedItems[selectedItemIndex].isSelectedAlternativePayment = 
                !isSelectedAlternativePayment;

            setSelectionData(updatedItems);
            onChangeEvent(
                productName,
                mappingProductInfos(updatedItems.filter(item => item.selected)),
                updatedItems[selectedItemIndex]?.SelectionType,
            );
        }
    };

    const priceTextLabel = (PaymentType) => {
        let priceTextLabel = "";
        switch(PaymentType) {
            case STRING_MONTHLYPAYMENT:
                priceTextLabel = STRING_MONTHLY_CURRENCY_TEXT;
                break;
            case STRING_ANNUALPAYMENT:
                priceTextLabel = STRING_ANNUAL_CURRENCY_TEXT;
                break;
            case STRING_LUMPSUMPAYMENT:
                priceTextLabel = STRING_LUMPSUM_CURRENCY_TEXT;
                break;
            case STRING_SIXTY_INSTALLPAYMENT:
                priceTextLabel = STRING_MONTHLY_CURRENCY_TEXT;
                break;
        }
        return (
            <>
                {priceTextLabel}
            </>
        );
    };

    return (
        <>
            <div className="accordion S-18">
                {((selectionData && selectionData.length > 0) || (emptyChildText && emptyChildText.length > 0)) && (
                    <div
                        className={`form_wrapper-input card-body ${isAccordionActive ? "activeAccordion" : "hideAccordion"
                            }`}
                    >
                        { emptyChildText && emptyChildText.length > 0 && (
                        <div class="description-content">
                                <p>{emptyChildText}</p>
                        </div>
                        )}
                        { precautionText && precautionText.length > 0 && (
                            <div className="precaution-box input-form no-margin S-18 with-image mb-20">
                                {parse(precautionText)}
                            </div>
                        )}
                        {selectionData?.map((item, parentKey) => (
                            <div key={parentKey + "S-18-Container"}>
                                <div
                                    className="input-form no-margin S-18 with-image"
                                    key={item?.label + "S-18-Parent"}
                                >
                                    <div className={`input-box ${item.selected ? "active" : ""}`}>
                                        <div className="input-container">
                                            <Placeholder
                                                name="required-tag"
                                                rendering={props.rendering}
                                            />
                                            {item?.SelectionType ===
                                                STRING_METHOD_SELECTABLEOPTION ? (
                                                <input
                                                    type="radio"
                                                    name={`rdOptionS18${productName}`}
                                                    value={item?.Name}
                                                    checked={item?.selected}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            item?.Name,
                                                            item?.SelectionType,
                                                            selectionData,
                                                            e
                                                        )
                                                    }
                                                />
                                            ) : item?.SelectionType === STRING_OPTION ? (
                                                <input
                                                    type="checkbox"
                                                    name="cOptionS18"
                                                    value={item?.Name}
                                                    checked={item?.selected}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            item?.Name,
                                                            item?.SelectionType,
                                                            selectionData,
                                                            e
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <></>
                                            )}

                                            <label
                                                htmlFor={`${item?.SelectionType === STRING_METHOD_SELECTABLEOPTION
                                                        ? `rdOptionS18${productName}`
                                                        : "cOptionS18"
                                                    } `}
                                            >
                                                <div className="title-container">
                                                    <dl>
                                                        <dt>
                                                            {item?.Title && (
                                                                <p className={item?.TitleClass}>
                                                                    <Text field={fillBlank(item?.Title)} />
                                                                </p>
                                                            )}
                                                        </dt>
                                                        {item?.Tooltip && (
                                                            <dd className="tool-tip">
                                                                <i className="bi bi-info-circle-fill"></i>
                                                                <p className="information">
                                                                    <Text field={fillBlank(item?.Tooltip)} />
                                                                </p>
                                                            </dd>
                                                        )}
                                                    </dl>
                                                    {item?.Price && (
                                                        <span className="right desktop">
                                                            <p>
                                                                <Text
                                                                    field={fillBlank(
                                                                        parseFloat(item?.Price).toLocaleString()
                                                                    )}
                                                                />
                                                            </p>
                                                            {priceTextLabel(item?.PaymentType)}
                                                        </span>
                                                    )}
                                                </div>
                                                {item?.Description && (
                                                    <div className="description">
                                                        {parse(item.Description)}
                                                    </div>
                                                )}

                                                {item?.Image && (
                                                    <div
                                                        className={`benefits-image ${item?.subChild ? "" : "mb-20"
                                                            }`}
                                                    >
                                                        <picture>
                                                            <source
                                                                media="(max-width:640px)"
                                                                srcset={item.ImageForSp}
                                                            />
                                                            <img src={item.Image} />
                                                        </picture>
                                                    </div>
                                                )}
                                                {item?.Price && (
                                                    <span className="right mobile">
                                                        <p>
                                                            <Text
                                                                field={fillBlank(
                                                                    parseFloat(item?.Price).toLocaleString()
                                                                )}
                                                            />
                                                        </p>
                                                        {priceTextLabel(item?.PaymentType)}
                                                    </span>
                                                )}
                                                {item?.QuantityInputFlg && (
                                                    <div className={`pull-down ${item?.subChild ? "mb-20" : ""}`}>
                                                        <select
                                                            name="M01-1-S18"
                                                            value={item?.Quantity}
                                                            disabled={!item?.selected}
                                                            onChange={(e) =>
                                                                handleChildChange(
                                                                    e,
                                                                    item?.Name,
                                                                    {
                                                                        name: item?.Name,
                                                                        title: item?.Title,
                                                                        titleClass: item?.TitleClass,
                                                                        paymentType: item?.PaymentType,
                                                                        price: item?.Price,
                                                                        priceWithoutTax: item?.PriceWithoutTax,
                                                                        quantity: e?.target?.value,
                                                                        sortOrder: item?.sortOrder,
                                                                        key: item?.Key,
                                                                        value: item?.Value,
                                                                        alternativePrice: item?.AlternativePrice,
                                                                        alternativePriceWithoutTax: item?.AlternativePriceWithoutTax,
                                                                        alternativePaymentType: item?.AlternativePaymentType,
                                                                    },
                                                                    item?.PaymentType,
                                                                    item?.SelectionType,
                                                                    e?.target?.value,
                                                                    item?.Name
                                                                )
                                                            }
                                                        >
                                                            {generateNumberArray(
                                                                QUANTITY_DROPDOWN_VALUE
                                                            ).map((selection, selectionKey) => (
                                                                <option
                                                                    key={selectionKey}
                                                                    value={selection}
                                                                >
                                                                    {selection}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span>台</span>
                                                    </div>
                                                )}

                                                {/* AlternativePaymentType */}
                                                {item?.AlternativePaymentType && (
                                                    <div
                                                        className="input-form S-04 payment-type"
                                                        key="paymentType"
                                                    >
                                                        <div className="input-container">
                                                            <input
                                                                type="checkbox"
                                                                name="cOptionS18-AlternativePayment"
                                                                value={item?.Name}
                                                                disabled={!item?.selected}
                                                                checked={item?.isSelectedAlternativePayment}
                                                                onChange={() =>
                                                                    handleAlternativePaymentChange(item?.Name)
                                                                }
                                                            />

                                                            <label
                                                                htmlFor={`${item?.SelectionType ===
                                                                        STRING_METHOD_SELECTABLEOPTION
                                                                        ? "rdOptionS18-child"
                                                                        : "cOptionS18-child"
                                                                    } `}
                                                                className={`${!item.selected ? "disabled" : ""
                                                                    }`}
                                                            >
                                                                {item?.AlternativePaymentTypeLabel}
                                                                <span className="right">
                                                                    {item?.AlternativePaymentTypeLabel && (
                                                                        <span className="price-label desktop">
                                                                            {item?.AlternativePaymentTypeLabel}
                                                                        </span>
                                                                    )}
                                                                    {item?.AlternativePrice && (
                                                                        <>
                                                                            <p className="price">
                                                                                {parseFloat(
                                                                                    item?.AlternativePrice
                                                                                ).toLocaleString()}
                                                                            </p>
                                                                            {priceTextLabel(item?.AlternativePaymentType)}
                                                                        </>
                                                                    )}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* S-18 Child */}
                                                {item?.subChild?.map((subItem, subItemKey) => (
                                                    <div className="input-form S-04" key={subItemKey}>
                                                        <div
                                                            className={`input-box ${subItem.selected ? "active" : ""
                                                                }`}
                                                        >
                                                            <div className="input-container">
                                                                {item?.SelectionType ===
                                                                    STRING_METHOD_SELECTABLEOPTION ? (
                                                                    <input
                                                                        type="radio"
                                                                        name="rdOptionS18-sub-child"
                                                                        value={subItem?.Name}
                                                                        checked={subItem?.selected}
                                                                        disabled={!item.selected}
                                                                        onChange={(e) =>
                                                                            handleChildChange(
                                                                                e,
                                                                                item?.Name,
                                                                                {
                                                                                    name: item?.Name,
                                                                                    title: item?.Title,
                                                                                    titleClass: item?.TitleClass,
                                                                                    paymentType: item?.PaymentType,
                                                                                    price: item?.Price,
                                                                                    priceWithoutTax: item?.PriceWithoutTax,
                                                                                    quantity: "1",
                                                                                    sortOrder: item?.sortOrder,
                                                                                    key: item?.Key,
                                                                                    value: item?.Value,
                                                                                    alternativePrice: item?.AlternativePrice,
                                                                                    alternativePriceWithoutTax: item?.AlternativePriceWithoutTax,
                                                                                    alternativePaymentType: item?.AlternativePaymentType,
                                                                                },
                                                                                item?.PaymentType,
                                                                                item?.SelectionType
                                                                            )
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        type="checkbox"
                                                                        name="cOptionS18-sub-child"
                                                                        value={subItem?.Name}
                                                                        checked={subItem?.selected}
                                                                        disabled={!item.selected}
                                                                        onChange={(e) =>
                                                                            handleChildChange(
                                                                                e,
                                                                                item?.Name,
                                                                                {
                                                                                    name: item?.Name,
                                                                                    title: item?.Title,
                                                                                    titleClass: item?.TitleClass,
                                                                                    paymentType: item?.PaymentType,
                                                                                    price: item?.Price,
                                                                                    priceWithoutTax: item?.PriceWithoutTax,
                                                                                    quantity: "1",
                                                                                    sortOrder: item?.sortOrder,
                                                                                    key: item?.Key,
                                                                                    value: item?.Value,
                                                                                    alternativePrice: item?.AlternativePrice,
                                                                                    alternativePriceWithoutTax: item?.AlternativePriceWithoutTax,
                                                                                    alternativePaymentType: item?.AlternativePaymentType,
                                                                                },
                                                                                item?.PaymentType,
                                                                                item?.SelectionType
                                                                            )
                                                                        }
                                                                    />
                                                                )}

                                                                <label
                                                                    htmlFor={`${item?.SelectionType ===
                                                                            STRING_METHOD_SELECTABLEOPTION
                                                                            ? "rdOptionS18-child"
                                                                            : "cOptionS18-child"
                                                                        } `}
                                                                    className={`${!item.selected ? "disabled" : ""
                                                                        }`}
                                                                >
                                                                    <div className="title-container">
                                                                        <dl>
                                                                            <dt className={subItem?.TitleClass}>
                                                                                {subItem?.Title}
                                                                            </dt>
                                                                            {subItem?.Tooltip && (
                                                                            <dd className="tool-tip">
                                                                                <i className="bi bi-info-circle-fill"></i>
                                                                                <p className="information">
                                                                                    {subItem?.Tooltip}
                                                                                </p>
                                                                            </dd>
                                                                            )}
                                                                        </dl>
                                                                        <span className="right desktop">
                                                                            {subItem?.Price && (
                                                                                <>
                                                                                    <p className="price">
                                                                                        {parseFloat(
                                                                                            subItem?.Price
                                                                                        ).toLocaleString()}
                                                                                    </p>
                                                                                    {priceTextLabel(subItem?.PaymentType)}
                                                                                </>
                                                                            )}
                                                                        </span>
                                                                    </div>

                                                                    {subItem?.Description && (
                                                                        <div className="description">
                                                                            {parse(subItem.Description)}
                                                                        </div>
                                                                    )}

                                                                    {subItem?.Image && (
                                                                        <div className="benefits-image">
                                                                            <picture>
                                                                                <source
                                                                                    media="(max-width:640px)"
                                                                                    srcset={subItem.ImageForSp}
                                                                                />
                                                                                <img src={subItem.Image} />
                                                                            </picture>
                                                                        </div>
                                                                    )}
                                                                    <span className="right mobile">
                                                                        {subItem?.Price && (
                                                                            <>
                                                                                <p className="price">
                                                                                    {parseFloat(
                                                                                        subItem?.Price
                                                                                    ).toLocaleString()}
                                                                                </p>
                                                                                {priceTextLabel(subItem?.PaymentType)}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                    {subItem?.QuantityInputFlg && (
                                                                        <div className="pull-down">
                                                                            <select
                                                                                name="M01-1-S18"
                                                                                value={subItem?.Quantity}
                                                                                disabled={!subItem.selected}
                                                                                onChange={(e) =>
                                                                                    handleChildChange(
                                                                                        e,
                                                                                        item?.Name,
                                                                                        {
                                                                                            name: item?.Name,
                                                                                            title: item?.Title,
                                                                                            titleClass: item?.TitleClass,
                                                                                            paymentType: item?.PaymentType,
                                                                                            price: item?.Price,
                                                                                            priceWithoutTax: item?.PriceWithoutTax,
                                                                                            quantity: "1",
                                                                                            sortOrder: item?.sortOrder,
                                                                                            key: item?.Key,
                                                                                            value: item?.Value,
                                                                                            alternativePrice: item?.AlternativePrice,
                                                                                            alternativePriceWithoutTax: item?.AlternativePriceWithoutTax,
                                                                                            alternativePaymentType: item?.AlternativePaymentType,
                                                                                        },
                                                                                        item?.PaymentType,
                                                                                        item?.SelectionType,
                                                                                        e?.target?.value,
                                                                                        subItem?.Name
                                                                                    )
                                                                                }
                                                                            >
                                                                                {generateNumberArray(
                                                                                    QUANTITY_DROPDOWN_VALUE
                                                                                ).map((selection, selectionKey) => (
                                                                                    <option
                                                                                        key={selectionKey}
                                                                                        value={selection}
                                                                                    >
                                                                                        {selection}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                            <span>台</span>
                                                                        </div>
                                                                    )}

                                                                    {subItem?.AlternativePaymentType && (
                                                                        <div
                                                                            className="input-form S-04 payment-type"
                                                                            key="paymentType"
                                                                        >
                                                                            <div className="input-container">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="cOptionS18-AlternativePayment"
                                                                                    value={subItem?.Name}
                                                                                    disabled={!subItem?.selected}
                                                                                    checked={
                                                                                        subItem?.isSelectedAlternativePayment
                                                                                    }
                                                                                    onChange={() =>
                                                                                        handleChildChange(
                                                                                            subItem?.Name,
                                                                                            item?.Name,
                                                                                            {
                                                                                                name: item?.Name,
                                                                                                title: item?.Title,
                                                                                                titleClass: item?.TitleClass,
                                                                                                paymentType: item?.PaymentType,
                                                                                                price: item?.Price,
                                                                                                priceWithoutTax: item?.PriceWithoutTax,
                                                                                                quantity: "1",
                                                                                                sortOrder: item?.sortOrder,
                                                                                                key: item?.Key,
                                                                                                value: item?.Value,
                                                                                                alternativePrice: item?.AlternativePrice,
                                                                                                alternativePriceWithoutTax: item?.AlternativePriceWithoutTax,
                                                                                                alternativePaymentType: item?.AlternativePaymentType,
                                                                                            },
                                                                                            item?.PaymentType,
                                                                                            item?.SelectionType,
                                                                                            "",
                                                                                            "",
                                                                                            STRING_ALTERNATIVE_PAYMENT
                                                                                        )
                                                                                    }
                                                                                />

                                                                                <label
                                                                                    htmlFor={`${subItem?.SelectionType ===
                                                                                            STRING_METHOD_SELECTABLEOPTION
                                                                                            ? "rdOptionS18-child"
                                                                                            : "cOptionS18-child"
                                                                                        } `}
                                                                                    className={`${!subItem.selected ? "disabled" : ""
                                                                                        }`}
                                                                                >
                                                                                    {subItem?.AlternativePaymentTypeLabel}
                                                                                    <span className="right">
                                                                                        {subItem?.AlternativePaymentTypeLabel && subItem?.AlternativePrice && (
                                                                                            <span className="price-label desktop">
                                                                                                {
                                                                                                    subItem?.AlternativePaymentTypeLabel
                                                                                                }
                                                                                            </span>
                                                                                        )}
                                                                                        {subItem?.AlternativePrice && (
                                                                                            <>
                                                                                                <p className="price">
                                                                                                    {parseFloat(
                                                                                                        subItem?.AlternativePrice
                                                                                                    ).toLocaleString()}
                                                                                                </p>
                                                                                                {priceTextLabel(subItem?.AlternativePaymentType)}
                                                                                            </>
                                                                                        )}
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <div
                    className={`accordion-title ${isAccordionActive ? "activeAccordion" : ""
                        }`}
                    onClick={() => setIsAccordionActive(!isAccordionActive)}
                >
                    <h5>{isAccordionActive ? "商品を閉じる" : "商品を開く"}</h5>
                </div>
            </div>
        </>
    );
};

export default S18;