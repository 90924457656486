import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { di_fetch } from "../../utils/di";
import {
  preventDoubleClick,
  openButtonLink,
  getProperData,
  fillBlank,
} from "../../utils/commonUtils";

const B002 = (props) => {
  // @Model
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    btnWrapperAdditionalClass,
    additionalClass,
    buttonText,
    linkURL,
    queryString,
    isHidden
  } = properData;

  // @Controller
  const handleButtonClick = preventDoubleClick(
    async (evt, myClickEvent = undefined) => {
      if (myClickEvent) {
        myClickEvent();
        return false;
      }

      if (properData?.onClick) properData.onClick();

      let urlInfo = linkURL?.value;
      let linkType = urlInfo?.linktype;
      let url = urlInfo?.href;
      if (linkType == "javascript") {
        eval(url);
        return true;
      }

      //openButtonLink(props.fields.buttonLink.value.href, props.fields.buttonLink.value.target)
      openButtonLink(url, urlInfo?.target);
    }
  );

  const openButtonLink = (url, target) => {
    if (target === "" || typeof target == "undefined") {
      window.location.href =
        queryString !== undefined ? `${url}?${queryString}` : url;
    } else if (target === "_blank") {
      const urlPath = queryString !== undefined ? `${url}?${queryString}` : url;
      window.open(urlPath);
    }
  };

  // @View
  return (
    <React.Fragment>
      {!isHidden && (
        <div
          className={`btn_wrapper secondary B-02 ${btnWrapperAdditionalClass?.value}`}
        >
          <button
            className={`btn btn_default ${additionalClass?.value}`}
            type="button"
            onClick={(e) => handleButtonClick(e, props?.fields?.onClickEvent)}
          >
            <Text field={fillBlank(buttonText)} />
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default B002;
