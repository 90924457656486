import React, { useState, useMemo, useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import classnames from "classnames";
import { di_fetch } from "../../utils/di";
import { getProperData, fillBlank } from "../../utils/commonUtils";
import { ApartmentSearchContext } from "../../assets/context/ApartmentSearchContext";

let PageSize = 15;

const PL01 = (props) => {
  const sitecoreContext = di_fetch("thisPageSitecoreContext");
  const properData = getProperData(sitecoreContext, props.fields);
  const {
    buttonSearchLabel,
    searchHeaderName,
    searchAddressHeaderName,
    searchResultAddressText,
    noSearchResultText,
    pagePrevious,
    pageNext,
    searchPlaceholder,
    onClickEvent,
    dataRowList,
    searchResultProvisionID,
    searchResultApartmentIsProvidable,
    searchResultApartmentIsProvisionName,
    searchResultApartmentNote,
    searchResultApartmentName,
    arrowIconLeft,
    arrowIconRight,
  } = properData;

  const [currentPage, setCurrentPage] = useState(1);
  const startElemNumber = (currentPage - 1) * PageSize;
  const endElementNumber = startElemNumber + PageSize;

  const DOTS = "...";
  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const usePagination = ({
    totalCount,
    pageSize,
    siblingCount = 1,
    currentPage,
  }) => {
    const paginationRange = useMemo(() => {
      const totalPageCount = Math.ceil(totalCount / pageSize);

      // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
      const totalPageNumbers = siblingCount + 5;

      /*
        If the number of pages is less than the page numbers we want to show in our
        paginationComponent, we return the range [1..totalPageCount]
      */
      if (totalPageNumbers >= totalPageCount) {
        return range(1, totalPageCount);
      }

      const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
      const rightSiblingIndex = Math.min(
        currentPage + siblingCount,
        totalPageCount
      );

      /*
        We do not want to show dots if there is only one position left 
        after/before the left/right page count as that would lead to a change if our Pagination
        component size which we do not want
      */
      const shouldShowLeftDots = leftSiblingIndex > 2;
      const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

      const firstPageIndex = 1;
      const lastPageIndex = totalPageCount;

      if (!shouldShowLeftDots && shouldShowRightDots) {
        let leftItemCount = 2 + 2 * siblingCount;
        let leftRange = range(1, leftItemCount);

        return [...leftRange, DOTS, totalPageCount];
      }

      if (shouldShowLeftDots && !shouldShowRightDots) {
        let rightItemCount = 2 + 2 * siblingCount;
        let rightRange = range(
          totalPageCount - rightItemCount + 1,
          totalPageCount
        );
        return [firstPageIndex, DOTS, ...rightRange];
      }

      if (shouldShowLeftDots && shouldShowRightDots) {
        let middleRange = range(leftSiblingIndex, rightSiblingIndex);
        return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
      }
    }, [totalCount, pageSize, siblingCount, currentPage]);

    return paginationRange;
  };

  const Pagination = (props) => {
    const {
      onPageChange,
      totalCount,
      siblingCount = 1,
      currentPage,
      pageSize,
      className,
    } = props;

    const paginationRange = usePagination({
      currentPage,
      totalCount,
      siblingCount,
      pageSize,
    });

    if (currentPage === 0 || paginationRange.length < 2) {
      return null;
    }

    const onNext = () => {
      onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
      onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];

    return (
      <React.Fragment>
        <div
          className={classnames("pagination P-01", {
            [className]: className,
          })}
        >
          <nav>
            <a
              className={classnames("pagination-item", {
                inactive: currentPage === 1,
                disabled: currentPage === 1,
              })}
              onClick={onPrevious}
            >
              {arrowIconLeft?.value && (
                <img src={fillBlank(arrowIconLeft)?.value?.src} />
              )}
            </a>
            <a
              className={classnames("pagination-item", {
                inactive: currentPage === 1,
                disabled: currentPage === 1,
              })}
              onClick={onPrevious}
            >
              <Text field={fillBlank(pagePrevious)} />
            </a>
            {paginationRange.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                return (
                  <span key={index} className="more">
                    ...
                  </span>
                );
              }

              return (
                <a
                  key={index}
                  className={classnames("sxa-paginationnumber", {
                    active: pageNumber === currentPage,
                    disabled: pageNumber === currentPage,
                  })}
                  onClick={() => onPageChange(pageNumber)}
                  href="#"
                >
                  {pageNumber}
                </a>
              );
            })}
            <a
              className={classnames("pagination-item", {
                inactive: currentPage === lastPage,
                disabled: currentPage === lastPage,
              })}
              onClick={onNext}
              href="#"
            >
              <Text field={fillBlank(pageNext)} />
            </a>
            <a
              className={classnames("pagination-item", {
                inactive: currentPage === lastPage,
                disabled: currentPage === lastPage,
              })}
              onClick={onNext}
              href="#"
            >
              {arrowIconRight?.value && (
                <img src={fillBlank(arrowIconRight)?.value?.src} />
              )}
            </a>
          </nav>
        </div>
      </React.Fragment>
    );
  };

  // @controller
  const handleButtonClick = (e, onClickEvent, roomId) => {
    onClickEvent(e.currentTarget.dataset, roomId);
  };

  const [value, setValue] = useState("");
  const [getValue, setGetValue] = useState("");

  const searchtEvent = (event) => {
    setValue(event.target.value);
  };

  const onSearch = (searchTerm) => {
    setCurrentPage(1);
    setGetValue(searchTerm);
  };

  const {
    apartmentSearchContext,
    setApartmentSearchContext,
    setSearchResultCount,
    totalItemCount,
  } = useContext(ApartmentSearchContext);

  let searchTerm = getValue.toLocaleLowerCase();

  let seachRowData = dataRowList?.filter((item) => {
    const data = item.PropertyName.toLocaleLowerCase();

    if (searchTerm === "") {
      return item;
    } else {
      const result = data.includes(searchTerm);
      setApartmentSearchContext(result);
      return result;
    }
  });

  setSearchResultCount(
    (searchTerm === ""
      ? totalItemCount?.toString()
      : seachRowData?.length?.toString()) || "0"
  );

  return (
    <>
      <div className="pagination_wrapper PL-01">
        <Pagination
          className=""
          currentPage={currentPage}
          totalCount={seachRowData?.length || 0}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
        <div className="search_wrapper L-03" id="SearchWrapper">
          <div className="input-container">
            <div className="search-container">
              <input
                type="text"
                id="wordSearch"
                value={value}
                onChange={searchtEvent}
                placeholder={fillBlank(searchPlaceholder).value}
              />
            </div>
            <div className="btn_wrapper primary">
              <button
                className="btn btn_medium"
                type="button"
                onClick={() => onSearch(value)}
              >
                <Text field={fillBlank(buttonSearchLabel)} />
              </button>
            </div>
          </div>
          <div className="search-result">
            <table>
              <tbody>
                <tr>
                  <th
                    className={classnames({
                      ["no-search-result-header"]:
                        seachRowData?.length === 0 || dataRowList?.length === 0,
                    })}
                  >
                    <Text field={fillBlank(searchHeaderName)} />
                  </th>
                  <th>
                    <Text field={fillBlank(searchAddressHeaderName)} />
                  </th>
                </tr>
                {seachRowData?.length === 0 || dataRowList?.length === 0 ? (
                  <tr>
                    <td colSpan={2} className="no-result-found">
                      <Text field={fillBlank(noSearchResultText)} />
                    </td>
                  </tr>
                ) : (
                  seachRowData
                    ?.filter(
                      (elm, i) => startElemNumber <= i && i < endElementNumber
                    )
                    .map((item, i) => {
                      return (
                        <tr
                          key={i}
                          className="result-item"
                          data-ProvisionID={item.ProvisionID}
                          data-PropertyName={item.PropertyName}
                          data-Address={item.Address}
                          data-OfferMethodName={item.OfferMethodName}
                          data-OfferState={item.OfferState}
                          data-Providable={item.Providable}
                          data-Remark={item.Remark}
                          data-ApartmentHome={item.ApartmentHome}
                          data-ApartmentStreetBunch={item.ApartmentStreetBunch}
                          onClick={(e) =>
                            handleButtonClick(e, onClickEvent, item.ProvisionID)
                          }
                        >
                          <td>
                            <Text field={fillBlank(item.PropertyName)} />
                          </td>
                          <td>
                            <Text field={fillBlank(item.Address)} />
                          </td>
                        </tr>
                      );
                    })
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          className=""
          currentPage={currentPage}
          totalCount={seachRowData?.length || 0}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default PL01;
